export {
  matchRoutes,
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

export type { Location, NavigateFunction } from 'react-router-dom';

export const useDeepLinking = (): void => undefined;
