import { usePushNotifications } from '../../../plugins/pushNotifications/usePushNotifications';
import { setMobileToken } from '../../../utils/authenticationStore';

type UseMobilePostLoginReturn = (jwt: string, hasHubOnboarded: boolean) => Promise<void>;

export const useMobilePostLogin = (): UseMobilePostLoginReturn => {
  const { registerForPushNotifications } = usePushNotifications();

  return async (jwt: string, hasHubOnboarded: boolean) => {
    await setMobileToken(jwt);

    if (hasHubOnboarded) {
      await registerForPushNotifications();
    }
  };
};
