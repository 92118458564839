import { uniq } from 'lodash';
import { useModulesSimpleByIds } from '../../../content/module/hooks/useModulesSimpleByIds';
import { useSectionsByIds } from '../../../content/section/hooks/useSectionsByIds';
import { useSkillPathwayGetForUserQuery } from '../../../graphQL';
import { PathwayStep } from '../PathwayTypes';

type UsePathwayReturn = [
  PathwayStep[],
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const usePathway = (): UsePathwayReturn => {
  const {
    data: pathwayData,
    loading: pathwayLoading,
    error: pathwayError,
    refetch: pathwayRefetch,
  } = useSkillPathwayGetForUserQuery();

  const pathwaySteps = pathwayData?.skillPathwayGetForUser ?? [];

  const moduleIds = uniq(pathwaySteps.map(({ moduleId }) => moduleId));
  const sectionIds = uniq(pathwaySteps.map(({ sectionId }) => sectionId));

  const [modules, moduleState] = useModulesSimpleByIds({ moduleIds });
  const [sections, sectionState] = useSectionsByIds({ sectionIds });

  const refetch = (): void => {
    void pathwayRefetch();

    moduleState.refetch();
    sectionState.refetch();
  };

  const injectedSteps = pathwaySteps.map(step => {
    const module = modules?.find(({ id }) => id === step.moduleId);
    const section = sections?.find(({ id }) => id === step.sectionId);

    return {
      ...step,
      module,
      section,
    };
  });

  return [
    injectedSteps,
    {
      idle: !pathwayLoading && moduleState.idle && sectionState.idle,
      loading: pathwayLoading || moduleState.loading || sectionState.loading,
      loaded: moduleState.loaded && sectionState.loaded,
      error: pathwayError ?? moduleState.error ?? sectionState.error,
      refetch,
    },
  ];
};
