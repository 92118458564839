import { map } from 'lodash';
import { Divider, PresenceTransition, useToast } from 'native-base';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Keyboard } from 'react-native';
import { Button, Heading, Layout } from '../../../../../components/core';
import { FormLike, FormRating, FormTextArea } from '../../../../../components/form';
import { IconMinus } from '../../../../../components/icons/IconMinus';
import { IconPlus } from '../../../../../components/icons/IconPlus';
import { IconStarFilled } from '../../../../../components/icons/IconStarFilled';
import { IconStarOutline } from '../../../../../components/icons/IconStarOutline';
import { IconThumbsDown, IconThumbsUp } from '../../../../../components/icons/IconThumbs';
import { useSkillContext } from '../../../../../contexts';
import {
  useGetUserSkillFeedbackQuery,
  useUpsertUserSkillFeedbackMutation,
} from '../../../../../graphQL';
import { getStylesheet } from '../../../../../styles';
import { SkillRatingValue } from '../../../SkillTypes';
import { SkillContinueButton } from '../../SkillContinueButton';
import type { SliceContext } from '../../skillTypes';

const additionalFeedbackQuestions = [
  { fieldName: 'easyToUnderstand', key: 'easy-to-understand', question: 'Easy to Understand' },
  { fieldName: 'relevantTopic', key: 'relevant-topic', question: 'Relevant topic' },
  { fieldName: 'clearExamples', key: 'clear-examples', question: 'Clear examples' },
  { fieldName: 'goodVideos', key: 'good-videos', question: 'Good videos' },
] as const;

type SkillRatingProps = {
  context: SliceContext;
  hasVideoSlide: boolean;
  skillId: string;
  onContinue?: () => void;
};

type FormValues = {
  rating: SkillRatingValue;
  easyToUnderstand: number;
  relevantTopic: number;
  clearExamples: number;
  goodVideos: number;
  additionalFeedback: string;
};

export const SkillRating = ({
  context,
  hasVideoSlide,
  skillId,
  onContinue,
}: SkillRatingProps): JSX.Element => {
  const { setSkillRating } = useSkillContext();
  const [openAdditionalFeedback, setOpenAdditionalFeedback] = useState(false);
  const onAdditionalFeedbackPressed = (): void =>
    setOpenAdditionalFeedback(currentIsOpen => !currentIsOpen);

  const questions = additionalFeedbackQuestions.filter(({ fieldName }) => {
    if (fieldName !== 'goodVideos') {
      return true;
    }

    return hasVideoSlide;
  });

  const toast = useToast();
  const [submitFeedback] = useUpsertUserSkillFeedbackMutation({
    onError: () => {
      toast.show({
        description: 'Feedback could not be saved',
        duration: 3000,
      });
    },
    onCompleted: ({ upsertUserSkillFeedback }) => {
      if (!upsertUserSkillFeedback) {
        toast.show({
          description: 'Feedback could not be saved',
          duration: 3000,
        });
        return;
      }

      toast.show({
        description: 'Feedback saved',
        duration: 3000,
      });
    },
  });

  const { data: feedbackData, refetch: feedbackRefetch } = useGetUserSkillFeedbackQuery({
    variables: { skillId },
    onCompleted: ({ userSkillFeedback }) => {
      const currentRating = userSkillFeedback?.rating ?? 0;

      let skillRating: SkillRatingValue = 0;
      if (currentRating > 0) {
        skillRating = 1;
      } else if (currentRating < 0) {
        skillRating = -1;
      }

      setValue('rating', skillRating);
    },
  });

  const formContext = useForm<FormValues>({
    defaultValues: {
      rating: 0,
      easyToUnderstand: 0,
      relevantTopic: 0,
      clearExamples: 0,
      goodVideos: 0,
      additionalFeedback: '',
    },
  });

  const { control, handleSubmit, setValue, watch } = formContext;

  const ratingValue = watch('rating');
  const ratingWasChanged =
    ratingValue !== 0 && ratingValue !== feedbackData?.userSkillFeedback?.rating;

  const submitForm = (): void => {
    Keyboard.dismiss();
    // Set the skill rating on the skill context so the upNext slide can use it.
    setSkillRating(ratingValue);

    onContinue?.();

    void handleSubmit(async (values): Promise<void> => {
      if (values.rating === 0 || !ratingWasChanged) {
        return;
      }

      await submitFeedback({
        variables: {
          skillId,
          rating: Number(values.rating),
          additionalFeedback: values.additionalFeedback,
          questions: questions.map(({ fieldName, key }) => ({
            questionSlug: key,
            response: values[fieldName],
          })),
        },
      });

      await feedbackRefetch();
    })();
  };

  const questionSection = (
    <Layout.VStack>
      {map(questions, ({ fieldName, question }) => (
        <Layout.HStack {...styles.starRatingLine} key={fieldName}>
          <Heading.h5 {...styles.starRatingTitle}>{question}</Heading.h5>
          <FormRating
            name={fieldName}
            maxRating={4}
            size="large"
            contextLabel={question}
            iconChecked={<IconStarFilled color="yellow.500" size="6" />}
            iconUnchecked={<IconStarOutline color="secondary.alpha.40:alpha.40" size="6" />}
            flex="1"
            control={control}
          />
        </Layout.HStack>
      ))}
    </Layout.VStack>
  );

  const openAnimation = {
    initial: {
      opacity: 0,
      translateY: 50,
    },
    animate: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 250,
      },
    },
  };

  const additionalFeedbackSection = (
    <Layout.View>
      <Button.tertiaryMedium
        {...styles.additionalFeedbackButton}
        rightIcon={
          openAdditionalFeedback ? (
            <IconMinus size={5} accessibilityHidden />
          ) : (
            <IconPlus size={5} accessibilityHidden />
          )
        }
        testID="button-skill-add-additional-feedback"
        onPress={onAdditionalFeedbackPressed}
      >
        Add additional feedback
      </Button.tertiaryMedium>

      <PresenceTransition visible={openAdditionalFeedback} {...openAnimation}>
        {openAdditionalFeedback && (
          <Layout.View paddingTop={4}>
            <FormTextArea
              name="additionalFeedback"
              label="Please share any additional feedback"
              hideLabel
              placeholder="Please share any additional feedback"
              placeholderTextColor="secondary.700"
              fontSize="md"
              testID="additional-feedback-text-area"
            />
          </Layout.View>
        )}
      </PresenceTransition>
    </Layout.View>
  );

  return (
    <FormProvider {...formContext}>
      <Layout.View {...styles.topWrapper}>
        <Layout.VStack {...styles.formSection} space={6}>
          <Heading.h5 center level={2}>
            Was this skill helpful?
          </Heading.h5>

          <FormLike
            name="rating"
            size="large"
            contextLabel="this skill"
            isDisabled={!context.isVisible}
            button={Button.secondaryMedium}
            buttonSelected={Button.skillMedium}
            iconUp={<IconThumbsUp size="6" accessibilityHidden />}
            iconDown={<IconThumbsDown size="6" accessibilityHidden />}
            control={control}
            space={3}
            flex={1}
          />

          <Layout.VStack space={2}>
            <Divider backgroundColor="secondary.alpha.10:alpha.10" />

            {ratingWasChanged && questionSection}

            {ratingWasChanged && additionalFeedbackSection}

            {ratingWasChanged && <Divider backgroundColor="secondary.alpha.10:alpha.10" />}
          </Layout.VStack>
        </Layout.VStack>

        <SkillContinueButton isVisible={context.isVisible} onContinue={submitForm} />
      </Layout.View>
    </FormProvider>
  );
};

const styles = getStylesheet({
  topWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    width: '100%',
  },

  formSection: {
    marginTop: 2,
    width: '100%',
  },

  starRatingLine: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  starRatingTitle: {
    flex: 1,
  },

  additionalFeeback: {
    overflow: 'hidden',
  },

  additionalFeedbackButton: {
    paddingX: 0,
    paddingBottom: 2,
  },
});
