import { JSX, useMemo, useState } from 'react';
import { Button, Heading, Layout, StatusFeedback, Text } from '../../components/core';
import { IconAlertCircle } from '../../components/icons/IconAlertCircle';
import { PageContent, PageError, PageLoading, PageTopBar } from '../../components/page';
import { useCoachingKickoffLinkQuery, useUpcomingHubAppointmentQuery } from '../../graphQL';
import {
  getRoute,
  useCurrentRouteName,
  useExternalNavigate,
  useLocation,
  useNavigate,
} from '../../routes';
import { useCoachingOnboardRedirectToDashboard } from './hooks/useCoachingOnboardRedirectToDashboard';
import { ImageCoachingGoToDashboard } from './images/ImageCoachingGoToDashboard';
import { ImageCoachingKickoffSchedule } from './images/ImageCoachingKickoffSchedule';

export const CoachingOnboardingPage = (): JSX.Element => {
  const navigate = useNavigate();
  const externalNavigate = useExternalNavigate();
  const currentRouteName = useCurrentRouteName(); // when going to dashboard
  const location = useLocation();
  const { data: coachingKickoffLinkData, ...coachingKickoffLinkState } =
    useCoachingKickoffLinkQuery();
  const { data: upcomingHubAppointmentData, ...upcomingHubAppointmentState } =
    useUpcomingHubAppointmentQuery();

  const [schedulingLinkClicked, setSchedulingLinkClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const showNoKickoffCallError = useMemo(() => {
    return (
      location.state?.from !== 'findCareRecommendation' &&
      !upcomingHubAppointmentData?.upcomingHubAppointment
    );
  }, [location, upcomingHubAppointmentData]);

  // Redirects the user to the coaching home page if they are already in coaching.
  useCoachingOnboardRedirectToDashboard();

  if (coachingKickoffLinkState.loading || upcomingHubAppointmentState.loading) {
    return <PageLoading pageName="coaching kickoff scheduling page" />;
  }

  if (loading) {
    return <PageLoading pageName="verifying appointment and coaching status" />;
  }

  if (
    coachingKickoffLinkState.error !== undefined ||
    upcomingHubAppointmentState.error !== undefined
  ) {
    return <PageError />;
  }

  const schedulingLink = coachingKickoffLinkData?.coachingKickoffLink;

  const onScheduleKickoffPress = (): void => {
    if (schedulingLink !== undefined) {
      externalNavigate(schedulingLink);
      setSchedulingLinkClicked(true);
    }
  };

  const onDashboardPress = async (): Promise<void> => {
    setLoading(true);

    await new Promise(resolve => {
      setTimeout(resolve, 5000);
    });

    navigate(getRoute('coaching', {}), { state: { from: currentRouteName } });
  };

  const onBackPress = (): void => {
    navigate(getRoute('home', {}));
  };

  const schedulingLinkWidget = (
    <Layout.VStack space={6} paddingTop={4}>
      <Layout.Center>
        <ImageCoachingKickoffSchedule />
      </Layout.Center>

      <Heading.h3 center>Schedule your kickoff</Heading.h3>

      <Text.para center>
        Clicking the link below will open a new window, where you can schedule your appointment.
      </Text.para>

      <Button.primaryLarge
        testID="button-to-schedule-kickoff-appointment"
        onPress={onScheduleKickoffPress}
      >
        Go to Scheduling
      </Button.primaryLarge>

      {showNoKickoffCallError && (
        <StatusFeedback.danger testID="status-feedback-no-kickoff" Icon={IconAlertCircle}>
          <Text.paraLarge>
            No kickoff found. Please click the link above to schedule your call.
          </Text.paraLarge>
        </StatusFeedback.danger>
      )}
    </Layout.VStack>
  );

  const schedulingLinkWidgetPressed = (
    <Layout.VStack space={6} paddingTop={4}>
      <Layout.Center>
        <ImageCoachingGoToDashboard />
      </Layout.Center>

      <Heading.h3 center>Let's get started</Heading.h3>

      <Text.para center>
        All set, head on over to your coaching dashboard to meet your coach and get started.
      </Text.para>

      <Button.primaryLarge testID="button-to-coaching-dashboard" onPress={onDashboardPress}>
        Go to Dashboard
      </Button.primaryLarge>
    </Layout.VStack>
  );

  return (
    <PageContent pageSize="medium" testID="coaching-intake-scheduling-page">
      <PageTopBar backAccessibilityLabel="Back to Home" onBackPress={onBackPress} />

      {schedulingLinkClicked ? schedulingLinkWidgetPressed : schedulingLinkWidget}
    </PageContent>
  );
};
