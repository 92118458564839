import { useRef } from 'react';

type UseChatInfiniteLoadProps = {
  fetchOlderMessages: () => void;
};

type UseChatInfiniteLoadReturn = {
  onContentYOffsetUpdate: (newOffset: number) => void;
  onContentHeightUpdate: (newContentHeight: number) => void;
  setViewHeight: (newViewHeight: number) => void;
};

export const useChatInfiniteLoad = ({
  fetchOlderMessages,
}: UseChatInfiniteLoadProps): UseChatInfiniteLoadReturn => {
  // Using useRef here to avoid re-renders when these values update.
  const contentYOffsetRef = useRef(0);
  const scrollViewHeightRef = useRef(0);
  const contentHeightRef = useRef(0);

  const onContentYOffsetUpdate = (newOffset: number): void => {
    const prevOffset = contentYOffsetRef.current;
    contentYOffsetRef.current = newOffset;

    if (newOffset > prevOffset) {
      // User is scrolling down.
      return;
    }

    // User has scrolled up near the top of the content.
    if (newOffset <= 100) {
      fetchOlderMessages();
    }
  };

  const onContentHeightUpdate = (newContentHeight: number): void => {
    contentHeightRef.current = newContentHeight;

    if (newContentHeight <= 0 || scrollViewHeightRef.current <= 0) {
      return;
    }

    // When the content is smaller than the scrollView, load in more messages.
    if (newContentHeight < scrollViewHeightRef.current) {
      fetchOlderMessages();
    }
  };

  const setViewHeight = (newViewHeight: number): void => {
    scrollViewHeightRef.current = newViewHeight;
    onContentHeightUpdate(contentHeightRef.current);
  };

  return {
    onContentHeightUpdate,
    onContentYOffsetUpdate,
    setViewHeight,
  };
};
