import React from 'react';
import { Button, Heading, Layout, StatusFeedback, Text } from '../../components/core';
import { IconAlertCircle } from '../../components/icons/IconAlertCircle';
import { IconAlertTriangle } from '../../components/icons/IconAlertTriangle';
import { IconExternalLink } from '../../components/icons/IconExternalLink';
import { PageContent } from '../../components/page';

type FindCareCrisisSupportPageProps = {
  onContinue: () => void;
  onPress: () => void;
};

export const FindCareCrisisSupportPage = ({
  onContinue,
  onPress,
}: FindCareCrisisSupportPageProps): JSX.Element => {
  return (
    <PageContent>
      <Layout.VStack space={4}>
        <IconAlertTriangle size={6} />
        <Heading.h5>BEFORE CONTINUING...</Heading.h5>
        <Heading.h2 id="find-care-crisis-support-page-header">
          Are you in need of immediate crisis support?
        </Heading.h2>
        <Text.para>
          For your safety, we want to pause this assessment to offer you in-the-moment support. If
          you don't feel this applies to you or your situation, please continue the assessment.
        </Text.para>

        <StatusFeedback.warning
          Icon={IconAlertCircle}
          testID="find-care-crisis-support-assessment-warning"
          vertical
        >
          <Text.para bold>
            Note: Your assessment progress will not be saved if you leave to access on-demand crisis
            resources.
          </Text.para>
        </StatusFeedback.warning>

        <Button.dangerLarge
          testID="button-visit-on-demand-resources"
          onPress={onPress}
          rightIcon={<IconExternalLink />}
        >
          Get support now
        </Button.dangerLarge>

        <Button.secondaryLarge testID="button-continue-assessment" onPress={onContinue}>
          Continue assessment
        </Button.secondaryLarge>
      </Layout.VStack>
    </PageContent>
  );
};
