/* eslint-disable @typescript-eslint/naming-convention */

import { useFocusRing } from '@react-native-aria/focus';
import { useHover } from '@react-native-aria/interactions';
import {
  Actionsheet,
  Box,
  ChevronDownIcon,
  extractInObject,
  FlatList,
  Input,
  mergeRefs,
  Pressable,
  ScrollView,
  stylingProps,
  useControllableState,
  usePropsResolution,
} from 'native-base';
import { useFormControl } from 'native-base/src/components/composites/FormControl';
import { useHasResponsiveProps } from 'native-base/src/hooks/useHasResponsiveProps';
import React, {
  createContext,
  ForwardedRef,
  forwardRef,
  JSX,
  memo,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Keyboard, Platform } from 'react-native';
import type { IButtonProps, ISelectItemProps, ISelectProps } from 'native-base';

type SelectedValue = string | null | undefined;

type SelectContextType = {
  onValueChange: (newValue: SelectedValue) => void;
  selectedValue: SelectedValue;
  _selectedItem: IButtonProps;
  _item: IButtonProps;
};

export { ISelectItemProps, ISelectProps };

export const SelectContext = createContext<SelectContextType>({
  onValueChange: () => undefined,
  selectedValue: undefined,
  _selectedItem: {},
  _item: {},
});

/**
 * Note: The is mostly copy/pasted from the Select component in native-base.
 * @see native-base/src/components/primitives/Select/Select.tsx
 */
const Select = (
  {
    isHovered: isHoveredProp,
    isFocused: isFocusedProp,
    isFocusVisible: isFocusVisibleProp,
    variant,
    ...props
  }: ISelectProps,
  ref: ForwardedRef<unknown>,
): JSX.Element | null => {
  const selectProps = useFormControl({
    isDisabled: props.isDisabled,
    nativeID: props.nativeID,
  });
  const flatListData: ISelectItemProps[] = [];

  const isDisabled = selectProps.disabled;
  const tempFix = '__NativebasePlaceholder__';
  const _ref = useRef(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { focusProps, isFocusVisible } = useFocusRing();
  const { hoverProps, isHovered } = useHover({ isDisabled }, _ref);

  const {
    onValueChange,
    selectedValue,
    children,
    dropdownIcon,
    dropdownCloseIcon,
    dropdownOpenIcon,
    placeholder,
    accessibilityLabel: _accessibilityLabel,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    defaultValue,
    _item,
    _selectedItem,
    onOpen,
    onClose,
    optimized,
    _customDropdownIconProps,
    _actionSheet,
    _actionSheetContent,
    _actionSheetBody,
    _webSelect,
    ...resolvedProps
  } = usePropsResolution(
    'Select',
    props,
    {
      isDisabled,
      isHovered: isHoveredProp === true || isHovered,
      isFocused: isFocusedProp === true || isFocused,
      isFocusVisible: isFocusVisibleProp === true || isFocusVisible,
    },
    undefined,
  );

  const accessibilityLabel = ariaLabel ?? _accessibilityLabel;

  const [value, setValue] = useControllableState({
    value: selectedValue,
    defaultValue,
    onChange: newValue => {
      onValueChange?.(newValue);
      setIsOpen(false);
    },
  });

  const itemsList: Array<{
    label: string;
    value: string;
  }> = React.Children.toArray(children).map(child => {
    if (typeof child === 'string' || typeof child === 'number') {
      return {
        label: String(child),
        value: String(child),
      };
    }

    if (!('props' in child)) {
      return {
        label: 'invalid select child',
        value: 'invalid select child',
      };
    }

    return {
      label: String(child.props.label),
      value: String(child.props.value),
    };
  });

  const selectedItemArray = itemsList.filter(item => item.value === value);

  const selectedItem = selectedItemArray.length ? selectedItemArray[0] : null;

  const contextValue = React.useMemo(() => {
    return {
      onValueChange: setValue,
      selectedValue: value,
      _selectedItem: _selectedItem ?? {},
      _item: _item ?? {},
    };
  }, [value, setValue, _selectedItem, _item]);

  // TODO: refactor for responsive prop
  if (useHasResponsiveProps(props)) {
    return null;
  }

  const rightIcon = useMemo(() => {
    if (isOpen && dropdownOpenIcon !== undefined) {
      return dropdownOpenIcon as JSX.Element;
    }
    if (!isOpen && dropdownCloseIcon !== undefined) {
      return dropdownCloseIcon as JSX.Element;
    }
    if (dropdownIcon !== undefined) {
      return dropdownIcon as JSX.Element;
    }
    return <ChevronDownIcon aria-hidden {..._customDropdownIconProps} />;
  }, [dropdownCloseIcon, dropdownOpenIcon, dropdownIcon, _customDropdownIconProps]);

  const handleClose = (): void => {
    setIsOpen(false);
    onClose?.();
  };

  if (optimized === true) {
    React.Children.toArray(children).forEach(child => {
      if (typeof child === 'string' || typeof child === 'number') {
        return;
      }
      if ('props' in child) {
        flatListData.push(child.props);
      }
    });
  }

  const [layoutProps, nonLayoutProps] = extractInObject(resolvedProps, [
    ...stylingProps.margin,
    ...stylingProps.flexbox,
    ...stylingProps.position,
    'shadow',
    'opacity',
  ]);

  const commonInput = (
    <Input
      placeholder={placeholder}
      InputRightElement={rightIcon}
      {...nonLayoutProps}
      isFocused={isFocused}
      isHovered={isHovered}
      aria-hidden={true}
      importantForAccessibility="no"
      value={selectedItem ? selectedItem.label : ''}
      editable={false}
      focusable={false}
      isDisabled={isDisabled}
      pointerEvents="none"
      variant={variant}
    />
  );

  return Platform.OS === 'android' || Platform.OS === 'ios' ? (
    <>
      <Pressable
        onPress={() => {
          Keyboard.dismiss();
          setIsOpen(true);
          onOpen?.();
        }}
        disabled={isDisabled}
        accessibilityLabel={accessibilityLabel}
        accessibilityRole="button"
        ref={mergeRefs([ref, _ref])}
        {...layoutProps}
      >
        {commonInput}
      </Pressable>

      <Actionsheet isOpen={isOpen} onClose={handleClose} {..._actionSheet}>
        <Actionsheet.Content {..._actionSheetContent}>
          {/* TODO: Replace ScrollVeiw with FlatList */}
          {optimized === true ? (
            <FlatList
              {..._actionSheetBody}
              data={flatListData}
              // eslint-disable-next-line no-shadow
              keyExtractor={(unusedItem, index) => index.toString()}
              renderItem={({ item }: { item: { label: string; value: string } }) => {
                const isSelected = selectedValue === item?.value;
                return (
                  <Actionsheet.Item
                    onPress={() => {
                      if (isDisabled !== true) {
                        setValue(item?.value);
                      }
                    }}
                    accessibilityState={{ selected: isSelected }}
                    {...item}
                    {..._item}
                    {...(isSelected && _selectedItem)}
                  >
                    {item?.label}
                  </Actionsheet.Item>
                );
              }}
            />
          ) : (
            <ScrollView {..._actionSheetBody}>
              <SelectContext.Provider value={contextValue}>{children}</SelectContext.Provider>
            </ScrollView>
          )}
        </Actionsheet.Content>
      </Actionsheet>
    </>
  ) : (
    <Box {...layoutProps}>
      {/* <Box w="100%" h="100%" position="absolute" opacity="0" zIndex={1}> */}
      <select
        aria-readonly={selectProps.readOnly}
        required={selectProps.required}
        disabled={isDisabled}
        {...focusProps}
        {...hoverProps}
        ref={mergeRefs([ref, _ref])}
        onChange={changeEvent => {
          setValue(changeEvent.target.value);
        }}
        value={selectedItem === null ? tempFix : value}
        aria-label={accessibilityLabel}
        aria-labelledby={ariaLabelledBy}
        onFocus={() => {
          setIsFocused(true);
          onOpen?.();
        }}
        onBlur={() => {
          setIsFocused(false);
          onClose?.();
        }}
        {..._webSelect}
      >
        <option disabled value={tempFix}>
          {placeholder}
        </option>

        {children}
      </select>

      {/* </Box> */}
      {commonInput}
    </Box>
  );
};

export const BaseSelect = memo(forwardRef(Select));
