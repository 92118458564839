import { compact, includes } from 'lodash';
import React from 'react';
import { Layout } from '../../../../../components/core';
import { getStylesheet } from '../../../../../styles';
import { SkillBox } from '../../../../skill/SkillBox';
import { SkillDocument } from '../../../../skill/SkillTypes';
import { SectionSkillsSlice } from '../../../SectionTypes';

type SectionSkillsProps = {
  context: {
    moduleId: string;
    skillsById: Record<string, SkillDocument>;
    completedSkillIds: string[];
  };
  slice: SectionSkillsSlice;
};

type SkillLink = Extract<SectionSkillsSlice['items'][number]['skill'], { id: string }>;

export const SectionSkills = ({ context, slice }: SectionSkillsProps): JSX.Element => {
  const skillLinks: SkillLink[] = compact(
    slice.items.map(({ skill }) => {
      return 'id' in skill ? skill : undefined;
    }),
  );

  return (
    <Layout.Flex {...styles.skillsList} testID="section-skills">
      {skillLinks.map(skill => {
        const fullSkill = context.skillsById[skill.id];
        if (!fullSkill) {
          return null;
        }

        const skillIsCompleted = includes(context.completedSkillIds, skill.id);

        return (
          <SkillBox
            key={skill.id}
            flexBasis="45%"
            minWidth="45%"
            moduleId={context.moduleId}
            skill={fullSkill}
            skillIsCompleted={skillIsCompleted}
          />
        );
      })}
    </Layout.Flex>
  );
};

export default SectionSkills;

const styles = getStylesheet({
  skillsList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
    justifyContent: 'space-between',
    width: '100%',
  },
});
