import { usePrismicDocumentByID } from '@prismicio/react';
import { isEmpty } from 'lodash';
import { useReload } from '../../../utils/useReload';
import { SectionDocument } from '../SectionTypes';

type SectionById = [
  SectionDocument | undefined,
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const useSectionById = ({ sectionId }: { sectionId: string }): SectionById => {
  const [shouldSkip, refetch] = useReload();

  const [section, { error, state }] = usePrismicDocumentByID<SectionDocument>(sectionId, {
    skip: shouldSkip || isEmpty(sectionId),
  });

  return [
    section,
    {
      idle: state === 'idle',
      loading: state === 'loading',
      loaded: state === 'loaded',
      error,
      refetch,
    },
  ];
};
