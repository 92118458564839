import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContent } from '../../../components/page';
import { Slides } from '../../../components/slides/Slides';
import { SlidesWrapper } from '../../../components/slides/SlideWrapper';
import { AssessmentForm } from '../../../content/assessment/components/AssessmentForm';
import { AssessmentContinueArgs } from '../../../content/assessment/components/AssessmentSlideTypes';
import { CoachingAssessments, CoachingGoalsAssessmentQuery } from '../../../graphQL';
import { getRoute } from '../../../routes';
import { useCoachingAssessmentContext } from '../contexts/coachingAssessmentContext';
import { useCoachingAssessmentSlideContext } from '../contexts/coachingAssessmentSlideContext';
import { CoachingAssessmentFinalSlide } from './CoachingAssessmentFinalSlide';
import { StartModalProps } from './CoachingAssessmentStartModal';
import { CoachingGoalsStartModal } from './CoachingGoalsStartModal';
import { CoachingWellnessStartModal } from './CoachingWellnessStartModal';

type CoachingAssessmentSlidesProps = {
  assessmentType: CoachingAssessments;
  questionnaires?: CoachingGoalsAssessmentQuery['coachingGoalsAssessment']['questionnaires'];
};

export const CoachingAssessmentSlides = ({
  assessmentType,
  questionnaires,
}: CoachingAssessmentSlidesProps): JSX.Element => {
  const navigate = useNavigate();
  const { setAnswer } = useCoachingAssessmentContext();
  const slideContext = useCoachingAssessmentSlideContext();
  const [showStartModal, setShowStartModal] = useState(true);

  const { questions, slides } = useMemo(() => {
    const flatQuestions = questionnaires
      ?.map(questionnaire => ({
        ...questionnaire,
        questions: questionnaire.questions.map(question => ({
          ...question,
          assessmentKey: questionnaire.key,
        })),
      }))
      .flatMap(questionnaire => questionnaire.questions);

    const forms = flatQuestions?.map(() => {
      return AssessmentForm;
    });

    return { questions: flatQuestions, slides: [...(forms ?? []), CoachingAssessmentFinalSlide] };
  }, [questionnaires]);

  const { nextSlide, registerNavigation, registerSlides, triggerNextSlide } = slideContext;

  useEffect(() => {
    if (slides === undefined) {
      return;
    }

    registerNavigation((newSlideNumber: string): void => {
      navigate(getRoute('coachingAssessment', { assessmentType, slide: newSlideNumber }));
    });

    registerSlides(slides);

    // The slides have loaded so take the user to the first slide.
    // This is to prevent incomplete assessments from being submitted.
    navigate(getRoute('coachingAssessment', { assessmentType, slide: '1' }));
  }, [slides === undefined, assessmentType]);

  const onContinue = ({ question, answer }: AssessmentContinueArgs): void => {
    setAnswer(question.assessmentKey, question.key, answer);

    if (nextSlide) {
      triggerNextSlide();
    }
  };
  const onStartContinue = (): void => {
    setShowStartModal(false);
  };

  const title =
    assessmentType === CoachingAssessments.Goals ||
    assessmentType === CoachingAssessments.GoalsSuccess
      ? 'Setting Goals'
      : 'Wellness Assessment';

  const onClose = (): void => {
    navigate(getRoute('coaching', {}));
  };

  if (questions === undefined) {
    return <></>;
  }

  const StartModal = getStartModal(assessmentType);

  return (
    <PageContent pageSize="medium" flexGrow={1} paddingBottom={0}>
      {showStartModal && <StartModal onNextPress={onStartContinue} onClosePress={onClose} />}

      <SlidesWrapper context={slideContext} onClose={onClose} title={title}>
        <Slides
          context={slideContext}
          assessmentType={assessmentType}
          questions={questions}
          onContinue={onContinue}
        />
      </SlidesWrapper>
    </PageContent>
  );
};

const getStartModal = (
  assessmentType: CoachingAssessments | undefined,
): ((props: StartModalProps) => JSX.Element | null) => {
  if (
    assessmentType === CoachingAssessments.Goals ||
    assessmentType === CoachingAssessments.GoalsSuccess
  ) {
    return CoachingGoalsStartModal;
  }
  if (
    assessmentType === CoachingAssessments.Wellness ||
    assessmentType === CoachingAssessments.WellnessSuccess
  ) {
    return CoachingWellnessStartModal;
  }
  return () => null;
};
