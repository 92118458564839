import { Heading, IHeadingProps } from 'native-base';
import { forwardRef } from 'react';
import * as styles from '../../styles/components/header';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type HeadingRef = HTMLHeadingElement | undefined;

export type HeadingProps = IHeadingProps & {
  level?: HeadingLevel;
  center?: boolean;
  // Only allow index -1 so it can't be focused using tab but can be through element.focus().
  tabIndex?: -1;
};

type HeadingAriaProps = Pick<IHeadingProps, 'role'> & {
  'aria-level'?: HeadingProps['level'];
};

const getLevelAriaProps = (level?: HeadingLevel): HeadingAriaProps => {
  if (level === undefined) {
    return {};
  }

  return {
    'aria-level': level,
    role: 'heading',
  };
};

// Linter disabled because trying to define the return type here is a nightmare, let it infer the type!
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const factory = (textStyle: IHeadingProps) =>
  forwardRef<HeadingRef, HeadingProps>(({ center, children, level, ...props }, ref) => {
    const textAlign = center === true ? 'center' : undefined;

    const ariaProps = getLevelAriaProps(level);

    return (
      <Heading {...textStyle} {...ariaProps} textAlign={textAlign} {...props} ref={ref}>
        {children}
      </Heading>
    );
  });

export const h1Xl = factory(styles.heading1Xl);
export const h1 = factory(styles.heading1);
export const h2 = factory(styles.heading2);
export const h3 = factory(styles.heading3);
export const h4 = factory(styles.heading4);
export const h5 = factory(styles.heading5);
export const h6 = factory(styles.heading6);

export const overline = factory(styles.overline);
export const overlineLarge = factory(styles.overlineLarge);
