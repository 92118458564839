import { some } from 'lodash';
import { CoachingAssessments, useCoachingIntakeProgressV2Query } from '../../../graphQL';

type UseCoachingIntakeProgressReturn = {
  tasks?: Task[];
  hasUncompletedTasks: boolean;
  loading: boolean;
};

type Task = {
  isCompleted: boolean;
  key: CoachingAssessments;
} & TaskCopy;

type TaskCopy = {
  title: string;
  description: string;
  timeToComplete: string;
};

const coachingIntakeTaskCopyMap: Record<CoachingAssessments, TaskCopy> = {
  [CoachingAssessments.Goals]: {
    title: 'Set Your Goals',
    description: 'Set your goals for the program.',
    timeToComplete: '3 min',
  },
  [CoachingAssessments.Wellness]: {
    title: 'Wellness Assessment',
    description: 'Complete your wellness assessment.',
    timeToComplete: '3 min',
  },
  [CoachingAssessments.GoalsSuccess]: {
    title: 'Set Your Goals',
    description: 'Set your goals for the program.',
    timeToComplete: '3 min',
  },
  [CoachingAssessments.WellnessSuccess]: {
    title: 'Wellness Assessment',
    description: 'Complete your wellness assessment.',
    timeToComplete: '3 min',
  },
};

export const useCoachingIntakeProgress = (): UseCoachingIntakeProgressReturn => {
  const { data: coachingIntakeProgressData, loading: coachingIntakeProgressLoading } =
    useCoachingIntakeProgressV2Query();

  if (coachingIntakeProgressLoading || !coachingIntakeProgressData?.coachingIntakeProgressV2) {
    return { loading: true, hasUncompletedTasks: false };
  }

  const { coachingAssessments } = coachingIntakeProgressData.coachingIntakeProgressV2;

  const tasks: Task[] = coachingAssessments.map(coachingAssessment => ({
    ...coachingIntakeTaskCopyMap[coachingAssessment.key],
    key: coachingAssessment.key,
    isCompleted: coachingAssessment.completed,
  }));

  return {
    loading: coachingIntakeProgressLoading,
    hasUncompletedTasks: some(tasks, { isCompleted: false }),
    tasks,
  };
};
