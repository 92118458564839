import { chain, compact, defaultTo, reduce, uniq } from 'lodash';
import { CompletionSlideSliceQuiz } from '../../../../../../.slicemachine/prismicio';
import { Button, Heading, Image, Layout, Text } from '../../../../../components/core';
import { useSkillContext } from '../../../../../contexts';
import { useSkillPathwaySubmitMutation } from '../../../../../graphQL';
import { getRoute, useNavigate } from '../../../../../routes';
import { getStylesheet } from '../../../../../styles';
import { SkillWrapper } from '../../SkillWrapper';
import type { SliceContext } from '../../skillTypes';

type CompletionSlideProps = {
  context: SliceContext;
  slice: CompletionSlideSliceQuiz;
};

export const QuizCompletionSlide = ({ context, slice }: CompletionSlideProps): JSX.Element => {
  const navigate = useNavigate();
  const { ratingsBySlide } = useSkillContext();

  const [submitPathwayAnswers] = useSkillPathwaySubmitMutation();

  const mainItem = slice.primary;
  const imageUrl = defaultTo(mainItem.image?.url, '');
  const title = defaultTo(mainItem.title, '');
  const text = defaultTo(mainItem.text, '');
  const buttonText = defaultTo('button_text' in mainItem ? mainItem.button_text : undefined, '');

  const onQuizFinish = async (): Promise<void> => {
    type RatingScore = {
      sectionId: string;
      moduleId: string;
      score: number;
      nudgeIds: string[];
    };

    const accumulator: Record<string, RatingScore> = {};

    // Calculate final quiz results.
    // First accumulate the total score for each skill/module combo - by summing all their scores.
    const ratingScores = Object.values(
      reduce(
        ratingsBySlide,
        (acc, slide) => {
          const key = `${slide.moduleId}-${slide.sectionId}`;
          const item = acc[key] ?? {
            sectionId: slide.sectionId,
            moduleId: slide.moduleId,
            score: 0,
            nudgeIds: [],
          };

          // Make sure that rating 5s are always at the top of the list.
          const slideScore = slide.rating >= 5 ? 1000 : (slide.rating - 2) * 2;
          item.score += slideScore;

          if (slideScore > 0) {
            item.nudgeIds.push(slide.nudge);
          }

          acc[key] = item;
          return acc;
        },
        accumulator,
      ),
    );

    const finalRatingScores = chain(ratingScores)
      // Make sure the nudges are unique.
      .map(score => ({
        ...score,
        nudgeIds: uniq(compact(score.nudgeIds)),
      }))
      // Remove any steps that score less than 1.
      .filter(({ score }) => {
        return score > 0;
      })
      // Sort the steps by their scores (from highest to lowest).
      .sortBy('score')
      .reverse()
      // Only take the top 5 results (highest scores).
      .take(5)
      .value();

    await submitPathwayAnswers({
      variables: {
        answers: finalRatingScores,
      },
    });

    navigate(getRoute('skillPathway', {}));
  };

  return (
    <SkillWrapper isVisible={context.isVisible} hideContinueButton>
      <Layout.VStack {...styles.topStack} space={4}>
        {imageUrl !== '' && (
          <Image
            {...styles.image}
            alt={mainItem.image.alt ?? 'Image'}
            source={{
              uri: imageUrl,
            }}
            resizeMode="contain"
            resizeMethod="auto"
          />
        )}

        <Layout.VStack space={2} alignItems="center">
          <Heading.h2>{title}</Heading.h2>

          {text !== '' && <Text.para>{text}</Text.para>}
        </Layout.VStack>

        <Button.skillLarge
          {...styles.button}
          aria-label={buttonText}
          isDisabled={!context.isVisible}
          testID="button-skill-complete-quiz"
          onPress={onQuizFinish}
        >
          {buttonText}
        </Button.skillLarge>
      </Layout.VStack>
    </SkillWrapper>
  );
};

const styles = getStylesheet({
  topStack: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },

  image: {
    alignSelf: 'center',
    size: 142,
  },

  button: {
    width: '100%',
  },
});
