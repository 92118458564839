/**
 * COLORS BY NAME
 */

export const blue = {
  50: '#EEF2FF',
  100: '#E0E7FF',
  200: '#C7D2FE',
  300: '#A5B8FC',
  400: '#819DF8',
  500: '#6387F1',
  600: '#2B59E3',
  700: '#1745CF',
  800: '#1931BE',
  900: '#1931BE',
};

export const brown = {
  50: '#E6E1E0',
  100: '#E6E1E0',
  200: '#E6E1E0',
  300: '#BDA6A0',
  400: '#BDA6A0',
  500: '#BDA6A0',
  600: '#745B55',
  700: '#745B55',
  800: '#745B55',
  900: '#745B55',
};

export const green = {
  50: '#F7FAF9',
  100: '#E9F2EA',
  200: '#D4E4D0',
  300: '#B4D1B8',
  400: '#8BB194',
  500: '#699677',
  600: '#567B61',
  700: '#46684F',
  800: '#3B5B42',
  900: '#2D4B32',
};

export const navy = {
  50: '#FBFCFE',
  100: '#F3F5FC',
  200: '#E8ECF7',
  300: '#D4D8E8',
  400: '#A6ACC6',
  500: '#878FB0',
  600: '#636D8C',
  700: '#55607E',
  800: '#485371',
  900: '#35405F',
};

export const orange = {
  50: '#FDE5D8',
  100: '#FDE5D8',
  200: '#FDE5D8',
  300: '#FABFA3',
  400: '#FABFA3',
  500: '#FABFA3',
  600: '#F67E6A',
  700: '#F67E6A',
  800: '#F67E6A',
  900: '#F67E6A',
};

export const purple = {
  50: '#E2E7FE',
  100: '#E2E7FE',
  200: '#E2E7FE',
  300: '#CAD2FA',
  400: '#CAD2FA',
  500: '#CAD2FA',
  600: '#35405F',
  700: '#35405F',
  800: '#35405F',
  900: '#35405F',
};

export const red = {
  50: '#FFF5F5',
  100: '#FFE5E5',
  200: '#FDCECC',
  300: '#FFACAC',
  400: '#F08080',
  500: '#DC5F5F',
  600: '#CB4848',
  700: '#AC3939',
  800: '#973232',
  900: '#842F2F',
};

export const teal = {
  50: '#E2EEEC',
  100: '#E2EEEC',
  200: '#E2EEEC',
  300: '#A2CAC2',
  400: '#A2CAC2',
  500: '#A2CAC2',
  600: '#2D6A62',
  700: '#2D6A62',
  800: '#2D6A62',
  900: '#2D6A62',
};

export const yellow = {
  50: '#FFF8ED',
  100: '#FAEAD3',
  200: '#F1D9B5',
  300: '#E7C797',
  400: '#CFA16C',
  500: '#B58453',
  600: '#A06736',
  700: '#824D20',
  800: '#753F11',
  900: '#6A3406',
};

/**
 * COLORS BY TYPE
 */

export const primary = {
  ...blue,

  alpha: {
    80: blue['600'],
    60: blue['600'],
    40: blue['600'],
    20: blue['600'],
    10: blue['600'],
    5: blue['600'],
  },
};
export const secondary = {
  ...navy,

  alpha: {
    80: navy['900'],
    60: navy['900'],
    40: navy['900'],
    20: navy['900'],
    10: navy['900'],
    5: navy['900'],
  },
};
export const muted = {
  ...secondary,
};

export const warning = {
  ...yellow,

  alpha: {
    80: yellow['400'],
    60: yellow['400'],
    40: yellow['400'],
    20: yellow['400'],
    10: yellow['400'],
    5: yellow['400'],
  },
};
export const success = {
  ...green,

  alpha: {
    80: green['600'],
    60: green['600'],
    40: green['600'],
    20: green['600'],
    10: green['600'],
    5: green['600'],
  },
};
export const danger = {
  ...red,

  alpha: {
    80: red['600'],
    60: red['600'],
    40: red['600'],
    20: red['600'],
    10: red['600'],
    5: red['600'],
  },
};
export const error = {
  ...red,
};
export const text = {
  ...secondary,
  50: '#ffffff',
};

// Special Mantra branding color.
export const mantraNavy = '#1D2744';

export const darkText = text['900'];
export const lightText = text['50'];
