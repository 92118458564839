export const formStyles = {
  FormControlErrorMessage: {
    baseStyle: {
      _text: {
        fontWeight: 'bold',
      },
    },
  },

  FormControlLabel: {
    baseStyle: {
      marginBottom: 2,

      _text: {
        color: 'secondary.900',
        fontSize: 'md',
      },
    },
  },

  Input: {
    baseStyle: {
      backgroundColor: 'white:alpha.60',
      _invalid: {
        borderColor: 'error.600',
      },
      _disabled: {
        color: 'secondary.alpha.60:alpha.60',
        backgroundColor: 'secondary.200',
        borderColor: 'secondary.300',
        opacity: 1,
      },
    },
    defaultProps: {
      size: 'lg',
    },
  },

  Select: {
    baseStyle: {
      backgroundColor: 'white:alpha.60',
      _invalid: {
        borderColor: 'error.600',
      },
    },
  },

  SelectItem: {
    baseStyle: {
      backgroundColor: 'white',
      _text: {
        color: 'primary.300',
      },
      _invalid: {
        borderColor: 'error.600',
      },
    },
  },
};
