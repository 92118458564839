import { StyledProps } from 'native-base';
import { ReactNode } from 'react';
import { Layout } from '../../../components/core';
import { getStylesheet } from '../../../styles';
import { SkillContinueButton } from './SkillContinueButton';

type SkillWrapperProps = StyledProps & {
  center?: boolean;
  children: ReactNode;
  hideContinueButton?: boolean;
  isVisible: boolean;
  onContinue?: () => void;
  video?: boolean;
};

export const SkillWrapper = ({
  center = false,
  children,
  hideContinueButton = false,
  isVisible,
  onContinue,
  video = false,
  ...styleProps
}: SkillWrapperProps): JSX.Element => {
  const videoInnerStyles = video ? styles.videoInner : {};
  const videoButtonWrapperStyles = video ? styles.videoButtonWrapper : {};

  return (
    <Layout.Slide {...styles.inner} {...videoInnerStyles} {...styleProps} center={center}>
      {children}

      <Layout.View {...styles.buttonWrapper} {...videoButtonWrapperStyles}>
        {!hideContinueButton && (
          <SkillContinueButton isVisible={isVisible} onContinue={onContinue} />
        )}
      </Layout.View>
    </Layout.Slide>
  );
};

const styles = getStylesheet({
  inner: {
    maxWidth: 500,
    paddingTop: 16,
    paddingX: 6,
  },

  videoInner: {
    paddingBottom: 2,
    paddingTop: 0,
    paddingX: 0,
  },

  buttonWrapper: {
    maxWidth: 500,
  },

  videoButtonWrapper: {
    paddingX: 4,
  },
});
