import { createIcon, Path } from './index';

export const IconCheck = createIcon({
  title: 'A checkmark',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M20 6L9 17L4 12"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
