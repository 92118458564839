import { useAllPrismicDocumentsByIDs } from '@prismicio/react';
import { isEmpty } from 'lodash';
import { useReload } from '../../../utils/useReload';
import { SectionDocument } from '../SectionTypes';

type SectionById = [
  SectionDocument[] | undefined,
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const useSectionsByIds = ({ sectionIds }: { sectionIds: string[] }): SectionById => {
  const [shouldSkip, refetch] = useReload();

  const [sections, { error, state }] = useAllPrismicDocumentsByIDs<SectionDocument>(sectionIds, {
    skip: isEmpty(sectionIds) || shouldSkip,
  });

  return [
    sections,
    {
      idle: state === 'idle',
      loading: state === 'loading',
      loaded: state === 'loaded',
      error,
      refetch,
    },
  ];
};
