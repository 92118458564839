import { useState } from 'react';

/**
 * Reloads network calls by flipping the returned skip state.
 */
export const useReload = (): [boolean, () => void] => {
  const [shouldSkip, setShouldSkip] = useState(false);

  const reload = (): void => {
    setShouldSkip(current => !current);

    setTimeout(() => {
      setShouldSkip(current => !current);
    }, 25);
  };

  return [shouldSkip, reload];
};
