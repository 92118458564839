import { useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { Button, Heading, Layout, Link, Text } from '../core';
import { IconChevronLeft } from '../icons/IconChevronLeft';
import { IconRefreshCW } from '../icons/IconRefreshCW';
import { ImageErrorTools } from './images/ImageErrorTools';
import { PageContent } from './PageContent';
import { PageFooter } from './PageFooter';

type PageErrorProps = {
  goBackTo?: string;
  onRefreshPress?: () => void;
  showContact?: boolean;
};

export const PageError = ({
  goBackTo,
  onRefreshPress,
  showContact = false,
}: PageErrorProps): JSX.Element => {
  const navigate = useNavigate();

  const onPressGoBack = (): void => {
    if (goBackTo === undefined) {
      return;
    }

    navigate(goBackTo);
  };

  return (
    <PageContent {...styles.page} flex={1} testID="page-error">
      <ImageErrorTools {...styles.image} size={118} />

      <Layout.VStack {...styles.stack} space={8}>
        <Layout.VStack space={4}>
          <Heading.h2 center>Oops... Something went wrong.</Heading.h2>

          <Text.para center>
            This could be an issue with our system or yours. Please refresh the page to try again.
            {showContact && (
              <>
                {' '}
                If this issue persists please contact{' '}
                <Link.paraSmall bold to="mailto:hi@mantrahealth.com">
                  hi@mantrahealth.com
                </Link.paraSmall>
              </>
            )}
          </Text.para>
        </Layout.VStack>

        <Layout.HStack space={4}>
          {goBackTo !== undefined && (
            <Button.secondaryMedium
              accessibilityLabel="Navigate to the previous page."
              leftIcon={<IconChevronLeft size={5} accessibilityHidden />}
              testID="button-cms-error-go-back"
              onPress={onPressGoBack}
            >
              Go Back
            </Button.secondaryMedium>
          )}

          {onRefreshPress && (
            <Button.secondaryMedium
              accessibilityLabel="Reload the current page."
              rightIcon={<IconRefreshCW size={5} accessibilityHidden />}
              testID="button-cms-error-refresh"
              onPress={onRefreshPress}
            >
              Refresh
            </Button.secondaryMedium>
          )}
        </Layout.HStack>
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  page: {
    height: '100%',
    marginTop: 16,
    position: 'relative',
  },

  image: {
    alignSelf: 'center',
    marginBottom: 6,
    marginTop: 16,
  },

  stack: {
    alignItems: 'center',
    display: 'flex',
  },
});
