import Svg, { Path, Rect } from 'react-native-svg';

export const ImageHandAndCheckboxes = (): JSX.Element => {
  return (
    <Svg
      accessibilityElementsHidden
      title="Hand over a list of checkboxes. The first item is checked off."
      viewBox="0 0 342 206"
      height={206}
      width={342}
    >
      <Rect width="342" height="206" rx="8" fill="#C7D2FE" />
      <Path
        d="M273.829 181.229L150.839 233.329C148.299 234.409 145.359 233.219 144.289 230.679L75.3988 68.0488C74.3188 65.5088 75.5087 62.5687 78.0487 61.4987L201.039 9.39875C203.579 8.31875 206.519 9.50877 207.589 12.0488L276.479 174.679C277.559 177.219 276.379 180.149 273.829 181.229Z"
        fill="#FAEAD3"
      />
      <Path
        d="M112.329 102.979C111.949 102.979 111.569 102.909 111.199 102.759C110.459 102.459 109.879 101.889 109.559 101.149L102.349 84.1387C101.709 82.6187 102.419 80.8487 103.939 80.2087L120.949 72.9987C121.689 72.6887 122.498 72.6787 123.248 72.9787C123.988 73.2787 124.569 73.8487 124.889 74.5887L132.099 91.5987C132.739 93.1187 132.028 94.8887 130.508 95.5287L113.498 102.739C113.128 102.899 112.729 102.979 112.329 102.979ZM122.139 74.7587C122.009 74.7587 121.878 74.7887 121.748 74.8387L104.739 82.0487C104.229 82.2587 103.989 82.8487 104.209 83.3587L111.419 100.369C111.519 100.619 111.719 100.809 111.959 100.909C112.209 101.009 112.479 101.009 112.719 100.899L129.729 93.6887C130.239 93.4787 130.478 92.8887 130.258 92.3787L123.049 75.3687C122.949 75.1187 122.748 74.9287 122.508 74.8287C122.388 74.7787 122.269 74.7587 122.139 74.7587Z"
        fill="#1D2744"
      />
      <Path
        d="M129.899 144.449C128.729 144.449 127.619 143.759 127.129 142.619L119.919 125.609C119.279 124.089 119.989 122.319 121.509 121.679L138.519 114.469C140.039 113.829 141.809 114.539 142.449 116.059L149.659 133.069C150.299 134.589 149.589 136.359 148.069 136.999L131.059 144.209C130.689 144.369 130.289 144.449 129.899 144.449ZM139.699 116.229C139.569 116.229 139.439 116.259 139.309 116.309L122.299 123.519C121.789 123.729 121.549 124.319 121.769 124.829L128.979 141.839C129.189 142.349 129.789 142.579 130.289 142.369L147.299 135.159C147.809 134.949 148.049 134.359 147.829 133.849L140.619 116.839C140.469 116.459 140.089 116.229 139.699 116.229Z"
        fill="#1D2744"
      />
      <Path
        d="M147.469 185.919C147.089 185.919 146.709 185.849 146.339 185.699C145.599 185.399 145.019 184.829 144.699 184.089L137.489 167.079C136.849 165.559 137.559 163.789 139.079 163.149L156.089 155.939C157.609 155.299 159.379 156.009 160.019 157.529L167.229 174.539C167.869 176.059 167.159 177.829 165.639 178.469L148.629 185.679C148.259 185.839 147.859 185.919 147.469 185.919ZM157.269 157.689C157.139 157.689 157.009 157.719 156.879 157.769L139.869 164.979C139.359 165.189 139.119 165.779 139.339 166.289L146.549 183.299C146.649 183.549 146.849 183.739 147.089 183.839C147.339 183.939 147.609 183.939 147.859 183.829L164.869 176.619C165.379 176.409 165.619 175.819 165.399 175.309L158.189 158.299C158.029 157.919 157.659 157.689 157.269 157.689Z"
        fill="#1D2744"
      />
      <Path
        d="M135.129 69.1587C147.709 63.8287 160.299 58.4987 172.879 53.1687C174.059 52.6687 173.039 50.9487 171.869 51.4387C159.289 56.7687 146.699 62.0987 134.119 67.4287C132.939 67.9287 133.959 69.6487 135.129 69.1587Z"
        fill="#1D2744"
      />
      <Path
        d="M138.799 77.8087C148.619 73.6487 158.439 69.4887 168.269 65.3287C169.449 64.8287 168.429 63.1087 167.259 63.5987C157.439 67.7587 147.619 71.9187 137.789 76.0787C136.609 76.5787 137.629 78.3087 138.799 77.8087Z"
        fill="#1D2744"
      />
      <Path
        d="M92.4889 73.0887C101.089 79.3987 109.689 85.7087 118.289 92.0187C119.329 92.7787 120.329 91.0488 119.299 90.2888C110.699 83.9788 102.099 77.6688 93.4986 71.3588C92.4586 70.5988 91.4589 72.3387 92.4889 73.0887Z"
        fill="#1D2744"
      />
      <Path
        d="M121.189 66.4287C120.069 74.5787 118.949 82.7387 117.829 90.8887C117.659 92.1487 119.578 92.6888 119.758 91.4188C120.878 83.2688 121.999 75.1087 123.119 66.9587C123.289 65.7087 121.359 65.1587 121.189 66.4287Z"
        fill="#1D2744"
      />
      <Path
        d="M152.699 110.629C165.279 105.299 177.869 99.9687 190.449 94.6387C191.629 94.1387 190.609 92.4187 189.439 92.9087C176.859 98.2387 164.269 103.569 151.689 108.899C150.509 109.399 151.529 111.119 152.699 110.629Z"
        fill="#1D2744"
      />
      <Path
        d="M156.369 119.279C166.189 115.119 176.009 110.959 185.839 106.799C187.019 106.299 185.999 104.579 184.829 105.069C175.009 109.229 165.189 113.389 155.359 117.549C154.179 118.049 155.189 119.769 156.369 119.279Z"
        fill="#1D2744"
      />
      <Path
        d="M170.269 152.099C182.849 146.769 195.439 141.439 208.019 136.109C209.199 135.609 208.179 133.889 207.009 134.379C194.429 139.709 181.839 145.039 169.259 150.369C168.079 150.869 169.099 152.589 170.269 152.099Z"
        fill="#1D2744"
      />
      <Path
        d="M173.929 160.749C183.749 156.589 193.569 152.429 203.399 148.269C204.579 147.769 203.559 146.049 202.389 146.539C192.569 150.699 182.749 154.859 172.919 159.019C171.739 159.519 172.759 161.239 173.929 160.749Z"
        fill="#1D2744"
      />
      <Path
        d="M315.079 116.169C315.079 116.169 318.879 62.5887 304.379 36.4287C289.879 10.2587 256.048 10.0987 231.548 20.4287C215.098 27.3687 197.549 43.2987 191.879 49.1287C186.209 54.9587 185.489 60.8388 188.379 64.7988C191.879 69.5988 202.549 68.8188 206.709 66.5988C206.709 66.5988 194.119 87.5688 195.879 98.0988C196.969 104.619 202.439 106.459 207.629 105.929C207.739 108.069 208.218 110.129 209.268 111.929C214.598 121.099 228.939 119.259 234.099 104.259C235.169 101.149 236.219 98.0687 237.169 95.2487C239.009 95.1287 240.209 95.0988 240.209 95.0988L257.879 162.989L304.379 242.519C304.379 242.519 344.709 228.739 368.079 206.649L315.079 116.169Z"
        fill="#F67E6A"
      />
      <Path
        d="M147.508 91.4087C150.808 90.1087 154.109 88.8087 157.419 87.5187C158.599 87.0487 158.089 85.1187 156.889 85.5887C153.589 86.8887 150.289 88.1887 146.979 89.4787C145.789 89.9487 146.308 91.8887 147.508 91.4087Z"
        fill="#1D2744"
      />
      <Path
        d="M171.999 69.9088L179.329 89.7188L321.659 37.0288C327.129 34.9988 329.919 28.9287 327.899 23.4587C325.869 17.9887 319.799 15.1988 314.329 17.2188L171.999 69.9088Z"
        fill="#1D2744"
      />
      <Path
        d="M171.998 69.9088C171.998 69.9088 147.979 82.6088 150.739 89.3488C153.429 95.9088 179.329 89.7188 179.329 89.7188L171.998 69.9088Z"
        fill="#A2CAC2"
      />
      <Path
        d="M297.946 23.2399L305.279 43.0464L311.741 40.6542L304.408 20.8477L297.946 23.2399Z"
        fill="#A2CAC2"
      />
      <Path
        d="M267.209 45.9287C267.729 47.3287 269.279 48.0487 270.679 47.5287L310.319 32.8487C311.719 32.3287 312.439 30.7787 311.919 29.3787C311.399 27.9787 309.849 27.2587 308.449 27.7787L268.809 42.4587C267.399 42.9687 266.689 44.5187 267.209 45.9287Z"
        fill="#A2CAC2"
      />
      <Path
        d="M257.879 162.989C257.879 162.989 239.209 154.319 231.879 139.209C224.549 124.099 224.519 105.429 224.519 105.429C224.519 105.429 201.549 77.4287 197.879 67.4287C194.209 57.4287 199.099 51.5388 205.209 51.0988C211.319 50.6588 260.549 79.3187 260.549 79.3187L257.879 162.989Z"
        fill="#F67E6A"
      />
      <Path
        d="M198.489 55.2287C206.539 58.4387 216.989 66.2787 225.419 59.8487C226.429 59.0787 225.429 57.3387 224.409 58.1187C216.639 64.0487 206.489 56.2688 199.019 53.2988C197.829 52.8288 197.309 54.7587 198.489 55.2287Z"
        fill="#1D2744"
      />
      <Path
        d="M239.028 67.1588C246.438 70.9188 253.698 74.9388 260.828 79.2088C261.938 79.8688 262.939 78.1488 261.839 77.4788C254.719 73.1988 247.448 69.1887 240.038 65.4287C238.888 64.8487 237.878 66.5788 239.028 67.1588Z"
        fill="#1D2744"
      />
      <Path
        d="M239.309 46.5187C240.169 43.0787 241.039 39.6487 241.899 36.2087C242.209 34.9587 240.289 34.4287 239.969 35.6787C239.109 39.1187 238.239 42.5487 237.379 45.9887C237.069 47.2387 238.989 47.7687 239.309 46.5187Z"
        fill="#1D2744"
      />
      <Path
        d="M208.489 106.739C212.619 105.249 216.269 103.289 219.239 99.9987C220.099 99.0487 218.689 97.6288 217.829 98.5888C215.079 101.639 211.789 103.439 207.959 104.819C206.749 105.239 207.269 107.179 208.489 106.739Z"
        fill="#1D2744"
      />
      <Path
        d="M203.869 81.5488C210.599 90.5988 217.589 99.4688 224.619 108.279C225.419 109.289 226.829 107.859 226.029 106.869C219.099 98.1887 212.219 89.4588 205.589 80.5388C204.839 79.5188 203.109 80.5188 203.869 81.5488Z"
        fill="#1D2744"
      />
      <Path
        d="M196.739 60.2687C197.259 50.9487 207.479 50.3688 213.819 54.1488C214.929 54.8088 215.939 53.0788 214.829 52.4188C210.849 50.0488 205.869 48.6988 201.439 50.5988C197.409 52.3288 194.979 55.9187 194.739 60.2587C194.669 61.5487 196.669 61.5487 196.739 60.2687Z"
        fill="#1D2744"
      />
      <Path
        d="M224.219 107.429C224.219 109.989 224.219 112.539 224.219 115.099C224.219 116.389 226.219 116.389 226.219 115.099C226.219 112.539 226.219 109.989 226.219 107.429C226.219 106.139 224.219 106.139 224.219 107.429Z"
        fill="#1D2744"
      />
    </Svg>
  );
};
