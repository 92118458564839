import { ReactNode } from 'react';
import { useScrollContext } from '../../contexts/scrollContext';
import { FullStyledProps, getStylesheet } from '../../styles/stylesheet';
import { Layout } from '../core';
import { APP_PAGE_MAX_WIDTHS } from './app/appSizes';

type LayoutCoreProps = {
  children: ReactNode;
  outerStyles?: FullStyledProps;
  pageSize?: keyof typeof APP_PAGE_MAX_WIDTHS;
  scrollStyles?: FullStyledProps;
};

export const LayoutCore = ({
  children,
  outerStyles = {},
  pageSize = 'medium',
  scrollStyles = {},
}: LayoutCoreProps): JSX.Element => {
  const { scrollRef } = useScrollContext();

  const maxWidthPx = APP_PAGE_MAX_WIDTHS[pageSize];

  return (
    <Layout.ScrollView
      _contentContainerStyle={{ ...styles.scroll, ...scrollStyles }}
      overScrollMode="never"
      ref={scrollRef}
      role="main"
      style={{ flex: 6 }}
      testID="layout-core-scroll"
    >
      <Layout.Flex {...styles.outer} {...outerStyles} testID="layout-core-outer">
        <Layout.View
          {...styles.inner}
          maxWidth={`${maxWidthPx}px`}
          safeArea
          testID="layout-core-inner"
        >
          {children}
        </Layout.View>
      </Layout.Flex>
    </Layout.ScrollView>
  );
};

export const LayoutCoreStatic = ({
  children,
  outerStyles = {},
  pageSize = 'medium',
}: LayoutCoreProps): JSX.Element => {
  const maxWidthPx = APP_PAGE_MAX_WIDTHS[pageSize];

  return (
    <Layout.View {...styles.outer} {...outerStyles} testID="layout-core-outer">
      <Layout.View
        {...styles.inner}
        {...styles.innerStatic}
        maxWidth={`${maxWidthPx}px`}
        role="main"
        safeArea
        testID="layout-core-inner"
      >
        {children}
      </Layout.View>
    </Layout.View>
  );
};

const styles = getStylesheet({
  scroll: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-start',
  },

  outer: {
    alignItems: 'center',
    flex: 6,
    width: '100%',
  },

  inner: {
    height: '100%',
    paddingX: 4,
    width: '100%',
  },

  innerStatic: {
    overflowY: 'hidden',
  },
});
