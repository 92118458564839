import { format, isSameYear, isToday, differenceInDays } from 'date-fns';
import { defaultTo, map, size } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { Layout, Text } from '../../../components/core';
import { ScrollContextType } from '../../../contexts/scrollContext';
import { CoachChatMessage } from '../../../graphQL';
import { Setter } from '../../../types/reactTypes';
import { usePrevious } from '../../../utils/usePrevious';
import { useChatInfiniteLoad } from '../hooks/useChatInfiniteLoad';
import { UseChatMessagesReturn } from '../hooks/useChatMessages';
import { ChatBubble } from './ChatBubble';

type ScrollProps = Pick<ScrollContextType, 'scrollRef' | 'scrollTo' | 'scrollToEnd'>;
type ChatProps = ScrollProps &
  UseChatMessagesReturn & {
    coachingActive: boolean;
    coachName: string;
  };

type ChatMessageProps = Pick<
  UseChatMessagesReturn,
  'chatState' | 'groupedMessages' | 'isBeginningOfChat'
> & {
  coachingActive: boolean;
  coachName: string;
};

export const CoachChat = ({
  coachingActive,
  coachName,
  fetchOlderMessages,
  groupedMessages,
  isBeginningOfChat,
  lastMessageId,
  scrollRef,
  scrollTo,
  scrollToEnd,
  chatState,
}: ChatProps): JSX.Element => {
  const [expandMessageId, setExpandMessageId] = useState(lastMessageId);
  const [contentHeight, setContentHeight] = useState(0);
  const prevContentHeight = usePrevious(contentHeight) ?? 0;

  const { onContentHeightUpdate, onContentYOffsetUpdate, setViewHeight } = useChatInfiniteLoad({
    fetchOlderMessages,
  });

  useEffect(() => {
    setExpandMessageId(lastMessageId);
  }, [lastMessageId]);

  useEffect(() => {
    // Maintains the current content position when more content is loaded in.
    // Prevents the user from jumping around.
    const diff = contentHeight - prevContentHeight;
    scrollTo({ yCoordinate: diff, animated: false });
  }, [contentHeight]);

  return (
    <Layout.ScrollView
      ref={scrollRef}
      flexBasis={0}
      contentContainerStyle={{ minHeight: '100%', justifyContent: 'flex-end' }}
      onScroll={({ nativeEvent }) => {
        onContentYOffsetUpdate(nativeEvent.contentOffset.y);
      }}
      onLayout={layoutEvent => {
        setViewHeight(layoutEvent.nativeEvent.layout.height);
        scrollToEnd();
      }}
      scrollEventThrottle={150}
      maintainVisibleContentPosition={{ minIndexForVisible: 100 }}
    >
      <Layout.VStack
        onLayout={layoutEvent => {
          setContentHeight(layoutEvent.nativeEvent.layout.height);
          onContentHeightUpdate(layoutEvent.nativeEvent.layout.height);
        }}
      >
        <MemoizedCoachChatMessages
          coachingActive={coachingActive}
          coachName={coachName}
          chatState={chatState}
          expandMessageId={expandMessageId}
          groupedMessages={groupedMessages}
          isBeginningOfChat={isBeginningOfChat}
          setExpandMessageId={setExpandMessageId}
        />
      </Layout.VStack>
    </Layout.ScrollView>
  );
};

const CoachChatMessages = ({
  coachingActive,
  coachName,
  chatState,
  expandMessageId,
  groupedMessages,
  isBeginningOfChat,
  setExpandMessageId,
}: ChatMessageProps & {
  expandMessageId: string | undefined;
  setExpandMessageId: Setter<string | undefined>;
}): JSX.Element => {
  const coachFirstName = coachName.split(' ')[0];

  return (
    <Layout.VStack alignItems="center" space={4}>
      {size(groupedMessages) === 0 && (
        <ChatBubble
          content={`Welcome! This is your chat with your coach, ${coachName}${
            coachName.endsWith('.') ? '' : '.'
          } You can say hi before your kickoff call.`}
          isUser={false}
          systemGenerated
        />
      )}

      {isBeginningOfChat && (
        <ChatBubble
          content={`This is the beginning of your chat with your coach, ${coachName}${
            coachName.endsWith('.') ? '' : '.'
          }`}
          isUser={false}
          systemGenerated
        />
      )}

      {chatState.loadingOlder && (
        <ChatBubble content="Loading older messages..." isUser={false} systemGenerated />
      )}

      {map(groupedMessages, (messages, dayISO) => (
        <Layout.VStack width="100%" space={2} key={dayISO}>
          <Text.caption bold alignSelf="center">
            {getDateLabel(messages[0]?.createdAt)}
          </Text.caption>

          {messages.map(message => {
            return (
              <Layout.VStack
                alignItems={message.author === 'patient' ? 'flex-end' : 'flex-start'}
                space={1}
                key={message.id}
              >
                <ChatBubble
                  key={message.id}
                  content={defaultTo(message.content, '')}
                  isUser={message.author === 'patient'}
                  onMessageClick={() => setExpandMessageId(message.id)}
                  systemGenerated={false}
                />

                {expandMessageId === message.id && (
                  <Text.caption color="secondary.700">{messageTime(message)}</Text.caption>
                )}
              </Layout.VStack>
            );
          })}
        </Layout.VStack>
      ))}

      {!coachingActive && (
        <ChatBubble
          content={`Your coaching program has ended. You can no longer message ${
            coachFirstName ?? 'your coach'
          }.`}
          isUser={false}
          systemGenerated
        />
      )}

      {chatState.subscriptionError && (
        <ChatBubble
          content={`There was an error connecting to the server. Please press to refresh.`}
          isUser={false}
          systemGenerated
          onMessageClick={() => chatState.refetch()}
        />
      )}
    </Layout.VStack>
  );
};

const MemoizedCoachChatMessages = memo(CoachChatMessages);

const messageTime = (message: CoachChatMessage): string => {
  // 12:00 am
  return format(new Date(message.createdAt), 'h:mm aaa');
};

const getDateLabel = (date: string | undefined): string => {
  if (date === undefined) return '';

  const now = new Date();
  const messageDate = new Date(date);
  const isWithinWeek = differenceInDays(now, messageDate) <= 6;

  let formatPattern;

  if (isToday(messageDate)) {
    // 12:00 am
    formatPattern = 'h:mm aaa';
  } else if (isWithinWeek) {
    // Monday • 12:00 am
    formatPattern = 'EEEE • h:mm aaa';
  } else if (isSameYear(now, messageDate)) {
    // Monday, January 1st • 12:00 AM
    formatPattern = 'EEEE, MMMM d • h:mm aaa';
  } else {
    // January 1st, 2021 • 12:00 AM
    formatPattern = 'MMMM d, y • h:mm aaa';
  }

  return format(messageDate, formatPattern);
};
