import { getRoute, RouteKeys, useParams } from '../../routes';
import type { FindCareAssessmentKey } from './FindCareAssessmentsPage';

type Params = ReturnType<typeof useParams<Record<string, string | undefined>>>;

type FindCareRoute<Name extends string> = {
  name: Name;
  isMatch: (routeName: RouteKeys, params: Params) => boolean;
  getNavRoute: () => string;
  pageCount: number;
};

const generateRoute = <Name extends string>({
  pageName,
  routeName,
}: {
  pageName: Name;
  routeName: Extract<
    RouteKeys,
    | 'findCareConfirmInfo'
    | 'findCareDemographics'
    | 'findCarePreTriage'
    | 'findCarePreQuantitative'
    | 'findCareReviewAnswers'
  >;
}): FindCareRoute<Name> => {
  return {
    name: pageName,
    isMatch: (currentRouteName: RouteKeys) => routeName === currentRouteName,
    getNavRoute: () => getRoute(routeName, {}),
    pageCount: 1,
  };
};

const generateAssessmentRoute = <Name extends string>({
  pageName,
  pageCount,
  assessmentType,
}: {
  pageName: Name;
  pageCount: number;
  assessmentType: FindCareAssessmentKey;
}): FindCareRoute<Name> => {
  const routeName = 'findCareAssessments';

  return {
    name: pageName,
    isMatch: (currentRouteName: RouteKeys, currentParams: Params) =>
      currentRouteName === routeName && currentParams.assessmentType === assessmentType,
    getNavRoute: () => getRoute(routeName, { assessmentType, slide: String(pageCount) }),
    pageCount,
  };
};

const findCareRoutes = [
  generateRoute({
    pageName: 'Confirm Info',
    routeName: 'findCareConfirmInfo',
  }),
  generateRoute({
    pageName: 'Demographics',
    routeName: 'findCareDemographics',
  }),
  generateRoute({
    pageName: 'Pre Triage',
    routeName: 'findCarePreTriage',
  }),
  generateAssessmentRoute({
    pageName: 'Assessment - Mental Health History',
    assessmentType: 'mental-health-history',
    pageCount: 7,
  }),
  generateRoute({
    pageName: 'Pre Quantitative',
    routeName: 'findCarePreQuantitative',
  }),
  generateAssessmentRoute({
    pageName: 'Assessment - PHQ',
    assessmentType: 'phq',
    pageCount: 9,
  }),
  generateAssessmentRoute({
    pageName: 'Assessment - GAD',
    assessmentType: 'gad',
    pageCount: 8,
  }),
  generateRoute({
    pageName: 'Review Answers Final Page',
    routeName: 'findCareReviewAnswers',
  }),
] as const;

export const findCareTotalPages = findCareRoutes.reduce((acc, route) => acc + route.pageCount, 0);

export const getFindCareCurrentPageIndex = (routeName: RouteKeys, params: Params): number =>
  findCareRoutes.findIndex(({ isMatch }) => isMatch(routeName, params));

export const getFindCarePreviousNavRoute = (currentPageIndex: number): string | undefined => {
  const previousPage = findCareRoutes[currentPageIndex - 1];
  if (previousPage === undefined) {
    return undefined;
  }

  return previousPage.getNavRoute();
};

export const getFindCarePreviousPageCount = (currentPageIndex: number): number => {
  return findCareRoutes.reduce((acc, route, index) => {
    if (index < currentPageIndex) {
      return acc + route.pageCount;
    }
    return acc;
  }, 0);
};
