import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useHeapUserInfoLazyQuery } from '../graphQL';
import { getHeap, isHeapActive } from '../plugins/heap';
import { useIsCurrentRoutePublic, useLocation } from '../routes';
import { Setter } from '../types/reactTypes';

type HeapContextType = {
  isHeapUserRegistered: boolean;
  setIsHeapUserRegistered: Setter<boolean>;
  trackEvent: (eventName: string, eventData?: Record<string, unknown>) => void;
};

export const HeapContext = createContext<HeapContextType>({
  isHeapUserRegistered: false,
  setIsHeapUserRegistered: () => undefined,
  trackEvent: () => undefined,
});

export function HeapProvider({ children }: { children: ReactNode }): JSX.Element {
  const [isHeapUserRegistered, setIsHeapUserRegistered] = useState(false);

  const location = useLocation();
  const isPublicPage = useIsCurrentRoutePublic();
  const [queryHeapUser] = useHeapUserInfoLazyQuery();

  const registerHubUser = async (): Promise<void> => {
    if (!isHeapActive()) {
      setIsHeapUserRegistered(true);
      return;
    }

    const { data } = await queryHeapUser();

    const user = data?.onboardingHubUser;
    if (user === undefined) {
      return;
    }

    const heap = getHeap();
    if (heap === undefined) {
      return;
    }

    heap.identify(user.uid);
    heap.addUserProperties({ organizationId: user.organization?.id });

    setIsHeapUserRegistered(true);
  };

  const trackEvent = (eventName: string, eventData?: Record<string, unknown>): void => {
    getHeap()?.track(eventName, eventData);
  };

  useEffect(() => {
    // Log to heap every time the url changes.
    if (Platform.OS !== 'web') {
      trackEvent('Mobile Pageview', {
        Path: location.pathname,
        Query: location.search,
      });
    }

    // As soon as a non-public page is reached, register the logged-in user with heap.
    if (!isHeapUserRegistered && !isPublicPage) {
      // eslint-disable-next-line no-console
      registerHubUser().catch(console.error);
    }
  }, [location]);

  const HeapProviderValue: HeapContextType = {
    isHeapUserRegistered,
    setIsHeapUserRegistered,
    trackEvent,
  };

  return <HeapContext.Provider value={HeapProviderValue}>{children}</HeapContext.Provider>;
}

export const useHeapContext = (): HeapContextType => {
  return useContext(HeapContext);
};
