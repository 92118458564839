import { range } from 'lodash';
import { FormControl, IIconButtonProps } from 'native-base';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Button, Layout } from '../core';
import { FormScrollingProps } from './hooks/useFormScrolling';

type Size = 'small' | 'medium' | 'large';

type FormRatingProps<TFieldValues extends FieldValues> = IIconButtonProps &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> & {
    contextLabel: string;
    preventUnrate?: boolean;
    iconChecked: JSX.Element;
    iconUnchecked: JSX.Element;
    label?: string;
    maxRating: number;
    size?: Size;
  };

const getButtonComponent = (size: Size): Button.ButtonComponent => {
  if (size === 'small') {
    return Button.tertiarySmall;
  }
  if (size === 'large') {
    return Button.tertiaryLarge;
  }
  return Button.tertiaryMedium;
};

export function FormRating<TFieldValues extends FieldValues>({
  contextLabel,
  control,
  iconChecked,
  iconUnchecked,
  maxRating,
  name,
  onLayout,
  preventUnrate = false,
  label = '',
  size = 'medium',
  ...iconButtonProps
}: FormRatingProps<TFieldValues>): JSX.Element {
  const ButtonComponent = getButtonComponent(size);

  return (
    <FormControl flex={1} onLayout={onLayout}>
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          const onPress = (rating: number) => () => {
            if (preventUnrate) {
              onChange(rating);
              return;
            }

            onChange(value === rating ? 0 : rating);
          };

          return (
            <Layout.HStack>
              {range(1, maxRating + 1).map(rating => (
                <ButtonComponent
                  {...iconButtonProps}
                  accessibilityLabel={`Rate ${contextLabel} with ${rating} stars.`}
                  key={rating}
                  leftIcon={value >= rating ? iconChecked : iconUnchecked}
                  paddingX={1}
                  testID={`button-form-rating-${rating}`}
                  onPress={onPress(rating)}
                />
              ))}
            </Layout.HStack>
          );
        }}
      />
    </FormControl>
  );
}
