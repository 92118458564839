import { usePrismicDocumentsByTag } from '@prismicio/react';
import { useReload } from '../../../utils/useReload';
import { SkillDocument } from '../SkillTypes';

type SkillResponse = [
  SkillDocument | undefined,
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

const skillTagsByName = {
  tour: 'TourSkill',
  selfCareQuiz: 'SelfCareQuiz',
} as const;

type SkillByTagOptions = {
  skip?: boolean;
};

export const useSkillByTag = (
  tagName: keyof typeof skillTagsByName,
  skillByTag: SkillByTagOptions = {},
): SkillResponse => {
  const [shouldSkip, refetch] = useReload();

  const [skill, { error: skillError, state: skillState }] = usePrismicDocumentsByTag<SkillDocument>(
    skillTagsByName[tagName],
    {
      skip: shouldSkip || skillByTag?.skip === true,
    },
  );

  const firstSkill = skill?.results[0];

  return [
    firstSkill,
    {
      idle: skillState === 'idle',
      loading: skillState === 'loading',
      loaded: skillState === 'loaded',
      error: skillError,
      refetch,
    },
  ];
};
