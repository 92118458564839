import { range } from 'lodash';
import { ButtonProps } from './Button';
import { Button, Layout } from './index';
import type { IHStackProps } from '../../types/nativeBaseTypes';

type PaginationProps = IHStackProps & {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
};

export const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  ...stackProps
}: PaginationProps): JSX.Element | null => {
  if (totalPages <= 1) {
    return null;
  }

  const getPageButtonStyles = (pageNumber: number): ButtonProps => {
    if (pageNumber === currentPage) {
      return {
        disabled: true,
        _text: {
          color: 'primary.500',
        },
      };
    }

    return {};
  };

  const onPrevPress = (): void => {
    onPageChange(Math.min(1, currentPage - 1));
  };
  const onPagePress = (newPage: number) => (): void => {
    onPageChange(newPage);
  };
  const onNextPress = (): void => {
    onPageChange(Math.min(totalPages, currentPage + 1));
  };

  return (
    <Layout.HStack {...stackProps} space={1}>
      <Button.tertiaryMedium
        key="page-prev"
        isDisabled={currentPage <= 1}
        onPress={onPrevPress}
        testID="button-pagination-previous-page"
      >
        Prev
      </Button.tertiaryMedium>

      {range(1, totalPages + 1).map(pageNumber => {
        const buttonStyles = getPageButtonStyles(pageNumber);

        return (
          <Button.tertiaryMedium
            key={`page-${pageNumber}`}
            {...buttonStyles}
            color="primary.200"
            onPress={onPagePress(pageNumber)}
            testID={`button-pagination-page-${pageNumber}`}
          >
            {pageNumber}
          </Button.tertiaryMedium>
        );
      })}

      <Button.tertiaryMedium
        key="page-next"
        isDisabled={currentPage >= totalPages}
        onPress={onNextPress}
        testID="button-pagination-next-page"
      >
        Next
      </Button.tertiaryMedium>
    </Layout.HStack>
  );
};
