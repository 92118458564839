import { trim } from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { useSetPreferredNameMutation } from '../../../graphQL';
import { getStylesheet } from '../../../styles';

type NameFormProps = {
  defaultUserName: string;
  onSaved: () => void;
};

export const NameForm = ({ defaultUserName, onSaved }: NameFormProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      name: defaultUserName,
      submit: undefined,
    },
  });

  const [setName, { loading }] = useSetPreferredNameMutation({
    onCompleted: (): void => {
      onSaved();
    },
    onError: saveNameError => {
      formContext.setError('submit', saveNameError);
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  // Disable the update button until name is edited.
  const nameValue = watch('name');

  const submitForm = (): void => {
    clearErrors();

    void handleSubmit(async ({ name }): Promise<void> => {
      await setName({
        variables: {
          preferredName: trim(name),
        },
      });
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <FormInput
          label="What should we call you?"
          helperText="Not required to be your legal name"
          name="name"
          placeholder="Name"
          autoCapitalize="none"
          control={control}
          error={errors.name}
          maxLength={100}
          necessityIndicator
          isRequired
          onSubmitEditing={nameValue !== defaultUserName ? submitForm : undefined}
        />

        {errors.submit && <FormErrorSubmit>{errors.submit.message}</FormErrorSubmit>}

        <Button.primaryMedium
          {...styles.submitButton}
          isDisabled={nameValue === defaultUserName}
          isLoading={loading}
          testID="button-edit-name-submit"
          onPress={submitForm}
        >
          Update
        </Button.primaryMedium>
      </Layout.VStack>
    </FormProvider>
  );
};

const styles = getStylesheet({
  submitButton: {
    alignSelf: 'flex-start',
    maxWidth: '90px',
    width: '100%',
  },
});
