// This file is generated by Slice Machine. Update with care!

import IntroViewSkills from './IntroViewSkills';
import ViewModules from './ViewModules';

export { IntroViewSkills, ViewModules };

export const components = {
  intro_view_skills: IntroViewSkills,
  view_modules: ViewModules,
};
