import { JSX } from 'react';
import { Link, Text } from '../../../components/core';

export const ErrorAssistance = (): JSX.Element => (
  <Text.para>
    If you need further assistance or believe this is an error,{' '}
    <Link.para bold to="mailto:hi@mantrahealth.com">
      please contact us
    </Link.para>
    .
  </Text.para>
);
