import { useAllPrismicDocumentsByIDs, usePrismicDocumentByID } from '@prismicio/react';
import { compact, isEmpty } from 'lodash';
import {
  useGetAllCompletedSkillsForModuleQuery,
  useGetUpNextSkillForModuleQuery,
} from '../../../graphQL';
import { useReload } from '../../../utils/useReload';
import { SectionDocument } from '../../section/SectionTypes';
import { SkillDocument } from '../../skill/SkillTypes';
import { ModuleDocument } from '../ModuleTypes';

type ModuleById = [
  {
    module: ModuleDocument | undefined;
    sectionsById: Record<string, SectionDocument>;
    skillsById: Record<string, SkillDocument>;
    completedSkillIds: string[];
    upNextSkill: SkillDocument | undefined;
    moduleIsComplete: boolean;
  },
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const useModuleById = ({ moduleId }: { moduleId: string }): ModuleById => {
  const [shouldSkip, refetch] = useReload();

  const [module, { error: moduleError, state: moduleState }] =
    usePrismicDocumentByID<ModuleDocument>(moduleId, {
      skip: shouldSkip,
    });

  const sectionIds = compact(
    module?.data.slices.flatMap(slice =>
      slice.items.map(({ section }) => ('id' in section ? section.id : undefined)),
    ),
  );

  const [sections, { error: sectionError, state: sectionState }] =
    useAllPrismicDocumentsByIDs<SectionDocument>(sectionIds, {
      skip: shouldSkip || isEmpty(sectionIds),
    });

  const emptySectionsById: Record<string, SectionDocument> = {};
  const sectionsById =
    sections?.reduce((acc, section) => {
      acc[section.id] = section;
      return acc;
    }, emptySectionsById) ?? emptySectionsById;

  const skillIds = compact(
    sections?.flatMap(section =>
      section.data.slices.flatMap(slice =>
        slice.items.map(item => ('id' in item.skill ? item.skill.id : undefined)),
      ),
    ),
  );

  const [skills, { error: skillError, state: skillState }] =
    useAllPrismicDocumentsByIDs<SkillDocument>(skillIds ?? [], {
      skip: shouldSkip || isEmpty(skillIds),
    });

  const emptySkillsById: Record<string, SkillDocument> = {};
  const skillsById =
    skills?.reduce((acc, skill) => {
      acc[skill.id] = skill;
      return acc;
    }, emptySkillsById) ?? emptySkillsById;

  const {
    data: upNextData,
    loading: upNextLoading,
    error: upNextError,
  } = useGetUpNextSkillForModuleQuery({
    variables: {
      moduleId,
    },
    skip: shouldSkip || isEmpty(skills),
  });

  const upNextSkill = upNextData?.getUpNextSkillForModule.upNextSkill as SkillDocument | undefined;
  const moduleIsComplete = upNextData?.getUpNextSkillForModule.moduleIsComplete ?? false;

  const {
    data: completedSkillsData,
    loading: completedLoading,
    error: completedError,
  } = useGetAllCompletedSkillsForModuleQuery({
    variables: { moduleId },
    skip: shouldSkip || isEmpty(skills),
  });

  const completedSkillIds = completedSkillsData?.getAllCompletedSkillsForModule ?? [];

  return [
    { module, sectionsById, skillsById, completedSkillIds, upNextSkill, moduleIsComplete },
    {
      idle: moduleState === 'idle' && sectionState === 'idle' && skillState === 'idle',
      loading:
        moduleState === 'loading' ||
        sectionState === 'loading' ||
        skillState === 'loading' ||
        completedLoading ||
        upNextLoading,
      loaded:
        moduleState === 'loaded' &&
        moduleState === 'loaded' &&
        skillState === 'loaded' &&
        !completedLoading &&
        !upNextLoading,
      error: moduleError ?? sectionError ?? skillError ?? completedError ?? upNextError,
      refetch,
    },
  ];
};
