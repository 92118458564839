import { trim } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { Masks } from 'react-native-mask-input';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput, FormInputMasked } from '../../../components/form';
import { convertDateForDisplay, DATE_REGEX } from '../../../utils/date';
import { FormTerms } from '../components/FormTerms';
import { useOnboardUser } from '../hooks/useOnboardUser';

type OnboardingFormProps = {
  defaultUserName: string;
  currentBirthDate?: string;
  onConsentBlocked: () => void;
  onSaved: () => void;
};

export const OnboardingForm = ({
  defaultUserName,
  onConsentBlocked,
  onSaved,
  currentBirthDate,
}: OnboardingFormProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      name: defaultUserName,
      birthDate: convertDateForDisplay(currentBirthDate) ?? '',
      terms: false,
      submit: undefined,
    },
  });

  const hasPrefilledBirthDate = Boolean(currentBirthDate);

  const [onboardUser, { loading }] = useOnboardUser({
    onConsentBlocked: (): void => {
      onConsentBlocked();
    },
    onCompleted: (): void => {
      onSaved();
    },
    onError: saveNameError => {
      formContext.setError('submit', saveNameError);
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(({ name, birthDate }): void => {
      void onboardUser(trim(name), birthDate);
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6} width="100%">
        <FormInput
          label="What should we call you?"
          helperText="Not required to be your legal name"
          name="name"
          placeholder="Name"
          autoCapitalize="none"
          control={control}
          error={errors.name}
          maxLength={100}
          necessityIndicator
          isRequired
        />

        <FormInputMasked
          label="Date of Birth"
          mask={Masks.DATE_MMDDYYYY}
          name="birthDate"
          placeholder="MM/DD/YYYY"
          autoCapitalize="none"
          control={control}
          error={errors.birthDate}
          isDisabled={hasPrefilledBirthDate}
          isRequired
          necessityIndicator
          rules={{
            pattern: {
              value: DATE_REGEX,
              message: 'You must enter a valid date MM/DD/YYYY.',
            },
          }}
        />

        <FormTerms name="terms" control={control} errors={errors} />

        {errors.submit && <FormErrorSubmit>{errors.submit.message}</FormErrorSubmit>}

        <Button.primaryMedium
          isLoading={loading}
          testID="button-onboarding-submit"
          onPress={submitForm}
        >
          Continue
        </Button.primaryMedium>
      </Layout.VStack>
    </FormProvider>
  );
};
