import { useState } from 'react';
import { Button, Heading, Layout, Pagination, Skeleton, Text } from '../../../components/core';
import { IconBook } from '../../../components/icons/IconBook';
import { SkillBox } from '../../../content/skill/SkillBox';
import { getRoute, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { useAssignedSkills } from '../hooks/useAssignedSkills';

export const AssignedSkills = (): JSX.Element => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [assignedSkills, { error, loading }] = useAssignedSkills({ currentPage });

  if (loading) {
    return <Skeleton height={110} />;
  }

  const onReflectionsPress = (): void => {
    navigate(getRoute('reflections', {}));
  };

  return (
    <Layout.Flex>
      <Layout.HStack alignItems="center">
        <Layout.VStack flex={1} space={1}>
          <Heading.h4>Your Skills</Heading.h4>
        </Layout.VStack>

        <Button.tertiarySmall
          testID="button-coaching-skills-to-reflections"
          leftIcon={<IconBook accessibilityHidden />}
          onPress={onReflectionsPress}
        >
          Reflections
        </Button.tertiarySmall>
      </Layout.HStack>

      <Layout.VStack {...styles.topWrapper}>
        {error !== undefined && assignedSkills.length === 0 && (
          <Layout.VStack {...styles.noEntries} space={6}>
            <Layout.VStack space={2}>
              <Heading.h4 center>Could not load skill assignments.</Heading.h4>
            </Layout.VStack>
          </Layout.VStack>
        )}

        {assignedSkills.length === 0 && (
          <Layout.VStack {...styles.noEntries} space={6}>
            <Layout.VStack space={2}>
              <Heading.h4 center>No skills assigned</Heading.h4>

              <Text.para center>Your coach will assign skills as the program progresses </Text.para>
            </Layout.VStack>
          </Layout.VStack>
        )}

        {assignedSkills.length > 0 && (
          <Layout.VStack space={2} width="100%">
            {assignedSkills.map(assignedSkill => (
              <SkillBox
                key={`${assignedSkill.moduleId}-${assignedSkill.skill.id}`}
                moduleId={assignedSkill.moduleId}
                skill={assignedSkill.skill}
                horizontal
                skillIsCompleted={assignedSkill.completedAt != null}
              />
            ))}
          </Layout.VStack>
        )}
      </Layout.VStack>

      <Pagination
        {...styles.pagination}
        currentPage={1}
        totalPages={1}
        onPageChange={setCurrentPage}
      />
    </Layout.Flex>
  );
};

const styles = getStylesheet({
  topWrapper: {
    alignItems: 'center',
  },

  noEntries: {
    backgroundColor: 'secondary.200',
    padding: 6,
    width: '100%',
  },

  skill: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingX: 4,
    paddingY: 8,
  },

  pagination: {
    alignSelf: 'center',
  },
});
