import { NativeBaseProvider as BaseNativeBaseProvider } from 'native-base';
import { ReactNode } from 'react';
import { NativeBaseSetup } from '../components/plugin/NativeBaseSetup';
import { theme } from '../styles/theme';

export const NativeBaseProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <BaseNativeBaseProvider theme={theme}>
      <NativeBaseSetup />

      {children}
    </BaseNativeBaseProvider>
  );
};
