import { PresenceTransition } from 'native-base';
import { JSX, ReactNode, useEffect, useRef } from 'react';
import { Dimensions } from 'react-native';
import { Heading } from '../../../components/core';
import { getStylesheet } from '../../../styles';

type SkillTransitionProps = {
  back?: boolean;
  children: ReactNode;
  slideCount: number;
  slideNumber: number;
  visible?: boolean;
};

export const SkillTransition = ({
  children,
  slideCount,
  slideNumber,
  back = false,
  visible = false,
}: SkillTransitionProps): JSX.Element => {
  const headingRef = useRef<Heading.HeadingRef>();

  const enterInitial = { opacity: 0, translateX: !back ? -500 : 500 } as const;
  const exitInitial = { opacity: 0, translateX: !back ? 500 : -500 } as const;

  const animation = { opacity: 1, translateX: 0, transition: { duration: 300 } } as const;

  useEffect(() => {
    if (visible) {
      headingRef.current?.focus?.();
    }
  }, [visible]);

  const windowHeight = Dimensions.get('window').height;
  const style = {
    maxHeight: Math.round(windowHeight) - 73, // 73px is the height of the skill header.
    height: '100%',
    position: visible ? undefined : 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: visible ? 1 : 0,
  } as const;

  const headingId = `skill-slide-${slideNumber}-heading`;

  return (
    <PresenceTransition
      aria-labelledby={headingId}
      aria-hidden={!visible}
      role="group"
      visible={visible}
      initial={visible ? exitInitial : enterInitial}
      animate={animation}
      style={style}
      {...(visible ? {} : { tabIndex: -1 })}
    >
      <Heading.h6
        {...styles.visuallyHidden}
        id={headingId}
        level={2}
        ref={headingRef}
        tabIndex={-1}
      >
        {`Slide ${slideNumber} of ${slideCount}`}
      </Heading.h6>

      {children}
    </PresenceTransition>
  );
};

const styles = getStylesheet({
  visuallyHidden: {
    opacity: 0,
    height: 0,
  },
});
