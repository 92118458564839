import { OptionsWithTZ } from 'date-fns-tz';
import { getCalendars } from 'expo-localization';
import { Platform } from 'react-native';

export const DATE_REGEX =
  /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2[0-9]|3[01])\/(19[0-9]{2}|20[0-1][0-9]|202[0-3])$/;

export const convertDateForDisplay = (date?: string | null): string => {
  if (date === null || date === undefined) return '';

  const dateArray = date.split('-');
  const year = dateArray[0] ?? '';
  const month = dateArray[1] ?? '';
  const day = dateArray[2] ?? '';

  return `${month}/${day}/${year}`;
};

export const getTimezone = (): string => {
  const calendar = getCalendars();
  // if it can't find the user's timezone on mobile (for whatever reason) just put in America/New_York (ET) as the default
  return calendar[0]?.timeZone ?? 'America/New_York';
};

export const getFormatTzOptions = (): OptionsWithTZ => {
  if (Platform.OS === 'web') {
    return {};
  }

  return {
    timeZone: getTimezone(),
  };
};
