import { fromPairs } from 'lodash';
import { createContext, JSX, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { PageLoading } from '../components/page';
import {
  ActivePortals,
  AllowedPortal,
  castToRule,
  Portal,
  Portals,
} from '../content/portal/portals';
import { useGetPortalsQuery, usePortalUpdatesSubscription } from '../graphQL';
import { useIsCurrentRoutePublic } from '../routes';
import { isInvalidAuthentication, isSiteBlocked } from '../utils/graphql';
import type { AppErrorLink } from '../plugins/apolloSetup';

export type OnlyIfPortalIsActive = <A, F>(portal: AllowedPortal, allowed: A, fallback: F) => A | F;
export type PortalSwitch = <A, F>(portalAndTo: Array<[AllowedPortal, A]>, fallback: F) => A | F;

export type Block =
  | 'locationRestricted'
  | 'ageTooYoung'
  | 'ageConsentRequired'
  | 'ageTermsRequired';

type PortalContextType = {
  activeBlock: Block | undefined;
  activePortals: ActivePortals;
  onlyIfPortalIsActive: OnlyIfPortalIsActive;
  portalsAreLoading: boolean;
  portalSwitch: PortalSwitch;
  refetchPortals: () => void;
  removeBlock: (props: { isLogout: boolean }) => Promise<void>;
};

const PortalContext = createContext<PortalContextType>({
  activeBlock: undefined,
  activePortals: {},
  onlyIfPortalIsActive: <A, F>(portal: AllowedPortal, allowed: A, fallback: F) => fallback,
  portalsAreLoading: true,
  portalSwitch: <A, F>(portalAndTo: Array<[AllowedPortal, A]>, fallback: F) => fallback,
  refetchPortals: () => undefined,
  removeBlock: async () => Promise.resolve(undefined),
});

export const PortalConsumer = PortalContext.Consumer;

const getActivePortalsFromList = (portalsList: Portal[]): ActivePortals =>
  fromPairs(portalsList.map(activePortal => [activePortal, true]));

export const PortalProvider = ({
  children,
  errorLink,
}: {
  children: ReactNode;
  errorLink: AppErrorLink;
}): JSX.Element => {
  const defaultPortals = {
    [Portals.Public]: true,
  };

  const [
    activePortals,
    _setActivePortals, // eslint-disable-line @typescript-eslint/naming-convention
  ] = useState<ActivePortals>(defaultPortals);

  /**
   * Sets the active portals, making sure public is always set and hub is optionally set.
   */
  const setActivePortals = (portals: ActivePortals, addHub: boolean): void => {
    // Don't let portals be set without the public portal.
    _setActivePortals({
      ...portals,
      ...(addHub ? { [Portals.Hub]: true } : {}),
      [Portals.Public]: true,
    });
  };

  const [
    activeBlock,
    _setActiveBlock, // eslint-disable-line @typescript-eslint/naming-convention
  ] = useState<PortalContextType['activeBlock']>(undefined);

  /**
   * Sets the active block and makes sure the portals are also set correctly.
   */
  const setActiveBlock = (block: Block): void => {
    _setActiveBlock(block);

    setActivePortals(
      {
        [Portals.Blocked]: true,
        [Portals.Public]: true,
      },
      false,
    );
  };

  // Use this to determine whether or load the active portals.
  // Make sure that when the user logs in, the active portals are loaded.
  const isPublicRoute = useIsCurrentRoutePublic();

  const { loading: portalsAreLoading, refetch: refetchPortalsQuery } = useGetPortalsQuery({
    skip: isPublicRoute, // Load the user's portals after the user is logged in.
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getPortals }) => {
      if (getPortals === undefined) {
        setActivePortals({}, true);
        return;
      }

      setActivePortals(getActivePortalsFromList(getPortals), true);
    },
    onError: portalError => {
      if (isSiteBlocked(portalError)) {
        // This case is handled in the AppErrorLink.
        // It will call the blockedHandler which will set the active block.
        return;
      }

      const userIsNotLoggedIn = isInvalidAuthentication(portalError);

      // Reset the portals to the default portals.
      setActivePortals({}, !userIsNotLoggedIn);
    },
  });

  usePortalUpdatesSubscription({
    skip: isPublicRoute, // Only subscribe when the user is logged in.
    onData: ({ data: { data } }) => {
      const newActivePortals = data?.portalUpdates;
      if (newActivePortals === undefined) {
        return;
      }

      setActivePortals(getActivePortalsFromList(newActivePortals), true);
    },
  });

  useEffect(() => {
    errorLink.setBlockedHandler((block: Block): void => {
      setActiveBlock(block);
    });
  }, []);

  const refetchPortals = (): void => {
    void refetchPortalsQuery();
  };

  /**
   * Removes the active block and refreshes the user's portal.
   */
  const removeBlock = async ({ isLogout = false } = {}): Promise<void> => {
    if (isLogout) {
      // Just reset the logged out portals. We don't want to call the API as that will
      // return an auth error and cause a redirect we don't want.
      setActivePortals({}, false);
    } else {
      await refetchPortalsQuery();
    }

    _setActiveBlock(undefined);
  };

  const onlyIfPortalIsActive = useCallback(
    <A, F>(allowedPortals: AllowedPortal, allowed: A, fallback: F): A | F => {
      const isAllowed = castToRule(allowedPortals).check(activePortals);
      return isAllowed ? allowed : fallback;
    },
    [activePortals],
  );

  const portalSwitch = useCallback(
    <A, F>(portalAndTo: Array<[AllowedPortal, A]>, fallback: F): A | F => {
      const foundAllowed = portalAndTo.find(([allowedPortals]) =>
        castToRule(allowedPortals).check(activePortals),
      );

      if (foundAllowed === undefined) {
        return fallback;
      }

      return foundAllowed[1];
    },
    [activePortals],
  );

  const providerValue: PortalContextType = {
    activeBlock,
    activePortals,
    onlyIfPortalIsActive,
    portalsAreLoading,
    portalSwitch,
    refetchPortals,
    removeBlock,
  };

  return (
    <PortalContext.Provider value={providerValue}>
      {portalsAreLoading ? <PageLoading pageName="app" /> : children}
    </PortalContext.Provider>
  );
};

export const usePortalContext = (): PortalContextType => {
  return useContext(PortalContext);
};
