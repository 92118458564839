import { createIcon, Path } from './index';

export const IconMantraLogoReversed = createIcon({
  title: 'Mantra Health logo',
  viewBox: '0 0 20 16',
  path: [
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0758 6.43551C10.7015 6.43551 11.3132 6.24679 11.8335 5.89322C12.3538 5.53965 12.7593 5.03711 12.9988 4.44914C13.2383 3.86117 13.3009 3.21419 13.1789 2.59C13.0568 1.96582 12.7554 1.39247 12.313 0.94246C11.8705 0.492449 11.3067 0.185988 10.693 0.0618297C10.0793 -0.0623281 9.44311 0.00139421 8.86499 0.244939C8.28686 0.488483 7.79273 0.900911 7.44508 1.43007C7.09742 1.95922 6.91187 2.58135 6.91187 3.21776C6.91187 4.07116 7.2452 4.88961 7.83855 5.49305C8.43189 6.0965 9.23664 6.43551 10.0758 6.43551Z"
      fill="#FFFFFF"
    />,
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.8688 16L0 14.1063L4.41744 9.62985C4.72411 9.3184 5.13339 9.13358 5.56621 9.11109C5.99904 9.0886 6.4247 9.23003 6.76099 9.50807L10.0469 12.2143L13.2864 9.55163C13.6227 9.27423 14.0481 9.1333 14.4806 9.15604C14.913 9.17877 15.3219 9.36354 15.6283 9.67473L20 14.1063L18.1309 16L14.3274 12.145L11.1495 14.7568C10.8366 15.0121 10.4474 15.1513 10.0462 15.1513C9.64505 15.1513 9.25586 15.0121 8.94294 14.7568L5.71901 12.1008L1.8688 16Z"
      fill="#6387F1"
    />,
  ],
});
