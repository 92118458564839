import { PresenceTransition, StyledProps } from 'native-base';
import { Button, Heading, Layout, Text } from '../../../../../components/core';
import { IconAlertTriangle } from '../../../../../components/icons/IconAlertTriangle';
import { IconX } from '../../../../../components/icons/IconX';
import { getStylesheet } from '../../../../../styles';

type ContentWarningProps = StyledProps & {
  label: string;
  message: string;
  show: boolean;
  onHide: () => void;
};

export const ContentWarning = ({
  label,
  message,
  show,
  onHide,
  ...topStyles
}: ContentWarningProps): JSX.Element => {
  if (message === '') {
    return <></>;
  }

  const animation = {
    initial: {
      opacity: 0,
      translateY: -100,
    },
    animate: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 500,
      },
    },
  };

  return (
    <Layout.View {...topStyles}>
      <PresenceTransition visible={show} {...animation}>
        <Layout.HStack {...styles.topStack}>
          <Layout.View {...styles.leftPanel}>
            <IconAlertTriangle color="red.600" size={6} />
          </Layout.View>

          <Layout.VStack {...styles.centerPanel} space={2}>
            <Heading.h5>{label}</Heading.h5>

            <Text.para>{message}</Text.para>
          </Layout.VStack>

          <Layout.View>
            <Button.tertiaryMedium
              leftIcon={<IconX size={4} />}
              testID="button-skill-video-warning-close"
              onPress={onHide}
            />
          </Layout.View>
        </Layout.HStack>
      </PresenceTransition>
    </Layout.View>
  );
};

const styles = getStylesheet({
  topStack: {
    backgroundColor: 'secondary.50',
    borderRadius: 4,
    justifyContent: 'space-between',
    shadow: 'md',
  },

  leftPanel: {
    backgroundColor: 'red.200',
    borderRadius: 4,
    justifyContent: 'center',
    padding: 4,
  },

  centerPanel: {
    flex: 1,
    paddingLeft: 4,
    paddingY: 4,
  },
});
