import { pick } from 'lodash';
import { appendSearchToRoute, useCurrentRouteSearch } from '../../../routes';
import { getRedirectUrl } from '../../../utils/authenticationStore';
import { parseMrnIntoRoute } from '../../../utils/mrn';
import { useSmartNavigate } from '../../../utils/useSmartNavigate';

type LoginRedirect = () => Promise<void>;

// A list of search params that should be preserved (passed through) during the redirect.
// These are preserved for marketing purposes.
const searchPassthrough = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];

export const useLoginRedirect = (): LoginRedirect => {
  const navigate = useSmartNavigate();
  const searchParams = useCurrentRouteSearch();

  /**
   * Redirects the user after login.
   */
  return async (): Promise<void> => {
    const storedRedirectUrl = await getRedirectUrl();
    const mrn = searchParams.mrn ?? '';

    let { to: redirectUrl, toOptions } = parseMrnIntoRoute(mrn);

    if (mrn === '' && storedRedirectUrl.trim() !== '') {
      // If no MRN was provided, the use any locally stored redirect route.
      redirectUrl = storedRedirectUrl;
    }

    // Gets a list of search parameters that should be preserved during the redirect.
    const toSearch = pick(searchParams, searchPassthrough);

    navigate(appendSearchToRoute(redirectUrl, toSearch), { ...toOptions, replace: true });
  };
};
