import { useAllPrismicDocumentsByIDs } from '@prismicio/react';
import { chain, compact, defaultTo, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { usePortalContext } from '../../../contexts/portalContext';
import { Portals } from '../../portal/portals';
import { usePrismicAggregateByTags } from './usePrismicAggregateByTags';
import type { ViewDocumentDataSlicesSlice } from '../../../../.slicemachine/prismicio';
import type { ModuleDocument } from '../../module/ModuleTypes';

type ViewResponse = [
  {
    aggregatedSlice: ViewDocumentDataSlicesSlice | undefined;
    modulesById: Record<string, ModuleDocument>;
    viewData?: { description: string; title: string };
  },
  {
    loading: boolean;
    error?: Error;
  },
];

const onShowExecFunc =
  <Tag extends string>(tag: Tag) =>
  ({ showExecFunc }: { showExecFunc: boolean }) =>
    showExecFunc ? tag : undefined;

const viewTagByType = {
  collections: [() => 'CollectionView', onShowExecFunc('ExecFuncCollectionView')],
  topics: [() => 'TopicView', onShowExecFunc('ExecFuncTopicView')],
} as const;

export const useViewByType = (viewName: keyof typeof viewTagByType): ViewResponse => {
  const { onlyIfPortalIsActive } = usePortalContext();

  const showExecFunc = onlyIfPortalIsActive(Portals.ExecutiveFunctioning, true, false);

  const tags = useMemo(() => {
    return compact(viewTagByType[viewName].map(tagItem => tagItem({ showExecFunc })));
  }, [showExecFunc]);

  const [documentsByTag, byTagState] = usePrismicAggregateByTags(tags);

  const { mainView, aggregatedSlice, moduleIds } = useMemo(() => {
    // The first view acts as the main display for the view, for instance, we use its title.
    const firstTag = tags[0] ?? '';
    const firstView = documentsByTag[firstTag]?.[0];

    const orderedDocuments = chain(tags)
      .map(tag => documentsByTag[tag]?.find(item => item))
      .compact()
      .value();

    const orderedSlices = chain(orderedDocuments)
      .flatMap(({ data }) => data.slices)
      .value();

    const moduleIdList = chain(orderedDocuments)
      .flatMap(document => {
        return document.data.slices.flatMap(slice =>
          slice.items.map(({ module }) => ('id' in module ? module.id : undefined)),
        );
      })
      .compact()
      .uniq()
      .value();

    const finalSliceItems = chain(orderedSlices)
      .flatMap(({ items }) => items)
      .value();

    const finalSlice = chain(orderedSlices)
      .first()
      .thru(slice => {
        if (slice === undefined) {
          return slice;
        }

        slice.items = finalSliceItems;
        return slice;
      })
      .value();

    return { mainView: firstView, aggregatedSlice: finalSlice, moduleIds: moduleIdList };
  }, [documentsByTag]);

  const [modules, getModuleState] = useAllPrismicDocumentsByIDs<ModuleDocument>(moduleIds, {
    skip: isEmpty(moduleIds),
  });

  const emptyModulesById: Record<string, ModuleDocument> = {};
  const modulesById =
    modules?.reduce((acc, module) => {
      acc[module.id] = module;
      return acc;
    }, emptyModulesById) ?? emptyModulesById;

  const title = defaultTo(mainView?.data.title, '');
  const description = defaultTo(mainView?.data.description, '');

  const viewData = mainView?.data ? { title, description } : undefined;

  return [
    { aggregatedSlice, modulesById, viewData },
    {
      loading: byTagState.loading || getModuleState.state === 'loading',
      error: byTagState.error ?? getModuleState.error,
    },
  ];
};
