import { JSX } from 'react';
import { Heading, Layout, Text } from '../../../components/core';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';
import { getStylesheet } from '../../../styles';

/**
 * This is just a cover our bases in case a bad block type is set.
 * Ideally this would never be seen.
 */
export const GenericBlocked = (): JSX.Element => {
  return (
    <Layout.VStack space={8}>
      <Layout.VStack {...styles.textStack} space={4}>
        <IconAlertTriangle alignSelf="center" color="danger.600" size={7} />

        <Heading.h3>You are not allowed to access Mantra Health</Heading.h3>

        <Text.para>Please contact your school’s counseling center for support.</Text.para>
      </Layout.VStack>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  textStack: {
    alignItems: 'stretch',
  },
});
