import {
  AppLayout,
  SimpleHeaderLayout,
  SimpleLayout,
  SimpleLayoutStatic,
  SkillLayout,
} from './components/layout';
import { TherapyProvider } from './contexts';
import { AccountSettingsPage } from './pages/accountSettings/AccountSettingsPage';
import { ActivationResendPage } from './pages/authentication/ActivationResendPage';
import { AuthenticationSsoResponsePage } from './pages/authentication/AuthenticationSsoResponsePage';
import { LoginDeepLinkPage } from './pages/authentication/LoginDeepLinkPage';
import { LoginPage } from './pages/authentication/LoginPage';
import { NewPasswordPage } from './pages/authentication/NewPasswordPage';
import { ResetPasswordPage } from './pages/authentication/ResetPasswordPage';
import { SignupFinishPage } from './pages/authentication/SignupFinishPage';
import { SignupPage } from './pages/authentication/SignupPage';
import { CampusResourcesPage } from './pages/campusResources/CampusResourcesPage';
import { CoachingHomePage } from './pages/coaching/CoachingHomePage';
import { CoachingOnboardingPage } from './pages/coaching/CoachingOnboardingPage';
import { CoachingProvider } from './pages/coaching/context/coachingContext';
import { CoachingAssessmentPage } from './pages/coachingAssessment/CoachingAssessmentPage';
import { CoachingChatPage } from './pages/coachingChat/CoachingChatPage';
import { ConnectNowConfirmInfoPage } from './pages/connectNow/ConnectNowConfirmInfoPage';
import { ConnectNowJoinCallPage } from './pages/connectNow/ConnectNowJoinCallPage';
import { ConnectNowLandingPage } from './pages/connectNow/ConnectNowLandingPage';
import { ConnectNowPreEncounterPage } from './pages/connectNow/ConnectNowPreEncounterPage';
import { CrisisResourcesPage } from './pages/crisisResources/CrisisResourcesPage';
import {
  FindCareAssessmentsPage,
  FindCareConfirmInfoPage,
  FindCareDemographicsPage,
  FindCarePreQuantitativePage,
  FindCarePreTriagePage,
  FindCareReviewAnswersPage,
  FindCareStartCarePage,
} from './pages/findCare';
import { FindCareProvider } from './pages/findCare/context/findCareContext';
import { HomePage } from './pages/home/HomePage';
import { LogoutPage } from './pages/logout/LogoutPage';
import { ModulePage } from './pages/module/ModulePage';
import { MrnPage } from './pages/mrn/MrnPage';
import { NotFoundPage } from './pages/notFound/NotFoundPage';
import { OnboardingPage } from './pages/onboarding/OnboardingPage';
import { OnboardingPhoneNumberPage } from './pages/onboarding/OnboardingPhoneNumberPage';
import { OnDemandConfirmInfoPage } from './pages/onDemand/OnDemandConfirmInfoPage';
import { OnDemandJoinCallPage } from './pages/onDemand/OnDemandJoinCallPage';
import { OnDemandLandingPage } from './pages/onDemand/OnDemandLandingPage';
import { onDemandPreEncounterPage } from './pages/onDemand/OnDemandPreEncounterPage';
import { PatientPortalPage } from './pages/portal/PatientPortalPage';
import { PatientPortalRedirectPage } from './pages/portal/PatientPortalRedirectPage';
import { PortalForwardPage } from './pages/portal/PortalForwardPage';
import { RecommendationPage } from './pages/recommendation/RecommendationPage';
import { ReflectionPage } from './pages/reflection/ReflectionPage';
import { ReflectionsPage } from './pages/reflections/ReflectionsPage';
import { RescheduleAppointmentPage } from './pages/reschedule/RescheduleAppointmentPage';
import { SelfCareHomePage } from './pages/selfCareHome/SelfCareHomePage';
import { SelfCareQuizPage } from './pages/selfCareQuiz/SelfCareQuizPage';
import { SkillPage } from './pages/skill/SkillPage';
import { SkillPathwayPage } from './pages/skillPathway/SkillPathwayPage';
import { SkillPathwaySectionPage } from './pages/skillPathwaySection/SkillPathwaySectionPage';
import { StartPage } from './pages/start/StartPage';
import { TherapyEligibilityPage } from './pages/therapy/TherapyEligibilityPage';
import { TherapyPage } from './pages/therapy/TherapyPage';
import { TourPage } from './pages/tour/TourPage';
import { useAddRoute } from './router/hooks/useAddRoute';
import { routes, Routes, useDeepLinking } from './routes';

export const AppRoutes = (): JSX.Element => {
  useDeepLinking();

  const addRoute = useAddRoute();

  return (
    <Routes>
      {/* Start Page - Must be the first route */}
      {addRoute(routes.start, StartPage, SimpleLayout)}

      {/* Signup and Onboarding Pages */}
      {addRoute(routes.login, LoginPage, SimpleLayout)}
      {addRoute(routes.loginActivationResend, ActivationResendPage, SimpleHeaderLayout)}
      {addRoute(routes.loginDeepLink, LoginDeepLinkPage, SimpleHeaderLayout)}
      {addRoute(routes.loginFromPortal, AuthenticationSsoResponsePage, SimpleHeaderLayout)}
      {addRoute(routes.loginNewPassword, NewPasswordPage, SimpleHeaderLayout)}
      {addRoute(routes.loginResetPassword, ResetPasswordPage, SimpleHeaderLayout)}
      {addRoute(routes.loginSsoResponse, AuthenticationSsoResponsePage, SimpleHeaderLayout)}
      {addRoute(routes.logout, LogoutPage, SimpleHeaderLayout)}
      {addRoute(routes.onboarding, OnboardingPage, SimpleLayout)}
      {addRoute(routes.onboardingPhoneNumber, OnboardingPhoneNumberPage, SimpleHeaderLayout)}
      {addRoute(routes.signup, SignupPage, SimpleHeaderLayout)}
      {addRoute(routes.signupFinish, SignupFinishPage, SimpleHeaderLayout)}

      {/* General Portal Pages */}
      {addRoute(routes.accountSettings, AccountSettingsPage, AppLayout)}
      {addRoute(routes.campusResources, CampusResourcesPage, AppLayout)}
      {addRoute(routes.crisisResources, CrisisResourcesPage, AppLayout)}
      {addRoute(routes.forwardOnPortal, PortalForwardPage, SimpleHeaderLayout)}
      {addRoute(routes.home, HomePage, AppLayout)}
      {addRoute(routes.mrn, MrnPage, SimpleHeaderLayout)}
      {addRoute(routes.mrnExternal, MrnPage, SimpleHeaderLayout)}
      {addRoute(routes.reschedule, RescheduleAppointmentPage, AppLayout)}

      {/* Find Care Pages */}
      {addRoute(routes.findCareAssessments, FindCareAssessmentsPage, SimpleLayout, {
        Wrapper: FindCareProvider,
      })}
      {addRoute(routes.findCareConfirmInfo, FindCareConfirmInfoPage, SimpleLayout, {
        Wrapper: FindCareProvider,
      })}
      {addRoute(routes.findCareDemographics, FindCareDemographicsPage, SimpleLayout, {
        Wrapper: FindCareProvider,
      })}
      {addRoute(routes.findCarePreQuantitative, FindCarePreQuantitativePage, SimpleLayout, {
        Wrapper: FindCareProvider,
      })}
      {addRoute(routes.findCarePreTriage, FindCarePreTriagePage, SimpleLayout, {
        Wrapper: FindCareProvider,
      })}
      {addRoute(routes.findCareStartCare, FindCareStartCarePage, SimpleLayout, {
        Wrapper: FindCareProvider,
      })}
      {addRoute(routes.findCareReviewAnswers, FindCareReviewAnswersPage, SimpleLayout, {
        Wrapper: FindCareProvider,
      })}
      {addRoute(routes.findCareRecommendation, RecommendationPage, AppLayout)}

      {/* Coaching Portal Pages */}
      {addRoute(routes.coaching, CoachingHomePage, AppLayout, {
        Wrapper: CoachingProvider,
      })}
      {addRoute(routes.coachingAssessment, CoachingAssessmentPage, SimpleLayout, {
        Wrapper: CoachingProvider,
      })}
      {addRoute(routes.coachingChat, CoachingChatPage, AppLayout, {
        MobileLayout: SimpleLayoutStatic,
        Wrapper: CoachingProvider,
      })}
      {addRoute(routes.coachingOnboarding, CoachingOnboardingPage, SimpleLayout, {
        Wrapper: CoachingProvider,
      })}

      {/* On Demand Portal Pages */}
      {addRoute(routes.onDemandConfirmInfo, OnDemandConfirmInfoPage, SimpleLayout)}
      {addRoute(routes.onDemandHome, OnDemandLandingPage, AppLayout)}
      {addRoute(routes.onDemandJoinCall, OnDemandJoinCallPage, SimpleLayout)}
      {addRoute(routes.onDemandPreEncounterForm, onDemandPreEncounterPage, SimpleLayout)}

      {/* ConnectNow Portal Pages */}
      {addRoute(routes.connectNowConfirmInfo, ConnectNowConfirmInfoPage, SimpleLayout)}
      {addRoute(routes.connectNowHome, ConnectNowLandingPage, AppLayout)}
      {addRoute(routes.connectNowJoinCall, ConnectNowJoinCallPage, SimpleLayout)}
      {addRoute(routes.connectNowPreEncounterForm, ConnectNowPreEncounterPage, SimpleLayout)}

      {/* Self Care Portal Pages */}
      {addRoute(routes.module, ModulePage, AppLayout)}
      {addRoute(routes.reflection, ReflectionPage, AppLayout)}
      {addRoute(routes.reflections, ReflectionsPage, AppLayout)}
      {addRoute(routes.selfCareHome, SelfCareHomePage, AppLayout)}
      {addRoute(routes.selfCareQuiz, SelfCareQuizPage, SkillLayout)}
      {addRoute(routes.skill, SkillPage, SkillLayout)}
      {addRoute(routes.skillPathway, SkillPathwayPage, AppLayout)}
      {addRoute(routes.skillPathwaySection, SkillPathwaySectionPage, AppLayout)}
      {addRoute(routes.tour, TourPage, SkillLayout)}

      {/* Therapy Portal Pages */}
      {addRoute(routes.patientPortal, PatientPortalPage, SimpleHeaderLayout, {
        Wrapper: TherapyProvider,
      })}
      {addRoute(routes.patientPortalRedirect, PatientPortalRedirectPage, SimpleHeaderLayout)}
      {addRoute(routes.therapy, TherapyPage, AppLayout)}
      {addRoute(routes.therapyEligibility, TherapyEligibilityPage, SimpleHeaderLayout)}

      {/* Deprecated Routes */}
      {addRoute(routes.deepLinkPage, LoginDeepLinkPage, SimpleHeaderLayout)}

      {/* Not Found Page - Must be the last route */}
      {addRoute(routes.notFound, NotFoundPage, SimpleHeaderLayout)}
    </Routes>
  );
};
