import { defaultTo } from 'lodash';
import { Heading, Image, Layout, Pressable, Text } from '../../components/core';
import { IconCheck } from '../../components/icons/IconCheck';
import { IconChevronRight } from '../../components/icons/IconChevronRight';
import { RichText } from '../../components/prismic';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { PathwayStep } from './PathwayTypes';

type SkillPathwayStepProps = PathwayStep & {
  isFirstStep?: boolean;
  isLastStep?: boolean;
};

export const SkillPathwayStep = ({
  module,
  section,
  nudges,
  isFirstStep = false,
  isLastStep = false,
}: SkillPathwayStepProps): JSX.Element => {
  const navigate = useNavigate();

  const moduleData = module?.data;
  const imageUrl = defaultTo(moduleData?.image?.url, '');
  const imageAlt = defaultTo(moduleData?.image?.alt, '');
  const moduleTitle = defaultTo(moduleData?.title, '');

  const sectionData = section?.data;
  const sectionTitle = defaultTo(sectionData?.title, '');
  const sectionDescription = defaultTo(sectionData?.description, []);

  const onSectionPress = (): void => {
    if (module === undefined || section === undefined) {
      return;
    }

    navigate(
      getRoute('skillPathwaySection', {
        moduleId: module.id,
        sectionId: section.id,
      }),
    );
  };

  return (
    <Layout.HStack {...styles.topStack} space={4} testID="skill-pathway-step">
      <Layout.Box position="relative">
        {!isFirstStep && <Layout.Box {...styles.pathLineTop} />}

        {imageUrl !== '' && (
          <Image
            {...styles.image}
            alt={imageAlt}
            aria-hidden
            source={{
              uri: imageUrl,
            }}
            resizeMode="contain"
            resizeMethod="auto"
          />
        )}

        {!isLastStep && <Layout.Box {...styles.pathLineBottom} />}
      </Layout.Box>

      <Layout.VStack {...styles.rightSection} space={6}>
        <Pressable
          aria-label={sectionTitle}
          isLink
          onPress={onSectionPress}
          testID="pressable-go-to-pathway-section"
        >
          <Layout.HStack {...styles.description}>
            <Layout.VStack flex={1} space={1}>
              <Text.caption bold>{moduleTitle}</Text.caption>

              <Heading.h4 level={2}>{sectionTitle}</Heading.h4>

              <RichText styles={{ paragraph: { marginBottom: 0 } }} field={sectionDescription} />
            </Layout.VStack>

            <IconChevronRight {...styles.rightChevron} aria-hidden size={5} />
          </Layout.HStack>
        </Pressable>

        <Layout.Flex {...styles.nudgeWrapper} aria-label="Helps with" role="list">
          {nudges.map(nudge => (
            <Layout.HStack {...styles.nudge} key={nudge.name} role="listitem" space={3}>
              <IconCheck aria-hidden size={4} />

              <Heading.h6>{nudge.name}</Heading.h6>
            </Layout.HStack>
          ))}
        </Layout.Flex>
      </Layout.VStack>
    </Layout.HStack>
  );
};

const styles = getStylesheet({
  topStack: {
    paddingRight: 4,
    width: '100%',
  },

  pathLineTop: {
    borderColor: 'secondary.300',
    borderLeftWidth: 0,
    borderRightWidth: 2,
    borderStyle: 'dashed',
    height: 6,
    position: 'absolute',
    top: -24,
    width: '50%',
    zIndex: -1,
  },

  image: {
    size: '42px',
  },

  pathLineBottom: {
    borderColor: 'secondary.300',
    borderLeftWidth: 0,
    borderRightWidth: 2,
    borderStyle: 'dashed',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '50%',
    zIndex: -1,

    _web: {
      height: 'calc(100% + 24px)',
    },
  },

  rightSection: {
    flex: 1,
  },

  description: {
    flex: 1,
    justifyContent: 'space-between',
  },

  rightChevron: {
    alignSelf: 'center',
    minWidth: 5,
  },

  nudgeWrapper: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 2,
  },

  nudge: {
    backgroundColor: 'primary.100',
    borderRadius: '12px',
    gap: 2,
    paddingX: '8px',
    paddingY: '4px',
  },
});
