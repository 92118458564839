import { createIcon, Path } from './index';

export const IconCloudLightning = createIcon({
  title: 'Cloud Lightning',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M6.00034 16.326C4.94657 15.8256 4.03456 15.0698 3.34719 14.1273C2.65983 13.1848 2.21891 12.0855 2.06452 10.9292C1.91012 9.77291 2.04713 8.59638 2.46311 7.50653C2.87909 6.41669 3.56084 5.44808 4.4464 4.68874C5.33196 3.9294 6.39324 3.40342 7.5338 3.15859C8.67435 2.91376 9.85801 2.95785 10.9772 3.28686C12.0964 3.61587 13.1156 4.21935 13.9422 5.04247C14.7688 5.86559 15.3766 6.88223 15.7103 8.00001H17.5003C18.6518 7.998 19.7603 8.43748 20.5975 9.22801C21.4348 10.0185 21.9372 11.0999 22.0012 12.2496C22.0653 13.3993 21.6862 14.5299 20.9419 15.4085C20.1977 16.2872 19.1449 16.8471 18.0003 16.973"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M13 12L10 17H14L11 22"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
