import { Ellipse, Path, Rect, Svg } from '../../../components/icons';

export const ImageCoachingStudentSquare = (): JSX.Element => {
  return (
    <Svg title="A coaching student." viewBox="0 0 94 94" height="94" width="94" aria-hidden={true}>
      <Rect width="94" height="94" rx="8" fill="#CAD2FA" />
      <Path
        d="M4.07919 35.5973C4.22861 35.9764 4.78005 35.885 5.18646 35.9132C9.9121 36.242 14.6571 36.2679 19.3862 35.9805C20.6384 35.9047 21.8906 35.8062 23.1396 35.6872C24.1819 35.5876 25.3345 35.5811 26.34 35.2683C27.2123 34.9967 27.8682 34.3094 27.8292 33.3537C27.7914 32.4111 27.1658 31.6448 26.3194 31.2768C23.4745 30.0384 19.7082 29.6921 16.771 28.6923C16.324 28.5402 15.8414 28.4638 15.3146 28.4877C15.1679 28.4944 15.0247 28.5111 14.8857 28.5371C12.1141 29.0548 10.0737 32.8828 7.30121 33.3959C6.51005 33.5421 5.60415 33.5929 4.87035 33.9414C4.2621 34.2293 3.81078 34.9231 4.07919 35.5973Z"
        fill="#FFF8ED"
      />
      <Path
        d="M82.9473 15.278C85.5794 15.239 88.2105 15.1589 90.8405 15.0377C91.6566 14.9998 93.2324 14.91 93.0181 13.6621C92.9424 13.2216 92.5895 12.9662 92.2118 12.78C91.6339 12.4954 91.0288 12.2529 90.4249 12.0289C89.137 11.5516 87.8036 11.1966 86.4485 10.9726C85.0371 10.7396 83.5424 9.66259 82.6507 8.54388C81.8996 7.60011 80.7437 7.26027 79.5716 7.31655C79.4038 7.3247 79.233 7.33727 79.06 7.35451C76.6478 7.59499 72.0208 6.8341 69.6003 6.96588C68.9384 7.00161 68.3147 7.15078 67.7354 7.39079C65.3494 8.37935 65.7771 11.5566 67.5563 13.4287C68.5678 14.4931 69.9496 15.1354 71.4172 15.1813C75.2584 15.3014 79.1032 15.3348 82.9473 15.278Z"
        fill="#FFF8ED"
      />
      <Ellipse cx="65.7577" cy="12.8068" rx="9.13323" ry="2.60949" fill="#FFF8ED" />
      <Path
        d="M52.2583 29.5458C52.514 28.1296 51.5732 26.7742 50.157 26.5184C48.7407 26.2627 47.3853 27.2035 47.1296 28.6197C46.8738 30.036 47.8146 31.3914 49.2309 31.6471C50.6471 31.9029 52.0025 30.9621 52.2583 29.5458Z"
        fill="#35405F"
      />
      <Path
        d="M78.9231 75.8394C75.3539 80.5627 72.4137 82.052 70.7894 82.5582C69.668 82.9058 68.4682 83.1769 67.3618 83.5696C67.042 83.6831 66.7044 83.7447 66.381 83.6993C66.2834 83.6856 66.1865 83.6693 66.0903 83.6507C63.5861 83.1649 62.7149 80.2686 62.9179 77.7258C63.1087 75.3351 63.2636 72.7937 63.3369 70.308C63.3536 69.7427 63.7852 68.7947 64.0875 68.3167C64.7227 67.3123 64.4149 66.4329 63.7203 65.4686L63.1035 64.6124L53.1859 50.8494C50.7845 47.5168 51.5236 42.7724 55.6169 42.429C56.8006 42.3298 58.1201 42.3792 59.5574 42.6697C64.7538 43.7184 72.2382 52.9224 76.1967 58.2568C80.0209 63.4142 82.912 70.5657 78.9231 75.8394Z"
        fill="#CB4848"
      />
      <Path
        d="M61.2583 58.3499C61.2568 58.3509 61.2553 58.3518 61.2539 58.3529C61.2525 58.3539 61.251 58.3548 61.2497 58.3558C61.1473 58.4299 61.1157 58.5667 61.1759 58.6779C61.7422 59.7179 62.2607 60.8019 62.7063 61.9293C63.1342 63.0097 63.4558 64.1691 63.6903 65.3834C63.6978 65.4221 63.7135 65.4588 63.7366 65.4908C63.9021 65.7199 64.2527 65.5741 64.1986 65.2967C63.9475 64.0074 63.6086 62.8221 63.1816 61.7423C62.7287 60.5961 62.2008 59.4925 61.623 58.4338C61.5519 58.3024 61.3809 58.2645 61.2583 58.3499Z"
        fill="#35405F"
      />
      <Path
        d="M79.1082 75.9175C75.539 80.6408 72.4146 82.0506 70.7896 82.5576C69.6682 82.9051 68.4684 83.1763 67.362 83.569C67.0422 83.6825 66.7046 83.7441 66.3812 83.6987C63.9481 83.356 63.2523 80.5041 63.5499 78.0652C63.9233 75.0055 64.0229 71.562 64.0864 68.7197C64.0928 68.4335 64.2733 68.0093 64.4255 67.7668C64.751 67.2481 64.6317 66.7328 64.2738 66.236L63.1037 64.6117C62.9584 64.4117 62.9402 64.145 63.0599 63.9286C64.6081 61.1306 66.4131 58.9341 68.2031 57.2232C70.5594 54.9709 74.308 55.579 76.2482 58.1984C80.0724 63.3551 83.0971 70.6438 79.1082 75.9175Z"
        fill="#FFACAC"
      />
      <Path
        d="M68.734 82.4038C68.842 82.5119 68.8978 82.637 68.7793 82.7335C68.6771 82.8167 68.4645 82.8547 68.3712 82.7616C66.922 81.3165 65.3165 80.05 63.5809 78.9862C63.4947 78.9333 63.4475 78.835 63.4615 78.7348C63.4866 78.5544 63.688 78.4559 63.8434 78.551C65.6185 79.6372 67.2582 80.927 68.734 82.4038Z"
        fill="#35405F"
      />
      <Path
        d="M38.9918 50.3673C38.7883 48.0595 37.2729 46.0749 34.9921 45.6682C32.0356 45.1409 27.6788 45.3293 23.9191 49.3353C17.5159 56.1582 8.22589 70.1766 7.49139 75.5305C6.75688 80.8843 7.48995 85.5665 15.4691 87.9174C22.1345 89.8811 25.7168 90.9435 27.6458 91.0222C29.4032 91.0939 31.671 91.1744 33.4293 91.1299C33.5101 91.1279 33.593 91.1247 33.6779 91.1204C36.0005 91.002 41.3046 83.0705 39.1003 80.8663C38.7474 80.5134 38.2539 80.1094 37.6738 79.6797C33.5671 76.6377 32.3899 70.7875 35.0499 66.4236C36.1234 64.6624 37.1251 62.7737 37.8278 60.935C39.2278 57.2713 39.2417 53.202 38.9918 50.3673Z"
        fill="#CB4848"
      />
      <Path
        d="M45.7211 45.5272C47.7934 45.175 49.9245 45.6448 51.515 47.0192C54.6859 49.7595 59.9996 55.0847 62.7064 61.9303C65.9308 70.0849 63.22 82.8232 61.8551 88.0847C61.4474 89.6563 60.4904 91.0245 59.0069 91.6845C56.8723 92.6342 53.108 93.824 47.4494 93.9741C45.0333 94.0383 43.0057 93.9751 41.3698 93.8606C38.2419 93.6417 35.9439 91.1824 35.4257 88.09L34.0499 79.8798L32.2847 72.5936C31.9623 71.2627 31.9869 69.8714 32.3561 68.5527L37.0917 51.6419C37.9261 48.6621 40.4043 46.4307 43.455 45.9123L45.7211 45.5272Z"
        fill="#CB4848"
      />
      <Path
        d="M36.5619 62.8626C36.5603 62.862 36.5586 62.8614 36.5568 62.861C36.5551 62.8607 36.5535 62.86 36.5518 62.8595C36.4312 62.8228 36.3026 62.8813 36.251 62.9968C35.7685 64.078 35.2211 65.1483 34.5957 66.1869C34.4736 66.3896 34.3452 66.5903 34.2124 66.7895C34.1206 66.9274 34.1649 67.1144 34.3184 67.1765C34.4344 67.2235 34.568 67.1795 34.6374 67.0753C34.7754 66.8677 34.9083 66.6603 35.0333 66.4519C35.6689 65.396 36.2257 64.3069 36.7161 63.2048C36.7778 63.067 36.7047 62.9084 36.5619 62.8626Z"
        fill="#35405F"
      />
      <Path
        d="M34.0423 86.6066C32.2929 88.8108 32.1593 92.419 34.8977 93.0667C34.9486 93.0787 35 93.0899 35.0518 93.1002C35.8496 93.2586 36.6612 92.9869 37.2747 92.453C37.335 92.4006 37.3973 92.3448 37.4614 92.2855C39.3015 90.5845 41.5309 89.3148 44.0228 89.0508C44.9502 88.9526 45.4203 87.3782 44.2395 86.6697C43.2818 86.0955 41.1296 85.2129 39.2728 83.0809C38.2311 81.885 35.9029 84.2623 34.0423 86.6066Z"
        fill="#E7C797"
      />
      <Path
        d="M65.4975 82.6163C66.2442 83.1741 66.2638 84.2493 65.675 84.9717L63.172 88.0427C62.6371 88.6989 61.5793 88.2161 61.7246 87.382C61.8261 86.7995 61.2854 86.2981 60.6966 86.2434C60.5955 86.234 60.4948 86.219 60.3974 86.1968C59.8593 86.074 59.3567 85.3243 60.3974 84.5436C61.1236 83.9989 62.3087 83.1962 62.7734 82.8837C62.8999 82.7986 62.9906 82.6711 63.0294 82.5237C63.1333 82.1286 63.6397 81.7171 64.0312 81.8339C64.4051 81.9454 64.9009 82.1706 65.4975 82.6163Z"
        fill="#E7C797"
      />
      <Path
        d="M38.6922 44.044C38.3876 45.6288 39.0348 47.2714 40.5731 47.7595C41.4716 48.0446 42.5975 48.2719 43.9038 48.2719C47.5085 48.2719 48.9142 45.6483 49.2447 44.8916C49.3032 44.7577 49.3364 44.617 49.3582 44.4725C49.5479 43.2106 48.7501 42.0104 47.5131 41.6969L43.8459 40.7673C41.5053 40.174 39.1479 41.6728 38.6922 44.044Z"
        fill="#E7C797"
      />
      <Path
        d="M42.2218 25.3139C38.5139 26.3986 36.3711 30.2416 37.4356 33.8982L38.9018 38.935C39.9663 42.5917 43.1253 45.0956 48.802 43.1637C52.4594 41.9193 53.3939 38.6642 52.3294 35.0076L50.8633 29.9708C49.7987 26.3141 45.9297 24.2291 42.2218 25.3139Z"
        fill="#E7C797"
      />
      <Path
        d="M42.3789 32.8142C42.7529 31.6644 43.5199 30.7633 44.7229 30.6413C45.937 30.5181 47.7289 29.4995 48.909 29.1887C50.4475 28.7835 52.3399 27.8005 53.0226 25.4266C53.8056 22.7046 52.0864 21.2567 50.0566 21.1191C48.4818 21.0123 45.6599 20.6679 44.1522 21.1351C43.7401 21.263 43.3556 21.4141 43.0011 21.5768C41.399 22.3122 38.541 22.0392 37.4601 23.4318C36.8178 24.2593 36.4431 25.7025 36.2263 26.9988C35.9837 28.4492 34.5912 30.2808 34.5734 31.7511C34.5608 32.8167 35.5537 34.1336 36.583 35.21C37.8622 36.5477 39.9509 36.7221 41.0855 35.2598C41.5811 34.621 42.0541 33.8128 42.3789 32.8142Z"
        fill="#35405F"
      />
      <Path
        d="M42.661 36.6399C43.3601 37.8569 42.9405 39.4104 41.7235 40.1102C40.5066 40.8093 38.9531 40.3897 38.2532 39.1728C37.5541 37.9558 37.9737 36.4023 39.1907 35.7025C40.4076 35.0033 41.9611 35.423 42.661 36.6399Z"
        fill="#E7C797"
      />
      <Path
        d="M33.6788 91.1188C33.5598 91.1509 33.4445 91.1775 33.3328 91.1992C31.6657 91.5223 29.3421 91.089 27.6453 91.0191C25.7163 90.9426 21.9903 90.1091 15.3249 88.1468C7.34718 85.7953 6.5224 81.0011 7.2569 75.6487C7.56208 73.432 9.47591 69.6573 11.9641 65.5644C13.2888 63.3856 15.6379 62.0018 18.1844 62.1344C21.7267 62.3189 26.161 63.026 30.8739 64.9277C33.0715 65.8145 32.7331 70.0064 31.3056 71.898C29.7521 73.9566 31.4332 75.5361 33.6152 76.9109C35.72 78.2371 38.0345 79.7971 39.1006 80.8632C41.3062 83.0688 38.2743 89.8787 33.6788 91.1188Z"
        fill="#FFACAC"
      />
      <Path
        d="M29.4138 73.8025C29.5601 73.8764 29.523 74.1601 29.4175 74.2856C29.3406 74.377 29.2892 74.3103 29.1825 74.2566C27.6162 73.4675 25.9905 72.7972 24.3107 72.2506C23.9987 72.151 24.1344 71.657 24.4464 71.7595C26.1591 72.3152 27.8172 72.9964 29.4138 73.8025Z"
        fill="#35405F"
      />
      <Path
        d="M68.4128 63.4476C67.2186 64.4932 66.1053 65.6126 65.0723 66.8018C64.9632 66.9274 64.7651 66.919 64.6684 66.7836C64.5999 66.6877 64.6057 66.5575 64.683 66.4686C65.7257 65.2692 66.8464 64.1399 68.0495 63.0843C68.2972 62.8669 68.6605 63.2273 68.4128 63.4476Z"
        fill="#35405F"
      />
      <Path
        d="M51.1679 35.4762C50.4826 34.7944 49.8968 34.091 49.4433 33.2323C49.2894 32.9412 48.8474 33.199 49.0013 33.4908C49.4743 34.3871 50.0897 35.1259 50.8054 35.8381C51.0401 36.0706 51.4019 35.7088 51.1679 35.4762Z"
        fill="#35405F"
      />
      <Path
        d="M50.8131 35.4922C50.6217 35.6951 50.353 35.7984 50.0742 35.7435C49.7528 35.6807 49.6149 36.174 49.9385 36.2368C50.3942 36.3256 50.8535 36.1949 51.1749 35.8533C51.401 35.6142 51.0398 35.2517 50.8131 35.4922Z"
        fill="#35405F"
      />
      <Path
        d="M46.358 35.1636C47.0275 35.217 47.6478 35.009 48.1751 34.6017C48.4351 34.4009 48.0703 34.0405 47.8132 34.2399C47.3922 34.5649 46.8837 34.6941 46.358 34.6523C46.0293 34.6255 46.0315 35.1376 46.358 35.1636Z"
        fill="#35405F"
      />
      <Path
        d="M51.9922 33.4109C52.0459 33.6094 51.8592 33.763 51.668 33.8389C51.3118 33.9803 50.9286 34.042 50.5297 34.0096C50.2025 33.9843 50.1996 33.4722 50.5297 33.4975C50.8808 33.5272 51.224 33.48 51.5383 33.3466C51.7203 33.2693 51.9406 33.2199 51.9922 33.4109Z"
        fill="#35405F"
      />
      <Path
        d="M46.7614 33.4302C47.2251 33.2785 47.5689 33.202 47.909 33.1449C48.2507 33.0879 48.2954 32.461 47.8354 32.2342C47.3753 32.0074 46.6444 32.1331 46.1165 32.3021C45.6817 32.4415 45.1855 32.869 45.2282 33.3089C45.2708 33.7487 45.6918 33.8346 45.8897 33.748C46.2458 33.5927 46.4458 33.5335 46.7614 33.4302Z"
        fill="#35405F"
      />
      <Path
        d="M51.7833 31.9284C51.8656 32.3475 51.3806 32.5251 50.9576 32.5843C50.4737 32.6536 50.1313 32.7352 49.8013 32.8327C49.4683 32.9324 49.1542 32.39 49.4662 31.9834C49.7811 31.5768 50.4925 31.3695 51.0399 31.2871C51.1731 31.2662 51.3159 31.2749 51.428 31.3498C51.6082 31.4703 51.7386 31.7008 51.7833 31.9284Z"
        fill="#35405F"
      />
      <Path
        d="M47.5166 37.5396C48.787 38.3492 50.2835 38.376 51.5871 37.622C51.8724 37.4573 51.6146 37.0146 51.3286 37.1799C50.1773 37.8451 48.8961 37.8126 47.7745 37.0976C47.4964 36.9207 47.2393 37.3634 47.5166 37.5396Z"
        fill="#35405F"
      />
      <Path
        d="M49.1684 43.0507C49.351 42.9743 49.5597 43.1233 49.5306 43.3191C49.5186 43.4001 49.4664 43.4697 49.3911 43.5018C47.7178 44.2128 45.7062 44.1264 43.9741 43.6743C43.6541 43.5898 43.7899 43.0958 44.1099 43.1803C45.7072 43.5988 47.6039 43.7054 49.1684 43.0507Z"
        fill="#35405F"
      />
      <Path
        d="M51.6536 43.3983C51.8582 43.3126 52.0672 43.5046 51.9948 43.7142C50.8298 47.0911 48.6971 49.8459 44.8061 50.4082C41.1453 50.94 37.0599 49.1036 34.8704 46.0904C34.7305 45.8979 34.8952 45.6469 35.1289 45.6921C35.1987 45.7056 35.2572 45.7452 35.2992 45.8025C37.3879 48.6539 41.2551 50.408 44.7346 49.9012C48.4295 49.3622 50.4098 46.7468 51.5097 43.5557C51.5344 43.4842 51.5839 43.4275 51.6536 43.3983Z"
        fill="#35405F"
      />
      <Path
        d="M40.2385 39.3638C39.5546 39.3081 39.0043 38.8401 38.9075 38.1482C38.8187 37.5163 39.1314 36.9313 39.7243 36.6908C40.0291 36.5673 40.1858 37.0541 39.8818 37.1776C39.5012 37.3321 39.3459 37.7424 39.4224 38.1302C39.5149 38.5989 39.8911 38.8257 40.3425 38.8625C40.6704 38.8893 40.5635 39.3898 40.2385 39.3638Z"
        fill="#35405F"
      />
      <Path
        d="M37.6673 79.6555C37.7496 79.7179 37.7813 79.8225 37.7521 79.9215C36.5407 84.0273 34.2584 87.7337 31.1254 90.6497C31.0232 90.7448 30.789 90.6979 30.6868 90.6028C30.5709 90.495 30.6518 90.3887 30.7677 90.2809C33.8327 87.4298 36.0828 83.8051 37.2653 79.7874C37.3156 79.6162 37.5251 79.5477 37.6673 79.6555Z"
        fill="#35405F"
      />
    </Svg>
  );
};
