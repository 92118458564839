import { Path, Rect, Svg } from '../../../components/icons';

export const ImageHandBallSquare = (): JSX.Element => {
  return (
    <Svg
      title="A hand touching a ball."
      viewBox="0 0 94 94"
      width="94"
      height="94"
      aria-hidden={true}
    >
      <Rect x="0.5" width="94" height="94" rx="8" fill="#FAEAD3" />
      <Path
        d="M61.7588 27.0863C67.1764 27.0863 71.5682 22.6944 71.5682 17.2768C71.5682 11.8591 67.1764 7.46729 61.7588 7.46729C56.3411 7.46729 51.9492 11.8591 51.9492 17.2768C51.9492 22.6944 56.3411 27.0863 61.7588 27.0863Z"
        fill="#F67E6A"
      />
      <Path
        d="M60.0956 8.24028C63.7408 8.84144 66.8644 10.8517 68.2798 14.3633C69.6667 17.8049 69.1068 21.994 66.858 24.9298C66.6099 25.2543 67.1601 25.5723 67.4082 25.2511C69.7716 22.1657 70.3314 17.719 68.8682 14.112C67.3765 10.4318 64.1002 8.26255 60.2674 7.62957C59.8634 7.55959 59.6917 8.17348 60.0956 8.24028Z"
        fill="#35405F"
      />
      <Path
        d="M51.9487 40.939C51.9487 40.939 58.5043 42.1922 59.1945 53.8561C59.8847 65.52 47.4988 64.7757 47.4988 64.7757L51.9487 40.939Z"
        fill="#A5B8FC"
      />
      <Path
        d="M28.182 64.566L18.4998 71.9168C18.4998 71.9168 22.3167 84.0738 33.8375 91.7776C33.8375 91.7776 71.0875 64.2098 74.1983 62.0182C77.3091 59.8267 76.797 56.824 75.4007 55.4086C74.0902 54.0822 71.5424 54.1713 69.23 54.6675C66.9176 55.1637 56.1029 58.2713 56.1029 58.2713C56.1029 58.2713 60.1234 31.0247 60.4987 28.6455C60.944 25.8114 60.1774 23.499 57.2352 23.2827C54.7256 23.0982 53.8222 25.2134 53.1002 27.7167C52.4831 29.8542 49.7063 39.6987 49.7063 39.6987C49.7063 39.6987 48.0619 38.6777 46.3792 38.8654C44.6966 39.053 43.2907 41.0665 43.2907 41.0665C43.2907 41.0665 41.6208 39.8164 39.8459 40.055C38.071 40.2935 37.1327 41.6104 36.7447 42.4755C36.3566 43.3407 28.182 64.566 28.182 64.566Z"
        fill="#A5B8FC"
      />
      <Path
        d="M45.9245 52.5767C47.2032 48.2954 48.4691 44.0109 49.8178 39.7518C49.9418 39.3606 49.3279 39.192 49.2038 39.5832C47.8552 43.8423 46.5861 48.1268 45.3106 52.4081C45.1929 52.7993 45.8068 52.9679 45.9245 52.5767Z"
        fill="#35405F"
      />
      <Path
        d="M56.5608 58.2047C57.0729 54.6836 57.5786 51.1656 58.148 47.6541C58.2116 47.2533 57.6009 47.0815 57.5341 47.4855C56.9647 50.9971 56.459 54.515 55.9469 58.0361C55.8896 58.4337 56.5003 58.6087 56.5608 58.2047Z"
        fill="#35405F"
      />
      <Path
        d="M56.3381 58.4266C57.2097 58.1722 58.0844 57.9145 58.9559 57.6569C59.3472 57.5424 59.1817 56.9285 58.7873 57.043C57.9158 57.3006 57.0411 57.5551 56.1696 57.8127C55.7783 57.9272 55.9437 58.5411 56.3381 58.4266Z"
        fill="#35405F"
      />
      <Path
        d="M39.5529 54.0664C40.8316 49.7851 42.1007 45.5006 43.4462 41.2415C43.5702 40.8503 42.9564 40.6817 42.8323 41.073C41.4837 45.332 40.2145 49.6165 38.939 53.8978C38.8245 54.2891 39.4384 54.4577 39.5529 54.0664Z"
        fill="#35405F"
      />
      <Path
        d="M75.747 56.5379C73.6922 57.4095 67.4737 61.5222 70.7499 64.2482C71.0616 64.509 71.5133 64.0605 71.1984 63.7997C68.4375 61.4999 74.2997 57.8389 76.0682 57.0882C76.4436 56.926 76.1191 56.3789 75.747 56.5379Z"
        fill="#35405F"
      />
      <Path
        d="M52.5817 30.6874C56.9903 32.0043 57.8523 26.9691 58.5361 23.871C58.6252 23.4734 58.0113 23.3016 57.9223 23.7024C57.3243 26.4125 56.7517 31.2727 52.7503 30.0767C52.3591 29.9559 52.1905 30.5697 52.5817 30.6874Z"
        fill="#35405F"
      />
      <Path
        d="M46.0231 2.46689C47.3877 4.55985 48.7522 6.64962 50.12 8.73939C50.3426 9.07973 50.8929 8.76165 50.6703 8.41813C49.3057 6.32836 47.9411 4.2354 46.5734 2.14563C46.3507 1.80529 45.7973 2.12337 46.0231 2.46689Z"
        fill="#35405F"
      />
      <Path
        d="M46.5733 29.3727C47.9378 27.2829 49.3024 25.19 50.6701 23.1002C50.896 22.7567 50.3425 22.4386 50.1199 22.7789C48.7553 24.8719 47.3907 26.9616 46.023 29.0514C45.8003 29.3949 46.3506 29.7162 46.5733 29.3727Z"
        fill="#35405F"
      />
      <Path
        d="M39.4868 16.0102C41.9837 16.0548 44.4805 16.0993 46.9774 16.1438C47.3877 16.1502 47.3877 15.514 46.9774 15.5077C44.4805 15.4631 41.9837 15.4186 39.4868 15.3741C39.0796 15.3677 39.0796 16.0039 39.4868 16.0102Z"
        fill="#35405F"
      />
    </Svg>
  );
};
