/**
 * Note: Our core Link component renders native base Text components, not native base Link components.
 * So styles here will not affect the core Link component.
 */
export const linkStyles = {
  Link: {
    baseStyle: {
      _text: {
        _light: {
          color: 'blue.600',
        },
        _dark: {
          color: 'blue.600',
        },
      },
    },
    defaultProps: {
      isUnderlined: false,
    },
  },
};
