import { chain, isArray, keyBy, zipObject } from 'lodash';
import { HubAnswerSubmission, Option, Question, Questionnaire } from '../../../graphQL';

export type QuestionPlus = Question & {
  assessmentKey: string;
  defaultManualInput?: string;
  defaultValues?: string[];
  description?: string | null;
};

type Options = {
  [key: string]: Option;
};

type OptionsText = {
  [key: string]: string;
};

export type AssessmentAnswer = HubAnswerSubmission;

const oneToSevenOptions = [
  { key: 'strongly-disagree', text: 'Strongly Disagree', score: 1 },
  { key: 'disagree', text: 'Disagree', score: 2 },
  { key: 'slightly-disagree', text: 'Slightly Disagree', score: 3 },
  { key: 'neither', text: 'Neither Agree nor Disagree', score: 4 },
  { key: 'slightly-agree', text: 'Slightly Agree', score: 5 },
  { key: 'agree', text: 'Agree', score: 6 },
  { key: 'strongly-agree', text: 'Strongly Agree', score: 7 },
];

export const getQuestionOptions = (question: Question): Options | undefined => {
  if (question.type === '1-to-7') {
    return keyBy(oneToSevenOptions, 'key');
  }

  if (question.options == null) {
    return undefined;
  }

  return keyBy(question.options, option => {
    if (option.key == null) {
      throw new Error('Trying to parse question option without key. This is not allowed.');
    }

    return option.key;
  });
};

export const getQuestionOptionsText = (question: Question): OptionsText | undefined => {
  if (question.type === '1-to-7') {
    return zipObject(
      oneToSevenOptions.map(({ key }) => key),
      oneToSevenOptions.map(({ text }) => text),
    );
  }

  if (question.options == null) {
    return undefined;
  }

  return zipObject(
    question.options.map(({ key }) => {
      if (key == null) {
        throw new Error('Trying to parse question option without key. This is not allowed.');
      }

      return key;
    }),
    question.options.map(({ text }) => text),
  );
};

export const parseOptionFromKey = (
  question?: Question,
  optionKey?: string | string[],
): Option | Option[] | undefined => {
  if (question === undefined || optionKey === undefined) {
    return;
  }

  const options = getQuestionOptions(question) ?? {};

  if (!isArray(optionKey)) {
    return options[optionKey];
  }

  return chain(optionKey)
    .map(key => options[key])
    .compact()
    .value();
};

export const getQuestions = (
  questionnaire: Questionnaire,
  values?: Array<{ key: string; value?: string[]; manualInput?: string }>,
): QuestionPlus[] => {
  return questionnaire.questions
    .filter(question => question.visible !== false)
    .map(question => {
      const defaultValue = values?.find(({ key }) => key === question.key);

      return {
        ...question,
        assessmentKey: questionnaire.key,
        defaultManualInput: defaultValue?.manualInput,
        defaultValues: defaultValue?.value,
        description: question.overrideDescription ?? questionnaire.description,
      };
    });
};
