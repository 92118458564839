import { compact } from 'lodash';
import React from 'react';
import { Layout } from '../../../../../components/core';
import { getStylesheet } from '../../../../../styles';
import { Section } from '../../../../section/components/Section';
import { SectionDocument } from '../../../../section/SectionTypes';
import { SkillDocument } from '../../../../skill/SkillTypes';
import { ModuleSectionsSlice } from '../../../ModuleTypes';

type ModuleContext = {
  moduleId: string;
  sectionsById: Record<string, SectionDocument>;
  skillsById: Record<string, SkillDocument>;
  completedSkillIds: string[];
};

type ModuleSectionsProps = {
  context: ModuleContext;
  slice: ModuleSectionsSlice;
};

type SectionLink = Extract<ModuleSectionsSlice['items'][number]['section'], { id: string }>;

const ModuleSections = ({ context, slice }: ModuleSectionsProps): JSX.Element => {
  const sectionLinks: SectionLink[] = compact(
    slice.items.map(({ section }) => {
      return 'id' in section ? section : undefined;
    }),
  );

  const items = sectionLinks.map(sectionLink => {
    const fullSection = context.sectionsById[sectionLink.id];
    if (!fullSection) {
      return null;
    }

    return <Section key={sectionLink.id} context={context} section={fullSection} />;
  });

  return (
    <Layout.VStack {...styles.moduleSectionsList} space={12} testID="module-sections">
      {items}
    </Layout.VStack>
  );
};

export default ModuleSections;

const styles = getStylesheet({
  moduleSectionsList: {
    alignItems: 'center',
    marginTop: 10,
    width: '100%',
  },
});
