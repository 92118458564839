import {
  ModuleDocument,
  SectionDocument,
  SkillDocument,
} from '../../../../.slicemachine/prismicio';
import { useModuleSimpleById } from '../../../content/module/hooks/useModuleSimpleById';
import { useSectionById } from '../../../content/section/hooks/useSectionById';
import { useSkillById } from '../../../content/skill/hooks/useSkillById';
import { useSkillPathwayNextSkillQuery } from '../../../graphQL';

type UsePathwayNextSkillReturn = [
  {
    hasPathway: boolean;
    nextSkill?: SkillDocument;
    module?: ModuleDocument;
    section?: SectionDocument;
  },
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const usePathwayNextSkill = (): UsePathwayNextSkillReturn => {
  const {
    data: pathwayData,
    loading: pathwayLoading,
    error: pathwayError,
    refetch: pathwayRefetch,
  } = useSkillPathwayNextSkillQuery();

  const nextSkillId = pathwayData?.skillPathwayNextSkill.nextSkillId ?? undefined;
  const hasPathway = pathwayData?.skillPathwayNextSkill.hasPathway ?? false;
  const moduleId = pathwayData?.skillPathwayNextSkill.moduleId ?? undefined;
  const sectionId = pathwayData?.skillPathwayNextSkill.sectionId ?? undefined;

  const [nextSkill, skillState] = useSkillById({
    skillId: nextSkillId,
  });

  const [module, moduleState] = useModuleSimpleById({ moduleId: moduleId ?? '' });
  const [section, sectionState] = useSectionById({ sectionId: sectionId ?? '' });

  const refetch = (): void => {
    void pathwayRefetch();

    moduleState.refetch();
    sectionState.refetch();
  };

  return [
    { hasPathway, nextSkill, module, section },
    {
      idle: !pathwayLoading && skillState.idle && moduleState.idle && sectionState.idle,
      loading: pathwayLoading || skillState.loading || moduleState.loading || sectionState.loading,
      loaded: !pathwayLoading && skillState.loaded && moduleState.loaded && sectionState.loaded,
      error: pathwayError ?? skillState.error ?? moduleState.error ?? sectionState.error,
      refetch,
    },
  ];
};
