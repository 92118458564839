import { useAccessibleColors } from 'native-base';
import { useEffect } from 'react';

export const NativeBaseSetup = (): null => {
  const [, , toggleAccessibleColors] = useAccessibleColors();

  useEffect(() => {
    // When the app loads, turn on accessible colors.
    toggleAccessibleColors();
  }, []);

  return null;
};
