import { defaultTo } from 'lodash';
import { StyledProps } from 'native-base';
import React from 'react';
import { Heading, Image, Layout, Pressable, Text } from '../../components/core';
import { IconCheckCircle2 } from '../../components/icons/IconCheckCircle2';
import { IconChevronRight } from '../../components/icons/IconChevronRight';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { SkillDocument } from './SkillTypes';
import type { HeadingLevel } from '../../components/core/Heading';

type SkillBoxProps = StyledProps & {
  contextText?: string;
  headingLevel?: HeadingLevel;
  hideTitle?: boolean;
  horizontal?: boolean;
  isDisabled?: boolean;
  moduleId: string;
  navToIntroSkill?: boolean;
  navToPathwaySkill?: boolean;
  skill: SkillDocument;
  skillIsCompleted?: boolean;
};

export const SkillBox = ({
  contextText,
  hideTitle,
  horizontal,
  moduleId,
  navToIntroSkill,
  navToPathwaySkill,
  skill,
  skillIsCompleted,
  headingLevel = 3,
  ...topStyles
}: SkillBoxProps): JSX.Element => {
  const navigate = useNavigate();
  const onSkillPress = (skillId: string) => (): void => {
    let searchParams;
    if (navToIntroSkill === true) {
      searchParams = { intro: '1' };
    } else if (navToPathwaySkill === true) {
      searchParams = { path: '1' };
    }

    navigate(getRoute('skill', { moduleId, skillId, slide: '1' }, searchParams));
  };

  const data = skill.data;
  const imageUrl = defaultTo(data.thumbnail_image?.url, '');
  const imageAlt = defaultTo(data.thumbnail_image?.alt, 'Image');
  const title = defaultTo(data.title, 'Skill Title');
  const valueProposition = defaultTo(data.value_proposition, '');
  const timeNeeded = defaultTo(data.time_needed, '');

  const shouldHideTitle = Boolean(hideTitle);
  const useCompletedStyle = Boolean(skillIsCompleted);
  const useHorizontalStyle = Boolean(horizontal);

  // Temporarily show the second skill as completed.
  const stylesCompletedText = useCompletedStyle ? styles.skillCompletedText : {};

  const idPrefix = `${skill.id}-skill-box`;
  const headerId = `${idPrefix}-header`;
  const descriptionId = `${idPrefix}-description`;

  return (
    <Pressable
      {...styles.skillBox}
      {...(useCompletedStyle ? styles.skillBoxCompleted : {})}
      {...(!useHorizontalStyle ? styles.skillBoxVertical : {})}
      {...topStyles}
      aria-describedby={descriptionId}
      aria-labelledby={headerId}
      role="link"
      testID="skill-box"
      onPress={onSkillPress(skill.id)}
    >
      <Layout.Stack
        direction={useHorizontalStyle ? 'row' : 'column'}
        {...styles.skillBoxStack}
        {...(useHorizontalStyle ? styles.skillBoxStackHorizontal : {})}
        space={useHorizontalStyle ? 4 : 0}
      >
        {imageUrl && (
          <Image
            {...styles.skillThumbnail}
            {...stylesCompletedText}
            aria-hidden
            alt={imageAlt}
            source={{
              uri: imageUrl,
            }}
            resizeMode="contain"
            resizeMethod="auto"
          />
        )}

        <Layout.VStack {...styles.skillTextStack} space={4}>
          <Layout.VStack space={2}>
            {!shouldHideTitle && (
              <Text.caption bold {...stylesCompletedText} numberOfLines={2}>
                {title}
              </Text.caption>
            )}

            <Heading.h5
              {...styles.skillText}
              {...stylesCompletedText}
              id={headerId}
              level={headingLevel}
              numberOfLines={4}
            >
              {valueProposition}
            </Heading.h5>
          </Layout.VStack>

          <Layout.HStack {...styles.skillBoxBottom}>
            {timeNeeded && (
              <Text.caption bold color="secondary.700">
                {`${timeNeeded} min`}
              </Text.caption>
            )}

            {useCompletedStyle && (
              <Text.para id={descriptionId} display="none">
                Skill Completed
              </Text.para>
            )}

            {useCompletedStyle ? (
              <IconCheckCircle2 aria-labelledby={descriptionId} color="green.500" size={5} />
            ) : (
              <Layout.HStack alignItems="center" space={2}>
                {contextText !== undefined && (
                  <Text.caption bold color="primary.600">
                    {contextText}
                  </Text.caption>
                )}

                <IconChevronRight aria-hidden color="secondary.500" size={5} />
              </Layout.HStack>
            )}
          </Layout.HStack>
        </Layout.VStack>
      </Layout.Stack>
    </Pressable>
  );
};

const styles = getStylesheet({
  skillBox: {
    backgroundColor: 'white',
    borderColor: 'secondary.200',
    borderRadius: 8,
    borderWidth: 1,
    flexGrow: 1,
    flexShrink: 1,
    minWidth: '45%',
    overflow: 'hidden',
    padding: 4,
    shadow: 'medium',
  },

  skillBoxVertical: {
    height: 230,
  },

  skillBoxCompleted: {
    backgroundColor: 'secondary.50',
    borderColor: 'secondary.300',
    borderWidth: 1,
    shadow: 'none',
  },

  skillBoxBottom: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 4,
  },

  skillBoxStack: {
    flexGrow: 1,
    justifyContent: 'space-between',
    textAlign: 'left',
  },

  skillBoxStackHorizontal: {
    alignItems: 'center',
  },

  skillThumbnail: {
    size: '48px',
  },

  skillText: {
    maxHeight: '72px',
    overflow: 'hidden',
  },

  skillTextStack: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'flex-end',
  },

  skillCompletedText: {
    opacity: 0.9,
  },
});
