import { JSX } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { useResendActivationEmailMutation } from '../../../graphQL';
import { isRateLimited } from '../../../utils/graphql';

type ActivationResendFormProps = {
  onSuccess: (newUserEmail: string) => void;
};

export const ActivationResendForm = ({ onSuccess }: ActivationResendFormProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      email: '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  const emailValue = watch('email');

  const [resendActivation, { loading }] = useResendActivationEmailMutation({
    onCompleted: ({ resendActivationEmail }): void => {
      const { __typename: responseType } = resendActivationEmail;

      if (responseType === 'HubResendActivationSuccess') {
        onSuccess(emailValue);
        return;
      }

      if (
        responseType === 'HubResendActivationError' &&
        resendActivationEmail.errorCode === 'InvalidEmail'
      ) {
        formContext.setError('email', {
          type: 'pattern',
          message: 'You must enter a valid email.',
        });
        return;
      }

      formContext.setError('submit', {
        type: 'custom',
        message: 'An internal error occurred.',
      });
    },
    onError: authError => {
      if (isRateLimited(authError)) {
        formContext.setError('submit', {
          type: 'submit',
          message: 'Your account has been locked. Please wait 15 minutes before trying again.',
        });
        return;
      }

      formContext.setError('submit', authError);
    },
  });

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(async ({ email }): Promise<void> => {
      await resendActivation({ variables: { email } });
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.View>
          <FormInput
            label="Email address"
            name="email"
            placeholder="School email address"
            autoCapitalize="none"
            autoFocus
            isRequired
            rules={{ pattern: { value: /.+@.+\..+/, message: 'You must enter a valid email.' } }}
            control={control}
            error={errors.email}
            keyboardType="email-address"
            onSubmitEditing={submitForm}
          />

          <Layout.VStack aria-live="polite" space={3}>
            {errors.submit && (
              <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
            )}
          </Layout.VStack>
        </Layout.View>

        <Layout.VStack>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-activation-resend-submit"
            onPress={submitForm}
          >
            Send
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
