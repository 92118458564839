import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-native';
import { PageLoading } from '../../components/page';
import { usePortalNavigateWhenAvailable } from '../../content/portal/usePortalNavigate';
import { getRoute, parseRouteName, routes, useNavigate, useParams } from '../../routes';

export const PortalForwardPage = (): JSX.Element => {
  const params = useParams();
  const to = `/${params['*'] ?? ''}`;

  const location = useLocation();
  const route = useMemo(() => {
    const toLocation = {
      ...location,
      pathname: to,
    };

    const routeName = parseRouteName(toLocation);
    if (routeName === 'notFound') {
      return undefined;
    }

    return routes[routeName];
  }, [location]);

  const navigate = useNavigate();
  const { navigateWhenAvailable } = usePortalNavigateWhenAvailable();

  useEffect(() => {
    if (to === '/' || route === undefined) {
      // Invalid link, so just route them to the home page.
      navigate(getRoute('home', {}));
      return;
    }

    navigateWhenAvailable(route[1], to, { state: location.state });
  }, []);

  return <PageLoading pageName="page" />;
};
