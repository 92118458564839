import { Path, createIcon } from '../../components/icons';

export const ImagePhoneInHand = createIcon({
  title: 'A hand holding a phone',
  viewBox: '0 0 99 167',
  path: [
    <Path
      d="M82.8187 164.059L82.9686 135.892C82.9686 135.892 95.0724 125.567 97.8326 108.986C101.601 86.3658 95.9925 71.2224 79.3566 54.7909C60.199 35.8719 21.952 28.2253 14.762 31.5647C6.80862 35.2586 8.75097 45.4746 14.0532 47.4442C14.0532 47.4442 6.80182 47.6009 4.97534 53.3529C3.46236 58.1236 4.75043 62.5944 11.7156 66.6835C14.7075 68.4418 23.908 74.1053 23.908 74.1053C23.908 74.1053 13.1195 75.959 7.21073 77.6287C1.30194 79.2916 -0.967524 84.444 0.736279 88.7989C2.33104 92.8676 5.88858 94.251 11.4907 94.7077C17.0928 95.1643 27.0907 95.1643 27.0907 95.1643C27.0907 95.1643 17.2495 112.127 26.7908 135.149V161.047C26.784 161.026 48.4428 171.931 82.8187 164.059Z"
      fill="#F67E6A"
    />,
    <Path
      d="M58.8496 119.045L2.58319 104.174C0.763526 103.69 -0.320091 101.83 0.156973 100.01L25.9185 2.53872C26.4023 0.719061 28.2629 -0.364556 30.0826 0.112509L86.349 14.9833C88.1686 15.4672 89.2522 17.3277 88.7752 19.1474L63.0137 116.619C62.5366 118.438 60.6693 119.529 58.8496 119.045Z"
      fill="#1D2744"
    />,
    <Path
      d="M58.8632 103.145L10.4343 90.3459C8.97585 89.9643 8.11033 88.4717 8.49198 87.0133L28.9581 9.5652C29.3397 8.10675 30.8322 7.24121 32.2907 7.62286L80.7196 20.4218C82.178 20.8035 83.0436 22.296 82.6619 23.7545L62.1958 101.203C61.8074 102.661 60.3148 103.527 58.8632 103.145Z"
      fill="#C7D2FE"
    />,
    <Path
      d="M62.9046 51.7786C61.6234 54.9682 60.3489 58.1577 59.0677 61.3472C58.7405 62.1582 60.0559 62.5126 60.383 61.7084C61.6643 58.5189 62.9387 55.3294 64.22 52.1398C64.5471 51.322 63.2249 50.9676 62.9046 51.7786Z"
      fill="#1D2744"
    />,
    <Path
      d="M33.2448 73.8735C36.6797 73.7645 40.1146 73.6487 43.5494 73.5396C44.4218 73.5123 44.4286 72.1493 43.5494 72.1766C40.1146 72.2856 36.6797 72.4015 33.2448 72.5105C32.3725 72.5378 32.3657 73.9008 33.2448 73.8735Z"
      fill="#1D2744"
    />,
    <Path
      d="M42.5135 55.1931C44.4354 58.0418 46.3573 60.8906 48.2792 63.7393C48.7699 64.4617 49.9489 63.7802 49.4582 63.051C47.5363 60.2022 45.6145 57.3535 43.6926 54.5047C43.2019 53.7823 42.0228 54.4638 42.5135 55.1931Z"
      fill="#1D2744"
    />,
    <Path
      d="M32.7132 109.306C32.2634 109.306 31.8205 109.251 31.3707 109.129C30.0281 108.774 28.8967 107.916 28.2016 106.709C27.4996 105.51 27.3156 104.106 27.67 102.756C28.0244 101.414 28.8831 100.282 30.0826 99.5873C31.2821 98.8854 32.686 98.7013 34.0354 99.0557C35.378 99.4101 36.5093 100.269 37.2045 101.475C37.9065 102.675 38.0905 104.079 37.7361 105.428C37.3817 106.771 36.523 107.902 35.3235 108.597C34.5193 109.06 33.6197 109.306 32.7132 109.306ZM32.7064 100.248C31.0026 100.248 29.4419 101.387 28.9853 103.111C28.7195 104.106 28.8627 105.142 29.3806 106.028C29.8986 106.914 30.73 107.547 31.7182 107.813C32.7133 108.072 33.7492 107.936 34.6351 107.418C35.5211 106.9 36.1549 106.069 36.4207 105.074C36.9591 103.022 35.7324 100.916 33.6878 100.371C33.3607 100.289 33.0268 100.248 32.7064 100.248Z"
      fill="#C7D2FE"
    />,
    <Path
      d="M53.0226 9.46299C56.4234 10.3626 59.8242 11.2622 63.225 12.1618C64.0769 12.3867 64.438 11.0714 63.5861 10.8465C60.1853 9.94687 56.7846 9.04726 53.3838 8.14765C52.5387 7.92275 52.1775 9.23809 53.0226 9.46299Z"
      fill="#C7D2FE"
    />,
    <Path
      d="M82.9754 84.5462C82.9754 84.5462 62.7547 69.1847 58.4407 68.7962C54.1266 68.4078 47.8294 72.2311 52.232 83.0264C55.6737 91.4704 68.7384 102.184 68.7384 102.184C68.7384 102.184 59.0472 110.137 56.2462 131.108C53.4451 152.085 80.706 122.323 80.706 122.323L82.9754 84.5462Z"
      fill="#F67E6A"
    />,
    <Path
      d="M56.3552 129.983C56.5733 126.644 57.4593 123.509 59.0132 120.551C59.4221 119.774 58.243 119.086 57.8341 119.863C56.1712 123.032 55.2239 126.412 54.9854 129.983C54.9308 130.856 56.2939 130.856 56.3552 129.983Z"
      fill="#1D2744"
    />,
    <Path
      d="M73.1411 78.2149C80.4129 83.2717 87.8824 88.0219 95.1815 93.0447C95.9039 93.5423 96.5854 92.3632 95.8698 91.8657C88.5775 86.8429 81.1012 82.0927 73.8294 77.0358C73.107 76.5383 72.4255 77.7173 73.1411 78.2149Z"
      fill="#1D2744"
    />,
    <Path
      d="M64.7447 100.562C67.5662 103.138 70.6672 105.346 74.0339 107.166C74.804 107.582 75.4923 106.409 74.7222 105.987C71.4577 104.229 68.4522 102.102 65.7125 99.601C65.0582 99.008 64.0973 99.969 64.7447 100.562Z"
      fill="#1D2744"
    />,
    <Path
      d="M54.7196 70.5682C58.6383 75.4615 67.2596 81.3771 73.7817 78.3034C74.5723 77.9286 73.8839 76.7564 73.0934 77.1244C67.1778 79.905 59.129 73.9008 55.6805 69.6004C55.1353 68.9189 54.1744 69.8867 54.7196 70.5682Z"
      fill="#1D2744"
    />,
    <Path
      d="M32.1953 140.111C35.378 142.046 38.8333 143.184 42.534 143.58C43.4063 143.668 43.3995 142.305 42.534 142.216C39.0718 141.855 35.855 140.744 32.8836 138.938C32.1339 138.475 31.4456 139.654 32.1953 140.111Z"
      fill="#1D2744"
    />,
  ],
});
