import { useState } from 'react';
import { Platform } from 'react-native';
import appStoreBadge from '../../../../assets/images/promotion/app-store-badge.png';
import googlePlayBadge from '../../../../assets/images/promotion/google-play-badge.png';
import iphoneSplashImage from '../../../../assets/images/promotion/iPhone-splash.png';
import iphoneImage from '../../../../assets/images/promotion/iPhone.png';
import { Heading, Image, Layout, Pressable, SimpleModal, Text } from '../../../components/core';
import { config } from '../../../config';
import { useFindCareUserHasCompletedQuery } from '../../../graphQL';
import { useExternalNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import {
  hasPromoBannerBeenDismissed,
  setPromoBannerDismissed,
} from '../../../utils/authenticationStore';

export const MobilePromotionBanner = (): JSX.Element => {
  const externalNavigate = useExternalNavigate();

  const dismissed = hasPromoBannerBeenDismissed();
  const [isDismissed, setDismissed] = useState(dismissed);

  const { data: hubUserHasCompletedLOCAAssessment } = useFindCareUserHasCompletedQuery();
  const hideBanner = hubUserHasCompletedLOCAAssessment?.findCareUserHasCompleted !== true;

  if (Platform.OS !== 'web' || isDismissed || hideBanner) {
    return <></>;
  }

  const onClose = (): void => {
    setPromoBannerDismissed();
    setDismissed(true);
  };

  const onGooglePlayPress = (): void => {
    externalNavigate(`https://play.google.com/store/apps/details?id=${config.googleStoreId}`);
  };

  const onAppleStorePress = (): void => {
    externalNavigate(`https://apps.apple.com/us/app/${config.appleStoreId}`);
  };

  const headerId = 'mobile-promotion-banner-header';

  return (
    <SimpleModal {...styles.modal} headerId={headerId} onClose={onClose}>
      <Layout.VStack space={8}>
        <Layout.Flex {...styles.screenImagesContainer}>
          <Image
            {...styles.iPhoneImage}
            alt="iPhone Splash Screen"
            aria-hidden
            source={iphoneSplashImage}
            resizeMethod="auto"
            resizeMode="contain"
          />

          <Image
            {...styles.iPhoneImage}
            alt="iPhone Home Screen"
            aria-hidden
            source={iphoneImage}
            resizeMethod="auto"
            resizeMode="contain"
          />
        </Layout.Flex>

        <Layout.VStack>
          <Heading.h2 center id={headerId} level={1}>
            Access care at your finger tips.
          </Heading.h2>

          <Text.para center padding={3}>
            Get the best experience in the Mantra Health mobile app
          </Text.para>

          <Layout.Flex {...styles.badgeWrapper}>
            <Pressable isLink onPress={onGooglePlayPress} testID="button-promotion-google-play">
              <Image
                {...styles.storeBadge}
                source={googlePlayBadge}
                resizeMethod="auto"
                resizeMode="stretch"
                alt="Get it on Google Play"
              />
            </Pressable>

            <Pressable isLink onPress={onAppleStorePress} testID="button-promotion-apple-store">
              <Image
                {...styles.storeBadge}
                source={appStoreBadge}
                resizeMethod="auto"
                resizeMode="stretch"
                alt="Download on the App Store"
              />
            </Pressable>
          </Layout.Flex>
        </Layout.VStack>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  modal: {
    minHeight: '564px',
    padding: 3,
    paddingTop: '55px',
  },

  screenImagesContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 3,
    justifyContent: 'center',
  },

  iPhoneImage: {
    height: '250px',
    width: '45%',
  },

  badgeWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 3,
    justifyContent: 'center',
  },

  storeBadge: {
    height: '44px',
    width: '148px',
  },
});
