import { IStackProps } from 'native-base';
import { Heading, Layout, Text } from '../../../components/core';
import { getStylesheet } from '../../../styles';

type PageHeaderProps = IStackProps & {
  button?: JSX.Element;
  overtext?: string;
  textColor?: string;
  title: string;
  undertext?: string;
};

export const FindCarePageHeader = ({
  button,
  overtext,
  textColor = 'darkText',
  title,
  undertext,
  ...topStyles
}: PageHeaderProps): JSX.Element => {
  return (
    <Layout.HStack {...styles.header} testID="page-header" {...topStyles}>
      <Layout.VStack flexGrow={1} flexShrink={1} space={1}>
        {overtext !== undefined && <Heading.h6 color={textColor}>{overtext}</Heading.h6>}

        <Heading.h2 color={textColor} level={1}>
          {title}
        </Heading.h2>

        {undertext !== undefined && <Text.para color={textColor}>{undertext}</Text.para>}
      </Layout.VStack>

      {button}
    </Layout.HStack>
  );
};

const styles = getStylesheet({
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingY: 4,
    width: '100%',
  },
});
