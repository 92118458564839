import { times } from 'lodash';
import { StyleSheet, TextStyle, ViewStyle } from 'react-native';

/*
 The goal here is to implement Tachyons as needed
 https://tachyons.io/docs/

 spacing, flex, border, text-align, width, opacity, textTransform
*/

const sizeScale = (index: number): number => 16 * 2 ** (index - 1);
const spacingScale = (index: number): number => 4 * 2 ** (index - 1);
const borderScale = (index: number): number => 2 * 2 ** (index - 1);

const SIDES = [
  { key: 'b', style: 'Bottom' },
  { key: 't', style: 'Top' },
  { key: 'l', style: 'Left' },
  { key: 'r', style: 'Right' },
  { key: 'h', style: 'Horizontal' },
  { key: 'v', style: 'Vertical' },
  { key: 'a', style: '' },
] as const;

const DISTANCE = [
  { key: 'w', style: 'width' },
  { key: 'mw', style: 'maxWidth' },
  { key: 'h', style: 'height' },
] as const;

const TEXT_ALIGN = [
  { key: 'l', style: 'left' },
  { key: 'r', style: 'right' },
  { key: 'c', style: 'center' },
  { key: 'j', style: 'justify' },
] as const;

const TEXT_TRANSFORM = [
  { key: 'c', style: 'capitalize' },
  { key: 'l', style: 'lowercase' },
  { key: 'u', style: 'uppercase' },
  { key: 'n', style: 'none' },
] as const;

const COORDINATES = ['top', 'right', 'bottom', 'left'] as const;
const PERCENTS = [10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90, 100] as const;

type FOUR = '0' | '1' | '2' | '3';
type FIVE = FOUR | '4';
type SIX = FIVE | '5';
type EIGHT = SIX | '6' | '7';

type Margins = `m${typeof SIDES[number]['key']}${EIGHT | 'x' | 'xl'}`;
type NegativeMargins = `n${typeof SIDES[number]['key']}${EIGHT | 'x' | 'xl'}`;
type Padding = `p${typeof SIDES[number]['key']}${EIGHT | 'x' | 'xl'}`;
type Distance = `${typeof DISTANCE[number]['key']}${EIGHT}`;
type Coordinates = `${typeof COORDINATES[number]}${'' | 'n'}${FOUR}`;
type TextTransform = `tt${typeof TEXT_TRANSFORM[number]['key']}`;
type TextAlign = `t${typeof TEXT_ALIGN[number]['key']}`;
type Border = `b${Exclude<typeof SIDES[number]['key'], 'h' | 'v'>}`;
type Opacity = `o${typeof PERCENTS[number]}`;
type Width = `w${typeof PERCENTS[number]}`;
type BorderRadius = `br${FIVE}`;
type ZIndex = `z${SIX}`;
type GeneratedKeys =
  | Margins
  | NegativeMargins
  | Padding
  | Distance
  | Coordinates
  | TextTransform
  | TextAlign
  | Border
  | Opacity
  | Width
  | BorderRadius
  | ZIndex;

// @ts-expect-error - This record will be populated, so don't error here.
const generatedStyles: Record<GeneratedKeys, ViewStyle | TextStyle> = {};

/*
 * Margin, Negative Margin, Padding
 * Custom margins: 24px - postfix 'x', 40px - postfix 'xl'
 */

SIDES.forEach(({ key, style }) => {
  // 24px
  generatedStyles[`m${key}x`] = { [`margin${style}`]: 24 };
  generatedStyles[`n${key}x`] = { [`margin${style}`]: -24 };
  generatedStyles[`p${key}x`] = { [`padding${style}`]: 24 };
  // 40px
  generatedStyles[`m${key}xl`] = { [`margin${style}`]: 40 };
  generatedStyles[`n${key}xl`] = { [`margin${style}`]: -40 };
  generatedStyles[`p${key}xl`] = { [`padding${style}`]: 40 };

  times(8, iter => {
    const index = String(iter) as EIGHT;
    generatedStyles[`m${key}${index}`] = { [`margin${style}`]: spacingScale(iter) };
  });
  times(8, iter => {
    const index = String(iter) as EIGHT;
    generatedStyles[`n${key}${index}`] = { [`margin${style}`]: -spacingScale(iter) };
  });
  times(8, iter => {
    const index = String(iter) as EIGHT;
    generatedStyles[`p${key}${index}`] = { [`padding${style}`]: spacingScale(iter) };
  });
});

DISTANCE.forEach(({ key, style }) => {
  times(8, iter => {
    const index = String(iter) as EIGHT;
    generatedStyles[`${key}${index}`] = { [style]: sizeScale(iter) };
  });
});

COORDINATES.forEach(coordinate => {
  times(4, iter => {
    const index = String(iter) as FOUR;
    generatedStyles[`${coordinate}${index}`] = { [coordinate]: sizeScale(iter) };
  });
  times(4, iter => {
    const index = String(iter) as FOUR;
    generatedStyles[`${coordinate}n${index}`] = { [coordinate]: -sizeScale(iter) };
  });
});

TEXT_TRANSFORM.forEach(({ key, style }) => {
  generatedStyles[`tt${key}`] = { textTransform: style };
});

TEXT_ALIGN.forEach(({ key, style }) => {
  generatedStyles[`t${key}`] = { textAlign: style };
});

SIDES.filter(({ key }) => !['h', 'v'].includes(key)).forEach(({ key, style }) => {
  generatedStyles[`b${key}` as Border] = {
    [`border${style}Width`]: 1,
  };
});

PERCENTS.forEach(percent => {
  generatedStyles[`o${percent}`] = { opacity: percent / 100 };
  generatedStyles[`w${percent}`] = { width: `${percent}%` };
});

times(5, iter => {
  const index = String(iter) as FIVE;
  generatedStyles[`br${index}`] = { borderRadius: borderScale(iter) };
});

times(6, iter => {
  const index = String(iter) as SIX;
  generatedStyles[`z${index}`] = { zIndex: iter, elevation: iter };
});

const staticStyles = {
  /* Text */
  italic: { fontStyle: 'italic' },
  bold: { fontFamily: 'DMSans-Bold' },
  underline: { textDecorationLine: 'underline' },

  /* Layout */
  overflowHidden: { overflow: 'hidden' },
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  flexRow: { display: 'flex', flexDirection: 'row' },
  flexRowReverse: { display: 'flex', flexDirection: 'row-reverse' },
  flexColReverse: { display: 'flex', flexDirection: 'column-reverse' },
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  flexShrink1: { flexShrink: 1 },
  flexWrap: { flexWrap: 'wrap' },
  flexNowrap: { flexWrap: 'nowrap' },
  flexWrapReverse: { flexWrap: 'wrap-reverse' },
  itemsStart: { alignItems: 'flex-start' },
  itemsEnd: { alignItems: 'flex-end' },
  itemsCenter: { alignItems: 'center' },
  itemsStretch: { alignItems: 'stretch' },
  justifyStart: { justifyContent: 'flex-start' },
  justifyEnd: { justifyContent: 'flex-end' },
  justifyCenter: { justifyContent: 'center' },
  justifyBetween: { justifyContent: 'space-between' },
  justifyAround: { justifyContent: 'space-around' },
  selfStart: { alignSelf: 'flex-start' },
  selfEnd: { alignSelf: 'flex-end' },
  selfCenter: { alignSelf: 'center' },
  selfBaseline: { alignSelf: 'baseline' },
  selfStretch: { alignSelf: 'stretch' },
  flexAuto: { minWidth: 0, minHeight: 0, flexGrow: 0, flexShrink: 0, flexBasis: 'auto' },
  brBottom: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  brTop: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  center: { marginHorizontal: 'auto' },
  relative: { position: 'relative' },
  absolute: { position: 'absolute' },
  strike: { textDecorationLine: 'line-through' },
} as const;

const tachyons = StyleSheet.create({
  ...generatedStyles,
  ...staticStyles,
});

export default tachyons;
