import { useToast } from 'native-base';
import React from 'react';
import { PageContent, PageFooter, PageHeader, PageLoading } from '../../components/page';
import { useCurrentUserNameQuery } from '../../graphQL';
import { getStylesheet } from '../../styles';
import { NameForm } from './components/NameForm';

export const AccountSettingsPage = (): JSX.Element => {
  const toast = useToast();
  const { data: userNameData, loading, refetch } = useCurrentUserNameQuery();

  const defaultUserName = userNameData?.onboardedHubUser.preferredName ?? '';

  if (loading) {
    return <PageLoading pageName="account settings" />;
  }

  const onSaved = (): void => {
    void refetch();

    toast.show({
      description: 'Account Updated',
      duration: 3000,
    });
  };

  return (
    <PageContent>
      <PageHeader {...styles.header} title="Account Settings" />

      <NameForm defaultUserName={defaultUserName} onSaved={onSaved} />

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  header: {
    marginBottom: 4,
  },
});
