import { compact, defaultTo } from 'lodash';
import { SkillDocument } from '../../../../.slicemachine/prismicio';
import { useSkillsByIds } from '../../../content/skill/hooks/useSkillsByIds';
import { useGetMySkillsAssignmentsQuery } from '../../../graphQL';

type UseAssignedSkillsReturn = [
  skillObjects: AssignedSkillObjects,
  skillsState: {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
  },
];

export type AssignedSkillObjects = Array<{
  skill: SkillDocument<string>;
  moduleId: string;
  completedAt?: string | null;
}>;

type UseAssignedSkillsProps = {
  currentPage: number;
};

export const useAssignedSkills = ({
  currentPage,
}: UseAssignedSkillsProps): UseAssignedSkillsReturn => {
  const { data: assignedSkillsData } = useGetMySkillsAssignmentsQuery({
    variables: { page: currentPage, perPage: 10 },
  });

  const skillAssignments = defaultTo(
    assignedSkillsData?.getMySkillsAssignments?.skillAssignments,
    [],
  );

  // get skillIds from skillAssignments
  const skillIds = skillAssignments.map(skillAssignment => skillAssignment.skillId);

  // get skills from prismic
  const [skills, skillState] = useSkillsByIds({ skillIds });

  const compactSkills = compact(skills);
  const assignedSkills = compact(
    skillAssignments.map(item => {
      const skill = compactSkills.find(({ id }) => id === item.skillId);

      if (!skill) return undefined;

      return {
        skill,
        moduleId: item.moduleId,
        completedAt: item.completedAt,
      };
    }),
  );

  // sort completed incomplete skills to the top
  const orderedAssignedSkills = assignedSkills.sort((skillA, skillB) => {
    if (skillA.completedAt != null && skillB.completedAt == null) {
      return 1;
    } else if (skillA.completedAt == null && skillB.completedAt != null) {
      return -1;
    } else {
      return 0;
    }
  });

  return [
    orderedAssignedSkills,
    {
      idle: skillState.idle,
      loading: skillState.loading,
      loaded: skillState.loaded,
      error: skillState.error,
    },
  ];
};
