import { createContext, ReactNode, useContext, useEffect, useId, useMemo, useReducer } from 'react';

type LoadingState = {
  [id: string]: boolean;
};
type TrackerAction = {
  type: 'register' | 'reset' | 'resolve';
  id: string;
  isLoading: boolean;
};

export type RegisterComponentLoading = (id: string, isLoading: boolean) => () => void;

type LoadTrackerReturn = {
  loading: boolean;
  registerComponent: RegisterComponentLoading;
};

export const loadTrackerContext = (): LoadTrackerReturn => {
  const [loadingState, trackerDispatch] = useReducer(
    (state: LoadingState, action: TrackerAction): LoadingState => {
      if (action.type === 'reset') {
        return {};
      }
      if (action.type === 'register') {
        return {
          ...state,
          [action.id]: action.isLoading,
        };
      }
      if (action.type === 'resolve') {
        return {
          ...state,
          [action.id]: false,
        };
      }
      return state;
    },
    {},
  );

  const loading = useMemo(() => {
    return Object.entries(loadingState).some(([, isLoading]) => isLoading);
  }, [loadingState]);

  const registerComponent = (id: string, isLoading: boolean): (() => void) => {
    trackerDispatch({
      type: 'register',
      id,
      isLoading,
    });

    return (): void => {
      trackerDispatch({
        type: 'resolve',
        id,
        isLoading: false,
      });
    };
  };

  return {
    loading,
    registerComponent,
  };
};

type LoadTrackerContextType = {
  loading: boolean;
  useRegisterComponent: (isLoading: boolean) => void;
};

const LoadTrackerContext = createContext<LoadTrackerContextType>({
  loading: false,
  useRegisterComponent: () => undefined,
});

export const LoadTrackerProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { registerComponent: registerWithHook, loading } = loadTrackerContext();

  const providerValue = {
    loading,
    useRegisterComponent: (isLoading: boolean) => {
      const id = useId();

      useEffect(() => {
        // Make sure to return so it unloads the component if it de-renders.
        return registerWithHook(id, isLoading);
      }, [id, isLoading]);
    },
  };

  return (
    <LoadTrackerContext.Provider value={providerValue}>{children}</LoadTrackerContext.Provider>
  );
};

export const useLoadTrackerContext = (): LoadTrackerContextType => {
  return useContext(LoadTrackerContext);
};
