import * as WebBrowser from 'expo-web-browser';
import { useState } from 'react';
import { Platform } from 'react-native';
import { Button, Heading, Layout, Link, SimpleModal, Text } from '../../../components/core';
import { IconVideo } from '../../../components/icons/IconVideo';
import { useNavigateFromHubMobileToWebLazyQuery } from '../../../graphQL';
import { getRoute, useExternalNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { isDuringHoliday } from '../utils/availability';
import { BrowserIncompatibilityWarning } from './BrowserIncompatibilityWarning';

type VideoContactProps = {
  crisisPhoneNumber: string;
  isDuringServiceHours: boolean;
};

export const VideoContact = (props: VideoContactProps): JSX.Element => {
  if (isDuringHoliday()) {
    return <DuringHoliday {...props} />;
  } else if (!props.isDuringServiceHours) {
    return <AfterServiceHours {...props} />;
  }

  return <DuringServiceHours {...props} />;
};

const CallToAction = ({
  message,
  crisisPhoneNumber,
}: {
  message: string;
  crisisPhoneNumber: string;
}): JSX.Element => (
  <Layout.VStack {...styles.warningBox} space={4}>
    <Heading.h6 center>{message}</Heading.h6>
    {crisisPhoneNumber && (
      <Text.caption center>
        If you need immediate crisis support, call or text{' '}
        <Link.para bold to={`tel:${crisisPhoneNumber}`}>
          {crisisPhoneNumber}
        </Link.para>
      </Text.caption>
    )}
  </Layout.VStack>
);

const VideoCallLayout = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <Layout.VStack {...styles.videoCall} space={2}>
    <Heading.h5>Video call</Heading.h5>
    <Layout.VStack space={4}>{children}</Layout.VStack>
  </Layout.VStack>
);

const DuringServiceHours = ({ crisisPhoneNumber }: { crisisPhoneNumber: string }): JSX.Element => {
  const [showBrowserWarning, setShowBrowserWarning] = useState(false);
  const externalNavigate = useExternalNavigate();
  const [queryForHubWebLoginUrl] = useNavigateFromHubMobileToWebLazyQuery();

  const onVideoCallClick = async (): Promise<void> => {
    if (Platform.OS === 'web') {
      externalNavigate(getRoute('onDemandConfirmInfo', {}));
    } else if (Platform.OS === 'android') {
      setShowBrowserWarning(true);
    } else {
      await openBrowser();
    }
  };

  const openBrowser = async (): Promise<void> => {
    const { data } = await queryForHubWebLoginUrl({ variables: { source: 'onDemand' } });
    const hubWebLoginUrl = data?.navigateFromHubMobileToWeb;

    if (hubWebLoginUrl !== undefined) {
      void WebBrowser.openBrowserAsync(hubWebLoginUrl);
    }
  };

  return (
    <VideoCallLayout>
      <Text.caption>Available everyday from noon - 12 am ET*</Text.caption>
      <Text.paraSmall>
        Connect in 10 minutes or less via video with a mental health staff member who is trained to
        support you in a high-stress situation with sensitivity and expertise.
      </Text.paraSmall>
      <Button.primaryLarge
        testID="button-on-demand-video-call"
        onPress={onVideoCallClick}
        leftIcon={<IconVideo accessibilityLabel="Video" />}
      >
        Get Connected
      </Button.primaryLarge>

      {showBrowserWarning && (
        <SimpleModal
          aria-label="Browser incompatibility"
          onClose={() => setShowBrowserWarning(false)}
          padding={2}
        >
          <BrowserIncompatibilityWarning
            crisisPhoneNumber={crisisPhoneNumber}
            onContinue={openBrowser}
            onClose={() => setShowBrowserWarning(false)}
          />
        </SimpleModal>
      )}
    </VideoCallLayout>
  );
};

const AfterServiceHours = ({ crisisPhoneNumber }: { crisisPhoneNumber: string }): JSX.Element => (
  <VideoCallLayout>
    <Text.caption>Available every day from noon - 12am ET*</Text.caption>
    <CallToAction
      message="On-demand video support is currently offline and will return at noon ET."
      crisisPhoneNumber={crisisPhoneNumber}
    />
  </VideoCallLayout>
);

const DuringHoliday = ({ crisisPhoneNumber }: { crisisPhoneNumber: string }): JSX.Element => (
  <VideoCallLayout>
    <Text.caption>Available every day from noon - 12am ET*</Text.caption>
    <CallToAction
      message="On-demand video support is currently offline for a scheduled holiday."
      crisisPhoneNumber={crisisPhoneNumber}
    />
  </VideoCallLayout>
);

const styles = getStylesheet({
  videoCall: {
    backgroundColor: 'white',
    borderRadius: 8,
    flex: 1,
    padding: 6,
    shadow: 'large',
  },

  warningBox: {
    alignItems: 'center',
    backgroundColor: 'warning.50',
    borderColor: 'warning.300',
    borderRadius: 4,
    borderWidth: 1,
    padding: 5,
  },
});
