import { Layout } from '../../components/core';
import { PageContent, PageFooter } from '../../components/page';
import { View } from '../../content/view/components/View';
import { useHasCompletedAnySkillQuery } from '../../graphQL';
import { getStylesheet } from '../../styles';
import { AboutTheseGuides } from './components/AboutTheseGuides';
import { JumpBackIn } from './components/JumpBackIn';
import { SelfCareHeader } from './components/SelfCareHeader';
import { YourPathway } from './components/YourPathway';

export const SelfCareHomePage = (): JSX.Element => {
  const { data } = useHasCompletedAnySkillQuery();

  const hasCompletedAnySkill = data?.hasCompletedAnySkill ?? false;

  return (
    <PageContent testID="self-care-home">
      <SelfCareHeader />

      <Layout.VStack space={12}>
        <YourPathway />

        {hasCompletedAnySkill && (
          <Layout.View {...styles.intro}>
            <Layout.VStack {...styles.stack}>
              <JumpBackIn />
            </Layout.VStack>
          </Layout.View>
        )}

        <Layout.VStack {...styles.stack} space={84}>
          <View isCollection={true} />

          <View isCollection={false} />

          <AboutTheseGuides />
        </Layout.VStack>
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  intro: {
    backgroundColor: 'secondary.200',
    paddingX: 4,
    paddingY: 8,
  },

  stack: {
    alignSelf: 'center',
    width: '100%',
  },
});
