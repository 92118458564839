import { useGetAllCompletedSkillsForModuleQuery } from '../../../graphQL';
import { useReload } from '../../../utils/useReload';

type SkillsByModuleId = [
  string[],
  {
    loading: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const useCompletedSkillsByModuleId = ({
  moduleId,
  skip,
}: {
  moduleId: string;
  skip?: boolean;
}): SkillsByModuleId => {
  const [shouldSkip, refetch] = useReload();

  const {
    data: completedSkillsData,
    loading: completedLoading,
    error: completedError,
  } = useGetAllCompletedSkillsForModuleQuery({
    variables: { moduleId },
    skip: shouldSkip || skip,
  });

  const completedSkillIds = completedSkillsData?.getAllCompletedSkillsForModule ?? [];

  return [
    completedSkillIds,
    {
      loading: completedLoading,
      error: completedError,
      refetch,
    },
  ];
};
