import React from 'react';
import { Layout, Pressable, Text } from '../../../components/core';
import { IconX } from '../../../components/icons/IconX';
import { getStylesheet } from '../../../styles';

type NewMessageToastFactoryArgs = {
  onClick: () => void;
  onClose: () => void;
};

export const generateNewMessageToast =
  ({ onClick, onClose }: NewMessageToastFactoryArgs) =>
  (): JSX.Element =>
    (
      <Layout.HStack {...styles.newMessageToast} space={3}>
        <Pressable
          onPress={() => {
            onClick();
            onClose();
          }}
          testID="pressable-coach-chat-view-new-messages"
        >
          <Text.para bold color="lightText">
            New Messages - Click to view
          </Text.para>
        </Pressable>

        <Pressable onPress={onClose} testID="pressable-coach-chat-new-messages-dismiss">
          <IconX size={4} color="lightText" />
        </Pressable>
      </Layout.HStack>
    );

const styles = getStylesheet({
  newMessageToast: {
    alignItems: 'center',
    backgroundColor: 'success.500',
    borderRadius: 4,
    padding: 2,
  },
});
