import { useEffect, useState } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { LayoutChangeEvent } from 'react-native';
import { useScrollContext } from '../../../contexts/scrollContext';

export type FormScrollingProps<TFieldValues extends FieldValues> = {
  onLayout?: ReturnType<ReturnType<typeof useFormScrolling<TFieldValues>>['onLayout']>;
};

export function useFormScrolling<
  FormValues extends FieldValues,
  ValidNames = Extract<keyof FormValues, string>,
>(
  errors: FieldErrors<FormValues>,
): {
  onLayout: (name: ValidNames) => (event: LayoutChangeEvent) => void;
  scrollToFirstError: () => void;
} {
  const { scrollTo } = useScrollContext();
  const [yCoordinates, setYCoordinates] = useState<Record<string, number>>({});

  useEffect(() => {
    scrollToFirstError();
  }, [errors, yCoordinates]);

  const scrollToFirstError = (): void => {
    const firstError = Object.keys(errors)[0];

    if (firstError !== undefined && yCoordinates[firstError] !== undefined) {
      scrollTo({ yCoordinate: yCoordinates[firstError], animated: true });
    }
  };

  const setCoordinates = (name: ValidNames, yCoord: number): void => {
    setYCoordinates(prevState => ({
      ...prevState,
      [String(name)]: yCoord,
    }));
  };

  const onLayout = (name: ValidNames) => (event: LayoutChangeEvent) => {
    setCoordinates(name, event.nativeEvent.layout.y);
  };

  return { onLayout, scrollToFirstError };
}
