import { format } from 'date-fns';
import { useCoachOverviewQuery } from '../../graphQL';
import { getStylesheet } from '../../styles';
import { Button, Heading, Layout, SimpleModal, Text } from '../core';

type ConfirmCancelAppointmentModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  appointmentDate: Date;
};

export const ConfirmCancelAppointmentModal = ({
  onClose,
  onConfirm,
  appointmentDate,
}: ConfirmCancelAppointmentModalProps): JSX.Element => {
  const { data: coachOverviewData } = useCoachOverviewQuery();
  const coachName = coachOverviewData?.coachOverview?.coachName ?? 'your coach';
  const formattedAppointmentDate = format(appointmentDate, 'EEE, MMMM d');

  const headerId = 'confirm-cancel-appointment-modal-header';

  return (
    <SimpleModal headerId={headerId} size="md" onClose={onClose}>
      <Layout.VStack {...styles.topStack} space={6}>
        <Layout.VStack space={2}>
          <Heading.h4 id={headerId}>Are you sure you want to cancel?</Heading.h4>

          <Text.para>
            This will cancel your upcoming appointment with {coachName} on{' '}
            {formattedAppointmentDate} and cannot be undone.
          </Text.para>
        </Layout.VStack>

        <Layout.Flex {...styles.buttons}>
          <Button.secondaryMedium testID="button-cancel-appointment-modal-close" onPress={onClose}>
            Go Back
          </Button.secondaryMedium>

          <Button.dangerMedium testID="button-cancel-appointment-modal-confirm" onPress={onConfirm}>
            Confirm
          </Button.dangerMedium>
        </Layout.Flex>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  topStack: {
    paddingTop: 8,
  },

  buttons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 3,
    justifyContent: 'flex-start',
  },
});
