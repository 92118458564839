import { Divider, Menu } from 'native-base';
import React, { useState } from 'react';
import { useBreakpointSwitch } from '../../../contexts/breakpointContext';
import { getRoute, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { Button, Image, Layout, Pressable } from '../../core';
import { PressableProps } from '../../core/Pressable';
import { IconMantraLogo } from '../../icons/IconMantraLogo';
import { IconVenetianMask } from '../../icons/IconVenetianMask';
import { ImageMantraHealth } from '../../images/ImageMantraHealth';
import { HubFeedbackModal } from './components/HubFeedbackModal';
import { ImpersonateModal } from './components/ImpersonateModal';
import { useOrganizationLogo } from './hooks/useOrganizationLogo';
import { useUserImpersonated } from './hooks/useUserImpersonated';
import { useUserInitials } from './hooks/useUserInitials';
import { UserInitials } from './UserInitials';

export const AppHeader = (): JSX.Element => {
  const [hubFeedbackModal, setHubFeedbackModal] = useState(false);
  const [showImpersonateModal, setShowImpersonateModal] = useState(false);

  const navigate = useNavigate();
  const [initials] = useUserInitials();
  const [orgLogoUrl] = useOrganizationLogo();
  const [{ isImpersonated, impersonatingId }] = useUserImpersonated();

  const logout = async (): Promise<void> => {
    navigate(getRoute('logout', {}));
  };

  const onAccountSettingsPressed = (): void => {
    navigate(getRoute('accountSettings', {}));
  };

  const onLogoPress = (): void => {
    navigate(getRoute('home', {}));
  };

  const openFeedbackForm = (): void => {
    setHubFeedbackModal(true);
  };
  const closeFeedbackForm = (): void => {
    setHubFeedbackModal(false);
  };

  const openImpersonateModal = (): void => {
    setShowImpersonateModal(true);
  };
  const closeImpersonateModal = (): void => {
    setShowImpersonateModal(false);
  };

  const userInitialsSize = useBreakpointSwitch({
    mobile: 8,
    desktop: 10,
  });

  const menuTrigger = (triggerProps: PressableProps): JSX.Element => {
    return (
      <Pressable
        {...triggerProps}
        {...styles.userLogo}
        aria-controls={menuId}
        aria-haspopup="menu"
        testID="pressable-user-menu-open"
      >
        <UserInitials size={userInitialsSize} initials={initials} />
      </Pressable>
    );
  };

  const headerImpersonatedStyles = isImpersonated ? styles.headerImpersonated : {};
  const menuId = 'app-header-menu';

  return (
    <Layout.HStack {...styles.header} {...headerImpersonatedStyles} role="banner" safeAreaTop>
      <Layout.HStack {...styles.headerContent}>
        <Pressable
          aria-label="Mantra Health home"
          isLink
          onPress={onLogoPress}
          testID="pressable-header-logo"
        >
          <Layout.HStack {...styles.mantraLogo} space={2}>
            <Layout.HStack alignItems="center" space={3}>
              <IconMantraLogo aria-hidden size={5} />

              <ImageMantraHealth height={16} />
            </Layout.HStack>

            {orgLogoUrl && (
              <>
                <Divider orientation="vertical" backgroundColor="secondary.alpha.40:alpha.40" />
                <Image
                  {...styles.orgLogoImage}
                  alt="Organization Logo"
                  source={{
                    uri: orgLogoUrl,
                  }}
                  resizeMode="contain"
                  resizeMethod="auto"
                />
              </>
            )}
          </Layout.HStack>
        </Pressable>

        <Layout.HStack space={4}>
          {isImpersonated && (
            <Button.tertiarySmall
              {...styles.impersonationMaskButton}
              leftIcon={<IconVenetianMask color="red.600" size={10} />}
              onPress={openImpersonateModal}
              testID="button-open-impersonate-modal"
            />
          )}

          <Menu
            aria-label="User Profile"
            id={menuId}
            placement="bottom right"
            trigger={menuTrigger}
          >
            <Menu.Item onPress={openFeedbackForm}>Share Feedback</Menu.Item>
            <Menu.Item onPress={onAccountSettingsPressed}>Account Settings</Menu.Item>
            <Menu.Item onPress={logout}>Logout</Menu.Item>
          </Menu>
        </Layout.HStack>
      </Layout.HStack>

      {hubFeedbackModal && <HubFeedbackModal onClose={closeFeedbackForm} />}

      {impersonatingId !== undefined && showImpersonateModal && (
        <ImpersonateModal impersonatingId={impersonatingId} onClose={closeImpersonateModal} />
      )}
    </Layout.HStack>
  );
};

const styles = getStylesheet({
  header: {
    backgroundColor: 'secondary.50',
    paddingY: 3,
    position: 'absolute',
    shadow: 'small',
    top: 0,
    width: '100%',
    zIndex: 1,

    _web: {
      position: 'sticky',
    },
  },

  headerImpersonated: {
    backgroundColor: 'red.100',
  },

  headerContent: {
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },

  mantraLogo: {
    alignItems: 'center',
    height: '24px',
    paddingLeft: 6,
  },

  orgLogoImage: {
    height: '100%',
    width: '71px',
  },

  impersonationMaskButton: {
    padding: 0,
  },

  userLogo: {
    paddingRight: 6,
  },
});
