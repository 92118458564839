import { createIcon, Path } from './index';

export const IconX = createIcon({
  title: 'X',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M18 6L6 18"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M6 6L18 18"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
