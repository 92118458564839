import { JSX, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DividerSoft, Heading, Layout, Link, Text } from '../../components/core';
import { PageContent, PageFooter, PageTopBar } from '../../components/page';
import { getRoute } from '../../routes';
import { SignupEmailForm } from './forms/SignupEmailForm';

export const SignupPage = (): JSX.Element => {
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState<string | undefined>();

  const onBackPress = (): void => {
    navigate(getRoute('login', {}));
  };

  const onEmailSuccess = (newUserEmail: string): void => {
    setUserEmail(newUserEmail);
  };

  return (
    <PageContent paddingTop={4} pageSize="medium" testID="signup-page">
      <PageTopBar
        backAccessibilityLabel="Back to Login"
        backTitle="Back"
        onBackPress={onBackPress}
        paddingBottom={8}
      />

      <Layout.VStack space={4}>
        {userEmail === undefined && (
          <Layout.VStack space={4}>
            <Heading.h3>Sign up using your school email address</Heading.h3>

            <SignupEmailForm onSuccess={onEmailSuccess} />

            <DividerSoft />

            <Layout.VStack space={2}>
              <Heading.h5>Referred by a school counselor?</Heading.h5>

              <Text.caption>
                If you haven’t set up your account yet, you’ll need to access the unique activation
                details that were sent to your university email.{' '}
                <Link.caption to={getRoute('loginActivationResend', {})}>
                  Resend activation details
                </Link.caption>
                .
              </Text.caption>
            </Layout.VStack>
          </Layout.VStack>
        )}

        {userEmail !== undefined && (
          <Layout.VStack space={4}>
            <Heading.h3>Verify your email address to activate your account</Heading.h3>

            <Text.para>We sent an email to {userEmail}.</Text.para>

            <Text.para>
              Please check your inbox for a link to verify and activate your account.
            </Text.para>
          </Layout.VStack>
        )}
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
};
