import { StyledProps } from 'native-base';
import type { PlatformProps } from '../types/nativeBaseTypes';

// Fixes bug with Image component types not matching.
type TrimmedStyledProps = Omit<StyledProps, 'tintColor'>;

export type FullStyledProps = TrimmedStyledProps &
  PlatformProps<TrimmedStyledProps> & {
    // Gap now works in react-native but native base doesn't know that yet.
    gap?: number;
  };

export const getStylesheet = <T extends string>(
  styleData: Readonly<Record<T, FullStyledProps>>,
): { [item in T]: FullStyledProps } => {
  return styleData;
};
