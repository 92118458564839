import { CompletionSlideSlice } from '../../../SkillTypes';
import CompletionSlide from './CompletionSlide';
import { QuizCompletionSlide } from './QuizCompletionSlide';
import type { SliceContext } from '../../skillTypes';

type CompletionSlideLoaderProps = {
  context: SliceContext;
  slice: CompletionSlideSlice;
};

export const CompletionSlideLoader = ({
  context,
  slice,
}: CompletionSlideLoaderProps): JSX.Element => {
  const isQuiz = slice.variation === 'quiz';

  if (isQuiz) {
    return <QuizCompletionSlide context={context} slice={slice} />;
  }

  return <CompletionSlide context={context} slice={slice} />;
};

export default CompletionSlideLoader;
