import { defaultTo } from 'lodash';
import { Layout, StatusFeedback, Text } from '../../components/core';
import { PageContent, PageError, PageFooter, PageHeader, PageLoading } from '../../components/page';
import {
  useIsConnectNowServiceAvailableQuery,
  useOrganizationConnectNowQuery,
} from '../../graphQL';
import { getRoute } from '../../routes';
import { getStylesheet } from '../../styles';
import { Offerings } from './components/Offerings';
import { OtherContactMethods } from './components/OtherContactMethods';
import { VideoContact } from './components/VideoContact';

export function ConnectNowLandingPage(): JSX.Element {
  const { data: connectNowData, loading, error, refetch } = useOrganizationConnectNowQuery();
  const connectNowEnabled = defaultTo(
    connectNowData?.organizationConnectNow?.connectNowEnabled,
    false,
  );
  const { data: connectNowServiceAvailability, error: connectNowServiceAvailabilityError } =
    useIsConnectNowServiceAvailableQuery();

  const isDuringServiceHours = connectNowServiceAvailability?.isConnectNowServiceAvailable ?? false;

  if (connectNowServiceAvailabilityError) {
    return <PageError goBackTo="connectNowHome" />;
  }

  if (loading) {
    return <PageLoading pageName="connectnow landing page" />;
  }

  if (!connectNowEnabled) {
    return <PageError goBackTo={getRoute('home', {})} />;
  }

  if (error) {
    return <PageError showContact onRefreshPress={refetch} />;
  }

  const connectNowCrisisContact = connectNowData?.organizationConnectNow?.connectNowData;

  return (
    <PageContent>
      <Layout.VStack space={4}>
        <PageHeader
          title="ConnectNow"
          undertext="Talk with a mental health staff member who is trained to support you in a high-stress situation with sensitivity and expertise."
        />
        <Offerings />
        <Layout.Shift
          {...styles.shiftContainer}
          reverseMobile={!isDuringServiceHours}
          reverseNonMobile={!isDuringServiceHours}
        >
          <VideoContact
            crisisPhoneNumber={connectNowCrisisContact?.crisisCallPhoneNumber ?? ''}
            isDuringServiceHours={isDuringServiceHours}
          />
          {connectNowCrisisContact && (
            <OtherContactMethods connectNowData={connectNowCrisisContact} />
          )}
        </Layout.Shift>
        <StatusFeedback.danger testID="connect-now-emergency-warning">
          <Text.paraLarge>
            If you’re in an emergency or crisis and need immediate help, call 911 or go to the
            nearest emergency room.
          </Text.paraLarge>
        </StatusFeedback.danger>
        <Text.paraSmall marginTop={8} center>
          * Video call support is available 7 days a week from noon - 12am ET, excluding major
          holidays.
        </Text.paraSmall>
        <PageFooter />
      </Layout.VStack>
    </PageContent>
  );
}

const styles = getStylesheet({
  shiftContainer: {
    gap: 3,
  },
});
