import { ComponentProps, useState } from 'react';
import { Dimensions } from 'react-native';
import { Vimeo } from '../../../../../components/vimeo/Vimeo';
import { useBreakpointSwitch } from '../../../../../contexts/breakpointContext';
import { getStylesheet } from '../../../../../styles';
import { useSlideEvents } from '../../hooks/useSlideEvents';
import { ContentWarning } from './ContentWarning';

type VimeoProps = ComponentProps<typeof Vimeo> & {
  slideIndex: number;
  warningLabel?: string;
  warningMessage?: string;
};

export const VideoSlideVimeo = ({
  slideIndex,
  warningLabel,
  warningMessage,
  ...vimeoProps
}: VimeoProps): JSX.Element => {
  const [showWarning, setShowWarning] = useState(false);
  const [disableWarning, setDisableWarning] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>(undefined);

  const onHideWarning = (): void => {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }

    // Hide the warning and never show it again.
    setShowWarning(false);
    setDisableWarning(true);
    setTimerId(undefined);
  };

  useSlideEvents(slideIndex, {
    onDisplay: () => {
      // If the warning has been disabled, don't ever show it again.
      if (disableWarning) {
        return;
      }

      // Show the warning.
      setShowWarning(true);

      // After 10 seconds, hide the warning and never show it again.
      const newTimerId = setTimeout(() => {
        setShowWarning(false);
        setDisableWarning(true);
        setTimerId(undefined);
      }, 10000);

      // Store the timer id, so the timer can be canceled.
      setTimerId(newTimerId);
    },
    onHide: () => {
      if (timerId !== undefined) {
        // Cancel the timer and hide the warning.
        // We want the timer to restart if the slide is displayed again.
        clearTimeout(timerId);
        setShowWarning(false);
      }
    },
  });

  const offset: number = useBreakpointSwitch({
    mobile: 300,
    desktop: 175,
    desktopLarge: 155,
  });

  // Don't let the videos scale to larger than this width.
  const maxContentWidth = 496;
  const warningWidth =
    Math.min(maxContentWidth, (Dimensions.get('window').height - offset) * 0.56) - 32;
  const width = Math.min(maxContentWidth, (Dimensions.get('window').height - offset) * 0.56);

  return (
    <>
      <ContentWarning
        {...styles.warning}
        show={showWarning}
        onHide={onHideWarning}
        label={warningLabel ?? ''}
        message={warningMessage ?? ''}
        width={warningWidth}
      />

      <Vimeo {...vimeoProps} width={width} />
    </>
  );
};

const styles = getStylesheet({
  warning: {
    margin: 4,
    position: 'absolute',
    top: 0,
    zIndex: 1,
  },
});
