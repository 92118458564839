import { config } from '../../config';

import type NativeHeap from '@heap/react-native-heap';

declare global {
  // need to disable enslint to extend window, allow to create heap property
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    heap: typeof NativeHeap & {
      appid: string;
      userId: string;
      identity: string | null;
      // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
      config: Record<string, unknown> | unknown;
    };
  }
}

const heapConfig: Record<string, unknown> = {};

export const heapInitialize = (): void => {
  if (config.heapId === '') {
    // We only want this to show once, so do it here.
    // eslint-disable-next-line no-console
    console.warn('Could not enable analytics -- no analytics id found.');
    return;
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  window.heap = window.heap || [];

  const heap = window.heap;
  heap.appid = config.heapId;
  heap.config = heapConfig;

  const heapScriptElement = document.createElement('script');
  heapScriptElement.type = 'text/javascript';
  heapScriptElement.async = !0;
  heapScriptElement.src = `https://cdn.heapanalytics.com/js/heap-${config.heapId}.js`;

  const firstScriptElement = document.getElementsByTagName('script')[0];
  firstScriptElement?.parentNode?.insertBefore(heapScriptElement, firstScriptElement);

  const newHeap = function (heapProperty: string): () => void {
    return function (...args) {
      // @ts-expect-error - The heap type is a pseudo-array which typescript does not like.
      heap.push([heapProperty].concat(Array.prototype.slice.call(args, 0)));
    };
  };

  const heapProperties = [
    'addEventProperties',
    'addUserProperties',
    'clearEventProperties',
    'identify',
    'resetIdentity',
    'removeEventProperty',
    'setEventProperties',
    'track',
    'unsetEventProperty',
  ];

  heapProperties.forEach(propertyName => {
    // @ts-expect-error - The heap type is a pseudo-array which typescript does not like.
    heap[propertyName] = newHeap(propertyName);
  });
};

export const getHeap = (): typeof window.heap | undefined => {
  if (!isHeapActive()) {
    return undefined;
  }

  return window.heap;
};

export const isHeapActive = (): boolean => {
  return config.heapId !== '';
};
