import { merge } from 'lodash';
import { Theme } from 'native-base';

type VariantThemes = Theme['components']['Badge']['variants'];

type Props = {
  colorScheme: string;
};

const badgeVariantOutline = (props: Props): VariantThemes['outline'] => {
  const styles = {
    borderColor: `${props.colorScheme}.600`,
    borderRadius: '2',
    borderWidth: '1',

    _icon: {
      color: `${props.colorScheme}.600`,
    },
    _text: {
      color: `${props.colorScheme}.600`,
    },

    _dark: {
      borderColor: `${props.colorScheme}.300`,

      _icon: {
        color: `${props.colorScheme}.300`,
      },
      _text: {
        color: `${props.colorScheme}.300`,
      },
    },
  };

  if (props.colorScheme === 'secondary') {
    return merge({}, styles, {
      borderColor: 'secondary.900',

      _icon: {
        color: `secondary.900`,
      },
      _text: {
        color: `secondary.900`,
      },
    });
  }

  return styles;
};

const badgeVariantSolid = (props: Props): VariantThemes['solid'] => {
  const styles = {
    backgroundColor: `${props.colorScheme}.600`,
    borderColor: 'transparent',
    borderRadius: '2',
    borderWidth: '1',

    _icon: {
      color: 'text.50',
    },
    _text: {
      color: 'text.50',
    },
  };

  if (props.colorScheme === 'secondary') {
    return merge({}, styles, {
      backgroundColor: 'secondary.900',
    });
  }

  return styles;
};

const badgeVariantSubtle = (props: Props): VariantThemes['subtle'] => {
  const styles = {
    backgroundColor: `${props.colorScheme}.100`,
    borderColor: 'transparent',
    borderRadius: '2',
    borderWidth: '1',

    _dark: {
      backgroundColor: `${props.colorScheme}.300`,
    },

    _icon: {
      color: `${props.colorScheme}.900`,
    },
    _text: {
      color: `${props.colorScheme}.900`,
    },
  };

  if (props.colorScheme === 'secondary') {
    return merge({}, styles, {
      backgroundColor: 'secondary.alpha.10:alpha.10',
    });
  }

  return styles;
};

export const badgeStyles = {
  Badge: {
    baseStyle: {
      _icon: {
        size: 3,
      },

      _text: {
        fontWeight: 'bold',
      },
    },
    variants: {
      outline: badgeVariantOutline,
      solid: badgeVariantSolid,
      subtle: badgeVariantSubtle,
    },
  },
};
