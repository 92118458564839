import Constants from 'expo-constants';
import { defaultTo } from 'lodash';
import { Platform } from 'react-native';
import sliceMachine from '../sm.json';

const extra = Constants.expoConfig?.extra;

const apiUrl = defaultTo<string>(
  Platform.OS === 'android' ? extra?.REACT_APP_API_URL_ANDROID : extra?.REACT_APP_API_URL,
  '',
);
const envIsDevelopment = extra?.NODE_ENV === 'development';
const heapId = defaultTo<string>(extra?.REACT_APP_HEAP_ID, '');
const sentryDsn = defaultTo<string>(extra?.REACT_APP_SENTRY_DSN, '');
const sentryEnvironment = defaultTo<string>(extra?.REACT_APP_SENTRY_ENVIRONMENT, 'local-dev');
const sentryRelease = defaultTo<string>(extra?.REACT_APP_SENTRY_RELEASE, 'local-dev');
const ipAddress = defaultTo<string>(extra?.REACT_APP_LOCAL_IP, '10.0.2.2');
const webSocketUrl = defaultTo<string>(extra?.REACT_APP_WEB_SOCKET_URL, '');
const zoomContactCenterApiKey = defaultTo<string>(extra?.REACT_APP_ZOOM_CONTACT_CENTER_API_KEY, '');
const zoomContactCenterEntryId = defaultTo<string>(
  extra?.REACT_APP_ZOOM_CONTACT_CENTER_ENTRY_ID,
  '',
);
const googleStoreId = defaultTo<string>(extra?.REACT_APP_GOOGLE_STORE_ID, 'com.mantrahealth.hub');
const appleStoreId = defaultTo<string>(
  extra?.REACT_APP_APPLE_STORE_ID,
  'mantra-health/id6450186860',
);

export const config = {
  apiUrl,
  appleStoreId,
  cmsUrl: `${apiUrl}/hub/cms`,
  authTokenName: 'mantra_token',
  envIsDevelopment,
  googleStoreId,
  heapId,
  sentry: {
    dsn: sentryDsn,
    environment: sentryEnvironment,
    release: sentryRelease,
  },
  sliceMachine,
  ipAddress,
  webSocketUrl,
  zoomContactCenterApiKey,
  zoomContactCenterEntryId,
};
