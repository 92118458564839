import { useAllPrismicDocumentsByIDs } from '@prismicio/react';
import { isEmpty } from 'lodash';
import { useReload } from '../../../utils/useReload';
import { ModuleDocument } from '../ModuleTypes';

type ModuleSimpleById = [
  ModuleDocument[] | undefined,
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const useModulesSimpleByIds = ({ moduleIds }: { moduleIds: string[] }): ModuleSimpleById => {
  const [shouldSkip, refetch] = useReload();

  const [modules, { error, state }] = useAllPrismicDocumentsByIDs<ModuleDocument>(moduleIds, {
    skip: isEmpty(moduleIds) || shouldSkip,
  });

  return [
    modules,
    {
      idle: state === 'idle',
      loading: state === 'loading',
      loaded: state === 'loaded',
      error,
      refetch,
    },
  ];
};
