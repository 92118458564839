import { differenceInMilliseconds, differenceInMinutes, isBefore } from 'date-fns';
import { format } from 'date-fns-tz';
import { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Heading, Layout, Pressable, Text } from '../../../components/core';
import { IconCalendar } from '../../../components/icons/IconCalendar';
import { ConfirmCancelAppointmentModal } from '../../../components/modals/ConfirmCancelAppointmentModal';
import { useLoadTrackerContext } from '../../../contexts/loadTrackerContext';
import {
  UpcomingHubAppointmentQuery,
  useCancelHealthieAppointmentMutation,
  useUpcomingHubAppointmentQuery,
} from '../../../graphQL';
import {
  getRoute,
  useCurrentRouteName,
  useExternalNavigate,
  useLocation,
  useNavigate,
} from '../../../routes';
import { getStylesheet } from '../../../styles';
import { getFormatTzOptions } from '../../../utils/date';

export type UpcomingAppointment = UpcomingHubAppointmentQuery['upcomingHubAppointment'];

type JoinAppointmentWidgetProps = {
  blockEarlyJoining?: boolean;
  onAppointmentLoad?: (appointment: UpcomingAppointment) => void;
};

export const JoinAppointmentWidget = ({
  blockEarlyJoining = false,
  onAppointmentLoad,
}: JoinAppointmentWidgetProps): JSX.Element => {
  const externalNavigate = useExternalNavigate();
  const navigate = useNavigate();
  const currentRouteName = useCurrentRouteName();
  const location = useLocation();

  const {
    data: upcomingAppointmentData,
    loading: upcomingAppointmentLoading,
    refetch: refetchAppointment,
  } = useUpcomingHubAppointmentQuery({
    variables: { delay: location.state?.rescheduled === true ? 5000 : 0 },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ upcomingHubAppointment }) => {
      onAppointmentLoad?.(upcomingHubAppointment);
    },
  });

  const upcomingAppointment = upcomingAppointmentData?.upcomingHubAppointment;

  const [cancelHealthieAppointment, { loading: cancelAppointmentLoading }] =
    useCancelHealthieAppointmentMutation({
      onCompleted: () => {
        void refetchAppointment({ delay: 5000 });
      },
    });

  const [isJoinDisabled, setIsJoinDisabled] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const onConfirmCancel = (): void => {
    setShowCancelModal(false);
    void cancelHealthieAppointment({ variables: { appointmentId: upcomingAppointment?.id ?? '' } });
  };

  const { appointmentIsWithinThreeHoursBefore, formattedAppointmentStart, isBeforeAppointment } =
    useMemo(() => {
      return getAppointmentStartDetails(upcomingAppointment?.start);
    }, [upcomingAppointment]);

  useEffect((): void => {
    if (appointmentIsWithinThreeHoursBefore && !blockEarlyJoining) {
      setIsJoinDisabled(false);
    }
  }, [appointmentIsWithinThreeHoursBefore, blockEarlyJoining]);

  useEffect(() => {
    const upcomingAppointmentStart = upcomingAppointment?.start;

    if (upcomingAppointmentStart == null) {
      return;
    }

    // Check if upcomingAppointmentStart is falsy or not a valid date string
    if (isNaN(new Date(upcomingAppointmentStart).getTime())) {
      return;
    }

    const msToAppointment = differenceInMilliseconds(
      new Date(upcomingAppointmentStart),
      new Date(),
    );

    if (msToAppointment <= 0) {
      // The appointment already started so make sure the user can join.
      setIsJoinDisabled(false);
      return;
    }

    const timeoutId = setTimeout(() => {
      // The appointment should have already started, so make sure the user can join.
      setIsJoinDisabled(false);
    }, msToAppointment);

    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [upcomingAppointment?.start]);

  const { loading: trackerLoading, useRegisterComponent } = useLoadTrackerContext();
  useRegisterComponent(upcomingAppointmentLoading || cancelAppointmentLoading);

  if (upcomingAppointmentLoading || cancelAppointmentLoading || trackerLoading) {
    return <></>;
  }

  if (upcomingAppointment == null) {
    return <></>;
  }

  const rescheduleOrCancelAppointment = (
    <Layout.HStack space={2}>
      <Pressable
        aria-label="Cancel appointment"
        testID="pressable-open-cancel-appointment-modal"
        onPress={() => setShowCancelModal(true)}
      >
        <Text.para bold>Cancel</Text.para>
      </Pressable>

      <Divider orientation="vertical" />

      <Pressable
        aria-label="Reschedule appointment"
        isLink
        testID="pressable-to-reschedule-appointment-page"
        onPress={() =>
          navigate(getRoute('reschedule', { appointmentId: upcomingAppointment.id }), {
            state: { from: currentRouteName },
          })
        }
      >
        <Text.para bold>Reschedule</Text.para>
      </Pressable>
    </Layout.HStack>
  );

  const headerId = 'join-appointment-widget-header';

  return (
    <Layout.HStack {...styles.appointmentWidget} aria-labelledby={headerId} role="region" space={3}>
      <IconCalendar {...styles.icon} aria-hidden size={6} />

      <Layout.VStack space={4} flex={1}>
        <Layout.HStack {...styles.appointmentWidgetTop} space={2}>
          <Layout.VStack {...styles.appointmentText} space={2}>
            <Heading.h5 id={headerId}>{upcomingAppointment.appointmentTypeName}</Heading.h5>

            <Text.caption>
              {formattedAppointmentStart} | {upcomingAppointment.length} min
            </Text.caption>
          </Layout.VStack>

          <Button.successMedium
            testID="button-join-appointment"
            isDisabled={isJoinDisabled}
            onPress={() => externalNavigate(upcomingAppointment.zoomJoinUrl)}
            alignSelf="center"
          >
            Join
          </Button.successMedium>
        </Layout.HStack>

        {isBeforeAppointment && rescheduleOrCancelAppointment}
      </Layout.VStack>

      {showCancelModal && (
        <ConfirmCancelAppointmentModal
          onClose={() => setShowCancelModal(false)}
          onConfirm={() => onConfirmCancel()}
          appointmentDate={new Date(upcomingAppointment?.start)}
        />
      )}
    </Layout.HStack>
  );
};

type AppointmentStartDetails = {
  appointmentIsWithinThreeHoursBefore: boolean;
  formattedAppointmentStart: string;
  isBeforeAppointment: boolean;
};

const getAppointmentStartDetails = (startDate?: string): AppointmentStartDetails => {
  if (startDate === undefined) {
    return {
      appointmentIsWithinThreeHoursBefore: false,
      formattedAppointmentStart: '',
      isBeforeAppointment: false,
    };
  }

  const currentTime = new Date();
  const eventStart = new Date(startDate);
  const formatTzOptions = getFormatTzOptions();

  const minutesToAppointment = differenceInMinutes(eventStart, currentTime);

  return {
    appointmentIsWithinThreeHoursBefore: minutesToAppointment >= 0 && minutesToAppointment <= 180,
    formattedAppointmentStart: format(eventStart, "EEE, MMM d 'at' h:mm aa z", formatTzOptions),
    isBeforeAppointment: isBefore(currentTime, eventStart),
  };
};

const styles = getStylesheet({
  appointmentText: {
    flexShrink: 1,
  },

  appointmentTextTop: {
    flexShrink: 1,
    flexWrap: 'wrap',
  },

  appointmentWidget: {
    backgroundColor: 'white',
    borderLeftColor: 'black',
    borderLeftWidth: 8,
    borderRadius: 4,
    padding: 3,
  },

  appointmentWidgetTop: {
    justifyContent: 'space-between',
  },

  icon: {
    color: 'black',
    minWidth: 6,
  },
});
