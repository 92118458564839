/* eslint-disable */
/*
THIS IS AN AUTO-GENERATED FILE
    ____            .    _  .
   /# /_\_          |\_|/__/|
  |  |/o\o\        / / \/ \  \
  |  \\_/_/       /__|O||O|__ \
 / |_   |        |/_ \_/\_/ _\ |
|  ||\_ ~|       | | (____) | ||
|  ||| \/        \/\___/\__/  //
|  |||_          (_/         ||
 \//  |           |          ||
  ||  |           |          ||\
  ||_  \           \        //_/
  \_|  o|           \______//
  /\___/          __ || __||
 /  ||||__       (____(____)
    (___)_)
run 'yarn codegen' to create a new one
*/
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  JSON: { [key: string]: any };
  LocalDate: any;
  LooseLocalDate: any;
  UserBirthdate: any;
};

export interface Address {
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<StateCodes>;
  updatedAt: Scalars['DateTime'];
  zip: Scalars['String'];
}

export interface AddressInput {
  city?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateCodes>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface AdminAlert {
  careType?: Maybe<CareType>;
  message: Scalars['String'];
  resolveBy?: Maybe<Scalars['String']>;
  type: AdminAlertType;
}

export const AdminAlertType = {
  ConfirmPatientReactivation: 'ConfirmPatientReactivation',
  MissingPharmacy: 'MissingPharmacy',
  ReactivatePatient: 'ReactivatePatient',
  SponsoredCareExpiring: 'SponsoredCareExpiring',
  UnfinishedNote: 'UnfinishedNote',
} as const;

export type AdminAlertType = (typeof AdminAlertType)[keyof typeof AdminAlertType];
export interface AdminEditUserResult {
  email: Scalars['String'];
  id: Scalars['Int'];
}

export interface Allocation {
  appointmentTypes?: Maybe<Array<Scalars['String']>>;
  childOrganizations?: Maybe<Array<Organization>>;
  endTime: Scalars['DateTime'];
  id: Scalars['Float'];
  isFeeForServiceTime?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  provider: Provider;
  providerId: Scalars['Float'];
  purpose: TimeAllocationPurpose;
  repeatsUntil?: Maybe<Scalars['DateTime']>;
  startTime: Scalars['DateTime'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
}

/** The different kinds of alternative care */
export const AlternativeCare = {
  CampusCrisisLine: 'campusCrisisLine',
  CollegeCounselor: 'collegeCounselor',
  EmergencyRoom: 'emergencyRoom',
  MantraTherapy: 'mantraTherapy',
  NoSupport: 'noSupport',
  OffCampusTherapy: 'offCampusTherapy',
  Other: 'other',
  OtherOr998: 'otherOr998',
  Unsure: 'unsure',
} as const;

export type AlternativeCare = (typeof AlternativeCare)[keyof typeof AlternativeCare];
export interface AnonymousNavigateFromPatientPortalInput {
  target: AnonymousNavigateFromPatientPortalTarget;
}

export interface AnonymousNavigateFromPatientPortalResult {
  url: Scalars['String'];
}

export const AnonymousNavigateFromPatientPortalTarget = {
  Login: 'Login',
  LoginActivationResend: 'LoginActivationResend',
  ResetPassword: 'ResetPassword',
  Signup: 'Signup',
} as const;

export type AnonymousNavigateFromPatientPortalTarget =
  (typeof AnonymousNavigateFromPatientPortalTarget)[keyof typeof AnonymousNavigateFromPatientPortalTarget];
export interface Answer {
  answer?: Maybe<Scalars['String']>;
  answerKey?: Maybe<Scalars['JSON']>;
  key: Scalars['String'];
  legend?: Maybe<Array<Scalars['String']>>;
  multiText?: Maybe<Array<Scalars['String']>>;
  score?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  textForProviders?: Maybe<Scalars['String']>;
  value: Scalars['JSON'];
}

export interface AnswerSubmission {
  answerKey?: InputMaybe<Scalars['JSON']>;
  key: Scalars['String'];
  multiText?: InputMaybe<Array<Scalars['String']>>;
  score?: InputMaybe<Scalars['Float']>;
  text?: InputMaybe<Scalars['String']>;
}

export interface AppointmentCharge {
  amountInCents: Scalars['Float'];
  capturedAt: Scalars['DateTime'];
  chargeType: AppointmentChargeType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
}

export const AppointmentChargeType = {
  Copay: 'Copay',
  LateCancellationFee: 'LateCancellationFee',
  NoShowFee: 'NoShowFee',
  PatientResponsibility: 'PatientResponsibility',
  SelfPay: 'SelfPay',
} as const;

export type AppointmentChargeType =
  (typeof AppointmentChargeType)[keyof typeof AppointmentChargeType];
export interface AppointmentFeedbackInput {
  audioQualityDown?: InputMaybe<CallQuality>;
  audioQualityUp?: InputMaybe<CallQuality>;
  comments?: InputMaybe<Scalars['String']>;
  issues?: InputMaybe<Array<Scalars['String']>>;
  overallRating?: InputMaybe<Scalars['Float']>;
  userDevice?: InputMaybe<Scalars['JSON']>;
  videoQualityDown?: InputMaybe<CallQuality>;
  videoQualityUp?: InputMaybe<CallQuality>;
  videoRating?: InputMaybe<Scalars['Float']>;
}

export interface AppointmentTemplate {
  appointmentType: Scalars['String'];
  careType: CareType;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['Float'];
  organization: Organization;
}

export interface AppointmentTemplateUpdate {
  appointmentType: Scalars['String'];
  careType: Scalars['String'];
  duration: Scalars['Int'];
}

export interface Assessment {
  id: Scalars['Float'];
  key: AssessmentKey;
  questionnaires: Array<Questionnaire>;
}

export const AssessmentKey = {
  AdjustedIntake: 'AdjustedIntake',
  Bipolar1Assessment: 'Bipolar1Assessment',
  Bipolar1Screener: 'Bipolar1Screener',
  CoachingExecutiveFunctioningKickoff: 'CoachingExecutiveFunctioningKickoff',
  CoachingExecutiveFunctioningWellnessAssessment: 'CoachingExecutiveFunctioningWellnessAssessment',
  CoachingKickOff: 'CoachingKickOff',
  CoachingWellnessAssessment: 'CoachingWellnessAssessment',
  FindCare: 'FindCare',
  Intake: 'Intake',
  MedicalHistory: 'MedicalHistory',
  Scales: 'Scales',
  SelfRefer1Screener: 'SelfRefer1Screener',
} as const;

export type AssessmentKey = (typeof AssessmentKey)[keyof typeof AssessmentKey];
export interface AssessmentModel {
  asrsv11: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  gad7: Scalars['Float'];
  id: Scalars['Float'];
  phq9: Scalars['Float'];
  result: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
}

export interface AssessmentRequestModel {
  complete: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  key: AssessmentKey;
}

export interface AssessmentResponse {
  createdAt: Scalars['DateTime'];
  followUpRequest?: Maybe<AssessmentRequestModel>;
  id: Scalars['Float'];
  provider?: Maybe<Provider>;
  questionnaires: Array<QuestionnaireResult>;
  specialConditions?: Maybe<SpecialConditions>;
}

export interface AssignSkillToUserResponse {
  isDuplicate: Scalars['Boolean'];
  wasAssigned: Scalars['Boolean'];
}

export interface AssignableSkill {
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
  title: Scalars['String'];
}

export interface AssignmentPagination {
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export interface AsynchronousJob {
  id: Scalars['Float'];
  status: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}

export interface AuthInstructions {
  authMethod: AuthMethod;
  hasPreviousSSOLogin?: Maybe<Scalars['Boolean']>;
  organizationAbbreviation?: Maybe<Scalars['String']>;
  samlEntryPoint?: Maybe<Scalars['String']>;
}

export const AuthMethod = {
  Password: 'Password',
  Saml: 'SAML',
} as const;

export type AuthMethod = (typeof AuthMethod)[keyof typeof AuthMethod];
export interface Availability {
  allocationId: Scalars['Float'];
  blockers: Array<AvailabilityBlockedReason>;
  careType: CareType;
  end: Scalars['String'];
  isFeeForServiceTime?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['Float']>;
  provider: Provider;
  providerId: Scalars['Float'];
  start: Scalars['String'];
}

export const AvailabilityBlockedReason = {
  BackToBackIntake: 'BackToBackIntake',
  ContractSessions: 'ContractSessions',
  DedicatedHoursMaxedForWeek: 'DedicatedHoursMaxedForWeek',
  FfsAllocationBlockedForSameDay: 'FFSAllocationBlockedForSameDay',
  IntakesMaxedForDay: 'IntakesMaxedForDay',
  NoDgmHoursLeftForState: 'NoDGMHoursLeftForState',
  NoDgmIntakesLeftForState: 'NoDGMIntakesLeftForState',
  SessionsModelWeeklyIntakes: 'SessionsModelWeeklyIntakes',
  SessionsModelWeeklySessions: 'SessionsModelWeeklySessions',
  StudentCapacity: 'StudentCapacity',
} as const;

export type AvailabilityBlockedReason =
  (typeof AvailabilityBlockedReason)[keyof typeof AvailabilityBlockedReason];
export interface AvailabilityResult {
  data: Array<Availability>;
  globalBlockers: Array<SimpleGlobalAvailabilityBlocker>;
  interval: Scalars['Float'];
}

export interface AvailabilityV4 {
  availability: Array<Availability>;
  globalBlockers: Array<SimpleGlobalAvailabilityBlocker>;
}

/** Calendar events show events related to a patient. */
export interface CalendarEvent {
  adminCrisisProtocolEngagedAt?: Maybe<Scalars['DateTime']>;
  adminCrisisTeamNotifiedAt?: Maybe<Scalars['DateTime']>;
  adminHostCrisisId?: Maybe<Scalars['String']>;
  appointmentType: Scalars['String'];
  cancelledOrRescheduleWithin: Scalars['Boolean'];
  careType: CareType;
  changedOutOfPolicy: Scalars['Boolean'];
  charges: Array<AppointmentCharge>;
  checkedInAt?: Maybe<Scalars['DateTime']>;
  complete: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  finalizedInsuranceClaim?: Maybe<FinalizedInsuranceClaim>;
  id: Scalars['Float'];
  /** Is the appointment blocked by patient tasks */
  isBlocked: Scalars['Boolean'];
  medicalNote?: Maybe<MedicalNote>;
  provider: Provider;
  providerId: Scalars['Float'];
  providerIncident?: Maybe<ProviderIncident>;
  requiresProviderFeedback: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
  status: Scalars['String'];
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusChanges?: Maybe<Array<CalendarStatusChange>>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Float'];
  usesDedicatedGroupModelHours?: Maybe<Scalars['Boolean']>;
  videoSessionId: Scalars['String'];
  videoToken: Scalars['String'];
}

/** Calendar events show events related to a patient. */
export type CalendarEventCancelledOrRescheduleWithinArgs = {
  diffHours: Scalars['Float'];
};

/** Calendar events show events related to a patient. */
export type CalendarEventRequiresProviderFeedbackArgs = {
  includeCurrentAppointment?: InputMaybe<Scalars['Boolean']>;
};

/** Calendar events show events related to a patient. */
export type CalendarEventStatusChangedAtArgs = {
  toStatuses: Array<Scalars['String']>;
};

export interface CalendarStatusChange {
  actingProvider?: Maybe<Provider>;
  createdAt: Scalars['DateTime'];
  fromStatus: Scalars['String'];
  id: Scalars['Float'];
  toStatus: Scalars['String'];
}

export interface CallQuality {
  bitrate: Scalars['Float'];
  packetLossRatio: Scalars['Float'];
}

export interface CampusOrgMappingInput {
  campus: Scalars['String'];
  organizationId: Scalars['Int'];
}

export interface CampusOrgMappingsInput {
  campus: Scalars['String'];
  organizationId: Scalars['Int'];
}

export interface CampusTeamMemberInput {
  providerId: Scalars['Int'];
  relationshipType: UniversityRelationship;
}

export interface CampusTeamRelationship {
  provider: Provider;
  relationshipType: UniversityRelationship;
  user: User;
}

export interface CampusTeamRelationshipEvents {
  events: Array<Event>;
  relationship: CampusTeamRelationship;
}

export interface CapacityUsage {
  endTime: Scalars['DateTime'];
  entries: Array<CareTypeUsage>;
  startTime: Scalars['DateTime'];
}

export interface CareEligibility {
  isUserEligible?: Maybe<Scalars['Boolean']>;
  isUserUnderage: Scalars['Boolean'];
  minimumAge: Scalars['Float'];
  minorConsentNeeded: Scalars['Boolean'];
  permittedCareTypes: Array<CareType>;
}

export interface CareRecommendation {
  recommendation?: Maybe<Scalars['String']>;
}

export interface CareRecommendationResponse {
  careRecommendation?: Maybe<RecommendationOptions>;
  connectNowEnabled: Scalars['Boolean'];
  isUserDeactivated?: Maybe<Scalars['Boolean']>;
  onDemandEnabled: Scalars['Boolean'];
  togetherAllEnabled: Scalars['Boolean'];
}

export interface CareSessionInfo {
  careType: CareType;
  completedAfter?: Maybe<Scalars['DateTime']>;
  completedAppts: Scalars['Float'];
  flowLimit?: Maybe<Scalars['Float']>;
  remainingAppts?: Maybe<Scalars['Float']>;
  unlimitedAppts: Scalars['Boolean'];
  user: User;
}

export const CareStatus = {
  Active: 'Active',
  Cancelled: 'Cancelled',
  Deleted: 'Deleted',
  Discharged: 'Discharged',
  New: 'New',
  OnHold: 'OnHold',
  ScreenedOut: 'ScreenedOut',
} as const;

export type CareStatus = (typeof CareStatus)[keyof typeof CareStatus];
export interface CareTeamChannel {
  channel: Channel;
  id: Scalars['Float'];
  user: User;
}

export interface CareTeamPreference {
  key: Scalars['String'];
  value: Scalars['JSON'];
}

export interface CareTeamReferral {
  activePatients: Scalars['Float'];
  activeReferrals: Scalars['Float'];
  provider: Provider;
  referralCompletionRate: Scalars['Float'];
  referralsMade: Scalars['Float'];
}

export const CareType = {
  Psychiatry: 'Psychiatry',
  Therapy: 'Therapy',
} as const;

export type CareType = (typeof CareType)[keyof typeof CareType];
export interface CareTypeUsage {
  careType: CareType;
  minutesAvailable: Scalars['Int'];
  minutesUsed: Scalars['Int'];
}

export interface Channel {
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  lastMessage?: Maybe<MessageV2>;
  messages: Array<MessageV2>;
  /** all providers who have sent messages in the channel */
  uniqProviders: Array<Provider>;
  unreadMessageCount: Scalars['Float'];
  unreadMessages: Array<MessageV2>;
}

export const ClaimAction = {
  Note: 'Note',
  StatusChange: 'StatusChange',
} as const;

export type ClaimAction = (typeof ClaimAction)[keyof typeof ClaimAction];
export interface CoachChatMessage {
  author: CoachChatMessageAuthor;
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
}

export const CoachChatMessageAuthor = {
  Coach: 'coach',
  Patient: 'patient',
} as const;

export type CoachChatMessageAuthor =
  (typeof CoachChatMessageAuthor)[keyof typeof CoachChatMessageAuthor];
export interface CoachChatUpdate {
  action: Scalars['String'];
  message?: Maybe<CoachChatMessage>;
  messageId: Scalars['String'];
}

export interface CoachOverview {
  coachAvatarUrl?: Maybe<Scalars['String']>;
  coachName: Scalars['String'];
  coachingEndDate?: Maybe<Scalars['String']>;
  coachingStartDate?: Maybe<Scalars['String']>;
  hasUnreadMessages: Scalars['Boolean'];
}

export interface CoachingAssessmentProgress {
  completed: Scalars['Boolean'];
  key: CoachingAssessments;
}

export interface CoachingIntakeProgress {
  setGoalsCompleted: Scalars['Boolean'];
  wellnessAssessmentCompleted: Scalars['Boolean'];
}

export interface CoachingIntakeProgressV2 {
  coachingAssessments: Array<CoachingAssessmentProgress>;
}

export interface ConfigurationOption {
  description: Scalars['String'];
  key: Scalars['String'];
  subText?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  value: Scalars['JSON'];
}

/** The different kinds of alternative care */
export const ConnectNowAlternativeCare = {
  CampusCrisisLine: 'campusCrisisLine',
  CollegeCounselor: 'collegeCounselor',
  EmergencyRoom: 'emergencyRoom',
  MantraTherapy: 'mantraTherapy',
  NoSupport: 'noSupport',
  OffCampusTherapy: 'offCampusTherapy',
  Other: 'other',
  OtherOr998: 'otherOr998',
  Unsure: 'unsure',
} as const;

export type ConnectNowAlternativeCare =
  (typeof ConnectNowAlternativeCare)[keyof typeof ConnectNowAlternativeCare];
/** The main concerns of a user */
export const ConnectNowMainConcerns = {
  Academics: 'Academics',
  AnxietyStress: 'AnxietyStress',
  CareerPlanning: 'CareerPlanning',
  ConcernForSomeoneElse: 'ConcernForSomeoneElse',
  Depression: 'Depression',
  Family: 'Family',
  FeelingDown: 'FeelingDown',
  Finances: 'Finances',
  FriendsRoommates: 'FriendsRoommates',
  Grief: 'Grief',
  HealthConcerns: 'HealthConcerns',
  Lgbtqia: 'LGBTQIA',
  LifeChanges: 'LifeChanges',
  Loneliness: 'Loneliness',
  Other: 'Other',
  Relationship: 'Relationship',
  SelfInjury: 'SelfInjury',
  SubstanceAbuse: 'SubstanceAbuse',
  SuicidalThoughts: 'SuicidalThoughts',
  Work: 'Work',
} as const;

export type ConnectNowMainConcerns =
  (typeof ConnectNowMainConcerns)[keyof typeof ConnectNowMainConcerns];
export interface ConnectNowPreEncounterInput {
  alternativeCare: ConnectNowAlternativeCare;
  currentLocation: Scalars['String'];
  distressLevel: Scalars['Int'];
  mainConcern: ConnectNowMainConcerns;
  otherAlternativeCare?: InputMaybe<Scalars['String']>;
  otherMainConcern?: InputMaybe<Scalars['String']>;
  preferredPhoneNumber: Scalars['String'];
  suicidalThoughts: ConnectNowSuicidalThoughts;
}

export interface ConnectNowReportsInput {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
}

export interface ConnectNowSession {
  callStartTime: Scalars['DateTime'];
  callType: Scalars['String'];
  callerFirstName: Scalars['String'];
  callerLastName: Scalars['String'];
  callerPreferredName?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  healthieNoteId: Scalars['String'];
  id: Scalars['Float'];
  organization?: Maybe<ConnectNowSessionOrganization>;
  userId?: Maybe<Scalars['Float']>;
}

export interface ConnectNowSessionDocument {
  expiringUrl: Scalars['String'];
}

export interface ConnectNowSessionDocumentOutput {
  document?: Maybe<ConnectNowSessionDocument>;
}

export interface ConnectNowSessionOrganization {
  id: Scalars['Float'];
  name: Scalars['String'];
}

/** The different kinds of suicidal thoughts */
export const ConnectNowSuicidalThoughts = {
  DuringMyLifeTime: 'DuringMyLifeTime',
  Never: 'Never',
  WithinThePastMonth: 'WithinThePastMonth',
  WithinThePastYear: 'WithinThePastYear',
} as const;

export type ConnectNowSuicidalThoughts =
  (typeof ConnectNowSuicidalThoughts)[keyof typeof ConnectNowSuicidalThoughts];
export interface Contact {
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneValidated?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['Boolean']>;
  relationship?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId: Scalars['Int'];
}

export interface ContactUpsertInput {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  relationship?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
}

export interface ContinuityOfCareEligibilityCarePeriodValues {
  currentPeriod: OrganizationCarePeriodModel;
  firstDayOfBreak?: Maybe<Scalars['String']>;
  isCarePeriodEnding?: Maybe<Scalars['Boolean']>;
  isEndingDueToBreak?: Maybe<Scalars['Boolean']>;
  lastDayOfBreak?: Maybe<Scalars['String']>;
  lastDayOfTerm?: Maybe<Scalars['String']>;
  nextPeriod?: Maybe<OrganizationCarePeriodModel>;
}

export interface ContinuityOfCareEligibilityInput {
  additionalNotes?: InputMaybe<Scalars['String']>;
  careType: CareType;
  enrollment: Scalars['Boolean'];
  interestedInContinuingCare: Scalars['Boolean'];
  paymentType: Scalars['String'];
  providerId?: InputMaybe<Scalars['Float']>;
  remainingSessions?: InputMaybe<Scalars['Float']>;
  residentialLocationDuringBreak: Scalars['String'];
  userId: Scalars['Float'];
}

export interface ContinuityOfCareEligibilityModel {
  additionalNotes?: Maybe<Scalars['String']>;
  careType: CareType;
  completedBy: Provider;
  completedById: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  enrollment: Scalars['Boolean'];
  id: Scalars['Int'];
  interestedInContinuingCare: Scalars['Boolean'];
  paymentType: Scalars['String'];
  provider?: Maybe<Provider>;
  providerId: Scalars['Float'];
  remainingSessions?: Maybe<Scalars['Float']>;
  residentialLocationDuringBreak: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Float'];
}

export interface ContinuityOfCareEligibilityResult {
  canContinueCare: Scalars['Boolean'];
  enrollment: Scalars['Boolean'];
  interestedInContinuingCare: Scalars['Boolean'];
  isEligible: Scalars['Boolean'];
  needsReferral: Scalars['Boolean'];
  paymentType: PaymentType;
  providerInState: Scalars['Boolean'];
}

export interface ContinuityOfCareEligibilityValidation {
  careType: CareType;
  paymentType: PaymentType;
}

export interface ContinuityOfCareModel {
  careType: CareType;
  completedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  nextSteps: NextSteps;
  pendingUser?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  requestedCareOutOfState?: Maybe<Scalars['Boolean']>;
  requiresReferral?: Maybe<Scalars['Boolean']>;
  secondaryState?: Maybe<StateCodes>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
}

export interface Contract {
  id: Scalars['Int'];
  psychiatryWeeks: ContractWeeks;
  scope: ContractScope;
  therapyWeeks: ContractWeeks;
  utilization: ContractUtilization;
}

export interface ContractCareTypeUtilization {
  sessionsRemaining: Scalars['Float'];
  sessionsScheduled: Scalars['Float'];
  sessionsUtilized: Scalars['Float'];
  studentCapacityRemaining: Scalars['Float'];
  studentCapacityUtilized: Scalars['Float'];
}

export interface ContractScope {
  endDate: Scalars['LocalDate'];
  psychiatryPatients: Scalars['Int'];
  psychiatrySessions: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  therapyPatients: Scalars['Int'];
  therapySessions: Scalars['Int'];
}

export interface ContractUtilization {
  psychiatryUsage: ContractCareTypeUtilization;
  therapyUsage: ContractCareTypeUtilization;
}

export interface ContractWeek {
  endDate: Scalars['LocalDate'];
  id: Scalars['Int'];
  intakesMax: Scalars['Int'];
  intakesScheduled: Scalars['Int'];
  intakesUtilized: Scalars['Int'];
  sessionsMax: Scalars['Int'];
  sessionsScheduled: Scalars['Int'];
  sessionsUtilized: Scalars['Int'];
  startDate: Scalars['LocalDate'];
}

export interface ContractWeeks {
  current?: Maybe<ContractWeek>;
  past: Array<ContractWeek>;
  upcoming: Array<ContractWeek>;
}

export interface CreateAllocationInput {
  appointmentTypes?: InputMaybe<Array<Scalars['String']>>;
  childOrganizationIds?: InputMaybe<Array<Scalars['Float']>>;
  endTime: Scalars['DateTime'];
  existingId?: InputMaybe<Scalars['Float']>;
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  purpose: TimeAllocationPurpose;
  repeatsUntil?: InputMaybe<Scalars['DateTime']>;
  startTime: Scalars['DateTime'];
}

export interface CreateCareTransition {
  careType: CareType;
  nextSteps: NextSteps;
  reason?: InputMaybe<Scalars['String']>;
  requiresReferral?: InputMaybe<Scalars['Boolean']>;
  secondaryState?: InputMaybe<StateCodes>;
  userId: Scalars['Int'];
}

export interface CreateContractError {
  errorCode: CreateContractErrorCode;
  message: Scalars['String'];
}

export const CreateContractErrorCode = {
  InvalidDateRange: 'InvalidDateRange',
  MainOrgOnly: 'MainOrgOnly',
} as const;

export type CreateContractErrorCode =
  (typeof CreateContractErrorCode)[keyof typeof CreateContractErrorCode];
export interface CreateContractInput {
  endDate: Scalars['LooseLocalDate'];
  organizationId: Scalars['Float'];
  psychiatryPatients?: InputMaybe<Scalars['Int']>;
  psychiatrySessions?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['LooseLocalDate'];
  therapyPatients?: InputMaybe<Scalars['Int']>;
  therapySessions?: InputMaybe<Scalars['Int']>;
}

export type CreateContractResult = CreateContractError | CreateContractSuccess;

export interface CreateContractSuccess {
  id: Scalars['Int'];
}

export interface CreateFileUpload {
  globallyShared: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  type: UploadType;
  userId?: InputMaybe<Scalars['Float']>;
}

export interface CreateIntake {
  endTime: Scalars['DateTime'];
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId: Scalars['Float'];
  startTime: Scalars['DateTime'];
}

export interface CreateOrganization {
  abbreviation: Scalars['String'];
  careTypes: Array<CareType>;
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
  emailDomains: Array<Scalars['String']>;
  entitlements: Array<OrganizationEntitlementInput>;
  name: Scalars['String'];
  type: OrganizationType;
}

export interface CreateOrganizationAnnouncementInput {
  details?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Float'];
  title: Scalars['String'];
}

export interface CreateProviderStateAllocationsModelInput {
  endDate: Scalars['String'];
  maxHours: Scalars['Float'];
  maxIntakeHours: Scalars['Float'];
  providerStateId: Scalars['Int'];
  startDate: Scalars['String'];
}

export interface CreateStaff {
  classification: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  npi?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Float'];
  phone: Scalars['String'];
  postNominalTitles: Scalars['String'];
  role: CreateStaffRole;
}

export const CreateStaffRole = {
  UniversityAdmin: 'universityAdmin',
  UniversityReferrer: 'universityReferrer',
  UniversityStaff: 'universityStaff',
  UniversitySupportingClinician: 'universitySupportingClinician',
} as const;

export type CreateStaffRole = (typeof CreateStaffRole)[keyof typeof CreateStaffRole];
export interface CreateTestUser {
  careTypes: Array<CareType>;
  completeCheckout?: InputMaybe<Scalars['Boolean']>;
  completeProfile?: InputMaybe<Scalars['Boolean']>;
  defaultPaymentSource?: InputMaybe<PaymentSource>;
  includeAppointmentFeedback?: InputMaybe<Scalars['Boolean']>;
  medicalHistory?: InputMaybe<Scalars['Boolean']>;
  mockMessages?: InputMaybe<Scalars['Boolean']>;
  numAppointments?: InputMaybe<Scalars['Float']>;
  numAssessments?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  psychiatristId?: InputMaybe<Scalars['Float']>;
  referralSource: ReferralSource;
  therapistId?: InputMaybe<Scalars['Float']>;
  withPharmacy?: InputMaybe<Scalars['Boolean']>;
}

export interface CreateTicketInput {
  assigneeId?: InputMaybe<Scalars['Float']>;
  category: TicketCategory;
  description?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<TicketPriority>;
  resolveIn24Hrs?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
}

export interface CreateUser {
  birthDate: Scalars['UserBirthdate'];
  campusTeam?: InputMaybe<Array<CampusTeamMemberInput>>;
  careTypes: Array<CareType>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  geoState: StateCodes;
  lastName: Scalars['String'];
  organizationId: Scalars['Float'];
  phone: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
  samlUniqueId?: InputMaybe<Scalars['String']>;
  suggestedProviders?: InputMaybe<Array<Scalars['Float']>>;
}

export interface CreateUserDemographicsInput {
  academicProgram: Scalars['String'];
  ethnicity: Scalars['String'];
  genderIdentity: Scalars['String'];
  isInternationalStudent: Scalars['Boolean'];
  sexualOrientation: Scalars['String'];
  yearInProgram: Scalars['String'];
}

export interface CrisisTeamHours {
  dayOfWeek: DayOfWeek;
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
}

export interface CrisisTeamHoursInput {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
}

export interface CrisisTeamRelationship {
  crisisPhone: Scalars['String'];
  organization: Organization;
  provider: Provider;
}

export interface CustomClinicalRecConfig {
  /** The url for the call to action button on the custom clinical care recommendation card. */
  customClinicalRecButtonLink?: Maybe<Scalars['String']>;
  /** The text for the call to action button on the custom clinical care recommendation card. */
  customClinicalRecButtonText?: Maybe<Scalars['String']>;
  customClinicalRecDescription: Scalars['String'];
  customClinicalRecTitle: Scalars['String'];
}

export interface DataSharingConsent {
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  revokedAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
}

export interface DataSharingRequest {
  canProviderSendReminder: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  lastReminderSentDate?: Maybe<Scalars['DateTime']>;
  pending: Scalars['Boolean'];
  provider: Provider;
}

export const DayOfWeek = {
  Friday: 'Friday',
  Monday: 'Monday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  Thursday: 'Thursday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
} as const;

export type DayOfWeek = (typeof DayOfWeek)[keyof typeof DayOfWeek];
export interface DdhFileReport {
  callId: Scalars['String'];
  callStart: Scalars['DateTime'];
  ddhFileSuccessId: Scalars['Float'];
  id: Scalars['Float'];
}

export interface DdhFileSuccess {
  ddhFileEntityId: Scalars['Float'];
  id: Scalars['Float'];
  organizationId: Scalars['Float'];
  reportDate: Scalars['String'];
  reports?: Maybe<Array<DdhFileReport>>;
  zipLink: Scalars['String'];
}

export interface DebugUserCareFlow {
  careStatus: CareStatus;
  careType: CareType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  paymentSource: PaymentSource;
  taskStatus: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  validEnd?: Maybe<Scalars['DateTime']>;
  validStart: Scalars['DateTime'];
}

export interface Dependency {
  foreignKey?: Maybe<Scalars['String']>;
  optionText?: Maybe<Scalars['String']>;
}

export interface DependentQuestions {
  /** The question is required if <target> question is blank. */
  requiredIfEmpty?: Maybe<Scalars['Boolean']>;
  requiredIfValues?: Maybe<Array<Scalars['String']>>;
  targetKey: Scalars['String'];
}

export const DiscountType = {
  PercentDiscount: 'PercentDiscount',
  ReplacePrice: 'ReplacePrice',
} as const;

export type DiscountType = (typeof DiscountType)[keyof typeof DiscountType];
export interface EditContractScopeError {
  errorCode: EditContractScopeErrorCode;
  message: Scalars['String'];
}

export const EditContractScopeErrorCode = {
  ContractRangeTooLong: 'ContractRangeTooLong',
  InvalidDateRange: 'InvalidDateRange',
  OverlappingContracts: 'OverlappingContracts',
} as const;

export type EditContractScopeErrorCode =
  (typeof EditContractScopeErrorCode)[keyof typeof EditContractScopeErrorCode];
export interface EditContractScopeInput {
  endDate?: InputMaybe<Scalars['LooseLocalDate']>;
  id: Scalars['Int'];
  psychiatryPatients?: InputMaybe<Scalars['Int']>;
  psychiatrySessions?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['LooseLocalDate']>;
  therapyPatients?: InputMaybe<Scalars['Int']>;
  therapySessions?: InputMaybe<Scalars['Int']>;
}

export type EditContractScopeResult = EditContractScopeError | EditContractScopeSuccess;

export interface EditContractScopeSuccess {
  id: Scalars['Int'];
}

export const EditContractWeekErrorCode = {
  IntakeGreaterThanSessions: 'IntakeGreaterThanSessions',
  WeekNotFound: 'WeekNotFound',
} as const;

export type EditContractWeekErrorCode =
  (typeof EditContractWeekErrorCode)[keyof typeof EditContractWeekErrorCode];
export interface EditContractWeekIntakesInput {
  id: Scalars['Int'];
  intakes: Scalars['Int'];
}

export interface EditContractWeekSessionsInput {
  id: Scalars['Int'];
  sessions: Scalars['Int'];
}

export interface EditContractWeeksAggregateError {
  aggregateErrors: Array<EditContractWeeksError>;
}

export interface EditContractWeeksError {
  errorCode: EditContractWeekErrorCode;
  weekId: Scalars['Int'];
}

export interface EditContractWeeksInput {
  psychiatryWeekIntakes?: InputMaybe<Array<EditContractWeekIntakesInput>>;
  psychiatryWeekSessions?: InputMaybe<Array<EditContractWeekSessionsInput>>;
  therapyWeekIntakes?: InputMaybe<Array<EditContractWeekIntakesInput>>;
  therapyWeekSessions?: InputMaybe<Array<EditContractWeekSessionsInput>>;
}

export type EditContractWeeksResult = EditContractWeeksAggregateError | EditContractWeeksSuccess;

export interface EditContractWeeksSuccess {
  successMessage: Scalars['String'];
}

export interface EditOrganizationAnnouncementInput {
  details?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
}

export interface EditOrganizationCarePeriod {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  periodType: PeriodType;
  startDate: Scalars['String'];
}

export interface EditOrganizationHandbookInput {
  campusCrisisTeamInProduct?: InputMaybe<Scalars['String']>;
  campusSafetyPoliceNumber?: InputMaybe<Scalars['String']>;
  coPayChargedByPartner?: InputMaybe<Scalars['String']>;
  defaultTherapyCadence?: InputMaybe<Scalars['String']>;
  ekgsNearCampus?: InputMaybe<Scalars['String']>;
  fullADHDEvaluation?: InputMaybe<Scalars['String']>;
  highLevelProtocolManagingEmergency?: InputMaybe<Scalars['String']>;
  hospitalization?: InputMaybe<Scalars['String']>;
  labsNearCampus?: InputMaybe<Scalars['String']>;
  mainPointOfContactCrisis?: InputMaybe<Scalars['String']>;
  noShowsCountForSessionLimits?: InputMaybe<Scalars['String']>;
  protocolForMedicalLeaveDoc?: InputMaybe<Scalars['String']>;
  requestingAcademicAccomodations?: InputMaybe<Scalars['String']>;
  sessionLimitsRenew?: InputMaybe<Scalars['String']>;
  summerBreakEligibility?: InputMaybe<Scalars['String']>;
  supportingClinician?: InputMaybe<Scalars['String']>;
  vitalsNearCampus?: InputMaybe<Scalars['String']>;
  winterBreakEligibility?: InputMaybe<Scalars['String']>;
}

export interface EditTicketInput {
  assigneeId?: InputMaybe<Scalars['Float']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<TicketCategory>;
  description?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<TicketPriority>;
  resolveIn24Hrs?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<TicketStatus>;
  title?: InputMaybe<Scalars['String']>;
}

export interface EditUser {
  authToken?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['String']>;
  campusTeam?: InputMaybe<Array<CampusTeamMemberInput>>;
  careFlows?: InputMaybe<Array<UserCareFlowInput>>;
  dtc?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  mantraAdminCareTeam?: InputMaybe<Array<Scalars['Float']>>;
  organizationId?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<AddressInput>;
  providerId?: InputMaybe<Scalars['Int']>;
  samlUniqueId?: InputMaybe<Scalars['String']>;
  secondaryState?: InputMaybe<StateCodes>;
  therapistId?: InputMaybe<Scalars['Int']>;
}

export const EditableUserFields = {
  Address: 'Address',
  CareFlows: 'CareFlows',
  CareTeam: 'CareTeam',
  DateOfBirth: 'DateOfBirth',
  Email: 'Email',
  LegalName: 'LegalName',
  OrgCareTeam: 'OrgCareTeam',
  Organization: 'Organization',
  Phone: 'Phone',
  PreferredName: 'PreferredName',
  Providers: 'Providers',
  SecondaryState: 'SecondaryState',
  UniqueId: 'UniqueId',
} as const;

export type EditableUserFields = (typeof EditableUserFields)[keyof typeof EditableUserFields];
export interface EmbedCoach {
  coachId: Scalars['String'];
  isCurrentCoach: Scalars['Boolean'];
  name: Scalars['String'];
}

export interface EmbedOrganization {
  name: Scalars['String'];
}

export interface EmbedPatient {
  name: Scalars['String'];
  organization?: Maybe<EmbedOrganization>;
}

export const EngagementTag = {
  Disengaged: 'Disengaged',
  IncompleteTasks: 'IncompleteTasks',
  LimitedSessions: 'LimitedSessions',
  NeedsInsuranceDetails: 'NeedsInsuranceDetails',
  NoShow: 'NoShow',
  Unverified: 'Unverified',
} as const;

export type EngagementTag = (typeof EngagementTag)[keyof typeof EngagementTag];
export const Entitlement = {
  AllowCharlieHealthIopReferralsViaMcp: 'AllowCharlieHealthIOPReferralsViaMCP',
  AllowMultipleReferralTypes: 'AllowMultipleReferralTypes',
  AllowTogetherAllReferralsViaMcp: 'AllowTogetherAllReferralsViaMCP',
  CampusResources: 'CampusResources',
  CanReactivatePatients: 'CanReactivatePatients',
  Coaching: 'Coaching',
  CollateEmail: 'CollateEmail',
  ConnectNow: 'ConnectNow',
  CrisisTeam: 'CrisisTeam',
  CustomAvailabilityInterval: 'CustomAvailabilityInterval',
  CustomHubDashboardModule: 'CustomHubDashboardModule',
  CustomMinAge: 'CustomMinAge',
  DdhSync: 'DDHSync',
  DedicatedGroupModel: 'DedicatedGroupModel',
  DisableCampusResources: 'DisableCampusResources',
  DisablePatientPortal: 'DisablePatientPortal',
  ExecutiveFunctioning: 'ExecutiveFunctioning',
  HideReportingDashboard: 'HideReportingDashboard',
  Hub: 'Hub',
  HubTogetherallEnable: 'HubTogetherallEnable',
  LevelOfCareAssessment: 'LevelOfCareAssessment',
  ManualSponsoredCareReactivation: 'ManualSponsoredCareReactivation',
  OnDemand: 'OnDemand',
  OrgIdentification: 'OrgIdentification',
  ReferrerRole: 'ReferrerRole',
  RequireConsentForMinors: 'RequireConsentForMinors',
  RequireSelfReferEligibilityCheck: 'RequireSelfReferEligibilityCheck',
  RestrictedPatientProfiles: 'RestrictedPatientProfiles',
  Sso: 'SSO',
  ScreenSelfReferStudents: 'ScreenSelfReferStudents',
  SelfReferral: 'SelfReferral',
  SelfReferralCollaborativeModelScreen: 'SelfReferralCollaborativeModelScreen',
  SessionsModel: 'SessionsModel',
  ShowUtilizationTabToMcpAdmin: 'ShowUtilizationTabToMcpAdmin',
  SupportingClinicianRole: 'SupportingClinicianRole',
  UnlimitedNoShows: 'UnlimitedNoShows',
  WellnessLevelOfCareAssessment: 'WellnessLevelOfCareAssessment',
  WholeCampusCare: 'WholeCampusCare',
} as const;

export type Entitlement = (typeof Entitlement)[keyof typeof Entitlement];
export interface EntitlementDefinition {
  config: Array<EntitlementDefinitionConfig>;
  description: Scalars['String'];
  key: Entitlement;
  name: Scalars['String'];
}

export interface EntitlementDefinitionConfig {
  key: Scalars['String'];
  maxLength?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  size?: Maybe<EntitlementDefinitionConfigSize>;
  smallText?: Maybe<Scalars['String']>;
  type: Scalars['String'];
}

export interface Event {
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['Float'];
  provider?: Maybe<Provider>;
  tag: Scalars['String'];
  user?: Maybe<User>;
}

export interface FaQuestion {
  answer: Scalars['String'];
  examples?: Maybe<Array<Scalars['String']>>;
  question: Scalars['String'];
}

export type FacultyHubReferralResult = FacultyHubReferralSuccess | ReferralError;

export interface FacultyHubReferralSuccess {
  successMessage: Scalars['String'];
}

export interface FeedbackAnswer {
  key: Scalars['String'];
  /** JSON stringified value */
  value: Scalars['String'];
}

export const FeedbackType = {
  Followup: 'Followup',
  Initial: 'Initial',
} as const;

export type FeedbackType = (typeof FeedbackType)[keyof typeof FeedbackType];
export interface FinalizedInsuranceClaim {
  insurancePaidInCents: Scalars['Float'];
  patientResponsibilityInCents: Scalars['Float'];
}

export interface FindCareStartOutput {
  connectNowEnabled: Scalars['Boolean'];
  onDemandEnabled: Scalars['Boolean'];
  questionnaire: Questionnaire;
}

export interface FinishSkillReflectionResponse {
  deleted?: Maybe<Scalars['Boolean']>;
  saved?: Maybe<Scalars['Boolean']>;
}

export interface FirstFactorResult {
  /** Token to be included with the second factor mutation when using 2FA */
  firstFactorToken: Scalars['String'];
  /** Last 4 digits of phone number used in two factor auth */
  phoneLastFour: Scalars['String'];
}

export interface FormSection {
  /** Copy after the main question */
  afterCopy?: Maybe<Array<SectionCopy>>;
  background?: Maybe<SectionColor>;
  /** Copy before the main question */
  beforeCopy?: Maybe<Array<SectionCopy>>;
  border?: Maybe<SectionColor>;
  /** Allows for sub-sections */
  children?: Maybe<Array<FormSection>>;
  closeable?: Maybe<Scalars['Boolean']>;
  dependsOn?: Maybe<SectionDependency>;
  format: SectionFormat;
  hooks?: Maybe<Array<SectionHook>>;
  isolatedOption?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  maxSelections?: Maybe<Scalars['Float']>;
  /** Options if selectable */
  options?: Maybe<Array<Scalars['String']>>;
  placeholder?: Maybe<Scalars['String']>;
  /** Prevent submission with outstanding required questions */
  required?: Maybe<Scalars['Boolean']>;
  /** Rules to determine if a field is required or not */
  requiredIf?: Maybe<RequiredIf>;
  sectionTitle?: Maybe<Scalars['String']>;
  selectDependentValues?: Maybe<Array<SelectDependentValue>>;
  startClosed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  /** Hide number when rendering */
  uncounted?: Maybe<Scalars['Boolean']>;
  /** JSON stringified value */
  value?: Maybe<Scalars['String']>;
  warning?: Maybe<TriggeredCopy>;
}

export interface GenericCollegeModalModel {
  description: Scalars['String'];
  id: Scalars['Float'];
  linkText: Scalars['String'];
  organizationId: Scalars['Float'];
  pictureType?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}

export interface GetContractError {
  errorCode: GetContractErrorCode;
  message: Scalars['String'];
}

export const GetContractErrorCode = {
  ContractNotFound: 'ContractNotFound',
} as const;

export type GetContractErrorCode = (typeof GetContractErrorCode)[keyof typeof GetContractErrorCode];
export type GetContractResult = Contract | GetContractError;

export interface GetUpNextSkillForModuleResult {
  moduleIsComplete: Scalars['Boolean'];
  upNextSkill?: Maybe<Scalars['JSON']>;
  upNextSkillIsComplete: Scalars['Boolean'];
}

export interface GetUpNextSkillResult {
  moduleWillBeComplete: Scalars['Boolean'];
  upNextSkill?: Maybe<Scalars['JSON']>;
  upNextSkillIsComplete: Scalars['Boolean'];
}

export const GlobalAvailabilityBlockerType = {
  ContractSessions: 'ContractSessions',
  NoContract: 'NoContract',
  ProviderStateBookableHours: 'ProviderStateBookableHours',
  ProviderStateWeeklyIntakes: 'ProviderStateWeeklyIntakes',
  StudentCapacity: 'StudentCapacity',
  WeeklyIntakes: 'WeeklyIntakes',
  WeeklySessions: 'WeeklySessions',
} as const;

export type GlobalAvailabilityBlockerType =
  (typeof GlobalAvailabilityBlockerType)[keyof typeof GlobalAvailabilityBlockerType];
export const HookType = {
  ClearOnChange: 'clearOnChange',
} as const;

export type HookType = (typeof HookType)[keyof typeof HookType];
export interface HubAnswerSubmission {
  answerKeys?: InputMaybe<Array<Scalars['String']>>;
  manualInput?: InputMaybe<Scalars['String']>;
  questionKey: Scalars['String'];
}

export interface HubAppointment {
  appointmentTypeId: Scalars['String'];
  appointmentTypeName: Scalars['String'];
  id: Scalars['String'];
  length: Scalars['Float'];
  rescheduleLink?: Maybe<Scalars['String']>;
  /** The ISO datetime for when the appointment starts. */
  start: Scalars['DateTime'];
  zoomJoinUrl: Scalars['String'];
}

export interface HubAssessmentSubmission {
  answers: Array<HubAnswerSubmission>;
  questionnaireKey: Scalars['String'];
}

export interface HubConnectNowData {
  crisisCallPhoneNumber: Scalars['String'];
  crisisSMS?: Maybe<Scalars['String']>;
  crisisWebChat?: Maybe<Scalars['String']>;
}

export interface HubConnectNowOutput {
  connectNowData?: Maybe<HubConnectNowData>;
  connectNowEnabled: Scalars['Boolean'];
}

export interface HubCustomModuleConfig {
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface HubCustomModuleData {
  data?: Maybe<HubCustomModuleConfig>;
  isEnabled: Scalars['Boolean'];
}

/** An appointment external to the hub. */
export interface HubExternalAppointment {
  /** The ISO datetime for when the appointment ends. */
  end: Scalars['DateTime'];
  /** The id of the appointment. */
  id: Scalars['Float'];
  /** The mantra resolution name for the appointment. */
  mrn: Scalars['String'];
  /** The ISO datetime for when the appointment starts. */
  start: Scalars['DateTime'];
}

export interface HubOnDemandData {
  crisisCallPhoneNumber: Scalars['String'];
  crisisSMS?: Maybe<Scalars['String']>;
  crisisWebChat?: Maybe<Scalars['String']>;
}

export interface HubOnDemandOutput {
  onDemandData?: Maybe<HubOnDemandData>;
  onDemandEnabled: Scalars['Boolean'];
}

export interface HubOrganizationResource {
  contact?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  showAsCrisisResource: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
}

export interface HubResendActivationError {
  errorCode: HubResendActivationErrorCode;
  message: Scalars['String'];
}

export const HubResendActivationErrorCode = {
  InvalidEmail: 'InvalidEmail',
} as const;

export type HubResendActivationErrorCode =
  (typeof HubResendActivationErrorCode)[keyof typeof HubResendActivationErrorCode];
export type HubResendActivationResult = HubResendActivationError | HubResendActivationSuccess;

export interface HubResendActivationSuccess {
  success: Scalars['Boolean'];
}

export interface HubStatusForUser {
  hasHubOnboarded: Scalars['Boolean'];
  isHubEnabled: Scalars['Boolean'];
}

export interface HubUser {
  birthDate?: Maybe<Scalars['Date']>;
  canRedirect: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  hasHubOnboarded: Scalars['Boolean'];
  impersonatingId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<HubUserOrganization>;
  organizationCanAccessCampusResources: Scalars['Boolean'];
  organizationCanAccessCare: Scalars['Boolean'];
  organizationCanAccessTherapy: Scalars['Boolean'];
  organizationCanAccessTogetherAll: Scalars['Boolean'];
  organizationCounselingCenter?: Maybe<HubUserOrgCounselingCenter>;
  organizationHasCoaching: Scalars['Boolean'];
  organizationHasSelfReferrals: Scalars['Boolean'];
  organizationLogo?: Maybe<HubUserOrganizationLogo>;
  organizationMinimumHubAge: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<Address>;
  pronouns?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
  upcomingAppointment?: Maybe<HubUserHealthieAppointmentData>;
}

export interface HubUserHealthieAppointmentData {
  appointmentTypeId: Scalars['String'];
  appointmentTypeName: Scalars['String'];
  id: Scalars['String'];
  length: Scalars['Float'];
  start: Scalars['String'];
  zoomJoinUrl: Scalars['String'];
}

export interface HubUserOnboardError {
  errorCode: HubUserOnboardErrorCode;
  message: Scalars['String'];
}

export const HubUserOnboardErrorCode = {
  AgeBlocked: 'AgeBlocked',
  AgeConsentIncomplete: 'AgeConsentIncomplete',
  AgeConsentRequired: 'AgeConsentRequired',
} as const;

export type HubUserOnboardErrorCode =
  (typeof HubUserOnboardErrorCode)[keyof typeof HubUserOnboardErrorCode];
export type HubUserOnboardResult = HubUserOnboardError | HubUserOnboardSuccess;

export interface HubUserOnboardSuccess {
  successMessage: Scalars['String'];
}

export interface HubUserOrgCounselingCenter {
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
}

export interface HubUserOrganization {
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface HubUserOrganizationLogo {
  contentType: Scalars['String'];
  url: Scalars['String'];
}

export interface HubUserUpdateInput {
  birthDate?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<AddressInput>;
  pronouns?: InputMaybe<Scalars['String']>;
}

export interface Hyperlink {
  displayText: Scalars['String'];
  url: Scalars['String'];
}

export interface InAppNotification {
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['Float'];
  isDismissible: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
  key: UserNotificationKey;
}

export interface InsuranceClaim {
  balanceCents: Scalars['Float'];
  events: Array<InsuranceClaimEvent>;
  externalStatus: Scalars['String'];
  geoState: Scalars['String'];
  id: Scalars['Float'];
  internalStatus: InternalStatus;
  payer: Payer;
  provider: Provider;
  sentDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  visitDate: Scalars['DateTime'];
}

export interface InsuranceClaimEvent {
  action: ClaimAction;
  actor?: Maybe<Provider>;
  codes: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  details: Scalars['String'];
  id: Scalars['Float'];
  status: InternalStatus;
  updatedAt: Scalars['DateTime'];
}

export interface InsuranceDetails {
  deductibleRemainingCents?: Maybe<Scalars['Float']>;
  eligibilityVerified: Scalars['Boolean'];
  groupNumber?: Maybe<Scalars['String']>;
  inNetwork: Scalars['Boolean'];
  lastChecked?: Maybe<Scalars['DateTime']>;
  memberId?: Maybe<Scalars['String']>;
  noMentalHealthBenefits?: Maybe<Scalars['Boolean']>;
  patientRelationshipToSubscriber: PatientRelationshipToSubscriber;
  payer?: Maybe<Payer>;
  state: Scalars['String'];
  subscriberDateOfBirth?: Maybe<Scalars['String']>;
  subscriberFirstName?: Maybe<Scalars['String']>;
  subscriberGender?: Maybe<Scalars['String']>;
  subscriberLastName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
}

export interface InsuranceDetailsInput {
  birthDate?: InputMaybe<Scalars['String']>;
  enteredByProviderId?: InputMaybe<Scalars['Float']>;
  firstName?: InputMaybe<Scalars['String']>;
  groupNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  patientRelationshipToSubscriber: PatientRelationshipToSubscriber;
  payerId?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  subscriberDateOfBirth?: InputMaybe<Scalars['String']>;
  subscriberFirstName?: InputMaybe<Scalars['String']>;
  subscriberGender?: InputMaybe<Scalars['String']>;
  subscriberLastName?: InputMaybe<Scalars['String']>;
}

export interface InsuranceEligibility {
  inNetwork: Scalars['Boolean'];
  noMentalHealthBenefits?: Maybe<Scalars['Boolean']>;
  valid: Scalars['Boolean'];
}

export interface IntakeAvailabilityResult {
  data: Array<Availability>;
  duration: Scalars['Float'];
  globalBlockers: Array<SimpleGlobalAvailabilityBlocker>;
  interval: Scalars['Float'];
}

export const InternalStatus = {
  Accepted: 'Accepted',
  Cancelled: 'Cancelled',
  Closed: 'Closed',
  Created: 'Created',
  Denied: 'Denied',
  FollowUp: 'FollowUp',
  Rejected: 'Rejected',
  Sent: 'Sent',
} as const;

export type InternalStatus = (typeof InternalStatus)[keyof typeof InternalStatus];
export interface Invoice {
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  paid: Scalars['Boolean'];
}

export interface IopReferralModel {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  organizationId?: Maybe<Scalars['Float']>;
  organizationName: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  referralDateTime: Scalars['DateTime'];
  status: Scalars['String'];
  userId?: Maybe<Scalars['Float']>;
}

export interface JumpBackInSkillData {
  module?: Maybe<Scalars['JSON']>;
  skill?: Maybe<Scalars['JSON']>;
}

export interface ListContractsItemModel {
  endDate: Scalars['LocalDate'];
  id: Scalars['Int'];
  startDate: Scalars['LocalDate'];
}

export interface ListContractsModel {
  contracts: Array<ListContractsItemModel>;
}

export interface ListCopy {
  bullets: Array<Scalars['String']>;
  showInView?: Maybe<Scalars['Boolean']>;
  style?: Maybe<TextStyle>;
}

export const LoginHubError = {
  InvalidToken: 'invalid_token',
  InvalidTokenId: 'invalid_token_id',
  InvalidUser: 'invalid_user',
} as const;

export type LoginHubError = (typeof LoginHubError)[keyof typeof LoginHubError];
export interface LoginHubResult {
  error?: Maybe<LoginHubError>;
  hasHubOnboarded?: Maybe<Scalars['Boolean']>;
  success?: Maybe<LoginHubSuccess>;
}

export interface LoginHubSuccess {
  impersonated: Scalars['Boolean'];
  jwt?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
}

export interface LoginToHubError {
  errorCode: LoginToHubErrorCode;
  message: Scalars['String'];
}

export const LoginToHubErrorCode = {
  InvalidToken: 'InvalidToken',
  InvalidTokenId: 'InvalidTokenId',
  InvalidUser: 'InvalidUser',
} as const;

export type LoginToHubErrorCode = (typeof LoginToHubErrorCode)[keyof typeof LoginToHubErrorCode];
export type LoginToHubResult = LoginToHubError | LoginToHubSuccess;

export interface LoginToHubSuccess {
  hasHubOnboarded?: Maybe<Scalars['Boolean']>;
  impersonated: Scalars['Boolean'];
  jwt: Scalars['String'];
  userId: Scalars['Int'];
}

/** The main concerns of a user */
export const MainConcerns = {
  Academics: 'ACADEMICS',
  AnxietyStress: 'ANXIETY_STRESS',
  CareerPlanning: 'CAREER_PLANNING',
  ConcernForSomeoneElse: 'CONCERN_FOR_SOMEONE_ELSE',
  Depression: 'DEPRESSION',
  Family: 'FAMILY',
  FeelingDown: 'FEELING_DOWN',
  Finances: 'FINANCES',
  FriendsRoommates: 'FRIENDS_ROOMMATES',
  Grief: 'GRIEF',
  HealthConcerns: 'HEALTH_CONCERNS',
  Lgbtqia: 'LGBTQIA',
  LifeChanges: 'LIFE_CHANGES',
  Loneliness: 'LONELINESS',
  Other: 'OTHER',
  Relationship: 'RELATIONSHIP',
  SelfInjury: 'SELF_INJURY',
  SubstanceAbuse: 'SUBSTANCE_ABUSE',
  SuicidalThoughts: 'SUICIDAL_THOUGHTS',
  Work: 'WORK',
} as const;

export type MainConcerns = (typeof MainConcerns)[keyof typeof MainConcerns];
export interface ManualInput {
  label?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
}

export interface MaybeBlockedCalendarEvents {
  blockedReason?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  data?: Maybe<CalendarEvent>;
}

export interface MaybeBlockedProviders {
  blockedReason?: Maybe<ProviderServiceBlockedReason>;
  data?: Maybe<Array<Provider>>;
}

export interface McpCurrentContractCareTypeUtilization {
  sessionsRemaining: Scalars['Float'];
  sessionsScheduled: Scalars['Float'];
  sessionsUtilized: Scalars['Float'];
  studentCapacityRemaining: Scalars['Float'];
  studentCapacityUtilized: Scalars['Float'];
}

export interface McpCurrentContractScope {
  endDate: Scalars['LocalDate'];
  psychiatryPatients: Scalars['Int'];
  psychiatrySessions: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  therapyPatients: Scalars['Int'];
  therapySessions: Scalars['Int'];
}

export interface McpCurrentContractUtilization {
  psychiatryUsage: McpCurrentContractCareTypeUtilization;
  scope: McpCurrentContractScope;
  therapyUsage: McpCurrentContractCareTypeUtilization;
}

export interface McpCurrentContractUtilizationResult {
  utilization?: Maybe<McpCurrentContractUtilization>;
}

export interface MedicalHistory {
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  questionnaires: Array<MedicalHistoryQuestionnaire>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Float'];
  uuid: Scalars['String'];
}

export interface MedicalHistoryQuestion {
  caption?: Maybe<Scalars['String']>;
  dependency?: Maybe<Dependency>;
  disclaimer?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['String']>>;
  isOptional?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  legend?: Maybe<Array<Scalars['String']>>;
  manualInput?: Maybe<ManualInput>;
  options?: Maybe<Array<Option>>;
  overrideDescription?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  subText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
  visible?: Maybe<Scalars['Boolean']>;
}

export interface MedicalHistoryQuestionnaire {
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  preScreen?: Maybe<QuestionnairePreScreen>;
  questions: Array<MedicalHistoryQuestion>;
  title: Scalars['String'];
}

export interface MedicalNote {
  appointment?: Maybe<CalendarEvent>;
  claimSubmitted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  key: Scalars['String'];
  provider: Provider;
  submittedAt?: Maybe<Scalars['DateTime']>;
  template: MedicalNoteTemplate;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  valueForKey?: Maybe<Scalars['String']>;
}

export type MedicalNoteValueForKeyArgs = {
  key: Scalars['String'];
};

export interface MedicalNoteSectionInput {
  key: Scalars['String'];
  /** JSON stringified value */
  value?: InputMaybe<Scalars['String']>;
}

export interface MedicalNoteTemplate {
  careTypes?: Maybe<Array<CareType>>;
  key: Scalars['String'];
  patientDetails?: Maybe<Array<PatientDetail>>;
  questions: Array<FormSection>;
  title: Scalars['String'];
  titleTag?: Maybe<TextTag>;
  uuid: Scalars['String'];
}

export interface MedicationInfo {
  brandNames: Array<Scalars['String']>;
  faq: Array<FaQuestion>;
  fdaApprovedTreatmentOf: Array<Scalars['String']>;
  medicationGuidelines: Array<Hyperlink>;
  name: Scalars['String'];
  safetyInformation: SafetyInfo;
  whatToExpect: Scalars['String'];
}

export const MedicationStatus = {
  Active: 'Active',
  CancelDenied: 'CancelDenied',
  CancelPending: 'CancelPending',
  CancelRequested: 'CancelRequested',
  Cancelled: 'Cancelled',
  Changed: 'Changed',
  Completed: 'Completed',
  Deleted: 'Deleted',
  Discontinued: 'Discontinued',
  FullFill: 'FullFill',
  Inactive: 'Inactive',
  NoFill: 'NoFill',
  PartialFill: 'PartialFill',
  Unknown: 'Unknown',
} as const;

export type MedicationStatus = (typeof MedicationStatus)[keyof typeof MedicationStatus];
export interface MessageV2 {
  attachments: Array<Upload>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Do not use this with queries that return arrays of messages */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
}

export const MinorConsentStatus = {
  Approved: 'Approved',
  Incomplete: 'Incomplete',
} as const;

export type MinorConsentStatus = (typeof MinorConsentStatus)[keyof typeof MinorConsentStatus];
export interface MinorConsentStatusModel {
  status?: Maybe<MinorConsentStatus>;
}

export interface Mutation {
  activateCrisisMode: Scalars['String'];
  activateSafetyPlan: SafetyPlan;
  addProviderToCrisisTeams: Scalars['Boolean'];
  addSelfReferralEmails: Scalars['Boolean'];
  addUserCareEligibilitySubmission: ContinuityOfCareEligibilityResult;
  adminAcceptTermsOfService: Scalars['Boolean'];
  adminAuthenticate: PasswordAuthResult;
  adminBeginUpload: SignedS3Upload;
  adminCancelAppointment: CalendarEvent;
  adminCompleteOrganizationPhotoUpload: Scalars['Boolean'];
  adminCompleteProviderPhotoUpload: Provider;
  adminCompleteUpload: Upload;
  adminContinuityOfCareExport: AsynchronousJob;
  adminCreateCareTransition: ContinuityOfCareModel;
  adminCreateNoteExport: AsynchronousJob;
  adminCreateStaff: Provider;
  adminCreateTestUser: User;
  adminCreateUser: User;
  adminDeleteUpload: Scalars['Boolean'];
  adminDisableTwoFactor: Scalars['Boolean'];
  adminEditSafetyPlan: SafetyPlan;
  adminEditUser: AdminEditUserResult;
  adminEndCall: Scalars['Boolean'];
  adminEnterSecondFactor: TwoFactorAuthResult;
  adminMarkNoShow: Scalars['Boolean'];
  adminMarkTaskDone: Scalars['Boolean'];
  adminMarkUpcoming: Scalars['Boolean'];
  adminModifyAppointment: CalendarEvent;
  adminPasswordlessLogin: Scalars['String'];
  adminRescheduleAppointment: CalendarEvent;
  adminResetProviderPassword: Scalars['Boolean'];
  adminScheduleAppointment: CalendarEvent;
  /** Send a two factor code for the authenticated provider (use for verification) */
  adminSendTwoFactorCode: Scalars['Boolean'];
  adminSetPharmacyOnPatient: Scalars['Boolean'];
  adminSetTwoFactorConfig: Scalars['Boolean'];
  adminSubmitAssessment: AssessmentResponse;
  adminTransitionForCarePeriod: Scalars['Boolean'];
  adminTriggerProviderPasswordlessFlow: Scalars['Boolean'];
  adminUndoTaskDone: Scalars['Boolean'];
  adminUpdateAllocationsForDate: Scalars['Boolean'];
  adminUpdateProvider: Provider;
  adminUpdateScreenUserResult: Scalars['Boolean'];
  adminUpdateStateAllocationsForDate: Scalars['Boolean'];
  adminUpdateUpload: Scalars['Boolean'];
  adminVerifyTwoFactorPhone: Scalars['String'];
  answerSkillReflectionQuestion: Scalars['Boolean'];
  applyCoupon?: Maybe<ServiceCoupon>;
  assignCoachToEmbedPatient: Scalars['Boolean'];
  assignSkillToUser: AssignSkillToUserResponse;
  autoApplyCoupons: Array<ServiceCoupon>;
  beginEmailVerification: Scalars['Boolean'];
  beginSponsoredCareReenrollment: Scalars['Boolean'];
  beginUpload: SignedS3Upload;
  cancelAppointment: Scalars['Boolean'];
  cancelHealthieAppointment: Scalars['Boolean'];
  cancelSponsoredCareReenrollment: Scalars['Boolean'];
  changeProviderPassword: Scalars['Boolean'];
  changeStatusOfClaim: InsuranceClaimEvent;
  checkIsBlurry: Scalars['Boolean'];
  clearProviderLockout: Scalars['Boolean'];
  completeAppointmentCheckin: CalendarEvent;
  completePendingTransition: Scalars['Boolean'];
  completeUpload: Upload;
  consumeCoachingRecommendation: Scalars['Boolean'];
  consumeTherapyRecommendation: Scalars['Boolean'];
  convertPatientCare: User;
  createConnectNowPreEncounter: Scalars['Boolean'];
  createContract: CreateContractResult;
  createDataSharingRequest: DataSharingRequest;
  createHubFeedback: Scalars['Boolean'];
  createNoteForClaim: InsuranceClaimEvent;
  createOnDemandEncounter: Scalars['Boolean'];
  createOrUpdateOrganizationResource: OrganizationResource;
  createOrUpdatePayer: Payer;
  createOrganization: Organization;
  createOrganizationAnnouncement: OrganizationAnnouncement;
  createPartnerReferral: Scalars['Boolean'];
  createProvider: Provider;
  createTestVideoSession: TestVideoSession;
  createTicket: Ticket;
  createUserFirstStep: User;
  createZendeskTicket: Scalars['Boolean'];
  debugImpersonateAsUser: Scalars['String'];
  debugLoginAsUser: Scalars['String'];
  /** Internal debug tooling */
  debugModifyDataSharingConsent: DataSharingConsent;
  /** Internal debug tooling */
  debugModifyDataSharingRequest: DataSharingRequest;
  decoupleOrgEntitlementsFromParent: Scalars['Boolean'];
  deleteEmergencyContact: Contact;
  deleteInsuranceCredential: Scalars['Boolean'];
  deleteMedicalNote: Scalars['Boolean'];
  deleteOrganizationResource: Scalars['Boolean'];
  deleteOutreachAttempt: Scalars['Boolean'];
  deleteUserCareEligibilitySubmission: Scalars['Boolean'];
  deleteUserContact: Scalars['Boolean'];
  deleteZendeskUpload: Scalars['Boolean'];
  disableDevice: Scalars['Boolean'];
  dismissNotification: Scalars['Boolean'];
  dropInsurance: Scalars['Boolean'];
  editContractScope: EditContractScopeResult;
  editContractWeeks: EditContractWeeksResult;
  editOrganizationAnnouncement: Scalars['Boolean'];
  editOrganizationHandbook: Scalars['Boolean'];
  editSafetyPlan: SafetyPlan;
  /** null if current provider gives up permission to view ticket */
  editTicket?: Maybe<Ticket>;
  emailUnsubscribe: Scalars['Boolean'];
  facultyHubReferral: FacultyHubReferralResult;
  findCareFinish: Scalars['Boolean'];
  finishProviderPasswordReset: Scalars['Boolean'];
  /** @deprecated Legacy reset password flow. */
  finishResetPassword: Scalars['Boolean'];
  finishSkillReflection: FinishSkillReflectionResponse;
  /** @deprecated use pushAssessment instead */
  forceNextScalesToNow: Scalars['Boolean'];
  forgotProviderPassword: Scalars['Boolean'];
  /** returns unsubmitted note of key for user or creates a new one */
  getOrCreateNote: MedicalNote;
  hubUserAcceptTerms: Scalars['Boolean'];
  hubUserOnboard: HubUserOnboardResult;
  impersonate: Scalars['String'];
  initializeStudent: User;
  /** @deprecated Replaced with loginToHub mutation. */
  loginHub: LoginHubResult;
  loginToHub: LoginToHubResult;
  loginUserWithPassword: PasswordAuthResult;
  logoutHub: Scalars['Boolean'];
  logoutProvider: Scalars['Boolean'];
  logoutUser: Scalars['Boolean'];
  markCareTeamChannelsAsRead: Scalars['Boolean'];
  markEmbedPatientCoachingInactive: Scalars['Boolean'];
  markHealthieAppointmentForCancellation: Scalars['Boolean'];
  /** @deprecated Use hubUserOnboard resolver instead. */
  markHubUserOnboarded: Scalars['Boolean'];
  markReferralComplete: Scalars['Boolean'];
  markSkillAssignmentSeenByAssigner: Scalars['Boolean'];
  markSkillComplete: Scalars['Boolean'];
  markSkillStarted: Scalars['Boolean'];
  minorConsentApproveUser: Scalars['Boolean'];
  minorConsentOverrideUser: Scalars['Boolean'];
  networkSearchAccommodationRequest: Scalars['Boolean'];
  notifyOrganizationCrisisTeam: Scalars['Boolean'];
  passwordlessLogin: PasswordlessLoginResult;
  patientMarkAsRead: Scalars['Boolean'];
  patientMessageProvider: MessageV2;
  preLoginOrPasswordLogin: PreLoginOrPasswordLoginResult;
  preSignUp: PreSignUpResult;
  providerMarkPrivateChannelsAsRead: Scalars['Boolean'];
  providerMessagePatient: MessageV2;
  pushAssessment: Scalars['Boolean'];
  reactivateSelfForSponsoredCare: Scalars['Boolean'];
  reactivateUserCareFlow: UserCareFlow;
  reactivateUserForSponsoredCare: Scalars['Boolean'];
  recheckInsuranceEligibility: Scalars['Boolean'];
  recoupleOrgEntitlementsToParent: Scalars['Boolean'];
  refreshProviderLogin: Scalars['String'];
  regenerateProviderPassword: Scalars['String'];
  registerDevice: Scalars['Boolean'];
  removeProviderFromCrisisTeams: Scalars['Boolean'];
  resendActivationEmail: HubResendActivationResult;
  /** Send a two factor code when attempting to authenticate */
  resendTwoFactorCode: Scalars['Boolean'];
  resetBadgeCount: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resetSessionStartForUser: Scalars['Boolean'];
  resetSessionsForFlow: OrganizationCareFlow;
  resetUserPassword: Scalars['String'];
  resubmitClaim: Scalars['Boolean'];
  /** should be called from the onBlur callback for each question */
  saveNoteSections: Scalars['Boolean'];
  saveUserDemographics: Scalars['Boolean'];
  selectCareFlow: User;
  sendCareTeamMessage: MessageV2;
  sendCoachChatMessage: SendCoachChatMessageResult;
  sendDataSharingRequestReminder: Scalars['Boolean'];
  sendPasswordResetEmail: Scalars['Boolean'];
  setDataSharingConsent: Scalars['Boolean'];
  setFlaggedForOutreach: Scalars['Boolean'];
  setHubUserPreferredName: Scalars['Boolean'];
  setInsurance: InsuranceEligibility;
  setPasswordWithToken: SetPasswordWithTokenResult;
  setPharmacyOnPatient: Scalars['Boolean'];
  setProviderSubscribed: Scalars['Boolean'];
  setSafeOperatingHandbookUrl: Scalars['Boolean'];
  setSuggestedPharmacyId: Scalars['Boolean'];
  setUserInsurance: InsuranceEligibility;
  setUserSubscribed: Scalars['Boolean'];
  setUserVerified: Scalars['Boolean'];
  signUp: SignUpResult;
  skillPathwaySubmit: Scalars['Boolean'];
  skillReflectionDelete: Scalars['Boolean'];
  skillReflectionEditAnswer: Scalars['Boolean'];
  skipPharmacySelection: Scalars['Boolean'];
  softDeleteUser: Scalars['Boolean'];
  studentHubReferral: StudentHubReferralResult;
  submitAppointmentFeedback: Scalars['Boolean'];
  submitAppointmentIncident: Scalars['Boolean'];
  submitCoachingAssessment: Scalars['Boolean'];
  submitCoachingAssessmentV2: Scalars['Boolean'];
  submitIntakeAssessment: AssessmentResponse;
  submitMedicalHistory: Scalars['Boolean'];
  submitNote: MedicalNote;
  submitProviderFeedback: Scalars['Boolean'];
  submitRequestedAssessment: Scalars['Boolean'];
  submitSelfRefer1Screener: AssessmentResponse;
  swapPrimaryEmergencyContact: Contact;
  syncHealthieProvider: Scalars['Boolean'];
  toggleOrganizationOnHold: Organization;
  /** Track events from the client */
  track: Scalars['Boolean'];
  trackPushOpen: Scalars['Boolean'];
  triggerPasswordlessAuthFlow: Scalars['Boolean'];
  unassignSkillFromUser: Scalars['Boolean'];
  updateAppointmentTemplates: Scalars['Boolean'];
  updateCareFlowForUser: Scalars['Boolean'];
  updateCertifications: Array<ProviderCertification>;
  updateCoupon: ServiceCoupon;
  updateCrisisTeamHours: Scalars['Boolean'];
  updateEducation: Array<ProviderEducation>;
  updateEligibilityCriteria: Scalars['Boolean'];
  updateEntitlements: Scalars['Boolean'];
  /** @deprecated Does not return state errors - use updateHubUser2() instead. */
  updateHubUser: Scalars['Boolean'];
  updateHubUser2: UpdateHubUserResult;
  updateNotificationOutcome: Scalars['Boolean'];
  updateOrganization: Organization;
  updateOrganizationCareFlows: Array<OrganizationCareFlow>;
  updateOrganizationCarePeriods: Array<OrganizationCarePeriodModel>;
  updateOrganizationDedicatedGroupModelAllocations: Scalars['Boolean'];
  updateOrganizationDefaults: Scalars['Boolean'];
  updateOrganizations: Provider;
  updateOutreachAttempt: Scalars['Boolean'];
  updatePaymentMethod: PaymentMethod;
  updatePreferences: Scalars['Boolean'];
  updateProviderTraits: Scalars['Boolean'];
  updateQualifications: Array<ProviderQualification>;
  updateSamlConfiguration: SamlConfiguration;
  updateScreenUserResult: Scalars['Boolean'];
  updateSponsoredCareReenrollment: Scalars['Boolean'];
  updateUser2: UpdateUser2Result;
  upsertEmergencyContact: Contact;
  upsertInsuranceCredential: ProviderInsuranceCredential;
  upsertSuggestedProviders: Scalars['Boolean'];
  upsertUserContact: Contact;
  upsertUserSkillFeedback: Scalars['Boolean'];
  userCreateCareTransition: Scalars['Boolean'];
  userScheduleAppointment: CalendarEvent;
}

export type MutationActivateCrisisModeArgs = {
  appointmentId: Scalars['Float'];
};

export type MutationActivateSafetyPlanArgs = {
  userId: Scalars['Float'];
};

export type MutationAddProviderToCrisisTeamsArgs = {
  organizationIds: Array<Scalars['Float']>;
  phone: Scalars['String'];
  providerId: Scalars['Float'];
};

export type MutationAddSelfReferralEmailsArgs = {
  emails: Array<Scalars['String']>;
  organizationId: Scalars['Float'];
};

export type MutationAddUserCareEligibilitySubmissionArgs = {
  submission: ContinuityOfCareEligibilityInput;
};

export type MutationAdminAuthenticateArgs = {
  deviceToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationAdminBeginUploadArgs = {
  contentType: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
};

export type MutationAdminCancelAppointmentArgs = {
  id: Scalars['Float'];
  outOfPolicy?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdminCompleteOrganizationPhotoUploadArgs = {
  key: Scalars['String'];
  organizationId: Scalars['Float'];
};

export type MutationAdminCompleteProviderPhotoUploadArgs = {
  key?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
};

export type MutationAdminCompleteUploadArgs = {
  input: CreateFileUpload;
};

export type MutationAdminContinuityOfCareExportArgs = {
  userId: Scalars['Float'];
};

export type MutationAdminCreateCareTransitionArgs = {
  createTransition: CreateCareTransition;
};

export type MutationAdminCreateNoteExportArgs = {
  noteId: Scalars['Float'];
};

export type MutationAdminCreateStaffArgs = {
  input: CreateStaff;
};

export type MutationAdminCreateTestUserArgs = {
  args: CreateTestUser;
};

export type MutationAdminCreateUserArgs = {
  input: CreateUser;
  intakeAppointment?: InputMaybe<CreateIntake>;
};

export type MutationAdminDeleteUploadArgs = {
  uploadId: Scalars['Float'];
};

export type MutationAdminEditSafetyPlanArgs = {
  plan: SafetyPlanInput;
  userId: Scalars['Float'];
};

export type MutationAdminEditUserArgs = {
  editUser: EditUser;
};

export type MutationAdminEndCallArgs = {
  appointmentId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationAdminEnterSecondFactorArgs = {
  code: Scalars['String'];
  firstFactorToken: Scalars['String'];
};

export type MutationAdminMarkNoShowArgs = {
  appointmentId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationAdminMarkTaskDoneArgs = {
  taskId: Scalars['Float'];
};

export type MutationAdminMarkUpcomingArgs = {
  id: Scalars['Float'];
};

export type MutationAdminModifyAppointmentArgs = {
  end: Scalars['DateTime'];
  id: Scalars['Float'];
  start: Scalars['DateTime'];
};

export type MutationAdminPasswordlessLoginArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type MutationAdminRescheduleAppointmentArgs = {
  end: Scalars['DateTime'];
  id: Scalars['Float'];
  outOfPolicy?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['DateTime'];
};

export type MutationAdminResetProviderPasswordArgs = {
  id: Scalars['Float'];
};

export type MutationAdminScheduleAppointmentArgs = {
  appointmentType: Scalars['String'];
  end: Scalars['DateTime'];
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId: Scalars['Float'];
  start: Scalars['DateTime'];
  userId: Scalars['Float'];
};

export type MutationAdminSetPharmacyOnPatientArgs = {
  pharmacyId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type MutationAdminSetTwoFactorConfigArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  phone: Scalars['String'];
  providerId?: InputMaybe<Scalars['Float']>;
};

export type MutationAdminSubmitAssessmentArgs = {
  assessment: AssessmentKey;
  questionnaires: Array<QuestionnaireSubmission>;
  userId: Scalars['Float'];
};

export type MutationAdminTransitionForCarePeriodArgs = {
  carePeriodId: Scalars['Float'];
};

export type MutationAdminTriggerProviderPasswordlessFlowArgs = {
  email: Scalars['String'];
};

export type MutationAdminUndoTaskDoneArgs = {
  taskId: Scalars['Float'];
};

export type MutationAdminUpdateAllocationsForDateArgs = {
  allocations: Array<CreateAllocationInput>;
  date: Scalars['String'];
  providerId: Scalars['Float'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
};

export type MutationAdminUpdateProviderArgs = {
  input: UpdateProvider;
};

export type MutationAdminUpdateScreenUserResultArgs = {
  input: ScreenUserResultInput;
  userId: Scalars['Float'];
};

export type MutationAdminUpdateStateAllocationsForDateArgs = {
  allocationsToCreate: Array<CreateProviderStateAllocationsModelInput>;
  allocationsToUpdate: Array<UpdateProviderStateAllocationsModelInput>;
};

export type MutationAdminUpdateUploadArgs = {
  input: UpdateFileUpload;
};

export type MutationAdminVerifyTwoFactorPhoneArgs = {
  code: Scalars['String'];
  phone: Scalars['String'];
};

export type MutationAnswerSkillReflectionQuestionArgs = {
  answer: Scalars['String'];
  question: Scalars['String'];
  questionKey: Scalars['String'];
  skillId: Scalars['String'];
};

export type MutationApplyCouponArgs = {
  code: Scalars['String'];
};

export type MutationAssignCoachToEmbedPatientArgs = {
  coachId: Scalars['String'];
  userId: Scalars['Int'];
};

export type MutationAssignSkillToUserArgs = {
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationAutoApplyCouponsArgs = {
  careType: CareType;
};

export type MutationBeginSponsoredCareReenrollmentArgs = {
  userId: Scalars['Float'];
};

export type MutationBeginUploadArgs = {
  contentType: Scalars['String'];
};

export type MutationCancelAppointmentArgs = {
  appointmentId: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
};

export type MutationCancelHealthieAppointmentArgs = {
  appointmentId: Scalars['String'];
};

export type MutationCancelSponsoredCareReenrollmentArgs = {
  userId: Scalars['Float'];
};

export type MutationChangeProviderPasswordArgs = {
  password: Scalars['String'];
};

export type MutationChangeStatusOfClaimArgs = {
  claimId: Scalars['Float'];
  details: Scalars['String'];
  status: InternalStatus;
};

export type MutationCheckIsBlurryArgs = {
  key: Scalars['String'];
  purpose: Scalars['String'];
};

export type MutationClearProviderLockoutArgs = {
  providerId: Scalars['Int'];
};

export type MutationCompleteAppointmentCheckinArgs = {
  appointmentId: Scalars['Int'];
};

export type MutationCompletePendingTransitionArgs = {
  careType: CareType;
};

export type MutationCompleteUploadArgs = {
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  purpose: Scalars['String'];
};

export type MutationConsumeCoachingRecommendationArgs = {
  coachingProgramType?: InputMaybe<CoachingProgramType>;
};

export type MutationConvertPatientCareArgs = {
  careFlows: Array<UserCareFlowInput>;
  userId: Scalars['Float'];
};

export type MutationCreateConnectNowPreEncounterArgs = {
  data: ConnectNowPreEncounterInput;
};

export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

export type MutationCreateDataSharingRequestArgs = {
  userId: Scalars['Float'];
};

export type MutationCreateHubFeedbackArgs = {
  feedback: Scalars['String'];
  url: Scalars['String'];
};

export type MutationCreateNoteForClaimArgs = {
  claimId: Scalars['Float'];
  note: Scalars['String'];
};

export type MutationCreateOnDemandEncounterArgs = {
  data: OnDemandEncounterInput;
};

export type MutationCreateOrUpdateOrganizationResourceArgs = {
  organizationId: Scalars['Int'];
  resource: OrganizationResourceInput;
};

export type MutationCreateOrUpdatePayerArgs = {
  params: UpdatePayerParams;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganization;
};

export type MutationCreateOrganizationAnnouncementArgs = {
  input: CreateOrganizationAnnouncementInput;
};

export type MutationCreatePartnerReferralArgs = {
  data: PartnerReferralInput;
};

export type MutationCreateProviderArgs = {
  careTypes: Array<CareType>;
  classification: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  funFact?: InputMaybe<Scalars['String']>;
  geoStates?: InputMaybe<Array<Scalars['String']>>;
  lastName: Scalars['String'];
  npi: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postNominalTitles?: InputMaybe<Scalars['String']>;
  withTwoFactor?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateTicketArgs = {
  ticket: CreateTicketInput;
};

export type MutationCreateUserFirstStepArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MutationCreateZendeskTicketArgs = {
  attachmentTokens?: InputMaybe<Array<Scalars['String']>>;
  body: Scalars['String'];
  subject: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type MutationDebugImpersonateAsUserArgs = {
  reason: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationDebugLoginAsUserArgs = {
  userId: Scalars['Float'];
};

export type MutationDebugModifyDataSharingConsentArgs = {
  endDate: Scalars['DateTime'];
  id: Scalars['Float'];
  revokedAt?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type MutationDebugModifyDataSharingRequestArgs = {
  date: Scalars['DateTime'];
  id: Scalars['Float'];
  pending: Scalars['Boolean'];
};

export type MutationDecoupleOrgEntitlementsFromParentArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteEmergencyContactArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteInsuranceCredentialArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteMedicalNoteArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteOrganizationResourceArgs = {
  resourceId: Scalars['Int'];
};

export type MutationDeleteOutreachAttemptArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteUserCareEligibilitySubmissionArgs = {
  id: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationDeleteUserContactArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteZendeskUploadArgs = {
  token: Scalars['String'];
};

export type MutationDisableDeviceArgs = {
  firebaseToken: Scalars['String'];
};

export type MutationDismissNotificationArgs = {
  id: Scalars['Float'];
};

export type MutationEditContractScopeArgs = {
  input: EditContractScopeInput;
};

export type MutationEditContractWeeksArgs = {
  input: EditContractWeeksInput;
};

export type MutationEditOrganizationAnnouncementArgs = {
  id: Scalars['Float'];
  input: EditOrganizationAnnouncementInput;
};

export type MutationEditOrganizationHandbookArgs = {
  input: EditOrganizationHandbookInput;
  organizationId: Scalars['Float'];
};

export type MutationEditSafetyPlanArgs = {
  plan: SafetyPlanInput;
};

export type MutationEditTicketArgs = {
  id: Scalars['Float'];
  ticket: EditTicketInput;
};

export type MutationEmailUnsubscribeArgs = {
  token: Scalars['String'];
};

export type MutationFacultyHubReferralArgs = {
  email: Scalars['String'];
};

export type MutationFindCareFinishArgs = {
  submissions: Array<HubAssessmentSubmission>;
};

export type MutationFinishProviderPasswordResetArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationFinishResetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationFinishSkillReflectionArgs = {
  discardReflection: Scalars['Boolean'];
  skillId: Scalars['String'];
};

export type MutationForceNextScalesToNowArgs = {
  userId: Scalars['Float'];
};

export type MutationForgotProviderPasswordArgs = {
  email: Scalars['String'];
};

export type MutationGetOrCreateNoteArgs = {
  key: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationHubUserOnboardArgs = {
  birthDate: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
};

export type MutationImpersonateArgs = {
  providerToImpersonateId: Scalars['Float'];
  reason: Scalars['String'];
};

export type MutationInitializeStudentArgs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  state: StateCodes;
  zip: Scalars['String'];
};

export type MutationLoginHubArgs = {
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type MutationLoginToHubArgs = {
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type MutationLoginUserWithPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationMarkCareTeamChannelsAsReadArgs = {
  channels: Array<Scalars['Float']>;
};

export type MutationMarkEmbedPatientCoachingInactiveArgs = {
  userId: Scalars['Int'];
};

export type MutationMarkHealthieAppointmentForCancellationArgs = {
  appointmentId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
};

export type MutationMarkReferralCompleteArgs = {
  userId: Scalars['Float'];
};

export type MutationMarkSkillAssignmentSeenByAssignerArgs = {
  skillAssignmentId: Scalars['Float'];
};

export type MutationMarkSkillCompleteArgs = {
  input: SkillCompletionInputModel;
};

export type MutationMarkSkillStartedArgs = {
  input: SkillCompletionInputModel;
};

export type MutationMinorConsentApproveUserArgs = {
  userId: Scalars['Int'];
};

export type MutationMinorConsentOverrideUserArgs = {
  birthDate: Scalars['UserBirthdate'];
  userId: Scalars['Int'];
};

export type MutationNetworkSearchAccommodationRequestArgs = {
  accommodationRequestDetails: Scalars['String'];
};

export type MutationNotifyOrganizationCrisisTeamArgs = {
  crisisId: Scalars['String'];
};

export type MutationPasswordlessLoginArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type MutationPatientMarkAsReadArgs = {
  providerId: Scalars['Float'];
};

export type MutationPatientMessageProviderArgs = {
  attachmentIds?: InputMaybe<Array<Scalars['Float']>>;
  content: Scalars['String'];
  providerId: Scalars['Float'];
};

export type MutationPreLoginOrPasswordLoginArgs = {
  app: SamlUserApp;
  email: Scalars['String'];
  mrn?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type MutationPreSignUpArgs = {
  app: SamlUserApp;
  email: Scalars['String'];
};

export type MutationProviderMarkPrivateChannelsAsReadArgs = {
  channels: Array<Scalars['Float']>;
};

export type MutationProviderMessagePatientArgs = {
  attachmentIds?: InputMaybe<Array<Scalars['Float']>>;
  content: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationPushAssessmentArgs = {
  assessment: AssessmentKey;
  userId: Scalars['Float'];
};

export type MutationReactivateSelfForSponsoredCareArgs = {
  careType: CareType;
};

export type MutationReactivateUserCareFlowArgs = {
  careType: CareType;
  paymentSource?: InputMaybe<PaymentSource>;
  userId: Scalars['Float'];
};

export type MutationReactivateUserForSponsoredCareArgs = {
  careType: CareType;
  userId: Scalars['Float'];
};

export type MutationRecheckInsuranceEligibilityArgs = {
  userId: Scalars['Int'];
};

export type MutationRecoupleOrgEntitlementsToParentArgs = {
  id: Scalars['Float'];
};

export type MutationRegenerateProviderPasswordArgs = {
  providerId: Scalars['Int'];
};

export type MutationRegisterDeviceArgs = {
  applicationName: Scalars['String'];
  firebaseToken: Scalars['String'];
  platform: Scalars['String'];
};

export type MutationRemoveProviderFromCrisisTeamsArgs = {
  providerId: Scalars['Float'];
};

export type MutationResendActivationEmailArgs = {
  email: Scalars['String'];
};

export type MutationResendTwoFactorCodeArgs = {
  firstFactorToken: Scalars['String'];
};

export type MutationResetBadgeCountArgs = {
  counterName: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetSessionStartForUserArgs = {
  appointmentsAfter?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Float'];
};

export type MutationResetSessionsForFlowArgs = {
  appointmentsAfter: Scalars['String'];
  careFlowId: Scalars['Int'];
};

export type MutationResetUserPasswordArgs = {
  userId: Scalars['Float'];
};

export type MutationResubmitClaimArgs = {
  noteId: Scalars['Int'];
};

export type MutationSaveNoteSectionsArgs = {
  sections: Array<MedicalNoteSectionInput>;
  uuid: Scalars['String'];
};

export type MutationSaveUserDemographicsArgs = {
  input: CreateUserDemographicsInput;
};

export type MutationSelectCareFlowArgs = {
  careFlows: Array<UserCareFlowInput>;
};

export type MutationSendCareTeamMessageArgs = {
  content: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationSendCoachChatMessageArgs = {
  coachingProgramId?: InputMaybe<Scalars['Float']>;
  content: Scalars['String'];
};

export type MutationSendDataSharingRequestReminderArgs = {
  userId: Scalars['Float'];
};

export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};

export type MutationSetDataSharingConsentArgs = {
  consent: Scalars['Boolean'];
  initials?: InputMaybe<Scalars['String']>;
};

export type MutationSetFlaggedForOutreachArgs = {
  userId: Scalars['Int'];
  value: Scalars['Boolean'];
};

export type MutationSetHubUserPreferredNameArgs = {
  preferredName: Scalars['String'];
};

export type MutationSetInsuranceArgs = {
  insurance: InsuranceDetailsInput;
};

export type MutationSetPasswordWithTokenArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['Float'];
};

export type MutationSetPharmacyOnPatientArgs = {
  pharmacyId?: InputMaybe<Scalars['Float']>;
};

export type MutationSetProviderSubscribedArgs = {
  providerId: Scalars['Int'];
  subscribed: Scalars['Boolean'];
};

export type MutationSetSafeOperatingHandbookUrlArgs = {
  organizationId: Scalars['Int'];
  url?: InputMaybe<Scalars['String']>;
};

export type MutationSetSuggestedPharmacyIdArgs = {
  organizationId: Scalars['Int'];
  pharmacyId?: InputMaybe<Scalars['Int']>;
};

export type MutationSetUserInsuranceArgs = {
  insurance: InsuranceDetailsInput;
  userId: Scalars['Int'];
};

export type MutationSetUserSubscribedArgs = {
  subscribed: Scalars['Boolean'];
  userId: Scalars['Int'];
};

export type MutationSetUserVerifiedArgs = {
  id: Scalars['Float'];
  verify: Scalars['Boolean'];
};

export type MutationSignUpArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type MutationSkillPathwaySubmitArgs = {
  answers: Array<SkillPathwayAnswer>;
};

export type MutationSkillReflectionDeleteArgs = {
  reflectionId: Scalars['Float'];
};

export type MutationSkillReflectionEditAnswerArgs = {
  answer: Scalars['String'];
  questionKey: Scalars['String'];
  reflectionId: Scalars['Float'];
};

export type MutationSoftDeleteUserArgs = {
  userId: Scalars['Float'];
};

export type MutationStudentHubReferralArgs = {
  email: Scalars['String'];
};

export type MutationSubmitAppointmentFeedbackArgs = {
  appointmentId: Scalars['Float'];
  feedback: AppointmentFeedbackInput;
};

export type MutationSubmitAppointmentIncidentArgs = {
  appointmentId: Scalars['Float'];
  incident: Scalars['String'];
};

export type MutationSubmitCoachingAssessmentArgs = {
  assessmentType: Scalars['String'];
  submissions: Array<HubAssessmentSubmission>;
};

export type MutationSubmitCoachingAssessmentV2Args = {
  assessmentType: CoachingAssessments;
  submissions: Array<HubAssessmentSubmission>;
};

export type MutationSubmitIntakeAssessmentArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
};

export type MutationSubmitMedicalHistoryArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
};

export type MutationSubmitNoteArgs = {
  sections: Array<MedicalNoteSectionInput>;
  uuid: Scalars['String'];
};

export type MutationSubmitProviderFeedbackArgs = {
  answers?: InputMaybe<Array<FeedbackAnswer>>;
  careType: CareType;
  feedbackType: FeedbackType;
  optedOut?: InputMaybe<Scalars['Boolean']>;
  providerId: Scalars['Int'];
};

export type MutationSubmitRequestedAssessmentArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
  requestId: Scalars['Float'];
};

export type MutationSubmitSelfRefer1ScreenerArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
};

export type MutationSwapPrimaryEmergencyContactArgs = {
  id: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationSyncHealthieProviderArgs = {
  id: Scalars['Float'];
};

export type MutationToggleOrganizationOnHoldArgs = {
  isOnHold: Scalars['Boolean'];
  organizationId: Scalars['Float'];
};

export type MutationTrackArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  tag: Scalars['String'];
};

export type MutationTrackPushOpenArgs = {
  createdAt?: InputMaybe<Scalars['Float']>;
  messageId: Scalars['String'];
  templateId?: InputMaybe<Scalars['Float']>;
};

export type MutationTriggerPasswordlessAuthFlowArgs = {
  email: Scalars['String'];
};

export type MutationUnassignSkillFromUserArgs = {
  skillAssignmentId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationUpdateAppointmentTemplatesArgs = {
  organizationId: Scalars['Int'];
  templates: Array<AppointmentTemplateUpdate>;
};

export type MutationUpdateCareFlowForUserArgs = {
  careFlow: UserCareFlowInput;
  userId: Scalars['Float'];
};

export type MutationUpdateCertificationsArgs = {
  certifications: Array<ProviderCertificationInput>;
  providerId: Scalars['Float'];
};

export type MutationUpdateCouponArgs = {
  updateCoupon: UpdateCoupon;
};

export type MutationUpdateCrisisTeamHoursArgs = {
  crisisTeamHours: Array<CrisisTeamHoursInput>;
  organizationId: Scalars['Float'];
};

export type MutationUpdateEducationArgs = {
  education: Array<ProviderEducationInput>;
  providerId: Scalars['Float'];
};

export type MutationUpdateEligibilityCriteriaArgs = {
  criteria: Array<OrganizationEligibilityCriteriaInput>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateEntitlementsArgs = {
  entitlements: Array<UpdateOrganizationEntitlementInput>;
  organizationId: Scalars['Float'];
};

export type MutationUpdateHubUserArgs = {
  updates: HubUserUpdateInput;
};

export type MutationUpdateHubUser2Args = {
  updates: HubUserUpdateInput;
};

export type MutationUpdateNotificationOutcomeArgs = {
  emailId?: InputMaybe<Scalars['Int']>;
  outcome: Scalars['String'];
  smsId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateOrganizationArgs = {
  update: UpdateOrganization;
};

export type MutationUpdateOrganizationCareFlowsArgs = {
  careFlows: Array<OrganizationCareFlowInput>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateOrganizationCarePeriodsArgs = {
  editedPeriods: Array<EditOrganizationCarePeriod>;
  newPeriods: Array<NewOrganizationCarePeriod>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateOrganizationDedicatedGroupModelAllocationsArgs = {
  editedOrganizationDedicatedGroupModelAllocations: Array<OrganizationDedicatedGroupModelAllocationInput>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateOrganizationDefaultsArgs = {
  overrides: Array<OrganizationOverrides>;
};

export type MutationUpdateOrganizationsArgs = {
  organizationIds: Array<Scalars['Float']>;
  providerId: Scalars['Float'];
};

export type MutationUpdateOutreachAttemptArgs = {
  attempt: OutreachAttemptInput;
};

export type MutationUpdatePaymentMethodArgs = {
  pmToken: Scalars['String'];
  pmType: Scalars['String'];
};

export type MutationUpdatePreferencesArgs = {
  preferences: Array<CareTeamPreference>;
};

export type MutationUpdateProviderTraitsArgs = {
  providerId: Scalars['Int'];
  traits: Array<ProviderTraitInput>;
};

export type MutationUpdateQualificationsArgs = {
  providerId: Scalars['Float'];
  qualifications: Array<ProviderQualificationInput>;
};

export type MutationUpdateSamlConfigurationArgs = {
  organizationId: Scalars['Int'];
  type: SamlConfigurationType;
  update: UpdateSamlConfiguration;
};

export type MutationUpdateScreenUserResultArgs = {
  input: ScreenUserResultInput;
};

export type MutationUpdateSponsoredCareReenrollmentArgs = {
  status: ReenrollmentStatus;
};

export type MutationUpdateUser2Args = {
  updates: UpdateUserInput;
};

export type MutationUpsertEmergencyContactArgs = {
  id?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
  relationship: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationUpsertInsuranceCredentialArgs = {
  credential: UpsertProviderInsuranceCredential;
};

export type MutationUpsertSuggestedProvidersArgs = {
  providerIds: Array<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type MutationUpsertUserContactArgs = {
  input: ContactUpsertInput;
};

export type MutationUpsertUserSkillFeedbackArgs = {
  additionalFeedback: Scalars['String'];
  questions: Array<SkillFeedbackQuestionModelInput>;
  rating: Scalars['Float'];
  skillId: Scalars['String'];
};

export type MutationUserCreateCareTransitionArgs = {
  careType: CareType;
  nextSteps: NextSteps;
};

export type MutationUserScheduleAppointmentArgs = {
  checkin: Scalars['Boolean'];
  end: Scalars['DateTime'];
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId: Scalars['Float'];
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
  rescheduleId?: InputMaybe<Scalars['Float']>;
  start: Scalars['DateTime'];
};

export interface NavigateFromHubToPatientPortalError {
  errorCode: NavigateFromHubToPatientPortalErrorCode;
  message: Scalars['String'];
}

export const NavigateFromHubToPatientPortalErrorCode = {
  VerifyEligibility: 'VerifyEligibility',
} as const;

export type NavigateFromHubToPatientPortalErrorCode =
  (typeof NavigateFromHubToPatientPortalErrorCode)[keyof typeof NavigateFromHubToPatientPortalErrorCode];
export type NavigateFromHubToPatientPortalResult =
  | NavigateFromHubToPatientPortalError
  | NavigateFromHubToPatientPortalSuccess;

export interface NavigateFromHubToPatientPortalSuccess {
  loginUrl: Scalars['String'];
}

export interface NewOrganizationCarePeriod {
  endDate: Scalars['String'];
  periodType: PeriodType;
  startDate: Scalars['String'];
}

export const NextSteps = {
  ActiveSelfPay: 'activeSelfPay',
  ActiveSponsored: 'activeSponsored',
  Cancel: 'cancel',
  OnHold: 'onHold',
} as const;

export type NextSteps = (typeof NextSteps)[keyof typeof NextSteps];
export const NextStepsFilter = {
  ActiveSponsored: 'ActiveSponsored',
  Cancelled: 'Cancelled',
  CompletedSelfPay: 'CompletedSelfPay',
  Na: 'NA',
  OnHold: 'OnHold',
  PendingSelfPay: 'PendingSelfPay',
} as const;

export type NextStepsFilter = (typeof NextStepsFilter)[keyof typeof NextStepsFilter];
export interface NumericKeyValue {
  key: Scalars['ID'];
  value: Scalars['Float'];
}

export interface OnDemandEncounterInput {
  alternativeCare: AlternativeCare;
  currentLocation: Scalars['String'];
  distressLevel: Scalars['Int'];
  mainConcern: MainConcerns;
  otherAlternativeCare?: InputMaybe<Scalars['String']>;
  otherMainConcern?: InputMaybe<Scalars['String']>;
  preferredPhoneNumber: Scalars['String'];
  suicidalThoughts: SuicidalThoughts;
}

export interface OnDemandReportsInput {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
}

export interface OnDemandSession {
  callStartTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  user: OnDemandSessionUser;
}

export interface OnDemandSessionOrganization {
  id: Scalars['Float'];
  name: Scalars['String'];
}

export interface OnDemandSessionUser {
  customerId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  organization: OnDemandSessionOrganization;
}

export interface Option {
  key?: Maybe<Scalars['String']>;
  requiresManualInput?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
}

export interface Organization {
  abbreviation: Scalars['String'];
  activityStatus: Scalars['String'];
  appointmentTemplate: AppointmentTemplate;
  appointmentTemplates: Array<AppointmentTemplate>;
  careFlows: Array<OrganizationCareFlow>;
  carePeriods: Array<OrganizationCarePeriodModel>;
  children: Array<Organization>;
  childrenCount: Scalars['Int'];
  crisisHours: Array<CrisisTeamHours>;
  crisisHoursActive: Scalars['Boolean'];
  crisisTeamProviders: Array<Provider>;
  currentCarePeriodUtilization?: Maybe<UtilizationVirtualModel>;
  currentCrisisHours?: Maybe<CrisisTeamHours>;
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
  director?: Maybe<Provider>;
  eligibilityCriteria: Array<OrganizationEligibilityCriteriaModel>;
  emailDomains: Array<Scalars['String']>;
  emergencyResources: Array<OrganizationResource>;
  entitlements: Array<OrganizationEntitlement>;
  highRiskUsers: Array<User>;
  id: Scalars['Int'];
  latestAssessments: Array<AssessmentModel>;
  latestCarePeriod?: Maybe<OrganizationCarePeriodModel>;
  latestNotes: Array<MedicalNote>;
  logo?: Maybe<Upload>;
  missedAppointments: Array<CalendarEvent>;
  name: Scalars['String'];
  nextAvailableTimeSlots: Array<Availability>;
  organizationUtilization?: Maybe<OrganizationUtilizationVirtualModel>;
  parent?: Maybe<Organization>;
  providers: Array<Provider>;
  providersWithParent: Array<Provider>;
  referralCredits?: Maybe<OrganizationReferralCreditOverview>;
  reporting: OrganizationReport;
  resources: Array<OrganizationResource>;
  resourcesUpdatedAt?: Maybe<Scalars['DateTime']>;
  resourcesUpdatedBy?: Maybe<Provider>;
  safeOperatingHandbook: OrganizationHandbook;
  safeOperatingHandbookUrl?: Maybe<Scalars['String']>;
  safetyPlans: Array<SafetyPlan>;
  samlConfiguration?: Maybe<SamlConfiguration>;
  suggestedPharmacy?: Maybe<Pharmacy>;
  suicidalRiskUsers: Array<User>;
  type: Scalars['String'];
  usersWithOnlyIncompleteReferrals: Array<User>;
  usersWithoutReferrals: Array<User>;
  usesDedicatedGroupModel: Scalars['Boolean'];
}

export type OrganizationAppointmentTemplateArgs = {
  careType: CareType;
};

export type OrganizationLatestAssessmentsArgs = {
  limit: Scalars['Float'];
};

export type OrganizationLatestNotesArgs = {
  key?: InputMaybe<Scalars['String']>;
  limit: Scalars['Float'];
};

export type OrganizationNextAvailableTimeSlotsArgs = {
  appointmentType: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
};

export type OrganizationProvidersArgs = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type OrganizationProvidersWithParentArgs = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type OrganizationReportingArgs = {
  carePeriodId?: InputMaybe<Scalars['Float']>;
  referralSource?: InputMaybe<ReferralSource>;
};

export type OrganizationSafetyPlansArgs = {
  complete?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationSamlConfigurationArgs = {
  type: SamlConfigurationType;
};

export interface OrganizationAnnouncement {
  createdAt: Scalars['DateTime'];
  createdByProviderName: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  inCurrentCarePeriod: Scalars['Boolean'];
  title: Scalars['String'];
}

export interface OrganizationCareFlow {
  canMcpUsersRefer: Scalars['Boolean'];
  canReferrerUsersRefer: Scalars['Boolean'];
  canSelfRefer: Scalars['Boolean'];
  careType: CareType;
  defaultPaymentSource: PaymentSource;
  hideSessionCount: Scalars['Boolean'];
  id: Scalars['Int'];
  referralRestrictedStates: Array<StateCodes>;
  sponsoredSessions?: Maybe<Scalars['Float']>;
  sponsoredSessionsLimitType: SponsoredSessionsLimitType;
  statesAvailable: Array<StateCodes>;
}

export interface OrganizationCareFlowInput {
  canMcpUsersRefer: Scalars['Boolean'];
  canReferrerUsersRefer: Scalars['Boolean'];
  canSelfRefer: Scalars['Boolean'];
  careType: CareType;
  defaultPaymentSource: PaymentSource;
  hideSessionCount: Scalars['Boolean'];
  referralRestrictedStates: Array<StateCodes>;
  sponsoredSessions?: InputMaybe<Scalars['Float']>;
  sponsoredSessionsLimitType: SponsoredSessionsLimitType;
}

export interface OrganizationCarePeriodModel {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  organizationId: Scalars['Int'];
  periodType: PeriodType;
  startDate: Scalars['String'];
}

export interface OrganizationDedicatedGroupModelAllocationInput {
  id: Scalars['Int'];
  psychiatryMaxHours?: InputMaybe<Scalars['Float']>;
  psychiatryMaxIntakeHours?: InputMaybe<Scalars['Int']>;
  therapyMaxHours?: InputMaybe<Scalars['Float']>;
  therapyMaxIntakeHours?: InputMaybe<Scalars['Int']>;
}

export interface OrganizationEligibilityCriteriaInput {
  minimumAge?: InputMaybe<Scalars['Float']>;
  permittedCareTypes?: InputMaybe<Array<CareType>>;
  states?: InputMaybe<Array<Scalars['String']>>;
}

export interface OrganizationEligibilityCriteriaModel {
  id: Scalars['Int'];
  minimumAge?: Maybe<Scalars['Float']>;
  permittedCareTypes?: Maybe<Array<CareType>>;
  states?: Maybe<Array<Scalars['String']>>;
}

export interface OrganizationEntitlement {
  config: Scalars['JSON'];
  id: Scalars['Int'];
  key: Entitlement;
}

export interface OrganizationEntitlementInput {
  config?: InputMaybe<Scalars['JSON']>;
  key: Entitlement;
}

export interface OrganizationHandbook {
  campusCrisisTeamInProduct: Scalars['String'];
  campusSafetyPoliceNumber: Scalars['String'];
  coPayChargedByPartner: Scalars['String'];
  defaultTherapyCadence: Scalars['String'];
  ekgsNearCampus: Scalars['String'];
  fullADHDEvaluation: Scalars['String'];
  hasManualReactivationEntitlement: Scalars['Boolean'];
  highLevelProtocolManagingEmergency: Scalars['String'];
  hospitalization: Scalars['String'];
  labsNearCampus: Scalars['String'];
  mainPointOfContactCrisis: Scalars['String'];
  noShowsCountForSessionLimits: Scalars['String'];
  organizationId: Scalars['Int'];
  protocolForMedicalLeaveDoc: Scalars['String'];
  requestingAcademicAccomodations: Scalars['String'];
  sessionLimitsRenew: Scalars['String'];
  summerBreakEligibility: Scalars['String'];
  supportingClinician: Scalars['String'];
  vitalsNearCampus: Scalars['String'];
  winterBreakEligibility: Scalars['String'];
}

export interface OrganizationOption {
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface OrganizationOverrides {
  key: Scalars['String'];
  organizationId: Scalars['Float'];
  value: Scalars['JSON'];
}

export interface OrganizationReferralCreditOverview {
  availableCredits: Scalars['Int'];
  psychiatryCreditWeight: Scalars['Int'];
  referralPeriodEnd?: Maybe<Scalars['DateTime']>;
  therapyCreditWeight: Scalars['Int'];
}

export interface OrganizationReport {
  activeStudentsCount: Scalars['Float'];
  /** Average Time from Referral to First Visit (Seconds) */
  averageReferralToVisitTime?: Maybe<Scalars['Float']>;
  careTeamReferrals: Array<CareTeamReferral>;
  diagnosesBreakdown: Array<UserDiagnosis>;
  patientMonitoring: Array<User>;
  referralCompletionRate?: Maybe<Scalars['Float']>;
  scope: ReportScope;
  visitAttendance: VisitAttendance;
  visitSatisfactionRatings: SatisfactionRating;
}

export interface OrganizationResource {
  contact?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  resourceSubtype?: Maybe<Scalars['String']>;
  resourceType: ResourceType;
  showAsCrisisResource: Scalars['Boolean'];
  tags: Array<ResourceTag>;
  website?: Maybe<Scalars['String']>;
}

export interface OrganizationResourceInput {
  contact?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  resourceSubtype?: InputMaybe<Scalars['String']>;
  resourceType: ResourceType;
  showAsCrisisResource?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<ResourceTag>>;
  website?: InputMaybe<Scalars['String']>;
}

/** All possible organization types */
export const OrganizationType = {
  University: 'University',
} as const;

export type OrganizationType = (typeof OrganizationType)[keyof typeof OrganizationType];
export interface OrganizationUtilizationVirtualModel {
  carePeriodUtilizations: Array<UtilizationVirtualModel>;
}

export interface OrganizationUtilizationWeek {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  organizationId: Scalars['Int'];
  psychiatryHours: UtilizationHours;
  startDate: Scalars['String'];
  therapyHours: UtilizationHours;
}

export interface OutreachAttemptInput {
  attemptNumber?: InputMaybe<Scalars['Int']>;
  createdByCustom?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  methods: Array<Scalars['String']>;
  outcome: Scalars['String'];
  subject: Scalars['String'];
  userId: Scalars['Int'];
  zendeskTicketId?: InputMaybe<Scalars['Int']>;
}

export interface OutreachAttemptModel {
  attemptNumber?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Provider>;
  createdByCustom?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  methods: Array<Scalars['String']>;
  outcome: Scalars['String'];
  subject: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  zendeskTicketId?: Maybe<Scalars['Int']>;
}

export interface PaginatedConnectNowSession {
  count?: Maybe<Scalars['Float']>;
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<ConnectNowSession>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PaginatedIopReferralInput {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  partner: ReferralPartners;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
}

export interface PaginatedIopReferrals {
  count: Scalars['Float'];
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<IopReferralModel>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PaginatedOnDemandSession {
  count?: Maybe<Scalars['Float']>;
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<OnDemandSession>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PaginatedUsers {
  count: Scalars['Float'];
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<User>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PartnerReferralInput {
  birthDate: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationId?: InputMaybe<Scalars['Float']>;
  partner: ReferralPartners;
  preferredName?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
  referralEmail?: InputMaybe<Scalars['String']>;
  referralSource: ReferralSource;
  userId?: InputMaybe<Scalars['Float']>;
}

export interface PasswordAuthResult {
  authToken?: Maybe<Scalars['String']>;
  blockedReason?: Maybe<Scalars['String']>;
  firstFactorResult?: Maybe<FirstFactorResult>;
  passwordCorrect: Scalars['Boolean'];
  twoFactorNeeded: Scalars['Boolean'];
  useOtherApp?: Maybe<UseOtherApp>;
}

export interface PasswordlessLoginResult {
  activationLinkExpired?: Maybe<Scalars['Boolean']>;
  authToken?: Maybe<Scalars['String']>;
  canLogin?: Maybe<Scalars['Boolean']>;
}

export interface PatientChannel {
  channel: Channel;
  id: Scalars['Float'];
  user: User;
}

export interface PatientDetail {
  breakLine?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}

export const PatientRelationshipToSubscriber = {
  CadaverDonor: 'CadaverDonor',
  Child: 'Child',
  ChildWhereInsuredHasNoFinancialResponsibility: 'ChildWhereInsuredHasNoFinancialResponsibility',
  EmancipatedMinor: 'EmancipatedMinor',
  Employee: 'Employee',
  Father: 'Father',
  FosterChild: 'FosterChild',
  Grandchild: 'Grandchild',
  Grandparent: 'Grandparent',
  HandicappedDependent: 'HandicappedDependent',
  InjuredPlaintiff: 'InjuredPlaintiff',
  LifePartner: 'LifePartner',
  Mother: 'Mother',
  NieceOrNephew: 'NieceOrNephew',
  OrganDonor: 'OrganDonor',
  Other: 'Other',
  Self: 'Self',
  SignificantOther: 'SignificantOther',
  SponsoredDependent: 'SponsoredDependent',
  Spouse: 'Spouse',
  StepsonOrStepdaughter: 'StepsonOrStepdaughter',
  Unknown: 'Unknown',
  WardOfTheCourt: 'WardOfTheCourt',
} as const;

export type PatientRelationshipToSubscriber =
  (typeof PatientRelationshipToSubscriber)[keyof typeof PatientRelationshipToSubscriber];
export interface Payer {
  billToProviderName: Scalars['Boolean'];
  id: Scalars['Float'];
  inNetworkStates: Array<Scalars['String']>;
  name: Scalars['String'];
  standardPayerId: Scalars['String'];
  stateAddressOverrides: Array<PayerStateAddressOverride>;
  tradingPartnerId: Scalars['String'];
  useAddressForAllStates?: Maybe<Scalars['String']>;
  visible: Scalars['Boolean'];
}

export interface PayerStateAddressOverride {
  id: Scalars['Int'];
  overrideForState: StateCodes;
  stateToUseInstead: StateCodes;
}

export interface PayerStateAddressOverrideInput {
  overrideForState: StateCodes;
  stateToUseInstead: StateCodes;
}

export interface PaymentMethod {
  id: Scalars['String'];
}

export interface PaymentProfile {
  cardType: Scalars['String'];
  expirationDate: Scalars['String'];
  id: Scalars['String'];
  lastFour: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
}

/** Supported Payment Sources */
export const PaymentSource = {
  Insurance: 'Insurance',
  OonInsurance: 'OONInsurance',
  Organization: 'Organization',
  SelfPay: 'SelfPay',
} as const;

export type PaymentSource = (typeof PaymentSource)[keyof typeof PaymentSource];
export interface PaymentSubscription {
  id: Scalars['String'];
  nextInvoice: Scalars['Float'];
}

export const PaymentType = {
  SelfPay: 'selfPay',
  Sponsored: 'sponsored',
} as const;

export type PaymentType = (typeof PaymentType)[keyof typeof PaymentType];
export const PeriodType = {
  NonSponsoredBreak: 'nonSponsoredBreak',
  NonSponsoredTerm: 'nonSponsoredTerm',
  SponsoredBreak: 'sponsoredBreak',
  SponsoredTerm: 'sponsoredTerm',
} as const;

export type PeriodType = (typeof PeriodType)[keyof typeof PeriodType];
/** Pharmacy data model from Dosespot. */
export interface Pharmacy {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  pharmacyId: Scalars['Float'];
  primary?: Maybe<Scalars['Boolean']>;
  primaryFax?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  serviceLevel?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  storeName: Scalars['String'];
  zipCode: Scalars['String'];
}

export const PortalKey = {
  Coaching: 'coaching',
  ConnectNow: 'connectNow',
  ExecutiveFunctioning: 'executiveFunctioning',
  Hub: 'hub',
  Loca: 'loca',
  LocaWellness: 'locaWellness',
  NonLocaTherapyGateway: 'nonLocaTherapyGateway',
  Odes: 'odes',
  Psychiatry: 'psychiatry',
  Skills: 'skills',
  Therapy: 'therapy',
  Togetherall: 'togetherall',
} as const;

export type PortalKey = (typeof PortalKey)[keyof typeof PortalKey];
export interface PreLoginOrPasswordLoginError {
  errorCode: PreLoginOrPasswordLoginErrorCode;
  message: Scalars['String'];
}

export const PreLoginOrPasswordLoginErrorCode = {
  CouldNotLogin: 'CouldNotLogin',
  Deactivated: 'Deactivated',
  PasswordRequired: 'PasswordRequired',
} as const;

export type PreLoginOrPasswordLoginErrorCode =
  (typeof PreLoginOrPasswordLoginErrorCode)[keyof typeof PreLoginOrPasswordLoginErrorCode];
export interface PreLoginOrPasswordLoginPassword {
  passwordRequired: Scalars['Boolean'];
}

export interface PreLoginOrPasswordLoginRedirect {
  externalRedirect: Scalars['String'];
}

export type PreLoginOrPasswordLoginResult =
  | PreLoginOrPasswordLoginError
  | PreLoginOrPasswordLoginPassword
  | PreLoginOrPasswordLoginRedirect
  | PreLoginOrPasswordLoginSaml
  | PreLoginOrPasswordLoginSuccess;

export interface PreLoginOrPasswordLoginSaml {
  samlEntryPoint: Scalars['String'];
}

export interface PreLoginOrPasswordLoginSuccess {
  /** Mobile only */
  hasHubOnboarded?: Maybe<Scalars['Boolean']>;
  /** Mobile only */
  jwt?: Maybe<Scalars['String']>;
  loginSuccess: Scalars['Boolean'];
}

export interface PreSignUpError {
  errorCode: PreSignUpErrorCode;
  message: Scalars['String'];
}

export const PreSignUpErrorCode = {
  CampusReferralRequired: 'CampusReferralRequired',
  EligibilityError: 'EligibilityError',
  UnknownPartner: 'UnknownPartner',
} as const;

export type PreSignUpErrorCode = (typeof PreSignUpErrorCode)[keyof typeof PreSignUpErrorCode];
export type PreSignUpResult = PreSignUpError | PreSignUpSsoRedirect | PreSignUpSuccess;

export interface PreSignUpSsoRedirect {
  samlEntryPoint: Scalars['String'];
}

export interface PreSignUpSuccess {
  successMessage: Scalars['String'];
}

export interface Prescription {
  daysSupply?: Maybe<Scalars['Float']>;
  directions: Scalars['String'];
  dispenseUnitDescription: Scalars['String'];
  displayName: Scalars['String'];
  doseForm: Scalars['String'];
  genericProductName: Scalars['String'];
  info?: Maybe<MedicationInfo>;
  medicationStatus: MedicationStatus;
  prescriptionId: Scalars['Float'];
  quantity: Scalars['String'];
  rawName?: Maybe<Scalars['String']>;
  status: Scalars['Float'];
  strength: Scalars['String'];
  writtenDate: Scalars['String'];
}

export interface Prices {
  lateCancellationFee: Scalars['Int'];
  noShowFee: Scalars['Int'];
  psychiatryFollowUp: Scalars['Int'];
  psychiatryIntake: Scalars['Int'];
  therapyFollowUp: Scalars['Int'];
  therapyIntake: Scalars['Int'];
}

export interface PricesInCents {
  lateCancellationFeeInCents: Scalars['Int'];
  noShowFeeInCents: Scalars['Int'];
  psychiatryFollowUpPriceInCents: Scalars['Int'];
  psychiatryIntakePriceInCents: Scalars['Int'];
  therapyFollowUpPriceInCents: Scalars['Int'];
  therapyIntakePriceInCents: Scalars['Int'];
}

export interface Provider {
  /** don't use this in lists, it doesn't use a dataloader yet */
  acceptingNewPatients: Scalars['Boolean'];
  accountSecurityStatus: Scalars['String'];
  activityStatus?: Maybe<Scalars['String']>;
  /** oz, mcp, or referral */
  appView: AppView;
  careTypes: Array<CareType>;
  certifications: Array<ProviderCertification>;
  classification: Scalars['String'];
  degrees: Array<ProviderEducation>;
  dosespotId?: Maybe<Scalars['Float']>;
  eligibleForSchedulingPromotion: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  funFact: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  geoStates: Array<StateCodes>;
  guaranteedPay: Scalars['Boolean'];
  hasDosespot: Scalars['Boolean'];
  hasSso?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  insuranceCredentials: Array<ProviderInsuranceCredential>;
  lastName?: Maybe<Scalars['String']>;
  /** virtualized firstName + lastName */
  name: Scalars['String'];
  npi?: Maybe<Scalars['String']>;
  organizationCrisisTeams: Array<CrisisTeamRelationship>;
  organizations: Array<Organization>;
  patients: Array<User>;
  permissions: Array<Permission>;
  personalTitle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  portrait?: Maybe<Upload>;
  postNominalTitles?: Maybe<Scalars['String']>;
  privateChannel: Channel;
  pronouns?: Maybe<Scalars['String']>;
  providerStates: Array<ProviderStateRelationShipModel>;
  providerType?: Maybe<Scalars['String']>;
  qualifications: Array<Scalars['String']>;
  qualifications2: Array<ProviderQualification>;
  role: Role;
  traits: Array<ProviderTrait>;
  twoFactorConfig?: Maybe<TwoFactorConfig>;
  unreadProviderMessagesCount: Scalars['Float'];
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  upcomingAvailability: Array<Availability>;
  upcomingAvailabilityV4: AvailabilityV4;
}

export type ProviderAcceptingNewPatientsArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};

export type ProviderInsuranceCredentialsArgs = {
  forState?: InputMaybe<Scalars['String']>;
};

export type ProviderUpcomingAvailabilityArgs = {
  apptType: Scalars['String'];
  careType?: InputMaybe<CareType>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  patientState?: InputMaybe<StateCodes>;
};

export type ProviderUpcomingAvailabilityV4Args = {
  apptType: Scalars['String'];
  careType?: InputMaybe<CareType>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  patientState?: InputMaybe<StateCodes>;
};

export interface ProviderCertification {
  abbreviation: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
}

export interface ProviderCertificationInput {
  abbreviation: Scalars['String'];
  name: Scalars['String'];
}

export interface ProviderEducation {
  abbreviation: Scalars['String'];
  degree: Scalars['String'];
  id: Scalars['Float'];
  university: Scalars['String'];
}

export interface ProviderEducationInput {
  abbreviation: Scalars['String'];
  degree: Scalars['String'];
  university: Scalars['String'];
}

export interface ProviderFeedback {
  careType: CareType;
  createdAt: Scalars['DateTime'];
  optedOut?: Maybe<Scalars['Boolean']>;
  providerId: Scalars['Int'];
  questions?: Maybe<Array<FormSection>>;
  userId: Scalars['Int'];
}

export const ProviderGenderSearch = {
  Man: 'Man',
  NonBinary: 'NonBinary',
  TransgenderMan: 'TransgenderMan',
  TransgenderWoman: 'TransgenderWoman',
  Woman: 'Woman',
} as const;

export type ProviderGenderSearch = (typeof ProviderGenderSearch)[keyof typeof ProviderGenderSearch];
export interface ProviderInOfficeAvailability {
  isOutOfOffice: Scalars['Boolean'];
  nextAvailableDate?: Maybe<Scalars['LocalDate']>;
}

export interface ProviderIncident {
  appointment: CalendarEvent;
  createdAt: Scalars['DateTime'];
  incident: Scalars['String'];
}

export interface ProviderInsuranceCredential {
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  payer: Payer;
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface ProviderIsLoggedIn {
  authToken?: Maybe<Scalars['String']>;
  isLoggedIn: Scalars['Boolean'];
}

export interface ProviderNetworkResult {
  /** Number of providers out of payer network */
  outOfNetworkCount: Scalars['Float'];
  providers: Array<Provider>;
  /** The state code if the state is not allowed for the organization and care type. */
  stateNotAllowed?: Maybe<StateCodes>;
}

export interface ProviderQualification {
  abbreviation: Scalars['String'];
  id: Scalars['Float'];
  licences: Array<Scalars['String']>;
  name: Scalars['String'];
  states: Array<Scalars['String']>;
}

export interface ProviderQualificationInput {
  abbreviation: Scalars['String'];
  licences: Array<Scalars['String']>;
  name: Scalars['String'];
  states: Array<Scalars['String']>;
}

export const ProviderServiceBlockedReason = {
  PatientMissingGeoState: 'PatientMissingGeoState',
} as const;

export type ProviderServiceBlockedReason =
  (typeof ProviderServiceBlockedReason)[keyof typeof ProviderServiceBlockedReason];
export interface ProviderStateAllocationsModel {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  maxHours: Scalars['Float'];
  maxIntakeHours: Scalars['Float'];
  providerState: ProviderStateRelationShipModel;
  providerStateId: Scalars['Int'];
  startDate: Scalars['String'];
}

export interface ProviderStateRelationShipModel {
  id: Scalars['Int'];
  provider: Provider;
  providerId: Scalars['Int'];
  state: StateCodes;
}

export interface ProviderTrait {
  category: TraitCategory;
  values: Array<Scalars['String']>;
}

export interface ProviderTraitInput {
  category: TraitCategory;
  values: Array<Scalars['String']>;
}

export interface Query {
  activeSubscriptions: Array<PaymentSubscription>;
  adminAppointment: CalendarEvent;
  adminAppointmentByCrisisId: MaybeBlockedCalendarEvents;
  adminAppointmentByUser: CalendarEvent;
  adminAppointments: Array<CalendarEvent>;
  adminAppointmentsToday: Array<CalendarEvent>;
  adminAssessment: Assessment;
  adminAssessmentResults: Array<AssessmentResponse>;
  adminAvailability: AvailabilityResult;
  adminDosespotLink?: Maybe<Scalars['String']>;
  adminExistingCalendarEventsInRange: Array<CalendarEvent>;
  /** Find appointments at risk when introducing a new provider availability */
  adminFindApptsAtRisk: Array<CalendarEvent>;
  adminGetAllocations: Array<Allocation>;
  adminGetJob: AsynchronousJob;
  adminGetStateAllocations: Array<ProviderStateAllocationsModel>;
  adminNeedsPatientOutreach: Array<User>;
  adminOrgIntakeAvailability: IntakeAvailabilityResult;
  adminOverviewReport: OrganizationReport;
  adminPatientMonitoring: Array<User>;
  adminProvider: Provider;
  adminScreenUserResult?: Maybe<ScreenUserResult>;
  adminSearchPharmacy: Array<Pharmacy>;
  adminTimeAllocationsToday: Array<Allocation>;
  adminUrgentActionUsers: Array<User>;
  adminUser: User;
  adminUserDemographics?: Maybe<UserDemographics>;
  adminUserNotifications: Array<InAppNotification>;
  adminUserPharmacies: Array<Pharmacy>;
  adminUsersNeedingBooking: Array<User>;
  allEvents: Array<Event>;
  allPayers: Array<Payer>;
  anonymousNavigateFromPatientPortal: AnonymousNavigateFromPatientPortalResult;
  appointment?: Maybe<CalendarEvent>;
  appointmentTemplate: AppointmentTemplate;
  appointments: Array<CalendarEvent>;
  assessmentByKey: Assessment;
  assessmentForRequest: Assessment;
  assessments: Array<AssessmentResponse>;
  authInstructionsForEmail: AuthInstructions;
  availability: AvailabilityResult;
  availableCoachesForEmbedPatient: Array<EmbedCoach>;
  careEligibility: CareEligibility;
  careNavigators: Array<Provider>;
  careRecommendationGetForUser: CareRecommendationResponse;
  careTeamChannels: Array<CareTeamChannel>;
  checkRegistrationValidity: RegistrationValidity;
  coachOverview: CoachOverview;
  coachingExecutiveFunctioningGoalsAssessment: Assessment;
  coachingExecutiveFunctioningWellnessAssessment: Assessment;
  coachingGoalsAssessment: Assessment;
  coachingIntakeProgress: CoachingIntakeProgress;
  coachingIntakeProgressV2: CoachingIntakeProgressV2;
  coachingKickoffLink: Scalars['String'];
  coachingWellnessAssessment: Assessment;
  configs: Array<ConfigurationOption>;
  connectNowOrgSessions?: Maybe<PaginatedConnectNowSession>;
  connectNowSessionReport?: Maybe<ConnectNowSessionDocumentOutput>;
  coupons: Array<ServiceCoupon>;
  crisisReportsForOrg: Array<DdhFileSuccess>;
  crisisReportsZip: DdhFileSuccess;
  /** @deprecated Use onboardingHubUser or onboardedHubUser instead. */
  currentHubUser: HubUser;
  currentProvider: Provider;
  currentUser: User;
  customClinicalRecConfigGetForUser: CustomClinicalRecConfig;
  /** Internal debug tooling */
  debugCareFlowsForUser: Array<DebugUserCareFlow>;
  /** Internal debug tooling */
  debugDataSharingConsentsForUser: Array<DataSharingConsent>;
  /** Internal debug tooling */
  debugDataSharingRequestsForUser: Array<DataSharingRequest>;
  doesProviderNeedToReactivateUserCareType?: Maybe<ReactivationType>;
  doesUserNeedToReactivateCareType?: Maybe<UserSponsoredReactivation>;
  /** states in which psychiatry is available for dtc */
  dtcPsychiatryStates: Array<Scalars['String']>;
  /** states in which therapy is available for dtc */
  dtcTherapyStates: Array<Scalars['String']>;
  embedPatient?: Maybe<EmbedPatient>;
  embedPatientCoachingStatus?: Maybe<CoachingStatus>;
  embedPatientIdFromHealthieId?: Maybe<Scalars['Float']>;
  entitlementDefinitions: Array<EntitlementDefinition>;
  events: Array<Event>;
  /** environment specific configuration */
  featureFlags: Array<Scalars['String']>;
  fetchProviderFeedbackTemplate: ProviderFeedback;
  findCareStart: FindCareStartOutput;
  findCareUserHasCompleted: Scalars['Boolean'];
  genericCollegeModal: Array<GenericCollegeModalModel>;
  getAllCompletedSkillsForModule: Array<Scalars['String']>;
  getAllNotesAndFormSubmissionsForUser: Array<MedicalNote>;
  getAllNotesForUser: Array<MedicalNote>;
  getAssessmentLink: Scalars['String'];
  getAssignableSkills: Array<AssignableSkill>;
  getBadgeCount: Scalars['Float'];
  getCareRecommendationStatusForUser: RecommendationStatusForUserResponse;
  getClaim: InsuranceClaim;
  getClaims: Array<InsuranceClaim>;
  getCoachChatMessages: Array<CoachChatMessage>;
  getContract: GetContractResult;
  getEligibilityCarePeriodValues: ContinuityOfCareEligibilityCarePeriodValues;
  getEligibilityResult: ContinuityOfCareEligibilityResult;
  getHubStatusForUser: HubStatusForUser;
  getJumpBackInSkills: Array<JumpBackInSkillData>;
  getMySkillsAssignments: UserSkillAssignments;
  getNoteByUuid: MedicalNote;
  getPortals: Array<PortalKey>;
  getPossibleAssignees: Array<Provider>;
  getSkillAssignmentCountsForUser: SkillAssignmentCountsResponse;
  getSkillsAssignedToUser: UserSkillAssignments;
  getUpNextSkill: GetUpNextSkillResult;
  getUpNextSkillForModule: GetUpNextSkillForModuleResult;
  hasCompletedAnySkill: Scalars['Boolean'];
  hasSkillBeenStarted: Scalars['Boolean'];
  healthieProviderSynced: Scalars['Boolean'];
  hubAppointmentById: HubAppointment;
  hubGetAssessmentLink: Scalars['String'];
  hubOrganizationResources: Array<HubOrganizationResource>;
  /** @deprecated Deprecated use "appointment" with the appt id */
  intakeCall: CalendarEvent;
  isConnectNowServiceAvailable: Scalars['Boolean'];
  isLoggedInHub: Scalars['Boolean'];
  isOnDemandServiceAvailable: Scalars['Boolean'];
  isSkillComplete: Scalars['Boolean'];
  latestAssessment: AssessmentResponse;
  listContracts: ListContractsModel;
  loadUnfinishedSkillReflection?: Maybe<UnfinishedSkillReflection>;
  mantraAdmins: Array<Provider>;
  mcpCurrentContractUtilization: McpCurrentContractUtilizationResult;
  medicationsForUser: Array<Prescription>;
  minorConsentStatusForUser: MinorConsentStatusModel;
  navigateFromHubMobileToWeb: Scalars['String'];
  /** @deprecated Replaced by navigateFromHubToPatientPortal2 */
  navigateFromHubToPatientPortal: Scalars['String'];
  navigateFromHubToPatientPortal2: NavigateFromHubToPatientPortalResult;
  navigateFromPatientPortalToHub: Scalars['String'];
  newClaimsCount: Scalars['Float'];
  nextAppointment?: Maybe<CalendarEvent>;
  nextAssessment?: Maybe<Scalars['DateTime']>;
  nextAvailableAppointment?: Maybe<Scalars['DateTime']>;
  nextHubExternalAppointment?: Maybe<HubExternalAppointment>;
  notifications: Array<InAppNotification>;
  onDemandOrgSessions?: Maybe<PaginatedOnDemandSession>;
  onboardedHubUser: HubUser;
  onboardingHubUser: HubUser;
  organization: Organization;
  organizationAnnouncementsByDays: Array<OrganizationAnnouncement>;
  organizationAnnouncementsByOrgId: Array<OrganizationAnnouncement>;
  organizationCampusTeam?: Maybe<Array<CampusTeamRelationship>>;
  organizationConnectNow?: Maybe<HubConnectNowOutput>;
  organizationCustomHubModule: HubCustomModuleData;
  organizationHandbook: OrganizationHandbook;
  organizationOnDemand?: Maybe<HubOnDemandOutput>;
  organizations: Array<Organization>;
  paginatedIopReferrals: PaginatedIopReferrals;
  paginatedUsers: PaginatedUsers;
  patients: Array<User>;
  patientsMonitoredCount?: Maybe<Scalars['Float']>;
  payers: Array<Payer>;
  permissibleUploadTypes: Array<UploadType>;
  pharmacies: Array<Pharmacy>;
  portalCareRecommendationForUser: CareRecommendation;
  prescriptions: Array<Prescription>;
  /** @deprecated Deprecated use "pricesInCents" resolver */
  prices: Prices;
  pricesInCents: PricesInCents;
  privateChannels: Array<PatientChannel>;
  promotionWindowInDays: Scalars['Float'];
  providerById: Provider;
  /** Current provider's campus team events */
  providerCampusTeamEvents?: Maybe<Array<CampusTeamRelationshipEvents>>;
  providerEmails: Array<SentEmail>;
  providerIsLoggedIn: ProviderIsLoggedIn;
  providerNetworkSearch: ProviderNetworkResult;
  providerOfficeAvailability: ProviderInOfficeAvailability;
  providerSafetyPlans: Array<SafetyPlan>;
  providers: Array<Provider>;
  providersById: Array<Provider>;
  providersForUser: MaybeBlockedProviders;
  referrals: Array<User>;
  remainingHoursForRange: Array<RemainingHours>;
  safetyPlans: Array<SafetyPlan>;
  searchPharmacy: Array<Pharmacy>;
  searchPharmacyByAddress: Array<Pharmacy>;
  searchUsers: Array<User>;
  skillPathwayGetForUser?: Maybe<Array<SkillPathwayStep>>;
  skillPathwayNextSkill: SkillPathwayNextSkill;
  skillReflectionGetAllForUser: UserSkillReflections;
  skillReflectionGetUserAnswers?: Maybe<SkillReflectionAnswers>;
  ticket: Ticket;
  tickets: Array<Ticket>;
  traitCategories: Array<ProviderTrait>;
  unreadCareTeamMessages: Scalars['Float'];
  unreadHubExternalMessages?: Maybe<Array<UnreadHubExternalMessage>>;
  unreadPatientMessages: Scalars['Float'];
  unsubmittedMedicalNotes: Array<MedicalNote>;
  /** number of users who need ID verification */
  unverifiedUserCount: Scalars['Float'];
  upcomingHubAppointment?: Maybe<HubAppointment>;
  userCoachingHealthieStatus: UserCoachingHealthieStatus;
  userCoachingStatus: UserCoachingStatus;
  userDemographics?: Maybe<UserDemographics>;
  userEmails: Array<SentEmail>;
  userHealthieStatus: UserHealthieStatus;
  userIsLoggedIn: UserIsLoggedIn;
  userOutreachAttempts: Array<OutreachAttemptModel>;
  userReferralStatus: User;
  userSkillFeedback?: Maybe<SkillFeedbackModel>;
  userSmses: Array<SentSmsModel>;
  users: Array<User>;
  usersOrganizations: Array<OrganizationOption>;
  weeklyCapacityUsage: Array<CapacityUsage>;
  zendeskLink: Scalars['String'];
}

export type QueryAdminAppointmentArgs = {
  id: Scalars['Float'];
};

export type QueryAdminAppointmentByCrisisIdArgs = {
  crisisId: Scalars['String'];
};

export type QueryAdminAppointmentByUserArgs = {
  appointmentId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type QueryAdminAppointmentsArgs = {
  allProviders?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  providerId?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryAdminAssessmentArgs = {
  assessment: AssessmentKey;
  userId: Scalars['Float'];
};

export type QueryAdminAssessmentResultsArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminAvailabilityArgs = {
  appointmentType: Scalars['String'];
  careType: CareType;
  duration: Scalars['Float'];
  end?: InputMaybe<Scalars['String']>;
  patientState?: InputMaybe<StateCodes>;
  providerIds: Array<Scalars['Float']>;
  rescheduleId?: InputMaybe<Scalars['Float']>;
  start: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  userId: Scalars['Float'];
};

export type QueryAdminDosespotLinkArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminExistingCalendarEventsInRangeArgs = {
  apptTypes?: InputMaybe<Array<Scalars['String']>>;
  careTypes?: InputMaybe<Array<CareType>>;
  end?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
  start: Scalars['String'];
};

export type QueryAdminFindApptsAtRiskArgs = {
  allocations: Array<CreateAllocationInput>;
  date: Scalars['String'];
  providerId: Scalars['Float'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
};

export type QueryAdminGetAllocationsArgs = {
  providerId: Scalars['Float'];
};

export type QueryAdminGetJobArgs = {
  jobId: Scalars['Float'];
};

export type QueryAdminGetStateAllocationsArgs = {
  providerId: Scalars['Float'];
};

export type QueryAdminOrgIntakeAvailabilityArgs = {
  careType: CareType;
  end?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  patientState?: InputMaybe<StateCodes>;
  providerIds: Array<Scalars['Float']>;
  start: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type QueryAdminOverviewReportArgs = {
  scope: ReportScope;
};

export type QueryAdminProviderArgs = {
  providerId: Scalars['Float'];
};

export type QueryAdminScreenUserResultArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminSearchPharmacyArgs = {
  query: Scalars['String'];
};

export type QueryAdminUserArgs = {
  id: Scalars['Float'];
};

export type QueryAdminUserDemographicsArgs = {
  id: Scalars['Float'];
};

export type QueryAdminUserNotificationsArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminUserPharmaciesArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminUsersNeedingBookingArgs = {
  careType: CareType;
};

export type QueryAllEventsArgs = {
  before?: InputMaybe<Scalars['DateTime']>;
};

export type QueryAnonymousNavigateFromPatientPortalArgs = {
  input: AnonymousNavigateFromPatientPortalInput;
};

export type QueryAppointmentArgs = {
  id: Scalars['Float'];
};

export type QueryAppointmentTemplateArgs = {
  appointmentType: Scalars['String'];
  careType: Scalars['String'];
};

export type QueryAssessmentByKeyArgs = {
  assessmentKey: AssessmentKey;
};

export type QueryAssessmentForRequestArgs = {
  requestId: Scalars['Float'];
};

export type QueryAuthInstructionsForEmailArgs = {
  app?: InputMaybe<SamlUserApp>;
  email: Scalars['String'];
  mrn?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SamlConfigurationType>;
};

export type QueryAvailabilityArgs = {
  careType?: InputMaybe<Scalars['String']>;
  checkin?: InputMaybe<Scalars['Boolean']>;
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};

export type QueryAvailableCoachesForEmbedPatientArgs = {
  userId: Scalars['Int'];
};

export type QueryCareTeamChannelsArgs = {
  providerId?: InputMaybe<Scalars['Float']>;
};

export type QueryCheckRegistrationValidityArgs = {
  email: Scalars['String'];
};

export type QueryConnectNowOrgSessionsArgs = {
  params: ConnectNowReportsInput;
};

export type QueryConnectNowSessionReportArgs = {
  id: Scalars['String'];
};

export type QueryCrisisReportsZipArgs = {
  ddhFileSuccessId: Scalars['Int'];
};

export type QueryDebugCareFlowsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryDebugDataSharingConsentsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryDebugDataSharingRequestsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryDoesProviderNeedToReactivateUserCareTypeArgs = {
  careType: CareType;
  userId: Scalars['Float'];
};

export type QueryEmbedPatientArgs = {
  patientId: Scalars['Float'];
};

export type QueryEmbedPatientCoachingStatusArgs = {
  userId: Scalars['Int'];
};

export type QueryEmbedPatientIdFromHealthieIdArgs = {
  healthieId: Scalars['String'];
};

export type QueryEventsArgs = {
  userId: Scalars['Float'];
};

export type QueryFetchProviderFeedbackTemplateArgs = {
  careType: Scalars['String'];
  feedbackType: FeedbackType;
  providerId: Scalars['Int'];
};

export type QueryFindCareStartArgs = {
  questionnaireKey: Scalars['String'];
};

export type QueryGenericCollegeModalArgs = {
  orgId: Scalars['Float'];
};

export type QueryGetAllCompletedSkillsForModuleArgs = {
  moduleId: Scalars['String'];
};

export type QueryGetAllNotesAndFormSubmissionsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryGetAllNotesForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryGetAssignableSkillsArgs = {
  userId: Scalars['Float'];
};

export type QueryGetClaimArgs = {
  id: Scalars['Float'];
};

export type QueryGetClaimsArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type QueryGetCoachChatMessagesArgs = {
  coachingProgramId?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QueryGetContractArgs = {
  id: Scalars['Float'];
};

export type QueryGetEligibilityCarePeriodValuesArgs = {
  submissionId: Scalars['Float'];
};

export type QueryGetEligibilityResultArgs = {
  submissionId: Scalars['Float'];
};

export type QueryGetMySkillsAssignmentsArgs = {
  page: Scalars['Float'];
  perPage: Scalars['Float'];
};

export type QueryGetNoteByUuidArgs = {
  uuid: Scalars['String'];
};

export type QueryGetPossibleAssigneesArgs = {
  ticketId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type QueryGetSkillsAssignedToUserArgs = {
  page: Scalars['Float'];
  perPage: Scalars['Float'];
  userId: Scalars['Float'];
};

export type QueryGetUpNextSkillArgs = {
  isPathway: Scalars['Boolean'];
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
};

export type QueryGetUpNextSkillForModuleArgs = {
  moduleId: Scalars['String'];
};

export type QueryHasSkillBeenStartedArgs = {
  input: SkillCompletionInputModel;
};

export type QueryHealthieProviderSyncedArgs = {
  id: Scalars['Float'];
};

export type QueryHubAppointmentByIdArgs = {
  appointmentId: Scalars['String'];
};

export type QueryIsSkillCompleteArgs = {
  input: SkillCompletionInputModel;
};

export type QueryLatestAssessmentArgs = {
  key: AssessmentKey;
};

export type QueryListContractsArgs = {
  organizationId: Scalars['Float'];
};

export type QueryLoadUnfinishedSkillReflectionArgs = {
  skillId: Scalars['String'];
};

export type QueryMedicationsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryMinorConsentStatusForUserArgs = {
  userId: Scalars['Int'];
};

export type QueryNavigateFromHubMobileToWebArgs = {
  source?: InputMaybe<Scalars['String']>;
};

export type QueryNavigateFromHubToPatientPortalArgs = {
  mrn?: InputMaybe<Scalars['String']>;
};

export type QueryNavigateFromHubToPatientPortal2Args = {
  mrn?: InputMaybe<Scalars['String']>;
};

export type QueryNextAvailableAppointmentArgs = {
  appointmentType: Scalars['String'];
  careType: CareType;
  organizationId: Scalars['Float'];
  startDate: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryOnDemandOrgSessionsArgs = {
  params: OnDemandReportsInput;
};

export type QueryOrganizationArgs = {
  id: Scalars['Float'];
};

export type QueryOrganizationAnnouncementsByDaysArgs = {
  days?: InputMaybe<Scalars['Float']>;
  organizationId: Scalars['Float'];
};

export type QueryOrganizationAnnouncementsByOrgIdArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  organizationId: Scalars['Float'];
};

export type QueryOrganizationCampusTeamArgs = {
  organizationId: Scalars['Float'];
};

export type QueryOrganizationHandbookArgs = {
  organizationId: Scalars['Float'];
};

export type QueryPaginatedIopReferralsArgs = {
  params: PaginatedIopReferralInput;
};

export type QueryPaginatedUsersArgs = {
  params: UserListParams;
};

export type QueryPatientsArgs = {
  providerId: Scalars['Float'];
};

export type QueryPrivateChannelsArgs = {
  providerId?: InputMaybe<Scalars['Float']>;
};

export type QueryProviderByIdArgs = {
  id: Scalars['Float'];
};

export type QueryProviderEmailsArgs = {
  providerId: Scalars['Float'];
};

export type QueryProviderNetworkSearchArgs = {
  careType: CareType;
  doesOrgUseDGMOrSessions?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Array<ProviderGenderSearch>>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  payerId?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<StateCodes>;
  traits?: InputMaybe<Array<ProviderTraitInput>>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryProviderOfficeAvailabilityArgs = {
  providerId: Scalars['Int'];
};

export type QueryProviderSafetyPlansArgs = {
  incomplete?: InputMaybe<Scalars['Boolean']>;
};

export type QueryProvidersArgs = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryProvidersByIdArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryProvidersForUserArgs = {
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryRemainingHoursForRangeArgs = {
  appointmentType: Scalars['String'];
  careType: CareType;
  end: Scalars['String'];
  organizationId: Scalars['Float'];
  start: Scalars['String'];
};

export type QuerySafetyPlansArgs = {
  complete?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySearchPharmacyArgs = {
  query: Scalars['String'];
};

export type QuerySearchPharmacyByAddressArgs = {
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type QuerySearchUsersArgs = {
  search: SearchUserInput;
};

export type QuerySkillReflectionGetAllForUserArgs = {
  page: Scalars['Float'];
  perPage: Scalars['Float'];
};

export type QuerySkillReflectionGetUserAnswersArgs = {
  reflectionId: Scalars['Float'];
};

export type QueryTicketArgs = {
  id: Scalars['Float'];
};

export type QueryTicketsArgs = {
  userId?: InputMaybe<Scalars['Float']>;
  view: TicketView;
};

export type QueryUpcomingHubAppointmentArgs = {
  delay?: InputMaybe<Scalars['Float']>;
};

export type QueryUserCoachingHealthieStatusArgs = {
  userId: Scalars['Float'];
};

export type QueryUserEmailsArgs = {
  userId: Scalars['Int'];
};

export type QueryUserHealthieStatusArgs = {
  userId: Scalars['Float'];
};

export type QueryUserOutreachAttemptsArgs = {
  userId: Scalars['Int'];
};

export type QueryUserReferralStatusArgs = {
  userId: Scalars['Float'];
};

export type QueryUserSkillFeedbackArgs = {
  skillId: Scalars['String'];
};

export type QueryUserSmsesArgs = {
  userId: Scalars['Int'];
};

export type QueryUsersArgs = {
  includeNewPatients?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
};

export type QueryUsersOrganizationsArgs = {
  params: UserListParams;
};

export type QueryWeeklyCapacityUsageArgs = {
  organizationId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  weeks: Scalars['Int'];
};

export type QueryZendeskLinkArgs = {
  userId: Scalars['Float'];
};

export interface Question {
  caption?: Maybe<Scalars['String']>;
  dependency?: Maybe<Dependency>;
  disclaimer?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['String']>>;
  isOptional?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  legend?: Maybe<Array<Scalars['String']>>;
  manualInput?: Maybe<ManualInput>;
  options?: Maybe<Array<Option>>;
  overrideDescription?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  subText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
}

export interface Questionnaire {
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  preScreen?: Maybe<QuestionnairePreScreen>;
  questions: Array<Question>;
  title: Scalars['String'];
}

export interface QuestionnairePreScreen {
  asset?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<SectionCopy>;
  title?: Maybe<Scalars['String']>;
}

export interface QuestionnaireResult {
  answers: Array<Answer>;
  assessment: AssessmentResponse;
  description: Scalars['String'];
  key: Scalars['String'];
  maximumScore?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  symptoms?: Maybe<Scalars['String']>;
}

export interface QuestionnaireSubmission {
  answers: Array<AnswerSubmission>;
  key: Scalars['String'];
}

export interface RecommendationStatusForUserResponse {
  activeRecommendation: Scalars['Boolean'];
  expiration?: Maybe<Scalars['DateTime']>;
  isActiveOrOnboardingInCare: Scalars['Boolean'];
  isActiveOrOnboardingInTherapy: Scalars['Boolean'];
}

export const ReenrollmentStatus = {
  Cancelled: 'Cancelled',
  Confirmed: 'Confirmed',
  Declined: 'Declined',
  Pending: 'Pending',
} as const;

export type ReenrollmentStatus = (typeof ReenrollmentStatus)[keyof typeof ReenrollmentStatus];
export interface ReferralError {
  errorCode: ReferralErrorCode;
  message: Scalars['String'];
}

export const ReferralErrorCode = {
  InvalidEmail: 'InvalidEmail',
  InvalidOrgEmail: 'InvalidOrgEmail',
} as const;

export type ReferralErrorCode = (typeof ReferralErrorCode)[keyof typeof ReferralErrorCode];
export const ReferralPartners = {
  CharlieHealth: 'charlie_health',
  Togetherall: 'togetherall',
} as const;

export type ReferralPartners = (typeof ReferralPartners)[keyof typeof ReferralPartners];
/** App where the user originated */
export const ReferralSource = {
  Mcp: 'MCP',
  Oz: 'OZ',
  Portal: 'PORTAL',
} as const;

export type ReferralSource = (typeof ReferralSource)[keyof typeof ReferralSource];
export interface ReflectionPagination {
  page: Scalars['Float'];
  totalPages: Scalars['Float'];
}

export interface RegistrationValidity {
  emailAlreadyExists: Scalars['Boolean'];
  emailIsEligibleForOrganization: Scalars['Boolean'];
  emailMatchesOrganization: Scalars['Boolean'];
  organizationAllowsSelfReferral: Scalars['Boolean'];
  patientPortalInitialized: Scalars['Boolean'];
}

export interface RemainingHours {
  date: Scalars['String'];
  dgmAllocationId?: Maybe<Scalars['Int']>;
  remainingHours: Scalars['Float'];
}

/** Scope of report generated */
export const ReportScope = {
  All: 'ALL',
  Dtc: 'DTC',
  Org: 'ORG',
} as const;

export type ReportScope = (typeof ReportScope)[keyof typeof ReportScope];
export interface RequiredIf {
  dependentQuestions: Array<DependentQuestions>;
}

export const ResourceTag = {
  ByAppointment: 'ByAppointment',
  InPerson: 'InPerson',
  NonEmergencies: 'NonEmergencies',
  Open247: 'Open247',
  Telehealth: 'Telehealth',
} as const;

export type ResourceTag = (typeof ResourceTag)[keyof typeof ResourceTag];
export const ResourceType = {
  AcademicAndAccessibility: 'AcademicAndAccessibility',
  Additional: 'Additional',
  DisabilityAccessibilityCenter: 'DisabilityAccessibilityCenter',
  Diversity: 'Diversity',
  Emergency: 'Emergency',
  Health: 'Health',
  HealthServices: 'HealthServices',
  LgbtqiaCenter: 'LgbtqiaCenter',
  LocalHospitalCommunityProvider: 'LocalHospitalCommunityProvider',
  Other: 'Other',
  PreferredCampusPharmacy: 'PreferredCampusPharmacy',
  PublicSafetyCampusPolice: 'PublicSafetyCampusPolice',
  ResidenceLife: 'ResidenceLife',
  Safety: 'Safety',
  UrgentCareCenter: 'UrgentCareCenter',
} as const;

export type ResourceType = (typeof ResourceType)[keyof typeof ResourceType];
export interface SafetyInfo {
  commonSideEffects: Array<SideEffect>;
  faq: Array<FaQuestion>;
  potentiallySeriousSideEffects: Array<SideEffect>;
  warning: Scalars['String'];
}

export interface SafetyPlan {
  activatingProvider: Provider;
  complete: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  data: SafetyPlanData;
  editHistory: Array<Event>;
  id: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user: User;
}

export interface SafetyPlanContact {
  address?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface SafetyPlanData {
  copingStrategies: Array<Scalars['String']>;
  crisisContacts: Array<SafetyPlanContact>;
  crisisFacilities: Array<SafetyPlanContact>;
  crisisResources: Array<SafetyPlanContact>;
  dangerousMeans?: Maybe<Scalars['Boolean']>;
  dangerousMeansList?: Maybe<Scalars['String']>;
  dangerousMeansPlan?: Maybe<Scalars['String']>;
  distractions: Array<SafetyPlanContact>;
  firearmAccess?: Maybe<Scalars['Boolean']>;
  firearmAccessPlan?: Maybe<Scalars['String']>;
  professionalContacts: Array<SafetyPlanContact>;
  reasonToLive?: Maybe<Scalars['String']>;
  warningSigns: Array<Scalars['String']>;
}

export interface SafetyPlanInput {
  copingStrategies: Array<Scalars['String']>;
  crisisContacts: Array<SafetyPlanQuestionInput>;
  crisisFacilities: Array<SafetyPlanQuestionInput>;
  crisisResources: Array<SafetyPlanQuestionInput>;
  dangerousMeans?: InputMaybe<Scalars['Boolean']>;
  dangerousMeansList?: InputMaybe<Scalars['String']>;
  dangerousMeansPlan?: InputMaybe<Scalars['String']>;
  distractions: Array<SafetyPlanQuestionInput>;
  firearmAccess?: InputMaybe<Scalars['Boolean']>;
  firearmAccessPlan?: InputMaybe<Scalars['String']>;
  professionalContacts: Array<SafetyPlanQuestionInput>;
  reasonToLive?: InputMaybe<Scalars['String']>;
  warningSigns: Array<Scalars['String']>;
}

export interface SafetyPlanQuestionInput {
  address?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface SamlConfiguration {
  authnRequestBinding?: Maybe<Scalars['String']>;
  automaticallyUpdateEmail: Scalars['Boolean'];
  campusOrgMappings?: Maybe<Array<CampusOrgMappingsInput>>;
  certificates: Array<Scalars['String']>;
  disableRequestedAuthnContext: Scalars['Boolean'];
  enableManualUniqueIdEdits: Scalars['Boolean'];
  entryPoint: Scalars['String'];
  id: Scalars['Int'];
  issuer?: Maybe<Scalars['String']>;
  metadata: Scalars['String'];
  profileCampusField?: Maybe<Scalars['String']>;
  profileEmailField: Scalars['String'];
  profileMatchingField: Scalars['String'];
  profileUniqueIdField?: Maybe<Scalars['String']>;
}

export const SamlConfigurationType = {
  Provider: 'Provider',
  User: 'User',
} as const;

export type SamlConfigurationType =
  (typeof SamlConfigurationType)[keyof typeof SamlConfigurationType];
export const SamlUserApp = {
  Hub: 'Hub',
  HubAndroid: 'HubAndroid',
  HubiOs: 'HubiOS',
} as const;

export type SamlUserApp = (typeof SamlUserApp)[keyof typeof SamlUserApp];
export interface SatisfactionRating {
  averageRating?: Maybe<Scalars['Float']>;
  numberOfRatings: Scalars['Float'];
}

export interface ScreenUserResult {
  screenedOut: Scalars['Boolean'];
  screenedOutFromHub: Scalars['Boolean'];
}

export interface ScreenUserResultInput {
  screenedOut: Scalars['Boolean'];
  screenedOutFromHub?: InputMaybe<Scalars['Boolean']>;
}

export interface SearchUserInput {
  careStatus?: InputMaybe<CareStatus>;
  createdAfter?: InputMaybe<Scalars['DateTime']>;
  createdBefore?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<Scalars['String']>;
}

export const SectionColor = {
  BrandAltBg: 'brandAltBg',
  DangerBg: 'dangerBg',
  GreyBg: 'greyBg',
} as const;

export type SectionColor = (typeof SectionColor)[keyof typeof SectionColor];
export type SectionCopy = ListCopy | TextCopy;

/** Change behavior based on another section */
export interface SectionDependency {
  key: Scalars['String'];
  /** only show if question has values */
  showValues?: Maybe<Array<Scalars['String']>>;
}

export const SectionFormat = {
  Appointment: 'appointment',
  Checkbox: 'checkbox',
  Date: 'date',
  Diagnosis: 'diagnosis',
  Formtexttitle: 'formtexttitle',
  Multicheckbox: 'multicheckbox',
  Multidiagnosis: 'multidiagnosis',
  Multiselect: 'multiselect',
  Multitext: 'multitext',
  Radio: 'radio',
  Rx: 'rx',
  Scale: 'scale',
  Select: 'select',
  Sign: 'sign',
  Singlelineinput: 'singlelineinput',
  Textarea: 'textarea',
  Title: 'title',
} as const;

export type SectionFormat = (typeof SectionFormat)[keyof typeof SectionFormat];
export interface SectionHook {
  targetKey: Scalars['String'];
  type: HookType;
}

/** Auto-select a value based on another value */
export interface SelectDependentValue {
  /** trigger selection if one of these values */
  dependsValues?: Maybe<Array<Scalars['String']>>;
  targetKey: Scalars['String'];
  /** the value to be selected */
  targetSelectValue?: Maybe<Scalars['String']>;
}

export interface SendCoachChatMessageResult {
  id?: Maybe<Scalars['String']>;
}

export interface SentEmail {
  bounced: Scalars['Boolean'];
  clicked: Scalars['Boolean'];
  delivered: Scalars['Boolean'];
  id: Scalars['Float'];
  label: Scalars['String'];
  opened: Scalars['Boolean'];
  outcome?: Maybe<Scalars['String']>;
  sent: Scalars['Boolean'];
  sentAt: Scalars['DateTime'];
  subject: Scalars['String'];
  template: Scalars['String'];
  unsubscribed: Scalars['Boolean'];
}

export interface SentSmsModel {
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  template?: Maybe<Scalars['String']>;
}

export interface ServiceCoupon {
  active: Scalars['Boolean'];
  autoApplyOnConversion: Scalars['Boolean'];
  careTypes: Array<CareType>;
  code: Scalars['String'];
  discountType: DiscountType;
  id: Scalars['Float'];
  maxUses: Scalars['Float'];
  organization?: Maybe<Organization>;
  percentDiscount?: Maybe<Scalars['Float']>;
  replacePriceCents?: Maybe<Scalars['Int']>;
}

export interface SetPasswordWithTokenError {
  errorCode: SetPasswordWithTokenErrorCode;
  message: Scalars['String'];
}

export const SetPasswordWithTokenErrorCode = {
  InvalidPasswordFormat: 'InvalidPasswordFormat',
  InvalidToken: 'InvalidToken',
  TokenExpired: 'TokenExpired',
} as const;

export type SetPasswordWithTokenErrorCode =
  (typeof SetPasswordWithTokenErrorCode)[keyof typeof SetPasswordWithTokenErrorCode];
export type SetPasswordWithTokenResult = SetPasswordWithTokenError | SetPasswordWithTokenSuccess;

export interface SetPasswordWithTokenSuccess {
  success: Scalars['Boolean'];
}

export interface SideEffect {
  explanation: Scalars['String'];
  title: Scalars['String'];
}

export interface SignUpError {
  errorCode: SignUpErrorCode;
  message: Scalars['String'];
}

export const SignUpErrorCode = {
  CampusReferralRequired: 'CampusReferralRequired',
  EligibilityError: 'EligibilityError',
  InvalidPassword: 'InvalidPassword',
  InvalidToken: 'InvalidToken',
  TokenExpired: 'TokenExpired',
  UnknownPartner: 'UnknownPartner',
} as const;

export type SignUpErrorCode = (typeof SignUpErrorCode)[keyof typeof SignUpErrorCode];
export type SignUpResult = SignUpError | SignUpSuccess;

export interface SignUpSuccess {
  successMessage: Scalars['String'];
}

export interface SignedS3Upload {
  fields: Array<Array<Scalars['String']>>;
  key: Scalars['String'];
  url: Scalars['String'];
}

export interface SimpleGlobalAvailabilityBlocker {
  date: Scalars['LocalDate'];
  providerId: Scalars['Float'];
  type: GlobalAvailabilityBlockerType;
}

export interface SkillAssignmentCountsResponse {
  notCompleted: Scalars['Int'];
  notSeen: Scalars['Int'];
}

export interface SkillAssignmentModel {
  assignedById: Scalars['Int'];
  assignerHasSeen: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
  skillReflection?: Maybe<SkillReflectionAnswers>;
  skillTitle: Scalars['String'];
  startedAt?: Maybe<Scalars['DateTime']>;
  userHasSeen: Scalars['Boolean'];
  userId: Scalars['Int'];
}

export interface SkillCompletionInputModel {
  moduleId: Scalars['String'];
  sectionId?: InputMaybe<Scalars['String']>;
  skillId: Scalars['String'];
}

export interface SkillFeedbackModel {
  additionalFeedback?: Maybe<Scalars['String']>;
  rating: Scalars['Float'];
}

export interface SkillFeedbackQuestionModelInput {
  questionSlug: Scalars['String'];
  response: Scalars['Float'];
}

export interface SkillPathwayAnswer {
  moduleId: Scalars['String'];
  nudgeIds: Array<Scalars['String']>;
  score: Scalars['Float'];
  sectionId: Scalars['String'];
}

export interface SkillPathwayNextSkill {
  hasPathway: Scalars['Boolean'];
  moduleId?: Maybe<Scalars['String']>;
  nextSkillId?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
}

export interface SkillPathwayNudge {
  id: Scalars['String'];
  name: Scalars['String'];
}

export interface SkillPathwayStep {
  moduleId: Scalars['String'];
  nudges: Array<SkillPathwayNudge>;
  score: Scalars['Float'];
  sectionId: Scalars['String'];
}

export interface SkillReflection {
  id: Scalars['Float'];
  isComplete: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface SkillReflectionAnswers {
  id: Scalars['Float'];
  questions: Array<SkillReflectionQuestion>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface SkillReflectionQuestion {
  answer: Scalars['String'];
  question: Scalars['String'];
  questionKey: Scalars['String'];
}

export const SortDirection = {
  Ascending: 'Ascending',
  Descending: 'Descending',
} as const;

export type SortDirection = (typeof SortDirection)[keyof typeof SortDirection];
export interface SpecialConditions {
  bipolarSchizophrenia: Scalars['Boolean'];
  suicidal: Scalars['Boolean'];
}

export const SponsoredSessionsLimitType = {
  ShortTermCapped: 'shortTermCapped',
  ShortTermUncapped: 'shortTermUncapped',
  StudentAssistanceProgram: 'studentAssistanceProgram',
  Unrestricted: 'unrestricted',
} as const;

export type SponsoredSessionsLimitType =
  (typeof SponsoredSessionsLimitType)[keyof typeof SponsoredSessionsLimitType];
export type StudentHubReferralResult = ReferralError | StudentHubReferralSuccess;

export interface StudentHubReferralSuccess {
  successMessage: Scalars['String'];
}

export interface Subscription {
  coachChatUpdates: CoachChatUpdate;
  portalUpdates: Array<PortalKey>;
}

export type SubscriptionCoachChatUpdatesArgs = {
  coachingProgramId?: InputMaybe<Scalars['Float']>;
};

/** The different kinds of suicidal thoughts */
export const SuicidalThoughts = {
  DuringMyLifetime: 'DURING_MY_LIFETIME',
  Never: 'NEVER',
  WithinThePastMonth: 'WITHIN_THE_PAST_MONTH',
  WithinThePastYear: 'WITHIN_THE_PAST_YEAR',
} as const;

export type SuicidalThoughts = (typeof SuicidalThoughts)[keyof typeof SuicidalThoughts];
export interface Task {
  blueprintId: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  done: Scalars['Boolean'];
  id: Scalars['Int'];
  ref: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Float'];
}

export const TaskStatusFilter = {
  Cancelled: 'Cancelled',
  Discharged: 'Discharged',
  Intake: 'Intake',
  OnHold: 'OnHold',
  OngoingCare: 'OngoingCare',
  ScreenedOut: 'ScreenedOut',
} as const;

export type TaskStatusFilter = (typeof TaskStatusFilter)[keyof typeof TaskStatusFilter];
export interface TestVideoSession {
  videoSessionId: Scalars['String'];
  videoToken: Scalars['String'];
}

export interface TextCopy {
  showInView?: Maybe<Scalars['Boolean']>;
  style?: Maybe<TextStyle>;
  text: Scalars['String'];
}

export const TextKind = {
  Danger: 'danger',
} as const;

export type TextKind = (typeof TextKind)[keyof typeof TextKind];
export interface TextStyle {
  italic?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<TextKind>;
  tag?: Maybe<TextTag>;
}

export const TextTag = {
  Body: 'body',
  BodyBold: 'bodyBold',
  BodyGrey: 'bodyGrey',
  BodySmallBold: 'bodySmallBold',
  Bodysmall: 'bodysmall',
  Caption: 'caption',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  Label: 'label',
} as const;

export type TextTag = (typeof TextTag)[keyof typeof TextTag];
export interface Ticket {
  assignOptions: TicketAssignOption;
  assignee?: Maybe<Provider>;
  blockedReason?: Maybe<Scalars['String']>;
  canEdit: Scalars['Boolean'];
  canResolve: Scalars['Boolean'];
  category: TicketCategory;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  events: Array<Event>;
  id: Scalars['Int'];
  priority?: Maybe<TicketPriority>;
  requester?: Maybe<Provider>;
  resolveIn24Hrs?: Maybe<Scalars['Boolean']>;
  status: TicketStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
}

export const TicketAssignOption = {
  Any: 'Any',
  None: 'None',
  Self: 'Self',
} as const;

export type TicketAssignOption = (typeof TicketAssignOption)[keyof typeof TicketAssignOption];
export const TicketCategory = {
  CloseCareCancellation: 'closeCareCancellation',
  CloseCareDischarge: 'closeCareDischarge',
  Coc: 'coc',
  DatasharingCoc: 'datasharingCoc',
  DatasharingRoi: 'datasharingRoi',
  InformationalContinuityOfCare: 'informationalContinuityOfCare',
  InformationalGeneral: 'informationalGeneral',
  Insurance: 'insurance',
  Labs: 'labs',
  OnHold: 'onHold',
  Other: 'other',
  Outreach: 'outreach',
  OutreachGeneral: 'outreachGeneral',
  OutreachStudentDisengagement: 'outreachStudentDisengagement',
  OutreachStudentNoShow: 'outreachStudentNoShow',
  PriorAuth: 'priorAuth',
  ProviderDefaultScheduleChange: 'providerDefaultScheduleChange',
  ProviderGeneralTimeOff: 'providerGeneralTimeOff',
  ProviderScheduleAdjustment: 'providerScheduleAdjustment',
  ReferralAdhdEvaluation: 'referralADHDEvaluation',
  ReferralExternalPsychiatry: 'referralExternalPsychiatry',
  ReferralExternalTherapy: 'referralExternalTherapy',
  ReferralIop: 'referralIOP',
  ReferralInternalCoaching: 'referralInternalCoaching',
  ReferralInternalPsychiatry: 'referralInternalPsychiatry',
  ReferralInternalTherapy: 'referralInternalTherapy',
  ReferralOther: 'referralOther',
  ReferralPsychiatry: 'referralPsychiatry',
  ReferralTherapy: 'referralTherapy',
  ReleaseOfInformationAccommodationLetter: 'releaseOfInformationAccommodationLetter',
  ReleaseOfInformationStudent: 'releaseOfInformationStudent',
  ReleaseOfInformationSummaryOfTreatmentLetter: 'releaseOfInformationSummaryOfTreatmentLetter',
  ReleaseOfInformationThirdParty: 'releaseOfInformationThirdParty',
  RxFax: 'rxFax',
  RxPriorAuth: 'rxPriorAuth',
  RxQuestion: 'rxQuestion',
  RxRefill: 'rxRefill',
  RxSideEffect: 'rxSideEffect',
  Scheduling: 'scheduling',
  SchedulingProviderDefaultScheduleChangeInPolicy:
    'schedulingProviderDefaultScheduleChangeInPolicy',
  SchedulingProviderDefaultScheduleChangeOutOfPolicy:
    'schedulingProviderDefaultScheduleChangeOutOfPolicy',
  SchedulingProviderEmergencyTimeOff: 'schedulingProviderEmergencyTimeOff',
  SchedulingProviderGeneralTimeOffInPolicy: 'schedulingProviderGeneralTimeOffInPolicy',
  SchedulingProviderGeneralTimeOffOutOfPolicy: 'schedulingProviderGeneralTimeOffOutOfPolicy',
  SchedulingProviderScheduleAdjustmentInPolicy: 'schedulingProviderScheduleAdjustmentInPolicy',
  SchedulingProviderScheduleAdjustmentOutOfPolicy:
    'schedulingProviderScheduleAdjustmentOutOfPolicy',
  SchedulingStudentAppointment: 'schedulingStudentAppointment',
  SelfPayBilling: 'selfPayBilling',
  SelfPayPsychiatry: 'selfPayPsychiatry',
  SelfPayTherapy: 'selfPayTherapy',
  TechSupport: 'techSupport',
  TechSupportAudioVideoIssue: 'techSupportAudioVideoIssue',
  TechSupportDocumentationIssue: 'techSupportDocumentationIssue',
  TechSupportEmailPasswordReset: 'techSupportEmailPasswordReset',
  TechSupportGeneral: 'techSupportGeneral',
  TechSupportOzPasswordReset: 'techSupportOzPasswordReset',
  TechSupportSlackPasswordReset: 'techSupportSlackPasswordReset',
  TechSupportStudentAssistance: 'techSupportStudentAssistance',
  TransferOfCareCoaching: 'transferOfCareCoaching',
  TransferOfCarePsychiatry: 'transferOfCarePsychiatry',
  TransferOfCareTherapy: 'transferOfCareTherapy',
} as const;

export type TicketCategory = (typeof TicketCategory)[keyof typeof TicketCategory];
export const TicketPriority = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type TicketPriority = (typeof TicketPriority)[keyof typeof TicketPriority];
export const TicketStatus = {
  Active: 'Active',
  Blocked: 'Blocked',
  Complete: 'Complete',
  Deleted: 'Deleted',
} as const;

export type TicketStatus = (typeof TicketStatus)[keyof typeof TicketStatus];
export const TicketView = {
  Active: 'Active',
  AssignedToMe: 'AssignedToMe',
  Blocked: 'Blocked',
  New: 'New',
  Resolved: 'Resolved',
  Unresolved: 'Unresolved',
} as const;

export type TicketView = (typeof TicketView)[keyof typeof TicketView];
export const TimeAllocationPurpose = {
  Admin: 'Admin',
  Availability: 'Availability',
  TimeOff: 'TimeOff',
} as const;

export type TimeAllocationPurpose =
  (typeof TimeAllocationPurpose)[keyof typeof TimeAllocationPurpose];
export const TraitCategory = {
  Ethnicity: 'Ethnicity',
  Faith: 'Faith',
  Languages: 'Languages',
  Sexuality: 'Sexuality',
  Specialties: 'Specialties',
  TherapyTypes: 'TherapyTypes',
} as const;

export type TraitCategory = (typeof TraitCategory)[keyof typeof TraitCategory];
export interface TriageInfo {
  answers: Array<Answer>;
  assessmentId: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
}

/** Copy triggered on specific answers */
export interface TriggeredCopy {
  background?: Maybe<SectionColor>;
  border?: Maybe<SectionColor>;
  copy: Array<SectionCopy>;
  /** If depends on another key */
  dependsKey?: Maybe<Scalars['String']>;
  triggers: Array<Scalars['String']>;
}

export interface TwoFactorAuthResult {
  authToken?: Maybe<Scalars['String']>;
  codeValid: Scalars['Boolean'];
  deviceToken?: Maybe<Scalars['String']>;
}

export interface TwoFactorConfig {
  enabled: Scalars['Boolean'];
  id: Scalars['Float'];
  phone: Scalars['String'];
  phoneVerified: Scalars['Boolean'];
}

export interface UnfinishedSkillReflection {
  isComplete: Scalars['Boolean'];
  questions: Array<SkillReflectionQuestion>;
}

export const UniversityRelationship = {
  UniversityCollaborator: 'universityCollaborator',
  UniversityMonitor: 'universityMonitor',
} as const;

export type UniversityRelationship =
  (typeof UniversityRelationship)[keyof typeof UniversityRelationship];
export interface UnreadHubExternalMessage {
  mrn: Scalars['String'];
  totalCount: Scalars['Float'];
}

export interface UpdateCoupon {
  active: Scalars['Boolean'];
  autoApplyOnConversion: Scalars['Boolean'];
  careTypes: Array<CareType>;
  code: Scalars['String'];
  discountType: DiscountType;
  id?: InputMaybe<Scalars['Int']>;
  maxUses: Scalars['Float'];
  organizationId?: InputMaybe<Scalars['Float']>;
  percentDiscount?: InputMaybe<Scalars['Float']>;
  replacePriceCents?: InputMaybe<Scalars['Int']>;
}

export interface UpdateFileUpload {
  globallyShared: Scalars['Boolean'];
  name: Scalars['String'];
  type: UploadType;
  uploadId: Scalars['Float'];
}

export interface UpdateHubUserError {
  errorCode: UpdateHubUserErrorCode;
  message: Scalars['String'];
}

export const UpdateHubUserErrorCode = {
  DuplicatePhone: 'DuplicatePhone',
  InvalidEmail: 'InvalidEmail',
  InvalidPhone: 'InvalidPhone',
  InvalidState: 'InvalidState',
  InvalidZip: 'InvalidZip',
} as const;

export type UpdateHubUserErrorCode =
  (typeof UpdateHubUserErrorCode)[keyof typeof UpdateHubUserErrorCode];
export type UpdateHubUserResult = UpdateHubUserError | UpdateHubUserSuccess;

export interface UpdateHubUserSuccess {
  successMessage: Scalars['String'];
}

export interface UpdateOrganization {
  abbreviation?: InputMaybe<Scalars['String']>;
  departmentAddress?: InputMaybe<Scalars['String']>;
  departmentHours?: InputMaybe<Scalars['String']>;
  departmentName?: InputMaybe<Scalars['String']>;
  directorId?: InputMaybe<Scalars['Int']>;
  emailDomains?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}

export interface UpdateOrganizationEntitlementInput {
  config?: InputMaybe<Scalars['JSON']>;
  enabled: Scalars['Boolean'];
  key: Entitlement;
}

export interface UpdatePayerParams {
  billToProviderName: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Float']>;
  inNetworkStates: Array<Scalars['String']>;
  name: Scalars['String'];
  standardPayerId: Scalars['String'];
  stateAddressOverrides: Array<PayerStateAddressOverrideInput>;
  tradingPartnerId: Scalars['String'];
  useAddressForAllStates?: InputMaybe<Scalars['String']>;
  visible: Scalars['Boolean'];
}

export interface UpdateProvider {
  activityStatus?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  dosespotId?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  funFact?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  geoStates?: InputMaybe<Array<Scalars['String']>>;
  guaranteedPay?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  lastName?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  personalTitle?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postNominalTitles?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  providerType?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UpdateStaffRole>;
}

export interface UpdateProviderStateAllocationsModelInput {
  id: Scalars['Int'];
  maxHours: Scalars['Float'];
  maxIntakeHours: Scalars['Float'];
}

export interface UpdateSamlConfiguration {
  authnRequestBinding?: InputMaybe<Scalars['String']>;
  automaticallyUpdateEmail: Scalars['Boolean'];
  campusOrgMappings?: InputMaybe<Array<CampusOrgMappingInput>>;
  certificates: Array<Scalars['String']>;
  disableRequestedAuthnContext: Scalars['Boolean'];
  enableManualUniqueIdEdits: Scalars['Boolean'];
  entryPoint: Scalars['String'];
  issuer?: InputMaybe<Scalars['String']>;
  profileCampusField?: InputMaybe<Scalars['String']>;
  profileEmailField: Scalars['String'];
  profileMatchingField?: InputMaybe<Scalars['String']>;
  profileUniqueIdField: Scalars['String'];
}

export const UpdateStaffRole = {
  UniversityAdmin: 'universityAdmin',
  UniversityReferrer: 'universityReferrer',
  UniversityStaff: 'universityStaff',
  UniversitySupportingClinician: 'universitySupportingClinician',
} as const;

export type UpdateStaffRole = (typeof UpdateStaffRole)[keyof typeof UpdateStaffRole];
export interface UpdateUser2Result {
  id: Scalars['Int'];
}

export interface UpdateUserInput {
  authToken?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['String']>;
  cardToken?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationIdentification?: InputMaybe<Scalars['String']>;
  pharmacyId?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<AddressInput>;
  pronouns?: InputMaybe<Scalars['String']>;
  secondaryState?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface Upload {
  canEdit?: Maybe<Scalars['Boolean']>;
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdByProvider?: Maybe<Provider>;
  globallyShared?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  type: UploadType;
  url: Scalars['String'];
}

export const UploadType = {
  InsuranceDetails: 'InsuranceDetails',
  LabResults: 'LabResults',
  Other: 'Other',
  PatientResource: 'PatientResource',
  ReferralNote: 'ReferralNote',
} as const;

export type UploadType = (typeof UploadType)[keyof typeof UploadType];
export interface UpsertProviderInsuranceCredential {
  active: Scalars['Boolean'];
  payerId: Scalars['Float'];
  providerId: Scalars['Float'];
  state: Scalars['String'];
}

export interface UseOtherApp {
  name: Scalars['String'];
  roleDescription: Scalars['String'];
  url: Scalars['String'];
}

export interface User {
  adminAlerts: Array<AdminAlert>;
  appointmentTemplates: Array<AppointmentTemplate>;
  assessmentInfo: UserAssessmentInfo;
  authToken?: Maybe<Scalars['String']>;
  awaitingMinorConsent: Scalars['Boolean'];
  /** get first of each questionnaire taken by the user */
  baselineQuestionnaires: Array<QuestionnaireResult>;
  billingAddress?: Maybe<Address>;
  birthDate?: Maybe<Scalars['Date']>;
  campusTeam?: Maybe<Array<CampusTeamRelationship>>;
  careFlows: Array<UserCareFlow>;
  careStatus: CareStatus;
  careTeam: Array<Provider>;
  careTeamChannel: Channel;
  careTransitions: Array<ContinuityOfCareModel>;
  careTypes: Array<CareType>;
  cocEligibility: Array<ContinuityOfCareEligibilityValidation>;
  /** Care eligibility form responses for continuity of care */
  continuityOfCareEligibility: Array<ContinuityOfCareEligibilityModel>;
  continuityOfCareTransitions: Array<ContinuityOfCareModel>;
  counselingCenter?: Maybe<UserOrgCounselingCenter>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  dataSharingConsent?: Maybe<DataSharingConsent>;
  dataSharingRequest?: Maybe<DataSharingRequest>;
  editableFields: Array<EditableUserFields>;
  email: Scalars['String'];
  /** Returns email if the requester has permission to see it, and null otherwise. Helpful for user lists. */
  emailIfVisible?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  emergencyContacts: Array<Contact>;
  engagementTags: Array<EngagementTag>;
  firstName: Scalars['String'];
  flaggedForOutreachAt?: Maybe<Scalars['DateTime']>;
  hasADHD: Scalars['Boolean'];
  hasAnxiety: Scalars['Boolean'];
  hasDepression: Scalars['Boolean'];
  hasDosespot: Scalars['Boolean'];
  /** Get insurance eligibility without restricted check */
  hasEligibleInsurance: Scalars['Boolean'];
  hasOutstandingPrescription: Scalars['Boolean'];
  hasPaymentMethod: Scalars['Boolean'];
  hasProvider: Scalars['Boolean'];
  hasSafetyPlan: Scalars['Boolean'];
  hasSso?: Maybe<Scalars['Boolean']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  hasTherapist: Scalars['Boolean'];
  hubOnboardingStatus?: Maybe<HubStatusForUser>;
  id: Scalars['Int'];
  identificationCard?: Maybe<Upload>;
  identityVerified: Scalars['Boolean'];
  insuranceDetails?: Maybe<InsuranceDetails>;
  insuranceDetailsIfBookingForSelfPayPatient?: Maybe<InsuranceDetails>;
  insuranceDetailsIfVisible?: Maybe<InsuranceDetails>;
  intakeComplete: Scalars['Boolean'];
  /** makes stripe request */
  invoices: Array<Invoice>;
  isPaymentMethodValid: Scalars['Boolean'];
  isTherapyOnboarding: Scalars['Boolean'];
  lastAppointment?: Maybe<CalendarEvent>;
  lastName: Scalars['String'];
  /** Last manual outreach made for this user */
  lastOutreachAttempt?: Maybe<Scalars['DateTime']>;
  lastPrescriptionWrittenDate?: Maybe<Scalars['DateTime']>;
  latestMedicalHistoryResult?: Maybe<MedicalHistory>;
  /** get latest of each questionnaire taken by the user */
  latestQuestionnaires: Array<QuestionnaireResult>;
  mantraAdminCareTeam: Array<Provider>;
  needsScales: Scalars['Boolean'];
  nextAppointment?: Maybe<CalendarEvent>;
  nextAppointmentTime?: Maybe<Scalars['DateTime']>;
  nextScales?: Maybe<Scalars['DateTime']>;
  notes: Array<MedicalNote>;
  onHoldOrActiveCareTypes: Array<CareType>;
  organization?: Maybe<Organization>;
  organizationIdentification?: Maybe<Scalars['String']>;
  origin: UserOrigin;
  /** makes stripe request */
  paymentInfo?: Maybe<PaymentProfile>;
  pendingSponsoredCareReenrollment: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<Address>;
  /** state from primaryAddress without restricted check */
  primaryAddressState?: Maybe<Scalars['String']>;
  primaryEmergencyContact?: Maybe<Contact>;
  privateChannel: Channel;
  privateChannelWith?: Maybe<Channel>;
  privateChannels?: Maybe<Array<Channel>>;
  pronouns?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  providerForCareType?: Maybe<Provider>;
  providerIfVisible?: Maybe<Provider>;
  recordViewStatus: UserRecordViewStatus;
  referralComplete?: Maybe<Scalars['Boolean']>;
  referralSource: ReferralSource;
  referredByUniversityReferrer: Scalars['Boolean'];
  referringProvider?: Maybe<Provider>;
  safetyPlan?: Maybe<SafetyPlan>;
  samlUniqueId?: Maybe<Scalars['String']>;
  screenedOut?: Maybe<Scalars['Boolean']>;
  secondaryState?: Maybe<StateCodes>;
  selfie?: Maybe<Upload>;
  suggestedProviders: Array<Provider>;
  taskStatus: Scalars['String'];
  taskStatusIfVisible?: Maybe<Scalars['String']>;
  tasks?: Maybe<Array<Task>>;
  therapist?: Maybe<Provider>;
  therapistIfVisible?: Maybe<Provider>;
  timeZone?: Maybe<Scalars['String']>;
  triageInfo?: Maybe<Array<TriageInfo>>;
  uid: Scalars['String'];
  unansweredMessagesCount: Scalars['Int'];
  universityCareTeam: Array<Provider>;
  universityCareTeamPrimary?: Maybe<Provider>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  uploads: Array<Upload>;
  verificationStatus: Scalars['String'];
}

export type UserLastAppointmentArgs = {
  appointmentType?: InputMaybe<AppointmentType>;
  careType: CareType;
  status?: InputMaybe<Scalars['String']>;
};

export type UserNextAppointmentArgs = {
  appointmentType?: InputMaybe<AppointmentType>;
  careType?: InputMaybe<CareType>;
  status?: InputMaybe<Scalars['String']>;
};

export type UserPrivateChannelArgs = {
  providerId?: InputMaybe<Scalars['Float']>;
};

export type UserPrivateChannelWithArgs = {
  kind: Scalars['String'];
};

export type UserProviderForCareTypeArgs = {
  careType: CareType;
};

export interface UserAssessmentInfo {
  additionalDiagnoses?: Maybe<Array<Scalars['String']>>;
  additionalProviderContact?: Maybe<Scalars['String']>;
  additionalProviderName?: Maybe<Scalars['String']>;
  allergies?: Maybe<Array<Scalars['String']>>;
  diagnoses?: Maybe<Array<Scalars['String']>>;
  medication?: Maybe<Array<Prescription>>;
  otherMeds?: Maybe<Array<Scalars['String']>>;
  primaryDiagnosis?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  psychiatricHistory?: Maybe<Array<Scalars['String']>>;
  riskLevel?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  suicidal?: Maybe<Array<Scalars['String']>>;
  wellnessGoals?: Maybe<Array<Scalars['String']>>;
}

export interface UserCareFlow {
  /** Broad activity check without restricted permission */
  canBookAppointment: Scalars['Boolean'];
  careSessionInfo?: Maybe<CareSessionInfo>;
  careStatus: CareStatus;
  careType: CareType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  organizationCareFlow?: Maybe<OrganizationCareFlow>;
  paymentSource: PaymentSource;
  pendingTransition?: Maybe<ContinuityOfCareModel>;
  providerStatesAvailable: Array<Scalars['String']>;
  taskStatus: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface UserCareFlowInput {
  careStatus?: InputMaybe<CareStatus>;
  careType: CareType;
  paymentSource: PaymentSource;
  sessionLimitOverride?: InputMaybe<Scalars['Int']>;
}

export interface UserCoachingHealthieStatus {
  userCoachingHealthieStatus?: Maybe<CoachingStatus>;
}

export interface UserCoachingStatus {
  userCoachingStatus?: Maybe<CoachingStatus>;
}

export interface UserDemographics {
  academicProgram: Scalars['String'];
  createdAt: Scalars['DateTime'];
  ethnicity: Scalars['String'];
  genderIdentity: Scalars['String'];
  id: Scalars['Float'];
  isInternationalStudent: Scalars['Boolean'];
  sexualOrientation: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  yearInProgram: Scalars['String'];
}

export interface UserDiagnosis {
  category: Scalars['String'];
  code: Scalars['String'];
  key: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  userId: Scalars['Float'];
}

export interface UserHealthieStatus {
  userIsInitialized: Scalars['Boolean'];
}

export interface UserIsLoggedIn {
  authToken?: Maybe<Scalars['String']>;
  isLoggedIn: Scalars['Boolean'];
}

export interface UserListParams {
  careStatus?: InputMaybe<CareStatus>;
  careTeamMemberId?: InputMaybe<Scalars['Float']>;
  cursor?: InputMaybe<Scalars['String']>;
  dtc?: InputMaybe<Scalars['Boolean']>;
  excludeMyPatients?: InputMaybe<Scalars['Boolean']>;
  geoState?: InputMaybe<Scalars['String']>;
  includeAllPatients?: InputMaybe<Scalars['Boolean']>;
  includeNewPatients?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  nextSteps?: InputMaybe<NextStepsFilter>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
  taskStatus?: InputMaybe<TaskStatusFilter>;
}

export interface UserOrgCounselingCenter {
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
}

export const UserOrigin = {
  Dtc: 'Dtc',
  Student: 'Student',
} as const;

export type UserOrigin = (typeof UserOrigin)[keyof typeof UserOrigin];
export const UserRecordViewStatus = {
  Full: 'Full',
  Limited: 'Limited',
  Restricted: 'Restricted',
} as const;

export type UserRecordViewStatus = (typeof UserRecordViewStatus)[keyof typeof UserRecordViewStatus];
export interface UserSkillAssignments {
  pagination: AssignmentPagination;
  skillAssignments: Array<SkillAssignmentModel>;
}

export interface UserSkillReflections {
  pagination: ReflectionPagination;
  reflections: Array<SkillReflection>;
}

export interface UserSponsoredReactivation {
  careTypes: Array<CareType>;
  isSelfReactivationAllowed: Scalars['Boolean'];
  reactivationCopy?: Maybe<Scalars['String']>;
  userHasRemainingAppts: Scalars['Boolean'];
}

export interface UtilizationHours {
  hoursScheduled?: Maybe<Scalars['Float']>;
  hoursUtilized?: Maybe<Scalars['Float']>;
  intakesScheduled?: Maybe<Scalars['Float']>;
  intakesUtilized?: Maybe<Scalars['Float']>;
  maxHours?: Maybe<Scalars['Float']>;
  maxIntakeHours?: Maybe<Scalars['Int']>;
}

export interface UtilizationVirtualModel {
  carePeriod: OrganizationCarePeriodModel;
  weeklyUtilizationRecords: Array<OrganizationUtilizationWeek>;
}

export interface VisitAttendance {
  attendance: Array<NumericKeyValue>;
  carePeriod?: Maybe<OrganizationCarePeriodModel>;
}

export const AppView = {
  Mcp: 'mcp',
  Oz: 'oz',
  Referral: 'referral',
} as const;

export type AppView = (typeof AppView)[keyof typeof AppView];
export const AppointmentType = {
  Adhoc: 'adhoc',
  Checkin: 'checkin',
  Intake: 'intake',
} as const;

export type AppointmentType = (typeof AppointmentType)[keyof typeof AppointmentType];
export const CoachingAssessments = {
  Goals: 'Goals',
  GoalsSuccess: 'GoalsSuccess',
  Wellness: 'Wellness',
  WellnessSuccess: 'WellnessSuccess',
} as const;

export type CoachingAssessments = (typeof CoachingAssessments)[keyof typeof CoachingAssessments];
export const CoachingProgramType = {
  EmotionalWellness: 'EmotionalWellness',
  ExecutiveFunctioning: 'ExecutiveFunctioning',
} as const;

export type CoachingProgramType = (typeof CoachingProgramType)[keyof typeof CoachingProgramType];
export const CoachingStatus = {
  Active: 'active',
  Inactive: 'inactive',
  Onboarding: 'onboarding',
} as const;

export type CoachingStatus = (typeof CoachingStatus)[keyof typeof CoachingStatus];
export const EntitlementDefinitionConfigSize = {
  Large: 'large',
  Small: 'small',
} as const;

export type EntitlementDefinitionConfigSize =
  (typeof EntitlementDefinitionConfigSize)[keyof typeof EntitlementDefinitionConfigSize];
export const Permission = {
  AppointmentCancel: 'appointmentCancel',
  AppointmentCancelAll: 'appointmentCancelAll',
  AppointmentEdit: 'appointmentEdit',
  AppointmentView: 'appointmentView',
  AssessmentPush: 'assessmentPush',
  CampusTeamEdit: 'campusTeamEdit',
  CareDashboards: 'careDashboards',
  ContractCreate: 'contractCreate',
  ContractEdit: 'contractEdit',
  ContractView: 'contractView',
  CrisisTeamEdit: 'crisisTeamEdit',
  CrisisTeamEngage: 'crisisTeamEngage',
  DedicatedGroupModelAllocationEdit: 'dedicatedGroupModelAllocationEdit',
  Dev: 'dev',
  GeneratePassword: 'generatePassword',
  Impersonate: 'impersonate',
  MantraAdmin: 'mantraAdmin',
  NoteCreate: 'noteCreate',
  NoteCreateReferral: 'noteCreateReferral',
  NoteCreateText: 'noteCreateText',
  NoteExport: 'noteExport',
  OrderLabs: 'orderLabs',
  OrganizationResourcesEdit: 'organizationResourcesEdit',
  OrganizationViewAll: 'organizationViewAll',
  PatientCreate: 'patientCreate',
  PatientEdit: 'patientEdit',
  PatientEditCareFlows: 'patientEditCareFlows',
  PatientEditProviders: 'patientEditProviders',
  PatientList: 'patientList',
  PatientMessage: 'patientMessage',
  PatientVideo: 'patientVideo',
  Phi: 'phi',
  Pii: 'pii',
  Prescribe: 'prescribe',
  ProviderAvailabilityEdit: 'providerAvailabilityEdit',
  ProviderCreate: 'providerCreate',
  ProviderDelete: 'providerDelete',
  ProviderEdit: 'providerEdit',
  ProviderList: 'providerList',
  ProviderMessage: 'providerMessage',
  ProviderMessageViewAll: 'providerMessageViewAll',
  ProviderPasswordReset: 'providerPasswordReset',
  ProviderView: 'providerView',
  ProviderViewEmail: 'providerViewEmail',
  ProviderViewPhone: 'providerViewPhone',
  ReferralsShowOrg: 'referralsShowOrg',
  ReferralsView: 'referralsView',
  Reporting: 'reporting',
  SafetyPlanActivate: 'safetyPlanActivate',
  SafetyPlanEdit: 'safetyPlanEdit',
  SponsoredCareReenrollment: 'sponsoredCareReenrollment',
  TicketCreate: 'ticketCreate',
  TicketView: 'ticketView',
  TicketViewAll: 'ticketViewAll',
  UtilizationView: 'utilizationView',
} as const;

export type Permission = (typeof Permission)[keyof typeof Permission];
export const ReactivationType = {
  Confirm: 'confirm',
  Reactivate: 'reactivate',
} as const;

export type ReactivationType = (typeof ReactivationType)[keyof typeof ReactivationType];
export const RecommendationOptions = {
  AssessmentVisit: 'assessmentVisit',
  Coaching: 'coaching',
  CustomClinicalRec: 'customClinicalRec',
  Therapy: 'therapy',
} as const;

export type RecommendationOptions =
  (typeof RecommendationOptions)[keyof typeof RecommendationOptions];
export const Role = {
  Admin: 'admin',
  Patient: 'patient',
  ProductAdmin: 'productAdmin',
  Provider: 'provider',
  Supreme: 'supreme',
  UniversityAdmin: 'universityAdmin',
  UniversityReferrer: 'universityReferrer',
  UniversityStaff: 'universityStaff',
  UniversitySupportingClinician: 'universitySupportingClinician',
} as const;

export type Role = (typeof Role)[keyof typeof Role];
export const StateCodes = {
  Ak: 'AK',
  Al: 'AL',
  Ar: 'AR',
  As: 'AS',
  Az: 'AZ',
  Ca: 'CA',
  Co: 'CO',
  Ct: 'CT',
  Dc: 'DC',
  De: 'DE',
  Fl: 'FL',
  Fm: 'FM',
  Ga: 'GA',
  Gu: 'GU',
  Hi: 'HI',
  Ia: 'IA',
  Id: 'ID',
  Il: 'IL',
  In: 'IN',
  Ks: 'KS',
  Ky: 'KY',
  La: 'LA',
  Ma: 'MA',
  Md: 'MD',
  Me: 'ME',
  Mh: 'MH',
  Mi: 'MI',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Ms: 'MS',
  Mt: 'MT',
  Nc: 'NC',
  Nd: 'ND',
  Ne: 'NE',
  Nh: 'NH',
  Nj: 'NJ',
  Nm: 'NM',
  Nv: 'NV',
  Ny: 'NY',
  Oh: 'OH',
  Ok: 'OK',
  Or: 'OR',
  Pa: 'PA',
  Pr: 'PR',
  Pw: 'PW',
  Ri: 'RI',
  Sc: 'SC',
  Sd: 'SD',
  Tn: 'TN',
  Tx: 'TX',
  Ut: 'UT',
  Va: 'VA',
  Vi: 'VI',
  Vt: 'VT',
  Wa: 'WA',
  Wi: 'WI',
  Wv: 'WV',
  Wy: 'WY',
} as const;

export type StateCodes = (typeof StateCodes)[keyof typeof StateCodes];
export const UserNotificationKey = {
  BipolarScreening: 'bipolarScreening',
  CompleteSafetyPlan: 'completeSafetyPlan',
  DataSharingRequest: 'dataSharingRequest',
  InvalidEmergencyContact: 'invalidEmergencyContact',
  InvalidPaymentMethod: 'invalidPaymentMethod',
  MonthlyScales: 'monthlyScales',
  PendingCareTransition: 'pendingCareTransition',
  PrescriptionSubmitted: 'prescriptionSubmitted',
  ProviderFeedback: 'providerFeedback',
  ReactivateSponsoredPsychiatry: 'reactivateSponsoredPsychiatry',
  ReactivateSponsoredTherapy: 'reactivateSponsoredTherapy',
  ScheduleAppointmentPsychiatry: 'scheduleAppointmentPsychiatry',
  ScheduleAppointmentTherapy: 'scheduleAppointmentTherapy',
  UnreadMessages: 'unreadMessages',
} as const;

export type UserNotificationKey = (typeof UserNotificationKey)[keyof typeof UserNotificationKey];
export type MarkHealthieAppointmentForCancellationMutationVariables = Exact<{
  appointmentId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
}>;

export type MarkHealthieAppointmentForCancellationMutation = {
  markHealthieAppointmentForCancellation: boolean;
};

export type CancelHealthieAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['String'];
}>;

export type CancelHealthieAppointmentMutation = { cancelHealthieAppointment: boolean };

export type NextHubExternalAppointmentQueryVariables = Exact<{ [key: string]: never }>;

export type NextHubExternalAppointmentQuery = {
  nextHubExternalAppointment?: { id: number; end: string; start: string; mrn: string } | null;
};

export type UpcomingHubAppointmentQueryVariables = Exact<{
  delay?: InputMaybe<Scalars['Float']>;
}>;

export type UpcomingHubAppointmentQuery = {
  upcomingHubAppointment?: {
    id: string;
    appointmentTypeId: string;
    appointmentTypeName: string;
    length: number;
    start: string;
    zoomJoinUrl: string;
    rescheduleLink?: string | null;
  } | null;
};

export type HubAppointmentByIdQueryVariables = Exact<{
  appointmentId: Scalars['String'];
}>;

export type HubAppointmentByIdQuery = {
  hubAppointmentById: {
    id: string;
    appointmentTypeId: string;
    appointmentTypeName: string;
    length: number;
    start: string;
    zoomJoinUrl: string;
    rescheduleLink?: string | null;
  };
};

export type GetHubAssessmentLinkQueryVariables = Exact<{ [key: string]: never }>;

export type GetHubAssessmentLinkQuery = { hubGetAssessmentLink: string };

export type AuthInstructionsForEmailQueryVariables = Exact<{
  email: Scalars['String'];
  app: SamlUserApp;
  mrn?: InputMaybe<Scalars['String']>;
}>;

export type AuthInstructionsForEmailQuery = {
  authInstructionsForEmail: {
    authMethod: AuthMethod;
    samlEntryPoint?: string | null;
    organizationAbbreviation?: string | null;
    hasPreviousSSOLogin?: boolean | null;
  };
};

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never }>;

export type IsLoggedInQuery = { isLoggedInHub: boolean };

export type NavigateFromHubMobileToWebQueryVariables = Exact<{
  source?: InputMaybe<Scalars['String']>;
}>;

export type NavigateFromHubMobileToWebQuery = { navigateFromHubMobileToWeb: string };

export type NavigateFromHubToPatientPortalQueryVariables = Exact<{
  mrn?: InputMaybe<Scalars['String']>;
}>;

export type NavigateFromHubToPatientPortalQuery = {
  navigateFromHubToPatientPortal2:
    | {
        __typename: 'NavigateFromHubToPatientPortalError';
        errorCode: NavigateFromHubToPatientPortalErrorCode;
      }
    | { __typename: 'NavigateFromHubToPatientPortalSuccess'; loginUrl: string };
};

export type LoginToHubMutationVariables = Exact<{
  tokenId: Scalars['String'];
  token: Scalars['String'];
}>;

export type LoginToHubMutation = {
  loginToHub:
    | { __typename: 'LoginToHubError'; errorCode: LoginToHubErrorCode }
    | {
        __typename: 'LoginToHubSuccess';
        userId: number;
        jwt: string;
        hasHubOnboarded?: boolean | null;
      };
};

export type LogoutHubMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutHubMutation = { logoutHub: boolean };

export type PreLoginOrPasswordLoginMutationVariables = Exact<{
  app: SamlUserApp;
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  mrn?: InputMaybe<Scalars['String']>;
}>;

export type PreLoginOrPasswordLoginMutation = {
  preLoginOrPasswordLogin:
    | {
        __typename: 'PreLoginOrPasswordLoginError';
        errorCode: PreLoginOrPasswordLoginErrorCode;
        message: string;
      }
    | { __typename: 'PreLoginOrPasswordLoginPassword'; passwordRequired: boolean }
    | { __typename: 'PreLoginOrPasswordLoginRedirect'; externalRedirect: string }
    | { __typename: 'PreLoginOrPasswordLoginSaml'; samlEntryPoint: string }
    | {
        __typename: 'PreLoginOrPasswordLoginSuccess';
        loginSuccess: boolean;
        hasHubOnboarded?: boolean | null;
        jwt?: string | null;
      };
};

export type PreSignUpMutationVariables = Exact<{
  app: SamlUserApp;
  email: Scalars['String'];
}>;

export type PreSignUpMutation = {
  preSignUp:
    | { errorCode: PreSignUpErrorCode; typename: 'PreSignUpError' }
    | { samlEntryPoint: string; typename: 'PreSignUpSsoRedirect' }
    | { typename: 'PreSignUpSuccess' };
};

export type ResendActivationEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResendActivationEmailMutation = {
  resendActivationEmail:
    | { __typename: 'HubResendActivationError'; errorCode: HubResendActivationErrorCode }
    | { __typename: 'HubResendActivationSuccess'; success: boolean };
};

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SendPasswordResetEmailMutation = { sendPasswordResetEmail: boolean };

export type SetPasswordWithTokenMutationVariables = Exact<{
  tokenId: Scalars['Float'];
  token: Scalars['String'];
  password: Scalars['String'];
}>;

export type SetPasswordWithTokenMutation = {
  setPasswordWithToken:
    | {
        __typename: 'SetPasswordWithTokenError';
        errorCode: SetPasswordWithTokenErrorCode;
        message: string;
      }
    | { __typename: 'SetPasswordWithTokenSuccess' };
};

export type SignUpMutationVariables = Exact<{
  tokenId: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
}>;

export type SignUpMutation = {
  signUp:
    | { errorCode: SignUpErrorCode; message: string; typename: 'SignUpError' }
    | { typename: 'SignUpSuccess' };
};

export type ResetBadgeCountMutationVariables = Exact<{
  counterName: Scalars['String'];
}>;

export type ResetBadgeCountMutation = { resetBadgeCount: boolean };

export type HubOrganizationResourcesQueryVariables = Exact<{ [key: string]: never }>;

export type HubOrganizationResourcesQuery = {
  hubOrganizationResources: Array<{
    id: number;
    name: string;
    description?: string | null;
    showAsCrisisResource: boolean;
    contact?: string | null;
    location?: string | null;
    phone?: string | null;
    email?: string | null;
    hours?: string | null;
    website?: string | null;
  }>;
};

export type GetCoachChatMessagesQueryVariables = Exact<{
  offset: Scalars['Float'];
}>;

export type GetCoachChatMessagesQuery = {
  getCoachChatMessages: Array<{
    id: string;
    content: string;
    createdAt: string;
    author: CoachChatMessageAuthor;
  }>;
};

export type CoachChatUpdatesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CoachChatUpdatesSubscription = {
  coachChatUpdates: {
    messageId: string;
    action: string;
    message?: {
      id: string;
      content: string;
      createdAt: string;
      author: CoachChatMessageAuthor;
    } | null;
  };
};

export type SendCoachChatMessageMutationVariables = Exact<{
  content: Scalars['String'];
}>;

export type SendCoachChatMessageMutation = { sendCoachChatMessage: { id?: string | null } };

export type CoachOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type CoachOverviewQuery = {
  coachOverview: {
    coachAvatarUrl?: string | null;
    coachName: string;
    hasUnreadMessages: boolean;
    coachingStartDate?: string | null;
    coachingEndDate?: string | null;
  };
};

export type CoachingIntakeProgressV2QueryVariables = Exact<{ [key: string]: never }>;

export type CoachingIntakeProgressV2Query = {
  coachingIntakeProgressV2: {
    coachingAssessments: Array<{ key: CoachingAssessments; completed: boolean }>;
  };
};

export type UserCoachingStatusQueryVariables = Exact<{ [key: string]: never }>;

export type UserCoachingStatusQuery = {
  userCoachingStatus: { userCoachingStatus?: CoachingStatus | null };
};

export type CoachingGoalsAssessmentQueryVariables = Exact<{ [key: string]: never }>;

export type CoachingGoalsAssessmentQuery = {
  coachingGoalsAssessment: {
    key: AssessmentKey;
    questionnaires: Array<{
      title: string;
      key: string;
      description?: string | null;
      questions: Array<{
        isOptional?: boolean | null;
        key: string;
        label?: string | null;
        placeholder?: string | null;
        preText?: string | null;
        subText?: string | null;
        text?: string | null;
        type?: string | null;
        options?: Array<{
          key?: string | null;
          requiresManualInput?: boolean | null;
          text: string;
        }> | null;
        manualInput?: { label?: string | null; placeholder?: string | null } | null;
      }>;
    }>;
  };
};

export type CoachingWellnessAssessmentQueryVariables = Exact<{ [key: string]: never }>;

export type CoachingWellnessAssessmentQuery = {
  coachingWellnessAssessment: {
    key: AssessmentKey;
    questionnaires: Array<{
      title: string;
      key: string;
      description?: string | null;
      questions: Array<{
        isOptional?: boolean | null;
        key: string;
        label?: string | null;
        placeholder?: string | null;
        preText?: string | null;
        subText?: string | null;
        text?: string | null;
        type?: string | null;
        options?: Array<{
          key?: string | null;
          requiresManualInput?: boolean | null;
          text: string;
        }> | null;
      }>;
    }>;
  };
};

export type CoachingExecutiveFunctioningGoalsAssessmentQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CoachingExecutiveFunctioningGoalsAssessmentQuery = {
  coachingExecutiveFunctioningGoalsAssessment: {
    key: AssessmentKey;
    questionnaires: Array<{
      title: string;
      key: string;
      description?: string | null;
      questions: Array<{
        isOptional?: boolean | null;
        key: string;
        label?: string | null;
        placeholder?: string | null;
        preText?: string | null;
        subText?: string | null;
        text?: string | null;
        type?: string | null;
        options?: Array<{
          key?: string | null;
          requiresManualInput?: boolean | null;
          text: string;
        }> | null;
        manualInput?: { label?: string | null; placeholder?: string | null } | null;
      }>;
    }>;
  };
};

export type CoachingExecutiveFunctioningWellnessAssessmentQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CoachingExecutiveFunctioningWellnessAssessmentQuery = {
  coachingExecutiveFunctioningWellnessAssessment: {
    key: AssessmentKey;
    questionnaires: Array<{
      title: string;
      key: string;
      description?: string | null;
      questions: Array<{
        isOptional?: boolean | null;
        key: string;
        label?: string | null;
        placeholder?: string | null;
        preText?: string | null;
        subText?: string | null;
        text?: string | null;
        type?: string | null;
        options?: Array<{
          key?: string | null;
          requiresManualInput?: boolean | null;
          text: string;
        }> | null;
      }>;
    }>;
  };
};

export type CoachingKickoffLinkQueryVariables = Exact<{ [key: string]: never }>;

export type CoachingKickoffLinkQuery = { coachingKickoffLink: string };

export type ConsumeCoachingRecommendationMutationVariables = Exact<{
  coachingProgramType: CoachingProgramType;
}>;

export type ConsumeCoachingRecommendationMutation = { consumeCoachingRecommendation: boolean };

export type SubmitCoachingAssessmentV2MutationVariables = Exact<{
  assessmentType: CoachingAssessments;
  submissions: Array<HubAssessmentSubmission> | HubAssessmentSubmission;
}>;

export type SubmitCoachingAssessmentV2Mutation = { submitCoachingAssessmentV2: boolean };

export type OrganizationConnectNowQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationConnectNowQuery = {
  organizationConnectNow?: {
    connectNowEnabled: boolean;
    connectNowData?: {
      crisisCallPhoneNumber: string;
      crisisSMS?: string | null;
      crisisWebChat?: string | null;
    } | null;
  } | null;
};

export type IsConnectNowServiceAvailableQueryVariables = Exact<{ [key: string]: never }>;

export type IsConnectNowServiceAvailableQuery = { isConnectNowServiceAvailable: boolean };

export type CreateConnectNowPreEncounterMutationVariables = Exact<{
  data: ConnectNowPreEncounterInput;
}>;

export type CreateConnectNowPreEncounterMutation = { createConnectNowPreEncounter: boolean };

export type OrganizationCustomHubModuleQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationCustomHubModuleQuery = {
  organizationCustomHubModule: {
    isEnabled: boolean;
    data?: {
      title?: string | null;
      description?: string | null;
      link?: string | null;
      url?: string | null;
    } | null;
  };
};

export type FindCareUserQueryVariables = Exact<{ [key: string]: never }>;

export type FindCareUserQuery = {
  onboardedHubUser: {
    preferredName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    birthDate?: string | null;
    pronouns?: string | null;
    primaryAddress?: {
      line1: string;
      line2?: string | null;
      city: string;
      state?: StateCodes | null;
      zip: string;
    } | null;
  };
};

export type FindCareStartQueryVariables = Exact<{
  questionnaireKey: Scalars['String'];
}>;

export type FindCareStartQuery = {
  findCareStart: {
    onDemandEnabled: boolean;
    connectNowEnabled: boolean;
    questionnaire: {
      description?: string | null;
      title: string;
      key: string;
      questions: Array<{
        key: string;
        text?: string | null;
        subText?: string | null;
        overrideDescription?: string | null;
        type?: string | null;
        visible?: boolean | null;
        options?: Array<{
          key?: string | null;
          text: string;
          requiresManualInput?: boolean | null;
        }> | null;
      }>;
    };
  };
};

export type FindCareUserHasCompletedQueryVariables = Exact<{ [key: string]: never }>;

export type FindCareUserHasCompletedQuery = { findCareUserHasCompleted: boolean };

export type GetCareRecommendationStatusForUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCareRecommendationStatusForUserQuery = {
  getCareRecommendationStatusForUser: {
    activeRecommendation: boolean;
    expiration?: string | null;
    isActiveOrOnboardingInCare: boolean;
    isActiveOrOnboardingInTherapy: boolean;
  };
};

export type UserDemographicsQueryVariables = Exact<{ [key: string]: never }>;

export type UserDemographicsQuery = {
  userDemographics?: {
    genderIdentity: string;
    ethnicity: string;
    isInternationalStudent: boolean;
    sexualOrientation: string;
    academicProgram: string;
    yearInProgram: string;
  } | null;
};

export type SaveUserDemographicsMutationVariables = Exact<{
  input: CreateUserDemographicsInput;
}>;

export type SaveUserDemographicsMutation = { saveUserDemographics: boolean };

export type FindCareFinishMutationVariables = Exact<{
  submissions: Array<HubAssessmentSubmission> | HubAssessmentSubmission;
}>;

export type FindCareFinishMutation = { findCareFinish: boolean };

export type ConsumeTherapyRecommendationMutationVariables = Exact<{ [key: string]: never }>;

export type ConsumeTherapyRecommendationMutation = { consumeTherapyRecommendation: boolean };

export type CreateHubFeedbackMutationVariables = Exact<{
  feedback: Scalars['String'];
  url: Scalars['String'];
}>;

export type CreateHubFeedbackMutation = { createHubFeedback: boolean };

export type UnreadHubExternalMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type UnreadHubExternalMessagesQuery = {
  unreadHubExternalMessages?: Array<{ totalCount: number; mrn: string }> | null;
};

export type OrganizationOnDemandQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationOnDemandQuery = {
  organizationOnDemand?: {
    onDemandEnabled: boolean;
    onDemandData?: {
      crisisCallPhoneNumber: string;
      crisisSMS?: string | null;
      crisisWebChat?: string | null;
    } | null;
  } | null;
};

export type IsOnDemandServiceAvailableQueryVariables = Exact<{ [key: string]: never }>;

export type IsOnDemandServiceAvailableQuery = { isOnDemandServiceAvailable: boolean };

export type CreateOnDemandEncounterMutationVariables = Exact<{
  data: OnDemandEncounterInput;
}>;

export type CreateOnDemandEncounterMutation = { createOnDemandEncounter: boolean };

export type GetPortalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPortalsQuery = { getPortals: Array<PortalKey> };

export type PortalUpdatesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type PortalUpdatesSubscription = { portalUpdates: Array<PortalKey> };

export type RegisterDeviceMutationVariables = Exact<{
  firebaseToken: Scalars['String'];
  platform: Scalars['String'];
  applicationName: Scalars['String'];
}>;

export type RegisterDeviceMutation = { registerDevice: boolean };

export type DisableDeviceMutationVariables = Exact<{
  firebaseToken: Scalars['String'];
}>;

export type DisableDeviceMutation = { disableDevice: boolean };

export type TrackPushOpenMutationVariables = Exact<{
  messageId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Float']>;
}>;

export type TrackPushOpenMutation = { trackPushOpen: boolean };

export type CareRecommendationGetForUserQueryVariables = Exact<{ [key: string]: never }>;

export type CareRecommendationGetForUserQuery = {
  careRecommendationGetForUser: {
    careRecommendation?: RecommendationOptions | null;
    onDemandEnabled: boolean;
    connectNowEnabled: boolean;
    togetherAllEnabled: boolean;
    isUserDeactivated?: boolean | null;
  };
};

export type CustomClinicalRecConfigGetForUserQueryVariables = Exact<{ [key: string]: never }>;

export type CustomClinicalRecConfigGetForUserQuery = {
  customClinicalRecConfigGetForUser: {
    customClinicalRecTitle: string;
    customClinicalRecDescription: string;
    customClinicalRecButtonText?: string | null;
    customClinicalRecButtonLink?: string | null;
  };
};

export type StudentHubReferralMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type StudentHubReferralMutation = {
  studentHubReferral:
    | { __typename: 'ReferralError'; errorCode: ReferralErrorCode }
    | { __typename: 'StudentHubReferralSuccess'; successMessage: string };
};

export type GetSkillAssignmentCountsForUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetSkillAssignmentCountsForUserQuery = {
  getSkillAssignmentCountsForUser: { notSeen: number; notCompleted: number };
};

export type GetMySkillsAssignmentsQueryVariables = Exact<{
  page: Scalars['Float'];
  perPage: Scalars['Float'];
}>;

export type GetMySkillsAssignmentsQuery = {
  getMySkillsAssignments: {
    pagination: { page: number; totalPages: number };
    skillAssignments: Array<{
      skillId: string;
      userHasSeen: boolean;
      assignerHasSeen: boolean;
      startedAt?: string | null;
      completedAt?: string | null;
      assignedById: number;
      moduleId: string;
    }>;
  };
};

export type IsSkillCompleteQueryVariables = Exact<{
  skillId: Scalars['String'];
  moduleId: Scalars['String'];
}>;

export type IsSkillCompleteQuery = { isSkillComplete: boolean };

export type GetAllCompletedSkillsForModuleQueryVariables = Exact<{
  moduleId: Scalars['String'];
}>;

export type GetAllCompletedSkillsForModuleQuery = { getAllCompletedSkillsForModule: Array<string> };

export type GetUpNextSkillQueryVariables = Exact<{
  skillId: Scalars['String'];
  moduleId: Scalars['String'];
  isPathway?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetUpNextSkillQuery = {
  getUpNextSkill: {
    moduleWillBeComplete: boolean;
    upNextSkill?: { [key: string]: any } | null;
    upNextSkillIsComplete: boolean;
  };
};

export type GetUpNextSkillForModuleQueryVariables = Exact<{
  moduleId: Scalars['String'];
}>;

export type GetUpNextSkillForModuleQuery = {
  getUpNextSkillForModule: {
    moduleIsComplete: boolean;
    upNextSkill?: { [key: string]: any } | null;
  };
};

export type HasCompletedAnySkillQueryVariables = Exact<{ [key: string]: never }>;

export type HasCompletedAnySkillQuery = { hasCompletedAnySkill: boolean };

export type GetJumpBackInSkillsQueryVariables = Exact<{ [key: string]: never }>;

export type GetJumpBackInSkillsQuery = {
  getJumpBackInSkills: Array<{
    skill?: { [key: string]: any } | null;
    module?: { [key: string]: any } | null;
  }>;
};

export type MarkSkillCompleteMutationVariables = Exact<{
  skillId: Scalars['String'];
  moduleId: Scalars['String'];
}>;

export type MarkSkillCompleteMutation = { markSkillComplete: boolean };

export type MarkSkillStartedMutationVariables = Exact<{
  skillId: Scalars['String'];
  moduleId: Scalars['String'];
}>;

export type MarkSkillStartedMutation = { markSkillStarted: boolean };

export type UpsertUserSkillFeedbackMutationVariables = Exact<{
  skillId: Scalars['String'];
  rating: Scalars['Float'];
  additionalFeedback: Scalars['String'];
  questions: Array<SkillFeedbackQuestionModelInput> | SkillFeedbackQuestionModelInput;
}>;

export type UpsertUserSkillFeedbackMutation = { upsertUserSkillFeedback: boolean };

export type GetUserSkillFeedbackQueryVariables = Exact<{
  skillId: Scalars['String'];
}>;

export type GetUserSkillFeedbackQuery = {
  userSkillFeedback?: { rating: number; additionalFeedback?: string | null } | null;
};

export type SkillPathwayGetForUserQueryVariables = Exact<{ [key: string]: never }>;

export type SkillPathwayGetForUserQuery = {
  skillPathwayGetForUser?: Array<{
    moduleId: string;
    sectionId: string;
    score: number;
    nudges: Array<{ name: string }>;
  }> | null;
};

export type SkillPathwayNextSkillQueryVariables = Exact<{ [key: string]: never }>;

export type SkillPathwayNextSkillQuery = {
  skillPathwayNextSkill: {
    hasPathway: boolean;
    nextSkillId?: string | null;
    moduleId?: string | null;
    sectionId?: string | null;
  };
};

export type SkillPathwaySubmitMutationVariables = Exact<{
  answers: Array<SkillPathwayAnswer> | SkillPathwayAnswer;
}>;

export type SkillPathwaySubmitMutation = { skillPathwaySubmit: boolean };

export type LoadUnfinishedSkillReflectionQueryVariables = Exact<{
  skillId: Scalars['String'];
}>;

export type LoadUnfinishedSkillReflectionQuery = {
  loadUnfinishedSkillReflection?: {
    questions: Array<{ questionKey: string; answer: string }>;
  } | null;
};

export type SkillReflectionGetAllForUserQueryVariables = Exact<{
  page: Scalars['Float'];
  perPage: Scalars['Float'];
}>;

export type SkillReflectionGetAllForUserQuery = {
  skillReflectionGetAllForUser: {
    pagination: { totalPages: number };
    reflections: Array<{ id: number; title: string; updatedAt: string }>;
  };
};

export type SkillReflectionGetUserAnswersQueryVariables = Exact<{
  reflectionId: Scalars['Float'];
}>;

export type SkillReflectionGetUserAnswersQuery = {
  skillReflectionGetUserAnswers?: {
    id: number;
    title: string;
    updatedAt: string;
    questions: Array<{ questionKey: string; question: string; answer: string }>;
  } | null;
};

export type AnswerSkillReflectionQuestionMutationVariables = Exact<{
  skillId: Scalars['String'];
  questionKey: Scalars['String'];
  question: Scalars['String'];
  answer: Scalars['String'];
}>;

export type AnswerSkillReflectionQuestionMutation = { answerSkillReflectionQuestion: boolean };

export type SkillReflectionDeleteMutationVariables = Exact<{
  reflectionId: Scalars['Float'];
}>;

export type SkillReflectionDeleteMutation = { skillReflectionDelete: boolean };

export type SkillReflectionEditAnswerMutationVariables = Exact<{
  reflectionId: Scalars['Float'];
  questionKey: Scalars['String'];
  answer: Scalars['String'];
}>;

export type SkillReflectionEditAnswerMutation = { skillReflectionEditAnswer: boolean };

export type FinishSkillReflectionMutationVariables = Exact<{
  skillId: Scalars['String'];
  discardReflection: Scalars['Boolean'];
}>;

export type FinishSkillReflectionMutation = {
  finishSkillReflection: { deleted?: boolean | null; saved?: boolean | null };
};

export type CurrentUserNameQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserNameQuery = { onboardedHubUser: { preferredName?: string | null } };

export type CurrentUserImpersonatedQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserImpersonatedQuery = {
  onboardedHubUser: { impersonatingId?: string | null };
};

export type OnboardingUserDataQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingUserDataQuery = {
  onboardingHubUser: { preferredName?: string | null; birthDate?: string | null };
};

export type OnboardingUserPhoneQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingUserPhoneQuery = { onboardedHubUser: { phone?: string | null } };

export type OnboardingUnderageErrorQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingUnderageErrorQuery = {
  onboardingHubUser: {
    organizationMinimumHubAge: number;
    organizationCounselingCenter?: {
      departmentName: string;
      departmentAddress: string;
      departmentHours: string;
    } | null;
  };
};

export type HeapUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type HeapUserInfoQuery = {
  onboardingHubUser: { uid: string; organization?: { id: number } | null };
};

export type TherapyContextUserQueryVariables = Exact<{ [key: string]: never }>;

export type TherapyContextUserQuery = {
  onboardedHubUser: {
    canRedirect: boolean;
    organizationHasSelfReferrals: boolean;
    organizationCanAccessTherapy: boolean;
    organization?: { name: string } | null;
  };
};

export type OnboardedUserQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardedUserQuery = { onboardedHubUser: { hasHubOnboarded: boolean } };

export type HomePageUserQueryVariables = Exact<{ [key: string]: never }>;

export type HomePageUserQuery = {
  onboardedHubUser: {
    preferredName?: string | null;
    organizationCanAccessCampusResources: boolean;
    organizationCanAccessTogetherAll: boolean;
    organizationCanAccessCare: boolean;
    organizationHasSelfReferrals: boolean;
    organization?: { name: string } | null;
  };
};

export type ConsentRequiredUserQueryVariables = Exact<{ [key: string]: never }>;

export type ConsentRequiredUserQuery = {
  onboardingHubUser: { organization?: { name: string } | null };
};

export type OnDemandUserQueryVariables = Exact<{ [key: string]: never }>;

export type OnDemandUserQuery = {
  onboardedHubUser: {
    preferredName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    birthDate?: string | null;
    pronouns?: string | null;
    primaryAddress?: {
      line1: string;
      line2?: string | null;
      city: string;
      state?: StateCodes | null;
      zip: string;
    } | null;
  };
};

export type ConnectNowUserQueryVariables = Exact<{ [key: string]: never }>;

export type ConnectNowUserQuery = {
  currentHubUser: {
    preferredName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    birthDate?: string | null;
    pronouns?: string | null;
    primaryAddress?: {
      line1: string;
      line2?: string | null;
      city: string;
      state?: StateCodes | null;
      zip: string;
    } | null;
  };
};

export type OrganizationLogoQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationLogoQuery = {
  onboardedHubUser: { organizationLogo?: { contentType: string; url: string } | null };
};

export type CurrentUserOrganizationQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserOrganizationQuery = {
  onboardedHubUser: { organization?: { id: number } | null };
};

export type OrganizationHasCoachingQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationHasCoachingQuery = {
  onboardedHubUser: { organizationHasCoaching: boolean };
};

export type SetPreferredNameMutationVariables = Exact<{
  preferredName: Scalars['String'];
}>;

export type SetPreferredNameMutation = { setHubUserPreferredName: boolean };

export type HubUserOnboardMutationVariables = Exact<{
  birthDate: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
}>;

export type HubUserOnboardMutation = {
  hubUserOnboard:
    | { __typename: 'HubUserOnboardError'; errorCode: HubUserOnboardErrorCode; message: string }
    | { __typename: 'HubUserOnboardSuccess'; successMessage: string };
};

export type HubUserAcceptTermsMutationVariables = Exact<{ [key: string]: never }>;

export type HubUserAcceptTermsMutation = { hubUserAcceptTerms: boolean };

export type UpdateHubUser2MutationVariables = Exact<{
  updates: HubUserUpdateInput;
}>;

export type UpdateHubUser2Mutation = {
  updateHubUser:
    | { __typename: 'UpdateHubUserError'; errorCode: UpdateHubUserErrorCode; message: string }
    | { __typename: 'UpdateHubUserSuccess'; successMessage: string };
};

export const MarkHealthieAppointmentForCancellationDocument = gql`
  mutation markHealthieAppointmentForCancellation(
    $appointmentId: String!
    $appointmentTypeId: String!
  ) {
    markHealthieAppointmentForCancellation(
      appointmentId: $appointmentId
      appointmentTypeId: $appointmentTypeId
    )
  }
`;
export type MarkHealthieAppointmentForCancellationMutationFn = ApolloReactCommon.MutationFunction<
  MarkHealthieAppointmentForCancellationMutation,
  MarkHealthieAppointmentForCancellationMutationVariables
>;

/**
 * __useMarkHealthieAppointmentForCancellationMutation__
 *
 * To run a mutation, you first call `useMarkHealthieAppointmentForCancellationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkHealthieAppointmentForCancellationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markHealthieAppointmentForCancellationMutation, { data, loading, error }] = useMarkHealthieAppointmentForCancellationMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *   },
 * });
 */
export function useMarkHealthieAppointmentForCancellationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkHealthieAppointmentForCancellationMutation,
    MarkHealthieAppointmentForCancellationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkHealthieAppointmentForCancellationMutation,
    MarkHealthieAppointmentForCancellationMutationVariables
  >(MarkHealthieAppointmentForCancellationDocument, options);
}
export type MarkHealthieAppointmentForCancellationMutationHookResult = ReturnType<
  typeof useMarkHealthieAppointmentForCancellationMutation
>;
export type MarkHealthieAppointmentForCancellationMutationResult =
  ApolloReactCommon.MutationResult<MarkHealthieAppointmentForCancellationMutation>;
export type MarkHealthieAppointmentForCancellationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MarkHealthieAppointmentForCancellationMutation,
    MarkHealthieAppointmentForCancellationMutationVariables
  >;
export const CancelHealthieAppointmentDocument = gql`
  mutation cancelHealthieAppointment($appointmentId: String!) {
    cancelHealthieAppointment(appointmentId: $appointmentId)
  }
`;
export type CancelHealthieAppointmentMutationFn = ApolloReactCommon.MutationFunction<
  CancelHealthieAppointmentMutation,
  CancelHealthieAppointmentMutationVariables
>;

/**
 * __useCancelHealthieAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelHealthieAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelHealthieAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelHealthieAppointmentMutation, { data, loading, error }] = useCancelHealthieAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCancelHealthieAppointmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelHealthieAppointmentMutation,
    CancelHealthieAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CancelHealthieAppointmentMutation,
    CancelHealthieAppointmentMutationVariables
  >(CancelHealthieAppointmentDocument, options);
}
export type CancelHealthieAppointmentMutationHookResult = ReturnType<
  typeof useCancelHealthieAppointmentMutation
>;
export type CancelHealthieAppointmentMutationResult =
  ApolloReactCommon.MutationResult<CancelHealthieAppointmentMutation>;
export type CancelHealthieAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelHealthieAppointmentMutation,
  CancelHealthieAppointmentMutationVariables
>;
export const NextHubExternalAppointmentDocument = gql`
  query nextHubExternalAppointment {
    nextHubExternalAppointment {
      id
      end
      start
      mrn
    }
  }
`;

/**
 * __useNextHubExternalAppointmentQuery__
 *
 * To run a query within a React component, call `useNextHubExternalAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextHubExternalAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextHubExternalAppointmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useNextHubExternalAppointmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NextHubExternalAppointmentQuery,
    NextHubExternalAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    NextHubExternalAppointmentQuery,
    NextHubExternalAppointmentQueryVariables
  >(NextHubExternalAppointmentDocument, options);
}
export function useNextHubExternalAppointmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NextHubExternalAppointmentQuery,
    NextHubExternalAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    NextHubExternalAppointmentQuery,
    NextHubExternalAppointmentQueryVariables
  >(NextHubExternalAppointmentDocument, options);
}
export type NextHubExternalAppointmentQueryHookResult = ReturnType<
  typeof useNextHubExternalAppointmentQuery
>;
export type NextHubExternalAppointmentLazyQueryHookResult = ReturnType<
  typeof useNextHubExternalAppointmentLazyQuery
>;
export type NextHubExternalAppointmentQueryResult = ApolloReactCommon.QueryResult<
  NextHubExternalAppointmentQuery,
  NextHubExternalAppointmentQueryVariables
>;
export const UpcomingHubAppointmentDocument = gql`
  query upcomingHubAppointment($delay: Float) {
    upcomingHubAppointment(delay: $delay) {
      id
      appointmentTypeId
      appointmentTypeName
      length
      start
      zoomJoinUrl
      rescheduleLink
    }
  }
`;

/**
 * __useUpcomingHubAppointmentQuery__
 *
 * To run a query within a React component, call `useUpcomingHubAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingHubAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingHubAppointmentQuery({
 *   variables: {
 *      delay: // value for 'delay'
 *   },
 * });
 */
export function useUpcomingHubAppointmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UpcomingHubAppointmentQuery,
    UpcomingHubAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UpcomingHubAppointmentQuery,
    UpcomingHubAppointmentQueryVariables
  >(UpcomingHubAppointmentDocument, options);
}
export function useUpcomingHubAppointmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UpcomingHubAppointmentQuery,
    UpcomingHubAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UpcomingHubAppointmentQuery,
    UpcomingHubAppointmentQueryVariables
  >(UpcomingHubAppointmentDocument, options);
}
export type UpcomingHubAppointmentQueryHookResult = ReturnType<
  typeof useUpcomingHubAppointmentQuery
>;
export type UpcomingHubAppointmentLazyQueryHookResult = ReturnType<
  typeof useUpcomingHubAppointmentLazyQuery
>;
export type UpcomingHubAppointmentQueryResult = ApolloReactCommon.QueryResult<
  UpcomingHubAppointmentQuery,
  UpcomingHubAppointmentQueryVariables
>;
export const HubAppointmentByIdDocument = gql`
  query hubAppointmentById($appointmentId: String!) {
    hubAppointmentById(appointmentId: $appointmentId) {
      id
      appointmentTypeId
      appointmentTypeName
      length
      start
      zoomJoinUrl
      rescheduleLink
    }
  }
`;

/**
 * __useHubAppointmentByIdQuery__
 *
 * To run a query within a React component, call `useHubAppointmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubAppointmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubAppointmentByIdQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useHubAppointmentByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    HubAppointmentByIdQuery,
    HubAppointmentByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<HubAppointmentByIdQuery, HubAppointmentByIdQueryVariables>(
    HubAppointmentByIdDocument,
    options,
  );
}
export function useHubAppointmentByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HubAppointmentByIdQuery,
    HubAppointmentByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<HubAppointmentByIdQuery, HubAppointmentByIdQueryVariables>(
    HubAppointmentByIdDocument,
    options,
  );
}
export type HubAppointmentByIdQueryHookResult = ReturnType<typeof useHubAppointmentByIdQuery>;
export type HubAppointmentByIdLazyQueryHookResult = ReturnType<
  typeof useHubAppointmentByIdLazyQuery
>;
export type HubAppointmentByIdQueryResult = ApolloReactCommon.QueryResult<
  HubAppointmentByIdQuery,
  HubAppointmentByIdQueryVariables
>;
export const GetHubAssessmentLinkDocument = gql`
  query getHubAssessmentLink {
    hubGetAssessmentLink
  }
`;

/**
 * __useGetHubAssessmentLinkQuery__
 *
 * To run a query within a React component, call `useGetHubAssessmentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubAssessmentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubAssessmentLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHubAssessmentLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHubAssessmentLinkQuery,
    GetHubAssessmentLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetHubAssessmentLinkQuery, GetHubAssessmentLinkQueryVariables>(
    GetHubAssessmentLinkDocument,
    options,
  );
}
export function useGetHubAssessmentLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHubAssessmentLinkQuery,
    GetHubAssessmentLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHubAssessmentLinkQuery,
    GetHubAssessmentLinkQueryVariables
  >(GetHubAssessmentLinkDocument, options);
}
export type GetHubAssessmentLinkQueryHookResult = ReturnType<typeof useGetHubAssessmentLinkQuery>;
export type GetHubAssessmentLinkLazyQueryHookResult = ReturnType<
  typeof useGetHubAssessmentLinkLazyQuery
>;
export type GetHubAssessmentLinkQueryResult = ApolloReactCommon.QueryResult<
  GetHubAssessmentLinkQuery,
  GetHubAssessmentLinkQueryVariables
>;
export const AuthInstructionsForEmailDocument = gql`
  query authInstructionsForEmail($email: String!, $app: SamlUserApp!, $mrn: String) {
    authInstructionsForEmail(email: $email, app: $app, mrn: $mrn) {
      authMethod
      samlEntryPoint
      organizationAbbreviation
      hasPreviousSSOLogin
    }
  }
`;

/**
 * __useAuthInstructionsForEmailQuery__
 *
 * To run a query within a React component, call `useAuthInstructionsForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthInstructionsForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthInstructionsForEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *      app: // value for 'app'
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useAuthInstructionsForEmailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AuthInstructionsForEmailQuery,
    AuthInstructionsForEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AuthInstructionsForEmailQuery,
    AuthInstructionsForEmailQueryVariables
  >(AuthInstructionsForEmailDocument, options);
}
export function useAuthInstructionsForEmailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AuthInstructionsForEmailQuery,
    AuthInstructionsForEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AuthInstructionsForEmailQuery,
    AuthInstructionsForEmailQueryVariables
  >(AuthInstructionsForEmailDocument, options);
}
export type AuthInstructionsForEmailQueryHookResult = ReturnType<
  typeof useAuthInstructionsForEmailQuery
>;
export type AuthInstructionsForEmailLazyQueryHookResult = ReturnType<
  typeof useAuthInstructionsForEmailLazyQuery
>;
export type AuthInstructionsForEmailQueryResult = ApolloReactCommon.QueryResult<
  AuthInstructionsForEmailQuery,
  AuthInstructionsForEmailQueryVariables
>;
export const IsLoggedInDocument = gql`
  query isLoggedIn {
    isLoggedInHub
  }
`;

/**
 * __useIsLoggedInQuery__
 *
 * To run a query within a React component, call `useIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoggedInQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(
    IsLoggedInDocument,
    options,
  );
}
export function useIsLoggedInLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(
    IsLoggedInDocument,
    options,
  );
}
export type IsLoggedInQueryHookResult = ReturnType<typeof useIsLoggedInQuery>;
export type IsLoggedInLazyQueryHookResult = ReturnType<typeof useIsLoggedInLazyQuery>;
export type IsLoggedInQueryResult = ApolloReactCommon.QueryResult<
  IsLoggedInQuery,
  IsLoggedInQueryVariables
>;
export const NavigateFromHubMobileToWebDocument = gql`
  query navigateFromHubMobileToWeb($source: String) {
    navigateFromHubMobileToWeb(source: $source)
  }
`;

/**
 * __useNavigateFromHubMobileToWebQuery__
 *
 * To run a query within a React component, call `useNavigateFromHubMobileToWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigateFromHubMobileToWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigateFromHubMobileToWebQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useNavigateFromHubMobileToWebQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NavigateFromHubMobileToWebQuery,
    NavigateFromHubMobileToWebQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    NavigateFromHubMobileToWebQuery,
    NavigateFromHubMobileToWebQueryVariables
  >(NavigateFromHubMobileToWebDocument, options);
}
export function useNavigateFromHubMobileToWebLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NavigateFromHubMobileToWebQuery,
    NavigateFromHubMobileToWebQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    NavigateFromHubMobileToWebQuery,
    NavigateFromHubMobileToWebQueryVariables
  >(NavigateFromHubMobileToWebDocument, options);
}
export type NavigateFromHubMobileToWebQueryHookResult = ReturnType<
  typeof useNavigateFromHubMobileToWebQuery
>;
export type NavigateFromHubMobileToWebLazyQueryHookResult = ReturnType<
  typeof useNavigateFromHubMobileToWebLazyQuery
>;
export type NavigateFromHubMobileToWebQueryResult = ApolloReactCommon.QueryResult<
  NavigateFromHubMobileToWebQuery,
  NavigateFromHubMobileToWebQueryVariables
>;
export const NavigateFromHubToPatientPortalDocument = gql`
  query navigateFromHubToPatientPortal($mrn: String) {
    navigateFromHubToPatientPortal2(mrn: $mrn) {
      __typename
      ... on NavigateFromHubToPatientPortalSuccess {
        loginUrl
      }
      ... on NavigateFromHubToPatientPortalError {
        errorCode
      }
    }
  }
`;

/**
 * __useNavigateFromHubToPatientPortalQuery__
 *
 * To run a query within a React component, call `useNavigateFromHubToPatientPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigateFromHubToPatientPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigateFromHubToPatientPortalQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useNavigateFromHubToPatientPortalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NavigateFromHubToPatientPortalQuery,
    NavigateFromHubToPatientPortalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    NavigateFromHubToPatientPortalQuery,
    NavigateFromHubToPatientPortalQueryVariables
  >(NavigateFromHubToPatientPortalDocument, options);
}
export function useNavigateFromHubToPatientPortalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NavigateFromHubToPatientPortalQuery,
    NavigateFromHubToPatientPortalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    NavigateFromHubToPatientPortalQuery,
    NavigateFromHubToPatientPortalQueryVariables
  >(NavigateFromHubToPatientPortalDocument, options);
}
export type NavigateFromHubToPatientPortalQueryHookResult = ReturnType<
  typeof useNavigateFromHubToPatientPortalQuery
>;
export type NavigateFromHubToPatientPortalLazyQueryHookResult = ReturnType<
  typeof useNavigateFromHubToPatientPortalLazyQuery
>;
export type NavigateFromHubToPatientPortalQueryResult = ApolloReactCommon.QueryResult<
  NavigateFromHubToPatientPortalQuery,
  NavigateFromHubToPatientPortalQueryVariables
>;
export const LoginToHubDocument = gql`
  mutation loginToHub($tokenId: String!, $token: String!) {
    loginToHub(tokenId: $tokenId, token: $token) {
      __typename
      ... on LoginToHubSuccess {
        userId
        jwt
        hasHubOnboarded
      }
      ... on LoginToHubError {
        errorCode
      }
    }
  }
`;
export type LoginToHubMutationFn = ApolloReactCommon.MutationFunction<
  LoginToHubMutation,
  LoginToHubMutationVariables
>;

/**
 * __useLoginToHubMutation__
 *
 * To run a mutation, you first call `useLoginToHubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginToHubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginToHubMutation, { data, loading, error }] = useLoginToHubMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginToHubMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginToHubMutation,
    LoginToHubMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginToHubMutation, LoginToHubMutationVariables>(
    LoginToHubDocument,
    options,
  );
}
export type LoginToHubMutationHookResult = ReturnType<typeof useLoginToHubMutation>;
export type LoginToHubMutationResult = ApolloReactCommon.MutationResult<LoginToHubMutation>;
export type LoginToHubMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginToHubMutation,
  LoginToHubMutationVariables
>;
export const LogoutHubDocument = gql`
  mutation logoutHub {
    logoutHub
  }
`;
export type LogoutHubMutationFn = ApolloReactCommon.MutationFunction<
  LogoutHubMutation,
  LogoutHubMutationVariables
>;

/**
 * __useLogoutHubMutation__
 *
 * To run a mutation, you first call `useLogoutHubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutHubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutHubMutation, { data, loading, error }] = useLogoutHubMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutHubMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutHubMutation, LogoutHubMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LogoutHubMutation, LogoutHubMutationVariables>(
    LogoutHubDocument,
    options,
  );
}
export type LogoutHubMutationHookResult = ReturnType<typeof useLogoutHubMutation>;
export type LogoutHubMutationResult = ApolloReactCommon.MutationResult<LogoutHubMutation>;
export type LogoutHubMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutHubMutation,
  LogoutHubMutationVariables
>;
export const PreLoginOrPasswordLoginDocument = gql`
  mutation preLoginOrPasswordLogin(
    $app: SamlUserApp!
    $email: String!
    $password: String
    $mrn: String
  ) {
    preLoginOrPasswordLogin(app: $app, email: $email, password: $password, mrn: $mrn) {
      __typename
      ... on PreLoginOrPasswordLoginSuccess {
        loginSuccess
        hasHubOnboarded
        jwt
      }
      ... on PreLoginOrPasswordLoginPassword {
        passwordRequired
      }
      ... on PreLoginOrPasswordLoginSaml {
        samlEntryPoint
      }
      ... on PreLoginOrPasswordLoginRedirect {
        externalRedirect
      }
      ... on PreLoginOrPasswordLoginError {
        errorCode
        message
      }
    }
  }
`;
export type PreLoginOrPasswordLoginMutationFn = ApolloReactCommon.MutationFunction<
  PreLoginOrPasswordLoginMutation,
  PreLoginOrPasswordLoginMutationVariables
>;

/**
 * __usePreLoginOrPasswordLoginMutation__
 *
 * To run a mutation, you first call `usePreLoginOrPasswordLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreLoginOrPasswordLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preLoginOrPasswordLoginMutation, { data, loading, error }] = usePreLoginOrPasswordLoginMutation({
 *   variables: {
 *      app: // value for 'app'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function usePreLoginOrPasswordLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PreLoginOrPasswordLoginMutation,
    PreLoginOrPasswordLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PreLoginOrPasswordLoginMutation,
    PreLoginOrPasswordLoginMutationVariables
  >(PreLoginOrPasswordLoginDocument, options);
}
export type PreLoginOrPasswordLoginMutationHookResult = ReturnType<
  typeof usePreLoginOrPasswordLoginMutation
>;
export type PreLoginOrPasswordLoginMutationResult =
  ApolloReactCommon.MutationResult<PreLoginOrPasswordLoginMutation>;
export type PreLoginOrPasswordLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PreLoginOrPasswordLoginMutation,
  PreLoginOrPasswordLoginMutationVariables
>;
export const PreSignUpDocument = gql`
  mutation preSignUp($app: SamlUserApp!, $email: String!) {
    preSignUp(app: $app, email: $email) {
      typename: __typename
      ... on PreSignUpSsoRedirect {
        samlEntryPoint
      }
      ... on PreSignUpError {
        errorCode
      }
    }
  }
`;
export type PreSignUpMutationFn = ApolloReactCommon.MutationFunction<
  PreSignUpMutation,
  PreSignUpMutationVariables
>;

/**
 * __usePreSignUpMutation__
 *
 * To run a mutation, you first call `usePreSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preSignUpMutation, { data, loading, error }] = usePreSignUpMutation({
 *   variables: {
 *      app: // value for 'app'
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePreSignUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<PreSignUpMutation, PreSignUpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<PreSignUpMutation, PreSignUpMutationVariables>(
    PreSignUpDocument,
    options,
  );
}
export type PreSignUpMutationHookResult = ReturnType<typeof usePreSignUpMutation>;
export type PreSignUpMutationResult = ApolloReactCommon.MutationResult<PreSignUpMutation>;
export type PreSignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PreSignUpMutation,
  PreSignUpMutationVariables
>;
export const ResendActivationEmailDocument = gql`
  mutation resendActivationEmail($email: String!) {
    resendActivationEmail(email: $email) {
      __typename
      ... on HubResendActivationSuccess {
        success
      }
      ... on HubResendActivationError {
        errorCode
      }
    }
  }
`;
export type ResendActivationEmailMutationFn = ApolloReactCommon.MutationFunction<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;

/**
 * __useResendActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationEmailMutation, { data, loading, error }] = useResendActivationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendActivationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >(ResendActivationEmailDocument, options);
}
export type ResendActivationEmailMutationHookResult = ReturnType<
  typeof useResendActivationEmailMutation
>;
export type ResendActivationEmailMutationResult =
  ApolloReactCommon.MutationResult<ResendActivationEmailMutation>;
export type ResendActivationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;
export const SendPasswordResetEmailDocument = gql`
  mutation sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;
export type SendPasswordResetEmailMutationFn = ApolloReactCommon.MutationFunction<
  SendPasswordResetEmailMutation,
  SendPasswordResetEmailMutationVariables
>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
  >(SendPasswordResetEmailDocument, options);
}
export type SendPasswordResetEmailMutationHookResult = ReturnType<
  typeof useSendPasswordResetEmailMutation
>;
export type SendPasswordResetEmailMutationResult =
  ApolloReactCommon.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendPasswordResetEmailMutation,
  SendPasswordResetEmailMutationVariables
>;
export const SetPasswordWithTokenDocument = gql`
  mutation setPasswordWithToken($tokenId: Float!, $token: String!, $password: String!) {
    setPasswordWithToken(tokenId: $tokenId, token: $token, password: $password) {
      __typename
      ... on SetPasswordWithTokenError {
        errorCode
        message
      }
    }
  }
`;
export type SetPasswordWithTokenMutationFn = ApolloReactCommon.MutationFunction<
  SetPasswordWithTokenMutation,
  SetPasswordWithTokenMutationVariables
>;

/**
 * __useSetPasswordWithTokenMutation__
 *
 * To run a mutation, you first call `useSetPasswordWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordWithTokenMutation, { data, loading, error }] = useSetPasswordWithTokenMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetPasswordWithTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetPasswordWithTokenMutation,
    SetPasswordWithTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetPasswordWithTokenMutation,
    SetPasswordWithTokenMutationVariables
  >(SetPasswordWithTokenDocument, options);
}
export type SetPasswordWithTokenMutationHookResult = ReturnType<
  typeof useSetPasswordWithTokenMutation
>;
export type SetPasswordWithTokenMutationResult =
  ApolloReactCommon.MutationResult<SetPasswordWithTokenMutation>;
export type SetPasswordWithTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetPasswordWithTokenMutation,
  SetPasswordWithTokenMutationVariables
>;
export const SignUpDocument = gql`
  mutation signUp($tokenId: String!, $token: String!, $password: String!) {
    signUp(tokenId: $tokenId, token: $token, password: $password) {
      typename: __typename
      ... on SignUpError {
        errorCode
        message
      }
    }
  }
`;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options,
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const ResetBadgeCountDocument = gql`
  mutation resetBadgeCount($counterName: String!) {
    resetBadgeCount(counterName: $counterName)
  }
`;
export type ResetBadgeCountMutationFn = ApolloReactCommon.MutationFunction<
  ResetBadgeCountMutation,
  ResetBadgeCountMutationVariables
>;

/**
 * __useResetBadgeCountMutation__
 *
 * To run a mutation, you first call `useResetBadgeCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetBadgeCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetBadgeCountMutation, { data, loading, error }] = useResetBadgeCountMutation({
 *   variables: {
 *      counterName: // value for 'counterName'
 *   },
 * });
 */
export function useResetBadgeCountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetBadgeCountMutation,
    ResetBadgeCountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ResetBadgeCountMutation, ResetBadgeCountMutationVariables>(
    ResetBadgeCountDocument,
    options,
  );
}
export type ResetBadgeCountMutationHookResult = ReturnType<typeof useResetBadgeCountMutation>;
export type ResetBadgeCountMutationResult =
  ApolloReactCommon.MutationResult<ResetBadgeCountMutation>;
export type ResetBadgeCountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetBadgeCountMutation,
  ResetBadgeCountMutationVariables
>;
export const HubOrganizationResourcesDocument = gql`
  query hubOrganizationResources {
    hubOrganizationResources {
      id
      name
      description
      showAsCrisisResource
      contact
      location
      phone
      email
      hours
      website
    }
  }
`;

/**
 * __useHubOrganizationResourcesQuery__
 *
 * To run a query within a React component, call `useHubOrganizationResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubOrganizationResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubOrganizationResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHubOrganizationResourcesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HubOrganizationResourcesQuery,
    HubOrganizationResourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    HubOrganizationResourcesQuery,
    HubOrganizationResourcesQueryVariables
  >(HubOrganizationResourcesDocument, options);
}
export function useHubOrganizationResourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HubOrganizationResourcesQuery,
    HubOrganizationResourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    HubOrganizationResourcesQuery,
    HubOrganizationResourcesQueryVariables
  >(HubOrganizationResourcesDocument, options);
}
export type HubOrganizationResourcesQueryHookResult = ReturnType<
  typeof useHubOrganizationResourcesQuery
>;
export type HubOrganizationResourcesLazyQueryHookResult = ReturnType<
  typeof useHubOrganizationResourcesLazyQuery
>;
export type HubOrganizationResourcesQueryResult = ApolloReactCommon.QueryResult<
  HubOrganizationResourcesQuery,
  HubOrganizationResourcesQueryVariables
>;
export const GetCoachChatMessagesDocument = gql`
  query getCoachChatMessages($offset: Float!) {
    getCoachChatMessages(offset: $offset) {
      id
      content
      createdAt
      author
    }
  }
`;

/**
 * __useGetCoachChatMessagesQuery__
 *
 * To run a query within a React component, call `useGetCoachChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachChatMessagesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCoachChatMessagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCoachChatMessagesQuery,
    GetCoachChatMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetCoachChatMessagesQuery, GetCoachChatMessagesQueryVariables>(
    GetCoachChatMessagesDocument,
    options,
  );
}
export function useGetCoachChatMessagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCoachChatMessagesQuery,
    GetCoachChatMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCoachChatMessagesQuery,
    GetCoachChatMessagesQueryVariables
  >(GetCoachChatMessagesDocument, options);
}
export type GetCoachChatMessagesQueryHookResult = ReturnType<typeof useGetCoachChatMessagesQuery>;
export type GetCoachChatMessagesLazyQueryHookResult = ReturnType<
  typeof useGetCoachChatMessagesLazyQuery
>;
export type GetCoachChatMessagesQueryResult = ApolloReactCommon.QueryResult<
  GetCoachChatMessagesQuery,
  GetCoachChatMessagesQueryVariables
>;
export const CoachChatUpdatesDocument = gql`
  subscription coachChatUpdates {
    coachChatUpdates {
      messageId
      action
      message {
        id
        content
        createdAt
        author
      }
    }
  }
`;

/**
 * __useCoachChatUpdatesSubscription__
 *
 * To run a query within a React component, call `useCoachChatUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCoachChatUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachChatUpdatesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCoachChatUpdatesSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    CoachChatUpdatesSubscription,
    CoachChatUpdatesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSubscription<
    CoachChatUpdatesSubscription,
    CoachChatUpdatesSubscriptionVariables
  >(CoachChatUpdatesDocument, options);
}
export type CoachChatUpdatesSubscriptionHookResult = ReturnType<
  typeof useCoachChatUpdatesSubscription
>;
export type CoachChatUpdatesSubscriptionResult =
  ApolloReactCommon.SubscriptionResult<CoachChatUpdatesSubscription>;
export const SendCoachChatMessageDocument = gql`
  mutation sendCoachChatMessage($content: String!) {
    sendCoachChatMessage(content: $content) {
      id
    }
  }
`;
export type SendCoachChatMessageMutationFn = ApolloReactCommon.MutationFunction<
  SendCoachChatMessageMutation,
  SendCoachChatMessageMutationVariables
>;

/**
 * __useSendCoachChatMessageMutation__
 *
 * To run a mutation, you first call `useSendCoachChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCoachChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCoachChatMessageMutation, { data, loading, error }] = useSendCoachChatMessageMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSendCoachChatMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendCoachChatMessageMutation,
    SendCoachChatMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SendCoachChatMessageMutation,
    SendCoachChatMessageMutationVariables
  >(SendCoachChatMessageDocument, options);
}
export type SendCoachChatMessageMutationHookResult = ReturnType<
  typeof useSendCoachChatMessageMutation
>;
export type SendCoachChatMessageMutationResult =
  ApolloReactCommon.MutationResult<SendCoachChatMessageMutation>;
export type SendCoachChatMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendCoachChatMessageMutation,
  SendCoachChatMessageMutationVariables
>;
export const CoachOverviewDocument = gql`
  query coachOverview {
    coachOverview {
      coachAvatarUrl
      coachName
      hasUnreadMessages
      coachingStartDate
      coachingEndDate
    }
  }
`;

/**
 * __useCoachOverviewQuery__
 *
 * To run a query within a React component, call `useCoachOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachOverviewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CoachOverviewQuery, CoachOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CoachOverviewQuery, CoachOverviewQueryVariables>(
    CoachOverviewDocument,
    options,
  );
}
export function useCoachOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CoachOverviewQuery,
    CoachOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CoachOverviewQuery, CoachOverviewQueryVariables>(
    CoachOverviewDocument,
    options,
  );
}
export type CoachOverviewQueryHookResult = ReturnType<typeof useCoachOverviewQuery>;
export type CoachOverviewLazyQueryHookResult = ReturnType<typeof useCoachOverviewLazyQuery>;
export type CoachOverviewQueryResult = ApolloReactCommon.QueryResult<
  CoachOverviewQuery,
  CoachOverviewQueryVariables
>;
export const CoachingIntakeProgressV2Document = gql`
  query coachingIntakeProgressV2 {
    coachingIntakeProgressV2 {
      coachingAssessments {
        key
        completed
      }
    }
  }
`;

/**
 * __useCoachingIntakeProgressV2Query__
 *
 * To run a query within a React component, call `useCoachingIntakeProgressV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCoachingIntakeProgressV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingIntakeProgressV2Query({
 *   variables: {
 *   },
 * });
 */
export function useCoachingIntakeProgressV2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CoachingIntakeProgressV2Query,
    CoachingIntakeProgressV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CoachingIntakeProgressV2Query,
    CoachingIntakeProgressV2QueryVariables
  >(CoachingIntakeProgressV2Document, options);
}
export function useCoachingIntakeProgressV2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CoachingIntakeProgressV2Query,
    CoachingIntakeProgressV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CoachingIntakeProgressV2Query,
    CoachingIntakeProgressV2QueryVariables
  >(CoachingIntakeProgressV2Document, options);
}
export type CoachingIntakeProgressV2QueryHookResult = ReturnType<
  typeof useCoachingIntakeProgressV2Query
>;
export type CoachingIntakeProgressV2LazyQueryHookResult = ReturnType<
  typeof useCoachingIntakeProgressV2LazyQuery
>;
export type CoachingIntakeProgressV2QueryResult = ApolloReactCommon.QueryResult<
  CoachingIntakeProgressV2Query,
  CoachingIntakeProgressV2QueryVariables
>;
export const UserCoachingStatusDocument = gql`
  query userCoachingStatus {
    userCoachingStatus {
      userCoachingStatus
    }
  }
`;

/**
 * __useUserCoachingStatusQuery__
 *
 * To run a query within a React component, call `useUserCoachingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCoachingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCoachingStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCoachingStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserCoachingStatusQuery,
    UserCoachingStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserCoachingStatusQuery, UserCoachingStatusQueryVariables>(
    UserCoachingStatusDocument,
    options,
  );
}
export function useUserCoachingStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserCoachingStatusQuery,
    UserCoachingStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserCoachingStatusQuery, UserCoachingStatusQueryVariables>(
    UserCoachingStatusDocument,
    options,
  );
}
export type UserCoachingStatusQueryHookResult = ReturnType<typeof useUserCoachingStatusQuery>;
export type UserCoachingStatusLazyQueryHookResult = ReturnType<
  typeof useUserCoachingStatusLazyQuery
>;
export type UserCoachingStatusQueryResult = ApolloReactCommon.QueryResult<
  UserCoachingStatusQuery,
  UserCoachingStatusQueryVariables
>;
export const CoachingGoalsAssessmentDocument = gql`
  query coachingGoalsAssessment {
    coachingGoalsAssessment {
      key
      questionnaires {
        title
        key
        questions {
          isOptional
          key
          label
          placeholder
          preText
          subText
          text
          type
          options {
            key
            requiresManualInput
            text
          }
          manualInput {
            label
            placeholder
          }
        }
        description
      }
    }
  }
`;

/**
 * __useCoachingGoalsAssessmentQuery__
 *
 * To run a query within a React component, call `useCoachingGoalsAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachingGoalsAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingGoalsAssessmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachingGoalsAssessmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CoachingGoalsAssessmentQuery,
    CoachingGoalsAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CoachingGoalsAssessmentQuery,
    CoachingGoalsAssessmentQueryVariables
  >(CoachingGoalsAssessmentDocument, options);
}
export function useCoachingGoalsAssessmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CoachingGoalsAssessmentQuery,
    CoachingGoalsAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CoachingGoalsAssessmentQuery,
    CoachingGoalsAssessmentQueryVariables
  >(CoachingGoalsAssessmentDocument, options);
}
export type CoachingGoalsAssessmentQueryHookResult = ReturnType<
  typeof useCoachingGoalsAssessmentQuery
>;
export type CoachingGoalsAssessmentLazyQueryHookResult = ReturnType<
  typeof useCoachingGoalsAssessmentLazyQuery
>;
export type CoachingGoalsAssessmentQueryResult = ApolloReactCommon.QueryResult<
  CoachingGoalsAssessmentQuery,
  CoachingGoalsAssessmentQueryVariables
>;
export const CoachingWellnessAssessmentDocument = gql`
  query coachingWellnessAssessment {
    coachingWellnessAssessment {
      key
      questionnaires {
        title
        key
        questions {
          isOptional
          key
          label
          placeholder
          preText
          subText
          text
          type
          options {
            key
            requiresManualInput
            text
          }
        }
        description
      }
    }
  }
`;

/**
 * __useCoachingWellnessAssessmentQuery__
 *
 * To run a query within a React component, call `useCoachingWellnessAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachingWellnessAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingWellnessAssessmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachingWellnessAssessmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CoachingWellnessAssessmentQuery,
    CoachingWellnessAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CoachingWellnessAssessmentQuery,
    CoachingWellnessAssessmentQueryVariables
  >(CoachingWellnessAssessmentDocument, options);
}
export function useCoachingWellnessAssessmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CoachingWellnessAssessmentQuery,
    CoachingWellnessAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CoachingWellnessAssessmentQuery,
    CoachingWellnessAssessmentQueryVariables
  >(CoachingWellnessAssessmentDocument, options);
}
export type CoachingWellnessAssessmentQueryHookResult = ReturnType<
  typeof useCoachingWellnessAssessmentQuery
>;
export type CoachingWellnessAssessmentLazyQueryHookResult = ReturnType<
  typeof useCoachingWellnessAssessmentLazyQuery
>;
export type CoachingWellnessAssessmentQueryResult = ApolloReactCommon.QueryResult<
  CoachingWellnessAssessmentQuery,
  CoachingWellnessAssessmentQueryVariables
>;
export const CoachingExecutiveFunctioningGoalsAssessmentDocument = gql`
  query coachingExecutiveFunctioningGoalsAssessment {
    coachingExecutiveFunctioningGoalsAssessment {
      key
      questionnaires {
        title
        key
        questions {
          isOptional
          key
          label
          placeholder
          preText
          subText
          text
          type
          options {
            key
            requiresManualInput
            text
          }
          manualInput {
            label
            placeholder
          }
        }
        description
      }
    }
  }
`;

/**
 * __useCoachingExecutiveFunctioningGoalsAssessmentQuery__
 *
 * To run a query within a React component, call `useCoachingExecutiveFunctioningGoalsAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachingExecutiveFunctioningGoalsAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingExecutiveFunctioningGoalsAssessmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachingExecutiveFunctioningGoalsAssessmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CoachingExecutiveFunctioningGoalsAssessmentQuery,
    CoachingExecutiveFunctioningGoalsAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CoachingExecutiveFunctioningGoalsAssessmentQuery,
    CoachingExecutiveFunctioningGoalsAssessmentQueryVariables
  >(CoachingExecutiveFunctioningGoalsAssessmentDocument, options);
}
export function useCoachingExecutiveFunctioningGoalsAssessmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CoachingExecutiveFunctioningGoalsAssessmentQuery,
    CoachingExecutiveFunctioningGoalsAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CoachingExecutiveFunctioningGoalsAssessmentQuery,
    CoachingExecutiveFunctioningGoalsAssessmentQueryVariables
  >(CoachingExecutiveFunctioningGoalsAssessmentDocument, options);
}
export type CoachingExecutiveFunctioningGoalsAssessmentQueryHookResult = ReturnType<
  typeof useCoachingExecutiveFunctioningGoalsAssessmentQuery
>;
export type CoachingExecutiveFunctioningGoalsAssessmentLazyQueryHookResult = ReturnType<
  typeof useCoachingExecutiveFunctioningGoalsAssessmentLazyQuery
>;
export type CoachingExecutiveFunctioningGoalsAssessmentQueryResult = ApolloReactCommon.QueryResult<
  CoachingExecutiveFunctioningGoalsAssessmentQuery,
  CoachingExecutiveFunctioningGoalsAssessmentQueryVariables
>;
export const CoachingExecutiveFunctioningWellnessAssessmentDocument = gql`
  query coachingExecutiveFunctioningWellnessAssessment {
    coachingExecutiveFunctioningWellnessAssessment {
      key
      questionnaires {
        title
        key
        questions {
          isOptional
          key
          label
          placeholder
          preText
          subText
          text
          type
          options {
            key
            requiresManualInput
            text
          }
        }
        description
      }
    }
  }
`;

/**
 * __useCoachingExecutiveFunctioningWellnessAssessmentQuery__
 *
 * To run a query within a React component, call `useCoachingExecutiveFunctioningWellnessAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachingExecutiveFunctioningWellnessAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingExecutiveFunctioningWellnessAssessmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachingExecutiveFunctioningWellnessAssessmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CoachingExecutiveFunctioningWellnessAssessmentQuery,
    CoachingExecutiveFunctioningWellnessAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CoachingExecutiveFunctioningWellnessAssessmentQuery,
    CoachingExecutiveFunctioningWellnessAssessmentQueryVariables
  >(CoachingExecutiveFunctioningWellnessAssessmentDocument, options);
}
export function useCoachingExecutiveFunctioningWellnessAssessmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CoachingExecutiveFunctioningWellnessAssessmentQuery,
    CoachingExecutiveFunctioningWellnessAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CoachingExecutiveFunctioningWellnessAssessmentQuery,
    CoachingExecutiveFunctioningWellnessAssessmentQueryVariables
  >(CoachingExecutiveFunctioningWellnessAssessmentDocument, options);
}
export type CoachingExecutiveFunctioningWellnessAssessmentQueryHookResult = ReturnType<
  typeof useCoachingExecutiveFunctioningWellnessAssessmentQuery
>;
export type CoachingExecutiveFunctioningWellnessAssessmentLazyQueryHookResult = ReturnType<
  typeof useCoachingExecutiveFunctioningWellnessAssessmentLazyQuery
>;
export type CoachingExecutiveFunctioningWellnessAssessmentQueryResult =
  ApolloReactCommon.QueryResult<
    CoachingExecutiveFunctioningWellnessAssessmentQuery,
    CoachingExecutiveFunctioningWellnessAssessmentQueryVariables
  >;
export const CoachingKickoffLinkDocument = gql`
  query coachingKickoffLink {
    coachingKickoffLink
  }
`;

/**
 * __useCoachingKickoffLinkQuery__
 *
 * To run a query within a React component, call `useCoachingKickoffLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachingKickoffLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingKickoffLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachingKickoffLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CoachingKickoffLinkQuery,
    CoachingKickoffLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CoachingKickoffLinkQuery, CoachingKickoffLinkQueryVariables>(
    CoachingKickoffLinkDocument,
    options,
  );
}
export function useCoachingKickoffLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CoachingKickoffLinkQuery,
    CoachingKickoffLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CoachingKickoffLinkQuery, CoachingKickoffLinkQueryVariables>(
    CoachingKickoffLinkDocument,
    options,
  );
}
export type CoachingKickoffLinkQueryHookResult = ReturnType<typeof useCoachingKickoffLinkQuery>;
export type CoachingKickoffLinkLazyQueryHookResult = ReturnType<
  typeof useCoachingKickoffLinkLazyQuery
>;
export type CoachingKickoffLinkQueryResult = ApolloReactCommon.QueryResult<
  CoachingKickoffLinkQuery,
  CoachingKickoffLinkQueryVariables
>;
export const ConsumeCoachingRecommendationDocument = gql`
  mutation consumeCoachingRecommendation($coachingProgramType: coachingProgramType!) {
    consumeCoachingRecommendation(coachingProgramType: $coachingProgramType)
  }
`;
export type ConsumeCoachingRecommendationMutationFn = ApolloReactCommon.MutationFunction<
  ConsumeCoachingRecommendationMutation,
  ConsumeCoachingRecommendationMutationVariables
>;

/**
 * __useConsumeCoachingRecommendationMutation__
 *
 * To run a mutation, you first call `useConsumeCoachingRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumeCoachingRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumeCoachingRecommendationMutation, { data, loading, error }] = useConsumeCoachingRecommendationMutation({
 *   variables: {
 *      coachingProgramType: // value for 'coachingProgramType'
 *   },
 * });
 */
export function useConsumeCoachingRecommendationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConsumeCoachingRecommendationMutation,
    ConsumeCoachingRecommendationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ConsumeCoachingRecommendationMutation,
    ConsumeCoachingRecommendationMutationVariables
  >(ConsumeCoachingRecommendationDocument, options);
}
export type ConsumeCoachingRecommendationMutationHookResult = ReturnType<
  typeof useConsumeCoachingRecommendationMutation
>;
export type ConsumeCoachingRecommendationMutationResult =
  ApolloReactCommon.MutationResult<ConsumeCoachingRecommendationMutation>;
export type ConsumeCoachingRecommendationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConsumeCoachingRecommendationMutation,
  ConsumeCoachingRecommendationMutationVariables
>;
export const SubmitCoachingAssessmentV2Document = gql`
  mutation submitCoachingAssessmentV2(
    $assessmentType: coachingAssessments!
    $submissions: [HubAssessmentSubmission!]!
  ) {
    submitCoachingAssessmentV2(assessmentType: $assessmentType, submissions: $submissions)
  }
`;
export type SubmitCoachingAssessmentV2MutationFn = ApolloReactCommon.MutationFunction<
  SubmitCoachingAssessmentV2Mutation,
  SubmitCoachingAssessmentV2MutationVariables
>;

/**
 * __useSubmitCoachingAssessmentV2Mutation__
 *
 * To run a mutation, you first call `useSubmitCoachingAssessmentV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCoachingAssessmentV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCoachingAssessmentV2Mutation, { data, loading, error }] = useSubmitCoachingAssessmentV2Mutation({
 *   variables: {
 *      assessmentType: // value for 'assessmentType'
 *      submissions: // value for 'submissions'
 *   },
 * });
 */
export function useSubmitCoachingAssessmentV2Mutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitCoachingAssessmentV2Mutation,
    SubmitCoachingAssessmentV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SubmitCoachingAssessmentV2Mutation,
    SubmitCoachingAssessmentV2MutationVariables
  >(SubmitCoachingAssessmentV2Document, options);
}
export type SubmitCoachingAssessmentV2MutationHookResult = ReturnType<
  typeof useSubmitCoachingAssessmentV2Mutation
>;
export type SubmitCoachingAssessmentV2MutationResult =
  ApolloReactCommon.MutationResult<SubmitCoachingAssessmentV2Mutation>;
export type SubmitCoachingAssessmentV2MutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitCoachingAssessmentV2Mutation,
  SubmitCoachingAssessmentV2MutationVariables
>;
export const OrganizationConnectNowDocument = gql`
  query organizationConnectNow {
    organizationConnectNow {
      connectNowEnabled
      connectNowData {
        crisisCallPhoneNumber
        crisisSMS
        crisisWebChat
      }
    }
  }
`;

/**
 * __useOrganizationConnectNowQuery__
 *
 * To run a query within a React component, call `useOrganizationConnectNowQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationConnectNowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationConnectNowQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationConnectNowQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationConnectNowQuery,
    OrganizationConnectNowQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationConnectNowQuery,
    OrganizationConnectNowQueryVariables
  >(OrganizationConnectNowDocument, options);
}
export function useOrganizationConnectNowLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationConnectNowQuery,
    OrganizationConnectNowQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationConnectNowQuery,
    OrganizationConnectNowQueryVariables
  >(OrganizationConnectNowDocument, options);
}
export type OrganizationConnectNowQueryHookResult = ReturnType<
  typeof useOrganizationConnectNowQuery
>;
export type OrganizationConnectNowLazyQueryHookResult = ReturnType<
  typeof useOrganizationConnectNowLazyQuery
>;
export type OrganizationConnectNowQueryResult = ApolloReactCommon.QueryResult<
  OrganizationConnectNowQuery,
  OrganizationConnectNowQueryVariables
>;
export const IsConnectNowServiceAvailableDocument = gql`
  query isConnectNowServiceAvailable {
    isConnectNowServiceAvailable
  }
`;

/**
 * __useIsConnectNowServiceAvailableQuery__
 *
 * To run a query within a React component, call `useIsConnectNowServiceAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsConnectNowServiceAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsConnectNowServiceAvailableQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsConnectNowServiceAvailableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IsConnectNowServiceAvailableQuery,
    IsConnectNowServiceAvailableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IsConnectNowServiceAvailableQuery,
    IsConnectNowServiceAvailableQueryVariables
  >(IsConnectNowServiceAvailableDocument, options);
}
export function useIsConnectNowServiceAvailableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IsConnectNowServiceAvailableQuery,
    IsConnectNowServiceAvailableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IsConnectNowServiceAvailableQuery,
    IsConnectNowServiceAvailableQueryVariables
  >(IsConnectNowServiceAvailableDocument, options);
}
export type IsConnectNowServiceAvailableQueryHookResult = ReturnType<
  typeof useIsConnectNowServiceAvailableQuery
>;
export type IsConnectNowServiceAvailableLazyQueryHookResult = ReturnType<
  typeof useIsConnectNowServiceAvailableLazyQuery
>;
export type IsConnectNowServiceAvailableQueryResult = ApolloReactCommon.QueryResult<
  IsConnectNowServiceAvailableQuery,
  IsConnectNowServiceAvailableQueryVariables
>;
export const CreateConnectNowPreEncounterDocument = gql`
  mutation createConnectNowPreEncounter($data: ConnectNowPreEncounterInput!) {
    createConnectNowPreEncounter(data: $data)
  }
`;
export type CreateConnectNowPreEncounterMutationFn = ApolloReactCommon.MutationFunction<
  CreateConnectNowPreEncounterMutation,
  CreateConnectNowPreEncounterMutationVariables
>;

/**
 * __useCreateConnectNowPreEncounterMutation__
 *
 * To run a mutation, you first call `useCreateConnectNowPreEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectNowPreEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectNowPreEncounterMutation, { data, loading, error }] = useCreateConnectNowPreEncounterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateConnectNowPreEncounterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateConnectNowPreEncounterMutation,
    CreateConnectNowPreEncounterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateConnectNowPreEncounterMutation,
    CreateConnectNowPreEncounterMutationVariables
  >(CreateConnectNowPreEncounterDocument, options);
}
export type CreateConnectNowPreEncounterMutationHookResult = ReturnType<
  typeof useCreateConnectNowPreEncounterMutation
>;
export type CreateConnectNowPreEncounterMutationResult =
  ApolloReactCommon.MutationResult<CreateConnectNowPreEncounterMutation>;
export type CreateConnectNowPreEncounterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateConnectNowPreEncounterMutation,
  CreateConnectNowPreEncounterMutationVariables
>;
export const OrganizationCustomHubModuleDocument = gql`
  query organizationCustomHubModule {
    organizationCustomHubModule {
      isEnabled
      data {
        title
        description
        link
        url
      }
    }
  }
`;

/**
 * __useOrganizationCustomHubModuleQuery__
 *
 * To run a query within a React component, call `useOrganizationCustomHubModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCustomHubModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCustomHubModuleQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationCustomHubModuleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationCustomHubModuleQuery,
    OrganizationCustomHubModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationCustomHubModuleQuery,
    OrganizationCustomHubModuleQueryVariables
  >(OrganizationCustomHubModuleDocument, options);
}
export function useOrganizationCustomHubModuleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationCustomHubModuleQuery,
    OrganizationCustomHubModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationCustomHubModuleQuery,
    OrganizationCustomHubModuleQueryVariables
  >(OrganizationCustomHubModuleDocument, options);
}
export type OrganizationCustomHubModuleQueryHookResult = ReturnType<
  typeof useOrganizationCustomHubModuleQuery
>;
export type OrganizationCustomHubModuleLazyQueryHookResult = ReturnType<
  typeof useOrganizationCustomHubModuleLazyQuery
>;
export type OrganizationCustomHubModuleQueryResult = ApolloReactCommon.QueryResult<
  OrganizationCustomHubModuleQuery,
  OrganizationCustomHubModuleQueryVariables
>;
export const FindCareUserDocument = gql`
  query findCareUser {
    onboardedHubUser {
      preferredName
      firstName
      lastName
      phone
      birthDate
      pronouns
      primaryAddress {
        line1
        line2
        city
        state
        zip
      }
    }
  }
`;

/**
 * __useFindCareUserQuery__
 *
 * To run a query within a React component, call `useFindCareUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCareUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCareUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindCareUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FindCareUserQuery, FindCareUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindCareUserQuery, FindCareUserQueryVariables>(
    FindCareUserDocument,
    options,
  );
}
export function useFindCareUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindCareUserQuery,
    FindCareUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindCareUserQuery, FindCareUserQueryVariables>(
    FindCareUserDocument,
    options,
  );
}
export type FindCareUserQueryHookResult = ReturnType<typeof useFindCareUserQuery>;
export type FindCareUserLazyQueryHookResult = ReturnType<typeof useFindCareUserLazyQuery>;
export type FindCareUserQueryResult = ApolloReactCommon.QueryResult<
  FindCareUserQuery,
  FindCareUserQueryVariables
>;
export const FindCareStartDocument = gql`
  query findCareStart($questionnaireKey: String!) {
    findCareStart(questionnaireKey: $questionnaireKey) {
      questionnaire {
        description
        title
        key
        questions {
          key
          text
          subText
          overrideDescription
          options {
            key
            text
            requiresManualInput
          }
          type
          visible
        }
        description
      }
      onDemandEnabled
      connectNowEnabled
    }
  }
`;

/**
 * __useFindCareStartQuery__
 *
 * To run a query within a React component, call `useFindCareStartQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCareStartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCareStartQuery({
 *   variables: {
 *      questionnaireKey: // value for 'questionnaireKey'
 *   },
 * });
 */
export function useFindCareStartQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<FindCareStartQuery, FindCareStartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindCareStartQuery, FindCareStartQueryVariables>(
    FindCareStartDocument,
    options,
  );
}
export function useFindCareStartLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindCareStartQuery,
    FindCareStartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindCareStartQuery, FindCareStartQueryVariables>(
    FindCareStartDocument,
    options,
  );
}
export type FindCareStartQueryHookResult = ReturnType<typeof useFindCareStartQuery>;
export type FindCareStartLazyQueryHookResult = ReturnType<typeof useFindCareStartLazyQuery>;
export type FindCareStartQueryResult = ApolloReactCommon.QueryResult<
  FindCareStartQuery,
  FindCareStartQueryVariables
>;
export const FindCareUserHasCompletedDocument = gql`
  query findCareUserHasCompleted {
    findCareUserHasCompleted
  }
`;

/**
 * __useFindCareUserHasCompletedQuery__
 *
 * To run a query within a React component, call `useFindCareUserHasCompletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCareUserHasCompletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCareUserHasCompletedQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindCareUserHasCompletedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindCareUserHasCompletedQuery,
    FindCareUserHasCompletedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindCareUserHasCompletedQuery,
    FindCareUserHasCompletedQueryVariables
  >(FindCareUserHasCompletedDocument, options);
}
export function useFindCareUserHasCompletedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindCareUserHasCompletedQuery,
    FindCareUserHasCompletedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindCareUserHasCompletedQuery,
    FindCareUserHasCompletedQueryVariables
  >(FindCareUserHasCompletedDocument, options);
}
export type FindCareUserHasCompletedQueryHookResult = ReturnType<
  typeof useFindCareUserHasCompletedQuery
>;
export type FindCareUserHasCompletedLazyQueryHookResult = ReturnType<
  typeof useFindCareUserHasCompletedLazyQuery
>;
export type FindCareUserHasCompletedQueryResult = ApolloReactCommon.QueryResult<
  FindCareUserHasCompletedQuery,
  FindCareUserHasCompletedQueryVariables
>;
export const GetCareRecommendationStatusForUserDocument = gql`
  query getCareRecommendationStatusForUser {
    getCareRecommendationStatusForUser {
      activeRecommendation
      expiration
      isActiveOrOnboardingInCare
      isActiveOrOnboardingInTherapy
    }
  }
`;

/**
 * __useGetCareRecommendationStatusForUserQuery__
 *
 * To run a query within a React component, call `useGetCareRecommendationStatusForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareRecommendationStatusForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareRecommendationStatusForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCareRecommendationStatusForUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCareRecommendationStatusForUserQuery,
    GetCareRecommendationStatusForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetCareRecommendationStatusForUserQuery,
    GetCareRecommendationStatusForUserQueryVariables
  >(GetCareRecommendationStatusForUserDocument, options);
}
export function useGetCareRecommendationStatusForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCareRecommendationStatusForUserQuery,
    GetCareRecommendationStatusForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCareRecommendationStatusForUserQuery,
    GetCareRecommendationStatusForUserQueryVariables
  >(GetCareRecommendationStatusForUserDocument, options);
}
export type GetCareRecommendationStatusForUserQueryHookResult = ReturnType<
  typeof useGetCareRecommendationStatusForUserQuery
>;
export type GetCareRecommendationStatusForUserLazyQueryHookResult = ReturnType<
  typeof useGetCareRecommendationStatusForUserLazyQuery
>;
export type GetCareRecommendationStatusForUserQueryResult = ApolloReactCommon.QueryResult<
  GetCareRecommendationStatusForUserQuery,
  GetCareRecommendationStatusForUserQueryVariables
>;
export const UserDemographicsDocument = gql`
  query UserDemographics {
    userDemographics {
      genderIdentity
      ethnicity
      isInternationalStudent
      sexualOrientation
      academicProgram
      yearInProgram
    }
  }
`;

/**
 * __useUserDemographicsQuery__
 *
 * To run a query within a React component, call `useUserDemographicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDemographicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDemographicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDemographicsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserDemographicsQuery,
    UserDemographicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserDemographicsQuery, UserDemographicsQueryVariables>(
    UserDemographicsDocument,
    options,
  );
}
export function useUserDemographicsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserDemographicsQuery,
    UserDemographicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserDemographicsQuery, UserDemographicsQueryVariables>(
    UserDemographicsDocument,
    options,
  );
}
export type UserDemographicsQueryHookResult = ReturnType<typeof useUserDemographicsQuery>;
export type UserDemographicsLazyQueryHookResult = ReturnType<typeof useUserDemographicsLazyQuery>;
export type UserDemographicsQueryResult = ApolloReactCommon.QueryResult<
  UserDemographicsQuery,
  UserDemographicsQueryVariables
>;
export const SaveUserDemographicsDocument = gql`
  mutation saveUserDemographics($input: CreateUserDemographicsInput!) {
    saveUserDemographics(input: $input)
  }
`;
export type SaveUserDemographicsMutationFn = ApolloReactCommon.MutationFunction<
  SaveUserDemographicsMutation,
  SaveUserDemographicsMutationVariables
>;

/**
 * __useSaveUserDemographicsMutation__
 *
 * To run a mutation, you first call `useSaveUserDemographicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserDemographicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserDemographicsMutation, { data, loading, error }] = useSaveUserDemographicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserDemographicsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveUserDemographicsMutation,
    SaveUserDemographicsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveUserDemographicsMutation,
    SaveUserDemographicsMutationVariables
  >(SaveUserDemographicsDocument, options);
}
export type SaveUserDemographicsMutationHookResult = ReturnType<
  typeof useSaveUserDemographicsMutation
>;
export type SaveUserDemographicsMutationResult =
  ApolloReactCommon.MutationResult<SaveUserDemographicsMutation>;
export type SaveUserDemographicsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveUserDemographicsMutation,
  SaveUserDemographicsMutationVariables
>;
export const FindCareFinishDocument = gql`
  mutation findCareFinish($submissions: [HubAssessmentSubmission!]!) {
    findCareFinish(submissions: $submissions)
  }
`;
export type FindCareFinishMutationFn = ApolloReactCommon.MutationFunction<
  FindCareFinishMutation,
  FindCareFinishMutationVariables
>;

/**
 * __useFindCareFinishMutation__
 *
 * To run a mutation, you first call `useFindCareFinishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindCareFinishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findCareFinishMutation, { data, loading, error }] = useFindCareFinishMutation({
 *   variables: {
 *      submissions: // value for 'submissions'
 *   },
 * });
 */
export function useFindCareFinishMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FindCareFinishMutation,
    FindCareFinishMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<FindCareFinishMutation, FindCareFinishMutationVariables>(
    FindCareFinishDocument,
    options,
  );
}
export type FindCareFinishMutationHookResult = ReturnType<typeof useFindCareFinishMutation>;
export type FindCareFinishMutationResult = ApolloReactCommon.MutationResult<FindCareFinishMutation>;
export type FindCareFinishMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FindCareFinishMutation,
  FindCareFinishMutationVariables
>;
export const ConsumeTherapyRecommendationDocument = gql`
  mutation consumeTherapyRecommendation {
    consumeTherapyRecommendation
  }
`;
export type ConsumeTherapyRecommendationMutationFn = ApolloReactCommon.MutationFunction<
  ConsumeTherapyRecommendationMutation,
  ConsumeTherapyRecommendationMutationVariables
>;

/**
 * __useConsumeTherapyRecommendationMutation__
 *
 * To run a mutation, you first call `useConsumeTherapyRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumeTherapyRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumeTherapyRecommendationMutation, { data, loading, error }] = useConsumeTherapyRecommendationMutation({
 *   variables: {
 *   },
 * });
 */
export function useConsumeTherapyRecommendationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConsumeTherapyRecommendationMutation,
    ConsumeTherapyRecommendationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ConsumeTherapyRecommendationMutation,
    ConsumeTherapyRecommendationMutationVariables
  >(ConsumeTherapyRecommendationDocument, options);
}
export type ConsumeTherapyRecommendationMutationHookResult = ReturnType<
  typeof useConsumeTherapyRecommendationMutation
>;
export type ConsumeTherapyRecommendationMutationResult =
  ApolloReactCommon.MutationResult<ConsumeTherapyRecommendationMutation>;
export type ConsumeTherapyRecommendationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConsumeTherapyRecommendationMutation,
  ConsumeTherapyRecommendationMutationVariables
>;
export const CreateHubFeedbackDocument = gql`
  mutation createHubFeedback($feedback: String!, $url: String!) {
    createHubFeedback(feedback: $feedback, url: $url)
  }
`;
export type CreateHubFeedbackMutationFn = ApolloReactCommon.MutationFunction<
  CreateHubFeedbackMutation,
  CreateHubFeedbackMutationVariables
>;

/**
 * __useCreateHubFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateHubFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHubFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHubFeedbackMutation, { data, loading, error }] = useCreateHubFeedbackMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateHubFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateHubFeedbackMutation,
    CreateHubFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateHubFeedbackMutation,
    CreateHubFeedbackMutationVariables
  >(CreateHubFeedbackDocument, options);
}
export type CreateHubFeedbackMutationHookResult = ReturnType<typeof useCreateHubFeedbackMutation>;
export type CreateHubFeedbackMutationResult =
  ApolloReactCommon.MutationResult<CreateHubFeedbackMutation>;
export type CreateHubFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateHubFeedbackMutation,
  CreateHubFeedbackMutationVariables
>;
export const UnreadHubExternalMessagesDocument = gql`
  query unreadHubExternalMessages {
    unreadHubExternalMessages {
      totalCount
      mrn
    }
  }
`;

/**
 * __useUnreadHubExternalMessagesQuery__
 *
 * To run a query within a React component, call `useUnreadHubExternalMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadHubExternalMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadHubExternalMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadHubExternalMessagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UnreadHubExternalMessagesQuery,
    UnreadHubExternalMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UnreadHubExternalMessagesQuery,
    UnreadHubExternalMessagesQueryVariables
  >(UnreadHubExternalMessagesDocument, options);
}
export function useUnreadHubExternalMessagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UnreadHubExternalMessagesQuery,
    UnreadHubExternalMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UnreadHubExternalMessagesQuery,
    UnreadHubExternalMessagesQueryVariables
  >(UnreadHubExternalMessagesDocument, options);
}
export type UnreadHubExternalMessagesQueryHookResult = ReturnType<
  typeof useUnreadHubExternalMessagesQuery
>;
export type UnreadHubExternalMessagesLazyQueryHookResult = ReturnType<
  typeof useUnreadHubExternalMessagesLazyQuery
>;
export type UnreadHubExternalMessagesQueryResult = ApolloReactCommon.QueryResult<
  UnreadHubExternalMessagesQuery,
  UnreadHubExternalMessagesQueryVariables
>;
export const OrganizationOnDemandDocument = gql`
  query organizationOnDemand {
    organizationOnDemand {
      onDemandEnabled
      onDemandData {
        crisisCallPhoneNumber
        crisisSMS
        crisisWebChat
      }
    }
  }
`;

/**
 * __useOrganizationOnDemandQuery__
 *
 * To run a query within a React component, call `useOrganizationOnDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationOnDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationOnDemandQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationOnDemandQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationOnDemandQuery,
    OrganizationOnDemandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationOnDemandQuery, OrganizationOnDemandQueryVariables>(
    OrganizationOnDemandDocument,
    options,
  );
}
export function useOrganizationOnDemandLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationOnDemandQuery,
    OrganizationOnDemandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationOnDemandQuery,
    OrganizationOnDemandQueryVariables
  >(OrganizationOnDemandDocument, options);
}
export type OrganizationOnDemandQueryHookResult = ReturnType<typeof useOrganizationOnDemandQuery>;
export type OrganizationOnDemandLazyQueryHookResult = ReturnType<
  typeof useOrganizationOnDemandLazyQuery
>;
export type OrganizationOnDemandQueryResult = ApolloReactCommon.QueryResult<
  OrganizationOnDemandQuery,
  OrganizationOnDemandQueryVariables
>;
export const IsOnDemandServiceAvailableDocument = gql`
  query isOnDemandServiceAvailable {
    isOnDemandServiceAvailable
  }
`;

/**
 * __useIsOnDemandServiceAvailableQuery__
 *
 * To run a query within a React component, call `useIsOnDemandServiceAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOnDemandServiceAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOnDemandServiceAvailableQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsOnDemandServiceAvailableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IsOnDemandServiceAvailableQuery,
    IsOnDemandServiceAvailableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IsOnDemandServiceAvailableQuery,
    IsOnDemandServiceAvailableQueryVariables
  >(IsOnDemandServiceAvailableDocument, options);
}
export function useIsOnDemandServiceAvailableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IsOnDemandServiceAvailableQuery,
    IsOnDemandServiceAvailableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IsOnDemandServiceAvailableQuery,
    IsOnDemandServiceAvailableQueryVariables
  >(IsOnDemandServiceAvailableDocument, options);
}
export type IsOnDemandServiceAvailableQueryHookResult = ReturnType<
  typeof useIsOnDemandServiceAvailableQuery
>;
export type IsOnDemandServiceAvailableLazyQueryHookResult = ReturnType<
  typeof useIsOnDemandServiceAvailableLazyQuery
>;
export type IsOnDemandServiceAvailableQueryResult = ApolloReactCommon.QueryResult<
  IsOnDemandServiceAvailableQuery,
  IsOnDemandServiceAvailableQueryVariables
>;
export const CreateOnDemandEncounterDocument = gql`
  mutation createOnDemandEncounter($data: OnDemandEncounterInput!) {
    createOnDemandEncounter(data: $data)
  }
`;
export type CreateOnDemandEncounterMutationFn = ApolloReactCommon.MutationFunction<
  CreateOnDemandEncounterMutation,
  CreateOnDemandEncounterMutationVariables
>;

/**
 * __useCreateOnDemandEncounterMutation__
 *
 * To run a mutation, you first call `useCreateOnDemandEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnDemandEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnDemandEncounterMutation, { data, loading, error }] = useCreateOnDemandEncounterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOnDemandEncounterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOnDemandEncounterMutation,
    CreateOnDemandEncounterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOnDemandEncounterMutation,
    CreateOnDemandEncounterMutationVariables
  >(CreateOnDemandEncounterDocument, options);
}
export type CreateOnDemandEncounterMutationHookResult = ReturnType<
  typeof useCreateOnDemandEncounterMutation
>;
export type CreateOnDemandEncounterMutationResult =
  ApolloReactCommon.MutationResult<CreateOnDemandEncounterMutation>;
export type CreateOnDemandEncounterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOnDemandEncounterMutation,
  CreateOnDemandEncounterMutationVariables
>;
export const GetPortalsDocument = gql`
  query getPortals {
    getPortals
  }
`;

/**
 * __useGetPortalsQuery__
 *
 * To run a query within a React component, call `useGetPortalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPortalsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPortalsQuery, GetPortalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPortalsQuery, GetPortalsQueryVariables>(
    GetPortalsDocument,
    options,
  );
}
export function useGetPortalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPortalsQuery, GetPortalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPortalsQuery, GetPortalsQueryVariables>(
    GetPortalsDocument,
    options,
  );
}
export type GetPortalsQueryHookResult = ReturnType<typeof useGetPortalsQuery>;
export type GetPortalsLazyQueryHookResult = ReturnType<typeof useGetPortalsLazyQuery>;
export type GetPortalsQueryResult = ApolloReactCommon.QueryResult<
  GetPortalsQuery,
  GetPortalsQueryVariables
>;
export const PortalUpdatesDocument = gql`
  subscription portalUpdates {
    portalUpdates
  }
`;

/**
 * __usePortalUpdatesSubscription__
 *
 * To run a query within a React component, call `usePortalUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePortalUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortalUpdatesSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePortalUpdatesSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    PortalUpdatesSubscription,
    PortalUpdatesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSubscription<
    PortalUpdatesSubscription,
    PortalUpdatesSubscriptionVariables
  >(PortalUpdatesDocument, options);
}
export type PortalUpdatesSubscriptionHookResult = ReturnType<typeof usePortalUpdatesSubscription>;
export type PortalUpdatesSubscriptionResult =
  ApolloReactCommon.SubscriptionResult<PortalUpdatesSubscription>;
export const RegisterDeviceDocument = gql`
  mutation registerDevice($firebaseToken: String!, $platform: String!, $applicationName: String!) {
    registerDevice(
      firebaseToken: $firebaseToken
      platform: $platform
      applicationName: $applicationName
    )
  }
`;
export type RegisterDeviceMutationFn = ApolloReactCommon.MutationFunction<
  RegisterDeviceMutation,
  RegisterDeviceMutationVariables
>;

/**
 * __useRegisterDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDeviceMutation, { data, loading, error }] = useRegisterDeviceMutation({
 *   variables: {
 *      firebaseToken: // value for 'firebaseToken'
 *      platform: // value for 'platform'
 *      applicationName: // value for 'applicationName'
 *   },
 * });
 */
export function useRegisterDeviceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterDeviceMutation,
    RegisterDeviceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RegisterDeviceMutation, RegisterDeviceMutationVariables>(
    RegisterDeviceDocument,
    options,
  );
}
export type RegisterDeviceMutationHookResult = ReturnType<typeof useRegisterDeviceMutation>;
export type RegisterDeviceMutationResult = ApolloReactCommon.MutationResult<RegisterDeviceMutation>;
export type RegisterDeviceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RegisterDeviceMutation,
  RegisterDeviceMutationVariables
>;
export const DisableDeviceDocument = gql`
  mutation disableDevice($firebaseToken: String!) {
    disableDevice(firebaseToken: $firebaseToken)
  }
`;
export type DisableDeviceMutationFn = ApolloReactCommon.MutationFunction<
  DisableDeviceMutation,
  DisableDeviceMutationVariables
>;

/**
 * __useDisableDeviceMutation__
 *
 * To run a mutation, you first call `useDisableDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableDeviceMutation, { data, loading, error }] = useDisableDeviceMutation({
 *   variables: {
 *      firebaseToken: // value for 'firebaseToken'
 *   },
 * });
 */
export function useDisableDeviceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DisableDeviceMutation,
    DisableDeviceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DisableDeviceMutation, DisableDeviceMutationVariables>(
    DisableDeviceDocument,
    options,
  );
}
export type DisableDeviceMutationHookResult = ReturnType<typeof useDisableDeviceMutation>;
export type DisableDeviceMutationResult = ApolloReactCommon.MutationResult<DisableDeviceMutation>;
export type DisableDeviceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DisableDeviceMutation,
  DisableDeviceMutationVariables
>;
export const TrackPushOpenDocument = gql`
  mutation TrackPushOpen($messageId: String!, $createdAt: Float) {
    trackPushOpen(messageId: $messageId, createdAt: $createdAt)
  }
`;
export type TrackPushOpenMutationFn = ApolloReactCommon.MutationFunction<
  TrackPushOpenMutation,
  TrackPushOpenMutationVariables
>;

/**
 * __useTrackPushOpenMutation__
 *
 * To run a mutation, you first call `useTrackPushOpenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackPushOpenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackPushOpenMutation, { data, loading, error }] = useTrackPushOpenMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useTrackPushOpenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TrackPushOpenMutation,
    TrackPushOpenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TrackPushOpenMutation, TrackPushOpenMutationVariables>(
    TrackPushOpenDocument,
    options,
  );
}
export type TrackPushOpenMutationHookResult = ReturnType<typeof useTrackPushOpenMutation>;
export type TrackPushOpenMutationResult = ApolloReactCommon.MutationResult<TrackPushOpenMutation>;
export type TrackPushOpenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TrackPushOpenMutation,
  TrackPushOpenMutationVariables
>;
export const CareRecommendationGetForUserDocument = gql`
  query careRecommendationGetForUser {
    careRecommendationGetForUser {
      careRecommendation
      onDemandEnabled
      connectNowEnabled
      togetherAllEnabled
      isUserDeactivated
    }
  }
`;

/**
 * __useCareRecommendationGetForUserQuery__
 *
 * To run a query within a React component, call `useCareRecommendationGetForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareRecommendationGetForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareRecommendationGetForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareRecommendationGetForUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CareRecommendationGetForUserQuery,
    CareRecommendationGetForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CareRecommendationGetForUserQuery,
    CareRecommendationGetForUserQueryVariables
  >(CareRecommendationGetForUserDocument, options);
}
export function useCareRecommendationGetForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CareRecommendationGetForUserQuery,
    CareRecommendationGetForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CareRecommendationGetForUserQuery,
    CareRecommendationGetForUserQueryVariables
  >(CareRecommendationGetForUserDocument, options);
}
export type CareRecommendationGetForUserQueryHookResult = ReturnType<
  typeof useCareRecommendationGetForUserQuery
>;
export type CareRecommendationGetForUserLazyQueryHookResult = ReturnType<
  typeof useCareRecommendationGetForUserLazyQuery
>;
export type CareRecommendationGetForUserQueryResult = ApolloReactCommon.QueryResult<
  CareRecommendationGetForUserQuery,
  CareRecommendationGetForUserQueryVariables
>;
export const CustomClinicalRecConfigGetForUserDocument = gql`
  query customClinicalRecConfigGetForUser {
    customClinicalRecConfigGetForUser {
      customClinicalRecTitle
      customClinicalRecDescription
      customClinicalRecButtonText
      customClinicalRecButtonLink
    }
  }
`;

/**
 * __useCustomClinicalRecConfigGetForUserQuery__
 *
 * To run a query within a React component, call `useCustomClinicalRecConfigGetForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomClinicalRecConfigGetForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomClinicalRecConfigGetForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomClinicalRecConfigGetForUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomClinicalRecConfigGetForUserQuery,
    CustomClinicalRecConfigGetForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomClinicalRecConfigGetForUserQuery,
    CustomClinicalRecConfigGetForUserQueryVariables
  >(CustomClinicalRecConfigGetForUserDocument, options);
}
export function useCustomClinicalRecConfigGetForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomClinicalRecConfigGetForUserQuery,
    CustomClinicalRecConfigGetForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomClinicalRecConfigGetForUserQuery,
    CustomClinicalRecConfigGetForUserQueryVariables
  >(CustomClinicalRecConfigGetForUserDocument, options);
}
export type CustomClinicalRecConfigGetForUserQueryHookResult = ReturnType<
  typeof useCustomClinicalRecConfigGetForUserQuery
>;
export type CustomClinicalRecConfigGetForUserLazyQueryHookResult = ReturnType<
  typeof useCustomClinicalRecConfigGetForUserLazyQuery
>;
export type CustomClinicalRecConfigGetForUserQueryResult = ApolloReactCommon.QueryResult<
  CustomClinicalRecConfigGetForUserQuery,
  CustomClinicalRecConfigGetForUserQueryVariables
>;
export const StudentHubReferralDocument = gql`
  mutation studentHubReferral($email: String!) {
    studentHubReferral(email: $email) {
      __typename
      ... on StudentHubReferralSuccess {
        successMessage
      }
      ... on ReferralError {
        errorCode
      }
    }
  }
`;
export type StudentHubReferralMutationFn = ApolloReactCommon.MutationFunction<
  StudentHubReferralMutation,
  StudentHubReferralMutationVariables
>;

/**
 * __useStudentHubReferralMutation__
 *
 * To run a mutation, you first call `useStudentHubReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentHubReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentHubReferralMutation, { data, loading, error }] = useStudentHubReferralMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useStudentHubReferralMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StudentHubReferralMutation,
    StudentHubReferralMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StudentHubReferralMutation,
    StudentHubReferralMutationVariables
  >(StudentHubReferralDocument, options);
}
export type StudentHubReferralMutationHookResult = ReturnType<typeof useStudentHubReferralMutation>;
export type StudentHubReferralMutationResult =
  ApolloReactCommon.MutationResult<StudentHubReferralMutation>;
export type StudentHubReferralMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StudentHubReferralMutation,
  StudentHubReferralMutationVariables
>;
export const GetSkillAssignmentCountsForUserDocument = gql`
  query getSkillAssignmentCountsForUser {
    getSkillAssignmentCountsForUser {
      notSeen
      notCompleted
    }
  }
`;

/**
 * __useGetSkillAssignmentCountsForUserQuery__
 *
 * To run a query within a React component, call `useGetSkillAssignmentCountsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillAssignmentCountsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillAssignmentCountsForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSkillAssignmentCountsForUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSkillAssignmentCountsForUserQuery,
    GetSkillAssignmentCountsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSkillAssignmentCountsForUserQuery,
    GetSkillAssignmentCountsForUserQueryVariables
  >(GetSkillAssignmentCountsForUserDocument, options);
}
export function useGetSkillAssignmentCountsForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSkillAssignmentCountsForUserQuery,
    GetSkillAssignmentCountsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSkillAssignmentCountsForUserQuery,
    GetSkillAssignmentCountsForUserQueryVariables
  >(GetSkillAssignmentCountsForUserDocument, options);
}
export type GetSkillAssignmentCountsForUserQueryHookResult = ReturnType<
  typeof useGetSkillAssignmentCountsForUserQuery
>;
export type GetSkillAssignmentCountsForUserLazyQueryHookResult = ReturnType<
  typeof useGetSkillAssignmentCountsForUserLazyQuery
>;
export type GetSkillAssignmentCountsForUserQueryResult = ApolloReactCommon.QueryResult<
  GetSkillAssignmentCountsForUserQuery,
  GetSkillAssignmentCountsForUserQueryVariables
>;
export const GetMySkillsAssignmentsDocument = gql`
  query getMySkillsAssignments($page: Float!, $perPage: Float!) {
    getMySkillsAssignments(page: $page, perPage: $perPage) {
      pagination {
        page
        totalPages
      }
      skillAssignments {
        skillId
        userHasSeen
        assignerHasSeen
        startedAt
        completedAt
        assignedById
        moduleId
      }
    }
  }
`;

/**
 * __useGetMySkillsAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetMySkillsAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySkillsAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySkillsAssignmentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetMySkillsAssignmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetMySkillsAssignmentsQuery,
    GetMySkillsAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetMySkillsAssignmentsQuery,
    GetMySkillsAssignmentsQueryVariables
  >(GetMySkillsAssignmentsDocument, options);
}
export function useGetMySkillsAssignmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMySkillsAssignmentsQuery,
    GetMySkillsAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetMySkillsAssignmentsQuery,
    GetMySkillsAssignmentsQueryVariables
  >(GetMySkillsAssignmentsDocument, options);
}
export type GetMySkillsAssignmentsQueryHookResult = ReturnType<
  typeof useGetMySkillsAssignmentsQuery
>;
export type GetMySkillsAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetMySkillsAssignmentsLazyQuery
>;
export type GetMySkillsAssignmentsQueryResult = ApolloReactCommon.QueryResult<
  GetMySkillsAssignmentsQuery,
  GetMySkillsAssignmentsQueryVariables
>;
export const IsSkillCompleteDocument = gql`
  query isSkillComplete($skillId: String!, $moduleId: String!) {
    isSkillComplete(input: { skillId: $skillId, moduleId: $moduleId })
  }
`;

/**
 * __useIsSkillCompleteQuery__
 *
 * To run a query within a React component, call `useIsSkillCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSkillCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSkillCompleteQuery({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useIsSkillCompleteQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IsSkillCompleteQuery,
    IsSkillCompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IsSkillCompleteQuery, IsSkillCompleteQueryVariables>(
    IsSkillCompleteDocument,
    options,
  );
}
export function useIsSkillCompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IsSkillCompleteQuery,
    IsSkillCompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IsSkillCompleteQuery, IsSkillCompleteQueryVariables>(
    IsSkillCompleteDocument,
    options,
  );
}
export type IsSkillCompleteQueryHookResult = ReturnType<typeof useIsSkillCompleteQuery>;
export type IsSkillCompleteLazyQueryHookResult = ReturnType<typeof useIsSkillCompleteLazyQuery>;
export type IsSkillCompleteQueryResult = ApolloReactCommon.QueryResult<
  IsSkillCompleteQuery,
  IsSkillCompleteQueryVariables
>;
export const GetAllCompletedSkillsForModuleDocument = gql`
  query getAllCompletedSkillsForModule($moduleId: String!) {
    getAllCompletedSkillsForModule(moduleId: $moduleId)
  }
`;

/**
 * __useGetAllCompletedSkillsForModuleQuery__
 *
 * To run a query within a React component, call `useGetAllCompletedSkillsForModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompletedSkillsForModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompletedSkillsForModuleQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetAllCompletedSkillsForModuleQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllCompletedSkillsForModuleQuery,
    GetAllCompletedSkillsForModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAllCompletedSkillsForModuleQuery,
    GetAllCompletedSkillsForModuleQueryVariables
  >(GetAllCompletedSkillsForModuleDocument, options);
}
export function useGetAllCompletedSkillsForModuleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllCompletedSkillsForModuleQuery,
    GetAllCompletedSkillsForModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAllCompletedSkillsForModuleQuery,
    GetAllCompletedSkillsForModuleQueryVariables
  >(GetAllCompletedSkillsForModuleDocument, options);
}
export type GetAllCompletedSkillsForModuleQueryHookResult = ReturnType<
  typeof useGetAllCompletedSkillsForModuleQuery
>;
export type GetAllCompletedSkillsForModuleLazyQueryHookResult = ReturnType<
  typeof useGetAllCompletedSkillsForModuleLazyQuery
>;
export type GetAllCompletedSkillsForModuleQueryResult = ApolloReactCommon.QueryResult<
  GetAllCompletedSkillsForModuleQuery,
  GetAllCompletedSkillsForModuleQueryVariables
>;
export const GetUpNextSkillDocument = gql`
  query getUpNextSkill($skillId: String!, $moduleId: String!, $isPathway: Boolean = false) {
    getUpNextSkill(skillId: $skillId, moduleId: $moduleId, isPathway: $isPathway) {
      moduleWillBeComplete
      upNextSkill
      upNextSkillIsComplete
    }
  }
`;

/**
 * __useGetUpNextSkillQuery__
 *
 * To run a query within a React component, call `useGetUpNextSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpNextSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpNextSkillQuery({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      moduleId: // value for 'moduleId'
 *      isPathway: // value for 'isPathway'
 *   },
 * });
 */
export function useGetUpNextSkillQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetUpNextSkillQuery, GetUpNextSkillQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUpNextSkillQuery, GetUpNextSkillQueryVariables>(
    GetUpNextSkillDocument,
    options,
  );
}
export function useGetUpNextSkillLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUpNextSkillQuery,
    GetUpNextSkillQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetUpNextSkillQuery, GetUpNextSkillQueryVariables>(
    GetUpNextSkillDocument,
    options,
  );
}
export type GetUpNextSkillQueryHookResult = ReturnType<typeof useGetUpNextSkillQuery>;
export type GetUpNextSkillLazyQueryHookResult = ReturnType<typeof useGetUpNextSkillLazyQuery>;
export type GetUpNextSkillQueryResult = ApolloReactCommon.QueryResult<
  GetUpNextSkillQuery,
  GetUpNextSkillQueryVariables
>;
export const GetUpNextSkillForModuleDocument = gql`
  query getUpNextSkillForModule($moduleId: String!) {
    getUpNextSkillForModule(moduleId: $moduleId) {
      moduleIsComplete
      upNextSkill
    }
  }
`;

/**
 * __useGetUpNextSkillForModuleQuery__
 *
 * To run a query within a React component, call `useGetUpNextSkillForModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpNextSkillForModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpNextSkillForModuleQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetUpNextSkillForModuleQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUpNextSkillForModuleQuery,
    GetUpNextSkillForModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetUpNextSkillForModuleQuery,
    GetUpNextSkillForModuleQueryVariables
  >(GetUpNextSkillForModuleDocument, options);
}
export function useGetUpNextSkillForModuleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUpNextSkillForModuleQuery,
    GetUpNextSkillForModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetUpNextSkillForModuleQuery,
    GetUpNextSkillForModuleQueryVariables
  >(GetUpNextSkillForModuleDocument, options);
}
export type GetUpNextSkillForModuleQueryHookResult = ReturnType<
  typeof useGetUpNextSkillForModuleQuery
>;
export type GetUpNextSkillForModuleLazyQueryHookResult = ReturnType<
  typeof useGetUpNextSkillForModuleLazyQuery
>;
export type GetUpNextSkillForModuleQueryResult = ApolloReactCommon.QueryResult<
  GetUpNextSkillForModuleQuery,
  GetUpNextSkillForModuleQueryVariables
>;
export const HasCompletedAnySkillDocument = gql`
  query hasCompletedAnySkill {
    hasCompletedAnySkill
  }
`;

/**
 * __useHasCompletedAnySkillQuery__
 *
 * To run a query within a React component, call `useHasCompletedAnySkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasCompletedAnySkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasCompletedAnySkillQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasCompletedAnySkillQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HasCompletedAnySkillQuery,
    HasCompletedAnySkillQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<HasCompletedAnySkillQuery, HasCompletedAnySkillQueryVariables>(
    HasCompletedAnySkillDocument,
    options,
  );
}
export function useHasCompletedAnySkillLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HasCompletedAnySkillQuery,
    HasCompletedAnySkillQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    HasCompletedAnySkillQuery,
    HasCompletedAnySkillQueryVariables
  >(HasCompletedAnySkillDocument, options);
}
export type HasCompletedAnySkillQueryHookResult = ReturnType<typeof useHasCompletedAnySkillQuery>;
export type HasCompletedAnySkillLazyQueryHookResult = ReturnType<
  typeof useHasCompletedAnySkillLazyQuery
>;
export type HasCompletedAnySkillQueryResult = ApolloReactCommon.QueryResult<
  HasCompletedAnySkillQuery,
  HasCompletedAnySkillQueryVariables
>;
export const GetJumpBackInSkillsDocument = gql`
  query getJumpBackInSkills {
    getJumpBackInSkills {
      skill
      module
    }
  }
`;

/**
 * __useGetJumpBackInSkillsQuery__
 *
 * To run a query within a React component, call `useGetJumpBackInSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJumpBackInSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJumpBackInSkillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJumpBackInSkillsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetJumpBackInSkillsQuery,
    GetJumpBackInSkillsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetJumpBackInSkillsQuery, GetJumpBackInSkillsQueryVariables>(
    GetJumpBackInSkillsDocument,
    options,
  );
}
export function useGetJumpBackInSkillsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetJumpBackInSkillsQuery,
    GetJumpBackInSkillsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetJumpBackInSkillsQuery, GetJumpBackInSkillsQueryVariables>(
    GetJumpBackInSkillsDocument,
    options,
  );
}
export type GetJumpBackInSkillsQueryHookResult = ReturnType<typeof useGetJumpBackInSkillsQuery>;
export type GetJumpBackInSkillsLazyQueryHookResult = ReturnType<
  typeof useGetJumpBackInSkillsLazyQuery
>;
export type GetJumpBackInSkillsQueryResult = ApolloReactCommon.QueryResult<
  GetJumpBackInSkillsQuery,
  GetJumpBackInSkillsQueryVariables
>;
export const MarkSkillCompleteDocument = gql`
  mutation markSkillComplete($skillId: String!, $moduleId: String!) {
    markSkillComplete(input: { skillId: $skillId, moduleId: $moduleId })
  }
`;
export type MarkSkillCompleteMutationFn = ApolloReactCommon.MutationFunction<
  MarkSkillCompleteMutation,
  MarkSkillCompleteMutationVariables
>;

/**
 * __useMarkSkillCompleteMutation__
 *
 * To run a mutation, you first call `useMarkSkillCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSkillCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSkillCompleteMutation, { data, loading, error }] = useMarkSkillCompleteMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useMarkSkillCompleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkSkillCompleteMutation,
    MarkSkillCompleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkSkillCompleteMutation,
    MarkSkillCompleteMutationVariables
  >(MarkSkillCompleteDocument, options);
}
export type MarkSkillCompleteMutationHookResult = ReturnType<typeof useMarkSkillCompleteMutation>;
export type MarkSkillCompleteMutationResult =
  ApolloReactCommon.MutationResult<MarkSkillCompleteMutation>;
export type MarkSkillCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkSkillCompleteMutation,
  MarkSkillCompleteMutationVariables
>;
export const MarkSkillStartedDocument = gql`
  mutation markSkillStarted($skillId: String!, $moduleId: String!) {
    markSkillStarted(input: { skillId: $skillId, moduleId: $moduleId })
  }
`;
export type MarkSkillStartedMutationFn = ApolloReactCommon.MutationFunction<
  MarkSkillStartedMutation,
  MarkSkillStartedMutationVariables
>;

/**
 * __useMarkSkillStartedMutation__
 *
 * To run a mutation, you first call `useMarkSkillStartedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSkillStartedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSkillStartedMutation, { data, loading, error }] = useMarkSkillStartedMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useMarkSkillStartedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkSkillStartedMutation,
    MarkSkillStartedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<MarkSkillStartedMutation, MarkSkillStartedMutationVariables>(
    MarkSkillStartedDocument,
    options,
  );
}
export type MarkSkillStartedMutationHookResult = ReturnType<typeof useMarkSkillStartedMutation>;
export type MarkSkillStartedMutationResult =
  ApolloReactCommon.MutationResult<MarkSkillStartedMutation>;
export type MarkSkillStartedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkSkillStartedMutation,
  MarkSkillStartedMutationVariables
>;
export const UpsertUserSkillFeedbackDocument = gql`
  mutation upsertUserSkillFeedback(
    $skillId: String!
    $rating: Float!
    $additionalFeedback: String!
    $questions: [SkillFeedbackQuestionModelInput!]!
  ) {
    upsertUserSkillFeedback(
      skillId: $skillId
      rating: $rating
      additionalFeedback: $additionalFeedback
      questions: $questions
    )
  }
`;
export type UpsertUserSkillFeedbackMutationFn = ApolloReactCommon.MutationFunction<
  UpsertUserSkillFeedbackMutation,
  UpsertUserSkillFeedbackMutationVariables
>;

/**
 * __useUpsertUserSkillFeedbackMutation__
 *
 * To run a mutation, you first call `useUpsertUserSkillFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserSkillFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserSkillFeedbackMutation, { data, loading, error }] = useUpsertUserSkillFeedbackMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      rating: // value for 'rating'
 *      additionalFeedback: // value for 'additionalFeedback'
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useUpsertUserSkillFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertUserSkillFeedbackMutation,
    UpsertUserSkillFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpsertUserSkillFeedbackMutation,
    UpsertUserSkillFeedbackMutationVariables
  >(UpsertUserSkillFeedbackDocument, options);
}
export type UpsertUserSkillFeedbackMutationHookResult = ReturnType<
  typeof useUpsertUserSkillFeedbackMutation
>;
export type UpsertUserSkillFeedbackMutationResult =
  ApolloReactCommon.MutationResult<UpsertUserSkillFeedbackMutation>;
export type UpsertUserSkillFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertUserSkillFeedbackMutation,
  UpsertUserSkillFeedbackMutationVariables
>;
export const GetUserSkillFeedbackDocument = gql`
  query getUserSkillFeedback($skillId: String!) {
    userSkillFeedback(skillId: $skillId) {
      rating
      additionalFeedback
    }
  }
`;

/**
 * __useGetUserSkillFeedbackQuery__
 *
 * To run a query within a React component, call `useGetUserSkillFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSkillFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSkillFeedbackQuery({
 *   variables: {
 *      skillId: // value for 'skillId'
 *   },
 * });
 */
export function useGetUserSkillFeedbackQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUserSkillFeedbackQuery,
    GetUserSkillFeedbackQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUserSkillFeedbackQuery, GetUserSkillFeedbackQueryVariables>(
    GetUserSkillFeedbackDocument,
    options,
  );
}
export function useGetUserSkillFeedbackLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserSkillFeedbackQuery,
    GetUserSkillFeedbackQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetUserSkillFeedbackQuery,
    GetUserSkillFeedbackQueryVariables
  >(GetUserSkillFeedbackDocument, options);
}
export type GetUserSkillFeedbackQueryHookResult = ReturnType<typeof useGetUserSkillFeedbackQuery>;
export type GetUserSkillFeedbackLazyQueryHookResult = ReturnType<
  typeof useGetUserSkillFeedbackLazyQuery
>;
export type GetUserSkillFeedbackQueryResult = ApolloReactCommon.QueryResult<
  GetUserSkillFeedbackQuery,
  GetUserSkillFeedbackQueryVariables
>;
export const SkillPathwayGetForUserDocument = gql`
  query skillPathwayGetForUser {
    skillPathwayGetForUser {
      moduleId
      sectionId
      score
      nudges {
        name
      }
    }
  }
`;

/**
 * __useSkillPathwayGetForUserQuery__
 *
 * To run a query within a React component, call `useSkillPathwayGetForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillPathwayGetForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillPathwayGetForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillPathwayGetForUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SkillPathwayGetForUserQuery,
    SkillPathwayGetForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SkillPathwayGetForUserQuery,
    SkillPathwayGetForUserQueryVariables
  >(SkillPathwayGetForUserDocument, options);
}
export function useSkillPathwayGetForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SkillPathwayGetForUserQuery,
    SkillPathwayGetForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SkillPathwayGetForUserQuery,
    SkillPathwayGetForUserQueryVariables
  >(SkillPathwayGetForUserDocument, options);
}
export type SkillPathwayGetForUserQueryHookResult = ReturnType<
  typeof useSkillPathwayGetForUserQuery
>;
export type SkillPathwayGetForUserLazyQueryHookResult = ReturnType<
  typeof useSkillPathwayGetForUserLazyQuery
>;
export type SkillPathwayGetForUserQueryResult = ApolloReactCommon.QueryResult<
  SkillPathwayGetForUserQuery,
  SkillPathwayGetForUserQueryVariables
>;
export const SkillPathwayNextSkillDocument = gql`
  query skillPathwayNextSkill {
    skillPathwayNextSkill {
      hasPathway
      nextSkillId
      moduleId
      sectionId
    }
  }
`;

/**
 * __useSkillPathwayNextSkillQuery__
 *
 * To run a query within a React component, call `useSkillPathwayNextSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillPathwayNextSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillPathwayNextSkillQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillPathwayNextSkillQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SkillPathwayNextSkillQuery,
    SkillPathwayNextSkillQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SkillPathwayNextSkillQuery, SkillPathwayNextSkillQueryVariables>(
    SkillPathwayNextSkillDocument,
    options,
  );
}
export function useSkillPathwayNextSkillLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SkillPathwayNextSkillQuery,
    SkillPathwayNextSkillQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SkillPathwayNextSkillQuery,
    SkillPathwayNextSkillQueryVariables
  >(SkillPathwayNextSkillDocument, options);
}
export type SkillPathwayNextSkillQueryHookResult = ReturnType<typeof useSkillPathwayNextSkillQuery>;
export type SkillPathwayNextSkillLazyQueryHookResult = ReturnType<
  typeof useSkillPathwayNextSkillLazyQuery
>;
export type SkillPathwayNextSkillQueryResult = ApolloReactCommon.QueryResult<
  SkillPathwayNextSkillQuery,
  SkillPathwayNextSkillQueryVariables
>;
export const SkillPathwaySubmitDocument = gql`
  mutation skillPathwaySubmit($answers: [SkillPathwayAnswer!]!) {
    skillPathwaySubmit(answers: $answers)
  }
`;
export type SkillPathwaySubmitMutationFn = ApolloReactCommon.MutationFunction<
  SkillPathwaySubmitMutation,
  SkillPathwaySubmitMutationVariables
>;

/**
 * __useSkillPathwaySubmitMutation__
 *
 * To run a mutation, you first call `useSkillPathwaySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkillPathwaySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skillPathwaySubmitMutation, { data, loading, error }] = useSkillPathwaySubmitMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useSkillPathwaySubmitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SkillPathwaySubmitMutation,
    SkillPathwaySubmitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SkillPathwaySubmitMutation,
    SkillPathwaySubmitMutationVariables
  >(SkillPathwaySubmitDocument, options);
}
export type SkillPathwaySubmitMutationHookResult = ReturnType<typeof useSkillPathwaySubmitMutation>;
export type SkillPathwaySubmitMutationResult =
  ApolloReactCommon.MutationResult<SkillPathwaySubmitMutation>;
export type SkillPathwaySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SkillPathwaySubmitMutation,
  SkillPathwaySubmitMutationVariables
>;
export const LoadUnfinishedSkillReflectionDocument = gql`
  query loadUnfinishedSkillReflection($skillId: String!) {
    loadUnfinishedSkillReflection(skillId: $skillId) {
      questions {
        questionKey
        answer
      }
    }
  }
`;

/**
 * __useLoadUnfinishedSkillReflectionQuery__
 *
 * To run a query within a React component, call `useLoadUnfinishedSkillReflectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadUnfinishedSkillReflectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadUnfinishedSkillReflectionQuery({
 *   variables: {
 *      skillId: // value for 'skillId'
 *   },
 * });
 */
export function useLoadUnfinishedSkillReflectionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LoadUnfinishedSkillReflectionQuery,
    LoadUnfinishedSkillReflectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LoadUnfinishedSkillReflectionQuery,
    LoadUnfinishedSkillReflectionQueryVariables
  >(LoadUnfinishedSkillReflectionDocument, options);
}
export function useLoadUnfinishedSkillReflectionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LoadUnfinishedSkillReflectionQuery,
    LoadUnfinishedSkillReflectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LoadUnfinishedSkillReflectionQuery,
    LoadUnfinishedSkillReflectionQueryVariables
  >(LoadUnfinishedSkillReflectionDocument, options);
}
export type LoadUnfinishedSkillReflectionQueryHookResult = ReturnType<
  typeof useLoadUnfinishedSkillReflectionQuery
>;
export type LoadUnfinishedSkillReflectionLazyQueryHookResult = ReturnType<
  typeof useLoadUnfinishedSkillReflectionLazyQuery
>;
export type LoadUnfinishedSkillReflectionQueryResult = ApolloReactCommon.QueryResult<
  LoadUnfinishedSkillReflectionQuery,
  LoadUnfinishedSkillReflectionQueryVariables
>;
export const SkillReflectionGetAllForUserDocument = gql`
  query skillReflectionGetAllForUser($page: Float!, $perPage: Float!) {
    skillReflectionGetAllForUser(page: $page, perPage: $perPage) {
      pagination {
        totalPages
      }
      reflections {
        id
        title
        updatedAt
      }
    }
  }
`;

/**
 * __useSkillReflectionGetAllForUserQuery__
 *
 * To run a query within a React component, call `useSkillReflectionGetAllForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillReflectionGetAllForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillReflectionGetAllForUserQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useSkillReflectionGetAllForUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SkillReflectionGetAllForUserQuery,
    SkillReflectionGetAllForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SkillReflectionGetAllForUserQuery,
    SkillReflectionGetAllForUserQueryVariables
  >(SkillReflectionGetAllForUserDocument, options);
}
export function useSkillReflectionGetAllForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SkillReflectionGetAllForUserQuery,
    SkillReflectionGetAllForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SkillReflectionGetAllForUserQuery,
    SkillReflectionGetAllForUserQueryVariables
  >(SkillReflectionGetAllForUserDocument, options);
}
export type SkillReflectionGetAllForUserQueryHookResult = ReturnType<
  typeof useSkillReflectionGetAllForUserQuery
>;
export type SkillReflectionGetAllForUserLazyQueryHookResult = ReturnType<
  typeof useSkillReflectionGetAllForUserLazyQuery
>;
export type SkillReflectionGetAllForUserQueryResult = ApolloReactCommon.QueryResult<
  SkillReflectionGetAllForUserQuery,
  SkillReflectionGetAllForUserQueryVariables
>;
export const SkillReflectionGetUserAnswersDocument = gql`
  query skillReflectionGetUserAnswers($reflectionId: Float!) {
    skillReflectionGetUserAnswers(reflectionId: $reflectionId) {
      id
      questions {
        questionKey
        question
        answer
      }
      title
      updatedAt
    }
  }
`;

/**
 * __useSkillReflectionGetUserAnswersQuery__
 *
 * To run a query within a React component, call `useSkillReflectionGetUserAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillReflectionGetUserAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillReflectionGetUserAnswersQuery({
 *   variables: {
 *      reflectionId: // value for 'reflectionId'
 *   },
 * });
 */
export function useSkillReflectionGetUserAnswersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SkillReflectionGetUserAnswersQuery,
    SkillReflectionGetUserAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SkillReflectionGetUserAnswersQuery,
    SkillReflectionGetUserAnswersQueryVariables
  >(SkillReflectionGetUserAnswersDocument, options);
}
export function useSkillReflectionGetUserAnswersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SkillReflectionGetUserAnswersQuery,
    SkillReflectionGetUserAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SkillReflectionGetUserAnswersQuery,
    SkillReflectionGetUserAnswersQueryVariables
  >(SkillReflectionGetUserAnswersDocument, options);
}
export type SkillReflectionGetUserAnswersQueryHookResult = ReturnType<
  typeof useSkillReflectionGetUserAnswersQuery
>;
export type SkillReflectionGetUserAnswersLazyQueryHookResult = ReturnType<
  typeof useSkillReflectionGetUserAnswersLazyQuery
>;
export type SkillReflectionGetUserAnswersQueryResult = ApolloReactCommon.QueryResult<
  SkillReflectionGetUserAnswersQuery,
  SkillReflectionGetUserAnswersQueryVariables
>;
export const AnswerSkillReflectionQuestionDocument = gql`
  mutation answerSkillReflectionQuestion(
    $skillId: String!
    $questionKey: String!
    $question: String!
    $answer: String!
  ) {
    answerSkillReflectionQuestion(
      skillId: $skillId
      questionKey: $questionKey
      question: $question
      answer: $answer
    )
  }
`;
export type AnswerSkillReflectionQuestionMutationFn = ApolloReactCommon.MutationFunction<
  AnswerSkillReflectionQuestionMutation,
  AnswerSkillReflectionQuestionMutationVariables
>;

/**
 * __useAnswerSkillReflectionQuestionMutation__
 *
 * To run a mutation, you first call `useAnswerSkillReflectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerSkillReflectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerSkillReflectionQuestionMutation, { data, loading, error }] = useAnswerSkillReflectionQuestionMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      questionKey: // value for 'questionKey'
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useAnswerSkillReflectionQuestionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AnswerSkillReflectionQuestionMutation,
    AnswerSkillReflectionQuestionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AnswerSkillReflectionQuestionMutation,
    AnswerSkillReflectionQuestionMutationVariables
  >(AnswerSkillReflectionQuestionDocument, options);
}
export type AnswerSkillReflectionQuestionMutationHookResult = ReturnType<
  typeof useAnswerSkillReflectionQuestionMutation
>;
export type AnswerSkillReflectionQuestionMutationResult =
  ApolloReactCommon.MutationResult<AnswerSkillReflectionQuestionMutation>;
export type AnswerSkillReflectionQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AnswerSkillReflectionQuestionMutation,
  AnswerSkillReflectionQuestionMutationVariables
>;
export const SkillReflectionDeleteDocument = gql`
  mutation skillReflectionDelete($reflectionId: Float!) {
    skillReflectionDelete(reflectionId: $reflectionId)
  }
`;
export type SkillReflectionDeleteMutationFn = ApolloReactCommon.MutationFunction<
  SkillReflectionDeleteMutation,
  SkillReflectionDeleteMutationVariables
>;

/**
 * __useSkillReflectionDeleteMutation__
 *
 * To run a mutation, you first call `useSkillReflectionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkillReflectionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skillReflectionDeleteMutation, { data, loading, error }] = useSkillReflectionDeleteMutation({
 *   variables: {
 *      reflectionId: // value for 'reflectionId'
 *   },
 * });
 */
export function useSkillReflectionDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SkillReflectionDeleteMutation,
    SkillReflectionDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SkillReflectionDeleteMutation,
    SkillReflectionDeleteMutationVariables
  >(SkillReflectionDeleteDocument, options);
}
export type SkillReflectionDeleteMutationHookResult = ReturnType<
  typeof useSkillReflectionDeleteMutation
>;
export type SkillReflectionDeleteMutationResult =
  ApolloReactCommon.MutationResult<SkillReflectionDeleteMutation>;
export type SkillReflectionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SkillReflectionDeleteMutation,
  SkillReflectionDeleteMutationVariables
>;
export const SkillReflectionEditAnswerDocument = gql`
  mutation skillReflectionEditAnswer(
    $reflectionId: Float!
    $questionKey: String!
    $answer: String!
  ) {
    skillReflectionEditAnswer(
      reflectionId: $reflectionId
      questionKey: $questionKey
      answer: $answer
    )
  }
`;
export type SkillReflectionEditAnswerMutationFn = ApolloReactCommon.MutationFunction<
  SkillReflectionEditAnswerMutation,
  SkillReflectionEditAnswerMutationVariables
>;

/**
 * __useSkillReflectionEditAnswerMutation__
 *
 * To run a mutation, you first call `useSkillReflectionEditAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkillReflectionEditAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skillReflectionEditAnswerMutation, { data, loading, error }] = useSkillReflectionEditAnswerMutation({
 *   variables: {
 *      reflectionId: // value for 'reflectionId'
 *      questionKey: // value for 'questionKey'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useSkillReflectionEditAnswerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SkillReflectionEditAnswerMutation,
    SkillReflectionEditAnswerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SkillReflectionEditAnswerMutation,
    SkillReflectionEditAnswerMutationVariables
  >(SkillReflectionEditAnswerDocument, options);
}
export type SkillReflectionEditAnswerMutationHookResult = ReturnType<
  typeof useSkillReflectionEditAnswerMutation
>;
export type SkillReflectionEditAnswerMutationResult =
  ApolloReactCommon.MutationResult<SkillReflectionEditAnswerMutation>;
export type SkillReflectionEditAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SkillReflectionEditAnswerMutation,
  SkillReflectionEditAnswerMutationVariables
>;
export const FinishSkillReflectionDocument = gql`
  mutation finishSkillReflection($skillId: String!, $discardReflection: Boolean!) {
    finishSkillReflection(skillId: $skillId, discardReflection: $discardReflection) {
      deleted
      saved
    }
  }
`;
export type FinishSkillReflectionMutationFn = ApolloReactCommon.MutationFunction<
  FinishSkillReflectionMutation,
  FinishSkillReflectionMutationVariables
>;

/**
 * __useFinishSkillReflectionMutation__
 *
 * To run a mutation, you first call `useFinishSkillReflectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishSkillReflectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishSkillReflectionMutation, { data, loading, error }] = useFinishSkillReflectionMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      discardReflection: // value for 'discardReflection'
 *   },
 * });
 */
export function useFinishSkillReflectionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FinishSkillReflectionMutation,
    FinishSkillReflectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FinishSkillReflectionMutation,
    FinishSkillReflectionMutationVariables
  >(FinishSkillReflectionDocument, options);
}
export type FinishSkillReflectionMutationHookResult = ReturnType<
  typeof useFinishSkillReflectionMutation
>;
export type FinishSkillReflectionMutationResult =
  ApolloReactCommon.MutationResult<FinishSkillReflectionMutation>;
export type FinishSkillReflectionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FinishSkillReflectionMutation,
  FinishSkillReflectionMutationVariables
>;
export const CurrentUserNameDocument = gql`
  query currentUserName {
    onboardedHubUser {
      preferredName
    }
  }
`;

/**
 * __useCurrentUserNameQuery__
 *
 * To run a query within a React component, call `useCurrentUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserNameQuery,
    CurrentUserNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CurrentUserNameQuery, CurrentUserNameQueryVariables>(
    CurrentUserNameDocument,
    options,
  );
}
export function useCurrentUserNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserNameQuery,
    CurrentUserNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CurrentUserNameQuery, CurrentUserNameQueryVariables>(
    CurrentUserNameDocument,
    options,
  );
}
export type CurrentUserNameQueryHookResult = ReturnType<typeof useCurrentUserNameQuery>;
export type CurrentUserNameLazyQueryHookResult = ReturnType<typeof useCurrentUserNameLazyQuery>;
export type CurrentUserNameQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserNameQuery,
  CurrentUserNameQueryVariables
>;
export const CurrentUserImpersonatedDocument = gql`
  query currentUserImpersonated {
    onboardedHubUser {
      impersonatingId
    }
  }
`;

/**
 * __useCurrentUserImpersonatedQuery__
 *
 * To run a query within a React component, call `useCurrentUserImpersonatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserImpersonatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserImpersonatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserImpersonatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserImpersonatedQuery,
    CurrentUserImpersonatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CurrentUserImpersonatedQuery,
    CurrentUserImpersonatedQueryVariables
  >(CurrentUserImpersonatedDocument, options);
}
export function useCurrentUserImpersonatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserImpersonatedQuery,
    CurrentUserImpersonatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CurrentUserImpersonatedQuery,
    CurrentUserImpersonatedQueryVariables
  >(CurrentUserImpersonatedDocument, options);
}
export type CurrentUserImpersonatedQueryHookResult = ReturnType<
  typeof useCurrentUserImpersonatedQuery
>;
export type CurrentUserImpersonatedLazyQueryHookResult = ReturnType<
  typeof useCurrentUserImpersonatedLazyQuery
>;
export type CurrentUserImpersonatedQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserImpersonatedQuery,
  CurrentUserImpersonatedQueryVariables
>;
export const OnboardingUserDataDocument = gql`
  query onboardingUserData {
    onboardingHubUser {
      preferredName
      birthDate
    }
  }
`;

/**
 * __useOnboardingUserDataQuery__
 *
 * To run a query within a React component, call `useOnboardingUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingUserDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OnboardingUserDataQuery,
    OnboardingUserDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OnboardingUserDataQuery, OnboardingUserDataQueryVariables>(
    OnboardingUserDataDocument,
    options,
  );
}
export function useOnboardingUserDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OnboardingUserDataQuery,
    OnboardingUserDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OnboardingUserDataQuery, OnboardingUserDataQueryVariables>(
    OnboardingUserDataDocument,
    options,
  );
}
export type OnboardingUserDataQueryHookResult = ReturnType<typeof useOnboardingUserDataQuery>;
export type OnboardingUserDataLazyQueryHookResult = ReturnType<
  typeof useOnboardingUserDataLazyQuery
>;
export type OnboardingUserDataQueryResult = ApolloReactCommon.QueryResult<
  OnboardingUserDataQuery,
  OnboardingUserDataQueryVariables
>;
export const OnboardingUserPhoneDocument = gql`
  query onboardingUserPhone {
    onboardedHubUser {
      phone
    }
  }
`;

/**
 * __useOnboardingUserPhoneQuery__
 *
 * To run a query within a React component, call `useOnboardingUserPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingUserPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingUserPhoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingUserPhoneQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OnboardingUserPhoneQuery,
    OnboardingUserPhoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OnboardingUserPhoneQuery, OnboardingUserPhoneQueryVariables>(
    OnboardingUserPhoneDocument,
    options,
  );
}
export function useOnboardingUserPhoneLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OnboardingUserPhoneQuery,
    OnboardingUserPhoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OnboardingUserPhoneQuery, OnboardingUserPhoneQueryVariables>(
    OnboardingUserPhoneDocument,
    options,
  );
}
export type OnboardingUserPhoneQueryHookResult = ReturnType<typeof useOnboardingUserPhoneQuery>;
export type OnboardingUserPhoneLazyQueryHookResult = ReturnType<
  typeof useOnboardingUserPhoneLazyQuery
>;
export type OnboardingUserPhoneQueryResult = ApolloReactCommon.QueryResult<
  OnboardingUserPhoneQuery,
  OnboardingUserPhoneQueryVariables
>;
export const OnboardingUnderageErrorDocument = gql`
  query onboardingUnderageError {
    onboardingHubUser {
      organizationCounselingCenter {
        departmentName
        departmentAddress
        departmentHours
      }
      organizationMinimumHubAge
    }
  }
`;

/**
 * __useOnboardingUnderageErrorQuery__
 *
 * To run a query within a React component, call `useOnboardingUnderageErrorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingUnderageErrorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingUnderageErrorQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingUnderageErrorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OnboardingUnderageErrorQuery,
    OnboardingUnderageErrorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OnboardingUnderageErrorQuery,
    OnboardingUnderageErrorQueryVariables
  >(OnboardingUnderageErrorDocument, options);
}
export function useOnboardingUnderageErrorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OnboardingUnderageErrorQuery,
    OnboardingUnderageErrorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OnboardingUnderageErrorQuery,
    OnboardingUnderageErrorQueryVariables
  >(OnboardingUnderageErrorDocument, options);
}
export type OnboardingUnderageErrorQueryHookResult = ReturnType<
  typeof useOnboardingUnderageErrorQuery
>;
export type OnboardingUnderageErrorLazyQueryHookResult = ReturnType<
  typeof useOnboardingUnderageErrorLazyQuery
>;
export type OnboardingUnderageErrorQueryResult = ApolloReactCommon.QueryResult<
  OnboardingUnderageErrorQuery,
  OnboardingUnderageErrorQueryVariables
>;
export const HeapUserInfoDocument = gql`
  query heapUserInfo {
    onboardingHubUser {
      uid
      organization {
        id
      }
    }
  }
`;

/**
 * __useHeapUserInfoQuery__
 *
 * To run a query within a React component, call `useHeapUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeapUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeapUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeapUserInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<HeapUserInfoQuery, HeapUserInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<HeapUserInfoQuery, HeapUserInfoQueryVariables>(
    HeapUserInfoDocument,
    options,
  );
}
export function useHeapUserInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HeapUserInfoQuery,
    HeapUserInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<HeapUserInfoQuery, HeapUserInfoQueryVariables>(
    HeapUserInfoDocument,
    options,
  );
}
export type HeapUserInfoQueryHookResult = ReturnType<typeof useHeapUserInfoQuery>;
export type HeapUserInfoLazyQueryHookResult = ReturnType<typeof useHeapUserInfoLazyQuery>;
export type HeapUserInfoQueryResult = ApolloReactCommon.QueryResult<
  HeapUserInfoQuery,
  HeapUserInfoQueryVariables
>;
export const TherapyContextUserDocument = gql`
  query therapyContextUser {
    onboardedHubUser {
      canRedirect
      organization {
        name
      }
      organizationHasSelfReferrals
      organizationCanAccessTherapy
    }
  }
`;

/**
 * __useTherapyContextUserQuery__
 *
 * To run a query within a React component, call `useTherapyContextUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapyContextUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapyContextUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useTherapyContextUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TherapyContextUserQuery,
    TherapyContextUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TherapyContextUserQuery, TherapyContextUserQueryVariables>(
    TherapyContextUserDocument,
    options,
  );
}
export function useTherapyContextUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TherapyContextUserQuery,
    TherapyContextUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TherapyContextUserQuery, TherapyContextUserQueryVariables>(
    TherapyContextUserDocument,
    options,
  );
}
export type TherapyContextUserQueryHookResult = ReturnType<typeof useTherapyContextUserQuery>;
export type TherapyContextUserLazyQueryHookResult = ReturnType<
  typeof useTherapyContextUserLazyQuery
>;
export type TherapyContextUserQueryResult = ApolloReactCommon.QueryResult<
  TherapyContextUserQuery,
  TherapyContextUserQueryVariables
>;
export const OnboardedUserDocument = gql`
  query onboardedUser {
    onboardedHubUser {
      hasHubOnboarded
    }
  }
`;

/**
 * __useOnboardedUserQuery__
 *
 * To run a query within a React component, call `useOnboardedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardedUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OnboardedUserQuery, OnboardedUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OnboardedUserQuery, OnboardedUserQueryVariables>(
    OnboardedUserDocument,
    options,
  );
}
export function useOnboardedUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OnboardedUserQuery,
    OnboardedUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OnboardedUserQuery, OnboardedUserQueryVariables>(
    OnboardedUserDocument,
    options,
  );
}
export type OnboardedUserQueryHookResult = ReturnType<typeof useOnboardedUserQuery>;
export type OnboardedUserLazyQueryHookResult = ReturnType<typeof useOnboardedUserLazyQuery>;
export type OnboardedUserQueryResult = ApolloReactCommon.QueryResult<
  OnboardedUserQuery,
  OnboardedUserQueryVariables
>;
export const HomePageUserDocument = gql`
  query homePageUser {
    onboardedHubUser {
      preferredName
      organization {
        name
      }
      organizationCanAccessCampusResources
      organizationCanAccessTogetherAll
      organizationCanAccessCare
      organizationHasSelfReferrals
    }
  }
`;

/**
 * __useHomePageUserQuery__
 *
 * To run a query within a React component, call `useHomePageUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<HomePageUserQuery, HomePageUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<HomePageUserQuery, HomePageUserQueryVariables>(
    HomePageUserDocument,
    options,
  );
}
export function useHomePageUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HomePageUserQuery,
    HomePageUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<HomePageUserQuery, HomePageUserQueryVariables>(
    HomePageUserDocument,
    options,
  );
}
export type HomePageUserQueryHookResult = ReturnType<typeof useHomePageUserQuery>;
export type HomePageUserLazyQueryHookResult = ReturnType<typeof useHomePageUserLazyQuery>;
export type HomePageUserQueryResult = ApolloReactCommon.QueryResult<
  HomePageUserQuery,
  HomePageUserQueryVariables
>;
export const ConsentRequiredUserDocument = gql`
  query consentRequiredUser {
    onboardingHubUser {
      organization {
        name
      }
    }
  }
`;

/**
 * __useConsentRequiredUserQuery__
 *
 * To run a query within a React component, call `useConsentRequiredUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentRequiredUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentRequiredUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsentRequiredUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ConsentRequiredUserQuery,
    ConsentRequiredUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConsentRequiredUserQuery, ConsentRequiredUserQueryVariables>(
    ConsentRequiredUserDocument,
    options,
  );
}
export function useConsentRequiredUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConsentRequiredUserQuery,
    ConsentRequiredUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConsentRequiredUserQuery, ConsentRequiredUserQueryVariables>(
    ConsentRequiredUserDocument,
    options,
  );
}
export type ConsentRequiredUserQueryHookResult = ReturnType<typeof useConsentRequiredUserQuery>;
export type ConsentRequiredUserLazyQueryHookResult = ReturnType<
  typeof useConsentRequiredUserLazyQuery
>;
export type ConsentRequiredUserQueryResult = ApolloReactCommon.QueryResult<
  ConsentRequiredUserQuery,
  ConsentRequiredUserQueryVariables
>;
export const OnDemandUserDocument = gql`
  query onDemandUser {
    onboardedHubUser {
      preferredName
      firstName
      lastName
      phone
      birthDate
      pronouns
      primaryAddress {
        line1
        line2
        city
        state
        zip
      }
    }
  }
`;

/**
 * __useOnDemandUserQuery__
 *
 * To run a query within a React component, call `useOnDemandUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnDemandUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDemandUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnDemandUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OnDemandUserQuery, OnDemandUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OnDemandUserQuery, OnDemandUserQueryVariables>(
    OnDemandUserDocument,
    options,
  );
}
export function useOnDemandUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OnDemandUserQuery,
    OnDemandUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OnDemandUserQuery, OnDemandUserQueryVariables>(
    OnDemandUserDocument,
    options,
  );
}
export type OnDemandUserQueryHookResult = ReturnType<typeof useOnDemandUserQuery>;
export type OnDemandUserLazyQueryHookResult = ReturnType<typeof useOnDemandUserLazyQuery>;
export type OnDemandUserQueryResult = ApolloReactCommon.QueryResult<
  OnDemandUserQuery,
  OnDemandUserQueryVariables
>;
export const ConnectNowUserDocument = gql`
  query connectNowUser {
    currentHubUser {
      preferredName
      firstName
      lastName
      phone
      birthDate
      pronouns
      primaryAddress {
        line1
        line2
        city
        state
        zip
      }
    }
  }
`;

/**
 * __useConnectNowUserQuery__
 *
 * To run a query within a React component, call `useConnectNowUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectNowUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectNowUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useConnectNowUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ConnectNowUserQuery,
    ConnectNowUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConnectNowUserQuery, ConnectNowUserQueryVariables>(
    ConnectNowUserDocument,
    options,
  );
}
export function useConnectNowUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConnectNowUserQuery,
    ConnectNowUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConnectNowUserQuery, ConnectNowUserQueryVariables>(
    ConnectNowUserDocument,
    options,
  );
}
export type ConnectNowUserQueryHookResult = ReturnType<typeof useConnectNowUserQuery>;
export type ConnectNowUserLazyQueryHookResult = ReturnType<typeof useConnectNowUserLazyQuery>;
export type ConnectNowUserQueryResult = ApolloReactCommon.QueryResult<
  ConnectNowUserQuery,
  ConnectNowUserQueryVariables
>;
export const OrganizationLogoDocument = gql`
  query organizationLogo {
    onboardedHubUser {
      organizationLogo {
        contentType
        url
      }
    }
  }
`;

/**
 * __useOrganizationLogoQuery__
 *
 * To run a query within a React component, call `useOrganizationLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationLogoQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationLogoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationLogoQuery,
    OrganizationLogoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationLogoQuery, OrganizationLogoQueryVariables>(
    OrganizationLogoDocument,
    options,
  );
}
export function useOrganizationLogoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationLogoQuery,
    OrganizationLogoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationLogoQuery, OrganizationLogoQueryVariables>(
    OrganizationLogoDocument,
    options,
  );
}
export type OrganizationLogoQueryHookResult = ReturnType<typeof useOrganizationLogoQuery>;
export type OrganizationLogoLazyQueryHookResult = ReturnType<typeof useOrganizationLogoLazyQuery>;
export type OrganizationLogoQueryResult = ApolloReactCommon.QueryResult<
  OrganizationLogoQuery,
  OrganizationLogoQueryVariables
>;
export const CurrentUserOrganizationDocument = gql`
  query currentUserOrganization {
    onboardedHubUser {
      organization {
        id
      }
    }
  }
`;

/**
 * __useCurrentUserOrganizationQuery__
 *
 * To run a query within a React component, call `useCurrentUserOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserOrganizationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserOrganizationQuery,
    CurrentUserOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CurrentUserOrganizationQuery,
    CurrentUserOrganizationQueryVariables
  >(CurrentUserOrganizationDocument, options);
}
export function useCurrentUserOrganizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserOrganizationQuery,
    CurrentUserOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CurrentUserOrganizationQuery,
    CurrentUserOrganizationQueryVariables
  >(CurrentUserOrganizationDocument, options);
}
export type CurrentUserOrganizationQueryHookResult = ReturnType<
  typeof useCurrentUserOrganizationQuery
>;
export type CurrentUserOrganizationLazyQueryHookResult = ReturnType<
  typeof useCurrentUserOrganizationLazyQuery
>;
export type CurrentUserOrganizationQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserOrganizationQuery,
  CurrentUserOrganizationQueryVariables
>;
export const OrganizationHasCoachingDocument = gql`
  query organizationHasCoaching {
    onboardedHubUser {
      organizationHasCoaching
    }
  }
`;

/**
 * __useOrganizationHasCoachingQuery__
 *
 * To run a query within a React component, call `useOrganizationHasCoachingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationHasCoachingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationHasCoachingQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationHasCoachingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationHasCoachingQuery,
    OrganizationHasCoachingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationHasCoachingQuery,
    OrganizationHasCoachingQueryVariables
  >(OrganizationHasCoachingDocument, options);
}
export function useOrganizationHasCoachingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationHasCoachingQuery,
    OrganizationHasCoachingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationHasCoachingQuery,
    OrganizationHasCoachingQueryVariables
  >(OrganizationHasCoachingDocument, options);
}
export type OrganizationHasCoachingQueryHookResult = ReturnType<
  typeof useOrganizationHasCoachingQuery
>;
export type OrganizationHasCoachingLazyQueryHookResult = ReturnType<
  typeof useOrganizationHasCoachingLazyQuery
>;
export type OrganizationHasCoachingQueryResult = ApolloReactCommon.QueryResult<
  OrganizationHasCoachingQuery,
  OrganizationHasCoachingQueryVariables
>;
export const SetPreferredNameDocument = gql`
  mutation setPreferredName($preferredName: String!) {
    setHubUserPreferredName(preferredName: $preferredName)
  }
`;
export type SetPreferredNameMutationFn = ApolloReactCommon.MutationFunction<
  SetPreferredNameMutation,
  SetPreferredNameMutationVariables
>;

/**
 * __useSetPreferredNameMutation__
 *
 * To run a mutation, you first call `useSetPreferredNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPreferredNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPreferredNameMutation, { data, loading, error }] = useSetPreferredNameMutation({
 *   variables: {
 *      preferredName: // value for 'preferredName'
 *   },
 * });
 */
export function useSetPreferredNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetPreferredNameMutation,
    SetPreferredNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SetPreferredNameMutation, SetPreferredNameMutationVariables>(
    SetPreferredNameDocument,
    options,
  );
}
export type SetPreferredNameMutationHookResult = ReturnType<typeof useSetPreferredNameMutation>;
export type SetPreferredNameMutationResult =
  ApolloReactCommon.MutationResult<SetPreferredNameMutation>;
export type SetPreferredNameMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetPreferredNameMutation,
  SetPreferredNameMutationVariables
>;
export const HubUserOnboardDocument = gql`
  mutation hubUserOnboard($birthDate: String!, $name: String) {
    hubUserOnboard(birthDate: $birthDate, preferredName: $name) {
      __typename
      ... on HubUserOnboardSuccess {
        successMessage
      }
      ... on HubUserOnboardError {
        errorCode
        message
      }
    }
  }
`;
export type HubUserOnboardMutationFn = ApolloReactCommon.MutationFunction<
  HubUserOnboardMutation,
  HubUserOnboardMutationVariables
>;

/**
 * __useHubUserOnboardMutation__
 *
 * To run a mutation, you first call `useHubUserOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHubUserOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hubUserOnboardMutation, { data, loading, error }] = useHubUserOnboardMutation({
 *   variables: {
 *      birthDate: // value for 'birthDate'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useHubUserOnboardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    HubUserOnboardMutation,
    HubUserOnboardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<HubUserOnboardMutation, HubUserOnboardMutationVariables>(
    HubUserOnboardDocument,
    options,
  );
}
export type HubUserOnboardMutationHookResult = ReturnType<typeof useHubUserOnboardMutation>;
export type HubUserOnboardMutationResult = ApolloReactCommon.MutationResult<HubUserOnboardMutation>;
export type HubUserOnboardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  HubUserOnboardMutation,
  HubUserOnboardMutationVariables
>;
export const HubUserAcceptTermsDocument = gql`
  mutation hubUserAcceptTerms {
    hubUserAcceptTerms
  }
`;
export type HubUserAcceptTermsMutationFn = ApolloReactCommon.MutationFunction<
  HubUserAcceptTermsMutation,
  HubUserAcceptTermsMutationVariables
>;

/**
 * __useHubUserAcceptTermsMutation__
 *
 * To run a mutation, you first call `useHubUserAcceptTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHubUserAcceptTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hubUserAcceptTermsMutation, { data, loading, error }] = useHubUserAcceptTermsMutation({
 *   variables: {
 *   },
 * });
 */
export function useHubUserAcceptTermsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    HubUserAcceptTermsMutation,
    HubUserAcceptTermsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    HubUserAcceptTermsMutation,
    HubUserAcceptTermsMutationVariables
  >(HubUserAcceptTermsDocument, options);
}
export type HubUserAcceptTermsMutationHookResult = ReturnType<typeof useHubUserAcceptTermsMutation>;
export type HubUserAcceptTermsMutationResult =
  ApolloReactCommon.MutationResult<HubUserAcceptTermsMutation>;
export type HubUserAcceptTermsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  HubUserAcceptTermsMutation,
  HubUserAcceptTermsMutationVariables
>;
export const UpdateHubUser2Document = gql`
  mutation updateHubUser2($updates: HubUserUpdateInput!) {
    updateHubUser: updateHubUser2(updates: $updates) {
      __typename
      ... on UpdateHubUserSuccess {
        successMessage
      }
      ... on UpdateHubUserError {
        errorCode
        message
      }
    }
  }
`;
export type UpdateHubUser2MutationFn = ApolloReactCommon.MutationFunction<
  UpdateHubUser2Mutation,
  UpdateHubUser2MutationVariables
>;

/**
 * __useUpdateHubUser2Mutation__
 *
 * To run a mutation, you first call `useUpdateHubUser2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubUser2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubUser2Mutation, { data, loading, error }] = useUpdateHubUser2Mutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateHubUser2Mutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHubUser2Mutation,
    UpdateHubUser2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateHubUser2Mutation, UpdateHubUser2MutationVariables>(
    UpdateHubUser2Document,
    options,
  );
}
export type UpdateHubUser2MutationHookResult = ReturnType<typeof useUpdateHubUser2Mutation>;
export type UpdateHubUser2MutationResult = ApolloReactCommon.MutationResult<UpdateHubUser2Mutation>;
export type UpdateHubUser2MutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateHubUser2Mutation,
  UpdateHubUser2MutationVariables
>;
