import { useEffect } from 'react';
import { Layout, StatusFeedback } from '../../../components/core';
import { IconHelpCircle } from '../../../components/icons/IconHelpCircle';

import { PageContent, PageFooter, PageTopBar } from '../../../components/page';
import { CoachingStatus, useCoachOverviewQuery } from '../../../graphQL';
import { getRoute, useExternalNavigate, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { BadgeCountCounterNameEnum, useBadgeCountReset } from '../hooks/useBadgeCountReset';
import { AssignedSkills } from './AssignedSkills';
import { CoachChatWidget } from './CoachChatWidget';
import { coachingAppointmentTaskCollection } from './CoachingAppointmentTaskCollection';

type CoachingDashboardProps = {
  coachingStatus: CoachingStatus;
};

export const CoachingDashboard = ({ coachingStatus }: CoachingDashboardProps): JSX.Element => {
  const navigate = useNavigate();
  const externalNavigate = useExternalNavigate();

  const { data: coachOverviewData } = useCoachOverviewQuery();

  const [badgeCountReset] = useBadgeCountReset(BadgeCountCounterNameEnum.UnviewedAssignedSkills);

  useEffect(() => {
    void badgeCountReset();
  }, []);

  const coachAvatarUrl = coachOverviewData?.coachOverview?.coachAvatarUrl ?? '';
  const coachName = coachOverviewData?.coachOverview?.coachName ?? '';
  const hasUnreadMessages = coachOverviewData?.coachOverview?.hasUnreadMessages ?? false;

  const onBackPress = (): void => {
    navigate(getRoute('home', {}));
  };

  return (
    <PageContent testID="coaching-dashboard-page">
      <PageTopBar
        backAccessibilityLabel="Back to Home"
        backTitle="Home"
        onBackPress={onBackPress}
      />

      <Layout.Box {...styles.mainWrapper}>
        <CoachChatWidget
          coachAvatarUrl={coachAvatarUrl}
          coachName={coachName}
          hasUnreadMessages={hasUnreadMessages}
          active={coachingStatus === CoachingStatus.Active}
        />

        {coachingAppointmentTaskCollection()}

        {coachingStatus === CoachingStatus.Inactive && (
          <StatusFeedback.info
            Icon={IconHelpCircle}
            isLink
            testID="coaching-inactive-status-feedback"
            onPress={() => externalNavigate('mailto:hi@mantrahealth.com')}
          >
            Think your coaching program was ended in error? Email hi@mantrahealth.com to see if
            you're eligible to re-enroll.
          </StatusFeedback.info>
        )}

        <AssignedSkills />
      </Layout.Box>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  mainWrapper: {
    gap: 4,
  },
});
