import { createIcon, Path } from './index';

export const IconZap = createIcon({
  title: 'A lighting bolt',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M13 2L3 14H12L11 22L21 10H12L13 2Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
