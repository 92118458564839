import { defaultTo } from 'lodash';
import { SkillDocument } from '../../../.slicemachine/prismicio';
import { InfoSlide } from './SkillTypes';

export const getSkillSlideCount = (skill: SkillDocument): number => {
  // The +2 accounts for the skill landing and up next pages.
  return skill.data.slices.length + 2;
};

export const getSkillImageUrl = (skill: SkillDocument, type: 'desktop' | 'mobile'): string => {
  let url;
  if (type === 'desktop') {
    url =
      'url' in skill.data.background_image_desktop
        ? skill.data.background_image_desktop.url
        : undefined;
  } else {
    url =
      'url' in skill.data.background_image_mobile
        ? skill.data.background_image_mobile.url
        : undefined;
  }

  return defaultTo(url, '');
};

export const getInfoSlideImageUrl = (
  infoSlidePrimary: InfoSlide['primary'] | undefined,
  type: 'background_desktop' | 'background_mobile' | 'main_image',
): string => {
  if (!infoSlidePrimary) {
    return '';
  }

  let url;
  if (type === 'background_desktop') {
    url =
      'url' in infoSlidePrimary.background_image_desktop
        ? infoSlidePrimary.background_image_desktop.url
        : undefined;
  } else if (type === 'background_mobile') {
    url =
      'url' in infoSlidePrimary.background_image_mobile
        ? infoSlidePrimary.background_image_mobile.url
        : undefined;
  } else if (type === 'main_image') {
    url = 'image' in infoSlidePrimary ? infoSlidePrimary.image.url : undefined;
  }

  return defaultTo(url, '');
};

export const getInfoSlideImageAlt = (
  infoSlidePrimary: InfoSlide['primary'] | undefined,
): string => {
  if (!infoSlidePrimary) {
    return '';
  }

  const alt = 'image' in infoSlidePrimary ? infoSlidePrimary.image.alt : undefined;

  return defaultTo(alt, 'Image');
};
