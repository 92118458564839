import { useNavigate } from 'react-router-native';
import { useCurrentRouteSearch } from '../routes';
import { parseMrnIntoRoute } from './mrn';

export const useMrnRouting = (): (() => void) => {
  const navigate = useNavigate();
  const routeSearch = useCurrentRouteSearch();

  return (): void => {
    const mrn = routeSearch.mrn ?? '';
    const { to, toOptions } = parseMrnIntoRoute(mrn);

    navigate(to, { replace: true, ...toOptions });
  };
};
