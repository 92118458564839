import { differenceInHours } from 'date-fns';
import { JSX, useMemo } from 'react';
import { StatusFeedback } from '../../../components/core';
import { IconAlert } from '../../../components/icons/IconAlert';
import { UpcomingAppointment } from '../../../content/appointment/components/JoinAppointmentWidget';

type CoachingTasksWarningWidgetProps = {
  appointmentData: UpcomingAppointment;
};

export const CoachingTasksWarningWidget = ({
  appointmentData,
}: CoachingTasksWarningWidgetProps): JSX.Element | null => {
  if (appointmentData == null) {
    return null;
  }

  const { start } = appointmentData;

  const appointmentWithinThreeHours = useMemo(() => {
    if (start) {
      return differenceInHours(new Date(start), new Date()) <= 3;
    }
    return false;
  }, [start]);

  if (!appointmentWithinThreeHours) {
    return null;
  }

  return (
    <StatusFeedback.danger Icon={IconAlert} testID="coaching-tasks-warning-widget">
      Please complete your "Getting Started" tasks prior to joining the call.
    </StatusFeedback.danger>
  );
};
