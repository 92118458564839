import { createIcon, Path } from './index';

export const IconMinus = createIcon({
  title: 'A minus sign',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M5 12H19"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
