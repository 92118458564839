import { CircleIcon } from 'native-base';
import { ReactElement, ReactNode } from 'react';
import { Layout, Text } from './index';

export const List = Layout.VStack;

type TextListItemProps = Layout.IHStackProps & {
  children: ReactNode;
  icon?: ReactElement;
};

export const Item = ({ children, icon, ...hStackProps }: TextListItemProps): JSX.Element => {
  return (
    <Layout.HStack {...hStackProps}>
      {icon ?? <CircleIcon size={2} marginTop="6px" />}
      <Text.para marginLeft={3} flex={1}>
        {children}
      </Text.para>
    </Layout.HStack>
  );
};
