import { Path, Rect, Svg } from '../../../components/icons';

export const ImageStudentLaptopSquare = (): JSX.Element => {
  return (
    <Svg
      title="A productivity coaching student sitting in a chair working on a laptop."
      width="94"
      height="95"
      viewBox="0 0 94 95"
      fill="none"
      aria-hidden={true}
    >
      <Rect width="94" height="94" transform="translate(0 0.5)" fill="#35405F" />
      <Path
        d="M78.8203 95.0214L77.8508 112.787C77.8266 113.236 77.4425 113.577 76.9967 113.553C76.5858 113.531 76.2554 113.201 76.2313 112.79L75.1463 94.4789L78.8203 95.0214Z"
        fill="#2B59E3"
      />
      <Path
        d="M15.5718 95.0214L16.5413 112.787C16.5654 113.236 16.9495 113.577 17.3954 113.553C17.8063 113.531 18.1366 113.201 18.1608 112.79L19.2458 94.4789L15.5718 95.0214Z"
        fill="#2B59E3"
      />
      <Path
        d="M19.7829 89.6043L20.7525 104.98C20.7767 105.429 21.1607 105.77 21.6065 105.745C22.0175 105.724 22.3478 105.394 22.372 104.983L23.457 89.0591L19.7829 89.6043Z"
        fill="#35405F"
      />
      <Path
        d="M74.3781 89.6043L73.4086 104.98C73.3844 105.429 73.0004 105.77 72.5545 105.745C72.1436 105.724 71.8133 105.394 71.7891 104.983L70.7041 89.0591L74.3781 89.6043Z"
        fill="#1D2744"
      />
      <Path
        d="M80.0047 50.6534V80.011L79.7442 88.2051L65.9557 88.6321L30.8803 84.8775L15.1527 85.8497L14.8117 85.8712L14.3309 85.9007C14.3309 85.9007 11.3175 81.7191 11.5754 81.1228C11.8305 80.5266 13.6434 64.2243 13.6434 64.2243V50.6561C13.6434 48.0644 15.7624 45.9615 18.381 45.9615H75.259C77.8803 45.9615 80.0047 48.0644 80.0047 50.6534Z"
        fill="#FFACAC"
      />
      <Path
        d="M78.4363 64.0524H78.1086V50.7018C78.1086 49.1199 76.8221 47.8307 75.2376 47.8307H18.2548C16.6729 47.8307 15.3838 49.1172 15.3838 50.7018V62.3202H15.0561V50.7018C15.0561 48.9372 16.4903 47.5031 18.2548 47.5031H75.2376C77.0021 47.5031 78.4363 48.9372 78.4363 50.7018V64.0524Z"
        fill="#F08080"
      />
      <Path
        d="M18.8644 64.4526C18.663 63.2521 17.3604 62.6774 15.8698 62.4168V62.7606C17.2154 62.997 18.3595 63.4992 18.5287 64.509C18.7919 66.0748 18.4025 81.0235 18.3998 81.1739C18.3971 81.2652 18.4723 81.3458 18.5663 81.3484H18.569C18.6603 81.3484 18.7355 81.2759 18.7409 81.1819C18.7543 80.5642 19.1303 66.0506 18.8644 64.4526Z"
        fill="#F08080"
      />
      <Path
        d="M80.0047 62.3094V62.6532C78.1542 62.7257 76.2286 63.1286 75.9708 64.4526C75.6673 66.013 75.6727 80.9644 75.6727 81.1148C75.6754 81.2061 75.5974 81.2867 75.5008 81.2867H75.4981C75.4067 81.284 75.3343 81.2115 75.3316 81.1148C75.3316 80.4998 75.3289 65.9781 75.635 64.3908C75.9332 62.8492 78.0334 62.39 80.0047 62.3094Z"
        fill="#F08080"
      />
      <Path d="M79.4918 84.0234H12.2414V96.6543H79.4918V84.0234Z" fill="#FFACAC" />
      <Path
        d="M78.3342 85.8336C78.2751 85.8336 78.2187 85.8014 78.1892 85.745L76.473 82.4308C76.0675 81.6492 75.2671 81.1604 74.3862 81.1604H19.4902C18.7436 81.1604 18.0346 81.5203 17.5941 82.1219L15.1045 85.522C15.0507 85.5946 14.9487 85.6107 14.8735 85.557C14.8009 85.5032 14.7849 85.4012 14.8386 85.326L17.3282 81.9259C17.8305 81.241 18.6389 80.8301 19.4876 80.8301H74.3835C75.388 80.8301 76.2985 81.3834 76.7604 82.2777L78.4766 85.5919C78.5195 85.6724 78.4873 85.7718 78.4067 85.8121C78.3852 85.8282 78.3584 85.8336 78.3342 85.8336Z"
        fill="#F08080"
      />
      <Path
        d="M14.9728 96.6543H11.4062C10.5951 96.6543 9.93713 95.9963 9.93713 95.1852V64.7158C9.93713 63.5502 10.8825 62.6022 12.0508 62.6022H12.8592C14.0248 62.6022 14.9728 63.5475 14.9728 64.7158V96.6543Z"
        fill="#FFACAC"
      />
      <Path
        d="M78.8928 96.6543H82.4595C83.2706 96.6543 83.9286 95.9963 83.9286 95.1852V64.7158C83.9286 63.5502 82.9832 62.6022 81.8149 62.6022H81.0065C79.8409 62.6022 78.8928 63.5475 78.8928 64.7158V96.6543Z"
        fill="#FFACAC"
      />
      <Path
        d="M78.6323 96.6543H78.3047V86.2848C78.3047 85.5516 77.7084 84.9554 76.9752 84.9554H16.4661C15.7329 84.9554 15.1366 85.5516 15.1366 86.2848V96.6543H14.809V86.2848C14.809 85.3717 15.553 84.6277 16.4661 84.6277H76.9752C77.8883 84.6277 78.6323 85.3717 78.6323 86.2848V96.6543Z"
        fill="#F08080"
      />
      <Path
        d="M15.1527 85.8497C15.1501 86.5158 15.142 86.9455 15.142 87.0422L14.8009 87.0395C14.8009 87.0153 14.8036 86.5829 14.8117 85.8712C14.8493 82.3475 14.9406 66.4938 14.8009 64.8152C14.7123 63.749 14.1349 63.1984 13.6434 62.9137V62.5323C14.237 62.8224 15.0292 63.4535 15.142 64.7883C15.2844 66.4964 15.1903 82.2831 15.1527 85.8497Z"
        fill="#F08080"
      />
      <Path
        d="M80.0047 62.6344V63.0158C79.4864 63.3219 78.7881 63.9477 78.6914 65.1428C78.5249 67.1598 78.6914 87.2194 78.6914 87.3671L78.3504 87.3698C78.3423 86.7682 78.1838 67.1464 78.3504 65.116C78.4712 63.6576 79.4085 62.9432 80.0047 62.6344Z"
        fill="#F08080"
      />
      <Path
        d="M38.8596 33.113C38.8596 33.113 39.5042 31.3915 41.6286 31.4452C43.753 31.4989 48.5657 31.3378 50.717 31.284C51.5845 31.2626 52.4412 31.0934 53.3087 31.0504C54.6059 30.9859 55.4036 31.6117 56.3865 32.3556C56.8351 32.6967 57.2862 33.0378 57.7052 33.4138C57.8314 33.5266 58.3176 33.8865 58.245 34.1067C58.1376 34.429 53.6202 36.7146 53.6202 36.7146C53.6202 36.7146 49.6212 38.4092 46.2318 37.6277C42.8398 36.8488 38.8596 33.113 38.8596 33.113Z"
        fill="#E0E7FF"
      />
      <Path
        d="M52.4923 32.3583C52.4923 32.3583 49.0196 36.0431 48.0555 36.3654C47.094 36.6877 46.7126 36.1506 45.1979 35.8014C43.6805 35.4496 42.9204 32.6269 42.9204 32.6269C42.9204 32.6269 43.4468 32.3986 43.9651 31.9823C44.3868 31.6466 44.4807 31.1927 44.6097 30.6395C44.8675 29.5518 44.4942 28.2008 44.3975 27.8839C44.3841 27.8383 44.3787 27.8168 44.3787 27.8168L44.615 27.8034L50.6015 27.5187C50.6015 27.5187 50.4834 29.5894 50.8916 30.72C51.2971 31.848 52.4923 32.3583 52.4923 32.3583Z"
        fill="#A2CAC2"
      />
      <Path
        d="M50.0563 29.1328C49.7206 28.2895 48.5443 27.8383 47.5881 27.661C46.675 27.4891 45.3456 27.5697 44.6124 27.8034C44.5506 27.8248 44.4888 27.8463 44.4324 27.8678C44.419 27.8705 44.411 27.8786 44.3975 27.8839C44.4942 28.2008 44.8675 29.5518 44.6097 30.6395C44.4807 31.19 44.1477 31.8051 43.9651 31.9823C44.2444 32.2106 44.5452 32.3959 44.8111 32.5302C45.6275 32.9465 46.7636 33.0217 47.6607 32.8794C48.3375 32.7719 49.1674 32.3207 49.5702 31.746C50.0134 31.1068 50.3356 29.8338 50.0563 29.1328Z"
        fill="#2D6A62"
      />
      <Path
        d="M37.6939 33.4568C37.6939 33.4568 40.095 32.6269 40.9839 32.7075C41.8702 32.788 42.9177 32.6269 42.9177 32.6269C42.9177 32.6269 45.2193 34.5123 47.7036 34.4317C50.1879 34.3511 52.4896 32.361 52.4896 32.361C52.4896 32.361 53.588 32.5517 54.4233 32.5786C55.2558 32.6054 57.6247 32.6833 57.6247 32.6833C57.6247 32.6833 55.9568 46.4879 54.99 48.3679C54.0231 50.2506 47.7036 51.2174 47.7036 51.2174L36.4397 47.9919L35.9563 37.4692L37.6939 33.4568Z"
        fill="white"
      />
      <Path d="M52.9086 62.2714H38.1371V70.3286H52.9086V62.2714Z" fill="#1D2744" />
      <Path
        d="M51.2004 21.449C50.572 19.8026 49.2748 18.6746 47.4566 18.3362C45.1979 17.9146 42.7915 19.5018 42.3108 21.7471C42.1254 22.6092 42.1684 23.5062 42.2677 24.3818C42.5175 26.6163 43.3501 30.2178 45.6034 31.2948C46.7637 31.8507 48.2139 31.797 49.3043 31.0772C50.4296 30.3333 51.0232 28.8132 51.316 27.5428C51.5496 26.525 51.6194 25.4722 51.5979 24.4301C51.5818 23.5599 51.5308 22.3111 51.2004 21.449Z"
        fill="#A2CAC2"
      />
      <Path
        d="M41.0511 20.8796C41.0296 20.944 40.1434 22.7139 41.6017 23.4149C42.7727 23.9762 44.0619 22.5931 44.0619 22.5931C44.0619 22.5931 44.3143 23.525 45.3134 23.6163C46.9302 23.764 47.2283 22.5259 47.2283 22.5259C47.2283 22.5259 47.4056 23.4444 48.6947 23.4444C49.9839 23.4444 50.2417 22.2439 50.2417 22.2439C50.2417 22.2439 50.9883 23.2994 52.1673 22.7757C53.3463 22.252 52.4815 20.4902 52.4815 20.4902C52.4815 20.4902 53.1744 19.4991 52.409 17.971C51.6436 16.4428 49.5703 16.9799 49.5703 16.9799C49.5703 16.9799 49.0277 15.3551 46.906 15.3336C44.7843 15.3121 44.3331 16.937 44.2418 16.937C44.1505 16.937 42.4477 16.4025 41.3868 17.6809C40.1729 19.1312 41.0511 20.8796 41.0511 20.8796Z"
        fill="#F67E6A"
      />
      <Path
        d="M43.0413 23.2618C42.958 23.1624 42.864 23.0738 42.7539 22.9986C42.4396 22.7891 42.0314 22.7381 41.6715 22.8455C41.3116 22.9556 41.0028 23.2215 40.8309 23.5572C40.4683 24.2636 40.7288 25.1364 41.1397 25.8132C41.5131 26.4256 42.4101 27.4596 43.1352 26.6861C43.3501 26.4551 43.4522 26.1409 43.5113 25.8294C43.6133 25.2761 43.5972 24.7014 43.4629 24.1562C43.3796 23.8366 43.2481 23.5143 43.0413 23.2618Z"
        fill="#A2CAC2"
      />
      <Path
        d="M50.5451 23.764C50.4726 24.3227 50.5209 24.8947 50.6875 25.4319C50.7788 25.7327 50.9157 26.0362 51.1575 26.2403C51.9659 26.9251 52.7393 25.7971 53.0401 25.1445C53.3732 24.4247 53.5316 23.5277 53.0912 22.867C52.8817 22.5528 52.546 22.3245 52.1753 22.2574C51.8047 22.1902 51.4045 22.2896 51.1171 22.5313C51.0151 22.6172 50.9318 22.7166 50.8593 22.824C50.6794 23.098 50.5881 23.4337 50.5451 23.764Z"
        fill="#A2CAC2"
      />
      <Path
        d="M42.488 24.3361C42.3967 24.132 42.0958 24.3092 42.1871 24.5134C42.3698 24.927 42.5712 25.3996 42.3537 25.8401C42.2543 26.0415 42.5552 26.2188 42.6545 26.0174C42.9338 25.4453 42.7297 24.8813 42.488 24.3361Z"
        fill="#2D6A62"
      />
      <Path
        d="M51.7779 23.5035C51.8692 23.2994 52.1699 23.4767 52.0786 23.6808C51.896 24.0944 51.6946 24.5671 51.9121 25.0075C52.0115 25.209 51.7107 25.3862 51.6113 25.1848C51.3293 24.6127 51.5361 24.0487 51.7779 23.5035Z"
        fill="#2D6A62"
      />
      <Path
        d="M45.4771 25.9851C45.606 25.9153 45.7672 25.926 45.8854 26.0576C46.1029 26.2994 46.1137 26.6915 45.9284 26.9574C45.88 27.0272 45.8156 27.089 45.735 27.1158C45.6061 27.1561 45.461 27.089 45.3778 26.9815C45.2945 26.8741 45.265 26.7371 45.2489 26.6028C45.2354 26.4739 45.2354 26.3423 45.2784 26.2215C45.316 26.114 45.3885 26.0308 45.4771 25.9851Z"
        fill="#1D2744"
      />
      <Path
        d="M48.6222 25.8052C48.7511 25.7354 48.9123 25.7461 49.0304 25.8777C49.248 26.1194 49.2587 26.5115 49.0734 26.7774C49.025 26.8472 48.9606 26.909 48.88 26.9359C48.7511 26.9762 48.6061 26.909 48.5228 26.8016C48.4395 26.6942 48.41 26.5572 48.3939 26.4229C48.3805 26.294 48.3805 26.1624 48.4235 26.0415C48.4611 25.9341 48.5336 25.8508 48.6222 25.8052Z"
        fill="#1D2744"
      />
      <Path
        d="M47.4216 27.5321C47.2712 27.336 47.1906 27.1695 47.2228 26.9144C47.2497 26.7156 47.2954 26.5169 47.3303 26.3182C47.3706 26.0979 47.0349 26.0039 46.9919 26.2242C46.9409 26.4981 46.8603 26.7855 46.8576 27.0648C46.8549 27.3172 46.9677 27.5133 47.1154 27.7094C47.2551 27.8839 47.5586 27.7094 47.4216 27.5321Z"
        fill="#2D6A62"
      />
      <Path
        d="M48.1682 28.0746C47.9748 28.695 46.863 28.652 46.5595 28.182C46.4386 27.994 46.1352 28.1686 46.2587 28.3593C46.7341 29.1006 48.2192 29.0844 48.5066 28.1686C48.5738 27.9511 48.2353 27.8598 48.1682 28.0746Z"
        fill="#2D6A62"
      />
      <Path
        d="M46.5891 24.7094C46.5434 24.551 46.4387 24.4113 46.3097 24.3092C46.1405 24.1776 45.9257 24.1078 45.7135 24.089C45.3859 24.0595 45.0448 24.1481 44.7789 24.3415C44.5372 24.5187 43.992 25.2063 44.4781 25.4077C44.5774 25.4507 44.6903 25.4507 44.7977 25.4507C45.1334 25.4507 45.4718 25.4507 45.8075 25.4507C46.0439 25.4507 46.3071 25.4399 46.4736 25.2707C46.5783 25.1633 46.624 25.0048 46.6133 24.8544C46.6106 24.8061 46.6025 24.7551 46.5891 24.7094Z"
        fill="#F67E6A"
      />
      <Path
        d="M47.6741 24.7255C47.6634 24.8759 47.709 25.0344 47.8138 25.1418C47.9803 25.311 48.2435 25.3218 48.4798 25.3218C48.8156 25.3218 49.154 25.3218 49.4897 25.3218C49.5971 25.3218 49.7099 25.3218 49.8093 25.2788C50.2954 25.0747 49.7501 24.3871 49.5084 24.2126C49.2425 24.0192 48.9015 23.9306 48.5738 23.9601C48.3589 23.9789 48.1468 24.0487 47.9776 24.1803C47.846 24.2824 47.7439 24.4247 47.6983 24.5805C47.6848 24.6288 47.6768 24.6772 47.6741 24.7255Z"
        fill="#F67E6A"
      />
      <Path
        d="M39.2785 64.9038C39.2785 64.9038 29.8114 65.8062 23.9807 61.9683C23.7417 61.8126 23.5053 61.6434 23.2797 61.4742C21.7596 60.3032 20.5564 58.7562 19.9441 56.7151C18.1285 50.6534 22.7507 42.7225 26.9377 38.2293C27.0183 38.1407 27.0988 38.0574 27.1794 37.9742C28.7425 36.3278 30.225 35.0816 31.2697 34.4398C33.6788 32.9572 36.6519 32.6162 38.7495 32.592C38.8918 32.5893 39.0314 32.5893 39.1657 32.592C40.4924 32.6 41.3788 32.729 41.3788 32.729C41.3788 32.729 40.3609 35.1998 39.9527 38.3609C39.9365 38.4791 39.9231 38.5999 39.907 38.7235C39.6142 41.291 39.7512 44.9409 41.1424 47.3983C44.3438 53.0437 39.2785 64.9038 39.2785 64.9038Z"
        fill="#E0E7FF"
      />
      <Path
        d="M40.4119 56.9487C40.4495 57.1636 40.1192 57.2549 40.0843 57.04C39.3699 52.5925 37.1514 48.3464 34.5329 44.7234C33.0853 42.7225 31.2456 40.6088 29.1077 39.4083C28.3933 39.0055 27.6709 38.6107 26.9404 38.2266C27.021 38.138 27.1015 38.0547 27.1821 37.9715C27.9341 38.3609 28.6727 38.7691 29.4086 39.1854C31.3611 40.2919 33.088 42.274 34.4631 44.068C37.3046 47.7609 39.6707 52.3266 40.4119 56.9487Z"
        fill="#C7D2FE"
      />
      <Path
        d="M31.2805 48.5505C30.3969 47.0089 28.2671 46.9256 26.7551 47.4494C24.9234 48.0832 23.6235 49.6248 22.8903 51.3732C22.0578 53.3606 21.9074 55.5978 22.2162 57.7168C22.5036 59.6962 23.0944 62.0328 24.7569 63.3166C24.9288 63.4482 25.1705 63.2091 24.9986 63.0748C23.5698 61.9683 23.0192 60.1018 22.6808 58.3963C22.2941 56.4546 22.2968 54.4107 22.8554 52.5039C23.3335 50.8656 24.252 49.2944 25.7023 48.3437C27.1714 47.3795 29.9403 46.8961 30.9877 48.7224C31.0952 48.9131 31.3906 48.7412 31.2805 48.5505Z"
        fill="#C7D2FE"
      />
      <Path
        d="M30.7944 47.6669C30.7944 47.6669 22.8232 48.1799 24.3594 57.6524C25.3881 63.9987 38.0136 64.7346 40.232 60.7248C42.4531 56.7151 30.7944 47.6669 30.7944 47.6669Z"
        fill="#A2CAC2"
      />
      <Path
        d="M32.1909 61.2942C31.3047 61.1626 30.405 61.0042 29.6046 60.5825C28.5706 60.0373 28.0738 59.1188 27.7864 58.015C27.2788 56.0705 27.3674 53.6158 29.3656 52.5549C29.5589 52.4528 29.414 52.144 29.2206 52.246C27.0801 53.3794 26.9082 55.9631 27.4507 58.0714C27.7085 59.0758 28.1329 60.0131 29.0084 60.6228C29.9269 61.262 31.0737 61.4715 32.1587 61.6299C32.3763 61.6675 32.4085 61.3264 32.1909 61.2942Z"
        fill="#2D6A62"
      />
      <Path
        d="M75.1274 56.7151C71.5232 68.3523 55.7957 64.9038 55.7957 64.9038C55.7957 64.9038 50.7251 53.0437 53.9291 47.3983C55.2988 44.9812 55.605 41.3635 55.511 38.796C55.5083 38.6724 55.5002 38.5489 55.4975 38.4307C55.3257 35.1649 54.5388 32.5839 54.5388 32.5839C54.5388 32.5839 55.3525 32.5732 56.5745 32.4953C56.7034 32.4872 56.8377 32.4792 56.9774 32.4684C56.9801 32.4684 56.9801 32.4684 56.9854 32.4684C59.3569 32.3046 62.424 32.8364 65.0291 34.4424C65.7328 34.8748 66.5949 35.5892 67.5215 36.5212C67.6021 36.6044 67.688 36.6877 67.7739 36.7763C71.9234 41.0627 77.1659 50.1378 75.1274 56.7151Z"
        fill="#E0E7FF"
      />
      <Path
        d="M67.7686 36.7736C65.7516 37.9554 64.0623 39.2526 62.9102 41.3367C60.6166 45.4915 59.3489 50.9381 57.9523 55.4447C57.8879 55.6569 57.5709 55.5307 57.6354 55.3212C58.433 52.7375 59.2334 50.1539 60.1466 47.6105C60.9684 45.3303 61.8305 42.317 63.1438 40.2651C64.1939 38.6241 65.8295 37.48 67.5134 36.5158C67.5994 36.5991 67.6826 36.685 67.7686 36.7736Z"
        fill="#C7D2FE"
      />
      <Path
        d="M73.5617 51.7465C71.6119 48.3759 67.2879 45.5425 63.3265 46.7672C63.117 46.8316 63.2056 47.162 63.4177 47.0975C67.2959 45.897 71.5958 48.7868 73.3979 52.152C74.405 54.0347 74.2654 56.143 73.9216 58.1815C73.5698 60.2548 72.9654 62.2718 72.1436 64.2055C72.0577 64.4043 72.3531 64.5788 72.4391 64.3774C73.3549 62.2235 74.0102 59.9782 74.346 57.6604C74.6441 55.6086 74.6252 53.5862 73.5617 51.7465Z"
        fill="#C7D2FE"
      />
      <Path
        d="M63.9173 47.6669C63.9173 47.6669 71.8885 48.1799 70.3523 57.6524C69.3236 63.9987 56.6981 64.7346 54.4797 60.7248C52.2586 56.7151 63.9173 47.6669 63.9173 47.6669Z"
        fill="#A2CAC2"
      />
      <Path
        d="M66.4445 52.2568C66.27 50.6051 64.2047 50.0867 63.4231 51.631C63.3237 51.8271 63.6192 51.999 63.7186 51.8029C64.4007 50.4547 66.0148 51.0375 66.1169 52.3857C66.1679 53.0356 66.0685 53.7071 65.9101 54.3328C65.5717 55.673 64.8519 56.8494 63.8555 57.8028C63.6971 57.9559 63.9388 58.1949 64.0972 58.0445C65.612 56.5969 66.6728 54.3973 66.4445 52.2568Z"
        fill="#2D6A62"
      />
      <Path
        d="M65.0238 46.9444V46.9471L65.0077 47.2855L64.0972 64.8689C64.0704 65.3604 63.6648 65.7471 63.1734 65.7471H29.9054C29.4139 65.7471 29.0084 65.3604 28.9815 64.8689L28.0711 47.2855L28.055 46.9471V46.9444C28.0308 46.4154 28.4497 45.9749 28.9788 45.9749H64.1026C64.629 45.9749 65.048 46.4154 65.0238 46.9444Z"
        fill="#35405F"
      />
      <Path
        d="M65.0238 46.9444V46.9471L65.0077 47.2855H28.0684L28.0496 46.9471V46.9444H65.0238Z"
        fill="#1D2744"
      />
      <Path
        d="M62.0265 50.6266H56.4939C56.2119 50.6266 55.9837 50.3983 55.9837 50.1163V49.273C55.9837 48.991 56.2119 48.7627 56.4939 48.7627H62.0265C62.3085 48.7627 62.5368 48.991 62.5368 49.273V50.1163C62.5368 50.3983 62.3059 50.6266 62.0265 50.6266ZM56.4939 49.0903C56.3946 49.0903 56.3113 49.1709 56.3113 49.273V50.1163C56.3113 50.2156 56.3919 50.2989 56.4939 50.2989H62.0265C62.1259 50.2989 62.2092 50.2183 62.2092 50.1163V49.273C62.2092 49.1736 62.1286 49.0903 62.0265 49.0903H56.4939Z"
        fill="#1D2744"
      />
      <Path
        d="M17.4141 72.5232C17.4141 72.5232 25.9896 82.5866 30.7944 83.0002C35.5991 83.4138 57.9846 84.7539 62.8484 83.3762C67.7122 81.9984 76.2555 70.2081 76.2555 70.2081L49.8764 76.1516L27.6413 75.6601L17.4141 72.5232Z"
        fill="#1D2744"
      />
      <Path
        d="M41.7225 83.7602C41.7225 83.7602 34.3207 86.8864 29.892 86.787C28.5867 86.7575 27.6011 86.556 26.8706 86.2472C26.6987 86.1747 26.5456 86.0995 26.4032 86.0162C26.1427 85.8739 25.9224 85.7047 25.7398 85.5274C25.6404 85.4307 25.5491 85.334 25.4712 85.2293C25.2994 85.0037 25.1785 84.7674 25.0926 84.5283C25.055 84.4155 25.0254 84.3027 25.0039 84.1926C24.9502 83.916 24.9422 83.642 24.9664 83.3842C24.9771 83.2983 24.9905 83.2123 25.0039 83.1291C25.012 83.0942 25.0174 83.0619 25.0281 83.0297C25.4202 81.6358 28.3692 81.2168 30.2143 80.7173C32.1023 80.2043 35.6501 77.9322 35.6501 77.9322L41.7225 83.7602Z"
        fill="#A2CAC2"
      />
      <Path
        d="M27.2063 82.882C26.465 83.1291 25.7184 83.2956 24.9637 83.3869C24.9744 83.301 24.9878 83.215 25.0013 83.1318C25.0093 83.0968 25.0147 83.0646 25.0255 83.0324C25.7345 82.9411 26.4382 82.7799 27.1311 82.5516C27.3406 82.4791 27.4158 82.8148 27.2063 82.882Z"
        fill="#2D6A62"
      />
      <Path
        d="M27.123 84.0583C26.4543 84.2759 25.7775 84.437 25.0926 84.5337C25.055 84.4209 25.0254 84.3081 25.0039 84.198C25.6942 84.1067 26.3737 83.9455 27.0451 83.7226C27.2546 83.6555 27.3271 83.9885 27.123 84.0583Z"
        fill="#2D6A62"
      />
      <Path
        d="M27.123 85.1675C26.6664 85.3179 26.2045 85.4415 25.7398 85.5301C25.6405 85.4334 25.5491 85.3367 25.4713 85.232C26.0003 85.1407 26.5241 85.0064 27.0451 84.8318C27.2546 84.7647 27.3271 85.0977 27.123 85.1675Z"
        fill="#2D6A62"
      />
      <Path
        d="M27.354 86.0995C27.1928 86.1532 27.029 86.2015 26.8706 86.2499C26.6987 86.1774 26.5456 86.1022 26.4033 86.0189C26.696 85.9518 26.9914 85.8658 27.2814 85.7691C27.4882 85.6993 27.5634 86.0297 27.354 86.0995Z"
        fill="#2D6A62"
      />
      <Path
        d="M68.1795 75.1283C63.8716 76.8633 63.3291 79.78 60.1412 80.72C56.9533 81.66 54.2595 81.6788 51.7886 83.967C50.5021 85.1622 48.743 86.2176 46.9033 86.8542C46.6723 86.9374 46.436 87.0099 46.1997 87.0798C44.7279 87.4987 43.2373 87.6357 41.9186 87.3456C40.7181 87.0824 39.4371 85.8228 38.3547 84.2195C38.2903 84.1228 38.2284 84.0288 38.164 83.9294C36.48 81.3135 35.344 77.9215 35.8838 76.3879C35.9134 76.3047 40.3931 72.8159 45.9741 68.6719C46.0734 68.5994 46.1675 68.5295 46.2668 68.457C52.17 64.0874 59.2254 59.0516 63.5574 56.5459C71.0479 52.2165 78.8741 54.451 79.8758 61.638C80.8695 68.833 72.4901 73.3934 68.1795 75.1283Z"
        fill="#2B59E3"
      />
      <Path
        d="M46.9006 86.8542C46.6696 86.9374 46.4333 87.0099 46.197 87.0798C45.1146 86.838 44.0806 86.3788 43.1325 85.7826C42.1898 85.189 41.3976 84.4021 40.2723 84.1926C39.6331 84.0744 38.9885 84.1094 38.3547 84.2195C38.2903 84.1228 38.2284 84.0288 38.164 83.9294C39.5391 83.7011 40.8765 83.779 42.1334 84.6707C43.6213 85.7208 45.1576 86.6044 46.9006 86.8542Z"
        fill="#1D2744"
      />
      <Path
        d="M56.1314 77.1829L56.1287 77.2312C56.126 77.3226 56.0508 77.3951 55.9568 77.3951C55.9568 77.3951 55.9542 77.3951 55.9488 77.3951C55.8575 77.3924 55.7823 77.3118 55.7849 77.2178L55.7876 77.1695C55.8118 76.5786 55.8467 75.7675 55.5647 75.2814C55.3633 74.9323 54.896 74.6261 54.5173 74.379L54.1601 74.14C51.802 72.5527 47.9641 69.9825 45.9713 68.6719C46.0707 68.5994 46.1647 68.5295 46.2641 68.457C48.2891 69.7891 52.033 72.2976 54.3508 73.858L54.7053 74.0943C55.1108 74.3602 55.6211 74.6959 55.8602 75.1122C56.1932 75.6789 56.1583 76.5518 56.1314 77.1829Z"
        fill="#1D2744"
      />
      <Path
        d="M68.2118 84.7405C68.1876 84.856 68.1553 84.9634 68.115 85.0682C68.0237 85.3099 67.8894 85.5247 67.7202 85.7127C67.6289 85.8148 67.5269 85.9061 67.4195 85.992C67.1402 86.215 66.8071 86.3895 66.4445 86.5077C66.2619 86.5748 66.0686 86.6259 65.8725 86.6608C65.3192 86.7655 64.723 86.779 64.1644 86.6877C62.2521 86.3869 61.1591 85.9759 59.3596 85.283C58.0544 84.7808 56.7598 84.2034 55.4277 83.771C54.0473 83.3224 48.8477 81.9823 48.8477 81.9823L50.572 74.8786C50.572 74.8786 54.9255 76.5759 55.699 76.9143C56.4752 77.2527 58.9783 77.0996 60.4393 77.6851C62.1018 78.3512 62.2602 78.9823 63.6809 79.9358C65.5421 81.1819 67.6666 82.369 67.8787 82.8148C68.0023 83.07 68.0882 83.3117 68.15 83.54C68.1849 83.6635 68.2118 83.7844 68.2252 83.9052C68.2735 84.2114 68.2628 84.4881 68.2118 84.7405Z"
        fill="#A2CAC2"
      />
      <Path
        d="M56.972 77.6878C56.1824 77.5347 55.3902 77.3817 54.6006 77.2286C54.3857 77.1856 54.3159 77.5213 54.5307 77.5643L56.9022 78.0235C57.1197 78.0638 57.1895 77.7281 56.972 77.6878Z"
        fill="#2D6A62"
      />
      <Path
        d="M68.2252 83.9079C67.5457 83.7602 66.8823 83.5346 66.2592 83.1855C66.0685 83.0781 66.2189 82.7719 66.4096 82.8766C66.9709 83.1882 67.5537 83.3976 68.15 83.5427C68.1849 83.6689 68.2117 83.7898 68.2252 83.9079Z"
        fill="#2D6A62"
      />
      <Path
        d="M68.2118 84.7405C68.1876 84.856 68.1553 84.9634 68.115 85.0682C67.4624 84.9205 66.826 84.6975 66.2217 84.3618C66.031 84.2544 66.1814 83.9509 66.3747 84.0583C66.9602 84.386 67.5806 84.5982 68.2118 84.7405Z"
        fill="#2D6A62"
      />
      <Path
        d="M67.7175 85.7127C67.6262 85.8148 67.5242 85.9061 67.4168 85.992C66.8447 85.8443 66.2914 85.6375 65.7596 85.3421C65.5689 85.2347 65.7194 84.9312 65.91 85.0386C66.4875 85.3609 67.0971 85.5731 67.7175 85.7127Z"
        fill="#2D6A62"
      />
      <Path
        d="M66.4445 86.5104C66.2619 86.5775 66.0685 86.6286 65.8725 86.6635C65.6308 86.5668 65.3944 86.4513 65.1634 86.3251C64.9727 86.2177 65.1231 85.9142 65.3138 86.0216C65.6791 86.2257 66.0578 86.3842 66.4445 86.5104Z"
        fill="#2D6A62"
      />
      <Path
        d="M55.5432 75.2143C55.3418 74.7738 54.2917 74.2823 53.9157 74.0164C52.5406 73.0388 51.1521 72.0827 49.7528 71.1427C45.8263 68.5054 41.8165 65.9647 37.6617 63.6979C32.7173 61.0042 25.67 57.2119 19.8796 58.4205C15.9021 59.2531 13.9737 61.0498 13.9495 64.9334C13.9039 72.1848 23.1132 77.3978 27.1311 78.4559C31.1462 79.5141 31.8606 83.5239 34.7907 83.967C37.7208 84.4102 40.2131 82.8148 42.6652 84.7002C45.1172 86.5856 48.7457 88.1218 51.4851 86.9482C54.2191 85.7745 56.6363 77.6019 55.5432 75.2143Z"
        fill="#2B59E3"
      />
      <Path
        d="M39.9554 38.3609C39.9393 38.4791 39.9258 38.5999 39.9097 38.7235C39.3699 38.6187 38.83 38.5059 38.2956 38.3824C37.4657 38.1917 36.9877 37.8452 37.1112 36.9321C37.3126 35.42 37.9277 33.9322 38.7522 32.592C38.8945 32.5893 39.0341 32.5893 39.1684 32.592C38.4997 33.6636 37.9868 34.8238 37.6564 36.0539C37.4657 36.7548 37.2401 37.7432 38.1506 38.0037C38.6528 38.146 39.1926 38.2132 39.7083 38.3152L39.9554 38.3609Z"
        fill="#C7D2FE"
      />
      <Path
        d="M57.5065 38.3824C56.8431 38.5355 56.1797 38.6698 55.5083 38.796C55.5056 38.6724 55.4976 38.5489 55.4949 38.4307L56.0965 38.3152C56.6094 38.2132 57.1493 38.1487 57.6542 38.0037C58.5647 37.7432 58.3364 36.7548 58.1484 36.0539C57.8046 34.7862 57.2701 33.5938 56.5718 32.4926C56.7008 32.4846 56.835 32.4765 56.9747 32.4658C57.8395 33.8355 58.4868 35.3717 58.6963 36.9321C58.8171 37.8452 58.3364 38.1917 57.5065 38.3824Z"
        fill="#C7D2FE"
      />
    </Svg>
  );
};
