import React from 'react';
import { Button } from '../../../components/core';
import { IconBook } from '../../../components/icons/IconBook';
import { PageHeader } from '../../../components/page';
import { getRoute, useNavigate } from '../../../routes';

export const SelfCareHeader = (): JSX.Element => {
  const navigate = useNavigate();

  const onReflectionsPress = (): void => {
    navigate(getRoute('reflections', {}));
  };

  return (
    <PageHeader title="Self Care" testID="self-care-header">
      <Button.primarySmall
        leftIcon={<IconBook aria-hidden />}
        testID="self-care-go-to-reflections"
        onPress={onReflectionsPress}
      >
        Reflections
      </Button.primarySmall>
    </PageHeader>
  );
};
