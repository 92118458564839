import { JSX } from 'react';
import { Button, Heading, Layout, Text } from '../../components/core';
import { PageContent, PageFooter } from '../../components/page';
import { Portals } from '../../content/portal/portals';
import { usePortalContext } from '../../contexts/portalContext';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { BlockedPage } from '../blocked/BlockedPage';
import { Image404Dog } from './Image404Dog';

export const NotFoundPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { onlyIfPortalIsActive } = usePortalContext();

  const showBlock = onlyIfPortalIsActive(Portals.Blocked, true, false);
  if (showBlock) {
    return <BlockedPage />;
  }

  const onPressGoHome = (): void => {
    navigate(getRoute('home', {}));
  };
  const onPressGoToSelfCare = (): void => {
    navigate(getRoute('selfCareHome', {}));
  };

  return (
    <PageContent {...styles.page} pageSize="medium" testID="not-found-page">
      <Image404Dog alignSelf="center" size={194} />

      <Layout.VStack {...styles.stack} space={8}>
        <Layout.VStack space={4}>
          <Heading.h6 center>Oops...</Heading.h6>

          <Heading.h2 center>We’re having trouble finding that page.</Heading.h2>

          <Text.para center>
            You might be able to find what you’re looking for in our Self Care library.
          </Text.para>
        </Layout.VStack>

        <Layout.Shift {...styles.buttons}>
          <Button.secondaryMedium testID="button-not-found-go-to-home" onPress={onPressGoHome}>
            Take me home
          </Button.secondaryMedium>

          <Button.primaryMedium
            testID="button-not-found-go-to-self-care"
            onPress={onPressGoToSelfCare}
          >
            Browse Self Care
          </Button.primaryMedium>
        </Layout.Shift>
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  page: {
    height: '100%',
  },

  stack: {
    alignItems: 'center',
    display: 'flex',
  },

  buttons: {
    gap: 4,
  },
});
