import { usePrismicDocumentByID } from '@prismicio/react';
import { isEmpty } from 'lodash';
import { useReload } from '../../../utils/useReload';
import { ModuleDocument } from '../ModuleTypes';

type ModuleSimpleById = [
  ModuleDocument | undefined,
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const useModuleSimpleById = ({ moduleId }: { moduleId: string }): ModuleSimpleById => {
  const [shouldSkip, refetch] = useReload();

  const [module, { error, state }] = usePrismicDocumentByID<ModuleDocument>(moduleId, {
    skip: shouldSkip || isEmpty(moduleId),
  });

  return [
    module,
    {
      idle: state === 'idle',
      loading: state === 'loading',
      loaded: state === 'loaded',
      error,
      refetch,
    },
  ];
};
