import { SliceZone } from '@prismicio/react';
import { Layout } from '../../../components/core';
import { PageLoading } from '../../../components/page';
import { useSkillContext } from '../../../contexts';
import { usePrevious } from '../../../utils/usePrevious';
import { ModuleDocument } from '../../module/ModuleTypes';
import { SkillDocument } from '../SkillTypes';
import { SkillLanding } from './SkillLanding';
import { SkillTransition } from './SkillTransition';
import { SkillUpNext } from './SkillUpNext';
import { components as sliceComponents } from './slice';
import type { ReflectionQuestions, SliceContext } from './skillTypes';

type SkillProps = {
  module: ModuleDocument;
  questions: ReflectionQuestions | undefined;
  isIntro: boolean;
  isPathway: boolean;
  moduleWillBeComplete: boolean;
  upNextSkill?: SkillDocument;
  upNextSkillIsCompleted: boolean;
};

export const SkillSlides = ({ module, questions, ...upNextProps }: SkillProps): JSX.Element => {
  const { skill, slideCount, slideNumber } = useSkillContext();
  const previousSlideNumber = usePrevious(slideNumber) ?? 0;

  if (!skill) {
    // Don't render while the skill context is initializing.
    return <PageLoading pageName="skill" />;
  }

  const isGoingBack = slideNumber < previousSlideNumber;

  return (
    <Layout.View flex={1} position="relative">
      <SkillTransition
        key={1}
        slideCount={slideCount}
        slideNumber={1}
        visible={slideNumber === 1}
        back={isGoingBack}
      >
        <SkillLanding isVisible={slideNumber === 1} moduleId={module.id} skill={skill} />
      </SkillTransition>

      {skill.data.slices.map((slice, index) => {
        // +2 offsets it to 1-index based and accounts for the landing page.
        const iterationSlideNumber = index + 2;

        const isVisible = slideNumber === iterationSlideNumber;

        const context: SliceContext = {
          isVisible,
          moduleId: module.id,
          questions,
          skillId: skill.id,
          slideIndex: iterationSlideNumber,
        };

        return (
          <SkillTransition
            key={iterationSlideNumber}
            slideCount={slideCount}
            slideNumber={iterationSlideNumber}
            visible={isVisible}
            back={isGoingBack}
          >
            <SliceZone context={context} slices={[slice]} components={sliceComponents} />
          </SkillTransition>
        );
      })}

      <SkillTransition
        key={slideCount}
        slideCount={slideCount}
        slideNumber={slideCount}
        visible={slideNumber >= slideCount}
        back={isGoingBack}
      >
        <SkillUpNext
          {...upNextProps}
          isVisible={slideNumber >= slideCount}
          module={module}
          moduleIsComplete={upNextProps.moduleWillBeComplete}
        />
      </SkillTransition>
    </Layout.View>
  );
};
