import { useToast } from 'native-base';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Layout, Text } from '../../../components/core';
import { getRoute } from '../../../routes';
import { CoachingAssessmentSlideProps } from '../contexts/coachingAssessmentSlideContext';
import { useSaveCoachingAssessmentLazy } from '../hooks/useSaveCoachingAssessment';

export const CoachingAssessmentFinalSlide = ({
  assessmentType,
}: CoachingAssessmentSlideProps): JSX.Element => {
  const navigate = useNavigate();
  const toast = useToast();
  const [saveAnswers, { isSaving }] = useSaveCoachingAssessmentLazy({
    assessmentType,
    onCompleted: () => {
      navigate(getRoute('coaching', {}));
    },
    onError: () => {
      toast.show({
        description: 'Assessment could not be saved.',
        duration: 3000,
      });
    },
  });

  const onCompletePress = (): void => {
    toast.show({
      description: 'Saving assessment...',
      duration: 3000,
    });

    void saveAnswers();
  };

  return (
    <Layout.Slide>
      <Layout.VStack space={2}>
        <Heading.h3>Confirm your responses</Heading.h3>
        <Text.para>
          If you’re satisfied with your responses, click “Complete” below. You can go back to review
          your answers before submitting.{' '}
        </Text.para>
      </Layout.VStack>

      <Button.primaryMedium
        testID="coaching-assessment-complete"
        isDisabled={isSaving}
        onPress={onCompletePress}
      >
        Complete
      </Button.primaryMedium>
    </Layout.Slide>
  );
};
