import { createIcon, Path } from './index';

export const IconNavigation = createIcon({
  title: 'A navigation indicator',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M3 11L22 2L13 21L11 13L3 11Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
  ],
});
