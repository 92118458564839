import { merge } from 'lodash';
import { Badge, IBadgeProps } from 'native-base';
import React, { FC } from 'react';

export type BadgeProps = IBadgeProps;
export type BadgeComponent = FC<
  BadgeProps & {
    medium?: boolean;
  }
>;

export { Badge } from 'native-base';

const badgeFactory =
  (styles: IBadgeProps): BadgeComponent =>
  ({ children, medium = false, ...props }) => {
    const size = medium ? 'medium' : 'small';
    const sizeStyle: Size = sizeStyles[size];

    const finalStyles = merge({}, styles, sizeStyle);

    return (
      <Badge {...finalStyles} {...props}>
        {children}
      </Badge>
    );
  };

export const infoSolid = badgeFactory({
  colorScheme: 'primary',
  variant: 'solid',
});

export const infoOutline = badgeFactory({
  colorScheme: 'primary',
  variant: 'outline',
});

export const infoSubtle = badgeFactory({
  colorScheme: 'primary',
  variant: 'subtle',
});

export const defaultSolid = badgeFactory({
  colorScheme: 'secondary',
  variant: 'solid',
});

export const defaultOutline = badgeFactory({
  colorScheme: 'secondary',
  variant: 'outline',
});

export const defaultSubtle = badgeFactory({
  colorScheme: 'secondary',
  variant: 'subtle',
});

export const dangerSolid = badgeFactory({
  colorScheme: 'danger',
  variant: 'solid',
});

export const dangerOutline = badgeFactory({
  colorScheme: 'danger',
  variant: 'outline',
});

export const dangerSubtle = badgeFactory({
  colorScheme: 'danger',
  variant: 'subtle',
});

export const successSolid = badgeFactory({
  colorScheme: 'success',
  variant: 'solid',
});

export const successOutline = badgeFactory({
  colorScheme: 'success',
  variant: 'outline',
});

export const successSubtle = badgeFactory({
  colorScheme: 'success',
  variant: 'subtle',
});

export const warningSolid = badgeFactory({
  colorScheme: 'warning',
  variant: 'solid',
});

export const warningOutline = badgeFactory({
  colorScheme: 'warning',
  variant: 'outline',
});

export const warningSubtle = badgeFactory({
  colorScheme: 'warning',
  variant: 'subtle',
});

type SizeName = keyof typeof sizeStyles;
type Size = (typeof sizeStyles)[SizeName];

const sizeStyles = {
  small: {},

  medium: {
    _text: {
      fontSize: '14px',
    },
  },
};
