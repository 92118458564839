import { Heading, Layout, Text } from '../../../components/core';
import { PageContent } from '../../../components/page';
import { getStylesheet } from '../../../styles';
import { ImageConnectNowLaunchZoom } from '../images/ImageConnectNowLaunchZoom';

export const LaunchZoomScreen = (): JSX.Element => {
  return (
    <PageContent testID="connect-now-launch-zoom-screen" {...styles.pageContent}>
      <Layout.VStack space={8}>
        <Heading.h3 center>Launching...</Heading.h3>
        <Layout.View
          style={{
            alignItems: 'center',
          }}
        >
          <ImageConnectNowLaunchZoom />
        </Layout.View>
        <Text.para center>
          When prompted, complete the info in the dialog box to start the session.
        </Text.para>
        <Text.paraSmall center {...styles.callBox}>
          If you’re unable to connect via video and are in a crisis situation,{' '}
          <Text.paraSmall bold>call or text 988</Text.paraSmall>
        </Text.paraSmall>
        <Text.paraSmall center>
          If your session has ended, <Text.paraSmall bold>close this window.</Text.paraSmall>
        </Text.paraSmall>
      </Layout.VStack>
    </PageContent>
  );
};

const styles = getStylesheet({
  pageContent: {
    paddingY: 16,
    paddingX: 6,
  },
  callBox: {
    borderBottomWidth: 1,
    borderBottomColor: 'primary.300',
    borderTopWidth: 1,
    borderTopColor: 'primary.300',
    paddingY: '32px',
  },
});
