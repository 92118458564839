import { KeyboardAvoidingView, useToast } from 'native-base';
import React, { useEffect, useRef } from 'react';
import { AppState, Platform } from 'react-native';
import { Layout } from '../../components/core';
import { PageContent, PageError, PageLoading } from '../../components/page';
import { CoachingStatus, useCoachOverviewQuery } from '../../graphQL';
import { getStylesheet } from '../../styles';
import { useCoachingContext } from '../coaching/context/coachingContext';
import {
  BadgeCountCounterNameEnum,
  useBadgeCountReset,
} from '../coaching/hooks/useBadgeCountReset';
import { ChatHeader } from './components/ChatHeader';
import { CoachChat } from './components/CoachChat';
import { CoachChatCrisisWarning } from './components/CoachChatCrisisWarning';
import { generateNewMessageToast } from './components/NewMessageToast';
import { MessageCoachForm } from './forms/MessageCoachForm';
import { useChatMessages } from './hooks/useChatMessages';
import { ChatScrollProvider, useChatScrollContext } from './hooks/useChatScrollContext';

export const CoachingChatPage = (): JSX.Element => {
  return (
    <ChatScrollProvider>
      <CoachingChat />
    </ChatScrollProvider>
  );
};

const CoachingChat = (): JSX.Element => {
  const toast = useToast();
  const { scrollRef, scrollTo, scrollToEnd } = useChatScrollContext();
  const appState = useRef(AppState.currentState);

  const onInitialMessages = (): void => {
    scrollToEnd({ animated: true });
  };
  const onNewMessages = (): void => {
    const newMessagesId = 'new-messsages';
    if (toast.isActive(newMessagesId)) {
      return;
    }

    toast.show({
      id: newMessagesId,
      duration: null,
      placement: 'top',
      render: generateNewMessageToast({
        onClick: () => scrollToEnd({ animated: true }),
        onClose: () => toast.close(newMessagesId),
      }),
    });
  };
  const onWriteMessage = (): void => {
    scrollToEnd({ animated: true });
  };

  const { chatState, ...chatData } = useChatMessages({
    onInitialMessages,
    onNewMessages,
  });

  const { coachingStatus, coachingStatusState } = useCoachingContext();
  const { data: coachOverviewData, ...overviewState } = useCoachOverviewQuery();

  const [badgeCountReset] = useBadgeCountReset(BadgeCountCounterNameEnum.UnreadMessages);

  useEffect(() => {
    void badgeCountReset();
  }, [chatData.lastMessageId]);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        void badgeCountReset();
      }

      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);

  if (chatState.loading || coachingStatusState.loading || overviewState.loading) {
    return <PageLoading pageName="Coaching Chat Page" />;
  }

  if (chatState.error !== undefined || coachingStatusState.error !== undefined) {
    return (
      <PageError
        onRefreshPress={() => {
          void chatState.refetch();
          void coachingStatusState.refetch();
        }}
      />
    );
  }

  const coachingActive = coachingStatus === CoachingStatus.Active;
  const coachName = coachOverviewData?.coachOverview.coachName ?? '';
  const coachAvatarUrl = coachOverviewData?.coachOverview.coachAvatarUrl ?? '';

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} flex={1}>
      <PageContent {...styles.pageContent} testID="coaching-chat-page">
        <ChatHeader coachName={coachName} coachAvatarUrl={coachAvatarUrl} />
        <Layout.VStack {...styles.chatStack} paddingBottom={coachingActive ? 4 : 8} space={2}>
          <CoachChat
            scrollToEnd={scrollToEnd}
            scrollTo={scrollTo}
            scrollRef={scrollRef}
            coachingActive={coachingActive}
            coachName={coachName}
            chatState={chatState}
            {...chatData}
          />

          {coachingActive && (
            <Layout.VStack>
              <CoachChatCrisisWarning />

              <MessageCoachForm onWriteMessage={onWriteMessage} />
            </Layout.VStack>
          )}
        </Layout.VStack>
      </PageContent>
    </KeyboardAvoidingView>
  );
};

const styles = getStylesheet({
  pageContent: {
    height: '100%',
    justifyContent: 'space-between',
    paddingBottom: 0,
  },

  chatStack: {
    flex: 10,
    paddingX: 4,

    _web: {
      paddingX: 0,
    },
  },
});
