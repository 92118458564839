import { useEffect } from 'react';
import { Heading, Layout, Text } from '../../components/core';
import { PageError, PageLoading } from '../../components/page';
import { useConnectNowUserQuery, useIsConnectNowServiceAvailableQuery } from '../../graphQL';
import { getRoute, useNavigate } from '../../routes';
import { ConnectNowPageWrapper } from './components/ConnectNowPageWrapper';
import { CrisisSupport } from './components/CrisisSupport';
import { ConfirmContactForm } from './forms/ConfirmContactForm';
import { isDuringHoliday } from './utils/availability';

export function ConnectNowConfirmInfoPage(): JSX.Element {
  const { data: patientData, loading, error, refetch } = useConnectNowUserQuery();

  const { loading: checkingAvailability } = useIsConnectNowServiceAvailableQuery({
    onCompleted(data) {
      const isDuringServiceHours = data.isConnectNowServiceAvailable ?? false;

      if (!isDuringServiceHours || isDuringHoliday()) {
        navigate(getRoute('connectNowHome', {}), { replace: true });
      }
    },
    onError() {
      navigate(getRoute('connectNowHome', {}), { replace: true });
    },
  });

  const hasPhoneNumber = Boolean(patientData?.currentHubUser?.phone);
  const hasBirthDate = Boolean(patientData?.currentHubUser?.birthDate);
  const hasFirstName = Boolean(patientData?.currentHubUser?.firstName);
  const hasLastName = Boolean(patientData?.currentHubUser?.lastName);

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the encounter form if the user has already filled out the form
    if (hasFirstName && hasLastName && hasPhoneNumber && hasBirthDate) {
      navigate(getRoute('connectNowPreEncounterForm', {}), { replace: true });
    }
  }, [hasFirstName, hasLastName, hasPhoneNumber, hasBirthDate]);

  if (loading || checkingAvailability) {
    return <PageLoading pageName="connectnow confirm info" />;
  }

  if (error) {
    return <PageError onRefreshPress={refetch} showContact />;
  }

  return (
    <ConnectNowPageWrapper testID="connect-now-confirm-info-page">
      <Layout.VStack space={4}>
        <Heading.h2>Confirm your information</Heading.h2>
        <Layout.VStack space={8}>
          <Text.para>
            For your safety, we want to make sure your contact info is up-to-date.
          </Text.para>

          {patientData !== undefined && (
            <ConfirmContactForm
              hubUser={patientData.currentHubUser}
              nextRoute="connectNowPreEncounterForm"
            />
          )}
        </Layout.VStack>

        <CrisisSupport />
      </Layout.VStack>
    </ConnectNowPageWrapper>
  );
}
