import { registerRootComponent } from 'expo';
import { AppPlugins } from './AppPlugins';
import { useSetup } from './plugins';
import { Router } from './routes';
import Sentry from './utils/sentry';

export default function App(): JSX.Element | null {
  const { isReady } = useSetup();
  if (!isReady) {
    return null;
  }

  return (
    <Router>
      <AppPlugins />
    </Router>
  );
}

registerRootComponent(Sentry.wrap(App));
