import { createIcon, Path } from '../../../components/icons';

export const ImageHands = createIcon({
  title: 'Hands holding a compass.',
  viewBox: '0 0 790 790',
  path: [
    <Path
      d="M284.907 687.709L320.349 616.825C320.349 616.825 393.486 603.889 394.599 518.373C395.713 432.857 394.599 188.713 394.599 171.828C394.599 154.942 388.878 141.07 374.069 133.576C352.981 122.893 334.678 140.335 334.678 140.335C334.678 140.335 332.703 116.716 314.628 108.564C295.362 99.8811 277.287 108.843 269.414 123.171C269.414 123.171 268.072 107.678 249.01 102.413C227.922 96.5901 214.834 109.931 208.657 121.197C202.48 132.437 161.114 202.764 164.202 324.278C164.202 324.278 132.254 285.419 105.977 260.281C86.5595 241.724 66.7374 244.661 55.6492 253.167C43.9281 262.154 41.523 281.166 50.2316 300.988C59.4465 321.949 138.887 514.702 138.887 514.702L91.9011 615.686C91.9517 615.686 173.519 693.329 284.907 687.709Z"
      fill="#A2CAC2"
    />,
    <Path
      d="M164.356 325.164C173.115 337.67 180.937 350.733 187.697 364.429C189.14 367.34 193.519 364.783 192.076 361.872C185.317 348.176 177.494 335.113 168.735 322.607C166.862 319.974 162.482 322.506 164.356 325.164Z"
      fill="#35405F"
    />,
    <Path
      d="M267.489 126.234C272.223 157.22 264.375 186.662 257.97 216.762C251.186 248.508 249.085 279.798 254.578 311.949C255.135 315.164 259.995 313.797 259.464 310.607C253.337 274.684 257.768 240.61 265.666 205.421C271.691 178.612 276.577 152.385 272.375 124.892C271.869 121.677 267.008 123.044 267.489 126.234Z"
      fill="#35405F"
    />,
    <Path
      d="M332.957 141.729C333.236 158.943 332.856 176.158 330.831 193.246C328.907 209.397 324.274 224.789 321.616 240.763C316.806 269.547 316.907 299.521 319.363 328.532C319.641 331.747 324.705 331.773 324.426 328.532C321.869 298.179 321.312 265.902 327.641 235.953C334.325 204.283 338.527 174.259 338.021 141.729C337.97 138.463 332.907 138.463 332.957 141.729Z"
      fill="#35405F"
    />,
    <Path
      d="M219.923 315.951C266.96 312.153 318.199 317.014 350.906 355.089C353.033 357.57 356.602 353.975 354.476 351.519C320.958 312.533 268.2 307.014 219.923 310.913C216.708 311.166 216.658 316.229 219.923 315.951Z"
      fill="#35405F"
    />,
    <Path
      d="M276.704 612.444C290.274 618.469 304.45 621.228 319.285 620.494C322.526 620.342 322.551 615.279 319.285 615.431C305.387 616.115 291.995 613.735 279.261 608.064C276.325 606.748 273.743 611.127 276.704 612.444Z"
      fill="#35405F"
    />,
    <Path
      d="M283.338 546.906C294.806 471.339 266.402 389.165 190.556 360.735C187.493 359.596 186.177 364.482 189.215 365.621C262.225 392.987 289.464 472.985 278.452 545.565C277.971 548.754 282.857 550.121 283.338 546.906Z"
      fill="#35405F"
    />,
    <Path
      d="M505.304 687.709L469.862 616.825C469.862 616.825 396.725 603.889 395.611 518.373C394.497 432.857 395.611 188.713 395.611 171.828C395.611 154.942 401.333 141.07 416.142 133.576C437.23 122.893 455.533 140.335 455.533 140.335C455.533 140.335 457.508 116.716 475.583 108.564C494.848 99.8811 512.924 108.843 520.797 123.171C520.797 123.171 522.139 107.678 541.201 102.413C562.289 96.5901 575.377 109.931 581.554 121.197C587.731 132.462 629.097 202.764 626.008 324.278C626.008 324.278 657.957 285.419 684.234 260.281C703.651 241.724 723.474 244.661 734.562 253.167C746.283 262.154 748.688 281.166 739.979 300.988C730.764 321.949 651.324 514.702 651.324 514.702L698.31 615.686C698.259 615.686 616.692 693.329 505.304 687.709Z"
      fill="#A2CAC2"
    />,
    <Path
      d="M621.502 322.607C612.743 335.113 604.92 348.176 598.161 361.872C596.718 364.783 601.097 367.34 602.54 364.429C609.3 350.733 617.122 337.67 625.881 325.164C627.729 322.506 623.35 319.974 621.502 322.607Z"
      fill="#35405F"
    />,
    <Path
      d="M517.835 124.892C513.633 152.385 518.519 178.612 524.544 205.421C532.442 240.61 536.872 274.684 530.746 310.607C530.214 313.797 535.075 315.164 535.632 311.949C541.126 279.798 538.999 248.508 532.24 216.762C525.809 186.687 517.962 157.22 522.721 126.234C523.227 123.044 518.342 121.677 517.835 124.892Z"
      fill="#35405F"
    />,
    <Path
      d="M452.193 141.729C451.686 174.259 455.889 204.283 462.572 235.953C468.901 265.902 468.344 298.179 465.787 328.532C465.509 331.773 470.572 331.773 470.85 328.532C473.306 299.521 473.432 269.547 468.597 240.763C466.04 225.523 461.585 210.815 459.635 195.423C457.382 177.601 456.977 159.677 457.256 141.729C457.306 138.463 452.243 138.463 452.193 141.729Z"
      fill="#35405F"
    />,
    <Path
      d="M570.291 310.889C522.014 307.015 469.256 312.509 435.738 351.495C433.637 353.95 437.181 357.545 439.308 355.064C472.016 317.015 523.254 312.154 570.291 315.926C573.556 316.23 573.531 311.167 570.291 310.889Z"
      fill="#35405F"
    />,
    <Path
      d="M510.952 608.064C498.218 613.735 484.826 616.115 470.928 615.431C467.662 615.279 467.688 620.342 470.928 620.494C485.763 621.228 499.94 618.469 513.509 612.444C516.471 611.127 513.914 606.748 510.952 608.064Z"
      fill="#35405F"
    />,
    <Path
      d="M511.76 545.565C500.748 472.985 527.988 392.987 600.998 365.621C604.01 364.482 602.719 359.596 599.656 360.735C523.81 389.165 495.407 471.364 506.875 546.906C507.381 550.121 512.241 548.754 511.76 545.565Z"
      fill="#35405F"
    />,
    <Path
      d="M392.575 177.951C392.575 295.821 392.575 413.715 392.575 531.585C392.575 534.85 397.638 534.85 397.638 531.585C397.638 413.715 397.638 295.821 397.638 177.951C397.638 174.686 392.575 174.686 392.575 177.951Z"
      fill="#35405F"
    />,
    <Path
      d="M395.107 465.873C470.956 465.873 532.444 404.385 532.444 328.536C532.444 252.687 470.956 191.199 395.107 191.199C319.257 191.199 257.77 252.687 257.77 328.536C257.77 404.385 319.257 465.873 395.107 465.873Z"
      fill="#F67E6A"
    />,
    <Path
      d="M395.107 210.969C406.39 210.969 415.536 201.822 415.536 190.539C415.536 179.256 406.39 170.109 395.107 170.109C383.824 170.109 374.677 179.256 374.677 190.539C374.677 201.822 383.824 210.969 395.107 210.969Z"
      fill="#F67E6A"
    />,
    <Path
      d="M395.106 185.094C382.448 185.094 372.145 174.791 372.145 162.133C372.145 149.475 382.448 139.172 395.106 139.172C407.764 139.172 418.067 149.475 418.067 162.133C418.067 174.791 407.764 185.094 395.106 185.094ZM395.106 144.26C385.233 144.26 377.208 152.285 377.208 162.158C377.208 172.032 385.233 180.057 395.106 180.057C404.979 180.057 413.004 172.032 413.004 162.158C413.004 152.285 404.979 144.26 395.106 144.26Z"
      fill="#F67E6A"
    />,
    <Path
      d="M395.106 450.121C462.259 450.121 516.697 395.683 516.697 328.53C516.697 261.378 462.259 206.939 395.106 206.939C327.954 206.939 273.516 261.378 273.516 328.53C273.516 395.683 327.954 450.121 395.106 450.121Z"
      fill="white"
    />,
    <Path
      d="M325.235 268.991L371.411 352.203L395.081 328.533L418.751 304.863L335.539 258.687C328.906 254.966 321.539 262.333 325.235 268.991Z"
      fill="#F67E6A"
    />,
    <Path
      d="M464.978 388.074L418.802 304.861L395.132 328.531L371.462 352.202L454.674 398.377C461.332 402.099 468.674 394.757 464.978 388.074Z"
      fill="#35405F"
    />,
    <Path
      d="M473.914 320.102C473.914 330.558 473.914 341.038 473.914 351.493C473.914 354.759 478.977 354.759 478.977 351.493C478.977 341.038 478.977 330.558 478.977 320.102C478.977 316.862 473.914 316.862 473.914 320.102Z"
      fill="#35405F"
    />,
    <Path
      d="M476.445 322.635C482.622 322.635 488.773 322.635 494.95 322.635C498.216 322.635 498.216 317.572 494.95 317.572C488.773 317.572 482.622 317.572 476.445 317.572C473.204 317.572 473.179 322.635 476.445 322.635Z"
      fill="#35405F"
    />,
    <Path
      d="M476.445 354.053C482.622 354.053 488.773 354.053 494.95 354.053C498.216 354.053 498.216 348.99 494.95 348.99C488.773 348.99 482.622 348.99 476.445 348.99C473.204 348.99 473.179 354.053 476.445 354.053Z"
      fill="#35405F"
    />,
    <Path
      d="M476.445 334.229C479.862 334.229 483.254 334.229 486.672 334.229C489.938 334.229 489.938 329.166 486.672 329.166C483.254 329.166 479.862 329.166 476.445 329.166C473.204 329.166 473.179 334.229 476.445 334.229Z"
      fill="#35405F"
    />,
    <Path
      d="M408.144 398.456C404.27 393.773 395.84 392.127 394.625 400.077C393.941 404.507 397.41 408.785 399.815 412.127C401.84 414.937 404.574 418.202 404.22 421.899C403.587 428.329 397.005 427.544 394.448 422.582C392.98 419.671 388.6 422.228 390.068 425.139C393.688 432.227 405.106 435.113 408.65 426.278C410.523 421.62 408.751 416.43 406.194 412.431C404.65 410 402.498 407.874 401.207 405.292C399.08 401.064 400.017 396.558 404.549 402.051C406.65 404.557 410.22 400.963 408.144 398.456Z"
      fill="#35405F"
    />,
    <Path
      d="M389.232 225.193C389.232 235.649 389.232 246.129 389.232 256.585C389.232 259.85 394.296 259.85 394.296 256.585C394.296 246.129 394.296 235.649 394.296 225.193C394.296 221.928 389.232 221.928 389.232 225.193Z"
      fill="#35405F"
    />,
    <Path
      d="M408.445 225.193C408.445 235.649 408.445 246.129 408.445 256.585C408.445 259.85 413.508 259.85 413.508 256.585C413.508 246.129 413.508 235.649 413.508 225.193C413.508 221.928 408.445 221.928 408.445 225.193Z"
      fill="#35405F"
    />,
    <Path
      d="M413.155 255.319C406.548 244.965 400.193 234.484 393.941 223.928C392.27 221.117 387.89 223.674 389.561 226.484C395.814 237.041 402.194 247.547 408.776 257.876C410.548 260.61 414.927 258.078 413.155 255.319Z"
      fill="#35405F"
    />,
    <Path
      d="M289.134 320.789C291.944 331.219 294.501 341.7 297.083 352.181C297.868 355.345 302.729 354.003 301.969 350.839C299.412 340.358 296.83 329.878 294.02 319.448C293.159 316.308 288.274 317.625 289.134 320.789Z"
      fill="#35405F"
    />,
    <Path
      d="M301.691 352.789C303.995 349.65 306.425 346.612 308.982 343.675C311.109 341.22 307.539 337.625 305.413 340.106C302.577 343.371 299.894 346.738 297.312 350.232C295.388 352.865 299.767 355.396 301.691 352.789Z"
      fill="#35405F"
    />,
    <Path
      d="M320.4 319.447C317.59 329.877 315.033 340.358 312.451 350.839C311.666 354.003 316.552 355.345 317.337 352.18C319.894 341.7 322.476 331.219 325.286 320.789C326.122 317.65 321.236 316.283 320.4 319.447Z"
      fill="#35405F"
    />,
    <Path
      d="M317.083 350.23C314.526 346.736 311.818 343.369 308.982 340.104C306.83 337.648 303.261 341.243 305.413 343.673C307.969 346.61 310.4 349.648 312.704 352.787C314.628 355.394 319.007 352.863 317.083 350.23Z"
      fill="#35405F"
    />,
  ],
});
