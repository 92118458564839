import { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { usePreSignUpMutation } from '../../../graphQL';
import { isRateLimited } from '../../../utils/graphql';
import { SignupErrorModal, SignupErrorType } from '../components/SignupErrorModal';
import { SignupRateLimitError } from '../components/SignupRateLimitError';
import { useAppType } from '../hooks/useAppType';
import { useSsoNavigation } from '../hooks/useSsoNavigation';

type SignupEmailFormProps = { onSuccess: (email: string) => void };

export const SignupEmailForm = ({ onSuccess }: SignupEmailFormProps): JSX.Element => {
  const appType = useAppType();
  const ssoNavigate = useSsoNavigation({
    onError: ssoNavError => {
      formContext.setError('submit', ssoNavError);
    },
  });

  const [showRateLimitError, setShowRateLimitError] = useState(false);
  const [signupErrorType, setSignupErrorType] = useState<SignupErrorType | undefined>();
  const formContext = useForm({
    defaultValues: {
      email: '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  const userEmail = watch('email');

  const [validateEmailForSignup, { loading }] = usePreSignUpMutation({
    onCompleted: signupResponse => {
      const responseType = signupResponse.preSignUp.typename;

      if (responseType === 'PreSignUpSuccess') {
        onSuccess(userEmail);
        return;
      }

      if (responseType === 'PreSignUpSsoRedirect') {
        ssoNavigate(signupResponse.preSignUp.samlEntryPoint);
        return;
      }

      if (responseType === 'PreSignUpError') {
        const { errorCode } = signupResponse.preSignUp;

        if (errorCode === 'UnknownPartner') {
          setSignupErrorType('unavailable');
        } else if (errorCode === 'EligibilityError') {
          setSignupErrorType('isIneligible');
        } else if (errorCode === 'CampusReferralRequired') {
          setSignupErrorType('refRequired');
        }
        return;
      }

      formContext.setError('submit', {
        type: 'unknown',
        message: 'An internal error occurred.',
      });
    },
    onError: authError => {
      if (isRateLimited(authError)) {
        setShowRateLimitError(true);
        return;
      }

      formContext.setError('submit', authError);
    },
  });

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();
    setSignupErrorType(undefined);
    setShowRateLimitError(false);

    void handleSubmit(async ({ email }): Promise<void> => {
      await validateEmailForSignup({ variables: { app: appType, email } });
    })();
  };

  const onErrorClose = (): void => {
    setSignupErrorType(undefined);
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.View>
          <FormInput
            testID="signup-input-email"
            label="Email address"
            name="email"
            placeholder="School email address"
            autoCapitalize="none"
            autoFocus
            isRequired
            rules={{ pattern: { value: /.+@.+\..+/, message: 'You must enter a valid email.' } }}
            control={control}
            error={errors.email}
            keyboardType="email-address"
            onSubmitEditing={submitForm}
          />

          <Layout.VStack aria-live="polite" space={3}>
            {showRateLimitError && (
              <Layout.View marginTop={6}>
                <SignupRateLimitError />
              </Layout.View>
            )}

            {errors.submit && (
              <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
            )}
          </Layout.VStack>

          {signupErrorType !== undefined && (
            <SignupErrorModal errorType={signupErrorType} onClose={onErrorClose} />
          )}
        </Layout.View>

        <Layout.VStack space={2}>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-signup-email-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
