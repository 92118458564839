import { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout, Link } from '../../../components/core';
import { FormErrorSubmit, FormInputPassword } from '../../../components/form';
import { SetPasswordWithTokenErrorCode, useSetPasswordWithTokenMutation } from '../../../graphQL';
import { getRoute } from '../../../routes';
import { usePasswordRules } from '../hooks/usePasswordRules';

type SignupEmailFormProps = {
  token: string;
  tokenId: string;
  onSuccess: () => void;
};

type TokenExpiredError =
  | Exclude<SetPasswordWithTokenErrorCode, 'InvalidPasswordFormat'>
  | undefined;

export const NewPasswordForm = ({
  onSuccess,
  token,
  tokenId,
}: SignupEmailFormProps): JSX.Element => {
  const [tokenExpiredError, setTokenExpiredError] = useState<TokenExpiredError>();

  const formContext = useForm({
    defaultValues: {
      password: '',
      passwordConfirm: '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  const [setNewPassword, { loading }] = useSetPasswordWithTokenMutation({
    onCompleted: resetResponse => {
      const responseType = resetResponse.setPasswordWithToken.__typename;

      if (responseType === 'SetPasswordWithTokenError') {
        const { errorCode, message } = resetResponse.setPasswordWithToken;

        if (errorCode === 'InvalidPasswordFormat') {
          formContext.setError('password', { message, type: 'password' });
          return;
        }

        // Take them back to reset password to start the process over.
        setTokenExpiredError(errorCode);
        return;
      }

      onSuccess();
    },
    onError: authError => {
      formContext.setError('submit', authError);
    },
  });

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();
    setTokenExpiredError(undefined);

    void handleSubmit(async ({ password }): Promise<void> => {
      await setNewPassword({ variables: { password, token, tokenId: Number(tokenId) } });
    })();
  };

  const passwordValue = watch('password');

  const { passwordRules, confirmPasswordRules } = usePasswordRules(passwordValue);

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.VStack space={3}>
          <FormInputPassword
            label="New password"
            name="password"
            placeholder="Password"
            rules={passwordRules}
            autoFocus
            isRequired
            control={control}
            error={errors.password}
            onSubmitEditing={submitForm}
          />

          <FormInputPassword
            label="Confirm new password"
            name="passwordConfirm"
            placeholder="Confirm Password"
            rules={confirmPasswordRules}
            isRequired
            control={control}
            error={errors.passwordConfirm}
            onSubmitEditing={submitForm}
          />

          <Layout.VStack aria-live="polite" space={3}>
            {errors.submit && (
              <FormErrorSubmit marginTop={3}>{errors.submit.message}</FormErrorSubmit>
            )}

            {tokenExpiredError === 'TokenExpired' && (
              <FormErrorSubmit marginTop={3}>
                Reset password link expired. Please go back to the{' '}
                <Link.para to={getRoute('loginResetPassword', {})}>reset password</Link.para> page
                to generate a new link.
              </FormErrorSubmit>
            )}

            {tokenExpiredError === 'InvalidToken' && (
              <FormErrorSubmit marginTop={3}>
                Reset password link was invalid. Please go back to the{' '}
                <Link.para to={getRoute('loginResetPassword', {})}>reset password</Link.para> page
                to generate a new link.
              </FormErrorSubmit>
            )}
          </Layout.VStack>
        </Layout.VStack>

        <Layout.VStack space={2}>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-new-password-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
