import { defaultTo } from 'lodash';
import { ArrowBackIcon, CloseIcon, PresenceTransition } from 'native-base';
import { ReactNode, useState } from 'react';
import { ImageBackground as NativeImageBackground } from 'react-native';
import { SkillDocument, SkillDocumentDataSlicesSlice } from '../../../../.slicemachine/prismicio';
import { getInfoSlideImageUrl, getSkillImageUrl } from '../../../content/skill/SkillUtils';
import { SkillProvider, useSkillContext } from '../../../contexts';
import { useBreakpointSwitch } from '../../../contexts/breakpointContext';
import {
  getRoute,
  RouteParams,
  useCurrentRouteName,
  useCurrentRouteSearch,
  useNavigate,
  useParams,
} from '../../../routes';
import { getStylesheet } from '../../../styles';
import { Button, Heading, Layout, Progress } from '../../core';
import { ProgressDots } from '../../core/Progress';
import { ConfirmLeaveModal } from '../../modals/ConfirmLeaveModal';

type SkillLayoutProps = {
  children: ReactNode;
};

type SkillPageParams = RouteParams<'skill'>;

export const SkillLayout = ({ children }: SkillLayoutProps): JSX.Element => {
  return (
    <SkillProvider>
      <SkillWrapper>{children}</SkillWrapper>
    </SkillProvider>
  );
};

const ImageBackground = ({
  backgroundImage,
  children,
}: {
  backgroundImage?: string;
  children: ReactNode;
}): JSX.Element => {
  if (backgroundImage === undefined || backgroundImage === '') {
    return <>{children}</>;
  }

  return (
    <NativeImageBackground
      resizeMode="cover"
      source={{ uri: backgroundImage }}
      style={{ height: '100%', width: '100%' }}
    >
      {children}
    </NativeImageBackground>
  );
};

const getSlideStyles = (
  skill?: SkillDocument,
  currentSlide?: SkillDocumentDataSlicesSlice,
): {
  backgroundImageDesktop: string;
  backgroundImageMobile: string;
  color: string;
} => {
  if (!skill) {
    return {
      backgroundImageDesktop: '',
      backgroundImageMobile: '',
      color: 'blue',
    };
  }

  // Determine the background images and color.
  const mainBgImageDesktop = getSkillImageUrl(skill, 'desktop');
  const mainBgImageMobile = getSkillImageUrl(skill, 'mobile');
  const mainColor = defaultTo(skill.data.color, 'blue');

  if (!currentSlide || currentSlide.slice_type !== 'info_slide') {
    return {
      backgroundImageDesktop: mainBgImageDesktop,
      backgroundImageMobile: mainBgImageMobile,
      color: mainColor,
    };
  }

  const slidePrimary = currentSlide?.primary;
  const slideColor = defaultTo(slidePrimary?.color, '');
  const slideBgImageDesktop = getInfoSlideImageUrl(slidePrimary, 'background_desktop');
  const slideBgImageMobile = getInfoSlideImageUrl(slidePrimary, 'background_mobile');

  return {
    backgroundImageDesktop: slideBgImageDesktop || mainBgImageDesktop,
    backgroundImageMobile: slideBgImageMobile || mainBgImageMobile,
    color: slideColor || mainColor,
  };
};

const SkillWrapper = ({ children }: SkillLayoutProps): JSX.Element => {
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { moduleId } = useParams<SkillPageParams>();
  const { currentSlide, skill, skillName, slideCount, slideNumber, triggerPrevSlide } =
    useSkillContext();

  const route = useCurrentRouteName();
  const isTour = route === 'tour';
  const isSelfCareQuiz = route === 'selfCareQuiz';
  const isSkill = route === 'skill';

  const routeSearch = useCurrentRouteSearch();
  const isPath = routeSearch.path !== undefined;

  const slideStyles = getSlideStyles(skill, currentSlide);

  const backgroundImage = useBreakpointSwitch({
    mobile: slideStyles.backgroundImageMobile,
    desktop: slideStyles.backgroundImageDesktop,
  });

  const hasStarted = slideNumber > 1;
  const isFinalSlide = slideNumber === slideCount;

  // We want the progress bar to ignore the completion slide and the up next slide (slideCount - 2).
  const offsetSlideCount = isSkill ? slideCount - 2 : slideCount;

  // We also have to adjust our numbers to be 0-indexed or the percent math won't work (both - 1).
  const progressPercent = Math.round(((slideNumber - 1) * 100) / (offsetSlideCount - 1)) || 0;

  const onCloseSkillsPressed = (): void => {
    // If on one of the last 2 slides (completion and up next), don't show the close modal, just leave.
    if (slideNumber >= slideCount - 1) {
      onModalLeave();
      return;
    }

    setShowConfirmModal(true);
  };

  const onModalClose = (): void => {
    setShowConfirmModal(false);
  };

  const onModalLeave = (): void => {
    if (isSelfCareQuiz) {
      navigate(getRoute('selfCareHome', {}));
    } else if (isPath) {
      navigate(getRoute('skillPathway', {}));
    } else if (isTour) {
      navigate(getRoute('home', {}));
    } else {
      navigate(getRoute('module', { moduleId: moduleId ?? '' }));
    }
  };

  const onBackPressed = (): void => {
    if (!hasStarted) {
      onCloseSkillsPressed();
      return;
    }

    triggerPrevSlide();
  };

  const animation = {
    initial: {
      translateY: -200,
    },
    animate: {
      translateY: 0,
      transition: {
        duration: 250,
      },
    },
  };

  return (
    <Layout.View
      {...styles.base}
      background={`${slideStyles.color}.100`}
      testID="skill-layout"
      safeAreaTop
    >
      {showConfirmModal && <ConfirmLeaveModal onClose={onModalClose} onConfirm={onModalLeave} />}

      <ImageBackground backgroundImage={backgroundImage}>
        <Layout.HStack {...styles.header} role="banner" space={0}>
          <PresenceTransition visible={progressPercent > 0} {...animation}>
            {!isFinalSlide ? (
              <Button.tertiaryMedium
                aria-label="Show the previous slide"
                leftIcon={<ArrowBackIcon aria-hidden />}
                testID="button-skill-back"
                onPress={onBackPressed}
              />
            ) : (
              <Button.tertiaryMedium
                aria-label="Show the previous slide"
                leftIcon={<ArrowBackIcon aria-hidden />}
                testID="button-skill-back"
                opacity={0}
                disabled
              />
            )}
          </PresenceTransition>

          {!isTour ? (
            <PresenceTransition
              visible={progressPercent > 0 && progressPercent < 101}
              {...animation}
            >
              <Heading.h5 {...styles.skillTitle} level={1}>
                {skillName}
              </Heading.h5>
            </PresenceTransition>
          ) : (
            <PresenceTransition
              visible={progressPercent > 0 && progressPercent < 100}
              {...animation}
            >
              <ProgressDots aria-label="Tour progress" value={slideNumber} maxValue={slideCount} />
            </PresenceTransition>
          )}

          <Button.tertiaryMedium
            aria-label="Close"
            rightIcon={<CloseIcon aria-hidden />}
            testID="button-skill-close"
            onPress={onCloseSkillsPressed}
          />
        </Layout.HStack>

        {!isTour && (
          <PresenceTransition visible={progressPercent > 0 && progressPercent < 101} {...animation}>
            <Layout.Center position="absolute" left={0} right={0} bottom={3}>
              <Progress
                aria-label="Skill progress"
                variant="skill"
                width="225px"
                aria-valuetext={String(slideNumber)}
                aria-valuemax={slideCount}
                aria-valuemin={1}
                value={progressPercent}
              />
            </Layout.Center>
          </PresenceTransition>
        )}

        <Layout.Box {...styles.main} role="main">
          {children}
        </Layout.Box>
      </ImageBackground>
    </Layout.View>
  );
};

const styles = getStylesheet({
  base: {
    flex: 1,
  },

  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 2,
    paddingBottom: 6,
    paddingX: 1,
  },

  skillTitle: {
    textAlign: 'center',
  },

  main: {
    flex: 1,
  },
});
