import { defaultTo, throttle } from 'lodash';
import {
  Consumer,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { useParams } from '../../routes';

type OnNavigate = (newSlideNumber: string) => void;

export type SlideContextType<Slide> = {
  registerNavigation: (onNav: (newSlideNumber: string) => void) => void;
  registerSlides: (newSlides: Slide[]) => void;
  currentSlide?: Slide;
  nextSlide?: Slide;
  slideCount: number;
  slideNumber: number;
  slides: Slide[];
  triggerNextSlide: () => void;
  triggerPrevSlide: () => void;
  triggerFirstSlide: () => void;
  triggerLastSlide: () => void;
};

type FactoryReturnType<T> = {
  SlideConsumer: Consumer<SlideContextType<T>>;
  SlideProvider: ({ children }: { children: ReactNode }) => JSX.Element;
  useSlideContext: () => SlideContextType<T>;
};

export const slideContextFactory = <Slide,>(): FactoryReturnType<Slide> => {
  const SlideContext = createContext<SlideContextType<Slide>>({
    registerNavigation: () => undefined,
    registerSlides: () => undefined,
    currentSlide: undefined,
    nextSlide: undefined,
    slideCount: 0,
    slideNumber: 1,
    slides: [],
    triggerNextSlide: () => undefined,
    triggerPrevSlide: () => undefined,
    triggerFirstSlide: () => undefined,
    triggerLastSlide: () => undefined,
  });

  type RatingsAction = {
    type: 'setSlides';
    slides: Slide[];
  };
  type RatingsState = Slide[];

  const SlideProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { slide: rawSlideNumber } = useParams<{ slide: string }>();
    const slideNumber = Math.max(1, defaultTo(Number(rawSlideNumber), 1)); // The current slide (1-indexed).
    const slideIndex = slideNumber - 1; // The slide index (0-indexed).

    const [onNavigate, setNavigation] = useState<OnNavigate>(() => undefined);

    const [slides, slidesDispatch] = useReducer((state: RatingsState, action: RatingsAction) => {
      if (action.type === 'setSlides') {
        if (state.length > 0) {
          // eslint-disable-next-line no-console
          console.error(
            'Attempt to set slides in slide context but they have already been set. This would cause rendering issues so it is not allowed.',
          );
          return state;
        }

        return action.slides;
      }
      throw new Error('Unknown slides action.');
    }, []);

    const registerNavigation = (onNav: (newSlideNumber: string) => void): void => {
      setNavigation(() =>
        throttle(onNav, Platform.OS === 'web' ? 700 : 1000, {
          leading: true,
          trailing: false,
        }),
      );
    };

    const registerSlides = (newSlides: Slide[]): void => {
      slidesDispatch({
        type: 'setSlides',
        slides: newSlides,
      });
    };

    const { currentSlide, nextSlide, slideCount } = useMemo(() => {
      return {
        currentSlide: slides[slideIndex],
        nextSlide: slides[slideIndex + 1],
        slideCount: slides.length,
      };
    }, [slides, slideIndex]);

    const triggerNextSlide = useCallback((): void => {
      const nextSlideNumber = Math.min(slideCount, slideNumber + 1);
      onNavigate(String(nextSlideNumber));
    }, [onNavigate, slideCount, slideNumber]);

    const triggerPrevSlide = useCallback((): void => {
      const prevSlideNumber = Math.max(1, slideNumber - 1);
      onNavigate(String(prevSlideNumber));
    }, [onNavigate, slideNumber]);

    const triggerFirstSlide = useCallback((): void => onNavigate('1'), [onNavigate]);

    const triggerLastSlide = useCallback(
      (): void => onNavigate(String(slideCount ?? '1')),
      [onNavigate, slideCount],
    );

    const providerValue: SlideContextType<Slide> = {
      registerNavigation,
      registerSlides,
      currentSlide,
      nextSlide,
      slideCount,
      slideNumber,
      slides,
      triggerNextSlide,
      triggerPrevSlide,
      triggerFirstSlide,
      triggerLastSlide,
    };

    return <SlideContext.Provider value={providerValue}>{children}</SlideContext.Provider>;
  };

  const SlideConsumer = SlideContext.Consumer;

  const useSlideContext = (): SlideContextType<Slide> => {
    return useContext(SlideContext);
  };

  return {
    SlideConsumer,
    SlideProvider,
    useSlideContext,
  };
};
