import { JSX } from 'react';
import { Heading, Layout, Link, Text } from '../../../components/core';
import { useOrganizationCustomHubModuleQuery } from '../../../graphQL';
import { getStylesheet } from '../../../styles';

export const CustomModule = (): JSX.Element => {
  const { data, loading } = useOrganizationCustomHubModuleQuery();
  const isEnabled = data?.organizationCustomHubModule?.isEnabled ?? false;

  if (loading || !isEnabled) {
    return <></>;
  }

  const moduleData = data?.organizationCustomHubModule?.data ?? {};
  const title = moduleData.title ?? '';
  const description = moduleData.description ?? '';
  const link = moduleData.link ?? '';
  const url = moduleData.url ?? '';

  if (!title && !description && !link && !url) {
    return <></>;
  }

  return (
    <Layout.VStack {...styles.customModule} space={2}>
      {title && <Heading.h3>{title}</Heading.h3>}

      {description && <Text.para>{description}</Text.para>}

      {url && link && (
        <Link.para bold to={url}>
          {link}
        </Link.para>
      )}
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  customModule: {
    backgroundColor: 'secondary.50',
    borderRadius: 4,
    minWidth: '45%',
    padding: 6,
    shadow: 'large',
  },
});
