import { FormControl } from 'native-base';
import { JSX } from 'react';
import { Text } from '../core';

export type FormLabelProps = {
  inputId?: string;
  isRequired?: boolean;
  label?: string;
  necessityIndicator?: boolean;
};

export const FormLabel = ({
  inputId,
  isRequired = false,
  label = '',
  necessityIndicator = false,
}: FormLabelProps): JSX.Element => {
  if (label === '') {
    return <></>;
  }

  return (
    <FormControl.Label nativeID={inputId}>
      <Text.para flexGrow={1}>
        {label}
        {necessityIndicator && isRequired && (
          <Text.label alignSelf="center" color="red.500">
            {' *'}
          </Text.label>
        )}
        {necessityIndicator && !isRequired && (
          <Text.para italic alignSelf="center">
            {' - optional'}
          </Text.para>
        )}
      </Text.para>
    </FormControl.Label>
  );
};
