import { mapKeys } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { formatWithMask, Masks } from 'react-native-mask-input';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput, FormInputMasked, FormSelect } from '../../../components/form';
import { FormLabel } from '../../../components/form/FormLabel';
import { PageError } from '../../../components/page';
import {
  ConnectNowUserQuery,
  StateCodes,
  UpdateHubUserErrorCode,
  useUpdateHubUser2Mutation,
} from '../../../graphQL';
import { getRoute, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { convertDateForDisplay, DATE_REGEX } from '../../../utils/date';
import { PHONE_MASK } from '../../../utils/phone';

type ConfirmContactFormProps = {
  hubUser: ConnectNowUserQuery['currentHubUser'];
  nextRoute: 'connectNowPreEncounterForm' | 'findCareDemographics';
};

const uppercasedStateCodes = mapKeys(StateCodes, value => value.toUpperCase());

export function ConfirmContactForm({ hubUser, nextRoute }: ConfirmContactFormProps): JSX.Element {
  const [pageError, setPageError] = useState(false);
  const [update] = useUpdateHubUser2Mutation({
    onCompleted: async ({ updateHubUser }) => {
      const responseType = updateHubUser?.__typename;
      if (responseType === 'UpdateHubUserSuccess') {
        navigate(getRoute(nextRoute, {}));
        return;
      }

      if (responseType === 'UpdateHubUserError') {
        if (updateHubUser.errorCode === UpdateHubUserErrorCode.DuplicatePhone) {
          formContext.setError('phone', {
            type: 'error',
            message: 'This phone number is already in use. Please enter a new number',
          });
          return;
        }

        if (updateHubUser.errorCode === UpdateHubUserErrorCode.InvalidPhone) {
          formContext.setError('phone', {
            type: 'error',
            message: updateHubUser.message,
          });
          return;
        }

        if (updateHubUser.errorCode === UpdateHubUserErrorCode.InvalidState) {
          formContext.setError('state', {
            type: 'error',
            message: 'Please select a state.',
          });
          return;
        }

        if (updateHubUser.errorCode === UpdateHubUserErrorCode.InvalidZip) {
          formContext.setError('zip', {
            type: 'error',
            message: 'Please enter a valid zip code.',
          });
          return;
        }
      }

      formContext.setError('submit', {
        type: 'error',
        message: 'An error occurred. Please try again.',
      });
    },
    onError: () => {
      setPageError(true);
    },
  });
  const { preferredName, firstName, lastName, phone, birthDate, pronouns, primaryAddress } =
    hubUser;

  const hasPhoneNumber = Boolean(phone);
  const hasBirthDate = Boolean(birthDate);
  const hasFirstName = Boolean(firstName);
  const hasLastName = Boolean(lastName);

  const navigate = useNavigate();

  const formContext = useForm({
    defaultValues: {
      name: preferredName ?? '',
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      phone: convertPhoneNumberForDisplay(phone) ?? '',
      birthDate: convertDateForDisplay(birthDate) ?? '',
      pronouns: pronouns ?? '',
      line1: primaryAddress?.line1 ?? '',
      line2: primaryAddress?.line2 ?? '',
      city: primaryAddress?.city ?? '',
      state: primaryAddress?.state ?? null,
      zip: primaryAddress?.zip ?? '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = formContext;

  const submitForm = (): void => {
    clearErrors();

    void handleSubmit(async (updates): Promise<void> => {
      const unmaskedPhoneNumber = formatWithMask({
        text: updates.phone,
        mask: PHONE_MASK,
      }).unmasked;

      const { line1, line2, city, state, zip, name, ...baseUpdates } = updates;

      void update({
        variables: {
          updates: {
            ...baseUpdates,
            preferredName: name.trim(),
            birthDate: convertBirthDateForUpdate(updates.birthDate),
            phone: unmaskedPhoneNumber,
            primaryAddress: {
              line1,
              line2,
              city,
              state,
              zip,
            },
          },
        },
      });
    })();
  };

  if (pageError) {
    return <PageError />;
  }

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={5}>
        <FormInput
          label="What should we call you?"
          helperText="Not required to be your legal name"
          name="name"
          placeholder="Name"
          autoCapitalize="none"
          autoFocus
          control={control}
          error={errors.name}
          maxLength={100}
          necessityIndicator
          blurOnSubmit={false}
          returnKeyType="next"
          onSubmitEditing={() => {
            if (!hasFirstName) {
              setFocus('firstName');
            } else if (!hasLastName) {
              setFocus('lastName');
            } else {
              setFocus('pronouns');
            }
          }}
        />

        <FormInput
          label="Legal First Name"
          name="firstName"
          placeholder="First Name"
          autoCapitalize="none"
          control={control}
          error={errors.firstName}
          isRequired
          isDisabled={hasFirstName}
          necessityIndicator
          blurOnSubmit={false}
          returnKeyType="next"
          onSubmitEditing={() => {
            if (!hasLastName) {
              setFocus('lastName');
            } else {
              setFocus('pronouns');
            }
          }}
        />

        <FormInput
          label="Legal Last Name"
          name="lastName"
          placeholder="Last Name"
          autoCapitalize="none"
          control={control}
          error={errors.lastName}
          isRequired
          isDisabled={hasLastName}
          necessityIndicator
          blurOnSubmit={false}
          returnKeyType="next"
          onSubmitEditing={() => setFocus('pronouns')}
        />

        <FormInput
          label="Pronouns"
          helperText="Examples: she/her, he/him, they/them, she/they, he/they, xe/xim, she/he/they."
          name="pronouns"
          placeholder="Pronouns"
          autoCapitalize="none"
          control={control}
          error={errors.pronouns}
          necessityIndicator
          blurOnSubmit={false}
          returnKeyType="next"
          onSubmitEditing={() => {
            if (!hasPhoneNumber) {
              setFocus('phone');
            } else if (!hasBirthDate) {
              setFocus('birthDate');
            } else {
              setFocus('line1');
            }
          }}
        />

        <FormInputMasked
          label="Phone Number"
          helperText="Provide a number where we can reach you if necessary."
          name="phone"
          mask={PHONE_MASK}
          placeholder="###-###-####"
          control={control}
          error={errors.phone}
          isRequired
          isDisabled={hasPhoneNumber}
          necessityIndicator
          rules={{ minLength: 12 }}
          blurOnSubmit={false}
          returnKeyType="next"
          onSubmitEditing={() => {
            if (!hasBirthDate) {
              setFocus('birthDate');
            } else {
              setFocus('line1');
            }
          }}
        />

        <FormInputMasked
          label="Date of Birth"
          mask={Masks.DATE_MMDDYYYY}
          name="birthDate"
          placeholder="MM/DD/YYYY"
          autoCapitalize="none"
          control={control}
          error={errors.birthDate}
          isRequired
          isDisabled={hasBirthDate}
          necessityIndicator
          rules={{
            pattern: {
              value: DATE_REGEX,
              message: 'You must enter a valid date MM/DD/YYYY.',
            },
          }}
          blurOnSubmit={false}
          returnKeyType="next"
          onSubmitEditing={() => setFocus('line1')}
        />

        <Layout.VStack space={2}>
          <FormLabel label="Where are you currently living?" isRequired necessityIndicator />

          <FormInput
            name="line1"
            label="Address Line 1"
            hideLabel
            placeholder="Address Line 1"
            autoCapitalize="none"
            control={control}
            error={errors.line1}
            isRequired
            necessityIndicator
            blurOnSubmit={false}
            returnKeyType="next"
            onSubmitEditing={() => setFocus('line2')}
          />

          <FormInput
            name="line2"
            label="Address Line 2"
            hideLabel
            placeholder="Address Line 2"
            autoCapitalize="none"
            control={control}
            error={errors.line2}
            blurOnSubmit={false}
            returnKeyType="next"
            onSubmitEditing={() => setFocus('city')}
          />
          <FormInput
            name="city"
            label="City"
            hideLabel
            placeholder="City"
            autoCapitalize="none"
            control={control}
            error={errors.city}
            isRequired
            blurOnSubmit={false}
            returnKeyType="next"
          />

          <Layout.HStack {...styles.stack}>
            <FormSelect
              name="state"
              label="State"
              hideLabel
              options={uppercasedStateCodes}
              placeholder="State"
              control={control}
              error={errors.state}
              necessityIndicator
              isRequired
              onSubmitEditing={() => setFocus('zip')}
            />

            <FormInput
              name="zip"
              label="Zip"
              hideLabel
              placeholder="Zip"
              autoCapitalize="none"
              control={control}
              error={errors.zip}
              rules={{
                required: 'Zip code is required',
                pattern: {
                  value: /^\d{5}(?:[-\s]\d{4})?$/,
                  message: 'Invalid zip code',
                },
              }}
              isRequired
              inputMode="numeric"
              onSubmitEditing={() => submitForm()}
            />
          </Layout.HStack>
        </Layout.VStack>

        {errors.submit && <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>}

        <Layout.View marginTop={8}>
          <Button.primaryLarge testID="button-patient-info-submit" onPress={submitForm}>
            Confirm and continue
          </Button.primaryLarge>
        </Layout.View>
      </Layout.VStack>
    </FormProvider>
  );
}

const convertBirthDateForUpdate = (birthDate?: string | null): string => {
  if (birthDate === null || birthDate === undefined) return '';

  const birthDateArray = birthDate.split('/');
  const year = birthDateArray[2] ?? '';
  const month = birthDateArray[0] ?? '';
  const day = birthDateArray[1] ?? '';

  return `${year}-${month}-${day}`;
};

const convertPhoneNumberForDisplay = (phoneNumber?: string | null): string => {
  if (phoneNumber === null || phoneNumber === undefined) return '';

  const areaCode = phoneNumber.slice(0, 3);
  const firstThree = phoneNumber.slice(3, 6);
  const lastFour = phoneNumber.slice(6, 10);

  return `${areaCode}-${firstThree}-${lastFour}`;
};

const styles = getStylesheet({
  stack: {
    width: '50%',
  },
});
