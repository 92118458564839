import { Heading, Layout, Text } from '../../../components/core';
import { ImageHandHoldingPaper } from '../images/ImageHandHoldingPaper';
import { CoachingAssessmentStartModal, StartModalProps } from './CoachingAssessmentStartModal';

export const CoachingWellnessStartModal = ({ ...startProps }: StartModalProps): JSX.Element => {
  return (
    <CoachingAssessmentStartModal title="Wellness Assessment" {...startProps}>
      <Layout.VStack space={4}>
        <ImageHandHoldingPaper />

        <Heading.h6 level={2}>Step 2</Heading.h6>

        <Heading.h3 level={3}>Assessment</Heading.h3>

        <Text.para>
          Next, we are going to ask you to answer a few questions related to your wellbeing,
          academic challenges, and stress.
        </Text.para>

        <Text.para>
          Your responses will help your coach understand how to best support you and will also help
          track your progress during the program.
        </Text.para>
      </Layout.VStack>
    </CoachingAssessmentStartModal>
  );
};
