import { JSX, useState } from 'react';
import { Task, TaskList } from '../../../components/core';
import {
  JoinAppointmentWidget,
  UpcomingAppointment,
} from '../../../content/appointment/components/JoinAppointmentWidget';
import { getRoute, useNavigate } from '../../../routes';
import { useCoachingIntakeProgress } from '../hooks/useCoachingIntakeProgress';
import { CoachingTasksWarningWidget } from './CoachingTasksWarningWidget';

export const coachingAppointmentTaskCollection = (): Array<JSX.Element | null> => {
  const navigate = useNavigate();
  const [upcomingAppointment, setUpcomingAppointment] = useState<UpcomingAppointment | undefined>();
  const {
    tasks,
    hasUncompletedTasks,
    loading: coachingIntakeProgressLoading,
  } = useCoachingIntakeProgress();

  const tasksWarning = hasUncompletedTasks ? (
    <CoachingTasksWarningWidget appointmentData={upcomingAppointment} key="tasks-warning" />
  ) : null;

  const taskList = tasks ? (
    <TaskList
      headerText="Getting Started"
      description="Complete these before your kickoff call."
      key="coaching-tasks"
    >
      {tasks.map(task => (
        <Task
          key={'coaching-task-' + task.key}
          completed={task.isCompleted}
          isLink
          timeToComplete={task.timeToComplete}
          title={task.title}
          onPress={() => {
            navigate(
              getRoute('coachingAssessment', {
                assessmentType: task.key,
                slide: '1',
              }),
            );
          }}
        />
      ))}
    </TaskList>
  ) : null;

  return [
    tasksWarning,
    taskList,
    <JoinAppointmentWidget
      key="join-appointment"
      onAppointmentLoad={setUpcomingAppointment}
      blockEarlyJoining={hasUncompletedTasks || coachingIntakeProgressLoading}
    />,
  ];
};
