import VimeoPlayerType, { Options } from '@vimeo/player';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useHeapContext } from '../../contexts/heapContext';

type ConstructorOf<T> = new (
  element: string | HTMLElement | HTMLIFrameElement,
  options?: (Options & { paused: boolean; volume: number }) | undefined,
) => T;

export type VimeoOptions = {
  isPaused?: boolean;
  isVisible?: boolean;
  textTrack?: string;
  volume?: number;
  onError?: (vimeoPlayerError: Error) => void;
  onPlayingChange?: (isPlaying: boolean) => void;
  onTextTrackChange?: (textTrack: string | undefined) => void;
  onVolumeChange?: (volume: number) => void;
};

/**
 * Lazy loads the vimeo player.
 * This is done because the vimeo player will throw errors while being imported, especially
 * during tests. To catch those errors, we are instead lazy loading the package.
 */
const loadVimeoPlayer = async (): Promise<ConstructorOf<VimeoPlayerType> | undefined> => {
  try {
    const imported = await import('@vimeo/player');
    return imported.default;
  } catch (importError) {
    // eslint-disable-next-line no-console
    console.error(importError);
    return undefined;
  }
};

type UseVimeoArgs = {
  videoId: string | number;
  vimeoOptions?: VimeoOptions;
};

type UseVimeoResponse = {
  initializePlayer: (containerElement: HTMLDivElement) => Promise<void>;
  pauseVideo: () => Promise<void>;
  setControlTabbing: (tabbingOn: boolean) => void;
  setTextTrack: (newTextTrack?: string) => Promise<void>;
  setVolume: (newVolume: number) => Promise<void>;
};

export const useVimeo = ({ videoId, vimeoOptions = {} }: UseVimeoArgs): UseVimeoResponse => {
  const { trackEvent } = useHeapContext();
  const [player, setPlayer] = useState<VimeoPlayerType>();
  const [iframeEl, setIframeEl] = useState<HTMLIFrameElement | undefined>();

  const vimeoUrl = `https://vimeo.com/${videoId}`;

  const initializePlayer = async (containerElement: HTMLDivElement): Promise<void> => {
    const VimeoPlayer = await loadVimeoPlayer();
    if (!VimeoPlayer) {
      return;
    }

    const newPlayer = new VimeoPlayer(containerElement, {
      url: vimeoUrl,
      controls: true,
      playsinline: true,
      dnt: true,
      responsive: true,
      autopause: false,
      autoplay: false,
      paused: vimeoOptions.isPaused ?? true,
      texttrack: vimeoOptions.textTrack ?? '',
      volume: vimeoOptions.volume ?? 0,
    });

    setPlayer(newPlayer);

    if (vimeoOptions.onError) {
      newPlayer.on('error', vimeoOptions.onError);
    }

    newPlayer.on('playing', ({ seconds }: { seconds: number }) => {
      trackEvent('video playing', { videoId: String(videoId), timestamp: seconds });
    });

    newPlayer.on('pause', ({ seconds }: { seconds: number }) => {
      trackEvent('video paused', { videoId: String(videoId), timestamp: seconds });
    });

    newPlayer.on('ended', ({ seconds }: { seconds: number }) => {
      trackEvent('video ended', { videoId: String(videoId), timestamp: seconds });
    });

    if (vimeoOptions.onPlayingChange) {
      newPlayer.on('playing', () => {
        vimeoOptions.onPlayingChange?.(true);
      });
      newPlayer.on('pause', () => {
        vimeoOptions.onPlayingChange?.(false);
      });
      newPlayer.on('ended', () => {
        vimeoOptions.onPlayingChange?.(false);
      });
    }

    if (vimeoOptions.onVolumeChange) {
      newPlayer.on(
        'volumechange',
        debounce(volumeEvent => {
          vimeoOptions.onVolumeChange?.(volumeEvent.volume);
        }, 1000),
      );
    }

    if (vimeoOptions.onTextTrackChange) {
      newPlayer.on(
        'texttrackchange',
        debounce(trackChangeEvent => {
          vimeoOptions.onTextTrackChange?.(trackChangeEvent.language ?? undefined);
        }, 1000),
      );
    }

    await newPlayer.ready();

    // Set the width and height of the iframe to 100%.
    // The actual width and height should be set on the containerElement.
    const containerIframeEl = containerElement.querySelector('iframe');
    if (containerIframeEl) {
      containerIframeEl.style.width = '100%';
      containerIframeEl.style.height = '100%';

      if (vimeoOptions?.isVisible !== undefined) {
        containerIframeEl.tabIndex = vimeoOptions.isVisible ? 0 : -1;
      }
    }

    setIframeEl(() => containerIframeEl ?? undefined);
  };

  const pauseVideo = async (): Promise<void> => {
    if (!player) {
      return;
    }

    await player.pause();
  };

  const setControlTabbing = (tabbingOn: boolean): void => {
    if (!iframeEl) {
      return;
    }

    iframeEl.tabIndex = tabbingOn ? 0 : -1;
  };

  const setTextTrack = async (newTextTrack?: string): Promise<void> => {
    if (!player) {
      return;
    }

    if (newTextTrack === undefined) {
      await player.disableTextTrack();
      return;
    }

    await player.enableTextTrack(newTextTrack);
  };

  const setVolume = async (newVolume: number): Promise<void> => {
    if (!player) {
      return;
    }

    await player.setVolume(newVolume);
  };

  return {
    initializePlayer,
    pauseVideo,
    setControlTabbing,
    setTextTrack,
    setVolume,
  };
};
