import { useEffect } from 'react';
import { useSkillContext } from '../../../../contexts';

type SlideEvents = {
  onDisplay?: () => void;
  onHide?: () => void;
};

export const useSlideEvents = (slideIndex: number, { onDisplay, onHide }: SlideEvents): void => {
  const { slideNumber } = useSkillContext();

  useEffect(() => {
    if (slideNumber === slideIndex) {
      onDisplay?.();
    } else {
      onHide?.();
    }
  }, [slideNumber === slideIndex]);
};
