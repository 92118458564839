import { SliceZone } from '@prismicio/react';
import { Layout } from '../../components/core';
import { PageLoading } from '../../components/page';
import { SkillTransition } from '../../content/skill/components/SkillTransition';
import { components as sliceComponents } from '../../content/skill/components/slice';
import { useSkillContext } from '../../contexts';
import { usePrevious } from '../../utils/usePrevious';
import { TourFinish } from './TourFinish';
import { TourLanding } from './TourLanding';
import type { SliceContext } from '../../content/skill/components/skillTypes';

type TourProps = {
  orgName: string;
};

export const TourSlides = ({ orgName }: TourProps): JSX.Element => {
  const { skill, slideCount, slideNumber } = useSkillContext();
  const previousSlideNumber = usePrevious(slideNumber) ?? 0;

  if (!skill) {
    // Don't render while the skill context is initializing.
    return <PageLoading pageName="tour" />;
  }

  const isGoingBack = slideNumber < previousSlideNumber;

  return (
    <Layout.View
      aria-label="Site Tour slide deck"
      flex={1}
      position="relative"
      role="region"
      testID="tour-slides"
    >
      <SkillTransition
        key={1}
        slideCount={slideCount}
        slideNumber={1}
        visible={slideNumber === 1}
        back={isGoingBack}
      >
        <TourLanding isVisible={slideNumber === 1} orgName={orgName} skill={skill} />
      </SkillTransition>

      {skill.data.slices.map((slice, index) => {
        // +2 offsets it to 1-index based and accounts for the landing page.
        const iterationSlideNumber = index + 2;

        const isVisible = slideNumber === iterationSlideNumber;

        const context: SliceContext = {
          isVisible,
          moduleId: module.id,
          questions: undefined,
          skillId: skill.id,
          slideIndex: iterationSlideNumber,
        };

        return (
          <SkillTransition
            key={iterationSlideNumber}
            slideCount={slideCount}
            slideNumber={iterationSlideNumber}
            visible={isVisible}
            back={isGoingBack}
          >
            <SliceZone context={context} slices={[slice]} components={sliceComponents} />
          </SkillTransition>
        );
      })}

      <SkillTransition
        key={slideCount}
        slideCount={slideCount}
        slideNumber={slideCount}
        visible={slideNumber >= slideCount}
        back={isGoingBack}
      >
        <TourFinish isVisible={slideNumber >= slideCount} />
      </SkillTransition>
    </Layout.View>
  );
};
