import { IBoxProps } from 'native-base';
import { ReactNode } from 'react';
import { getStylesheet } from '../../styles';
import { Layout } from '../core';
import { APP_PAGE_MAX_WIDTHS } from '../layout/app/appSizes';

type PageContentProps = IBoxProps & {
  children: ReactNode;
  pageSize?: keyof typeof APP_PAGE_MAX_WIDTHS;
};

export const PageContent = ({
  children,
  paddingBottom = 24,
  pageSize = 'largest',
  ...contentStyles
}: PageContentProps): JSX.Element => {
  const maxWidthPx = APP_PAGE_MAX_WIDTHS[pageSize];

  return (
    <Layout.View
      {...styles.content}
      maxWidth={`${maxWidthPx}px`}
      paddingBottom={paddingBottom}
      testID="page-content"
      {...contentStyles}
    >
      {children}
    </Layout.View>
  );
};

const styles = getStylesheet({
  content: {
    alignSelf: 'center',
    width: '100%',
  },
});
