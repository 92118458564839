import { JSX } from 'react';
import { Platform } from 'react-native';
import { Link } from '../../components/core';
import { PageError } from '../../components/page';
import { useLaunchExternalRedirect } from '../../utils/useLaunchExternalRedirect';
import { AuthError } from '../authentication/components/AuthError';

export const PatientPortalRedirectPage = (): JSX.Element => {
  const errorType = Platform.OS === 'web' ? 'toPatientPortal' : 'toPatientPortalMobile';

  // This will open the url in the web browser app (not in the app itself).
  const { loading, manuallyRedirect, success } = useLaunchExternalRedirect({
    preventRefreshAction: true,
  });

  if (!loading && !success) {
    return <PageError />;
  }

  return (
    <AuthError errorType={errorType}>
      If you are not redirected in a few seconds,{' '}
      <Link.para bold to="" onPress={manuallyRedirect}>
        click here
      </Link.para>{' '}
      to be redirected.
    </AuthError>
  );
};
