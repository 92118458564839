import * as prismic from '@prismicio/client';
// import * as prismicHelpers from '@prismicio/helpers';
import { PrismicProvider as BasePrismicProvider } from '@prismicio/react';
import { ReactNode, useEffect, useMemo } from 'react';
import { Platform } from 'react-native';
import { Link } from '../components/core';
import { config } from '../config';
import { Location, NavigateFunction, useLocation, useNavigate } from '../routes';
import { getLoginUrl } from '../utils/authentication';
import { getMobileToken } from '../utils/authenticationStore';
import Sentry from '../utils/sentry';

const { cmsUrl } = config;

type PrismicRouting = { location: Location; navigate: NavigateFunction };
type PrismicProps = PrismicRouting;

let defaultFetchFn: prismic.FetchLike | undefined;

const getClient = ({ ...fetchProps }: PrismicProps): prismic.Client => {
  /**
   * Creates a Prismic client for the project's repository. The client is used to
   * query content from the Prismic API.
   */
  const client = prismic.createClient(cmsUrl, {
    routes: [],
  });

  defaultFetchFn = client.fetchFn;

  setupPrismicFetch(client, fetchProps);

  return client;
};

const setupPrismicFetch = (
  client: prismic.Client,
  { location, navigate }: PrismicRouting,
): void => {
  /**
   * Override the fetch function in order to send the authorization header.
   */
  client.fetchFn = async (url, params) => {
    const previousFetch = defaultFetchFn ?? client.fetchFn;

    const fetchParams = {
      ...params,
      credentials: 'include',
    };

    if (Platform.OS !== 'web') {
      // get the authentication token from local storage if it exists
      const token = await getMobileToken();
      fetchParams.headers = {
        ...params?.headers,
        authorization: `Bearer ${token}`,
      };
    }

    return previousFetch(url, fetchParams)
      .then(async response => {
        if (response.status !== 401) {
          return response;
        }

        // If the user is not logged in or the token is bad, force them to the login page.
        // Force logout?
        navigate(getLoginUrl(location));

        return response;
      })
      .catch(fetchError => {
        // eslint-disable-next-line no-console
        console.log(fetchError);

        Sentry.captureException(fetchError);

        throw fetchError;
      });
  };
};

/**
 * Creates the React Provider for Prismic.
 */
export function PrismicProvider({ children }: { children: ReactNode }): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const client = useMemo(() => getClient({ location, navigate }), []);

  useEffect(() => {
    setupPrismicFetch(client, { location, navigate });
  }, [location, navigate]);

  return (
    <BasePrismicProvider
      client={client}
      internalLinkComponent={({ href, children: linkChildren }) => (
        <Link.Link to={href}>{linkChildren}</Link.Link>
      )}
    >
      {children}
    </BasePrismicProvider>
  );
}
