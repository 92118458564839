export const iconStyles = {
  Icon: {
    baseStyle: {
      color: 'secondary.900',
      _dark: {
        color: 'secondary.200',
      },
    },
  },
};
