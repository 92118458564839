import { defaultTo, kebabCase } from 'lodash';
import { useContrastText } from 'native-base';
import React from 'react';
import { Heading, Image, Layout, Pressable, Text } from '../../../../../components/core';
import { IconChevronRight } from '../../../../../components/icons/IconChevronRight';
import { getRoute, useNavigate } from '../../../../../routes';
import { getStylesheet } from '../../../../../styles';
import { getColorFromShade } from '../../../../../utils/color';
import { ModuleDocument } from '../../../../module/ModuleTypes';
import type { HeadingLevel } from '../../../../../components/core/Heading';

type CollectionModuleBoxProps = {
  headingLevel?: HeadingLevel;
  module: ModuleDocument;
};

export const CollectionModuleBox = ({
  module,
  headingLevel = 3,
}: CollectionModuleBoxProps): JSX.Element => {
  const navigate = useNavigate();
  const onCollectionModulePress = (moduleId: string) => (): void => {
    navigate(getRoute('module', { moduleId }));
  };

  const data = module.data;
  const color = defaultTo(data?.color, '');
  const colorShade = defaultTo(data?.color_shade, '');
  const imageUrl = defaultTo(data.image?.url, '');
  const imageAlt = defaultTo(data.image?.alt, 'Image');
  const title = defaultTo(data.title, 'Module Title');

  const backgroundColor = getColorFromShade(color, colorShade, 'secondary.900');

  // Determines the text color based on the background color.
  const textColor = useContrastText(backgroundColor);

  const slug = `collection-${kebabCase(title).toLowerCase()}`;

  return (
    <Pressable
      {...styles.moduleBox}
      aria-labelledby={`${slug}-action ${slug}-header`}
      backgroundColor={backgroundColor}
      onPress={onCollectionModulePress(module.id)}
      role="link"
      testID="module-box"
    >
      <Layout.View {...styles.moduleBoxTop}>
        {imageUrl && (
          <Image
            {...styles.moduleThumbnail}
            alt={imageAlt}
            aria-hidden
            source={{
              uri: imageUrl,
            }}
            resizeMode="contain"
            resizeMethod="auto"
          />
        )}
      </Layout.View>

      <Layout.VStack {...styles.moduleBoxStack} space={4}>
        <Layout.VStack flexGrow={1} space={2}>
          <Heading.h4 color={textColor} id={`${slug}-header`} level={headingLevel}>
            {title}
          </Heading.h4>

          <Layout.HStack size={2} alignItems="center">
            <Text.paraSmall bold color={textColor} id={`${slug}-action`}>
              View collection
            </Text.paraSmall>
            <IconChevronRight aria-hidden color={textColor} size={4} />
          </Layout.HStack>
        </Layout.VStack>
      </Layout.VStack>
    </Pressable>
  );
};

const styles = getStylesheet({
  moduleBox: {
    borderRadius: 4,
    flex: 1,
    height: '264px',
    minWidth: '45%',
    overflow: 'hidden',
  },

  moduleBoxTop: {
    flex: 1,
    position: 'relative',
  },

  moduleBoxStack: {
    alignItems: 'flex-start',
    paddingBottom: 4,
    paddingX: 4,
  },

  moduleThumbnail: {
    position: 'absolute',
    size: '160px',
    top: '-14px',
    right: '-17px',
  },
});
