import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useCurrentRouteSearch, useExternalNavigate, useLocation, useNavigate } from '../routes';
import { ExternalToOptions } from './useExternalNavigate';

export const useLaunchExternalRedirect = ({
  overrideRedirectUrl,
  preventRefreshAction = false,
}: {
  overrideRedirectUrl?: string;
  preventRefreshAction?: boolean;
} = {}): {
  loading: boolean;
  manuallyRedirect: () => void;
  success: boolean;
} => {
  const searchParams = useCurrentRouteSearch();
  const externalNavigate = useExternalNavigate();
  const location = useLocation();
  const navigate = useNavigate();

  const locationStateRedirect = !isEmpty(location.state?.externalRedirect)
    ? String(location.state.externalRedirect)
    : undefined;

  // Support both externalRedirect and external_redirect params.
  const externalRedirect: string =
    overrideRedirectUrl ??
    searchParams.externalRedirect ??
    searchParams.external_redirect ??
    locationStateRedirect ??
    '';

  const navigateOptions: ExternalToOptions = { replace: true, sameTab: true };
  const success = searchParams.success ?? '';
  const successValue = '1';

  const manuallyRedirect = (): void => {
    if (externalRedirect) {
      externalNavigate(externalRedirect, navigateOptions);
    }
  };

  useEffect(() => {
    if (externalRedirect) {
      externalNavigate(externalRedirect, navigateOptions);

      if (preventRefreshAction) {
        // Reload this page without the external redirect (prevents re-opening of link on refresh).
        navigate(`${location.pathname}?success=${successValue}`, {
          replace: true,
          state: { externalRedirect },
        });
      }
    }
  }, [externalRedirect]);

  return {
    loading: !!externalRedirect,
    manuallyRedirect,
    success: success === successValue,
  };
};
