import { defaultTo } from 'lodash';
import { Divider } from 'native-base';
import { Button, Heading, Layout, Text } from '../../../components/core';
import { IconCheck } from '../../../components/icons/IconCheck';
import { IconChevronRight } from '../../../components/icons/IconChevronRight';
import { IconX } from '../../../components/icons/IconX';
import { useSkillContext } from '../../../contexts';
import { useMarkSkillStartedMutation } from '../../../graphQL';
import { SkillDocument } from '../SkillTypes';
import { useSlideEvents } from './hooks/useSlideEvents';
import { SkillWrapper } from './SkillWrapper';

type SkillLandingProps = {
  isVisible: boolean;
  moduleId: string;
  skill: SkillDocument;
};

export const SkillLanding = ({ isVisible, moduleId, skill }: SkillLandingProps): JSX.Element => {
  const { triggerNextSlide } = useSkillContext();
  const [markSkillStarted] = useMarkSkillStartedMutation();

  const data = skill.data;
  const category = defaultTo(data.category, '');
  const description = defaultTo(data.description, '');
  const title = defaultTo(data.title, '');
  const goodFor = defaultTo(data.good_for, []);
  const timeNeeded = defaultTo(data.time_needed, '');
  const skillId = skill.id;

  useSlideEvents(1, {
    onDisplay: () => {
      // Tell the backend that this skill was started.
      // It can't be completed until it has been started.
      void markSkillStarted({
        variables: {
          skillId,
          moduleId,
        },
      });
    },
  });

  const onStartPressed = (): void => {
    triggerNextSlide();
  };

  return (
    <SkillWrapper isVisible={isVisible} hideContinueButton>
      <Layout.VStack>
        {category && <Heading.overline marginBottom={2}>{category}</Heading.overline>}
        {title && (
          <Heading.h1 level={1} marginBottom={6}>
            {title}
          </Heading.h1>
        )}
        {description && <Text.paraLarge>{description}</Text.paraLarge>}

        <Layout.VStack
          marginY={9}
          space={4}
          divider={<Divider backgroundColor="secondary.alpha.10:alpha.10" />}
        >
          {goodFor.map(({ label, show_check: showCheck }) => (
            <Layout.HStack key={label}>
              {showCheck ? (
                <IconCheck aria-label="This skill helps with" size={5} />
              ) : (
                <IconX aria-label="This skill does not help with" size={5} />
              )}

              <Text.para marginLeft={3} flex={1}>
                {label}
              </Text.para>
            </Layout.HStack>
          ))}
        </Layout.VStack>

        <Button.skillLarge
          isDisabled={!isVisible}
          aria-label="Start the skill"
          alignSelf="flex-start"
          rightIcon={<IconChevronRight size={6} aria-hidden />}
          testID="button-skill-start"
          onPress={onStartPressed}
        >
          Start
        </Button.skillLarge>

        {timeNeeded && <Text.paraSmall marginTop={3}>{timeNeeded} minutes</Text.paraSmall>}
      </Layout.VStack>
    </SkillWrapper>
  );
};
