export const textStyles = {
  Text: {
    baseStyle: {
      color: 'secondary.900',
      lineHeight: 'sm',

      _dark: {
        color: 'secondary.200',
      },
    },
    variants: {
      caption: () => textVariantCaption,
      label: () => textVariantLabel,
      paragraph: () => textVariantParagraph,
      paragraphSmall: () => textVariantParagraphSmall,
      paragraphLarge: () => textVariantParagraphLarge,
    },
  },
};

// BODY
export const textVariantParagraph = {
  fontSize: 'md',
} as const;

export const textVariantParagraphSmall = {
  fontSize: 'sm',
} as const;

export const textVariantParagraphLarge = {
  fontSize: 'lg',
} as const;

// CAPTION
export const textVariantCaption = {
  fontSize: 'xs',
} as const;

// LABEL
export const textVariantLabel = {
  fontSize: 'sm',
  textTransform: 'uppercase',
  lineHeight: '2xs',
  letterSpacing: 'sm',
} as const;
