import { ReactNode } from 'react';
import { getStylesheet } from '../../../styles';
import { LayoutCore, LayoutCoreStatic } from '../LayoutCore';

type SimpleLayoutProps = {
  children: ReactNode;
};

export const SimpleLayout = ({ children }: SimpleLayoutProps): JSX.Element => {
  return (
    <LayoutCore pageSize="medium" scrollStyles={{ ...styles.background }}>
      {children}
    </LayoutCore>
  );
};

export const SimpleLayoutStatic = ({ children }: SimpleLayoutProps): JSX.Element => {
  return (
    <LayoutCoreStatic pageSize="medium" outerStyles={{ ...styles.background }}>
      {children}
    </LayoutCoreStatic>
  );
};

const styles = getStylesheet({
  background: {
    backgroundColor: 'secondary.100',
  },
});
