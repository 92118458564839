import { useNavigate } from '../routes';
import { useExternalNavigate } from './useExternalNavigate';
import type { ExternalToOptions } from './useExternalNavigate';

import type { NavigateOptions, To } from 'react-router-dom';

export { ExternalToOptions, NavigateOptions, To };

export const useSmartNavigate = (): ((
  to: To,
  toOptions?: NavigateOptions,
  externalToOptions?: ExternalToOptions,
) => void) => {
  const navigate = useNavigate();
  const externalNavigate = useExternalNavigate();

  return (to: To, toOptions?: NavigateOptions, externalToOptions?: ExternalToOptions): void => {
    const isExternal = typeof to === 'string' && to.substring(0, 4) === 'http';
    const isMailTo = typeof to === 'string' && to.substring(0, 6) === 'mailto';
    const isTelephone = typeof to === 'string' && to.substring(0, 3) === 'tel';

    if (isExternal || isMailTo || isTelephone) {
      externalNavigate(to, externalToOptions);
      return;
    }

    navigate(to, toOptions);
  };
};
