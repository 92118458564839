import { defaultTo, includes, throttle } from 'lodash';
import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { ICarouselInstance } from 'react-native-reanimated-carousel';
import { useSkillById } from '../content/skill/hooks/useSkillById';
import { useSkillByType } from '../content/skill/hooks/useSkillByType';
import { SkillDocument, SkillRatingValue } from '../content/skill/SkillTypes';
import { getSkillSlideCount } from '../content/skill/SkillUtils';
import {
  getRoute,
  RouteParams,
  useCurrentRouteName,
  useCurrentRouteSearch,
  useNavigate,
  useParams,
} from '../routes';
import { Setter } from '../types/reactTypes';

type SkillPageParams = RouteParams<'skill'>;

type RatingsAction = {
  slideNumber: number;
  rating: number;
  moduleId: string;
  sectionId: string;
  nudge: string;
};
type RatingsState = {
  [slideNumber: number]: RatingsAction;
};

type SkillContextType = {
  currentSlide?: SkillDocument['data']['slices'][number];
  hasVideoSlide: boolean;
  nextSlide?: SkillDocument['data']['slices'][number];
  videoTextTrack?: string;
  ratingsBySlide: RatingsState;
  ratingsDispatch: Dispatch<RatingsAction>;
  slideCount: number;
  skill: SkillDocument | undefined;
  skillState: ReturnType<typeof useSkillById>[1];
  skillName: string;
  skillRating: SkillRatingValue;
  slideNumber: number;
  volume: number;
  setVideoTextTrack: Setter<string | undefined>;
  setSkillRating: Setter<SkillRatingValue>;
  setVolume: Setter<number>;
  triggerNextSlide: (overrideSlider?: ICarouselInstance) => void;
  triggerPrevSlide: () => void;
};

const SkillContext = createContext<SkillContextType>({
  currentSlide: undefined,
  hasVideoSlide: false,
  nextSlide: undefined,
  videoTextTrack: 'en-x-autogen',
  slideCount: 0,
  ratingsBySlide: {},
  ratingsDispatch: () => undefined,
  skill: undefined,
  skillState: {
    idle: true,
    loading: false,
    loaded: false,
    refetch: () => undefined,
  },
  skillName: '',
  skillRating: 0,
  slideNumber: 1,
  volume: 0,
  setVideoTextTrack: () => undefined,
  setSkillRating: () => undefined,
  setVolume: () => undefined,
  triggerNextSlide: () => undefined,
  triggerPrevSlide: () => undefined,
});

export const SkillConsumer = SkillContext.Consumer;

const allowedTypes = ['selfCareQuiz', 'skill', 'tour'] as const;

export const SkillProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const route = useCurrentRouteName();
  const routeSearch = useCurrentRouteSearch();
  const type = includes(allowedTypes, route) ? (route as (typeof allowedTypes)[number]) : 'tour';

  const navigate = useNavigate();
  const { moduleId, skillId, slide: rawSlide } = useParams<SkillPageParams>();

  const slideNumber = Math.max(1, defaultTo(Number(rawSlide), 1));

  const [ratingsBySlide, ratingsDispatch] = useReducer(
    (state: RatingsState, action: RatingsAction) => {
      return {
        ...state,
        [action.slideNumber]: action,
      };
    },
    {},
  );

  const [skillRating, setSkillRating] = useState<SkillRatingValue>(0);
  const [videoTextTrack, setVideoTextTrack] = useState<string | undefined>('en-x-autogen');
  const [volume, setVolume] = useState(1);

  const [skill, skillState] = useSkillByType({ type, skillId });

  const { hasVideoSlide, skillName, slideCount } = useMemo(() => {
    if (!skill) {
      return {
        hasVideoSlide: false,
        skillName: '',
        slideCount: 0,
      };
    }

    return {
      hasVideoSlide: skill.data.slices.some(slice => slice.slice_type === 'video_slide'),
      skillName: skill.data.title ?? '',
      slideCount: getSkillSlideCount(skill),
    };
  }, [skill]);

  // -1 to shift from 1-index to 0-index. -1 again to account for the skill landing.
  const currentSlide = skill ? skill.data.slices[slideNumber - 2] : undefined;
  const nextSlide = skill ? skill.data.slices[slideNumber - 1] : undefined;

  const debouncedNavigate = useCallback(
    throttle(
      (newSlideNumber: number, routeSearchParams: Record<string, string>) => {
        navigate(
          getRoute(
            type,
            {
              moduleId: moduleId ?? '',
              skillId: skillId ?? '',
              slide: String(newSlideNumber),
            },
            routeSearchParams,
          ),
        );
      },
      Platform.OS === 'web' ? 700 : 1000,
      { leading: true, trailing: false },
    ),
    [moduleId, skillId],
  );

  const triggerNextSlide = useCallback((): void => {
    const nextSlideNumber = Math.min(slideCount, slideNumber + 1);

    debouncedNavigate(nextSlideNumber, routeSearch);
  }, [slideCount, slideNumber]);

  const triggerPrevSlide = useCallback((): void => {
    const prevSlideNumber = Math.max(1, slideNumber - 1);

    debouncedNavigate(prevSlideNumber, routeSearch);
  }, [slideNumber]);

  const providerValue: SkillContextType = {
    currentSlide,
    hasVideoSlide,
    nextSlide,
    videoTextTrack,
    ratingsBySlide,
    ratingsDispatch,
    slideCount,
    skill,
    skillState,
    skillName,
    skillRating,
    slideNumber,
    volume,
    setVideoTextTrack,
    setSkillRating,
    setVolume,
    triggerNextSlide,
    triggerPrevSlide,
  };

  return <SkillContext.Provider value={providerValue}>{children}</SkillContext.Provider>;
};

export const useSkillContext = (): SkillContextType => {
  return useContext(SkillContext);
};
