import { combineContextAndProps, useFormControlContext, usePropsResolution } from 'native-base';
import { useHasResponsiveProps } from 'native-base/src/hooks/useHasResponsiveProps';
import { ForwardedRef, forwardRef, JSX, memo, ReactNode, useEffect } from 'react';
import { Layout, Text } from '../core';
import type { IFormControlHelperTextProps } from 'native-base/src/components/composites/FormControl/types';

/**
 * Note: The is mostly copy/pasted from the FormControlHelperText component in native-base.
 * @see native-base/src/components/composites/FormControl/FormControlHelperText.tsx
 */
const FormControlHelperText = (
  props: IFormControlHelperTextProps,
  ref: ForwardedRef<typeof Layout.Box>,
): JSX.Element | null => {
  const formControlContext = useFormControlContext();
  const combinedProps = combineContextAndProps(formControlContext, props);
  const resolvedProps = usePropsResolution('FormControlHelperText', combinedProps, {
    isDisabled: combinedProps.isDisabled,
    isReadOnly: combinedProps.isReadOnly,
    isInvalid: combinedProps.isInvalid,
    // isRequired: combinedProps.isRequired,
  });

  useEffect(() => {
    resolvedProps?.setHasHelpText(true);
    return () => {
      resolvedProps?.setHasHelpText(false);
    };
  });

  // TODO: refactor for responsive prop
  if (useHasResponsiveProps(props)) {
    return null;
  }

  return <Layout.Box {...resolvedProps} nativeID={resolvedProps?.helpTextId} ref={ref} />;
};

export const FormHelperTextWrapper = memo(forwardRef(FormControlHelperText));

export const FormHelperText = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <FormHelperTextWrapper>
      <Text.caption>{children}</Text.caption>
    </FormHelperTextWrapper>
  );
};
