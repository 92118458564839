import { createIcon, Path } from './index';

export const IconChevronsDown = createIcon({
  title: 'Two down chevrons',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M7 13L12 18L17 13"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M7 6L12 11L17 6"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
