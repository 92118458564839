import { ApolloError } from '@apollo/client';
import {
  CoachingAssessments,
  CoachingGoalsAssessmentQuery,
  useCoachingExecutiveFunctioningGoalsAssessmentQuery,
  useCoachingExecutiveFunctioningWellnessAssessmentQuery,
  useCoachingGoalsAssessmentQuery,
  useCoachingWellnessAssessmentQuery,
} from '../../../graphQL';

type UseCoachingAssessmentReturn = {
  assessments?: CoachingGoalsAssessmentQuery['coachingGoalsAssessment'];
  loading: boolean;
  error?: ApolloError | Error;
  type?: CoachingAssessments;
};

export const useCoachingAssessment = ({
  assessmentType,
}: {
  assessmentType?: string;
}): UseCoachingAssessmentReturn => {
  const {
    data: goalsData,
    loading: goalsLoading,
    error: goalsError,
  } = useCoachingGoalsAssessmentQuery({
    skip: assessmentType !== CoachingAssessments.Goals,
  });

  const {
    data: wellnessData,
    loading: wellnessLoading,
    error: wellnessError,
  } = useCoachingWellnessAssessmentQuery({
    skip: assessmentType !== CoachingAssessments.Wellness,
  });

  const {
    data: efGoalsData,
    loading: efGoalsLoading,
    error: efGoalsError,
  } = useCoachingExecutiveFunctioningGoalsAssessmentQuery({
    skip: assessmentType !== CoachingAssessments.GoalsSuccess,
  });

  const {
    data: efWellnessData,
    loading: efWellnessLoading,
    error: efWellnessError,
  } = useCoachingExecutiveFunctioningWellnessAssessmentQuery({
    skip: assessmentType !== CoachingAssessments.WellnessSuccess,
  });

  if (assessmentType === CoachingAssessments.Goals) {
    return {
      assessments: goalsData?.coachingGoalsAssessment,
      loading: goalsLoading,
      error: goalsError,
      type: CoachingAssessments.Goals,
    };
  }

  if (assessmentType === CoachingAssessments.Wellness) {
    return {
      assessments: wellnessData?.coachingWellnessAssessment,
      loading: wellnessLoading,
      error: wellnessError,
      type: CoachingAssessments.Wellness,
    };
  }

  if (assessmentType === CoachingAssessments.GoalsSuccess) {
    return {
      assessments: efGoalsData?.coachingExecutiveFunctioningGoalsAssessment,
      loading: efGoalsLoading,
      error: efGoalsError,
      type: CoachingAssessments.GoalsSuccess,
    };
  }

  if (assessmentType === CoachingAssessments.WellnessSuccess) {
    return {
      assessments: efWellnessData?.coachingExecutiveFunctioningWellnessAssessment,
      loading: efWellnessLoading,
      error: efWellnessError,
      type: CoachingAssessments.WellnessSuccess,
    };
  }

  return {
    loading: false,
    error: new Error('Invalid assessment type.'),
  };
};
