import { startOfDay } from 'date-fns';
import { flatten, groupBy, last, uniqBy } from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import {
  CoachChatMessage,
  useCoachChatUpdatesSubscription,
  useGetCoachChatMessagesLazyQuery,
  useGetCoachChatMessagesQuery,
} from '../../../graphQL';
import sentry from '../../../utils/sentry';

type UseChatMessagesProps = {
  onInitialMessages?: () => void;
  onNewMessages?: () => void;
  onOldMessages?: () => void;
};

type ChatState = {
  error?: Error;
  loading: boolean;
  loadingOlder: boolean;
  refetch: () => void;
  subscriptionError: boolean;
};

export type UseChatMessagesReturn = {
  chatState: ChatState;
  fetchOlderMessages: () => void;
  groupedMessages: Record<string, CoachChatMessage[]>;
  isBeginningOfChat: boolean;
  lastMessageId?: string;
  loadOldMessagesIsLocked: boolean;
};

type MessagesState = {
  lastMessageId?: string;
  messageOffset: number;
  messages: CoachChatMessage[];
  isInitialMessages: boolean;
  newMessageCount: number;
  prevMessageCount: number;
};

type MessagesAction = {
  type: 'query' | 'addNew' | 'addPrevious' | 'removeOne';
  messageId?: string;
  messages: CoachChatMessage[];
};

export const useChatMessages = ({
  onInitialMessages,
  onNewMessages,
  onOldMessages,
}: UseChatMessagesProps): UseChatMessagesReturn => {
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [isBeginningOfChat, setIsBeginningOfChat] = useState(false);
  const [loadOldMessagesIsLocked, setLoadOldMessagesLock] = useState(true);

  const [messageState, messageDispatch] = useReducer(
    (state: MessagesState, action: MessagesAction) => {
      const getStartMessages = (): CoachChatMessage[] => {
        if (action.type === 'addNew') {
          return [...state.messages, ...action.messages];
        } else if (action.type === 'addPrevious') {
          return [...action.messages, ...state.messages];
        } else if (action.type === 'removeOne') {
          return state.messages.filter(({ id }) => id !== action.messageId);
        }
        return action.messages;
      };

      const newMessageCount =
        action.type === 'addNew'
          ? state.newMessageCount +
            action.messages.filter(message => message.author === 'coach').length
          : state.newMessageCount;

      const prevMessageCount =
        action.type === 'addPrevious'
          ? state.prevMessageCount + action.messages.length
          : state.prevMessageCount;

      const cleanedMessages = uniqBy(flatten(getStartMessages()), 'id');

      const grouped: Record<string, CoachChatMessage[]> = groupBy(
        cleanedMessages,
        ({ createdAt }) => startOfDay(new Date(createdAt)).toISOString(),
      );

      return {
        messages: cleanedMessages,
        groupedMessages: grouped,
        lastMessageId: last(cleanedMessages)?.id,
        messageOffset: cleanedMessages.length,
        isInitialMessages: action.type === 'query',
        newMessageCount,
        prevMessageCount,
      };
    },
    {
      messages: [],
      groupedMessages: {},
      lastMessageId: undefined,
      messageOffset: 0,
      isInitialMessages: false,
      newMessageCount: 0,
      prevMessageCount: 0,
    },
  );

  // Initial query for messages (or when refetch is triggered).
  const {
    loading,
    error,
    refetch: queryRefetch,
  } = useGetCoachChatMessagesQuery({
    variables: { offset: 0 },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getCoachChatMessages }) => {
      messageDispatch({
        type: 'query',
        messages: getCoachChatMessages ?? [],
      });
    },
  });

  // Lazy load older messages as needed.
  const [getCoachChatMessages, { loading: loadingOlder }] = useGetCoachChatMessagesLazyQuery();

  const fetchOlderMessages = async (): Promise<void> => {
    if (isBeginningOfChat || loadOldMessagesIsLocked) {
      return;
    }

    setLoadOldMessagesLock(true);

    const { data: offsetData } = await getCoachChatMessages({
      variables: { offset: messageState.messageOffset },
    });

    const oldMessages = offsetData?.getCoachChatMessages ?? [];
    if (oldMessages.length === 0) {
      setIsBeginningOfChat(true);
      return;
    }

    messageDispatch({
      type: 'addPrevious',
      messages: oldMessages,
    });
  };

  // Subscription for new messages.
  useCoachChatUpdatesSubscription({
    onData: ({ data: { data: updateData } }) => {
      const { action, message, messageId } = updateData?.coachChatUpdates ?? {};

      if (action === 'add' && message != null) {
        messageDispatch({
          type: 'addNew',
          messages: [message],
        });
      }

      if (action === 'remove' && messageId !== undefined) {
        messageDispatch({
          type: 'removeOne',
          messageId,
          messages: [],
        });
      }
    },
    onError: updateError => {
      setSubscriptionError(true);
      sentry.captureException(updateError);
    },
  });

  useEffect(() => {
    if (messageState.isInitialMessages) {
      setLoadOldMessagesLock(false);
      onInitialMessages?.();
    }
  }, [messageState.isInitialMessages]);

  useEffect(() => {
    if (messageState.newMessageCount > 0) {
      onNewMessages?.();
    }
  }, [messageState.newMessageCount]);

  useEffect(() => {
    if (messageState.prevMessageCount > 0) {
      setLoadOldMessagesLock(false);
      onOldMessages?.();
    }
  }, [messageState.prevMessageCount]);

  const refetch = (): void => {
    setSubscriptionError(false);

    void queryRefetch();
  };

  return {
    chatState: {
      error,
      loading,
      loadingOlder,
      refetch,
      subscriptionError,
    },
    fetchOlderMessages,
    groupedMessages: messageState.groupedMessages,
    isBeginningOfChat,
    lastMessageId: messageState.lastMessageId,
    loadOldMessagesIsLocked,
  };
};
