import { kebabCase } from 'lodash';
import { ANY, Portals } from '../../../content/portal/portals';
import { useTherapyContext } from '../../../contexts';
import { useBreakpointSwitch } from '../../../contexts/breakpointContext';
import { usePortalContext } from '../../../contexts/portalContext';
import { getRoute, useCurrentRouteName, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { Heading, Layout, Pressable } from '../../core';
import { IconCoffee } from '../../icons/IconCoffee';
import { IconCompass } from '../../icons/IconCompass';
import { IconHeartHandShake } from '../../icons/IconHeartHandShake';
import { IconHome } from '../../icons/IconHome';

const navLinkInfo = [
  {
    key: 'home',
    title: 'Home',
    Icon: IconHome,
    getToRoute: () => getRoute('home', {}),
    matchingRoutes: ['home'],
    portal: Portals.Hub,
  },
  {
    key: 'self-care',
    title: 'Self Care',
    Icon: IconCoffee,
    getToRoute: () => getRoute('selfCareHome', {}),
    matchingRoutes: ['module', 'selfCare*', 'skill*', 'reflection*'],
    portal: Portals.Skills,
  },
  {
    key: 'find-care',
    title: 'Find Care',
    Icon: IconCompass,
    getToRoute: () => getRoute('findCareStartCare', {}),
    matchingRoutes: ['findCare*'],
    portal: ANY(Portals.Loca, Portals.LocaWellness),
  },
  {
    key: 'coaching',
    title: 'Coaching',
    Icon: IconHeartHandShake,
    getToRoute: () => getRoute('coaching', {}),
    matchingRoutes: ['coaching*'],
    portal: Portals.Coaching,
  },
  {
    key: 'therapy',
    title: 'Therapy',
    Icon: IconHeartHandShake,
    getToRoute: () => getRoute('patientPortal', {}),
    matchingRoutes: [],
    portal: ANY(Portals.Therapy, Portals.Psychiatry),
  },
  {
    key: 'non-loca-therapy',
    title: 'Therapy',
    Icon: IconHeartHandShake,
    getToRoute: () => getRoute('therapy', {}),
    matchingRoutes: ['therapy'],
    portal: Portals.NonLocaTherapyGateway,
  },
] as const;

export const NavLinks = (): JSX.Element => {
  const navigate = useNavigate();
  const currentRouteName = useCurrentRouteName();
  const { onlyIfPortalIsActive } = usePortalContext();
  const { navigateToTherapy, navigateToPatientPortal } = useTherapyContext();

  const [direction, navLinkViewStyle] = useBreakpointSwitch({
    mobile: [{ navLinkStack: 'column', mainStack: 'row' }, styles.navLinkViewMobile] as const,
    tablet: [{ navLinkStack: 'row', mainStack: 'row' }, styles.navLinkViewTablet] as const,
    desktop: [{ navLinkStack: 'row', mainStack: 'column' }, styles.navLinkViewDesktop] as const,
  });

  const isLinkActive = (matchNames: readonly string[]): boolean => {
    return matchNames.some(matchName => {
      if (matchName.endsWith('*')) {
        return currentRouteName.includes(matchName.replace('*', ''));
      }
      return currentRouteName === matchName;
    });
  };

  const navLinkItems = navLinkInfo
    .filter(({ portal }) => {
      // Hide any nav links that the user doesn't have access to.
      return onlyIfPortalIsActive(portal, true, false);
    })
    .map(({ matchingRoutes, key, title, getToRoute, Icon }) => {
      const navLinkColor = isLinkActive(matchingRoutes) ? 'primary.600' : 'secondary.600';

      const onPress = (): void => {
        if (key === 'non-loca-therapy') {
          void navigateToTherapy();
          return;
        } else if (key === 'therapy') {
          navigateToPatientPortal();
          return;
        }

        navigate(getToRoute());
      };

      return (
        <Layout.Flex {...styles.navLinkListItems} key={title} role="listitem">
          <Pressable
            {...navLinkViewStyle}
            aria-label={title}
            role="link"
            flexDirection={direction.mainStack}
            testID={`pressable-sidebar-${kebabCase(title)}`}
            onPress={onPress}
          >
            <Layout.Stack
              direction={direction.navLinkStack}
              {...styles.navLinkStack}
              space={direction.navLinkStack === 'column' ? 1 : 4}
            >
              <Icon size={6} color={navLinkColor} aria-hidden />

              <Heading.h5 color={navLinkColor}>{title}</Heading.h5>
            </Layout.Stack>
          </Pressable>
        </Layout.Flex>
      );
    });

  return (
    <Layout.Flex aria-label="Primary" role="navigation">
      <Layout.Flex {...styles.navLinksList} direction={direction.mainStack} role="list">
        {navLinkItems}
      </Layout.Flex>
    </Layout.Flex>
  );
};

const styles = getStylesheet({
  navLinkViewDesktop: {
    paddingX: 4,
    paddingY: 4,
  },

  navLinkViewMobile: {
    flexGrow: 1,
    justifyContent: 'center',
    paddingX: 0,
    paddingY: 0,
  },

  navLinkViewTablet: {
    flexGrow: 1,
    justifyContent: 'center',
    paddingX: 0,
    paddingY: 4,
  },

  navLinkStack: {
    alignItems: 'center',
  },

  navLinksList: {
    flexGrow: 1,
    gap: 2,
  },

  navLinkListItems: {
    flexGrow: 1,
  },
});
