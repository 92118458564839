import { Theme } from 'native-base';

type VariantThemes = Theme['components']['Button']['variants'];

export const buttonStyles = {
  Button: {
    sizes: {
      sm: () => buttonSizeSmall,
      small: () => buttonSizeSmall,
      md: () => buttonSizeMedium,
      medium: () => buttonSizeMedium,
      lg: () => buttonSizeLarge,
      large: () => buttonSizeLarge,
    },
    variants: {
      ghost: () => buttonVariantGhost,
      link: () => buttonVariantLink,
      skill: () => buttonVariantSkill,
      subtle: () => buttonVariantSubtle('primary', 'secondary.900'),
      danger: () => buttonVariantSubtle('danger', 'danger.800'),
      success: () => buttonVariantSubtle('success', 'success.700'),
    },
  },
};

export const buttonSizeSmall = {
  paddingX: 3,
  paddingY: 2,

  _icon: {
    size: 'sm',
  },
  _text: {
    fontWeight: 700,
    letterSpacing: 'md',
    lineHeight: 'sm',
    fontSize: 'sm',
  },

  _stack: {
    space: 3.5,
  },
};

export const buttonSizeMedium = {
  paddingX: 4,
  paddingY: 3,

  _icon: {
    size: 'md',
  },
  _text: {
    fontWeight: 700,
    letterSpacing: 'md',
    lineHeight: 'sm',
    fontSize: 'md',
  },

  _stack: {
    space: 4,
  },
};

export const buttonSizeLarge = {
  paddingX: 6,
  paddingY: 4,

  _icon: {
    size: 'lg',
  },
  _text: {
    fontWeight: 700,
    letterSpacing: 'md',
    lineHeight: 'sm',
    fontSize: 'lg',
  },

  _stack: {
    space: 5,
  },
};

export const buttonVariantGhost = {
  _icon: {
    color: 'text.900',
  },
  _text: {
    color: 'text.900',
  },
  _spinner: {
    color: 'text.900',
  },

  _hover: {
    backgroundColor: 'secondary.600:alpha.10',
  },
  _pressed: {
    backgroundColor: 'secondary.600:alpha.20',
  },

  _dark: {
    _text: {
      color: `text.500`,
    },
    _icon: {
      color: `text.500`,
    },
    _spinner: {
      color: `text.500`,
    },

    _hover: {
      backgroundColor: `secondary.500:alpha.10`,
    },
    _pressed: {
      backgroundColor: `secondary.500:alpha.20`,
    },
  },
};

export const buttonVariantLink = {
  _icon: {
    color: 'text.900',
  },
  _text: {
    color: 'text.900',
  },
  _spinner: {
    color: 'text.900',
  },

  _hover: {
    _text: {
      textDecorationLine: 'underline',
    },
  },
  _pressed: {
    _text: {
      color: `text.800`,
      textDecorationLine: 'underline',
    },
  },

  _dark: {
    _text: {
      color: `text.500`,
    },

    _pressed: {
      _text: {
        color: `text.300`,
      },
    },
  },
};

export const buttonVariantSkill = {
  backgroundColor: 'primary.600',

  _icon: {
    color: 'lightText',
  },
  _text: {
    color: 'lightText',
  },
  _spinner: {
    color: 'lightText',
  },

  _hover: {
    backgroundColor: `primary.700`,
  },
  _pressed: {
    backgroundColor: `primary.800`,
  },

  _dark: {
    backgroundColor: `white`,

    _icon: {
      color: 'primary.600',
    },
    _text: {
      color: 'primary.600',
    },
    _spinner: {
      color: 'primary.600',
    },

    _hover: {
      backgroundColor: `secondary.100`,
    },
    _pressed: {
      backgroundColor: `secondary.200`,
    },
  },
};

export const buttonVariantSubtle = (
  backgroundColor = 'primary',
  textColor = 'text.900',
): VariantThemes['subtle'] => ({
  backgroundColor: `${backgroundColor}.alpha.10:alpha.10`,

  _icon: {
    color: textColor,
  },
  _text: {
    color: textColor,
  },
  _spinner: {
    color: textColor,
  },

  _hover: {
    backgroundColor: `${backgroundColor}.alpha.20:alpha.20`,
  },
  _pressed: {
    backgroundColor: `${backgroundColor}.alpha.30:alpha.30`,
  },

  _dark: {
    backgroundColor: `${backgroundColor}.alpha.30:alpha.30`,

    _hover: {
      backgroundColor: `${backgroundColor}.alpha.20:alpha.20`,
    },
    _pressed: {
      backgroundColor: `${backgroundColor}.alpha.10:alpha.10`,
    },
  },
});
