import { compact } from 'lodash';
import React from 'react';
import { Layout } from '../../../../../components/core';
import { getStylesheet } from '../../../../../styles';
import { ModuleDocument } from '../../../../module/ModuleTypes';
import { ViewModulesSlice } from '../../../ViewTypes';
import { CollectionModuleBox } from './CollectionModuleBox';
import { TopicModuleBox } from './TopicModuleBox';

type ViewContext = {
  isCollection: boolean;
  modulesById: Record<string, ModuleDocument>;
  omitModuleId?: string;
};

type ViewModulesProps = {
  context: ViewContext;
  slice: ViewModulesSlice;
};

type ModuleLink = Extract<ViewModulesSlice['items'][number]['module'], { id: string }>;

const ViewModules = ({ context, slice }: ViewModulesProps): JSX.Element => {
  const ModuleBox = context.isCollection ? CollectionModuleBox : TopicModuleBox;

  const moduleLinks: ModuleLink[] = compact(
    slice.items.map(({ module }) => {
      return 'id' in module ? module : undefined;
    }),
  );

  const moduleBoxes = moduleLinks.map(moduleLink => {
    if (moduleLink.id === context.omitModuleId) {
      return null;
    }

    const fullModule = context.modulesById[moduleLink.id];
    if (!fullModule) {
      return null;
    }

    return <ModuleBox key={moduleLink.id} module={fullModule} />;
  });

  if (context.isCollection) {
    return (
      <Layout.Flex {...styles.collections} testID="collections">
        {moduleBoxes}
      </Layout.Flex>
    );
  }

  return (
    <Layout.VStack space={2} testID="topics">
      {moduleBoxes}
    </Layout.VStack>
  );
};

export default ViewModules;

const styles = getStylesheet({
  collections: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 2,
    justifyContent: 'space-between',
    width: '100%',
  },
});
