import { createIcon, Path } from './index';

export const IconGraduationCap = createIcon({
  title: 'Graduation Cap',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M22 10V16M2 10L12 5L22 10L12 15L2 10Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M6 12V17C9 20 15 20 18 17V12"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
