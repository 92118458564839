import { compact, isEmpty } from 'lodash';
import {
  ModuleDocument,
  SectionDocument,
  SkillDocument,
} from '../../../../.slicemachine/prismicio';
import { useModuleSimpleById } from '../../../content/module/hooks/useModuleSimpleById';
import { useSectionById } from '../../../content/section/hooks/useSectionById';
import { useCompletedSkillsByModuleId } from '../../../content/skill/hooks/useCompletedSkillsByModuleId';
import { useSkillsByIds } from '../../../content/skill/hooks/useSkillsByIds';

type UsePathwaySectionReturn = [
  {
    module?: ModuleDocument['data'];
    section?: SectionDocument['data'];
    steps: SkillDocument[];
    completedSkillIds: string[];
  },
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
  },
];

type UseSkillPathwaySectionProps = {
  moduleId: string;
  sectionId: string;
};

export const useSkillPathwaySection = ({
  moduleId,
  sectionId,
}: UseSkillPathwaySectionProps): UsePathwaySectionReturn => {
  const [moduleFull, moduleState] = useModuleSimpleById({ moduleId });
  const [sectionFull, sectionState] = useSectionById({ sectionId });

  const module = moduleFull?.data;
  const section = sectionFull?.data;

  const skillIds = compact(
    section?.slices?.flatMap(slice =>
      slice.items.map(item => ('id' in item.skill ? item.skill.id : undefined)),
    ),
  );

  const [skills, skillState] = useSkillsByIds({ skillIds });

  const steps = compact(
    skillIds.map(skillId => {
      return skills?.find(({ id }) => id === skillId);
    }),
  );

  const [completedSkillIds, completedState] = useCompletedSkillsByModuleId({
    moduleId,
    skip: isEmpty(steps),
  });

  return [
    { module, section, steps, completedSkillIds },
    {
      idle: moduleState.idle && sectionState.idle && skillState.idle,
      loading:
        moduleState.loading || sectionState.loading || skillState.loading || completedState.loading,
      loaded: moduleState.loaded && sectionState.loaded,
      error: moduleState.error ?? sectionState.error,
    },
  ];
};
