import { getStylesheet } from '../../../styles';
import { Layout } from '../../core';
import { NavLinks } from './NavLinks';

export const AppFooter = (): JSX.Element => {
  return (
    <Layout.Center {...styles.footer} testID="app-layout-footer">
      <NavLinks />
    </Layout.Center>
  );
};

const styles = getStylesheet({
  footer: {
    alignItems: 'stretch',
    backgroundColor: 'secondary.50',
    bottom: 0,
    paddingX: 1,
    paddingY: 3,
    position: 'absolute',
    width: '100%',

    _web: {
      position: 'fixed',
    },
  },
});
