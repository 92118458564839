import { Path, Rect, Svg } from '../../../components/icons';

export const ImageHandTabletSquare = (): JSX.Element => {
  return (
    <Svg
      title="A hand holding a tablet."
      viewBox="0 0 94 94"
      height="94"
      width="94"
      aria-hidden={true}
    >
      <Rect opacity="0.4" width="94" height="94" rx="8" fill="#E2EEEC" />
      <Path
        d="M33.2358 43.508C33.2868 42.7397 33.1826 41.8936 33.097 41.1285V41.1285C33.0885 41.0534 33.0744 40.9821 33.0555 40.9144C32.8158 40.0556 32.2211 38.9462 31.7631 38.1811V38.1811C31.382 37.5488 30.261 37.2963 28.8239 37.4279C27.2793 37.57 14.8839 38.4633 9.8172 48.1901C7.31406 52.9986 6.26504 58.191 5.82542 61.7081C5.49644 64.3401 6.87281 66.8168 9.44488 67.465C10.0152 67.6087 10.6492 67.7436 11.3479 67.8592C14.7075 68.4151 17.7127 66.0562 18.7941 62.8272C19.5536 60.559 20.4108 58.0564 21.0513 56.3467C21.1913 55.9735 21.3233 55.5959 21.4476 55.2182C21.8791 53.9072 22.5518 52.6814 23.5293 51.7069C25.0437 50.1971 27.2695 48.026 28.3016 47.2565C29.9449 46.03 32.3482 45.489 33.0275 44.2158C33.1586 43.9717 33.2208 43.7335 33.2358 43.508Z"
        fill="#A2CAC2"
      />
      <Path
        d="M21.5602 47.4176C22.4705 47.7041 23.3493 48.347 23.8739 49.1024C24.0308 49.3295 24.4209 49.1173 24.2617 48.8881C23.6721 48.0372 22.7036 47.3285 21.6813 47.0081C21.4056 46.9232 21.2867 47.3327 21.5602 47.4176Z"
        fill="#35405F"
      />
      <Path
        d="M21.2173 48.1408C22.3001 45.7664 24.5779 44.3575 26.9296 43.2286C28.6783 42.3905 31.1735 41.72 31.9604 39.8378C32.0658 39.5832 31.6331 39.4729 31.5277 39.7254C30.9897 41.0091 29.5033 41.5587 28.2815 42.1252C27.1695 42.6409 26.0486 43.1416 24.9904 43.7485C23.2193 44.7649 21.6612 46.0974 20.8294 47.9244C20.7173 48.1748 21.1052 48.3912 21.2173 48.1408Z"
        fill="#35405F"
      />
      <Path
        d="M23.7822 48.5636C25.3829 47.0401 27.4297 46.0767 29.4788 45.2025C30.871 44.6084 32.3574 43.9527 32.9358 42.5204C33.0389 42.2658 32.6063 42.1555 32.5031 42.408C31.853 44.0228 29.8062 44.5638 28.3041 45.2492C26.5487 46.0513 24.8493 46.9467 23.4661 48.2644C23.2621 48.4575 23.5781 48.7567 23.7822 48.5636Z"
        fill="#35405F"
      />
      <Path
        d="M71.0385 60.8343L23.8715 56.8133C23.3783 56.7709 23.0151 56.3592 23.0599 55.8924L26.212 22.7717C26.2568 22.3049 26.6918 21.9611 27.185 22.0035L74.3521 26.0245C74.8453 26.067 75.2085 26.4786 75.1637 26.9454L72.0115 60.0662C71.9667 60.533 71.5318 60.8767 71.0385 60.8343Z"
        fill="#35405F"
      />
      <Path
        d="M69.5256 27.145L29.7231 23.7521C29.2299 23.7096 28.795 24.0534 28.7502 24.5202L25.8873 54.6024C25.8425 55.0692 26.2056 55.4808 26.6988 55.5233L66.5013 58.9162C66.9945 58.9586 67.4295 58.6149 67.4743 58.1481L70.3372 28.0659C70.3843 27.5991 70.0188 27.1853 69.5256 27.145Z"
        fill="white"
      />
      <Path
        d="M64.053 33.3305C63.7234 34.3532 63.3938 35.376 63.0643 36.4009C62.9791 36.6618 63.4118 36.7743 63.497 36.5133C63.8266 35.4906 64.1561 34.4678 64.4856 33.4429C64.5708 33.1819 64.1382 33.0695 64.053 33.3305Z"
        fill="#35405F"
      />
      <Path
        d="M54.9308 40.9944C56.054 40.8671 57.1749 40.7419 58.2981 40.6146C58.5806 40.5827 58.5851 40.1584 58.2981 40.1902C57.1749 40.3175 56.054 40.4427 54.9308 40.57C54.6484 40.6018 54.6439 41.0262 54.9308 40.9944Z"
        fill="#35405F"
      />
      <Path
        d="M57.4864 34.9794C58.1971 35.8112 58.9055 36.6451 59.6162 37.4769C59.7978 37.6891 60.1139 37.3878 59.9323 37.1777C59.2216 36.3459 58.5132 35.512 57.8025 34.6802C57.6231 34.4659 57.3071 34.7693 57.4864 34.9794Z"
        fill="#35405F"
      />
      <Path
        d="M71.1888 44.8713C71.144 44.8713 71.0992 44.8691 71.0544 44.8649C70.6441 44.831 70.2742 44.6485 70.0097 44.3514C69.7451 44.0543 69.6196 43.6766 69.6554 43.2883C69.6913 42.9 69.8841 42.5499 70.1979 42.2995C70.5118 42.0491 70.9109 41.9282 71.3211 41.9643C71.7314 41.9982 72.1013 42.1807 72.3658 42.4778C72.6304 42.7748 72.7559 43.1525 72.7201 43.5408C72.6528 44.2962 71.978 44.8713 71.1888 44.8713ZM71.1844 42.3377C70.9176 42.3377 70.662 42.4247 70.4558 42.5902C70.2226 42.7748 70.0814 43.0337 70.0545 43.3202C70.0276 43.6066 70.1217 43.8846 70.3168 44.1053C70.5118 44.3259 70.7853 44.4596 71.088 44.4851C71.3884 44.5084 71.6843 44.4214 71.9175 44.2368C72.1506 44.0522 72.2919 43.7933 72.3188 43.5069C72.3457 43.2204 72.2515 42.9425 72.0565 42.7218C71.8614 42.5011 71.5879 42.3674 71.2853 42.342C71.2517 42.3398 71.218 42.3377 71.1844 42.3377Z"
        fill="white"
      />
      <Path
        d="M25.9901 40.912C26.0798 39.9317 26.1695 38.9514 26.2592 37.9711C26.2816 37.7271 25.878 37.7271 25.8556 37.9711C25.7659 38.9514 25.6763 39.9317 25.5866 40.912C25.5642 41.1582 25.9677 41.1561 25.9901 40.912Z"
        fill="white"
      />
      <Path
        d="M65.4602 52.4631C65.5772 53.9005 64.0526 54.8951 62.7816 54.2135C61.987 53.7874 61.2255 53.3814 60.782 53.151C59.2126 52.3383 57.8944 52.6375 57.3451 53.081C56.751 53.5563 56.2063 54.6724 57.2712 55.9095C58.3361 57.1465 65.259 64.465 67.4068 66.3747C68.8953 67.6973 70.5922 68.3043 71.8352 68.5829C72.9152 68.8249 73.9383 69.4308 74.4561 70.4089V70.4089C74.9742 71.3877 75.9914 72 77.0931 71.8876C78.8807 71.7052 81.7389 71.2263 84.4267 69.9695C87.2179 68.6644 87.8123 65.2588 86.6731 62.3959C84.8606 57.8413 82.2689 51.4247 81.5374 50.1082C80.3783 48.0224 78.8852 46.6771 76.7173 45.6161C75.7956 45.165 74.7458 45.0592 73.8434 45.0865C72.4049 45.1301 70.9341 45.2054 69.4989 45.0983C68.2396 45.0044 66.4941 44.9939 64.425 45.2978C60.764 45.8347 58.9481 46.6877 58.0536 47.6489C57.2308 48.5337 57.6994 50.6556 60.6138 50.7257C61.8236 50.7547 62.8403 50.7659 63.6157 50.769C64.5751 50.7728 65.3824 51.5068 65.4602 52.4631V52.4631Z"
        fill="#A2CAC2"
      />
      <Path
        d="M66.98 48.0108C66.5035 50.9863 63.0565 51.6884 62.1218 48.8236C61.9655 48.3445 61.8221 47.8488 61.6968 47.3395C60.5018 42.4825 60.5781 39.5818 61.8829 38.6164C62.9097 37.8567 64.6471 38.2981 64.934 39.9362C65.3916 42.5582 66.0853 44.4276 66.5825 45.528C66.9336 46.3052 67.1148 47.1687 66.98 48.0108V48.0108Z"
        fill="#A2CAC2"
      />
      <Path
        d="M61.0153 46.2101C61.3358 47.7358 61.8089 49.1978 62.4434 50.6322C62.5532 50.8804 62.9881 50.7722 62.876 50.5197C62.2416 49.0853 61.7708 47.6233 61.4479 46.0977C61.3919 45.8282 60.9592 45.9428 61.0153 46.2101Z"
        fill="#35405F"
      />
      <Path
        d="M65.5393 55.9029C66.333 56.5013 67.1333 57.0912 67.9382 57.6726C68.1668 57.8381 68.391 57.4689 68.1646 57.3055C67.3889 56.7453 66.62 56.1767 65.8555 55.6016C65.6335 55.434 65.3129 55.7332 65.5393 55.9029Z"
        fill="#35405F"
      />
      <Path
        d="M66.49 45.3364C67.7836 48.2646 69.3013 51.2522 71.7203 53.4972C71.9266 53.6882 72.2449 53.389 72.0364 53.198C69.6802 51.0103 68.1826 48.0779 66.9227 45.2239C66.8129 44.9756 66.3779 45.0839 66.49 45.3364Z"
        fill="#35405F"
      />
      <Path
        d="M71.7652 45.678C74.0475 46.8111 75.8433 48.445 77.1234 50.5626C77.2668 50.8003 77.6547 50.586 77.5112 50.3483C76.1952 48.1734 74.3389 46.4759 71.9939 45.3131C71.7361 45.1857 71.5096 45.5528 71.7652 45.678Z"
        fill="#35405F"
      />
      <Path
        d="M62.2327 38.5735C62.2484 39.7893 62.4053 41.3022 63.1877 42.3165C63.7034 42.9849 64.7436 43.2034 65.3871 42.552C65.5843 42.3526 65.2683 42.0513 65.071 42.2528C64.0823 43.2565 63.2348 41.6184 63.0151 40.8863C62.7887 40.1331 62.6923 39.3564 62.6833 38.5735C62.6766 38.2997 62.2282 38.2997 62.2327 38.5735Z"
        fill="#35405F"
      />
      <Path
        d="M57.6143 53.2552C58.7824 54.1973 60.7081 56.0922 58.9527 57.4396C58.7285 57.6115 59.0469 57.9107 59.2688 57.7388C61.2305 56.2322 59.2778 54.0403 57.9304 52.9539C57.713 52.7799 57.3946 53.0791 57.6143 53.2552Z"
        fill="#35405F"
      />
      <Path
        d="M18.894 54.8068C17.8119 59.9559 22.6848 63.6717 24.7673 58.8399C24.8941 58.5454 25.0127 58.2383 25.1218 57.9177C25.5002 56.8063 25.9458 55.7065 26.6437 54.7625C27.9427 53.0053 30.047 50.1522 30.6534 49.2912C31.5502 48.0181 31.391 47.0548 30.891 46.5731C30.4897 46.189 29.4069 45.8771 28.138 46.4437C27.3739 46.7842 24.4217 48.626 22.1882 50.0412C20.4895 51.1174 19.3076 52.8389 18.894 54.8068V54.8068Z"
        fill="#A2CAC2"
      />
      <Path
        d="M20.1237 51.1455C21.3074 50.4559 22.5113 49.8023 23.7353 49.1785C23.9887 49.0491 23.7622 48.682 23.5089 48.8114C22.2848 49.4352 21.081 50.0888 19.8973 50.7784C19.6506 50.9227 19.8771 51.2898 20.1237 51.1455Z"
        fill="#35405F"
      />
      <Path
        d="M30.7049 46.7191C29.6803 47.7207 27.5348 49.4628 26.2435 47.744C26.0776 47.5233 25.6875 47.7355 25.8557 47.9583C27.3375 49.9296 29.7543 48.2596 31.021 47.0205C31.2227 46.8231 30.9067 46.5218 30.7049 46.7191Z"
        fill="#35405F"
      />
    </Svg>
  );
};
