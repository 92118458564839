import { slideContextFactory } from '../../../components/slides/slideContextFactory';
import { ISlide, SlideProps } from '../../../components/slides/SlideTypes';
import { AssessmentOtherSlideProps } from '../../../content/assessment/components/AssessmentSlideTypes';
import { CoachingAssessments } from '../../../graphQL';

type CoachingAssessmentOtherSlideProps = AssessmentOtherSlideProps & {
  assessmentType: CoachingAssessments;
};

export type CoachingAssessmentSlideProps = SlideProps<CoachingAssessmentOtherSlideProps>;
export type CoachingAssessmentSlide = ISlide<CoachingAssessmentOtherSlideProps>;

export const {
  SlideProvider: CoachingAssessmentSlideProvider,
  useSlideContext: useCoachingAssessmentSlideContext,
} = slideContextFactory<CoachingAssessmentSlide>();
