import { ExternalToOptions } from './useExternalNavigate';

/**
 * This is the WEB VERSION of this hook!
 * For the mobile version, see useExternalNavigate.ts.
 */
export const useExternalNavigate = () => {
  return (to: string, externalToOptions?: ExternalToOptions): void => {
    if (externalToOptions?.sameTab === true) {
      if (externalToOptions?.replace === true) {
        window.location.replace(to);
      } else {
        window.location.assign(to);
      }

      return;
    }

    // Open link in new tab.
    window.open(to, '_blank');
  };
};
