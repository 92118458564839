import { createContext, ReactNode, useContext, useReducer } from 'react';
import { AssessmentAnswer } from '../../../content/assessment/utils/assessmentUtils';

type AssessmentKey = string;
type QuestionKey = string;

type Answers = Record<QuestionKey, AssessmentAnswer>;
type AnswersByAssessment = Record<AssessmentKey, Answers>;
type AnswerAction = {
  answers: Answers;
  assessmentKey?: string;
  type: 'set' | 'reset';
};

type CoachingAssessmentContextType = {
  answersByAssessment: AnswersByAssessment;
  setAnswer: (assessmentKey: string, questionKey: string, answer: AssessmentAnswer) => void;
  resetAnswers: () => void;
};

const CoachingAssessmentContext = createContext<CoachingAssessmentContextType>({
  answersByAssessment: {},
  setAnswer: () => undefined,
  resetAnswers: () => undefined,
});

export const CoachingAssessmentConsumer = CoachingAssessmentContext.Consumer;

export const CoachingAssessmentProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [answersByAssessment, dispatch] = useReducer(
    (state: AnswersByAssessment, action: AnswerAction) => {
      if (action.type === 'reset') {
        return {};
      }

      if (action.assessmentKey === undefined) {
        return state;
      }

      const { assessmentKey } = action;

      return {
        ...state,
        [assessmentKey]: { ...(state[assessmentKey] ?? {}), ...action.answers },
      };
    },
    {},
  );

  const setAnswer = (
    assessmentKey: string,
    questionKey: string,
    answer: AssessmentAnswer,
  ): void => {
    dispatch({
      answers: {
        [questionKey]: answer,
      },
      assessmentKey,
      type: 'set',
    });
  };

  const resetAnswers = (): void => {
    dispatch({ answers: {}, type: 'reset' });
  };

  const providerValue: CoachingAssessmentContextType = {
    answersByAssessment,
    setAnswer,
    resetAnswers,
  };

  return (
    <CoachingAssessmentContext.Provider value={providerValue}>
      {children}
    </CoachingAssessmentContext.Provider>
  );
};

export const useCoachingAssessmentContext = (): CoachingAssessmentContextType => {
  return useContext(CoachingAssessmentContext);
};
