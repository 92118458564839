import { ReactNode, useMemo } from 'react';
import { Platform } from 'react-native';
import { useBreakpointSwitch } from '../../contexts/breakpointContext';
import { OnlyIfPortalIsActive, usePortalContext } from '../../contexts/portalContext';
import { Route, RouteType } from '../../routes';

export const useAddRoute = (): ReturnType<typeof addRouteFactory> => {
  const { onlyIfPortalIsActive } = usePortalContext();

  const isMobile =
    useBreakpointSwitch({
      mobile: true,
      tablet: true,
      desktop: Platform.OS !== 'web',
    }) ?? true;

  return useMemo(
    () => addRouteFactory({ isMobile, onlyIfPortalIsActive }),
    [isMobile, onlyIfPortalIsActive],
  );
};

type ElementType = () => JSX.Element;
type LayoutType = ({ children }: { children: ReactNode }) => JSX.Element;

const noop = (): undefined => undefined;
const PassThrough = ({ children }: { children: ReactNode }): JSX.Element => <>{children}</>;

const addRouteSimple =
  (
    routePath: string,
    Element: ElementType,
    Layout: LayoutType,
    Wrapper: LayoutType = PassThrough,
  ) =>
  (): JSX.Element => {
    const wrappedElement = (
      <Layout>
        <Wrapper>
          <Element />
        </Wrapper>
      </Layout>
    );

    return <Route path={routePath} element={wrappedElement} />;
  };

type AddRouteFactoryArgs = {
  isMobile: boolean;
  onlyIfPortalIsActive: OnlyIfPortalIsActive;
};

const addRouteFactory =
  ({ isMobile, onlyIfPortalIsActive }: AddRouteFactoryArgs) =>
  (
    route: RouteType,
    Element: ElementType,
    Layout: LayoutType,
    {
      MobileLayout,
      Wrapper,
    }: {
      MobileLayout?: LayoutType;
      Wrapper?: LayoutType;
    } = {},
  ) => {
    const FinalLayout = MobileLayout !== undefined && isMobile ? MobileLayout : Layout;

    return onlyIfPortalIsActive(
      route[1],
      addRouteSimple(route[0], Element, FinalLayout, Wrapper),
      noop,
    )();
  };
