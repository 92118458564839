import { StatusBar } from 'expo-status-bar';
import { InputAccessoryView, Keyboard, Platform } from 'react-native';
import { Layout, Button } from '../../components/core';
import { getStylesheet } from '../../styles';

export const DoneAccessoryView = (): JSX.Element => {
  if (Platform.OS !== 'ios') return <></>;

  return (
    <InputAccessoryView nativeID="doneAccessoryID">
      <Layout.View {...styles.inputAccessoryView}>
        <Button.primarySmall
          size="md"
          variant="unstyled"
          colorScheme="secondary"
          onPress={Keyboard.dismiss}
          testID="done-keyboard-button"
        >
          Done
        </Button.primarySmall>
      </Layout.View>
    </InputAccessoryView>
  );
};

export const MobileAppAccessories = (): JSX.Element => {
  if (Platform.OS === 'web') return <></>;

  return (
    <>
      <StatusBar />
      <DoneAccessoryView />
    </>
  );
};

const styles = getStylesheet({
  inputAccessoryView: {
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
