import { useState } from 'react';
import { Platform } from 'react-native';
import { useLoginToHubMutation } from '../../../graphQL';
import { useMobilePostLogin } from './useMobilePostLogin';

export enum LoginErrorType {
  CouldNotAuthorize = 'could-not-authorize',
  UnknownDataFetchError = 'data-fetch-error',
}

type LoginError = {
  type: LoginErrorType;
  message: string;
};

type LoginInput = {
  tokenId: string;
  oneTimeToken: string;
};

type SsoLoginResponse = [
  (input: LoginInput) => Promise<void>,
  { loading: boolean; error: LoginError | undefined },
];

type LoginOutput = {
  userId: number;
};

type LoginHookOptions = {
  onCompleted: (output: LoginOutput) => void;
  onError?: (error: LoginError) => void;
};

export function useSsoLogin({ onCompleted, onError }: LoginHookOptions): SsoLoginResponse {
  const [loginLoading, setLoginLoading] = useState(true);
  const [loginError, setLoginError] = useState<LoginError | undefined>();

  const mobilePostLogin = useMobilePostLogin();

  const onLoginCompleted = (info: LoginOutput): void => {
    setLoginLoading(false);
    setLoginError(undefined);
    onCompleted(info);
  };

  const onLoginError = (): void => {
    setLoginLoading(false);
    setLoginError({
      type: LoginErrorType.CouldNotAuthorize,
      message: 'Could not authorize.',
    });
    onError?.({
      type: LoginErrorType.CouldNotAuthorize,
      message: 'Could not authorize.',
    });
  };

  const [loginToHub, { loading }] = useLoginToHubMutation({
    onCompleted: async data => {
      if (data.loginToHub.__typename === 'LoginToHubError') {
        onLoginError();
        return;
      }

      const { jwt, userId, hasHubOnboarded } = data.loginToHub;

      const mobileStrategy = async (): Promise<void> => {
        if (jwt == null) {
          onLoginError();
          return;
        }

        await mobilePostLogin(jwt, hasHubOnboarded ?? false);

        onLoginCompleted({ userId });
      };

      const onCompleteStrategy = Platform.select({
        default: async () => {
          onLoginCompleted({ userId });
        },
        ios: async () => {
          await mobileStrategy();
        },
        android: async () => {
          await mobileStrategy();
        },
      });

      setLoginLoading(true);

      try {
        await onCompleteStrategy();
      } catch (completeError) {
        onLoginError();
      }
    },
    onError: ({ message }) => {
      setLoginLoading(false);
      setLoginError({
        type: LoginErrorType.UnknownDataFetchError,
        message,
      });
      onError?.({
        type: LoginErrorType.UnknownDataFetchError,
        message,
      });
    },
  });

  const login = async ({ tokenId, oneTimeToken }: LoginInput): Promise<void> => {
    if (!tokenId || !oneTimeToken) {
      return;
    }

    await loginToHub({
      variables: {
        tokenId,
        token: oneTimeToken,
      },
    });
  };

  return [login, { loading: loading || loginLoading, error: loginError }];
}
