import * as Notifications from 'expo-notifications';
import { isEmpty, isObject, isNil } from 'lodash';
import { useEffect } from 'react';
import { Platform } from 'react-native';
import { useTrackPushOpenMutation } from '../../graphQL';
import { useURLChange } from '../../router/hooks/useURLChange';
import type { FirebaseRemoteMessage } from 'expo-notifications/src/Notifications.types';

type AndroidPushNotificationTrigger = {
  type: 'push';
  remoteMessage: FirebaseRemoteMessage;
};
type IOSPushNotificationTrigger = {
  type: 'push';
  payload: { itbl?: { defaultAction?: DefaultAction; messageId: string } };
};
type DefaultAction = {
  type?: string;
  data?: string;
};

export const useHandlePushNotifications = (): void => {
  const { handleURLChange } = useURLChange();
  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  const [trackPushOpenMutation] = useTrackPushOpenMutation();

  const handleAndroidResponse = (response: Notifications.NotificationResponse): void => {
    const trigger = response?.notification.request.trigger as
      | AndroidPushNotificationTrigger
      | undefined;
    if (!trigger) return;
    const { remoteMessage } = trigger;
    if (!isNil(remoteMessage?.messageId)) {
      void trackPushOpenMutation({ variables: { messageId: remoteMessage?.messageId } });
    }

    let itbl;
    try {
      itbl = JSON.parse(trigger?.remoteMessage?.data?.itbl ?? '{}');
    } catch (parseError) {
      return;
    }

    if (isEmpty(itbl) || isEmpty(itbl?.defaultAction) || !isObject(itbl?.defaultAction)) {
      return;
    }

    const defaultAction: DefaultAction = itbl.defaultAction;

    if (defaultAction?.type === 'openUrl') {
      const url = defaultAction.data ?? '';
      handleURLChange({ url }, true);
    }
  };

  const handleiOSResponse = (response: Notifications.NotificationResponse): void => {
    const trigger = response?.notification.request.trigger as
      | IOSPushNotificationTrigger
      | undefined;

    const itbl = trigger?.payload?.itbl;
    if (isEmpty(itbl) || isEmpty(itbl?.defaultAction) || !isObject(itbl?.defaultAction)) {
      return;
    }

    if (itbl?.messageId) {
      void trackPushOpenMutation({ variables: { messageId: itbl?.messageId } });
    }

    const defaultAction: DefaultAction = itbl.defaultAction;

    if (defaultAction?.type === 'openUrl') {
      const url = defaultAction.data ?? '';
      handleURLChange({ url }, true);
    }
  };

  const handleNotificationResponse = (response: Notifications.NotificationResponse): void => {
    if (Platform.OS === 'android') {
      handleAndroidResponse(response);
    }
    if (Platform.OS === 'ios') {
      handleiOSResponse(response);
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      return;
    }
    if (lastNotificationResponse) {
      handleNotificationResponse(lastNotificationResponse);
    }
  }, [lastNotificationResponse]);
};

export const PushNotificationsHandler = (): null => {
  useHandlePushNotifications();

  return null;
};
