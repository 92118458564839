import { createIcon, Path } from './index';

export const IconVenetianMask = createIcon({
  title: 'A venetian mask.',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M2 12C2 13.3261 2.52678 14.5979 3.46447 15.5355C4.40215 16.4732 5.67392 17 7 17C8.84771 17.0688 10.6145 17.7756 12 19C13.3855 17.7756 15.1523 17.0688 17 17C18.3261 17 19.5979 16.4732 20.5355 15.5355C21.4732 14.5979 22 13.3261 22 12V7H17C15.1523 7.06882 13.3855 7.77556 12 9C10.6145 7.77556 8.84771 7.06882 7 7H2V12Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M6 11C7.5 11 9 11.5 9 13C7 13 6 13 6 11Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M18 11C16.5 11 15 11.5 15 13C17 13 18 13 18 11Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
