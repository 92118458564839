import { defaultTo } from 'lodash';
import { FormControl } from 'native-base';
import { JSX } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Input, InputProps } from '../core/Input';
import { FormErrorInput } from './FormErrorInput';
import { FormHelperText } from './FormHelperText';
import { FormLabel, FormLabelProps } from './FormLabel';
import { FormScrollingProps } from './hooks/useFormScrolling';

export enum InputErrorTypes {
  Pattern = 'pattern', // from rules.pattern
  Required = 'required', // from isRequired or rules.required
}

export type FormInputProps<TFieldValues extends FieldValues> = InputProps &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> &
  Omit<FormLabelProps, 'inputId'> & {
    error?: FieldErrors<TFieldValues>[string];
    helperText?: string;
    hideLabel?: boolean;
  };

export function FormInput<TFieldValues extends FieldValues>({
  control,
  error,
  name,
  onLayout,
  helperText = '',
  hideLabel = false,
  label = '',
  necessityIndicator = false,
  rules = {},
  ...inputProps
}: FormInputProps<TFieldValues>): JSX.Element {
  const finalRules = {
    ...rules,
    required: defaultTo(rules.required, {
      value: Boolean(inputProps.isRequired),
      message: 'This field is required.',
    }),
  };

  const isRequired = inputProps.isRequired ?? false;

  const nativeId = name;
  const inputId = `${nativeId}-input`;

  const ariaLabelProps = label && hideLabel ? { 'aria-label': label } : {};

  return (
    <FormControl isInvalid={Boolean(error?.type)} nativeID={nativeId} onLayout={onLayout}>
      {!hideLabel && (
        <FormLabel
          inputId={inputId}
          isRequired={isRequired}
          label={label}
          necessityIndicator={necessityIndicator}
        />
      )}

      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, onBlur, ref, value } }) => (
          <Input
            {...inputProps}
            {...ariaLabelProps}
            id={inputId}
            isInvalid={inputProps.isInvalid ?? Boolean(error)}
            onBlur={onBlur}
            onChangeText={onChange}
            ref={ref}
            value={value}
          />
        )}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {error && <FormErrorInput nativeID={nativeId}>{error.message}</FormErrorInput>}
    </FormControl>
  );
}
