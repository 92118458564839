import { RichTextField } from '@prismicio/types';
import { IStackProps } from 'native-base';
import { ReactNode } from 'react';
import { getStylesheet } from '../../styles';
import { Heading, Image, Layout, Text } from '../core';
import { RichText } from '../prismic';

type PageBubbleHeaderProps = IStackProps & {
  backgroundColor?: string;
  button?: JSX.Element;
  children?: ReactNode;
  image?: {
    alt: string;
    url: string;
  };
  overtext?: string;
  textColor?: string;
  title: string;
  undertext?: string;
  undertextRich?: RichTextField;
};

export const PageBubbleHeader = ({
  button,
  children,
  image,
  overtext,
  textColor = 'darkText',
  title,
  undertext,
  undertextRich,
  ...topStyles
}: PageBubbleHeaderProps): JSX.Element => {
  return (
    <Layout.VStack {...styles.bubbleHeader} space={4} testID="page-bubble-header" {...topStyles}>
      {image && image.url !== '' && (
        <Image
          {...styles.image}
          alt={image.alt}
          aria-hidden
          source={{
            uri: image.url,
          }}
          resizeMode="contain"
          resizeMethod="auto"
        />
      )}

      <Layout.VStack space={2}>
        {overtext !== undefined && (
          <Heading.h6 center color={textColor}>
            {overtext}
          </Heading.h6>
        )}

        <Heading.h1 center color={textColor} level={1}>
          {title}
        </Heading.h1>

        {undertext !== undefined && (
          <Text.para center color={textColor}>
            {undertext}
          </Text.para>
        )}

        {undertextRich !== undefined && (
          <RichText
            styles={{ text: { color: textColor, alignSelf: 'center' } }}
            field={undertextRich}
          />
        )}
      </Layout.VStack>

      {children}
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  bubbleHeader: {
    alignItems: 'center',
    borderRadius: 8,
    justifyContent: 'space-between',
    paddingX: 6,
    paddingY: 8,
    width: '100%',
  },

  image: {
    alignSelf: 'center',
    size: '96px',
  },
});
