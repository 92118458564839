import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

export enum AuthStoreKeys {
  hasPromoBannerBeenDismissed = 'hasPromoBannerBeenDismissed',
  loginRedirectUrl = 'loginRedirectUrl',
  mobileAuthToken = 'secure_token',
  pushNotificationsDeviceToken = 'pushNotificationsDeviceToken',
}

export const setMobileToken = async (token: string): Promise<void> => {
  await SecureStore.setItemAsync(AuthStoreKeys.mobileAuthToken, token, {
    keychainAccessible: SecureStore.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
  });
};

export const removeMobileToken = async (): Promise<void> => {
  if (Platform.OS !== 'web') {
    await SecureStore.deleteItemAsync(AuthStoreKeys.mobileAuthToken);
  }
};

export const getMobileToken = async (): Promise<string> => {
  const result = await SecureStore.getItemAsync(AuthStoreKeys.mobileAuthToken);
  return result ?? '';
};

export async function setRedirectUrl(value: string): Promise<void> {
  if (Platform.OS === 'web') {
    return;
  }

  await SecureStore.setItemAsync(AuthStoreKeys.loginRedirectUrl, value);
}

export async function removeRedirectUrl(): Promise<void> {
  if (Platform.OS === 'web') {
    return;
  }

  await SecureStore.deleteItemAsync(AuthStoreKeys.loginRedirectUrl);
}

export async function getRedirectUrl(): Promise<string> {
  if (Platform.OS === 'web') {
    return '';
  }

  const result = await SecureStore.getItemAsync(AuthStoreKeys.loginRedirectUrl);
  return result ?? '';
}

export const setDeviceTokenRegistered = async (firebaseToken: string): Promise<void> => {
  await SecureStore.setItemAsync(AuthStoreKeys.pushNotificationsDeviceToken, firebaseToken, {
    keychainAccessible: SecureStore.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
  });
};

export const removeDeviceTokenRegistered = async (): Promise<void> => {
  await SecureStore.deleteItemAsync(AuthStoreKeys.pushNotificationsDeviceToken);
};

export const getDeviceTokenRegistered = async (): Promise<string> => {
  if (Platform.OS === 'web') {
    return '';
  }

  const token = await SecureStore.getItemAsync(AuthStoreKeys.pushNotificationsDeviceToken);
  return token ?? '';
};

export const hasPromoBannerBeenDismissed = (): boolean => {
  if (Platform.OS !== 'web') return true;

  const isPromoBannerDismissed = localStorage.getItem(AuthStoreKeys.hasPromoBannerBeenDismissed);
  return isPromoBannerDismissed === 'true';
};

export const setPromoBannerDismissed = (): void => {
  if (Platform.OS !== 'web') return;

  localStorage.setItem(AuthStoreKeys.hasPromoBannerBeenDismissed, 'true');
};
