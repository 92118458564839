import { useToast } from 'native-base';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCreateHubFeedbackMutation } from '../../../../graphQL';
import { getRoute, useExternalNavigate, useLocation, useNavigate } from '../../../../routes';
import { getStylesheet } from '../../../../styles';
import { Button, Heading, Layout, Link, SimpleModal, Text } from '../../../core';
import { FormTextArea } from '../../../form';

type HubFeedbackFormProps = {
  onClose: () => void;
};

type FormValues = {
  feedback: string;
  url: string;
};

export const HubFeedbackModal = ({ onClose }: HubFeedbackFormProps): JSX.Element => {
  const externalNavigate = useExternalNavigate();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const toCrisisResources = (): void => {
    onClose();
    navigate(getRoute('crisisResources', {}));
  };

  const mailMantra = (): void => {
    externalNavigate('mailto:hi@mantrahealth.com');
  };

  const [submitHubFeedback] = useCreateHubFeedbackMutation({
    onError: () => {
      onClose();
      toast.show({
        description: 'Feedback could not be saved',
        duration: 3000,
      });
    },
    onCompleted: () => {
      onClose();
      toast.show({
        description: 'Feedback saved',
        duration: 3000,
      });
    },
  });

  const formContext = useForm<FormValues>({
    defaultValues: {
      feedback: '',
      url: location.pathname,
    },
  });

  const { handleSubmit } = formContext;

  const submitForm = (): void => {
    void handleSubmit(async (values): Promise<void> => {
      await submitHubFeedback({
        variables: {
          feedback: values.feedback,
          url: values.url,
        },
      });
    })();
  };

  const onClosePress = (): void => {
    onClose();
  };

  const headerId = 'hub-feedback-modal-header';

  return (
    <SimpleModal {...styles.modalContent} headerId={headerId} onClose={onClosePress}>
      <FormProvider {...formContext}>
        <Layout.VStack space={4} paddingTop={6}>
          <Heading.h3 id={headerId}>Hub Feedback</Heading.h3>

          <Text.para>
            Thanks for helping us to make the Mantra Care Hub a better experience. Please leave your
            feedback below.
          </Text.para>

          <FormTextArea
            {...styles.feedbackModalTextArea}
            name="feedback"
            placeholder="Leave feedback, suggestions, bug reports, and more."
            placeholderTextColor="secondary.700"
            fontSize="md"
            testID="hub-feedback-text-area"
            label="Your Feedback"
            minHeight="120px"
          />

          <Layout.VStack {...styles.blueSection} space={3}>
            <Text.paraSmall>
              If you or someone you know is experiencing an emergency or crisis and needs immediate
              help, please visit{' '}
              <Link.paraSmall bold {...styles.link} onPress={toCrisisResources} to="">
                <Text.paraSmall>Crisis Resources</Text.paraSmall>
              </Link.paraSmall>
              .
            </Text.paraSmall>

            <Text.paraSmall>
              If you need assistance from Mantra Health, please email us at{' '}
              <Link.paraSmall bold {...styles.link} onPress={mailMantra} to="">
                <Text.paraSmall>hi@mantrahealth.com</Text.paraSmall>
              </Link.paraSmall>
            </Text.paraSmall>

            <Text.paraSmall bold>
              By submitting this form, I acknowledge that this form is for general feedback only and
              should not be used as a way to request services.
            </Text.paraSmall>
          </Layout.VStack>

          <Button.primaryMedium testID="button-submit-feedback-form" onPress={submitForm}>
            Submit
          </Button.primaryMedium>
        </Layout.VStack>
      </FormProvider>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  modalContent: {
    width: '100%',
    maxWidth: '600px',
    borderRadius: 0,
  },

  blueSection: {
    backgroundColor: 'primary.100',
    padding: 4,
  },

  feedbackModalTextArea: {
    marginBottom: 5,
  },

  link: {
    textDecoration: 'underline',
  },
});
