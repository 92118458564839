import { ApolloQueryResult } from '@apollo/client';
import { pick } from 'lodash';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import {
  CoachingStatus,
  UserCoachingStatusQuery,
  useUserCoachingStatusQuery,
} from '../../../graphQL';

type QueryState = {
  loading: boolean;
  error?: Error;
  refetch: () => Promise<ApolloQueryResult<UserCoachingStatusQuery> | undefined>;
};

type CoachingContextType = {
  coachingStatus?: CoachingStatus;
  coachingStatusState: QueryState;
};

const CoachingContext = createContext<CoachingContextType>({
  coachingStatus: undefined,
  coachingStatusState: {
    loading: false,
    refetch: async () => undefined,
  },
});

export const CoachingConsumer = CoachingContext.Consumer;

export const CoachingProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { data: userCoachingStatusData, ...coachingStatusState } = useUserCoachingStatusQuery();

  const coachingStatus = useMemo(
    () => userCoachingStatusData?.userCoachingStatus?.userCoachingStatus ?? undefined,
    [userCoachingStatusData],
  );

  const providerValue: CoachingContextType = {
    coachingStatus,
    coachingStatusState: pick(coachingStatusState, ['loading', 'error', 'refetch']),
  };

  return <CoachingContext.Provider value={providerValue}>{children}</CoachingContext.Provider>;
};

export const useCoachingContext = (): CoachingContextType => {
  return useContext(CoachingContext);
};
