import { createIcon, Path } from './index';

export const IconRefreshCW = createIcon({
  title: 'Refresh',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M21 2V8H15"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M3 12C3.00158 10.2633 3.5056 8.56416 4.45125 7.10752C5.39691 5.65088 6.74382 4.49895 8.32951 3.79067C9.9152 3.0824 11.6719 2.84803 13.3879 3.11584C15.1038 3.38365 16.7056 4.14219 18 5.29998L21 7.99998"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M3 22V16H9"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M21 12C20.9984 13.7367 20.4944 15.4358 19.5487 16.8925C18.6031 18.3491 17.2562 19.501 15.6705 20.2093C14.0848 20.9176 12.3281 21.152 10.6121 20.8841C8.89623 20.6163 7.29445 19.8578 6 18.7L3 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
