import { defaultTo } from 'lodash';
import { Divider } from 'native-base';
import { Heading, Layout, Text } from '../../../../components/core';
import { ModuleDocument } from '../../../module/ModuleTypes';
import { View } from '../../../view/components/View';
import { ImageSmiles } from './ImageSmiles';

type ModuleCompleteProps = {
  isVisible: boolean;
  module: ModuleDocument;
};

export const ModuleComplete = ({ isVisible, module }: ModuleCompleteProps): JSX.Element => {
  const title = defaultTo(module.data.title, 'this module');
  const type = defaultTo(module.data.type, 'topic');

  const isCollection = type === 'collection';

  return (
    <Layout.VStack flexGrow={1} space={8}>
      <Layout.Center>
        <ImageSmiles size={250} />
      </Layout.Center>

      <Layout.VStack space={4}>
        <Heading.h2 center>Well done!</Heading.h2>

        <Text.para center>You&apos;ve just completed {title}.</Text.para>
      </Layout.VStack>

      <Divider backgroundColor="secondary.alpha.10:alpha.10" />

      <Heading.h5 center>Other topics you might like</Heading.h5>

      {isVisible && <View isCollection={isCollection} omitModuleId={module.id} hideHeader />}
    </Layout.VStack>
  );
};
