import { Platform } from 'react-native';
import { SamlUserApp } from '../../../graphQL';

export const useAppType = (): SamlUserApp => {
  return Platform.select({
    web: SamlUserApp.Hub,
    ios: SamlUserApp.HubiOs,
    android: SamlUserApp.HubAndroid,
    default: SamlUserApp.Hub,
  });
};
