import { setupApollo, SetupApolloResponse } from './apolloSetup';
import { useSetupFonts } from './fonts';
import { heapInitialize } from './heap';
import { setupPolyfill } from './polyfillSetup';

import './sentry';

export { NativeBaseProvider } from './nativeBase';
export { PrismicProvider } from './prismic';

type PluginsResponse = SetupApolloResponse;

export function setupAppPlugins(): PluginsResponse {
  heapInitialize();

  setupPolyfill();

  return setupApollo();
}

export function useSetup(): { isReady: boolean } {
  const { areFontsLoaded } = useSetupFonts();

  return { isReady: areFontsLoaded };
}
