import {
  Day,
  format,
  getDay,
  getMonth,
  isSameDay,
  lastDayOfMonth,
  nextDay,
  parse,
  previousDay,
  startOfMonth,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

type DaysOfWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

type Months =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';

const getXthDayOfMonth = (xth: number, day: Day, now: Date): Date => {
  const positiveXth = Math.abs(xth);
  const fetchNextDay = xth < 0 ? previousDay : nextDay;
  const start = xth < 0 ? lastDayOfMonth(now) : startOfMonth(now);

  if (positiveXth === 1 && getDay(start) === day) {
    return start;
  }

  // Builds an array with one element per xth, so xth of 3 would generate [0, 1, 2].
  const loops = Array.from({ length: positiveXth }, (ignore, index) => index);

  return loops.reduce(acc => {
    return fetchNextDay(acc, day);
  }, start);
};

const toDayAndMonth = (
  dayOfWeekName: DaysOfWeek,
  monthName: Months,
): { day: Day; month: number } => {
  const day = parse(dayOfWeekName, 'EEEE', new Date()).getDay();
  const month = parse(monthName, 'MMMM', new Date()).getMonth();

  if (Number.isNaN(day) || day < 0 || day > 6) {
    throw new Error(`Invalid day of week '${dayOfWeekName}' given.`);
  }
  if (Number.isNaN(month)) {
    throw new Error(`Invalid month '${monthName}' given.`);
  }

  return { day: day as Day, month };
};

const isXthDayInMonth = (
  xth: number,
  dayOfWeekName: DaysOfWeek,
  monthName: Months,
): ((now: Date) => boolean) => {
  const { day, month } = toDayAndMonth(dayOfWeekName, monthName);

  return (now: Date): boolean => {
    if (getMonth(now) !== month) {
      return false;
    }

    const xthDate = getXthDayOfMonth(xth, day, now);

    return isSameDay(now, xthDate);
  };
};

const STATIC_HOLIDAYS = [
  '01-01', // New Year's Day
  '06-19', // Juneteenth
  '07-04', // Independence Day
  '12-24', // Christmas Eve
  '12-25', // Christmas Day
];

const MOVING_HOLIDAYS: Array<(now: Date) => boolean> = [
  isXthDayInMonth(3, 'Monday', 'January'), // Martin Luther King Jr. Day - 3rd Monday in Jan
  isXthDayInMonth(-1, 'Monday', 'May'), // Memorial Day - Last Monday in May
  isXthDayInMonth(4, 'Thursday', 'November'), // Thanksgiving - 4th Thursday in Nov
  isXthDayInMonth(4, 'Friday', 'November'), // Day after Thanksgiving - 4th Friday in Nov
];

export const isDuringHoliday = (): boolean => {
  const nowInNewYork = utcToZonedTime(new Date(), 'America/New_York');

  const today = format(nowInNewYork, 'MM-dd');
  if (STATIC_HOLIDAYS.includes(today)) {
    return true;
  }

  return MOVING_HOLIDAYS.some(dateCheck => dateCheck(nowInNewYork));
};
