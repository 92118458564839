import { useToast } from 'native-base';
import { useState } from 'react';
import { Button, Heading, Layout, TextArea } from '../../components/core';
import { IconX } from '../../components/icons/IconX';
import { PageContent } from '../../components/page';
import { useSkillReflectionEditAnswerMutation } from '../../graphQL';
import { getStylesheet } from '../../styles';

type EditReflectionModalProps = {
  reflectionId: string;
  questionKey: string;
  question: string;
  answer?: string;
  onClose: (wasUpdated: boolean) => void;
};

export const ReflectionEdit = ({
  reflectionId,
  questionKey,
  question,
  answer,
  onClose,
}: EditReflectionModalProps): JSX.Element | null => {
  const toast = useToast();
  const [answerValue, setAnswerValue] = useState(answer ?? '');
  const [saveReflectionEdit] = useSkillReflectionEditAnswerMutation({
    onError: () => {
      toast.show({
        description: 'Reflection answer could not be saved.',
        duration: 3000,
      });
    },
    onCompleted: ({ skillReflectionEditAnswer }) => {
      toast.show({
        description: skillReflectionEditAnswer
          ? 'Reflection answer saved'
          : 'Reflection answer could not be saved.',
        duration: 3000,
      });
    },
  });

  const onChangeText = (text: string): void => {
    if (text.length > 4040) {
      toast.show({
        description: 'Maximum length of 4040 characters reached.',
        duration: 3000,
      });
      return;
    }

    setAnswerValue(text);
  };

  const onSavePress = async (): Promise<void> => {
    const response = await saveReflectionEdit({
      variables: {
        reflectionId: Number(reflectionId),
        questionKey,
        answer: answerValue,
      },
    });

    onClose(response.data?.skillReflectionEditAnswer ?? false);
  };
  const onClosePress = (): void => {
    onClose(false);
  };

  return (
    <PageContent padding={6} testID="reflection-page-edit">
      <Layout.View alignItems="flex-end">
        <Button.tertiaryLarge
          accessibilityLabel="Close"
          leftIcon={<IconX accessibilityHidden />}
          testID="reflection-page-close-edit"
          onPress={onClosePress}
        />
      </Layout.View>

      <Layout.VStack {...styles.topStack} space={6}>
        <Heading.h4>{question}</Heading.h4>

        <TextArea
          autoCompleteType={undefined}
          backgroundColor="white:alpha.60"
          fontSize="md"
          h={140}
          placeholderTextColor="secondary.700"
          onChangeText={onChangeText}
          value={answerValue}
          testID="reflection-text-area"
        />

        <Layout.HStack justifyContent="center" space={3}>
          <Button.dangerMedium testID="button-skill-reflection-cancel" onPress={onClosePress}>
            Cancel
          </Button.dangerMedium>

          <Button.primaryMedium testID="button-skill-reflection-update" onPress={onSavePress}>
            Update Response
          </Button.primaryMedium>
        </Layout.HStack>
      </Layout.VStack>
    </PageContent>
  );
};

const styles = getStylesheet({
  topStack: {
    alignSelf: 'center',
    maxWidth: '552px',
    paddingY: '72px',
    width: '100%',
  },
});
