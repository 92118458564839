import { isString } from 'lodash';
import { PortalKey } from '../../graphQL';

export const Portals = {
  ...PortalKey,
  Blocked: 'blocked',
  Public: 'public',
} as const;

export type Portal = (typeof Portals)[keyof typeof Portals];

export type ActivePortals = {
  [key in Portal]?: boolean;
};

type IPortalsRule = {
  check: (activePortals: ActivePortals) => boolean;
  toString: () => string;
};
export type AllowedPortal = Portal | IPortalsRule;

/**
 * Portals rule that only returns true if the single given portal is active.
 */
class PortalsOnly implements IPortalsRule {
  readonly allowedPortal: Portal;

  constructor(allowedPortal: Portal) {
    this.allowedPortal = allowedPortal;
  }

  check(activePortals: ActivePortals): boolean {
    // The Hub portal should always be active.
    return activePortals[this.allowedPortal] === true;
  }

  toString(): string {
    return this.allowedPortal;
  }
}

/**
 * Portals rule that only returns true if ALL the given rules/portals are active.
 * This is similar to an AND statement / Array.every().
 */
class PortalsAll implements IPortalsRule {
  readonly rules: IPortalsRule[];

  constructor(...allowedPortals: AllowedPortal[]) {
    this.rules = allowedPortals.flatMap(allowed => {
      if (allowed instanceof PortalsAll) {
        return allowed.rules;
      }
      return castToRule(allowed);
    });
  }

  check(activePortals: ActivePortals): boolean {
    return this.rules.every(rule => rule.check(activePortals));
  }

  toString(): string {
    const ands = this.rules.map(String).join(' AND ');
    return `(${ands})`;
  }
}

/**
 * Portals rule that only returns true if ANY the given rules/portals are active.
 * This is similar to an OR statement / Array.some().
 */
class PortalsAny implements IPortalsRule {
  readonly rules: IPortalsRule[];

  constructor(...allowedPortals: AllowedPortal[]) {
    this.rules = allowedPortals.flatMap(allowed => {
      if (allowed instanceof PortalsAny) {
        return allowed.rules;
      }
      return castToRule(allowed);
    });
  }

  check(activePortals: ActivePortals): boolean {
    return this.rules.some(rule => rule.check(activePortals));
  }

  toString(): string {
    const ors = this.rules.map(String).join(' OR ');
    return `(${ors})`;
  }
}

export const castToRule = (item: AllowedPortal): IPortalsRule => {
  return isString(item) ? new PortalsOnly(item) : item;
};

export const ALL = (...items: AllowedPortal[]): PortalsAll => new PortalsAll(...items);
export const ANY = (...items: AllowedPortal[]): PortalsAny => new PortalsAny(...items);
export const ONLY = (item: Portal): PortalsOnly => new PortalsOnly(item);
