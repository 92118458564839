import { tail } from 'lodash';
import { IBoxProps } from 'native-base';
import { ReactNode } from 'react';
import { getStylesheet } from '../../../styles';
import { Layout } from '../index';

export type LayoutSlideProps = IBoxProps & {
  center?: boolean;
  children: ReactNode[];
  space?: number;
};

export const LayoutSlide = ({
  center = false,
  children,
  space = 4,
  ...innerProps
}: LayoutSlideProps): JSX.Element => {
  const centerStyles = center ? styles.center : {};

  return (
    <Layout.Center {...styles.outer} testID="layout-slide-wrapper-outer">
      <Layout.View {...styles.inner} {...innerProps} testID="layout-slide-wrapper-inner">
        <Layout.ScrollView
          _contentContainerStyle={{ ...centerStyles, flexGrow: 1 }}
          testID="layout-slide-wrapper-content"
        >
          {children[0]}
        </Layout.ScrollView>

        <Layout.View height={space} />

        {tail(children)}
      </Layout.View>
    </Layout.Center>
  );
};

const styles = getStylesheet({
  outer: {
    flex: 1,
  },

  inner: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 4,
    paddingTop: 12,
    width: '100%',
  },

  center: {
    justifyContent: 'center',
  },
});
