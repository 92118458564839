import { defaultTo } from 'lodash';
import React from 'react';
import { Heading, Layout, Link, Text } from '../../../components/core';
import { getStylesheet } from '../../../styles';

type AdditionalResourcesProps = {
  orgHasResources: boolean;
};

export const AdditionalResources = ({ orgHasResources }: AdditionalResourcesProps): JSX.Element => {
  return (
    <Layout.VStack space={6}>
      <Heading.h4>
        {orgHasResources ? 'Additional Crisis Resources' : 'Crisis Resources'}
      </Heading.h4>

      <Heading.h4 {...style.emergency}>Emergency: Dial 988</Heading.h4>

      <Layout.VStack space={6}>
        {RESOURCES.map(resource => (
          <Layout.VStack key={resource.name} space={2}>
            <Heading.h4>{resource.name}</Heading.h4>

            {'phone' in resource && <Text.para>{resource.phone}</Text.para>}

            {'href' in resource && (
              <Link.para to={defaultTo(resource.href, '')}>{resource.linkText}</Link.para>
            )}
          </Layout.VStack>
        ))}
      </Layout.VStack>
    </Layout.VStack>
  );
};

const style = getStylesheet({
  emergency: {
    color: 'danger.600',
  },
});

const RESOURCES = [
  {
    name: 'National Suicide Prevention Hotline',
    phone: '988',
    href: 'https://suicidepreventionlifeline.org/chat/',
    linkText: 'Suicide Prevention Lifeline Web Chat',
  },
  {
    name: 'Crisis Text Line',
    phone: 'Text HOME to 741-741',
  },
  {
    name: 'National Hopeline Network',
    phone: '1 (800) 784-2433',
  },
  {
    name: 'Self-Harm Hotline',
    phone: '1 (800) 366-8288',
  },
  {
    name: 'Substance Abuse and Mental Health Services Administration (SAMHSA) National Helpline',
    phone: '1-800-662-HELP',
  },
  {
    name: 'National Domestic Violence Hotline',
    phone: '1 (800) 799-7233',
  },
  {
    name: 'Family Violence Helpline',
    phone: '1 (800) 996-6228',
  },
  {
    name: 'The Childhelp National Child Abuse Hotline',
    phone: '1 (800) 422-4453',
  },
  {
    name: 'Alcoholism & Drug Dependency Hope Line',
    phone: '1 (800) 622-2255',
  },
  {
    name: 'American Association of Poison Control Centers',
    phone: '1 (800) 222-1222',
  },
  {
    name: 'National Crisis Line - Anorexia & Bulimia',
    phone: '1 (800) 233-4357',
  },
  {
    name: 'LGBT Hotline',
    phone: '1 (888) 843-4564',
    href: 'https://www.glbthotline.org/peer-chat.html',
    linkText: 'LGBT National Online Peer-Support Chat',
  },
  {
    name: 'TREVOR Crisis Hotline (LGBTQ)',
    phone: '1 (866) 488-7386',
    href: 'https://www.thetrevorproject.org/get-help-now/',
    linkText: 'TrevorChat',
  },
  {
    name: 'TransLifeline',
    phone: '1 (877) 565-8860',
  },
  {
    name: 'Planned Parenthood Hotline',
    phone: '1 (800) 230-7526',
  },
  {
    name: 'AIDS Crisis Line',
    phone: '1 (800) 221-7044',
  },
  {
    name: 'Rape and Incest National Network Online Help Center',
    phone: '1 (800) 656-4673',
    href: 'https://hotline.rainn.org/online',
    linkText: 'RAINN Online Hotline',
  },
  {
    name: 'Veterans Crisis Line',
    phone: '1-800-273-8255 (Press 1)',
    href: 'https://www.veteranscrisisline.net',
    linkText: 'Visit Online',
  },
];
