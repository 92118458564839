import { JSX } from 'react';
import { Button, Heading, Layout, Link, SimpleModal, Text } from '../../../components/core';
import { getRoute } from '../../../routes';
import { ErrorAssistance } from './ErrorAssistance';

const SignupErrorUnavailable = ({ headerId }: { headerId: string }): JSX.Element => {
  return (
    <Layout.VStack space={2}>
      <Heading.h4 id={headerId}>
        Unfortunately, Mantra services are only available to students enrolled at partner
        institutions.
      </Heading.h4>

      <Text.para>The email you entered does not belong to any of our partner schools.</Text.para>

      <Text.para>
        If you are certain that your school partners with Mantra,{' '}
        <Text.para bold>be sure to sign up using your school email address.</Text.para>
      </Text.para>
    </Layout.VStack>
  );
};

const SignupErrorIsIneligible = ({ headerId }: { headerId: string }): JSX.Element => (
  <Layout.VStack space={2}>
    <Heading.h4 id={headerId}>
      We partner with your institution, but we can’t verify your eligibility
    </Heading.h4>

    <Text.para>
      Based on the email address you entered, Mantra partners with your school to provide care for
      currently enrolled students.
    </Text.para>

    <ErrorAssistance />
  </Layout.VStack>
);

const SignupErrorReferralRequired = ({ headerId }: { headerId: string }): JSX.Element => {
  return (
    <Layout.VStack space={5}>
      <Heading.h3 id={headerId}>Campus referral required</Heading.h3>

      <Text.para>
        <Text.para bold>Already referred?</Text.para> If you were recently referred to Mantra by
        your counseling center, please{' '}
        <Text.para bold>
          activate your account using the unique link in the invite email you were sent
        </Text.para>{' '}
        or{' '}
        <Link.para bold to={getRoute('loginActivationResend', {})}>
          resend your activation details
        </Link.para>
        .
      </Text.para>

      <Text.para>
        <Text.para bold>Not yet referred?</Text.para> To sign up for Mantra services, your
        university requires a referral from an authorized campus staff member to request a referral
        to Mantra services.
      </Text.para>

      <ErrorAssistance />
    </Layout.VStack>
  );
};

const errorMap = {
  unavailable: SignupErrorUnavailable,
  isIneligible: SignupErrorIsIneligible,
  refRequired: SignupErrorReferralRequired,
};

export type SignupErrorType = keyof typeof errorMap;

type SignupErrorArgs = {
  errorType: SignupErrorType;
  onClose: () => void;
};

export const SignupErrorModal = ({ errorType, onClose }: SignupErrorArgs): JSX.Element => {
  const ErrorComponent = errorMap[errorType];

  const headerId = 'signup-error-modal-header';

  return (
    <SimpleModal headerId={headerId} onClose={onClose} padding={6} paddingTop={12}>
      <Layout.VStack space={12}>
        <ErrorComponent headerId={headerId} />

        <Button.secondaryMedium
          testID="button-signup-error-close"
          onPress={onClose}
          alignSelf="flex-start"
        >
          Close
        </Button.secondaryMedium>
      </Layout.VStack>
    </SimpleModal>
  );
};
