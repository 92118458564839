import { Layout, Link, Text } from '../../../components/core';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';
import { getStylesheet } from '../../../styles';

// TODO: Customize the phone number based on entitlement info
export const CrisisSupport = (): JSX.Element => {
  return (
    <Layout.VStack {...styles.container} space={4}>
      <Layout.Center>
        <IconAlertTriangle size={8} color="warning.500" />
      </Layout.Center>
      <Text.para center>
        For crisis support via phone, call the 24/7 Crisis Support Line at{' '}
        <Link.para to="tel:999-999-9999">999-999-9999</Link.para>
      </Text.para>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  container: {
    padding: 4,
    backgroundColor: 'warning.50',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'warning.300',
    alignItems: 'center',
  },
});
