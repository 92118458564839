import { useAllPrismicDocumentsByIDs } from '@prismicio/react';
import { isEmpty } from 'lodash';
import { useReload } from '../../../utils/useReload';
import { SkillDocument } from '../SkillTypes';

type SkillsById = [
  SkillDocument[] | undefined,
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const useSkillsByIds = ({ skillIds }: { skillIds: string[] }): SkillsById => {
  const [shouldSkip, refetch] = useReload();

  const [skills, { error, state }] = useAllPrismicDocumentsByIDs<SkillDocument>(skillIds, {
    skip: shouldSkip || isEmpty(skillIds),
  });

  return [
    skills,
    {
      idle: state === 'idle',
      loading: state === 'loading',
      loaded: state === 'loaded',
      error,
      refetch,
    },
  ];
};
