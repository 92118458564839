import React from 'react';
import { getRoute, useNavigate } from '../../../../routes';
import { getStylesheet } from '../../../../styles';
import { Button, Heading, Layout, SimpleModal, Text } from '../../../core';

type ImpersonateModalProps = {
  impersonatingId: string;
  onClose: () => void;
};

export const ImpersonateModal = ({
  impersonatingId,
  onClose,
}: ImpersonateModalProps): JSX.Element => {
  const navigate = useNavigate();

  const onClosePress = (): void => {
    onClose();
  };

  const onStopPress = (): void => {
    navigate(getRoute('logout', {}));
    onClose();
  };

  const headerId = 'impersonate-modal-header';

  return (
    <SimpleModal {...styles.modalContent} headerId={headerId} onClose={onClosePress}>
      <Layout.VStack space={8} paddingTop={12}>
        <Heading.h4 id={headerId}>You are currently impersonating {impersonatingId}.</Heading.h4>

        <Text.para>
          Any action taken while impersonating has the same effect as if the impersonated user did
          it themselves.
        </Text.para>

        <Layout.HStack space={3}>
          <Button.secondaryMedium testID="button-impersonate-close" onPress={onClosePress}>
            Close
          </Button.secondaryMedium>

          <Button.dangerMedium testID="button-impersonate-stop" onPress={onStopPress}>
            Stop Impersonation
          </Button.dangerMedium>
        </Layout.HStack>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  modalContent: {
    width: '100%',
    maxWidth: '342px',
    borderRadius: 0,
  },
});
