import { SkillDocument } from '../SkillTypes';
import { useSkillById } from './useSkillById';
import { useSkillByTag } from './useSkillByTag';

type SkillResponse = [
  SkillDocument | undefined,
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

type ValidTypes = 'tour' | 'skill' | 'selfCareQuiz';

type SkillByTypeOptions<T extends ValidTypes> = {
  type: T;
  skillId: T extends 'skill' ? string : undefined;
};

export const useSkillByType = <T extends ValidTypes>(
  options: SkillByTypeOptions<T>,
): SkillResponse => {
  const selfCareQuiz = useSkillByTag('selfCareQuiz', {
    skip: options.type !== 'selfCareQuiz',
  });
  const skillResults = useSkillById({ skillId: options?.skillId, skip: options.type !== 'skill' });
  const tourResults = useSkillByTag('tour', {
    skip: options.type !== 'tour',
  });

  if (options.type === 'skill') {
    return skillResults;
  }
  if (options.type === 'selfCareQuiz') {
    return selfCareQuiz;
  }
  if (options.type === 'tour') {
    return tourResults;
  }

  return [
    undefined,
    {
      idle: true,
      loading: false,
      loaded: false,
      refetch: () => undefined,
    },
  ];
};
