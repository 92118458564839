import { ApolloProvider } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { AppRoutes } from './AppRoutes';
import { BreakpointProvider } from './contexts/breakpointContext';
import { HeapProvider } from './contexts/heapContext';
import { PortalProvider } from './contexts/portalContext';
import { ScrollProvider } from './contexts/scrollContext';
import { NativeBaseProvider, PrismicProvider, setupAppPlugins } from './plugins';
import { MobileAppAccessories } from './plugins/accessories/MobileAppAccessories';
import { PushNotificationsHandler } from './plugins/pushNotifications/PushNotificationsHandler';
import { useLocation, useNavigate } from './routes';

export const AppPlugins = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const { apolloClient, errorLink } = useMemo(() => {
    return setupAppPlugins();
  }, []);

  useEffect(() => {
    errorLink.setNavAndLocation(navigate, location);
  }, [location, navigate]);

  return (
    <ApolloProvider client={apolloClient}>
      <NativeBaseProvider>
        <BreakpointProvider>
          <PrismicProvider>
            <HeapProvider>
              <PortalProvider errorLink={errorLink}>
                <GestureHandlerRootView style={{ flex: 1 }}>
                  <ScrollProvider>
                    <PushNotificationsHandler />
                    <MobileAppAccessories />
                    <AppRoutes />
                  </ScrollProvider>
                </GestureHandlerRootView>
              </PortalProvider>
            </HeapProvider>
          </PrismicProvider>
        </BreakpointProvider>
      </NativeBaseProvider>
    </ApolloProvider>
  );
};
