import { JSX, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { Pressable } from '../core';
import { IconEye } from '../icons/IconEye';
import { IconEyeOff } from '../icons/IconEyeOff';
import { FormInput, FormInputProps } from './FormInput';

export const FormInputPassword = <TFieldValues extends FieldValues>({
  ...props
}: Omit<FormInputProps<TFieldValues>, 'InputRightElement' | 'type'>): JSX.Element => {
  const [showValue, setShowValue] = useState(false);

  const onShowHidePress = (): void => {
    setShowValue(currentValue => !currentValue);
  };

  const Eye = useMemo(() => {
    const testId = `form-input-password-${showValue ? 'hide' : 'show'}-password`;
    const Icon = showValue ? IconEye : IconEyeOff;

    return (
      <Pressable
        aria-label={showValue ? 'Hide password' : 'Show password'}
        onPress={onShowHidePress}
        paddingRight={3}
        testID={testId}
      >
        <Icon aria-hidden color="text.700" size={6} />
      </Pressable>
    );
  }, [showValue]);

  return <FormInput {...props} InputRightElement={Eye} type={showValue ? 'text' : 'password'} />;
};
