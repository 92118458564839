import { compact } from 'lodash';
import React from 'react';
import { Link } from '../../../../../components/core';
import { getRoute } from '../../../../../routes';
import { IntroViewSkillsSlice } from '../../../ViewTypes';

type IntroViewSkillsProps = {
  slice: IntroViewSkillsSlice;
};

type ModuleLink = Extract<IntroViewSkillsSlice['items'][number]['module'], { id: string }>;

const IntroViewSkills = ({ slice }: IntroViewSkillsProps): JSX.Element => {
  const modules: ModuleLink[] = compact(
    slice.items.map(({ module }) => {
      return 'id' in module ? module : undefined;
    }),
  );

  return (
    <>
      {modules.map((module, itemIndex) => (
        <section key={itemIndex}>
          <Link.para to={getRoute('module', { moduleId: module.id })}>{module.slug}</Link.para>
        </section>
      ))}
    </>
  );
};

export default IntroViewSkills;
