import { isArray } from 'lodash';
import { createIcon as baseCreateIcon } from 'native-base';
import {
  Circle,
  CircleProps,
  Ellipse,
  EllipseProps,
  Path,
  PathProps,
  Rect,
  RectProps,
} from 'react-native-svg';
import type { IcreateIconProps } from '../../types/nativeBaseTypes';

export { Circle, Ellipse, Path, Rect, Svg } from 'react-native-svg';

export type IconType = ReturnType<typeof baseCreateIcon>;

type Props = IcreateIconProps & {
  accessibilityLabel?: string;
  title: string;
};

export const createIcon = (props: Props): IconType => {
  const keyedProps = addKeysToPaths(props);

  return baseCreateIcon(keyedProps);
};

/**
 * Makes sure that there are multiple paths that a key is added to each path.
 * This prevents react warnings on android.
 */
const addKeysToPaths = (props: Props): Props => {
  if (!isArray(props.path)) {
    return props;
  }

  const newPath = props.path.map((pathEl, index) => {
    let key = `pathItem-${index}`;
    if (pathEl.type === Path) {
      // Support for <Path>
      key = (pathEl.props as PathProps).d ?? key;
    } else if (pathEl.type === Circle) {
      // Support for <Circle>
      const elProps = pathEl.props as CircleProps;
      key = `${elProps.cx ?? 0}-${elProps.cy ?? 0}-${elProps.r ?? 0}`;
    } else if (pathEl.type === Rect) {
      const elProps = pathEl.props as RectProps;
      key = `${elProps.x ?? 0}-${elProps.y ?? 0}-${elProps.height ?? 0}-${elProps.width ?? 0}`;
    } else if (pathEl.type === Ellipse) {
      const elProps = pathEl.props as EllipseProps;
      key = `${elProps.cx ?? 0}-${elProps.cy ?? 0}-${elProps.rx ?? 0}-${elProps.ry ?? 0}`;
    }

    return {
      ...pathEl,
      key,
    };
  });

  return {
    ...props,
    path: newPath,
  };
};
