import { createIcon, Path } from './index';

export const IconEyeOff = createIcon({
  title: 'A closed eye.',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M9.87964 9.88C9.58489 10.1547 9.34848 10.4859 9.18451 10.8539C9.02055 11.2218 8.93238 11.6191 8.92527 12.0219C8.91816 12.4247 8.99226 12.8248 9.14315 13.1984C9.29403 13.5719 9.51861 13.9113 9.80348 14.1962C10.0884 14.481 10.4277 14.7056 10.8012 14.8565C11.1748 15.0074 11.5749 15.0815 11.9777 15.0744C12.3805 15.0673 12.7778 14.9791 13.1458 14.8151C13.5138 14.6512 13.845 14.4148 14.1196 14.12"
      fill="none"
      stroke="#35405F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M10.7305 5.08C11.1518 5.02751 11.5759 5.00079 12.0005 5C19.0005 5 22.0005 12 22.0005 12C21.5534 12.9571 20.9927 13.8569 20.3305 14.68"
      fill="none"
      stroke="#35405F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M6.61 6.60999C4.62125 7.96461 3.02987 9.82524 2 12C2 12 5 19 12 19C13.9159 19.0051 15.7908 18.4451 17.39 17.39"
      fill="none"
      stroke="#35405F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M2 2L22 22"
      fill="none"
      stroke="#35405F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
