import { JSX, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Layout, Text } from '../../components/core';
import { PageContent, PageFooter, PageTopBar } from '../../components/page';
import { getRoute } from '../../routes';
import { ActivationResendForm } from './forms/ActivationResendForm';

export const ActivationResendPage = (): JSX.Element => {
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState<string | undefined>();

  const onBackPress = (): void => {
    navigate(getRoute('login', {}));
  };

  const onActivationSuccess = (newUserEmail: string): void => {
    setUserEmail(newUserEmail);
  };

  return (
    <PageContent paddingTop={4} pageSize="medium" testID="activation-resend-page">
      {userEmail === undefined && (
        <>
          <PageTopBar
            backAccessibilityLabel="Back to Login"
            backTitle="Back"
            onBackPress={onBackPress}
            paddingBottom={8}
          />

          <Layout.VStack space={4}>
            <Heading.h3>Resend Activation Details</Heading.h3>

            <Text.para>
              If you were referred to Mantra Health by your university, you should have received
              activation instructions in your email.
            </Text.para>

            <Text.para>
              If you'd like us to resend your activation email, enter your university email below.
            </Text.para>

            <ActivationResendForm onSuccess={onActivationSuccess} />
          </Layout.VStack>
        </>
      )}

      {userEmail !== undefined && (
        <Layout.VStack space={8}>
          <Layout.VStack space={4}>
            <Heading.h3>Activation Details Sent</Heading.h3>

            <Text.para>
              If you were referred by your university, you will receive new activation instructions
              at {userEmail}.
            </Text.para>
          </Layout.VStack>

          <Button.secondaryMedium
            testID="button-activation-resend-back-to-login"
            onPress={onBackPress}
          >
            Back to Login
          </Button.secondaryMedium>
        </Layout.VStack>
      )}

      <PageFooter />
    </PageContent>
  );
};
