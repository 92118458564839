import { Platform } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Heading, Layout, Skeleton } from '../../components/core';
import { PageFooter } from '../../components/page';
import { useOnboardingUserDataQuery } from '../../graphQL';
import { usePushNotifications } from '../../plugins/pushNotifications/usePushNotifications';
import { getRoute } from '../../routes';
import { getStylesheet } from '../../styles';
import { OnboardingForm } from './forms/OnboardingForm';
import { ImageDog } from './ImageDog';

export function OnboardingPage(): JSX.Element {
  const navigate = useNavigate();
  const { registerForPushNotifications } = usePushNotifications();
  const { data: onboardingUserData, loading: loadingOnboardingUserData } =
    useOnboardingUserDataQuery();

  const defaultUserName = onboardingUserData?.onboardingHubUser.preferredName ?? '';
  const birthDate = onboardingUserData?.onboardingHubUser.birthDate ?? '';

  const onSaved = async (): Promise<void> => {
    if (Platform.OS !== 'web') {
      await registerForPushNotifications();
    }
    navigate(getRoute('onboardingPhoneNumber', {}));
  };

  const onConsentBlocked = (): void => {
    navigate(getRoute('home', {}));
  };

  return (
    <Layout.Flex {...styles.page} testID="onboarding-page">
      <Layout.VStack {...styles.stack} space={7}>
        <ImageDog size={181} marginBottom={2} />

        <Heading.h2 center>Welcome to the Mantra Care Hub</Heading.h2>

        {loadingOnboardingUserData ? (
          <Skeleton height={40} />
        ) : (
          <OnboardingForm
            defaultUserName={defaultUserName}
            currentBirthDate={birthDate}
            onConsentBlocked={onConsentBlocked}
            onSaved={onSaved}
          />
        )}
      </Layout.VStack>

      <PageFooter />
    </Layout.Flex>
  );
}

const styles = getStylesheet({
  page: {
    height: '100%',
    marginTop: 16,
    position: 'relative',
  },

  stack: {
    alignItems: 'center',
    display: 'flex',
  },
});
