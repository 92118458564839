import { usePrismicDocumentByID } from '@prismicio/react';
import { isEmpty } from 'lodash';
import { useReload } from '../../../utils/useReload';
import { SkillDocument } from '../SkillTypes';

type SkillById = [
  SkillDocument | undefined,
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const useSkillById = ({
  skillId,
  skip,
}: {
  skillId?: string;
  skip?: boolean;
}): SkillById => {
  const [shouldSkip, refetch] = useReload();

  const [skill, { error, state }] = usePrismicDocumentByID<SkillDocument>(String(skillId), {
    skip: shouldSkip || skip === true || isEmpty(skillId),
  });

  return [
    skill,
    {
      idle: state === 'idle',
      loading: state === 'loading',
      loaded: state === 'loaded',
      error,
      refetch,
    },
  ];
};
