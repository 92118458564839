import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useScrollContext } from '../../contexts/scrollContext';
import { getStylesheet } from '../../styles';
import { usePrevious } from '../../utils/usePrevious';
import { Layout } from '../core';
import { PageLoading } from '../page';
import { useSlideParamCheck } from './hooks/useSlideParamCheck';
import { SlideContextType } from './slideContextFactory';
import { SlideTransition } from './SlideTransition';
import { ISlide, SlideData } from './SlideTypes';

type SlidesProps<MoreSlideProps> = MoreSlideProps & {
  context: SlideContextType<ISlide<MoreSlideProps>>;
};

export const Slides = <MoreSlideProps extends Record<string, unknown>>({
  context,
  ...slideProps
}: SlidesProps<MoreSlideProps>): JSX.Element => {
  const { slideCount, slideNumber, slides } = context;

  const previousSlideNumber = usePrevious(slideNumber) ?? 0;
  const { scrollToTop } = useScrollContext();
  useSlideParamCheck(context);

  useEffect(() => {
    scrollToTop();
  }, [slideNumber, isEmpty(slides)]);

  if (isEmpty(slides)) {
    // Don't render while the slides are not registered.
    return <PageLoading pageName="slides" />;
  }

  const isGoingBack = slideNumber < previousSlideNumber;

  return (
    <Layout.View {...styles.slides} testID="slides">
      {slides.map((SlideComponent, index) => {
        // +1 offsets it to 1-index based.
        const iterationSlideNumber = index + 1;

        // If there is no next slide, the last slide should always be visible even if the
        // slide numbers don't match.
        const isVisible = slideNumber === iterationSlideNumber;

        const slideData: SlideData = {
          isFinalSlide: iterationSlideNumber >= slideCount,
          isVisible,
          slideIndex: iterationSlideNumber,
        };

        const moreSlideProps = slideProps as unknown as MoreSlideProps;

        return (
          <SlideTransition
            key={iterationSlideNumber}
            slideCount={slideCount}
            slideNumber={iterationSlideNumber}
            visible={isVisible}
            back={isGoingBack}
          >
            <SlideComponent {...moreSlideProps} slideData={slideData} />
          </SlideTransition>
        );
      })}
    </Layout.View>
  );
};

const styles = getStylesheet({
  slides: {
    flex: 1,
    paddingX: 4,
    position: 'relative',
  },
});
