import { Platform, Role } from 'react-native';

export const getLinkRoleProps = (isLink: boolean): { role?: Role; href?: string; tabIndex?: 0 } => {
  const linkRole: Role = 'link';

  if (!isLink) {
    return {};
  }

  if (Platform.OS !== 'web') {
    return { role: linkRole };
  }

  // Without any href, it won't properly render as an anchor and have enter keypress click work.
  // If we don't include `javascript:;` in the href, it will reload the page when activated.
  return {
    role: linkRole,
    href: 'javascript:;',
    tabIndex: 0,
  };
};
