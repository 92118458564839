import { CloseIcon, IStackProps } from 'native-base';
import React, { ReactNode } from 'react';
import { getStylesheet } from '../../styles';
import { Button, Heading, Layout, Pressable } from '../core';
import { IconChevronLeft } from '../icons/IconChevronLeft';

type PageTopBarProps = IStackProps & {
  actionButton?: JSX.Element;
  backAccessibilityLabel: string;
  backTitle?: string;
  children?: ReactNode;
  closeAccessibilityLabel?: string;
  onBackPress: () => void;
  onClosePress?: () => void;
  textColor?: string;
};

export const PageTopBar = ({
  actionButton,
  backAccessibilityLabel,
  backTitle,
  children,
  closeAccessibilityLabel,
  onBackPress,
  onClosePress,
  textColor = 'darkText',
  ...topStyles
}: PageTopBarProps): JSX.Element => {
  return (
    <Layout.HStack {...styles.topBar} {...topStyles} testID="page-top-bar">
      <Pressable
        {...styles.backButton}
        {...(backAccessibilityLabel ? { 'aria-label': backAccessibilityLabel } : {})}
        onPress={onBackPress}
        testID="page-top-bar-go-back"
      >
        <Layout.HStack alignItems="center" flex={1} space={2}>
          <IconChevronLeft color={textColor} size={5} accessibilityHidden />

          {backTitle !== undefined && <Heading.h5 color={textColor}>{backTitle}</Heading.h5>}
        </Layout.HStack>
      </Pressable>

      {children}

      {actionButton}

      {onClosePress && (
        <Button.tertiaryMedium
          aria-label={closeAccessibilityLabel ?? 'Close'}
          rightIcon={<CloseIcon accessibilityHidden />}
          testID="button-page-top-bar-close"
          onPress={onClosePress}
        />
      )}
    </Layout.HStack>
  );
};

const styles = getStylesheet({
  topBar: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
    minHeight: '44px',
    marginTop: 4,
    width: '100%',
  },

  backButton: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '44px',
    paddingRight: 4,
  },
});
