import { getStylesheet } from '../../../styles';
import { Layout } from '../../core';
import { APP_SIDEBAR_WIDTH_PX } from './appSizes';
import { NavLinks } from './NavLinks';

export const AppSidebar = (): JSX.Element => {
  return (
    <Layout.Flex {...styles.sideBar} testID="app-layout-sidebar">
      <NavLinks />
    </Layout.Flex>
  );
};

const styles = getStylesheet({
  sideBar: {
    alignItems: 'flex-start',
    height: '100%',
    paddingX: 2,
    paddingY: 6,
    // Needs further testing with horizontal mobile devices
    position: 'absolute',
    width: `${APP_SIDEBAR_WIDTH_PX}px`,

    _web: {
      position: 'fixed',
    },
  },
});
