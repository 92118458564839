import { useToken } from 'native-base';
import RenderHTML from 'react-native-render-html';
import { Layout, Pressable, Text } from '../../../components/core';
import { getStylesheet } from '../../../styles';

type ChatBubbleProps = {
  content: string;
  isUser: boolean;
  systemGenerated: boolean;
  onMessageClick?: () => void;
};

export const ChatBubble = ({
  content,
  isUser,
  systemGenerated,
  onMessageClick,
}: ChatBubbleProps): JSX.Element => {
  const renderHTML = (message: string): JSX.Element => {
    const [darkTextColor, linkColor] = useToken('colors', ['darkText', 'primary.600']);
    const isItalic = systemGenerated ? 'italic' : 'normal';

    // eslint-disable-next-line id-length
    const tagsStyles = { a: { color: linkColor }, p: { marginVertical: 1 } };

    return (
      <RenderHTML
        source={{ html: message }}
        baseStyle={{ color: darkTextColor, fontSize: 16, fontStyle: isItalic }}
        tagsStyles={tagsStyles}
        contentWidth={290}
      />
    );
  };

  return (
    <Pressable
      testID="coaching-chat-message"
      {...style.chatBubble}
      {...(isUser && style.isUser)}
      {...(!isUser && !systemGenerated && style.isCoach)}
      {...(systemGenerated && style.systemGenerated)}
      onPress={onMessageClick}
    >
      {content[0] === '<' ? (
        <Layout.View>{renderHTML(content)}</Layout.View>
      ) : (
        <Text.para italic={systemGenerated}>{content}</Text.para>
      )}
    </Pressable>
  );
};

const style = getStylesheet({
  chatBubble: {
    borderRadius: 8,
    flex: 1,
    maxWidth: '290px',
    padding: 2,
    shadow: 'none',

    _web: {
      flex: undefined,
      shadow: 'small',
    },
  },

  isUser: {
    backgroundColor: 'white',
    borderBottomRightRadius: 0,
  },

  isCoach: {
    backgroundColor: 'primary.alpha.20:alpha.20',
    borderBottomLeftRadius: 0,
  },

  systemGenerated: {
    backgroundColor: 'secondary.200',
    shadow: 'none',
  },
});
