import { ApolloError as FullApolloError } from '@apollo/client';

type ApolloError = Pick<FullApolloError, 'graphQLErrors'>;

type GraphqlExtension = {
  code: string;
  customCode?: string;
};

/*
 * Global GraphQL Errors
 */

/**
 * Determines if the graphQL error(s) are of a specific global type.
 * This should not be exported, instead specific functions for each global error should be created.
 *
 * @param apolloError - The complete apollo error object (must have a graphQLErrors property).
 * @param globalType  - Matches the exception code defined by the API.
 * @param entryPoint  - Optionally only search errors for a specific query/mutation by providing the
 *                      name of the query/mutation here. If not provided, every error is checked.
 */
const isGraphqlErrorOfGlobalType = (
  apolloError: ApolloError,
  globalType: string,
  entryPoint?: string,
): boolean => {
  return apolloError.graphQLErrors.some(({ extensions, path }) => {
    if (entryPoint !== undefined && path?.[0] !== entryPoint) {
      return false;
    }

    const globalCodeExtension = extensions as GraphqlExtension;
    return globalCodeExtension.code === globalType;
  });
};

/**
 * Whether the request has been blocked due to rate limiting.
 */
export const isRateLimited = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'RATE_LIMIT');
};

/**
 * Whether this specific graphQL error is an invalid authentication error.
 */
export const isInvalidAuthentication = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'INVALID_AUTHENTICATION');
};

/**
 * Whether this specific graphQL error is because the user is outside the US.
 */
export const isGeolocationBlocked = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'GEOLOCATION_BLOCKED');
};

/**
 * Whether this specific graphQL error is because the user has not onboarded yet.
 */
export const isOnboardingRequired = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'ONBOARDING_REQUIRED');
};

/**
 * Whether this specific graphQL error is because the user is too young to access Mantra.
 */
export const isAgeBlocked = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'AGE_BLOCKED');
};

/**
 * Whether this specific graphQL error is because the user still requires consent to access Mantra.
 */
export const isAgeConsentRequired = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'AGE_CONSENT_REQUIRED');
};

/**
 * Whether this specific graphQL error is because the user had consent as a minor, but is now an
 * adult.
 */
export const isAgeTermsRequired = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'AGE_TERMS_REQUIRED');
};

/**
 * Whether any total site blocker graphQL error exists.
 */
export const isSiteBlocked = (apolloError: ApolloError): boolean => {
  return [
    isGeolocationBlocked(apolloError),
    isAgeBlocked(apolloError),
    isAgeConsentRequired(apolloError),
    isAgeTermsRequired(apolloError),
  ].some(Boolean);
};
