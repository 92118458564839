import { ReactNode } from 'react';
import { TherapyProvider } from '../../../contexts';
import { useBreakpointSwitch } from '../../../contexts/breakpointContext';
import { getStylesheet } from '../../../styles';
import { Layout } from '../../core';
import { LayoutCore } from '../LayoutCore';
import { AppFooter } from './AppFooter';
import { AppHeader } from './AppHeader';
import { AppSidebar } from './AppSidebar';
import { NewUserRedirect } from './NewUserRedirect';

type AppLayoutProps = {
  children: ReactNode;
};

export const AppLayout = ({ children }: AppLayoutProps): JSX.Element => {
  const breakPoints = useBreakpointSwitch({
    mobile: (
      <Layout.Flex {...styles.vertical} testID="app-layout-vertical">
        <LayoutCore pageSize="largest">{children}</LayoutCore>

        <AppFooter />
      </Layout.Flex>
    ),
    desktop: (
      <Layout.HStack {...styles.horizontal} testID="app-layout-horizontal">
        <Layout.Flex flex={1} minWidth="205px" position="relative">
          <AppSidebar />
        </Layout.Flex>

        <LayoutCore pageSize="largest">{children}</LayoutCore>
      </Layout.HStack>
    ),
  });

  return (
    <TherapyProvider>
      <NewUserRedirect />

      <Layout.Flex {...styles.layout}>
        <AppHeader />

        {breakPoints}
      </Layout.Flex>
    </TherapyProvider>
  );
};

const styles = getStylesheet({
  layout: {
    alignItems: 'center',
    backgroundColor: 'secondary.100',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },

  vertical: {
    flex: 1,
    paddingBottom: 24,
    paddingTop: 58,
    position: 'relative',
    width: '100%',

    _web: {
      paddingTop: 'initial',
    },
  },

  horizontal: {
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: 58,
    position: 'relative',
    width: '100%',

    _web: {
      paddingTop: 'initial',
    },
  },
});
