import Svg, { Path, Rect } from 'react-native-svg';

export const ImageHandHoldingPaper = (): JSX.Element => {
  return (
    <Svg
      accessibilityElementsHidden
      title="Hand holding a piece of paper with writing on it."
      viewBox="0 0 326 206"
      height={206}
      width={326}
    >
      <Rect width="326" height="206" rx="8" fill="#C7D2FE" />
      <Path
        d="M112.631 224.505L43.9893 139.197C42.4392 137.271 42.7439 134.449 44.6703 132.899L157.645 41.9825C159.572 40.4324 162.394 40.737 163.944 42.6634L232.586 127.971C234.136 129.897 233.832 132.719 231.905 134.269L118.93 225.186C117.003 226.745 114.181 226.44 112.631 224.505Z"
        fill="#FAEAD3"
      />
      <Path
        d="M269.241 50.7185C269.241 50.7185 248.696 39.2588 222.856 28.6682C190.331 15.3449 169.517 40.3788 154.51 70.5378C139.493 100.715 155.728 103.08 160.513 102.498C165.306 101.906 171.086 92.409 172.501 89.5597C172.501 89.5597 195.878 107.479 203.529 108.465C211.181 109.442 219.818 103.089 212.875 92.0505C205.931 81.0119 200.68 76.2811 200.68 76.2811L269.241 50.7185Z"
        fill="#2D6A62"
      />
      <Path
        d="M112.631 211.647L43.9893 126.34C42.4392 124.414 42.7439 121.591 44.6703 120.041L157.645 29.1253C159.572 27.5752 162.394 27.8799 163.944 29.8062L232.586 115.113C234.136 117.04 233.832 119.862 231.905 121.412L118.93 212.328C117.003 213.878 114.181 213.574 112.631 211.647Z"
        fill="#FAEAD3"
      />
      <Path
        d="M156.956 57.3757C172.456 76.6306 187.948 95.8943 203.449 115.149C204.174 116.045 205.429 114.773 204.712 113.886C189.211 94.631 173.72 75.3672 158.219 56.1124C157.502 55.2164 156.239 56.4887 156.956 57.3757Z"
        fill="#1D2744"
      />
      <Path
        d="M166.229 86.9704C175.279 99.2006 185.78 110.266 194.82 122.505C195.501 123.419 197.051 122.532 196.37 121.6C187.33 109.361 176.829 98.3046 167.779 86.0654C167.098 85.1515 165.539 86.0475 166.229 86.9704Z"
        fill="#1D2744"
      />
      <Path
        d="M148.471 64.9112C153.309 70.9233 158.148 76.9354 162.986 82.9474C163.712 83.8434 164.966 82.5711 164.249 81.6841C159.411 75.672 154.572 69.6599 149.734 63.6478C149.008 62.7429 147.754 64.0241 148.471 64.9112Z"
        fill="#1D2744"
      />
      <Path
        d="M168.881 112.909C173.854 119.092 178.836 125.283 183.809 131.465C184.534 132.361 185.789 131.089 185.072 130.202C180.099 124.019 175.117 117.828 170.145 111.646C169.428 110.741 168.164 112.013 168.881 112.909Z"
        fill="#1D2744"
      />
      <Path
        d="M137.316 73.6917C146.616 85.474 156.454 96.8352 165.557 108.779C166.247 109.684 167.806 108.797 167.107 107.874C157.914 95.8048 147.987 84.3361 138.588 72.4284C137.871 71.5145 136.617 72.7868 137.316 73.6917Z"
        fill="#1D2744"
      />
      <Path
        d="M133.669 109.854C144.134 122.165 154.447 134.592 164.043 147.593C164.724 148.507 166.274 147.62 165.593 146.688C155.907 133.562 145.496 121.009 134.941 108.591C134.189 107.704 132.925 108.976 133.669 109.854Z"
        fill="#1D2744"
      />
      <Path
        d="M117.684 89.9988C121.976 95.3299 126.268 100.67 130.569 106.001C131.294 106.897 132.549 105.625 131.832 104.738C127.54 99.4067 123.248 94.0666 118.948 88.7355C118.231 87.8305 116.968 89.1028 117.684 89.9988Z"
        fill="#1D2744"
      />
      <Path
        d="M136.276 131.179C142.745 139.216 149.214 147.262 155.692 155.299C156.418 156.195 157.672 154.922 156.956 154.035C150.487 145.998 144.018 137.952 137.54 129.915C136.823 129.01 135.559 130.292 136.276 131.179Z"
        fill="#1D2744"
      />
      <Path
        d="M109.056 97.3459C116.735 107.408 125.22 116.816 132.854 126.914C133.543 127.818 135.093 126.931 134.404 126.009C126.779 115.911 118.285 106.503 110.606 96.4409C109.916 95.536 108.357 96.432 109.056 97.3459Z"
        fill="#1D2744"
      />
      <Path
        d="M121.752 153.829C126.519 159.761 131.295 165.683 136.061 171.615C136.787 172.511 138.041 171.238 137.325 170.351C132.558 164.42 127.782 158.497 123.016 152.566C122.29 151.661 121.036 152.942 121.752 153.829Z"
        fill="#1D2744"
      />
      <Path
        d="M89.5683 113.832C99.6392 125.534 108.456 138.248 118.589 149.896C119.351 150.765 120.614 149.492 119.853 148.632C109.71 136.984 100.903 124.27 90.8317 112.569C90.0791 111.691 88.8156 112.963 89.5683 113.832Z"
        fill="#1D2744"
      />
      <Path
        d="M94.2542 142.289C103.743 155.469 114.862 167.35 124.763 180.207C125.453 181.112 127.012 180.216 126.313 179.302C116.412 166.445 105.293 154.564 95.8042 141.384C95.1412 140.452 93.5822 141.348 94.2542 142.289Z"
        fill="#1D2744"
      />
      <Path
        d="M78.4222 122.613C82.714 127.944 87.0059 133.284 91.3067 138.615C92.0324 139.511 93.2867 138.239 92.5699 137.352C88.2781 132.021 83.9862 126.681 79.6855 121.349C78.9597 120.445 77.7054 121.717 78.4222 122.613Z"
        fill="#1D2744"
      />
      <Path
        d="M365.372 67.9575C365.372 67.9575 364.933 92.4807 355.767 129.198C355.767 129.198 267.906 123.357 251.357 119.575C234.808 115.794 223.707 106.324 217.641 96.5665C217.641 96.5665 184.274 96.9249 176.605 96.7815C168.944 96.6381 164.123 90.8321 162.994 85.9669C161.857 81.1017 164.76 77.3743 171.39 75.0448C178.02 72.7062 192.858 67.9575 192.858 67.9575L269.241 50.7097L296.828 67.9575H365.372Z"
        fill="#2D6A62"
      />
      <Path
        d="M194.856 67.5274C203.296 64.6243 211.844 62.0887 220.437 59.6785C221.548 59.3649 221.073 57.6356 219.962 57.9492C211.369 60.3594 202.821 62.8951 194.381 65.7981C193.297 66.1744 193.763 67.9037 194.856 67.5274Z"
        fill="#1D2744"
      />
      <Path
        d="M163.613 85.1157C172.654 86.0923 183.298 82.0424 184.194 71.8192C184.292 70.6723 182.5 70.6813 182.402 71.8192C181.587 81.0927 171.534 84.1838 163.613 83.3237C162.475 83.1982 162.484 84.9902 163.613 85.1157Z"
        fill="#1D2744"
      />
      <Path
        d="M116.233 213.932C114.459 213.932 112.703 213.161 111.502 211.674L47.2239 131.797C46.9103 131.411 46.9731 130.847 47.3584 130.533C47.7437 130.22 48.3081 130.282 48.6217 130.668L112.9 210.545C114.378 212.382 117.075 212.669 118.903 211.199L227.936 123.455C228.321 123.141 228.886 123.204 229.199 123.589C229.513 123.975 229.45 124.539 229.065 124.853L120.032 212.597C118.912 213.493 117.568 213.932 116.233 213.932Z"
        fill="#1D2744"
      />
    </Svg>
  );
};
