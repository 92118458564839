import { Button, Heading, Image, Layout, Link, Text } from '../../components/core';
import { IconChevronRight } from '../../components/icons/IconChevronRight';
import { PageFooter } from '../../components/page';
import { View } from '../../content/view/components/View';
import { useTherapyContext } from '../../contexts';
import { useBreakpointSwitch } from '../../contexts/breakpointContext';
import { getStylesheet } from '../../styles';

export const TherapyPage = (): JSX.Element => {
  const { orgName, navigateToPatientPortal, orgCanAccessTherapy, orgHasSelfReferrals } =
    useTherapyContext();

  const imageStyles = useBreakpointSwitch({
    mobile: { maxHeight: '245px', height: '245px' } as const,
    tablet: { maxWidth: '200px', width: '200px' } as const,
    desktop: { maxWidth: '300px', width: '300px' } as const,
  });

  // Hard coded temporarily ahead of the MDC launch. This special flow will be removed once
  // MDC is switch to self-referral.
  const tmpIsMiamiDadeCollege = orgName === 'Miami Dade College';
  const showMiamiDadeHardCoded = tmpIsMiamiDadeCollege && !orgHasSelfReferrals;

  return (
    <Layout.VStack {...styles.mainStack} space={8}>
      <Heading.h1 center>Therapy & Psychiatry</Heading.h1>

      <Layout.Shift {...styles.topBox} reverseNonMobile>
        <Image
          {...styles.handsImage}
          {...imageStyles}
          alt="Caring Hands"
          source={{
            uri: 'https://images.prismic.io/mantra-hub/f6a49974-51ce-4525-82b1-8d795e4219ae_TherapyHands.png?auto=compress,format',
          }}
          resizeMode="cover"
          resizeMethod="auto"
        />

        <Layout.VStack {...styles.topBoxInfo} space={12}>
          <Layout.VStack space={2}>
            <Heading.h4>Therapy Services</Heading.h4>

            {showMiamiDadeHardCoded ? (
              <Text.para>
                Miami Dade College partners with Mantra Health to give you more options for free
                mental health and wellness support. If you’re interested in virtual mental health
                care, you can reach out to SingleStop or fill out this{' '}
                <Link.para to="https://www.mdc.edu/singlestop/services/referral-form/">
                  form
                </Link.para>{' '}
                to get referred.
              </Text.para>
            ) : (
              <Text.para>
                {orgName} partners with Mantra Health to give you more options for free mental
                health and wellness support. Connect with one of Mantra's licensed therapists by
                completing the intake form below.
              </Text.para>
            )}
          </Layout.VStack>

          {orgHasSelfReferrals && orgCanAccessTherapy && (
            <Layout.VStack space={2}>
              <Button.primaryMedium
                {...styles.startIntakeButton}
                accessibilityLabel="Start therapy intake in new tab."
                rightIcon={<IconChevronRight size={5} accessibilityHidden />}
                testID="button-therapy-start-intake"
                onPress={() => {
                  navigateToPatientPortal();
                }}
              >
                Start Intake
              </Button.primaryMedium>

              <Text.caption>10 - 15 minutes</Text.caption>
            </Layout.VStack>
          )}
        </Layout.VStack>
      </Layout.Shift>

      <Layout.VStack {...styles.learn} space={10}>
        <Layout.VStack {...styles.learnText} space={4}>
          <Heading.h3 center>Learn evidenced-based life skills while you wait.</Heading.h3>

          <Text.para center>
            Collections to help you with a specific challenge or experience.
          </Text.para>
        </Layout.VStack>

        <View isCollection hideHeader />
      </Layout.VStack>

      <PageFooter marginTop={8} />
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  mainStack: {
    alignSelf: 'center',
    paddingX: 4,
    paddingY: 12,
    maxWidth: '736px',
    width: '100%',
  },

  topBox: {
    backgroundColor: 'secondary.50',
    gap: 1,
    shadow: 'large',
  },

  handsImage: {
    alignSelf: 'center',
    flex: 1,
    height: '100%',
    width: '100%',
  },

  topBoxInfo: {
    flex: 1,
    justifyContent: 'center',
    padding: 8,
  },

  startIntakeButton: {
    maxWidth: '155px',
  },

  learn: {
    justifyContent: 'center',
    marginTop: 16,
  },

  learnText: {
    alignSelf: 'center',
    maxWidth: '418px',
  },
});
