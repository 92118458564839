import { createIcon, Path } from './index';

export const IconLamp = createIcon({
  title: 'Lamp',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M8 2H16L20 12H4L8 2Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M12 12V18"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M8 22V20C8 18.9 8.9 18 10 18H14C14.5304 18 15.0391 18.2107 15.4142 18.5858C15.7893 18.9609 16 19.4696 16 20V22H8Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
