import { range } from 'lodash';
import { AccessibilityProps } from 'react-native';
import { IconDot } from '../icons/IconDot';
import { Layout } from './index';

export { Progress } from 'native-base';

type ProgressDotsProps = AccessibilityProps & {
  maxValue: number;
  value: number;
};

export const ProgressDots = ({
  maxValue,
  value,
  ...accessibilityProps
}: ProgressDotsProps): JSX.Element => {
  const dotCount = Math.max(maxValue, value, 1);

  const activeColor = 'primary.600';
  const inactiveColor = 'primary.alpha.20:alpha.20';

  return (
    <Layout.HStack
      aria-valuemin={1}
      aria-valuemax={maxValue}
      aria-valuetext={String(value)}
      role="progressbar"
      space={4}
      {...accessibilityProps}
    >
      {range(1, dotCount + 1).map(currentDot => (
        <IconDot
          aria-hidden
          key={currentDot}
          size={5}
          color={currentDot === value ? activeColor : inactiveColor}
        />
      ))}
    </Layout.HStack>
  );
};
