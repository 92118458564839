import { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Layout, Text } from '../../components/core';
import { PageContent, PageError, PageFooter, PageTopBar } from '../../components/page';
import { getRoute, useCurrentRouteSearch } from '../../routes';
import { SignupPasswordForm } from './forms/SignupPasswordForm';

export const SignupFinishPage = (): JSX.Element => {
  const navigate = useNavigate();
  const searchParams = useCurrentRouteSearch();

  const token = searchParams.token ?? '';
  const tokenId = searchParams.tokenId ?? '';
  const mrn = searchParams.mrn ?? '';

  if (!token || !tokenId) {
    return <PageError showContact />;
  }

  const onBackPress = (): void => {
    navigate(getRoute('login', {}));
  };

  const onPasswordSuccess = (): void => {
    const mrnParams = mrn ? { mrn } : undefined;
    navigate(getRoute('login', {}, { postSignup: 'true', ...mrnParams }));
  };

  return (
    <PageContent paddingTop={4} pageSize="medium" testID="signup-page">
      <PageTopBar
        backAccessibilityLabel="Back to Login"
        backTitle="Back"
        onBackPress={onBackPress}
        paddingBottom={8}
      />

      <Layout.VStack space={4}>
        <Layout.VStack space={4}>
          <Heading.h3>Create a password</Heading.h3>

          <Text.para>
            Passwords must be at least 8 characters and include at least one uppercase letter, one
            lower case letter, one number and one special character (!, @, #, $, %, ^, &, *)
          </Text.para>

          <SignupPasswordForm token={token} tokenId={tokenId} onSuccess={onPasswordSuccess} />
        </Layout.VStack>
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
};
