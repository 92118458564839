import { Path } from 'react-native-svg';
import { createIcon } from '../../icons';

export const ImageErrorTools = createIcon({
  title: 'A bundle of tools',
  viewBox: '0 0 119 96',
  path: [
    <Path
      d="M13.2083 31.218C14.1723 31.711 15.1742 32.1061 16.1634 32.5391C16.53 32.7003 16.8809 32.8931 17.2412 33.0606C17.5888 32.4759 17.9649 31.9102 18.2778 31.3097C18.8657 30.1782 19.3935 29.0183 19.994 27.8931C20.6609 26.6447 21.3277 25.3994 22.0104 24.1573C19.8265 24.2237 17.6931 25.1592 15.7494 26.0758C14.5926 26.6226 13.4327 27.1946 12.3929 27.95C11.6849 28.4652 11.0465 29.0878 10.4333 29.7357C10.4839 29.761 10.5376 29.7863 10.5851 29.8116C11.4637 30.2793 12.3265 30.766 13.2083 31.218Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M19.7475 42.0554C19.7254 42.166 21.3025 42.7855 21.9346 43.0352C21.9726 42.9435 22.0137 42.855 22.0516 42.7634C21.7102 42.5485 21.3499 42.362 20.9612 42.2387C20.8474 42.204 19.7728 41.9258 19.7475 42.0554Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M25.2657 29.0056C26.0148 29.1699 26.7607 29.5081 27.4844 29.7641C28.0059 28.4841 28.5242 27.2009 29.0394 25.9146C28.461 25.5638 27.87 25.2445 27.2664 24.9759C25.4206 24.151 23.5021 24.4133 22.3675 26.1832C21.7417 27.1598 21.2803 28.2629 20.7588 29.2964C20.1899 30.4247 19.6242 31.553 19.0553 32.6845C17.8258 35.1276 16.5964 37.5707 15.3701 40.0138C12.0736 38.6705 8.69499 37.5485 5.24999 36.6541C5.23103 36.6478 5.2089 36.6446 5.18994 36.6415C6.05277 39.6661 7.44341 42.5738 9.26705 45.0675C10.4491 46.6857 11.9662 48.0352 13.6412 49.1319C13.7139 49.1699 13.7866 49.2078 13.8593 49.2426C15.4491 50.0137 17.0926 50.3456 18.7455 50.3614C19.681 48.2375 20.6102 46.1136 21.53 43.9802C20.7714 43.6673 19.9181 43.4019 19.2417 42.9562C18.7866 42.6528 18.5306 42.1345 18.8024 41.613C19.4472 40.3741 21.4699 41.2148 22.4623 41.8058C23.0944 40.3235 23.7234 38.8412 24.346 37.3589C23.6191 37.0871 22.8037 36.8627 22.1558 36.4581C21.7006 36.1737 21.4225 35.6364 21.6532 35.1181C21.8934 34.5808 22.5192 34.4354 23.0502 34.5492C23.7803 34.7041 24.504 35.0422 25.2088 35.2856C25.2088 35.2856 25.212 35.2856 25.212 35.2887C25.6703 34.1857 26.1286 33.0827 26.5837 31.9765C25.8631 31.651 25.0571 31.3792 24.3871 30.9588C23.913 30.6617 23.5812 30.0707 23.8719 29.5271C24.1374 29.0277 24.7506 28.8918 25.2657 29.0056Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M22.6299 35.5416C22.6836 35.7218 23.4864 35.9146 23.676 35.9873C24.03 36.1263 24.3871 36.2654 24.7411 36.4044C24.7664 36.3476 24.7885 36.2907 24.8138 36.2338C24.4662 36.1137 24.1185 35.9936 23.7708 35.8735C23.6887 35.845 22.6362 35.5574 22.6299 35.5416Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M104.314 41.2937C103.069 39.6091 101.113 38.7242 99.1309 38.2343C96.7826 37.6528 94.3458 37.343 91.9533 37.0175C89.5007 36.6825 87.0386 36.4075 84.5734 36.1768C79.6398 35.7154 74.6903 35.4309 69.7409 35.1654C61.1063 34.7008 52.4401 34.3026 43.8687 33.1269C43.5242 33.0795 43.1829 33.0289 42.8384 32.9815C42.3706 32.9151 41.9029 32.8456 41.4351 32.7729C41.0938 32.7192 40.7524 32.6623 40.4111 32.6085C40.2025 32.5738 39.9907 32.5422 39.7821 32.5074C38.6412 32.3146 37.5034 32.1029 36.3719 31.8785C35.2309 30.7059 34.0205 29.6029 32.7373 28.582C31.8428 27.8709 30.9042 27.144 29.9244 26.4866C26.7133 34.4891 23.3694 42.4378 19.8928 50.3266C23.0628 50.0895 26.2139 48.7969 29.011 47.3114C31.827 45.8196 34.583 44.2141 37.3612 42.6559C37.5065 42.678 37.6519 42.697 37.8005 42.7191C38.145 42.7697 38.4863 42.8171 38.8308 42.8677C39.2986 42.9341 39.7663 43.0004 40.2373 43.0699C40.5818 43.1205 40.9231 43.1679 41.2676 43.2185C48.973 44.3184 56.6816 45.4214 64.387 46.5213C69.4628 47.2482 74.5386 47.9719 79.6176 48.6989C84.5576 49.4037 89.507 50.2792 94.469 50.7848C98.445 51.1894 103.195 50.4877 104.937 46.3316C105.642 44.6313 105.405 42.7665 104.314 41.2937ZM39.5767 37.4031C39.5356 38.1521 39.3966 39.0339 38.9351 39.6471C38.4199 40.3329 37.6361 40.4309 36.8302 40.3645C35.7872 40.2792 34.7474 40.1464 33.7139 39.9947C32.7373 39.8525 31.6785 39.7609 30.743 39.4322C28.8783 38.7748 29.1911 36.8658 29.8201 35.4025C29.8833 35.2539 29.9844 35.1718 30.0982 35.1401C30.6166 34.546 31.3751 34.4448 32.1747 34.5365C33.4105 34.6819 34.6462 34.8968 35.8757 35.0801C37.4086 35.3077 39.6936 35.2918 39.5767 37.4031ZM99.5196 45.5984C98.1163 47.5453 95.3256 46.6477 93.5304 45.9176C92.696 45.5794 91.7541 45.1938 91.574 44.2046C91.4539 43.5472 91.732 42.8677 92.2503 42.4663C93.4229 40.9587 96.0588 41.6066 97.6391 41.91C98.5082 42.0775 99.4058 42.4157 99.8198 43.2564C100.193 44.015 100 44.9315 99.5196 45.5984Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M36.0338 36.1453C35.1362 36.0125 34.2386 35.8798 33.3378 35.747C32.6804 35.6491 31.274 35.175 30.8252 35.8829C30.7809 35.9556 30.7272 36.0094 30.6672 36.0473C30.3574 36.79 29.9876 37.8709 30.8505 38.3576C31.511 38.7274 32.4624 38.7559 33.1924 38.8728C34.0711 39.015 34.9529 39.1351 35.8378 39.2331C36.5647 39.3153 37.7879 39.6566 38.2145 38.857C38.4705 38.3798 38.8087 37.084 38.3536 36.6478C37.8764 36.1927 36.6406 36.2338 36.0338 36.1453Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M24.9401 30.0075C24.6651 30.0454 25.174 30.2034 25.2846 30.254C25.4584 30.333 25.6323 30.412 25.8061 30.491C26.1948 30.668 26.5836 30.845 26.9723 31.022C27.0134 30.9209 27.0545 30.8198 27.0988 30.7186C26.7479 30.5954 26.394 30.4721 26.0431 30.3488C25.7492 30.2445 25.2593 29.9632 24.9401 30.0075Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M29.0425 25.9146C28.5273 27.1977 28.009 28.4809 27.4875 29.7641C26.7606 29.5049 26.0178 29.1667 25.2688 29.0056C24.7505 28.8918 24.1405 29.0277 23.8718 29.5271C23.5811 30.0707 23.9129 30.6617 24.387 30.9588C25.057 31.3791 25.863 31.6509 26.5836 31.9765C26.1285 33.0827 25.6733 34.1857 25.2119 35.2887C25.2119 35.2887 25.2087 35.2887 25.2087 35.2856C24.5071 35.0422 23.7802 34.704 23.0501 34.5492C22.5191 34.4385 21.8933 34.5808 21.6531 35.1181C21.4224 35.6364 21.7005 36.1737 22.1557 36.4581C22.8036 36.8627 23.619 37.0871 24.3459 37.3589C23.7233 38.8443 23.0943 40.3266 22.4622 41.8058C21.4698 41.2147 19.4471 40.374 18.8023 41.613C18.5305 42.1313 18.7865 42.6528 19.2416 42.9562C19.918 43.4018 20.7713 43.6705 21.5299 43.9802C20.6133 46.1104 19.6841 48.2375 18.7454 50.3614C19.1279 50.3645 19.5103 50.3519 19.8927 50.3234C23.3693 42.4316 26.7132 34.4859 29.9243 26.4834C29.6335 26.2875 29.3396 26.0979 29.0425 25.9146ZM21.9344 43.0352C21.3023 42.7855 19.7252 42.1661 19.7473 42.0555C19.7726 41.9259 20.8504 42.204 20.961 42.2388C21.3497 42.362 21.7069 42.5485 22.0514 42.7634C22.0103 42.8551 21.9723 42.9467 21.9344 43.0352ZM24.7441 36.4076C24.3902 36.2685 24.033 36.1294 23.679 35.9904C23.4894 35.9145 22.6866 35.7249 22.6329 35.5447C22.6361 35.5605 23.6917 35.8482 23.777 35.8766C24.1247 35.9967 24.4723 36.1168 24.82 36.2369C24.7915 36.2938 24.7663 36.3507 24.7441 36.4076ZM26.9755 31.022C26.5867 30.845 26.198 30.668 25.8092 30.491C25.6354 30.412 25.4616 30.333 25.2878 30.254C25.1771 30.2034 24.6683 30.0454 24.9433 30.0075C25.2625 29.9632 25.7524 30.2445 26.0463 30.3488C26.3971 30.472 26.7511 30.5953 27.1019 30.7186C27.0577 30.8197 27.0166 30.9208 26.9755 31.022Z"
      fill="#35405F"
    />,
    <Path
      d="M35.8788 35.0802C34.6462 34.8969 33.4136 34.6819 32.1778 34.5366C31.3782 34.4417 30.6228 34.5461 30.1013 35.1402C29.9876 35.1718 29.8864 35.254 29.8232 35.4026C29.1974 36.8659 28.8845 38.7749 30.7461 39.4323C31.6816 39.761 32.7404 39.8526 33.717 39.9948C34.7505 40.1465 35.7935 40.2793 36.8333 40.3646C37.6392 40.431 38.423 40.333 38.9382 39.6472C39.3997 39.034 39.5356 38.1522 39.5798 37.4032C39.6936 35.2919 37.4085 35.3077 35.8788 35.0802ZM38.2144 38.857C37.7878 39.6567 36.5678 39.3122 35.8377 39.2332C34.9528 39.1352 34.071 39.0151 33.1923 38.8728C32.4591 38.7559 31.5109 38.7275 30.8504 38.3577C29.9844 37.8741 30.3542 36.79 30.6671 36.0473C30.7271 36.0094 30.7809 35.9557 30.8251 35.883C31.2739 35.175 32.6803 35.6491 33.3377 35.7471C34.2353 35.8798 35.1329 36.0125 36.0337 36.1453C36.6405 36.2338 37.8763 36.1927 38.3535 36.6478C38.8055 37.0808 38.4673 38.3798 38.2144 38.857Z"
      fill="#35405F"
    />,
    <Path
      d="M95.2941 68.7873C91.9155 68.3543 88.4642 70.6363 87.4875 73.879C87.4749 73.9232 87.4559 73.9611 87.437 73.9959C87.0862 75.1874 87.0419 76.4675 87.4022 77.7127C88.3472 80.9776 91.8017 83.4491 95.2214 82.9497C98.6095 82.4567 101.501 79.3277 101.463 75.8606C101.422 72.4725 98.6917 69.2235 95.2941 68.7873ZM96.6057 78.2279C95.1613 79.3752 92.8478 79.1792 91.6279 77.7854C90.3668 76.3474 90.5375 74.0654 91.8712 72.7443C91.9534 72.6622 92.0451 72.6179 92.1367 72.6021C92.1746 72.5168 92.2378 72.4409 92.3327 72.3777C93.9129 71.3727 96.1885 71.7298 97.2979 73.2785C98.3756 74.7829 98.0532 77.0774 96.6057 78.2279Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M87.9615 80.9018C86.947 79.6597 86.2738 78.1426 86.1347 76.5213C85.7965 72.5832 88.7011 68.7305 92.5095 67.8234C92.6517 67.7887 92.7782 67.814 92.8761 67.8708C94.1656 67.6022 95.5152 67.6464 96.811 68.0858C98.9065 68.8 100.629 70.393 101.609 72.3493C101.64 70.8386 101.675 69.3247 101.707 67.814C100.218 67.893 98.7263 68.13 97.2345 68.1869C96.4128 67.8456 95.5342 67.6306 94.6239 67.5706C93.6473 67.5074 92.6928 67.6275 91.7984 67.8993C91.7415 67.8708 91.6783 67.8487 91.6024 67.8424C84.3047 67.0775 77.0039 66.3127 69.7062 65.5479L69.1562 73.6641C74.4122 74.2804 79.6777 74.8272 84.9463 75.295C85.2687 75.3234 85.5911 75.3487 85.9135 75.3771C85.8502 76.0503 85.8629 76.733 85.9545 77.4094C85.1992 78.051 84.4406 78.6894 83.6758 79.3215C84.3711 80.4308 85.0949 81.5212 85.8408 82.599C86.5519 82.0301 87.2567 81.4675 87.9615 80.9018Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M65.9672 87.6748C65.4868 87.6906 65.0033 87.7001 64.5229 87.7064C64.5197 90.4055 64.5671 93.1078 64.6651 95.8038C65.5848 95.8101 66.5045 95.8101 67.4242 95.8006C67.184 93.0794 67.0734 90.3518 67.0861 87.6211C66.7131 87.6464 66.3402 87.6622 65.9672 87.6748Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M85.0411 83.2406C84.2952 82.1691 83.5746 81.0851 82.8793 79.9789C82.5633 80.238 82.2504 80.4941 81.9343 80.7501C82.6897 81.8057 83.4419 82.8613 84.1973 83.9138C84.4786 83.6925 84.7598 83.465 85.0411 83.2406Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M66.2328 65.1812C65.9736 67.8676 65.844 70.5636 65.8535 73.2627C66.6152 73.3575 67.3737 73.446 68.1354 73.5376L68.6854 65.4372C67.8668 65.3518 67.0514 65.2665 66.2328 65.1812Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M92.854 73.2691C92.8034 73.3007 92.7529 73.3196 92.7023 73.3354C92.677 73.3828 92.6486 73.4302 92.6012 73.4745C91.6467 74.4195 91.4886 76.0946 92.421 77.1218C93.2965 78.0889 94.9589 78.2311 95.9766 77.4094C97.0322 76.5623 97.2282 74.8146 96.3717 73.7589C95.5278 72.7223 93.9444 72.5737 92.854 73.2691Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M81.3496 89.3246C80.7302 88.7841 80.629 87.8897 80.8945 87.1438C81.1979 86.2905 81.947 85.7184 82.6265 85.1748C82.8825 84.9694 83.1385 84.7671 83.3914 84.5616C82.6391 83.506 81.8838 82.4536 81.1316 81.3979C80.2814 82.0806 79.428 82.757 78.5684 83.4239C77.3516 84.2646 76.0968 85.0484 74.7726 85.7184C73.344 86.439 71.8775 86.9479 70.3699 87.2829C69.624 87.3998 68.875 87.4883 68.1196 87.5515C68.1038 90.3012 68.2176 93.0477 68.4578 95.7879C71.9628 95.7279 75.471 95.5319 78.935 95.1084C80.3035 94.9409 81.6657 94.7133 82.9963 94.3625C83.0563 94.3562 83.1164 94.353 83.1764 94.3467C83.2871 94.3372 83.3756 94.2962 83.4483 94.2393C84.2542 94.0085 85.0475 93.7336 85.8218 93.3954C86.8079 92.9656 87.7403 92.4346 88.6252 91.8309C87.7656 90.5098 86.8648 89.2203 85.923 87.9592C84.7409 88.8916 82.8351 90.6204 81.3496 89.3246Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M104.662 67.9562C104.023 67.8361 103.382 67.7887 102.743 67.7855C102.686 70.4309 102.629 73.0794 102.573 75.7248C102.569 75.8386 102.538 75.9302 102.487 76.0029C102.484 76.4517 102.443 76.9005 102.358 77.3462C101.628 81.1768 97.8349 84.2678 93.9095 84.0307C92.9898 83.9738 92.0985 83.7494 91.2641 83.3923C91.2388 83.4302 91.2104 83.4681 91.1725 83.5061C90.4392 84.1856 89.6902 84.8493 88.9316 85.5004C89.8988 86.7393 90.8659 87.9751 91.8299 89.214C93.5176 87.5769 95.0031 85.7374 96.4727 83.9043C96.6908 83.8253 96.912 83.7431 97.1269 83.6578C97.4398 83.5345 97.7432 83.3986 98.0435 83.2532C101.198 84.9473 104.327 86.7172 107.538 88.2943C110.171 89.587 113.514 90.4814 116.078 88.5282C116.289 88.367 116.305 88.092 116.078 87.934C111.514 84.7071 106.947 81.4833 102.383 78.2564C102.576 77.5421 102.667 76.8026 102.661 76.0598C103.303 76.0756 103.947 76.1104 104.58 76.2021C104.665 73.465 104.75 70.728 104.832 67.991C104.775 67.9815 104.719 67.9657 104.662 67.9562Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M86.7256 87.324C87.6801 88.5977 88.5966 89.903 89.4689 91.2368C90.0252 90.8259 90.5593 90.3834 91.0777 89.9188C90.101 88.6672 89.1244 87.4156 88.1478 86.1641C87.6769 86.5591 87.206 86.9447 86.7256 87.324Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M118.236 84.1223C116.35 79.6502 113.84 75.4308 110.522 71.8689C109.169 70.4151 107.706 68.996 105.857 68.2943C105.774 71.0029 105.689 73.7115 105.607 76.4201C105.891 76.4991 106.17 76.5971 106.441 76.7172C107.813 77.324 109.058 78.2943 110.297 79.1318C112.829 80.848 115.307 82.6464 117.734 84.5111C117.958 84.6881 118.35 84.391 118.236 84.1223Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M96.8142 68.0826C95.5184 67.6433 94.1689 67.599 92.8794 67.8677C92.7782 67.8108 92.655 67.7855 92.5127 67.8203C88.7043 68.7305 85.8029 72.58 86.1379 76.5181C86.277 78.1426 86.947 79.6597 87.9647 80.8986C88.1828 81.1641 88.4135 81.4169 88.6632 81.6571C89.1594 82.1344 89.7093 82.5547 90.3035 82.9024C90.6133 83.0857 90.9356 83.25 91.2675 83.3923C92.1019 83.7526 92.9963 83.977 93.9129 84.0307C97.8383 84.2677 101.631 81.1767 102.361 77.3461C102.446 76.8973 102.487 76.4485 102.491 76.0029C102.541 75.9271 102.576 75.8354 102.576 75.7248C102.633 73.0762 102.69 70.4309 102.747 67.7855C102.402 67.7823 102.058 67.7918 101.713 67.8108C101.681 69.3215 101.647 70.8354 101.615 72.3462C100.632 70.3929 98.9097 68.7969 96.8142 68.0826ZM95.2213 82.9529C91.7985 83.4523 88.3471 80.9776 87.4021 77.7159C87.0418 76.4707 87.0861 75.1907 87.4369 73.9991C87.4559 73.9644 87.4748 73.9264 87.4875 73.8822C88.4641 70.6395 91.9186 68.3575 95.294 68.7905C98.6916 69.2267 101.422 72.4757 101.46 75.867C101.501 79.3278 98.6094 82.4599 95.2213 82.9529Z"
      fill="#35405F"
    />,
    <Path
      d="M92.3326 72.3777C92.2346 72.4378 92.1745 72.5168 92.1366 72.6021C92.045 72.6179 91.9533 72.6622 91.8711 72.7443C90.5374 74.0654 90.3699 76.3505 91.6278 77.7854C92.8477 79.176 95.1612 79.3752 96.6056 78.2279C98.0531 77.0774 98.3755 74.7829 97.2978 73.2785C96.1884 71.7298 93.9128 71.3695 92.3326 72.3777ZM95.9767 77.4093C94.959 78.2279 93.2997 78.0857 92.4211 77.1217C91.4887 76.0945 91.6467 74.4194 92.6012 73.4744C92.6455 73.4302 92.6771 73.3828 92.7024 73.3353C92.7529 73.3227 92.8035 73.3038 92.8541 73.269C93.9444 72.5737 95.531 72.7222 96.3717 73.7589C97.2282 74.8145 97.0291 76.5623 95.9767 77.4093Z"
      fill="#35405F"
    />,
    <Path
      d="M104.832 67.994C104.747 70.7311 104.662 73.4681 104.58 76.2051C104.927 76.2557 105.269 76.3252 105.607 76.4232C105.689 73.7146 105.774 71.006 105.856 68.2974C105.528 68.1678 105.186 68.0667 104.832 67.994Z"
      fill="#35405F"
    />,
    <Path
      d="M68.6852 65.4373L68.1353 73.5377C68.4766 73.5788 68.8179 73.6199 69.1593 73.661L69.7092 65.5447C69.3679 65.51 69.0265 65.472 68.6852 65.4373Z"
      fill="#35405F"
    />,
    <Path
      d="M67.0862 87.6242C67.0735 90.3549 67.1873 93.0825 67.4243 95.8037C67.7688 95.8005 68.1133 95.7942 68.4578 95.791C68.2176 93.0509 68.1039 90.3044 68.1197 87.5547C67.7752 87.58 67.4307 87.6052 67.0862 87.6242Z"
      fill="#35405F"
    />,
    <Path
      d="M63.4894 87.719C59.4471 87.7411 55.3921 87.5452 51.3561 87.4978C40.8568 87.3713 30.3543 87.4282 19.8581 87.6716C17.257 87.7316 14.6559 87.8043 12.0547 87.8865C10.1521 87.9465 7.51302 87.877 6.63755 89.9883C5.8569 91.8719 7.61732 93.4143 9.28609 93.8252C10.4144 94.1033 11.6154 94.1444 12.769 94.2582C14.0806 94.3877 15.3954 94.5079 16.707 94.6216C27.1242 95.5287 37.5825 95.9901 48.0407 95.9996C52.8669 96.0059 57.693 95.9143 62.516 95.7278C62.5792 95.7594 62.6551 95.7784 62.7436 95.7815C63.0406 95.7878 63.3377 95.791 63.638 95.7942C63.9825 95.7973 64.327 95.8037 64.6715 95.8037C64.5735 93.1046 64.5261 90.4055 64.5293 87.7064C64.1784 87.7127 63.8339 87.7159 63.4894 87.719Z"
      fill="#F67E6A"
    />,
    <Path
      d="M65.2087 65.0737C63.6537 64.9094 62.0987 64.7482 60.5437 64.5838C50.1898 63.4998 39.8358 62.4125 29.4787 61.3285C26.925 61.0598 24.3713 60.7944 21.8144 60.5257C20.1678 60.3519 17.7531 59.7324 16.3277 60.9366C14.8201 62.2071 15.8599 64.2773 17.3612 64.9884C18.3568 65.4593 19.5009 65.6047 20.5723 65.8259C21.8081 66.0819 23.047 66.3316 24.2859 66.5781C34.3302 68.5788 44.4313 70.3044 54.5671 71.7583C57.9805 72.2482 61.3971 72.7033 64.82 73.1331C65.1644 73.1774 65.5089 73.2216 65.8534 73.2627C65.844 70.5636 65.9735 67.8677 66.2327 65.1812C65.8914 65.1464 65.55 65.1085 65.2087 65.0737Z"
      fill="#F67E6A"
    />,
    <Path
      d="M84.1971 83.9169C83.4417 82.8613 82.6895 81.8056 81.9341 80.7532C81.6655 80.9681 81.4 81.183 81.1313 81.3979C81.8836 82.4535 82.6389 83.506 83.3911 84.5616C83.6598 84.3467 83.9284 84.1318 84.1971 83.9169Z"
      fill="#35405F"
    />,
    <Path
      d="M85.8469 82.5958C85.101 81.5213 84.3773 80.4277 83.6819 79.3184C83.4133 79.5396 83.1478 79.7608 82.8792 79.9789C83.5745 81.082 84.2951 82.1692 85.041 83.2406C85.3096 83.0257 85.5783 82.8108 85.8469 82.5958Z"
      fill="#35405F"
    />,
    <Path
      d="M86.0211 87.8834C85.9895 87.9086 85.9579 87.9339 85.9231 87.9592C86.8681 89.2203 87.7657 90.5129 88.6254 91.8309C88.6759 91.7961 88.7265 91.7645 88.7739 91.7298C89.0078 91.5717 89.2417 91.4042 89.4692 91.2367C88.5969 89.903 87.6804 88.5977 86.7259 87.324C86.492 87.5104 86.2581 87.7 86.0211 87.8834Z"
      fill="#35405F"
    />,
    <Path
      d="M88.1479 86.1671C89.1246 87.4187 90.1012 88.6703 91.0778 89.9219C91.1189 89.8839 91.1631 89.8492 91.2042 89.8113C91.4159 89.6185 91.6245 89.4193 91.83 89.2202C90.8629 87.9813 89.8957 86.7455 88.9318 85.5066C88.6726 85.7278 88.4103 85.9491 88.1479 86.1671Z"
      fill="#35405F"
    />,
    <Path
      d="M55.193 8.30706C51.9503 8.19012 48.7044 8.15536 45.4617 8.08898C44.5926 8.07002 43.7234 8.05422 42.8511 8.03526C42.4402 8.02578 42.0262 8.00365 41.6153 8.00997C41.5142 8.00997 41.2202 7.97838 41.1191 8.04159C40.901 8.17749 41.2013 8.12691 41.274 8.1522C41.6785 8.29442 42.358 8.21857 42.7847 8.24701L44.0205 8.32603C44.798 8.37343 45.5755 8.42401 46.3561 8.47142C49.6526 8.67685 52.949 8.88546 56.2455 9.09089C57.9395 9.19835 59.6336 9.30263 61.3276 9.41009C62.1525 9.46066 62.9743 9.5144 63.7992 9.56497C64.2101 9.59026 64.6241 9.61554 65.035 9.64399C65.3162 9.66295 65.7935 9.7388 66.1348 9.69771C66.0874 9.69771 66.0337 9.69455 65.98 9.68191C62.456 8.74323 58.8245 8.43665 55.193 8.30706Z"
      fill="#F67E6A"
    />,
    <Path
      d="M65.424 12.9185C65.0163 12.7826 64.34 12.8616 63.9133 12.8395L62.6775 12.7731C61.9 12.732 61.1194 12.6909 60.3419 12.6498C57.0454 12.476 53.7458 12.3022 50.4494 12.1283C48.7553 12.0398 47.0613 11.9513 45.3641 11.8597C44.5392 11.8154 43.7143 11.7744 42.8893 11.7301C42.4785 11.708 42.0644 11.6859 41.6536 11.6637C41.3723 11.6479 40.895 11.5784 40.5537 11.6195C40.6011 11.6195 40.6548 11.6195 40.7086 11.6353C44.2421 12.5329 47.8767 12.8015 51.5082 12.8964C54.7509 12.9817 57.9968 12.9849 61.2395 13.0196C62.1086 13.0291 62.9809 13.0386 63.8501 13.0481C64.261 13.0512 64.675 13.0702 65.0859 13.0607C65.187 13.0575 65.4809 13.086 65.5789 13.0259C65.797 12.8932 65.4967 12.9438 65.424 12.9185Z"
      fill="#F67E6A"
    />,
    <Path
      d="M70.5631 7.92782C70.5536 7.92466 70.5473 7.92151 70.5378 7.91835C70.5062 7.89307 70.4746 7.86778 70.4304 7.85197C68.1927 6.98282 65.8096 6.67943 63.4329 6.46451C60.7844 6.22431 58.1295 6.03151 55.4778 5.864C52.8546 5.69649 50.2313 5.55742 47.6081 5.42784C46.2964 5.36463 44.9848 5.30141 43.67 5.24136C42.5133 5.18763 41.3091 5.03594 40.1555 5.18133C38.0569 5.44681 37.33 7.15351 36.9191 9.01191C36.5525 10.6712 36.3312 12.7445 37.7914 13.9581C39.3085 15.2192 41.7927 14.9474 43.6194 15.0169C46.2585 15.1181 48.9007 15.2192 51.5398 15.3203C57.548 15.5511 63.5593 15.7818 69.5675 16.0157C69.7951 16.0251 69.9057 15.845 69.8994 15.668C69.95 15.6143 69.9816 15.5448 69.9879 15.4531C70.1301 13.108 70.3861 10.7755 70.759 8.45565C70.7622 8.43669 70.7622 8.41772 70.7622 8.39876C70.8096 8.2755 70.8128 8.1554 70.7243 8.04162C70.68 7.98157 70.6263 7.94679 70.5631 7.92782ZM66.6061 13.4809C66.2205 14.1099 65.4588 14.1004 64.8109 14.0972C60.6422 14.0656 56.4671 14.0403 52.2983 13.955C48.3508 13.876 44.3875 13.6263 40.5442 12.6655C40.0259 12.7414 39.5107 12.4379 39.4633 11.8343C39.4064 11.0726 40.1713 10.6459 40.8255 10.608C41.9475 10.5416 43.139 10.7154 44.2642 10.7723L47.8356 10.962C51.957 11.18 56.0815 11.395 60.2028 11.613C61.3027 11.6699 62.4026 11.73 63.4993 11.7869C64.3052 11.8279 65.345 11.7015 66.1067 12.0144C66.6977 12.2578 66.9474 12.9215 66.6061 13.4809ZM65.8539 10.7091C64.7287 10.766 63.5404 10.5795 62.4184 10.51L58.847 10.2856C54.7256 10.0264 50.6074 9.77042 46.4861 9.51126C45.3862 9.44173 44.2895 9.37221 43.1896 9.30584C42.3837 9.25527 41.3439 9.37537 40.5853 9.04983C39.9975 8.80015 39.7541 8.13326 40.1018 7.57701C40.4937 6.95122 41.2554 6.96702 41.9033 6.97966C46.072 7.05235 50.2439 7.11873 54.4127 7.24515C58.3602 7.36526 62.3204 7.65287 66.1541 8.6516C66.6756 8.57891 67.1876 8.88863 67.2256 9.4923C67.2761 10.2571 66.5081 10.6775 65.8539 10.7091Z"
      fill="#F67E6A"
    />,
    <Path
      d="M103.78 13.2281C103.332 13.1807 102.88 13.1333 102.431 13.0859C98.8753 12.7129 95.3133 12.3748 91.7482 12.0682C87.7502 11.7237 83.7489 11.4235 79.7445 11.1611C79.6623 11.8248 79.5738 12.4886 79.498 13.1491C79.4664 13.4336 79.4442 13.7275 79.4221 14.0246C79.4474 14.0277 79.4695 14.0341 79.4948 14.0341C83.0283 14.3027 86.565 14.5682 90.0984 14.8368C93.142 15.0676 96.2046 15.3583 99.264 15.3678C100.408 15.2098 101.552 15.0454 102.696 14.9411C103.303 14.8874 103.91 14.8274 104.514 14.7641C104.523 14.3944 104.533 14.0246 104.536 13.6548C104.536 13.5505 104.539 13.4462 104.545 13.3419C104.321 13.2439 104.021 13.2534 103.78 13.2281Z"
      fill="#CAD2FA"
    />,
    <Path
      d="M71.315 9.4387C71.2897 9.4387 71.2676 9.44503 71.2454 9.44819C71.198 10.0929 71.138 10.7377 71.1032 11.3824C71.0432 12.4728 70.9547 13.5727 70.9167 14.6694C70.9199 14.6726 70.9262 14.6757 70.9294 14.6789C71.1791 14.818 71.4793 14.8053 71.7575 14.8211C72.3074 14.8527 72.8573 14.8717 73.4073 14.8875C73.6032 14.8938 73.7992 14.897 73.9951 14.9001C74.5451 14.9475 75.0981 14.976 75.6481 14.9886C75.7176 13.1839 75.8851 11.3824 76.1538 9.59673C74.5514 9.41658 72.9363 9.35969 71.315 9.4387Z"
      fill="#F67E6A"
    />,
    <Path
      d="M79.7444 11.158C79.8139 10.5765 79.8803 9.99491 79.9182 9.41337C79.9656 8.70225 79.8234 7.86156 79.0364 7.62768C78.5908 7.49493 78.1673 7.634 77.8828 7.92477C77.8797 7.92477 77.8765 7.92791 77.8702 7.92791C77.8354 7.95952 77.8007 7.99745 77.7691 8.03222C77.6964 8.15232 77.63 8.27557 77.5731 8.402C77.4593 8.84447 77.355 9.28696 77.2602 9.73576C77.2349 9.73259 77.2096 9.72942 77.1844 9.72626C76.8904 11.6858 76.7198 13.658 76.6692 15.6365C76.6882 16.3192 76.8019 17.103 77.5225 17.3685C78.2431 17.634 78.8721 17.0461 79.0807 16.4014C79.3177 15.6649 79.3715 14.8274 79.4283 14.0215C79.4505 13.7244 79.4726 13.4304 79.5042 13.146C79.5737 12.4854 79.6622 11.8217 79.7444 11.158Z"
      fill="#F67E6A"
    />,
    <Path
      d="M76.1602 9.59351C75.8916 11.3824 75.7209 13.1807 75.6545 14.9854C75.9548 14.9917 76.255 14.9949 76.5585 14.9886C76.5995 14.9886 76.6343 14.9791 76.6691 14.9696C76.6659 15.0929 76.6659 15.2193 76.6659 15.3425C76.6659 15.4342 76.6659 15.5322 76.6691 15.6333C76.7165 13.6548 76.8872 11.6795 77.1842 9.7231C76.8397 9.67569 76.4984 9.63143 76.1602 9.59351Z"
      fill="#35405F"
    />,
    <Path
      d="M66.1066 12.0112C65.3449 11.6952 64.3051 11.8247 63.4991 11.7837C62.3993 11.7268 61.2994 11.6667 60.2027 11.6098C56.0813 11.3917 51.9568 11.1768 47.8355 10.9588L44.2641 10.7691C43.1389 10.7091 41.9505 10.5352 40.8254 10.6048C40.1712 10.6427 39.4063 11.0694 39.4632 11.8311C39.5074 12.4347 40.0226 12.7381 40.5441 12.6623C44.3873 13.6231 48.3507 13.8728 52.2982 13.9518C56.4669 14.0371 60.642 14.0624 64.8108 14.094C65.4618 14.1003 66.2204 14.1098 66.606 13.4777C66.9473 12.9215 66.6976 12.2577 66.1066 12.0112ZM65.5788 13.0289C65.4808 13.0921 65.1837 13.0605 65.0857 13.0637C64.6749 13.0731 64.2608 13.0542 63.85 13.051C62.9808 13.0416 62.1085 13.0321 61.2394 13.0226C57.9966 12.9878 54.7508 12.9847 51.508 12.8993C47.8734 12.8045 44.2419 12.5327 40.7085 11.6383C40.6547 11.6256 40.6042 11.6225 40.5536 11.6225C40.8949 11.5782 41.3722 11.6509 41.6535 11.6667C42.0643 11.6888 42.4784 11.711 42.8892 11.7331C43.7141 11.7773 44.539 11.8184 45.3639 11.8627C47.058 11.9512 48.752 12.0397 50.4493 12.1313C53.7457 12.3052 57.0453 12.479 60.3418 12.6528C61.1193 12.6939 61.8999 12.735 62.6774 12.7761L63.9132 12.8424C64.3399 12.8646 65.0194 12.7856 65.4239 12.9215C65.4966 12.9436 65.7969 12.893 65.5788 13.0289Z"
      fill="#35405F"
    />,
    <Path
      d="M66.1539 8.6548C62.3201 7.65607 58.3599 7.36529 54.4124 7.24835C50.2437 7.12193 46.0717 7.05555 41.903 6.98286C41.2519 6.97022 40.4934 6.95442 40.1015 7.58021C39.7538 8.1333 39.9972 8.80019 40.585 9.05303C41.3436 9.37541 42.3834 9.25847 43.1893 9.30903C44.2892 9.37857 45.3859 9.44809 46.4858 9.51446C50.6071 9.77362 54.7253 10.0296 58.8467 10.2888L62.4181 10.5132C63.5401 10.5827 64.7316 10.7692 65.8536 10.7123C66.5078 10.6807 67.2758 10.2604 67.2284 9.49866C67.1874 8.89184 66.6753 8.58211 66.1539 8.6548ZM65.035 9.63772C64.6241 9.61244 64.2101 9.58716 63.7992 9.55871C62.9743 9.50814 62.1526 9.45442 61.3277 9.40385C59.6336 9.29639 57.9396 9.19209 56.2455 9.08463C52.9491 8.87919 49.6526 8.67059 46.3562 8.46516C45.5787 8.41775 44.8012 8.36719 44.0206 8.31978L42.7848 8.24077C42.3581 8.21548 41.6786 8.28816 41.274 8.14594C41.2014 8.12065 40.9011 8.16807 41.1192 8.03533C41.2171 7.97528 41.5142 8.00689 41.6154 8.00373C42.0262 7.99741 42.4403 8.01952 42.8512 8.029C43.7203 8.04796 44.5895 8.06378 45.4618 8.08274C48.7045 8.14911 51.9504 8.18388 55.1931 8.30082C58.8245 8.4304 62.456 8.74012 65.98 9.66932C66.0337 9.68512 66.0843 9.6883 66.1349 9.68514C65.7936 9.73255 65.3163 9.65669 65.035 9.63772Z"
      fill="#35405F"
    />,
    <Path
      d="M23.9638 10.7661C21.9916 11.2813 19.7856 11.0252 17.864 10.1624C18.5119 9.05623 19.0049 7.84575 19.2894 6.59417C19.6212 5.12452 19.7792 3.43678 19.0902 2.04614C17.7849 -0.583433 14.065 -0.671916 12.5068 1.74274C10.6737 4.58407 13.2938 8.28822 15.5599 9.98543C15.8254 10.1845 16.1004 10.371 16.3848 10.5417C14.8172 12.7225 12.4531 14.5366 9.85197 15.2952C10.0226 14.8843 10.1775 14.4703 10.3197 14.0499C11.1004 11.7301 12.058 8.06699 10.0321 6.08849C7.8229 3.933 4.04605 5.72185 2.29511 7.61186C0.168062 9.90957 0.152255 13.3767 2.71546 15.3394C4.242 16.5088 6.17942 16.8249 8.0789 16.6352C7.25084 18.1681 6.20786 19.584 4.98789 20.8356C5.19017 20.902 5.39244 20.9715 5.59471 21.0379C5.76222 21.0821 5.92657 21.1295 6.09092 21.1769C7.39623 19.7642 8.48978 18.1649 9.3368 16.4393C10.0985 16.2749 10.8381 16.0379 11.5239 15.7503C13.8469 14.7768 15.8633 13.1144 17.3014 11.0474C19.321 12.0177 21.6376 12.3369 23.8374 11.8628C23.8911 11.4804 23.967 11.1074 24.0302 10.7598C24.0112 10.7566 23.9891 10.7598 23.9638 10.7661ZM9.25147 13.9835C9.06184 14.524 8.84692 15.0518 8.60988 15.5701C7.54793 15.7345 6.46071 15.7123 5.38928 15.4595C2.608 14.8053 0.80649 12.0872 2.27298 9.38494C2.88929 8.24714 3.93859 7.40958 5.07323 6.82488C6.18258 6.25283 7.69964 5.81983 8.86272 6.48987C11.2426 7.86154 9.94047 12.0082 9.25147 13.9835ZM17.2666 9.11628C17.1655 9.30907 17.0549 9.50187 16.9411 9.6915C15.0732 8.60427 13.5972 6.90073 13.082 4.76737C12.7692 3.46838 13.1137 2.11883 14.2989 1.39191C15.4114 0.709228 16.8684 0.949431 17.747 1.90708C18.7015 2.9469 18.6225 4.5493 18.3886 5.84512C18.1863 6.98292 17.8039 8.0891 17.2666 9.11628Z"
      fill="#35405F"
    />,
  ],
});
