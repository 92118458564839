import { defaultTo } from 'lodash';
import { useState } from 'react';
import { Layout } from '../../../../../components/core';
import { useSkillContext } from '../../../../../contexts';
import { getStylesheet } from '../../../../../styles';
import { VideoSlideSlice } from '../../../SkillTypes';
import { useSlideEvents } from '../../hooks/useSlideEvents';
import { SkillWrapper } from '../../SkillWrapper';
import { VideoSlideVimeo } from './Vimeo';
import type { SliceContext } from '../../skillTypes';

type VideoSlideProps = {
  context: SliceContext;
  slice: VideoSlideSlice;
};

export const VideoSlide = ({ context, slice }: VideoSlideProps): JSX.Element => {
  const [isPaused, setIsPaused] = useState(true);
  const { volume, videoTextTrack, setVideoTextTrack, setVolume } = useSkillContext();

  useSlideEvents(context.slideIndex, {
    onHide: () => {
      setIsPaused(true);
    },
  });

  const mainItem = slice.primary;
  const videoId = defaultTo(mainItem.video_id, '');
  const warningLabel = defaultTo(mainItem.warning_label, 'Sensitive Content');
  const warningMessage = defaultTo(mainItem.warning_text, '');

  const onVideoPlaying = (isPlaying: boolean): void => {
    if (isPlaying) {
      setIsPaused(false);
    }
  };

  return (
    <SkillWrapper isVisible={context.isVisible} video>
      {videoId && (
        <Layout.Flex flex={1} {...styles.videoWrapper}>
          <VideoSlideVimeo
            slideIndex={context.slideIndex}
            warningLabel={warningLabel}
            warningMessage={warningMessage}
            videoId={videoId}
            isPaused={isPaused}
            isVisible={context.isVisible}
            textTrack={videoTextTrack}
            volume={volume}
            onPlayingChange={onVideoPlaying}
            onTextTrackChange={setVideoTextTrack}
            onVolumeChange={setVolume}
          />
        </Layout.Flex>
      )}
    </SkillWrapper>
  );
};

export default VideoSlide;

const styles = getStylesheet({
  videoWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
