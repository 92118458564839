import { useToast } from 'native-base';
import { Button, Heading, Layout, SimpleModal, Text } from '../../components/core';
import { IconTrash2 } from '../../components/icons/IconTrash2';
import { useSkillReflectionDeleteMutation } from '../../graphQL';
import { getStylesheet } from '../../styles';

type DeleteReflectionModalProps = {
  reflectionId: string;
  onClose: (wasUpdated: boolean) => void;
};

export const ReflectionDeleteModal = ({
  reflectionId,
  onClose,
}: DeleteReflectionModalProps): JSX.Element | null => {
  const toast = useToast();
  const [reflectionDelete] = useSkillReflectionDeleteMutation({
    onError: () => {
      toast.show({
        description: 'Reflection could not be deleted.',
        duration: 3000,
      });
    },
    onCompleted: ({ skillReflectionDelete }) => {
      toast.show({
        description: skillReflectionDelete
          ? 'Reflection deleted'
          : 'Reflection could not be deleted.',
        duration: 3000,
      });
    },
  });

  const onDeletePress = async (): Promise<void> => {
    const response = await reflectionDelete({
      variables: {
        reflectionId: Number(reflectionId),
      },
    });

    onClose(response.data?.skillReflectionDelete ?? false);
  };
  const onClosePress = (): void => {
    onClose(false);
  };

  const headerId = 'reflection-delete-modal-header';

  return (
    <SimpleModal headerId={headerId} size="md" onClose={onClosePress}>
      <Layout.VStack {...styles.topStack} space={6}>
        <Layout.VStack space={2}>
          <Heading.h4 id={headerId}>Are you sure?</Heading.h4>

          <Text.para>Once deleted, this entry will not be recoverable.</Text.para>
        </Layout.VStack>

        <Layout.HStack justifyContent="flex-start" space={3}>
          <Button.secondaryMedium
            testID="button-skill-reflection-delete-cancel"
            onPress={onClosePress}
          >
            Cancel
          </Button.secondaryMedium>

          <Button.dangerMedium
            leftIcon={<IconTrash2 accessibilityHidden />}
            testID="button-skill-reflection-delete-confirm"
            onPress={onDeletePress}
          >
            Delete
          </Button.dangerMedium>
        </Layout.HStack>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  topStack: {
    alignSelf: 'center',
    maxWidth: '408px',
    paddingTop: 8,
    width: '100%',
  },
});
