import { createIcon, Path } from './index';

export const IconArrowLeft = createIcon({
  title: 'An arrow pointing left',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M19 12H5"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M12 19L5 12L12 5"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
