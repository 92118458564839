import React from 'react';
import { PageHeader, PageTopBar } from '../../components/page';
import { getRoute, useNavigate } from '../../routes';

export const ReflectionsHeader = (): JSX.Element => {
  const navigate = useNavigate();

  const onBackPressed = (): void => {
    navigate(getRoute('selfCareHome', {}));
  };

  return (
    <>
      <PageTopBar
        backAccessibilityLabel="Back to Self Care"
        backTitle="Self Care"
        onBackPress={onBackPressed}
        testID="reflections-top-bar"
      />

      <PageHeader title="Reflections" testID="reflections-header" />
    </>
  );
};
