import { Button, Heading, Layout, Link, SimpleModal, Text } from '../../../components/core';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';

export const BrowserIncompatibilityWarning = ({
  crisisPhoneNumber,
  onContinue,
  onClose,
}: {
  crisisPhoneNumber: string;
  onContinue: () => void;
  onClose: () => void;
}): JSX.Element => {
  const headerId = 'video-browser-incompatibility-modal-header';

  return (
    <SimpleModal headerId={headerId} onClose={onClose} padding={2}>
      <Layout.VStack space={12}>
        <Layout.VStack space={4}>
          <IconAlertTriangle aria-label="Alert" size={6} />

          <Heading.h4 id="headerId">Browser incompatibility</Heading.h4>

          <Text.para>
            Some users have issues accessing video support on Android devices due to browser
            restrictions.
          </Text.para>

          <Text.para bold>For the best audio and video experience, we recommend:</Text.para>

          <Text.para>
            {` \u2022`} Using the{' '}
            <Link.para bold to="https://play.google.com/store/apps/details?id=org.mozilla.firefox">
              Firefox
            </Link.para>{' '}
            browser on your mobile device; or {`\n \u2022`} Accessing support via desktop
          </Text.para>

          <Text.para bold>
            If you're unable to connect by video, you can access 24/7 ConnectNow by calling{' '}
            <Link.para bold to={`tel:${crisisPhoneNumber}`}>
              {crisisPhoneNumber}
            </Link.para>
          </Text.para>
        </Layout.VStack>

        <Layout.VStack space={3}>
          <Button.primaryLarge testID="button-connect-now-view-other-options" onPress={onClose}>
            View other support options
          </Button.primaryLarge>

          <Button.secondaryLarge testID="button-connect-now-dismiss-warning" onPress={onContinue}>
            Continue anyway
          </Button.secondaryLarge>
        </Layout.VStack>
      </Layout.VStack>
    </SimpleModal>
  );
};
