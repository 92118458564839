import { JSX } from 'react';
import { Control, FieldErrors, FieldPath, FieldValues } from 'react-hook-form';
import { Layout, Link } from '../../../components/core';
import { FormCheckbox } from '../../../components/form';
import {
  LINK_TO_HEALTH_TERMS,
  LINK_TO_PRIVACY_POLICY,
  LINK_TO_TELEHEALTH_CONSENT,
} from '../../../constants/links';
import { getStylesheet } from '../../../styles';

type FormTermsProps<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
};

export const FormTerms = <TFieldValues extends FieldValues>({
  name,
  control,
  errors,
}: FormTermsProps<TFieldValues>): JSX.Element => {
  return (
    <Layout.View {...styles.checkBox}>
      <FormCheckbox
        accessibilityLabel="Agree to terms"
        name={name}
        label={
          <>
            I agree to the{' '}
            <Link.caption to={LINK_TO_HEALTH_TERMS}>
              Mantra Health Terms &amp; Conditions
            </Link.caption>
            , <Link.caption to={LINK_TO_TELEHEALTH_CONSENT}>Telehealth Consent</Link.caption>,{' '}
            <Link.caption to={LINK_TO_PRIVACY_POLICY}>Privacy Policy</Link.caption>, and receiving
            periodic emails and text messages from Mantra Health. I understand that I can opt out at
            any time.
          </>
        }
        control={control}
        error={errors[name]}
        isRequired
      />
    </Layout.View>
  );
};

const styles = getStylesheet({
  checkBox: {
    width: '100%',
    maxWidth: 320,

    _web: {
      width: '100%',
    },
  },
});
