import { useToast } from 'native-base';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useNavigateFromHubToPatientPortalLazyQuery, useTherapyContextUserQuery } from '../graphQL';
import { getRoute, useExternalNavigate, useNavigate } from '../routes';

type NavigateToPatientPortalProps = {
  mrn?: string;
  onCompleted?: () => void;
  onError?: () => void;
};

type TherapyContextType = {
  hasLoaded: boolean;
  orgName: string;
  navigateToPatientPortal: (props?: NavigateToPatientPortalProps) => void;
  navigateToTherapy: () => Promise<void>;
  orgCanAccessTherapy: boolean;
  orgHasSelfReferrals: boolean;
};

const TherapyContext = createContext<TherapyContextType>({
  hasLoaded: false,
  orgName: '',
  navigateToPatientPortal: () => undefined,
  navigateToTherapy: async () => undefined,
  orgCanAccessTherapy: false,
  orgHasSelfReferrals: false,
});

export const TherapyConsumer = TherapyContext.Consumer;

export const TherapyProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const navigate = useNavigate();
  const externalNavigate = useExternalNavigate();
  const toast = useToast();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [orgName, setOrgName] = useState<string>('');
  const [canRedirect, setCanRedirect] = useState(false);
  const [orgCanAccessTherapy, setOrgCanAccessTherapy] = useState(false);
  const [orgHasSelfReferrals, setOrgHasSelfReferrals] = useState(false);

  const [queryForPortalLoginUrl] = useNavigateFromHubToPatientPortalLazyQuery();

  useTherapyContextUserQuery({
    onCompleted: ({ onboardedHubUser }) => {
      setOrgName(onboardedHubUser?.organization?.name ?? '');
      setCanRedirect(onboardedHubUser?.canRedirect ?? false);
      setOrgCanAccessTherapy(onboardedHubUser?.organizationCanAccessTherapy ?? false);
      setOrgHasSelfReferrals(onboardedHubUser?.organizationHasSelfReferrals ?? false);
      setHasLoaded(true);
    },
  });

  const navigateToTherapy = async (): Promise<void> => {
    if (canRedirect) {
      navigateToPatientPortal();
      return;
    }

    navigate(getRoute('therapy', {}));
  };

  const navigateToPatientPortal = ({
    mrn,
    onCompleted,
    onError,
  }: NavigateToPatientPortalProps = {}): void => {
    if (!orgCanAccessTherapy) {
      // This org can't access therapy, so send them home.
      navigate(getRoute('home', {}));
      return;
    }

    const onLoginError = (): void => {
      toast.show({
        description: 'Could not log you into the patient portal.',
      });

      onError?.();
    };

    void queryForPortalLoginUrl({
      variables: { mrn },
      onError: onLoginError,
      onCompleted: ({ navigateFromHubToPatientPortal2 }) => {
        const responseType = navigateFromHubToPatientPortal2.__typename;

        if (responseType === 'NavigateFromHubToPatientPortalError') {
          const { errorCode } = navigateFromHubToPatientPortal2;

          if (errorCode === 'VerifyEligibility') {
            navigate(getRoute('therapyEligibility', {}));
            return;
          }

          onLoginError();
          return;
        }

        if (responseType === 'NavigateFromHubToPatientPortalSuccess') {
          const { loginUrl } = navigateFromHubToPatientPortal2;

          if (loginUrl === undefined || loginUrl === '') {
            onLoginError();
            return;
          }

          externalNavigate(loginUrl);
          onCompleted?.();
        }
      },
    });
  };

  const providerValue: TherapyContextType = {
    hasLoaded,
    orgName, // Temporary until therapy copy can come from the api.
    navigateToPatientPortal,
    navigateToTherapy,
    orgCanAccessTherapy,
    orgHasSelfReferrals,
  };

  return <TherapyContext.Provider value={providerValue}>{children}</TherapyContext.Provider>;
};

export const useTherapyContext = (): TherapyContextType => {
  return useContext(TherapyContext);
};
