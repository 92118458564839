import { Text, ITextProps } from 'native-base';
import React from 'react';

export { Text } from 'native-base';
export type TextProps = ITextProps & {
  center?: boolean;
};

const factory =
  (textStyle: ITextProps): React.FC<TextProps> =>
  ({ center, children, ...props }) => {
    const textAlign = center === true ? 'center' : undefined;

    return (
      <Text {...textStyle} textAlign={textAlign} {...props}>
        {children}
      </Text>
    );
  };

// BODY
export const paraSmall = factory({
  variant: 'paragraphSmall',
});
export const para = factory({
  variant: 'paragraph',
});
export const paraLarge = factory({
  variant: 'paragraphLarge',
});

// CAPTION
export const caption = factory({
  variant: 'caption',
});

// LABEL
export const label = factory({
  variant: 'label',
});
