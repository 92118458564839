import { useState } from 'react';
import { Button, Heading, Layout, Text } from '../../components/core';
import { PageContent, PageError, PageLoading, PageTopBar } from '../../components/page';
import { useHubAppointmentByIdQuery } from '../../graphQL';
import { getRoute, RouteParams, useLocation, useNavigate, useParams } from '../../routes';
import { getStylesheet } from '../../styles';
import { RescheduleRedirectNoticeModal } from './components/RescheduleRedirectNoticeModal';

type RescheduleParams = RouteParams<'reschedule'>;

export const RescheduleAppointmentPage = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPage = location.state?.from ?? 'home';
  const [reschedulePressed, setReschedulePressed] = useState(false);
  const [showRescheduleRedirectNoticeModal, setShowRescheduleRedirectNoticeModal] = useState(false);
  const params = useParams<RescheduleParams>();

  const { data: hubAppointmentData, loading: hubAppointmentLoading } = useHubAppointmentByIdQuery({
    variables: { appointmentId: params.appointmentId ?? '' },
  });
  const upcomingAppointment = hubAppointmentData?.hubAppointmentById;
  const rescheduleAppointmentLink = hubAppointmentData?.hubAppointmentById.rescheduleLink;

  if (hubAppointmentLoading) {
    return <PageLoading pageName="reschedule-appointment" />;
  }

  if (upcomingAppointment === undefined || rescheduleAppointmentLink == null) {
    return <PageError goBackTo={getRoute(previousPage, {})} />;
  }

  const onBackPress = (): void => {
    navigate(getRoute(previousPage, {}));
  };

  const onRescheduledBackPress = (): void => {
    navigate(getRoute(previousPage, {}), { state: { rescheduled: true } });
  };

  const onReschedulePressed = (): void => {
    setShowRescheduleRedirectNoticeModal(true);
  };

  const rescheduleWidgetReschedulePressed = (
    <Layout.VStack {...styles.rescheduleWidget} space={4}>
      <Text.para>
        When you have finished rescheduling, please return to the previous page to see your new
        appointment details.
      </Text.para>

      <Button.primaryLarge testID="button-to-previous-page" onPress={onRescheduledBackPress}>
        Take me back
      </Button.primaryLarge>

      <Button.secondaryLarge
        testID="button-to-reschedule-appointment"
        onPress={onReschedulePressed}
      >
        Reschedule Again
      </Button.secondaryLarge>
    </Layout.VStack>
  );

  const rescheduleWidget = (
    <Layout.VStack {...styles.rescheduleWidget} space={4}>
      <Heading.h3>Reschedule Appointment</Heading.h3>

      <Text.para>
        You will be redirected to a third-party platform to schedule a new appointment
      </Text.para>

      <Button.primaryLarge testID="button-to-reschedule-appointment" onPress={onReschedulePressed}>
        Reschedule
      </Button.primaryLarge>
    </Layout.VStack>
  );

  return (
    <PageContent testID="reschedule-appointment-page" pageSize="medium">
      <PageTopBar
        backAccessibilityLabel="Back"
        backTitle="Back"
        onBackPress={reschedulePressed ? onRescheduledBackPress : onBackPress}
      />

      <Layout.View {...styles.content}>
        {reschedulePressed ? rescheduleWidgetReschedulePressed : rescheduleWidget}
      </Layout.View>

      {showRescheduleRedirectNoticeModal && (
        <RescheduleRedirectNoticeModal
          onClose={() => setShowRescheduleRedirectNoticeModal(false)}
          onConfirm={() => {
            setShowRescheduleRedirectNoticeModal(false);
            setReschedulePressed(true);
          }}
          rescheduleAppointmentLink={rescheduleAppointmentLink}
          upcomingAppointment={upcomingAppointment}
        />
      )}
    </PageContent>
  );
};

const styles = getStylesheet({
  content: {
    paddingTop: 10,
  },

  rescheduleWidget: {
    backgroundColor: 'white',
    borderRadius: 8,
    justifyContent: 'space-between',
    padding: 6,
  },
});
