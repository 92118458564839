import { Box, Flex, IFlexProps } from 'native-base';
import { ReactNode } from 'react';
import { useBreakpointSwitch } from '../../contexts/breakpointContext';
import type { IHStackProps, IVStackProps } from '../../types/nativeBaseTypes';

export const View = Box;

export { Box, Center, Flex, HStack, Stack, VStack } from 'native-base';

export type { IHStackProps, IVStackProps };

export { LayoutSlide as Slide } from './layouts/LayoutSlide';
export { LayoutScrollView as ScrollView } from './layouts/LayoutScrollView';

type ShiftProps = IFlexProps & {
  alignMobile?: IFlexProps['align'];
  alignNonMobile?: IFlexProps['align'];
  children: ReactNode;
  flip?: boolean;
  justifyMobile?: IFlexProps['justify'];
  justifyNonMobile?: IFlexProps['justify'];
  reverseMobile?: boolean;
  reverseNonMobile?: boolean;
};

const flipDirection = (shouldFlip: boolean, direction: 'column' | 'row'): 'column' | 'row' => {
  if (!shouldFlip) {
    return direction;
  }

  return direction === 'row' ? 'column' : 'row';
};

const reverseDirection = (
  shouldReverse: boolean,
  direction: 'column' | 'row',
): 'column' | 'row' | 'column-reverse' | 'row-reverse' => {
  if (!shouldReverse) {
    return direction;
  }

  return direction === 'row' ? 'row-reverse' : 'column-reverse';
};

export const Shift = ({
  alignMobile,
  alignNonMobile,
  children,
  flip = false,
  justifyMobile,
  justifyNonMobile,
  reverseMobile = false,
  reverseNonMobile = false,
  ...flexProps
}: ShiftProps): JSX.Element => {
  const [flexDirection, align, justify, reverse] = useBreakpointSwitch({
    mobile: ['column', alignMobile, justifyMobile, reverseMobile] as const,
    tablet: ['row', alignNonMobile, justifyNonMobile, reverseNonMobile] as const, // tablet+
  });

  const finalDirection = reverseDirection(reverse, flipDirection(flip, flexDirection));

  return (
    <Flex align={align} justify={justify} {...flexProps} direction={finalDirection}>
      {children}
    </Flex>
  );
};
