import * as Application from 'expo-application';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { useRegisterDeviceMutation } from '../../graphQL';
import {
  getDeviceTokenRegistered,
  setDeviceTokenRegistered,
} from '../../utils/authenticationStore';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

const registerForPushNotificationsAsync = async (): Promise<string> => {
  let firebaseToken = '';

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  const registered = await getDeviceTokenRegistered();
  // handle the case when the user has already registered the device
  if (registered !== '') {
    return '';
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      // eslint-disable-next-line no-console
      console.error('Notifications permission is needed');
      return '';
    }

    firebaseToken = (await Notifications.getDevicePushTokenAsync()).data;
  }

  return firebaseToken;
};

export const usePushNotifications = (): { registerForPushNotifications: () => Promise<void> } => {
  const [registerDevice] = useRegisterDeviceMutation();

  const registerForPushNotifications = async (): Promise<void> => {
    const firebaseToken = await registerForPushNotificationsAsync();
    if (!firebaseToken) {
      return;
    }

    // Send token to iterable's backend
    const registeredDevice = await registerDevice({
      variables: {
        firebaseToken,
        platform: Platform.OS === 'ios' ? 'APNS' : 'GCM',
        applicationName: Application.applicationId ?? 'unknown',
      },
    });

    if (registeredDevice.data?.registerDevice === true) {
      await setDeviceTokenRegistered(firebaseToken);
    }
  };

  return { registerForPushNotifications };
};
