import { useBreakpointValue } from 'native-base';
import { createContext, memo, ReactNode, useCallback, useContext } from 'react';

export type Breakpoint = 'mobile' | 'tablet' | 'desktop' | 'desktopLarge';
type SwitchCases<Matched> = {
  // Mobile is required as the lowest fallback case.
  mobile: Matched;
} & {
  [key in Exclude<Breakpoint, 'mobile'>]?: Matched;
};
export type BreakpointSwitch = <Matched>(cases: SwitchCases<Matched>) => Matched;

type BreakpointContextType = {
  currentBreakpoint: 'mobile' | 'tablet' | 'desktop' | 'desktopLarge';
  breakpointSwitch: BreakpointSwitch;
};

// Map breakpoints to an order: Largest to smallest.
const breakpointOrder = ['desktopLarge', 'desktop', 'tablet', 'mobile'] as const;
const accumulator: SwitchCases<number> = { mobile: 3 };
const orderByBreakpoint = breakpointOrder.reduce((acc, breakpoint, breakpointIndex) => {
  acc[breakpoint] = breakpointIndex;
  return acc;
}, accumulator);

const BreakpointContext = createContext<BreakpointContextType>({
  currentBreakpoint: 'mobile',
  breakpointSwitch: <Matched,>() => undefined as Matched,
});

export const BreakpointConsumer = BreakpointContext.Consumer;

export const BreakpointProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const currentBreakpoint: Breakpoint = useBreakpointValue({
    base: 'mobile',
    sm: 'tablet',
    md: 'desktop',
    lg: 'desktopLarge',
  });

  return (
    <MemoizedBreakpointProvider currentBreakpoint={currentBreakpoint}>
      {children}
    </MemoizedBreakpointProvider>
  );
};

export const BreakpointProviderCore = ({
  currentBreakpoint,
  children,
}: {
  currentBreakpoint: Breakpoint;
  children: ReactNode;
}): JSX.Element => {
  const breakpointSwitch = useCallback(
    <Matched,>(cases: SwitchCases<Matched>): Matched => {
      const matchedCase = cases[currentBreakpoint];
      if (matchedCase !== undefined) {
        return matchedCase;
      }

      const currentIndex = orderByBreakpoint[currentBreakpoint] ?? 0;

      const matchedBreakpoint = breakpointOrder.find((breakpointCheck, breakpointIndex) => {
        if (breakpointIndex <= currentIndex) {
          return false;
        }

        return cases[breakpointCheck] !== undefined;
      });

      const finalMatchedCase = cases[matchedBreakpoint ?? 'mobile'];
      return finalMatchedCase ?? cases.mobile;
    },
    [currentBreakpoint],
  );

  const providerValue: BreakpointContextType = {
    currentBreakpoint,
    breakpointSwitch,
  };

  return <BreakpointContext.Provider value={providerValue}>{children}</BreakpointContext.Provider>;
};

const MemoizedBreakpointProvider = memo(BreakpointProviderCore);

export const useBreakpointContext = (): BreakpointContextType => {
  return useContext(BreakpointContext);
};

export const useBreakpointSwitch = <Matched,>(cases: SwitchCases<Matched>): Matched => {
  const { breakpointSwitch } = useBreakpointContext();

  return breakpointSwitch(cases);
};
