import { JSX } from 'react';
import { Link, StatusFeedback } from '../../../components/core';
import { IconAlert } from '../../../components/icons/IconAlert';

export const ResetPasswordRateLimitError = (): JSX.Element => {
  return (
    <StatusFeedback.danger Icon={IconAlert} testID="reset-password-rate-limit-error">
      Your account has been locked for 15 minutes due to too many reset attempts. If you're having
      trouble accessing your account please reach out to us at{' '}
      <Link.paraSmall bold to="mailto:hi@mantrahealth.com">
        hi@mantrahealth.com
      </Link.paraSmall>
    </StatusFeedback.danger>
  );
};
