import { Ellipse, Path, Rect, Svg } from '../../../components/icons';

export const ImageCoachingStudent = (): JSX.Element => {
  return (
    <Svg
      title="A coaching student."
      viewBox="0 0 237 300"
      height="300"
      width="237"
      aria-hidden={true}
    >
      <Rect x="0.5" width="237" height="300" rx="8" fill="#CAD2FA" />
      <Path
        d="M1.15312 96.5051C1.67212 96.8468 2.36252 96.7087 2.98227 96.7533C15.9567 97.6871 28.9871 97.7744 41.9731 96.9852C45.3484 96.781 48.7238 96.5155 52.0904 96.1946C54.8997 95.9262 58.0067 95.9087 60.7169 95.0656C63.0682 94.3333 64.8361 92.4808 64.7311 89.9048C64.629 87.3639 62.9428 85.2984 60.6614 84.3065C53.4909 81.3513 50.1623 83.3992 48.3796 85.4148C46.9739 87.0041 45.4112 87.1052 44.0632 85.4667C40.084 80.6298 38.7262 76.7885 30.9981 76.7886C25.5457 77.0365 22.1355 86.5005 23.1126 87.4776C23.9329 88.2979 16.6017 85.6429 11.8004 88.0339C9.99138 88.9348 8.28483 90.1692 6.29273 90.5093C5.09004 90.7146 3.90192 90.9875 2.84601 91.4889C1.20647 92.265 -0.0100315 94.135 0.713464 95.9524C0.81161 96.2014 0.967698 96.3831 1.15312 96.5051Z"
        fill="#FFF8ED"
      />
      <Path
        d="M208.854 64.4236C215.949 64.3186 223.041 64.1027 230.13 63.776C232.33 63.6739 236.578 63.4317 236 60.068C235.796 58.8807 234.845 58.1922 233.827 57.6904C232.269 56.9232 230.638 56.2697 229.01 55.6658C225.539 54.3793 221.945 53.4224 218.292 52.8185C217.201 52.6384 215.41 52.9434 213.852 53.2106C211.693 53.5813 210.72 51.829 209.962 49.7731C209.5 48.5188 208.896 47.327 208.055 46.272C206.164 43.8956 203.32 42.9398 200.378 42.9479C199.964 42.949 199.547 42.936 199.132 42.9126C192.946 42.5637 188.243 47.0357 186.187 48.8853C185.443 49.5543 184.891 49.0215 184.4 48.1496C182.176 44.1953 177.606 41.7612 172.878 42.0186C169.14 42.2204 165.853 43.7647 163.173 46.091C160.367 48.5259 160.945 52.774 163.531 55.441C165.485 57.4569 167.425 59.4879 169.349 61.5336C170.796 63.0721 172.785 63.9921 174.895 64.0669C186.207 64.4678 197.532 64.5911 208.854 64.4236Z"
        fill="#FFF8ED"
      />
      <Ellipse cx="166.967" cy="57.7653" rx="24.6184" ry="7.03383" fill="#FFF8ED" />
      <Path
        d="M130.58 109.213C131.269 105.395 128.733 101.742 124.916 101.052C121.098 100.363 117.445 102.899 116.755 106.716C116.066 110.534 118.602 114.187 122.419 114.877C126.237 115.566 129.89 113.03 130.58 109.213Z"
        fill="#35405F"
      />
      <Path
        d="M202.453 233.995C192.833 246.726 184.907 250.74 180.529 252.105C180.208 252.205 179.865 252.275 179.516 252.324C177.029 252.67 174.51 253.343 172.247 254.43C171.125 254.97 169.849 255.35 168.646 255.181C166.088 254.821 163.698 253.819 161.54 252.439C159.391 251.065 158.479 248.475 158.717 245.935C159.493 237.642 160.173 228.213 160.442 219.065C160.486 217.554 161.656 214.995 162.464 213.717C164.162 211.034 163.357 208.654 161.501 206.078L159.812 203.733L125.909 156.684C123.507 153.351 124.1 148.771 127.828 147.047C132.78 144.756 140.514 142.618 150.253 144.587C164.26 147.413 184.434 172.223 195.104 186.601C205.412 200.503 213.205 219.78 202.453 233.995Z"
        fill="#CB4848"
      />
      <Path
        d="M154.839 186.854C154.835 186.857 154.831 186.859 154.827 186.862C154.823 186.865 154.819 186.867 154.815 186.87C154.54 187.07 154.454 187.439 154.617 187.738C156.143 190.541 157.541 193.464 158.742 196.502C159.895 199.414 160.762 202.54 161.394 205.813C161.414 205.917 161.457 206.016 161.519 206.102C161.965 206.72 162.91 206.327 162.764 205.579C162.087 202.104 161.174 198.909 160.023 195.998C158.802 192.909 157.379 189.934 155.822 187.08C155.63 186.726 155.169 186.624 154.839 186.854Z"
        fill="#35405F"
      />
      <Path
        d="M202.953 234.207C193.332 246.939 184.91 250.739 180.53 252.106C180.209 252.205 179.866 252.276 179.517 252.325C177.03 252.671 174.511 253.343 172.248 254.431C171.126 254.97 169.85 255.35 168.647 255.182C166.52 254.882 164.726 254.327 163.066 253.464C160.382 252.068 159.589 248.775 160.14 245.799C161.886 236.366 162.249 224.333 162.462 214.806C162.479 214.035 162.966 212.891 163.376 212.238C164.253 210.839 163.932 209.451 162.967 208.111L159.813 203.733C159.421 203.194 159.372 202.475 159.695 201.892C165.731 190.984 173.214 183.467 179.929 178.397C183.109 175.996 187.562 176.771 190.077 179.863C191.972 182.192 193.726 184.398 195.244 186.446C205.552 200.346 213.705 219.992 202.953 234.207Z"
        fill="#FFACAC"
      />
      <Path
        d="M174.988 251.69C175.28 251.981 175.43 252.318 175.11 252.579C174.835 252.803 174.262 252.905 174.01 252.654C170.104 248.759 165.777 245.345 161.098 242.478C160.866 242.335 160.739 242.07 160.776 241.8C160.844 241.314 161.387 241.049 161.806 241.305C166.591 244.233 171.01 247.709 174.988 251.69Z"
        fill="#35405F"
      />
      <Path
        d="M94.0598 159.2C93.6906 156.912 92.2089 154.996 90.0215 154.232C83.2208 151.859 67.3502 148.532 54.1925 162.552C36.9327 180.943 11.8918 218.729 9.91193 233.16C7.93209 247.591 9.90805 260.212 31.4156 266.549C49.3821 271.842 59.038 274.706 64.2377 274.918C64.8661 274.943 65.4572 274.941 66.011 274.917C69.7088 274.759 74.3576 274.988 78.0536 275.186C78.7875 275.225 79.6 275.228 80.4969 275.183C86.7576 274.863 101.054 253.484 95.113 247.543C91.7201 244.15 83.5048 239.005 77.0814 235.215C72.6797 232.618 71.5736 226.603 74.6581 222.528C80.0778 215.368 87.6806 204.292 91.6829 193.819C96.6559 180.805 95.1404 165.893 94.0598 159.2Z"
        fill="#CB4848"
      />
      <Path
        d="M118.442 151.36C120.514 151.007 122.635 151.48 124.289 152.777C131.197 158.195 150.006 174.413 158.742 196.506C168.447 221.051 158.203 261.003 155.339 271.094C154.895 272.656 153.953 274.001 152.545 274.809C148.228 277.284 137.079 282.363 117.617 282.879C105.796 283.193 97.4246 282.375 92.5566 281.655C89.4548 281.195 87.1682 278.719 86.65 275.627L81.4991 244.888L75.9073 221.807C75.5848 220.476 75.6094 219.085 75.9787 217.766L92.0153 160.499C92.8498 157.519 95.3279 155.288 98.3786 154.769L118.442 151.36Z"
        fill="#CB4848"
      />
      <Path
        d="M88.2698 199.018C88.2652 199.016 88.2607 199.014 88.2559 199.013C88.2514 199.012 88.247 199.011 88.2425 199.009C87.9174 198.91 87.5708 199.068 87.4316 199.379C86.1312 202.294 84.6556 205.179 82.9697 207.978C82.6407 208.525 82.2946 209.065 81.9368 209.603C81.6892 209.974 81.8085 210.478 82.2223 210.646C82.5351 210.772 82.8953 210.654 83.0821 210.373C83.4543 209.813 83.8125 209.254 84.1494 208.693C85.8625 205.846 87.3635 202.911 88.6853 199.94C88.8517 199.569 88.6546 199.141 88.2698 199.018Z"
        fill="#35405F"
      />
      <Path
        d="M76.9993 269.036C75.2857 271.472 75.1742 274.737 77.3375 276.784C79.3355 278.675 82.2737 280.602 86.0324 280.741C87.0032 280.777 87.9517 280.49 88.7436 279.927C90.0482 279 92.2298 277.15 94.5511 273.892C96.3934 271.307 99.3194 269.592 102.493 269.689C104.615 269.754 106.909 269.767 108.38 269.611C110.88 269.346 112.147 265.103 108.964 263.193C106.383 261.645 100.582 259.266 95.5767 253.519C91.8179 249.204 81.8435 262.15 76.9993 269.036Z"
        fill="#E7C797"
      />
      <Path
        d="M166.266 252.266C168.278 253.77 168.331 256.668 166.744 258.615L159.997 266.893C158.555 268.661 155.704 267.36 156.096 265.112C156.369 263.542 154.912 262.19 153.325 262.043C153.052 262.018 152.781 261.977 152.518 261.917C151.068 261.586 149.713 259.565 152.518 257.461C154.476 255.993 157.67 253.829 158.923 252.987C159.264 252.758 159.508 252.414 159.613 252.016C159.893 250.951 161.258 249.842 162.313 250.157C163.321 250.457 164.657 251.065 166.266 252.266Z"
        fill="#E7C797"
      />
      <Path
        d="M93.6177 150.339C93.0372 153.36 94.2603 156.462 97.1162 157.604C99.7689 158.665 103.508 159.688 108.059 159.688C117.775 159.688 121.565 152.616 122.455 150.577C122.613 150.216 122.703 149.836 122.761 149.447C123.272 146.046 121.122 142.81 117.788 141.965L104.496 138.596C100.036 137.465 95.5431 140.322 94.6745 144.841L93.6177 150.339Z"
        fill="#E7C797"
      />
      <Path
        d="M103.527 97.8088C93.5321 100.733 87.7561 111.091 90.6256 120.948L94.5775 134.524C97.447 144.381 105.962 151.13 121.263 145.922C131.122 142.568 133.641 133.794 130.771 123.938L126.819 110.361C123.95 100.505 113.521 94.8848 103.527 97.8088Z"
        fill="#E7C797"
      />
      <Path
        d="M103.95 118.023C104.958 114.924 107.025 112.495 110.268 112.166C113.54 111.834 118.371 109.089 121.551 108.251C125.698 107.159 130.799 104.509 132.639 98.1103C134.75 90.7731 130.116 86.8703 124.645 86.4995C120.4 86.2117 112.794 85.2834 108.73 86.5428C107.619 86.8874 106.582 87.2947 105.627 87.7332C101.308 89.7156 93.6047 88.9797 90.6911 92.7334C88.9599 94.9638 87.9497 98.854 87.3653 102.348C86.7115 106.258 82.958 111.195 82.91 115.158C82.8668 118.82 87.2297 123.584 90.5837 126.7C92.82 128.778 96.1488 129.082 98.3493 126.966C100.344 125.048 102.613 122.134 103.95 118.023Z"
        fill="#35405F"
      />
      <Path
        d="M104.71 128.335C106.594 131.615 105.463 135.803 102.183 137.689C98.9024 139.573 94.715 138.442 92.8286 135.162C90.9442 131.882 92.0752 127.694 95.3555 125.808C98.6357 123.924 102.823 125.055 104.71 128.335Z"
        fill="#E7C797"
      />
      <Path
        d="M80.4988 275.183C79.9036 275.343 79.3424 275.453 78.8144 275.52C74.7017 276.046 69.1453 274.959 64.9991 274.932C64.751 274.931 64.4966 274.925 64.2358 274.914C59.036 274.708 48.9928 272.461 31.0263 267.172C9.5226 260.833 7.2994 247.911 9.27924 233.483C10.2454 226.466 17.1931 213.654 25.6056 200.465C26.9768 198.315 29.306 196.955 31.8557 196.921C42.8166 196.776 58.8366 198.313 75.9655 205.863C80.1789 207.72 81.547 212.907 78.9288 216.695C77.4822 218.788 76.0918 220.709 74.8305 222.402C71.8363 226.42 72.8823 232.291 77.153 234.912C83.4839 238.798 91.6883 244.114 95.113 247.539C101.058 253.484 92.886 271.84 80.4988 275.183Z"
        fill="#FFACAC"
      />
      <Path
        d="M69.0016 228.507C69.3961 228.706 69.296 229.471 69.0116 229.809C68.8044 230.055 68.6657 229.875 68.3783 229.731C64.1563 227.604 59.7744 225.797 55.2463 224.324C54.4053 224.055 54.7713 222.724 55.6123 223C60.2288 224.498 64.698 226.334 69.0016 228.507Z"
        fill="#35405F"
      />
      <Path
        d="M174.123 200.593C170.904 203.411 167.903 206.429 165.119 209.634C164.825 209.973 164.291 209.95 164.03 209.585C163.846 209.327 163.861 208.976 164.069 208.736C166.88 205.503 169.901 202.459 173.144 199.614C173.812 199.028 174.791 199.999 174.123 200.593Z"
        fill="#35405F"
      />
      <Path
        d="M127.64 125.202C125.793 123.364 124.214 121.468 122.992 119.153C122.577 118.368 121.386 119.063 121.8 119.85C123.075 122.266 124.734 124.257 126.663 126.177C127.296 126.804 128.271 125.828 127.64 125.202Z"
        fill="#35405F"
      />
      <Path
        d="M126.684 125.242C126.168 125.789 125.443 126.067 124.692 125.919C123.826 125.75 123.454 127.08 124.326 127.249C125.554 127.489 126.793 127.136 127.659 126.215C128.268 125.571 127.295 124.594 126.684 125.242Z"
        fill="#35405F"
      />
      <Path
        d="M114.675 124.357C116.48 124.501 118.152 123.94 119.573 122.842C120.274 122.301 119.291 121.33 118.598 121.867C117.463 122.743 116.093 123.091 114.675 122.978C113.79 122.906 113.795 124.287 114.675 124.357Z"
        fill="#35405F"
      />
      <Path
        d="M129.862 119.632C130.007 120.167 129.504 120.581 128.989 120.786C128.028 121.167 126.995 121.333 125.92 121.246C125.038 121.178 125.031 119.798 125.92 119.866C126.867 119.946 127.792 119.819 128.639 119.459C129.13 119.251 129.723 119.118 129.862 119.632Z"
        fill="#35405F"
      />
      <Path
        d="M115.763 119.683C117.013 119.274 117.939 119.068 118.856 118.914C119.777 118.76 119.898 117.07 118.658 116.459C117.418 115.848 115.447 116.187 114.024 116.642C112.852 117.018 111.515 118.17 111.63 119.356C111.745 120.541 112.88 120.773 113.413 120.539C114.373 120.121 114.912 119.961 115.763 119.683Z"
        fill="#35405F"
      />
      <Path
        d="M129.323 115.783C129.465 116.827 128.264 117.244 127.22 117.384C127.169 117.391 127.12 117.398 127.073 117.404C125.768 117.591 124.846 117.811 123.956 118.074C123.059 118.343 122.212 116.881 123.053 115.785C123.902 114.689 125.819 114.13 127.295 113.908C127.654 113.852 128.039 113.875 128.341 114.077C128.865 114.428 129.233 115.124 129.323 115.783Z"
        fill="#35405F"
      />
      <Path
        d="M117.799 130.762C121.224 132.944 125.257 133.016 128.771 130.984C129.54 130.54 128.845 129.347 128.074 129.792C124.971 131.585 121.518 131.498 118.494 129.57C117.745 129.093 117.052 130.287 117.799 130.762Z"
        fill="#35405F"
      />
      <Path
        d="M122.251 145.614C122.743 145.408 123.306 145.81 123.228 146.337C123.195 146.556 123.054 146.743 122.851 146.83C118.341 148.746 112.919 148.513 108.25 147.295C107.388 147.067 107.754 145.736 108.616 145.963C112.922 147.091 118.034 147.379 122.251 145.614Z"
        fill="#35405F"
      />
      <Path
        d="M128.949 146.552C129.501 146.321 130.064 146.839 129.869 147.404C126.729 156.506 120.98 163.931 110.492 165.447C100.625 166.88 89.6125 161.93 83.7106 153.809C83.3335 153.29 83.7776 152.613 84.4075 152.735C84.5955 152.771 84.7533 152.878 84.8665 153.032C90.4965 160.718 100.92 165.446 110.299 164.08C120.259 162.628 125.597 155.578 128.562 146.976C128.628 146.784 128.761 146.631 128.949 146.552Z"
        fill="#35405F"
      />
      <Path
        d="M98.1807 135.68C96.3372 135.53 94.8538 134.269 94.5929 132.404C94.3535 130.7 95.1964 129.123 96.7946 128.475C97.6162 128.142 98.0386 129.454 97.2191 129.787C96.1931 130.204 95.7746 131.309 95.9809 132.355C96.2301 133.618 97.2444 134.23 98.4611 134.329C99.3449 134.401 99.0568 135.75 98.1807 135.68Z"
        fill="#35405F"
      />
      <Path
        d="M91.2504 244.284C91.4722 244.452 91.5578 244.734 91.479 245.001C88.2136 256.068 82.0617 266.059 73.6169 273.919C73.3414 274.175 72.7103 274.049 72.4347 273.792C72.1224 273.502 72.3404 273.215 72.6527 272.925C80.9145 265.239 86.9794 255.469 90.1668 244.64C90.3025 244.178 90.8673 243.993 91.2504 244.284Z"
        fill="#35405F"
      />
    </Svg>
  );
};
