import { Path, Rect, Svg } from '../../../components/icons';

export const ImageHandPlantSquare = (): JSX.Element => {
  return (
    <Svg
      title="A hand holding a growing plant."
      width="94"
      height="94"
      viewBox="0 0 94 94"
      aria-hidden={true}
    >
      <Rect opacity="0.4" width="94" height="94" rx="8" fill="#C7D2FE" />
      <Path
        d="M43.5839 25.5189C47.1157 26.5487 50.4842 23.6988 49.0929 20.2932C48.4881 18.8129 47.4774 17.2325 45.8191 15.7561C44.1856 14.3028 42.416 13.3908 40.7433 12.8255C36.8365 11.5051 33.7964 15.3143 35.7379 18.9527C36.3085 20.0219 37.0277 21.131 37.9338 22.2129C39.3422 23.8979 41.4973 24.9104 43.5839 25.5189Z"
        fill="#A2CAC2"
      />
      <Path
        d="M36.7031 20.2356C38.846 19.5638 41.0101 19.0228 43.213 18.5984C43.6586 18.5136 43.4711 17.8311 43.0256 17.916C40.8227 18.3368 38.6586 18.8813 36.5158 19.5532C36.0809 19.6911 36.2647 20.3735 36.7031 20.2356Z"
        fill="#35405F"
      />
      <Path
        d="M38.5421 22.6088C40.7486 21.7106 43.0576 21.1272 45.4303 20.8973C45.8794 20.8549 45.8829 20.1442 45.4303 20.1901C42.994 20.427 40.6213 21.0034 38.3547 21.9263C37.9374 22.096 38.1178 22.782 38.5421 22.6088Z"
        fill="#35405F"
      />
      <Path
        d="M55.849 16.8366C54.6673 20.0475 49.9706 18.9541 50.9923 15.6888C51.0892 15.3779 51.2039 15.0847 51.3323 14.8086C52.6605 11.9526 56.7805 13.3705 55.9815 16.4172C55.9443 16.5585 55.8998 16.6984 55.849 16.8366Z"
        fill="#A2CAC2"
      />
      <Path
        d="M23.6408 42.2612C21.761 40.1652 21.7849 36.983 23.696 34.9155C25.4942 32.9701 28.43 32.5977 30.6569 34.0325L34.5468 36.5386C38.2777 38.9424 38.2578 44.4046 34.5095 46.7811C32.0019 48.3709 28.7127 47.9163 26.7303 45.706L23.6408 42.2612Z"
        fill="#A06736"
      />
      <Path
        d="M77.9953 53.4792C75.5418 48.9344 78.0182 42.7897 83.1715 43.1329C83.4432 43.151 83.7009 43.1796 83.941 43.22C85.9288 43.5547 86.6857 44.9204 86.8879 46.2766C87.1288 47.8916 86.7868 48.5683 87.3903 50.0855C88.1582 52.016 88.2876 56.1251 87.387 57.9974C87.3152 58.1466 87.228 58.2987 87.1234 58.4532C85.3819 61.0262 81.4665 59.9091 79.9906 57.1751L77.9953 53.4792Z"
        fill="#A06736"
      />
      <Path
        d="M58.6333 32.0755C54.8585 32.6527 51.9217 29.0134 54.3227 26.044C55.079 25.1086 56.0902 24.1928 57.4419 23.4044C59.1448 22.4094 60.8724 21.9206 62.4511 21.7217C66.2065 21.2486 68.4751 25.204 66.0001 28.0679C65.2712 28.9114 64.363 29.7422 63.2339 30.4764C61.8863 31.3519 60.2414 31.8296 58.6333 32.0755Z"
        fill="#A2CAC2"
      />
      <Path
        d="M51.9574 32.2906C57.1341 28.6131 62.3815 24.9463 68.4423 22.8777C68.8701 22.7327 68.6863 22.0467 68.2549 22.1952C62.1341 24.285 56.83 27.966 51.6002 31.6824C51.2325 31.9441 51.5825 32.5558 51.9574 32.2906Z"
        fill="#35405F"
      />
      <Path
        d="M58.3188 27.9806C60.2849 28.2811 62.1872 28.7868 64.0401 29.5081C64.4644 29.6743 64.6483 28.9883 64.2275 28.8257C62.3747 28.1043 60.4723 27.5952 58.5063 27.2981C58.0607 27.2309 57.8698 27.9099 58.3188 27.9806Z"
        fill="#35405F"
      />
      <Path
        d="M61.7525 25.8973C63.4109 25.9998 65.0481 26.3464 66.6075 26.9121C67.0353 27.0677 67.2192 26.3853 66.7949 26.2297C65.1754 25.6392 63.471 25.2926 61.749 25.1901C61.2999 25.1653 61.2999 25.8725 61.7525 25.8973Z"
        fill="#35405F"
      />
      <Path
        d="M33.885 12.7792C39.525 15.0069 44.2598 18.9496 47.6084 23.992C50.2321 27.9452 52.3184 32.7259 51.8057 37.5632C51.7562 38.0158 52.4669 38.0123 52.5129 37.5632C53.0504 32.489 50.7979 27.4184 48.0009 23.306C44.5568 18.2494 39.7725 14.3421 34.0724 12.0932C33.6481 11.9306 33.4642 12.613 33.885 12.7792Z"
        fill="#35405F"
      />
      <Path
        d="M52.0884 31.258C51.2752 24.6987 52.4385 17.7362 55.3663 11.8098C55.5679 11.4032 54.9562 11.0461 54.7546 11.4527C51.7702 17.4852 50.5503 24.5784 51.3777 31.258C51.4343 31.7035 52.145 31.7071 52.0884 31.258Z"
        fill="#35405F"
      />
      <Path
        d="M67.6151 41.7854C65.6797 41.7854 63.6545 41.1739 62.2445 39.8481C59.7852 37.5356 56.4746 36.1172 52.831 36.1172C50.6664 36.1172 48.62 36.6186 46.7996 37.5099C44.6027 38.5856 41.918 38.748 39.4719 38.748C35.2852 38.748 31.8906 42.1426 31.8906 46.3292C31.8906 50.5159 35.2852 53.9105 39.4719 53.9105C39.5947 53.9105 39.7056 53.9826 39.7434 54.0995C41.5304 59.6221 46.7128 63.6134 52.8274 63.6134C55.4131 63.6134 57.8324 62.8981 59.8984 61.6553C62.1395 60.3072 64.9963 59.851 67.6116 59.851C72.6009 59.851 76.6497 55.8058 76.6497 50.8129C76.6532 45.8307 72.608 41.7854 67.6151 41.7854Z"
        fill="#BDA6A0"
      />
      <Path
        d="M15.9521 39.4641C18.527 36.465 22.9889 36.2116 26.1368 38.6023C28.2602 40.2149 31.0535 40.6148 33.6962 40.2615C36.4117 39.8984 40.0231 39.8677 43.1672 41.3569C49.1395 44.1857 50.9075 50.3137 54.7583 51.3356C57.1506 51.9705 58.746 52.1427 60.3888 52.4695C62.5065 52.8908 64.7316 52.8486 66.7193 52.0053C69.5953 50.7851 73.841 49.151 76.565 48.8604C77.7988 48.7297 78.7898 48.8201 79.5776 49.0188C81.5423 49.5144 84.1023 50.6042 84.9701 52.4353C85.9735 54.5536 86.0217 57.8029 85.1634 59.984C85.1083 60.124 85.0441 60.2669 84.9701 60.4126C83.7113 62.8878 75.2673 72.3573 67.5269 78.1316C59.7865 83.9059 45.6318 84.2489 35.2323 78.9944C26.8646 74.7674 15.4657 63.9345 9.68589 58.1434C7.42162 55.8748 6.85284 52.4597 8.50728 49.7145C10.1265 47.0276 12.57 43.4035 15.9521 39.4641Z"
        fill="#A06736"
      />
      <Path
        d="M63.7857 70.19C68.8528 66.9546 73.4426 63.0119 77.9369 59.0374C79.8463 57.3472 83.9658 54.0375 82.9757 50.9576C82.8378 50.5262 82.1554 50.7101 82.2933 51.145C82.7141 52.4462 81.8406 53.7581 81.091 54.7588C79.6447 56.693 77.7636 58.2559 75.9461 59.8224C71.9257 63.2806 67.9051 66.7212 63.425 69.5819C63.0466 69.8258 63.4002 70.4376 63.7857 70.19Z"
        fill="#35405F"
      />
      <Path
        d="M68.6442 72.6329C71.9115 70.0409 75.1009 67.3571 78.1596 64.5212C80.6949 62.1733 83.9269 59.6804 85.0938 56.307C85.2423 55.8756 84.5599 55.6917 84.4114 56.1196C83.1844 59.6662 79.3866 62.4597 76.6851 64.9137C73.9235 67.4207 71.0629 69.8146 68.1421 72.1307C67.7885 72.4171 68.2906 72.9122 68.6442 72.6329Z"
        fill="#35405F"
      />
      <Path
        d="M43.0749 59.9172C46.5261 60.8295 50.0445 61.5509 53.5628 62.1414C56.8584 62.693 60.2247 62.9759 63.4955 62.0636C65.3979 61.5332 67.3427 60.4476 67.7564 58.3578C68.2727 55.7695 66.3244 53.5948 63.9764 52.7886C63.545 52.6401 63.3612 53.3225 63.789 53.471C66.1228 54.2737 68.1348 56.9682 66.6214 59.3302C65.1256 61.6676 61.3598 61.8762 58.9234 61.9469C56.3563 62.0212 53.8032 61.4908 51.2927 61.0134C48.6017 60.5007 45.9108 59.9349 43.2623 59.2312C42.8239 59.1181 42.6365 59.8005 43.0749 59.9172Z"
        fill="#35405F"
      />
      <Path
        d="M22.8949 36.4205C24.9706 37.9693 27.0143 39.5499 29.189 40.9607C29.5709 41.2083 29.928 40.5965 29.5461 40.349C27.3715 38.9381 25.3242 37.3575 23.2521 35.8087C22.8879 35.5365 22.5342 36.1517 22.8949 36.4205Z"
        fill="#35405F"
      />
      <Path
        d="M29.4649 40.996C31.0738 40.5964 32.7569 40.5045 34.4082 40.4443C34.8609 40.4267 34.8644 39.7195 34.4082 39.7371C32.6968 39.8008 30.9429 39.8998 29.2739 40.3135C28.8355 40.4267 29.0229 41.1056 29.4649 40.996Z"
        fill="#35405F"
      />
      <Path
        d="M81.7525 49.5811C83.1987 49.0366 84.7793 48.8739 86.3068 49.1108C86.7524 49.1816 86.9433 48.4991 86.4942 48.4284C84.8429 48.1738 83.1315 48.3082 81.5615 48.8987C81.1442 49.0578 81.3246 49.7403 81.7525 49.5811Z"
        fill="#35405F"
      />
      <Path
        d="M76.5261 48.8783C78.5841 48.6732 81.5968 48.4505 82.2616 51.0105C82.3747 51.449 83.0571 51.2651 82.944 50.8231C82.1873 47.9236 78.9377 47.9307 76.5261 48.1711C76.077 48.2171 76.0735 48.9243 76.5261 48.8783Z"
        fill="#35405F"
      />
      <Path
        d="M82.6152 51.6258C84.4787 52.2729 85.0197 54.3521 84.4151 56.1201C84.2665 56.5515 84.9525 56.7389 85.0975 56.3075C85.833 54.1435 85.0268 51.7107 82.8026 50.9434C82.3712 50.7949 82.1873 51.4773 82.6152 51.6258Z"
        fill="#35405F"
      />
      <Path
        d="M84.6096 56.1516C85.6279 57.3361 85.6138 58.9592 84.5812 60.1296C84.2807 60.4691 84.7792 60.9712 85.0798 60.6282C86.3811 59.1537 86.3882 57.1381 85.1081 55.6495C84.8111 55.3029 84.3125 55.805 84.6096 56.1516Z"
        fill="#35405F"
      />
      <Path
        d="M85.1008 54.9842C85.557 54.8781 86.0095 54.8604 86.4728 54.8887C86.9254 54.9205 86.9254 54.2133 86.4728 54.1815C85.9459 54.1461 85.4297 54.1815 84.9134 54.3017C84.4714 54.4043 84.6588 55.0867 85.1008 54.9842Z"
        fill="#35405F"
      />
    </Svg>
  );
};
