import { createIcon, Path } from './index';

export const IconArrowUpRight = createIcon({
  title: 'An arrow pointing up and to the right',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M7 17L17 7"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M7 7H17V17"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
