import { format } from 'date-fns';
import { Divider } from 'native-base';
import { useState } from 'react';
import { Button, Heading, Layout, Text } from '../../components/core';
import { IconTrash2 } from '../../components/icons/IconTrash2';
import { PageContent, PageFooter, PageHeader, PageTopBar } from '../../components/page';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { ReflectionDeleteModal } from './ReflectionDeleteModal';

type Question = { questionKey: string; question: string; answer: string };

type QuestionsListProps = {
  onEditPress: (question: Question) => () => void;
  reflectionId: string;
  questions: Question[];
  title: string;
  updatedAt: string;
};

export const QuestionsList = ({
  onEditPress,
  reflectionId,
  questions,
  title,
  updatedAt,
}: QuestionsListProps): JSX.Element => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onBackPress = (): void => {
    navigate(getRoute('reflections', {}));
  };

  const onDeletePressed = (): void => {
    setShowDeleteModal(true);
  };

  const onCloseDelete = (wasDeleted: boolean): void => {
    setShowDeleteModal(false);

    if (wasDeleted) {
      navigate(getRoute('reflections', {}));
    }
  };

  return (
    <PageContent testID="reflection-page-list">
      <PageTopBar backAccessibilityLabel="Back to Reflections" onBackPress={onBackPress} />

      <PageHeader overtext={format(new Date(updatedAt), 'MMM dd, yyyy')} title={title} />

      <Layout.VStack {...styles.topWrapper} space={0}>
        <Divider backgroundColor="secondary.alpha.10:alpha.10" />

        <Layout.VStack
          {...styles.topStack}
          space={0}
          divider={<Divider backgroundColor="secondary.alpha.10:alpha.10" />}
        >
          {questions.map(question => (
            <Layout.VStack {...styles.question} key={question.questionKey} space={4}>
              <Heading.h5>{question.question}</Heading.h5>

              {question.answer !== undefined ? (
                <Text.para color="secondary.800">{question.answer}</Text.para>
              ) : (
                <Text.para color="secondary.600">No response.</Text.para>
              )}

              <Layout.View>
                <Button.tertiarySmall
                  alignSelf="flex-start"
                  _text={{ color: 'primary.600' }}
                  paddingLeft={0}
                  testID="reflection-page-edit-question"
                  onPress={onEditPress(question)}
                >
                  Edit
                </Button.tertiarySmall>
              </Layout.View>
            </Layout.VStack>
          ))}
        </Layout.VStack>

        <Divider backgroundColor="secondary.alpha.10:alpha.10" />

        <Layout.View paddingY={4}>
          <Button.tertiaryLarge
            _text={styles.deleteButtonColor}
            leftIcon={<IconTrash2 {...styles.deleteButtonColor} accessibilityHidden />}
            testID="reflection-page-delete-entry"
            onPress={onDeletePressed}
          >
            Delete this Entry
          </Button.tertiaryLarge>
        </Layout.View>
      </Layout.VStack>

      {showDeleteModal && (
        <ReflectionDeleteModal reflectionId={reflectionId} onClose={onCloseDelete} />
      )}

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  topWrapper: {
    alignItems: 'center',
  },

  topStack: {
    width: '100%',
  },

  question: {
    paddingY: 8,
  },

  deleteButtonColor: {
    color: 'red.700',
  },
});
