import { createIcon, Path } from './index';

export const IconHeartHandShake = createIcon({
  title: 'A heart and handshake',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M20.4201 4.57996C19.9184 4.07653 19.3223 3.67709 18.6659 3.40455C18.0095 3.132 17.3058 2.9917 16.5951 2.9917C15.8844 2.9917 15.1806 3.132 14.5243 3.40455C13.8679 3.67709 13.2718 4.07653 12.7701 4.57996L12.0001 5.35996L11.2301 4.57996C10.7284 4.07653 10.1323 3.67709 9.47591 3.40455C8.81953 3.132 8.1158 2.9917 7.40509 2.9917C6.69437 2.9917 5.99065 3.132 5.33427 3.40455C4.67789 3.67709 4.08176 4.07653 3.58009 4.57996C1.46009 6.69996 1.33009 10.28 4.00009 13L12.0001 21L20.0001 13C22.6701 10.28 22.5401 6.69996 20.4201 4.57996Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
    <Path
      d="M11.9997 5.35999L8.8697 8.49999C8.47399 8.89893 8.25195 9.43808 8.25195 9.99999C8.25195 10.5619 8.47399 11.101 8.8697 11.5V11.5C9.26865 11.8957 9.80779 12.1177 10.3697 12.1177C10.9316 12.1177 11.4708 11.8957 11.8697 11.5L14.1297 9.28999C14.6914 8.73427 15.4496 8.42257 16.2397 8.42257C17.0298 8.42257 17.788 8.73427 18.3497 9.28999L20.7497 11.69"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
    <Path
      d="M18 15L16 13"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
    <Path
      d="M15 18L13 16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
  ],
});
