import { IModalProps, IOverlayProps, Modal as NativeModal } from 'native-base';
import React, { ReactNode } from 'react';
import { Platform } from 'react-native';
import { FullStyledProps } from '../../styles/stylesheet';
import type { InterfaceBoxProps } from '../../types/nativeBaseTypes';

export { Modal } from 'native-base';

type AccessibleModalProps =
  | {
      'aria-label': string;
      headerId?: never;
    }
  | {
      'aria-label'?: never;
      headerId: string;
    };

type SimpleModalProps = InterfaceBoxProps & {
  children: ReactNode;
  footer?: ReactNode;
  footerStyles?: FullStyledProps;
  onClose?: () => void;
  size?: IModalProps['size'];
} & AccessibleModalProps;

export const SimpleModal = ({
  'aria-label': ariaLabel = '',
  children,
  footer,
  footerStyles,
  headerId = '',
  onClose,
  size,
  testID,
  ...contentStyles
}: SimpleModalProps): JSX.Element => {
  const overlayStyles = {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
  };

  const contentAriaProps = {
    ...(ariaLabel ? { 'aria-label': ariaLabel } : {}),
    ...(headerId ? { 'aria-labelledby': headerId } : {}),
  };

  return (
    <NativeModal
      _overlay={{ style: overlayStyles as IOverlayProps['style'] }}
      closeOnOverlayClick={false}
      isOpen={true}
      onClose={onClose}
      size={size}
      testID={testID}
      safeAreaTop
    >
      <NativeModal.Content {...contentStyles} {...contentAriaProps}>
        <NativeModal.CloseButton aria-label="Close" _icon={{ 'aria-hidden': true }} />

        <NativeModal.Body>{children}</NativeModal.Body>

        {footer !== undefined && (
          <NativeModal.Footer {...footerStyles}>{footer}</NativeModal.Footer>
        )}
      </NativeModal.Content>
    </NativeModal>
  );
};
