import { Path, Rect, Svg } from '../../../components/icons';

export const ImageStudentLaptop = (): JSX.Element => {
  return (
    <Svg
      title="A productivity coaching student sitting in a chair working on a laptop."
      width="238"
      height="300"
      viewBox="0 0 238 300"
      aria-hidden={true}
      fill="none"
    >
      <Rect width="237" height="300" transform="translate(0.5 0.5)" fill="#35405F" />
      <Path
        d="M199.228 238.815L196.783 283.608C196.722 284.739 195.754 285.598 194.63 285.538C193.594 285.483 192.761 284.651 192.7 283.614L189.965 237.447L199.228 238.815Z"
        fill="#2B59E3"
      />
      <Path
        d="M39.7608 238.815L42.2052 283.608C42.2661 284.739 43.2345 285.598 44.3586 285.538C45.3946 285.483 46.2274 284.651 46.2883 283.614L49.024 237.447L39.7608 238.815Z"
        fill="#2B59E3"
      />
      <Path
        d="M50.3783 225.157L52.8229 263.923C52.8838 265.054 53.8521 265.914 54.9761 265.853C56.0122 265.799 56.8451 264.966 56.9061 263.93L59.6416 223.782L50.3783 225.157Z"
        fill="#35405F"
      />
      <Path
        d="M188.028 225.157L185.583 263.923C185.522 265.054 184.554 265.914 183.43 265.853C182.394 265.799 181.561 264.966 181.5 263.93L178.765 223.782L188.028 225.157Z"
        fill="#1D2744"
      />
      <Path
        d="M202.214 126.951V200.969L201.557 221.629L166.793 222.705L78.3579 213.239L38.7043 215.69L37.8443 215.744L36.6322 215.819C36.6322 215.819 29.0347 205.276 29.6847 203.773C30.328 202.269 34.8989 161.167 34.8989 161.167V126.957C34.8989 120.423 40.2415 115.121 46.8436 115.121H190.249C196.858 115.121 202.214 120.423 202.214 126.951Z"
        fill="#FFACAC"
      />
      <Path
        d="M198.26 160.733H197.433V127.073C197.433 123.084 194.19 119.834 190.195 119.834H46.5254C42.537 119.834 39.2868 123.077 39.2868 127.073V156.366H38.4607V127.073C38.4607 122.624 42.0766 119.008 46.5254 119.008H190.195C194.644 119.008 198.26 122.624 198.26 127.073V160.733Z"
        fill="#F08080"
      />
      <Path
        d="M48.0625 161.742C47.5547 158.715 44.2704 157.266 40.5123 156.61V157.476C43.9048 158.072 46.7895 159.338 47.2161 161.884C47.8797 165.832 46.8979 203.522 46.8911 203.901C46.8843 204.131 47.0738 204.335 47.3108 204.341H47.3176C47.5478 204.341 47.7375 204.159 47.7511 203.922C47.7849 202.364 48.7329 165.771 48.0625 161.742Z"
        fill="#F08080"
      />
      <Path
        d="M202.214 156.339V157.205C197.549 157.388 192.693 158.404 192.043 161.742C191.278 165.677 191.292 203.373 191.292 203.752C191.299 203.982 191.102 204.186 190.858 204.186H190.852C190.621 204.179 190.439 203.996 190.432 203.752C190.432 202.202 190.425 165.588 191.197 161.587C191.949 157.7 197.244 156.542 202.214 156.339Z"
        fill="#F08080"
      />
      <Path d="M200.921 211.086H31.3641V242.932H200.921V211.086Z" fill="#FFACAC" />
      <Path
        d="M198.002 215.65C197.853 215.65 197.711 215.568 197.637 215.426L193.31 207.07C192.287 205.1 190.269 203.867 188.048 203.867H49.6404C47.7579 203.867 45.9702 204.775 44.8597 206.292L38.5826 214.864C38.4471 215.047 38.1897 215.088 38.0001 214.952C37.8173 214.817 37.7768 214.559 37.9122 214.37L44.1893 205.797C45.4556 204.071 47.4938 203.034 49.6336 203.034H188.042C190.574 203.034 192.87 204.429 194.034 206.684L198.361 215.04C198.47 215.243 198.388 215.494 198.185 215.595C198.131 215.636 198.063 215.65 198.002 215.65Z"
        fill="#F08080"
      />
      <Path
        d="M38.2507 242.932H29.2583C27.2133 242.932 25.5543 241.273 25.5543 239.228V162.406C25.5543 159.467 27.9378 157.077 30.8833 157.077H32.9216C35.8604 157.077 38.2507 159.46 38.2507 162.406V242.932Z"
        fill="#FFACAC"
      />
      <Path
        d="M199.411 242.932H208.403C210.448 242.932 212.107 241.273 212.107 239.228V162.406C212.107 159.467 209.724 157.077 206.778 157.077H204.74C201.801 157.077 199.411 159.46 199.411 162.406V242.932Z"
        fill="#FFACAC"
      />
      <Path
        d="M198.754 242.932H197.928V216.787C197.928 214.939 196.424 213.435 194.576 213.435H42.0157C40.1671 213.435 38.6637 214.939 38.6637 216.787V242.932H37.8376V216.787C37.8376 214.485 39.7134 212.609 42.0157 212.609H194.576C196.878 212.609 198.754 214.485 198.754 216.787V242.932Z"
        fill="#F08080"
      />
      <Path
        d="M38.7043 215.69C38.6976 217.37 38.6773 218.453 38.6773 218.697L37.8173 218.69C37.8173 218.629 37.824 217.539 37.8443 215.744C37.9391 206.86 38.1694 166.889 37.8173 162.656C37.5938 159.968 36.1381 158.58 34.8989 157.862V156.901C36.3954 157.632 38.3929 159.223 38.6773 162.589C39.0362 166.895 38.7991 206.698 38.7043 215.69Z"
        fill="#F08080"
      />
      <Path
        d="M202.214 157.158V158.12C200.907 158.892 199.147 160.469 198.903 163.483C198.483 168.568 198.903 219.144 198.903 219.516L198.043 219.523C198.023 218.006 197.623 168.534 198.043 163.415C198.348 159.738 200.711 157.937 202.214 157.158Z"
        fill="#F08080"
      />
      <Path
        d="M98.4758 82.7265C98.4758 82.7265 100.101 78.386 105.457 78.5215C110.813 78.6569 122.948 78.2506 128.372 78.1152C130.559 78.061 132.719 77.6344 134.906 77.5261C138.177 77.3635 140.188 78.9413 142.666 80.817C143.797 81.6769 144.935 82.5369 145.991 83.4849C146.309 83.7693 147.535 84.6767 147.352 85.2319C147.081 86.0445 135.692 91.807 135.692 91.807C135.692 91.807 125.609 96.0798 117.063 94.1093C108.511 92.1456 98.4758 82.7265 98.4758 82.7265Z"
        fill="#E0E7FF"
      />
      <Path
        d="M132.848 80.8237C132.848 80.8237 124.092 90.1141 121.661 90.9267C119.237 91.7393 118.276 90.385 114.456 89.5047C110.631 88.6177 108.714 81.5009 108.714 81.5009C108.714 81.5009 110.041 80.9253 111.348 79.8757C112.411 79.0293 112.648 77.8849 112.973 76.49C113.623 73.7476 112.682 70.3416 112.439 69.5425C112.405 69.4274 112.391 69.3732 112.391 69.3732L112.987 69.3394L128.08 68.6216C128.08 68.6216 127.783 73.8424 128.812 76.6932C129.834 79.5372 132.848 80.8237 132.848 80.8237Z"
        fill="#A2CAC2"
      />
      <Path
        d="M126.706 72.6912C125.859 70.565 122.894 69.4274 120.483 68.9805C118.181 68.5471 114.829 68.7503 112.98 69.3394C112.824 69.3936 112.669 69.4477 112.527 69.5019C112.493 69.5087 112.472 69.529 112.439 69.5425C112.682 70.3416 113.623 73.7476 112.973 76.49C112.648 77.8782 111.809 79.4288 111.348 79.8757C112.053 80.4513 112.811 80.9185 113.481 81.2571C115.54 82.3067 118.404 82.4963 120.666 82.1374C122.372 81.8665 124.465 80.7289 125.48 79.2798C126.598 77.6682 127.41 74.4586 126.706 72.6912Z"
        fill="#2D6A62"
      />
      <Path
        d="M95.5369 83.5932C95.5369 83.5932 101.591 81.5009 103.832 81.704C106.067 81.9072 108.707 81.5009 108.707 81.5009C108.707 81.5009 114.51 86.2544 120.774 86.0513C127.038 85.8481 132.841 80.8305 132.841 80.8305C132.841 80.8305 135.61 81.3113 137.716 81.379C139.815 81.4467 145.788 81.6431 145.788 81.6431C145.788 81.6431 141.583 116.448 139.145 121.188C136.707 125.935 120.774 128.373 120.774 128.373L92.3747 120.24L91.1558 93.7098L95.5369 83.5932Z"
        fill="white"
      />
      <Path d="M133.897 156.243H96.6543V176.557H133.897V156.243Z" fill="#1D2744" />
      <Path
        d="M129.59 53.3182C128.006 49.1673 124.735 46.3233 120.151 45.4701C114.456 44.407 108.389 48.4089 107.177 54.0698C106.71 56.2434 106.818 58.5051 107.069 60.7126C107.698 66.3464 109.798 75.4269 115.479 78.1423C118.404 79.5439 122.061 79.4085 124.81 77.5938C127.647 75.7181 129.144 71.8854 129.882 68.6826C130.471 66.1162 130.647 63.4618 130.593 60.8345C130.552 58.6405 130.423 55.4918 129.59 53.3182Z"
        fill="#A2CAC2"
      />
      <Path
        d="M104.001 51.8826C103.947 52.0452 101.713 56.5075 105.389 58.2749C108.342 59.6901 111.592 56.2028 111.592 56.2028C111.592 56.2028 112.229 58.5525 114.748 58.7827C118.824 59.1552 119.576 56.0335 119.576 56.0335C119.576 56.0335 120.023 58.3494 123.273 58.3494C126.523 58.3494 127.173 55.3225 127.173 55.3225C127.173 55.3225 129.056 57.9837 132.028 56.6633C135.001 55.3428 132.821 50.9008 132.821 50.9008C132.821 50.9008 134.568 48.4021 132.638 44.5492C130.708 40.6962 125.48 42.0505 125.48 42.0505C125.48 42.0505 124.112 37.9538 118.763 37.8996C113.414 37.8455 112.276 41.9422 112.046 41.9422C111.816 41.9422 107.522 40.5947 104.848 43.8179C101.787 47.4744 104.001 51.8826 104.001 51.8826Z"
        fill="#F67E6A"
      />
      <Path
        d="M109.019 57.8889C108.809 57.6384 108.572 57.4149 108.294 57.2253C107.502 56.6971 106.473 56.5685 105.565 56.8393C104.658 57.117 103.879 57.7873 103.446 58.6338C102.532 60.4146 103.189 62.6154 104.225 64.3218C105.166 65.8656 107.428 68.4726 109.256 66.5225C109.798 65.9401 110.055 65.1479 110.204 64.3624C110.461 62.9675 110.421 61.5184 110.082 60.1438C109.872 59.338 109.54 58.5254 109.019 57.8889Z"
        fill="#A2CAC2"
      />
      <Path
        d="M127.938 59.1552C127.755 60.5636 127.877 62.0059 128.297 63.3602C128.527 64.1186 128.873 64.8838 129.482 65.3984C131.52 67.1251 133.471 64.2811 134.229 62.6357C135.069 60.8209 135.468 58.5593 134.358 56.8935C133.829 56.1013 132.983 55.5257 132.049 55.3564C131.114 55.1871 130.105 55.4377 129.38 56.0471C129.123 56.2638 128.913 56.5143 128.73 56.7852C128.277 57.4758 128.047 58.3223 127.938 59.1552Z"
        fill="#A2CAC2"
      />
      <Path
        d="M107.624 60.5975C107.394 60.0828 106.635 60.5298 106.866 61.0444C107.326 62.0872 107.834 63.279 107.285 64.3895C107.035 64.8973 107.793 65.3443 108.044 64.8364C108.748 63.3941 108.233 61.9721 107.624 60.5975Z"
        fill="#2D6A62"
      />
      <Path
        d="M131.046 58.4983C131.277 57.9837 132.035 58.4306 131.805 58.9453C131.344 59.9881 130.836 61.1798 131.385 62.2903C131.635 62.7982 130.877 63.2451 130.626 62.7372C129.915 61.2949 130.437 59.8729 131.046 58.4983Z"
        fill="#2D6A62"
      />
      <Path
        d="M115.16 64.7551C115.486 64.5791 115.892 64.6062 116.19 64.938C116.738 65.5474 116.765 66.536 116.298 67.2064C116.176 67.3824 116.014 67.5382 115.811 67.6059C115.486 67.7075 115.12 67.5382 114.91 67.2673C114.7 66.9965 114.626 66.6511 114.585 66.3126C114.551 65.9875 114.551 65.6557 114.659 65.351C114.754 65.0802 114.937 64.8702 115.16 64.7551Z"
        fill="#1D2744"
      />
      <Path
        d="M123.09 64.3014C123.415 64.1254 123.821 64.1525 124.119 64.4843C124.668 65.0937 124.695 66.0823 124.228 66.7527C124.106 66.9288 123.943 67.0845 123.74 67.1522C123.415 67.2538 123.049 67.0845 122.839 66.8136C122.63 66.5428 122.555 66.1974 122.514 65.8589C122.481 65.5338 122.481 65.202 122.589 64.8973C122.684 64.6265 122.867 64.4166 123.09 64.3014Z"
        fill="#1D2744"
      />
      <Path
        d="M120.063 68.6555C119.684 68.1612 119.481 67.7413 119.562 67.098C119.63 66.597 119.745 66.0959 119.833 65.5948C119.934 65.0395 119.088 64.8025 118.98 65.3578C118.851 66.0485 118.648 66.773 118.641 67.4772C118.634 68.1138 118.919 68.6081 119.291 69.1024C119.643 69.5425 120.408 69.1024 120.063 68.6555Z"
        fill="#2D6A62"
      />
      <Path
        d="M121.945 70.0233C121.458 71.5875 118.655 71.4792 117.889 70.2942C117.585 69.8202 116.82 70.2603 117.131 70.7411C118.33 72.61 122.074 72.5694 122.799 70.2603C122.968 69.7118 122.115 69.4816 121.945 70.0233Z"
        fill="#2D6A62"
      />
      <Path
        d="M117.964 61.5387C117.849 61.1392 117.585 60.7871 117.26 60.5298C116.833 60.198 116.291 60.0219 115.756 59.9745C114.93 59.9 114.07 60.1235 113.4 60.611C112.791 61.0579 111.416 62.7914 112.642 63.2993C112.892 63.4076 113.177 63.4076 113.447 63.4076C114.294 63.4076 115.147 63.4076 115.994 63.4076C116.589 63.4076 117.253 63.3805 117.673 62.9539C117.937 62.6831 118.052 62.2836 118.025 61.9044C118.018 61.7825 117.998 61.6538 117.964 61.5387Z"
        fill="#F67E6A"
      />
      <Path
        d="M120.7 61.5793C120.673 61.9585 120.788 62.358 121.052 62.6289C121.472 63.0555 122.135 63.0826 122.731 63.0826C123.578 63.0826 124.431 63.0826 125.277 63.0826C125.548 63.0826 125.832 63.0826 126.083 62.9742C127.309 62.4596 125.934 60.7261 125.325 60.286C124.654 59.7984 123.794 59.575 122.968 59.6495C122.426 59.6969 121.891 59.8729 121.465 60.2047C121.133 60.4621 120.876 60.8209 120.761 61.2137C120.727 61.3356 120.707 61.4574 120.7 61.5793Z"
        fill="#F67E6A"
      />
      <Path
        d="M99.5321 162.88C99.5321 162.88 75.6629 165.155 60.9621 155.479C60.3594 155.086 59.7635 154.659 59.1947 154.233C55.3621 151.28 52.3285 147.38 50.7846 142.234C46.2071 126.951 57.8608 106.955 68.4175 95.6261C68.6206 95.4026 68.8237 95.1927 69.0268 94.9828C72.9678 90.8319 76.7056 87.69 79.3397 86.0716C85.4137 82.3338 92.9097 81.4738 98.1982 81.4128C98.5571 81.4061 98.9091 81.4061 99.2476 81.4128C102.593 81.4332 104.827 81.7582 104.827 81.7582C104.827 81.7582 102.261 87.9879 101.232 95.9579C101.191 96.2558 101.157 96.5605 101.117 96.872C100.378 103.345 100.724 112.548 104.231 118.744C112.303 132.977 99.5321 162.88 99.5321 162.88Z"
        fill="#E0E7FF"
      />
      <Path
        d="M102.39 142.823C102.485 143.365 101.652 143.595 101.564 143.053C99.7624 131.84 94.1691 121.134 87.567 111.999C83.9172 106.955 79.2788 101.626 73.8887 98.5987C72.0875 97.583 70.2661 96.5876 68.4243 95.6193C68.6274 95.3958 68.8305 95.1859 69.0336 94.976C70.9296 95.9579 72.7918 96.9871 74.6472 98.0367C79.57 100.827 83.9241 105.824 87.3911 110.347C94.5552 119.658 100.521 131.169 102.39 142.823Z"
        fill="#C7D2FE"
      />
      <Path
        d="M79.3669 121.649C77.1391 117.762 71.7693 117.552 67.957 118.872C63.3388 120.47 60.0615 124.357 58.2129 128.765C56.1138 133.776 55.7346 139.417 56.5133 144.76C57.2379 149.75 58.7275 155.641 62.9191 158.878C63.3524 159.21 63.9618 158.607 63.5284 158.269C59.926 155.479 58.538 150.773 57.6848 146.473C56.7097 141.577 56.7164 136.424 58.1248 131.616C59.3301 127.486 61.646 123.524 65.3026 121.127C69.0066 118.696 75.9879 117.477 78.6287 122.082C78.8996 122.563 79.6445 122.129 79.3669 121.649Z"
        fill="#C7D2FE"
      />
      <Path
        d="M78.1412 119.421C78.1412 119.421 58.0437 120.714 61.9169 144.597C64.5104 160.598 96.3429 162.453 101.936 152.344C107.536 142.234 78.1412 119.421 78.1412 119.421Z"
        fill="#A2CAC2"
      />
      <Path
        d="M81.6623 153.779C79.4278 153.447 77.1594 153.048 75.1415 151.985C72.5345 150.61 71.2818 148.294 70.5572 145.511C69.2774 140.609 69.5008 134.42 74.5388 131.745C75.0263 131.488 74.6608 130.709 74.1732 130.966C68.7764 133.824 68.343 140.338 69.7108 145.653C70.3609 148.186 71.4308 150.549 73.6383 152.086C75.9541 153.698 78.8455 154.226 81.5812 154.626C82.1296 154.72 82.2108 153.86 81.6623 153.779Z"
        fill="#2D6A62"
      />
      <Path
        d="M189.917 142.234C180.83 171.574 141.176 162.88 141.176 162.88C141.176 162.88 128.392 132.977 136.47 118.744C139.924 112.649 140.696 103.528 140.459 97.0548C140.452 96.7434 140.431 96.4319 140.425 96.1339C139.991 87.8999 138.007 81.3925 138.007 81.3925C138.007 81.3925 140.059 81.3654 143.14 81.1691C143.465 81.1488 143.804 81.1284 144.156 81.1014C144.163 81.1014 144.163 81.1014 144.176 81.1014C150.155 80.6883 157.888 82.029 164.456 86.0784C166.231 87.1686 168.404 88.9698 170.74 91.3194C170.944 91.5294 171.16 91.7393 171.377 91.9627C181.839 102.77 195.057 125.651 189.917 142.234Z"
        fill="#E0E7FF"
      />
      <Path
        d="M171.363 91.956C166.278 94.9354 162.019 98.206 159.114 103.461C153.331 113.936 150.135 127.668 146.614 139.031C146.451 139.566 145.652 139.248 145.815 138.719C147.826 132.205 149.844 125.691 152.146 119.279C154.218 113.53 156.392 105.932 159.703 100.759C162.351 96.6215 166.474 93.7369 170.72 91.3059C170.937 91.5158 171.147 91.7325 171.363 91.956Z"
        fill="#C7D2FE"
      />
      <Path
        d="M185.969 129.707C181.053 121.209 170.151 114.065 160.164 117.152C159.635 117.315 159.859 118.148 160.394 117.985C170.172 114.958 181.013 122.245 185.556 130.729C188.096 135.476 187.744 140.791 186.877 145.931C185.99 151.159 184.466 156.244 182.394 161.119C182.177 161.62 182.922 162.061 183.139 161.553C185.448 156.122 187.1 150.461 187.947 144.617C188.698 139.444 188.651 134.345 185.969 129.707Z"
        fill="#C7D2FE"
      />
      <Path
        d="M161.653 119.421C161.653 119.421 181.751 120.714 177.878 144.597C175.284 160.598 143.452 162.453 137.858 152.344C132.258 142.234 161.653 119.421 161.653 119.421Z"
        fill="#A2CAC2"
      />
      <Path
        d="M168.025 130.993C167.585 126.829 162.378 125.522 160.407 129.416C160.157 129.91 160.902 130.343 161.152 129.849C162.872 126.45 166.942 127.919 167.199 131.318C167.328 132.957 167.077 134.65 166.678 136.228C165.824 139.607 164.01 142.572 161.498 144.976C161.098 145.362 161.707 145.965 162.107 145.586C165.926 141.936 168.601 136.39 168.025 130.993Z"
        fill="#2D6A62"
      />
      <Path
        d="M164.443 117.599V117.606L164.403 118.459L162.107 162.792C162.039 164.031 161.017 165.006 159.778 165.006H75.8998C74.6606 165.006 73.6382 164.031 73.5705 162.792L71.275 118.459L71.2344 117.606V117.599C71.1734 116.265 72.2297 115.155 73.5637 115.155H162.12C163.448 115.155 164.504 116.265 164.443 117.599Z"
        fill="#35405F"
      />
      <Path
        d="M164.443 117.599V117.606L164.403 118.459H71.2682L71.2208 117.606V117.599H164.443Z"
        fill="#1D2744"
      />
      <Path
        d="M156.886 126.883H142.937C142.226 126.883 141.65 126.307 141.65 125.596V123.47C141.65 122.759 142.226 122.184 142.937 122.184H156.886C157.597 122.184 158.173 122.759 158.173 123.47V125.596C158.173 126.307 157.59 126.883 156.886 126.883ZM142.937 123.01C142.686 123.01 142.477 123.213 142.477 123.47V125.596C142.477 125.847 142.68 126.057 142.937 126.057H156.886C157.137 126.057 157.347 125.854 157.347 125.596V123.47C157.347 123.22 157.143 123.01 156.886 123.01H142.937Z"
        fill="#1D2744"
      />
      <Path
        d="M44.4059 182.09C44.4059 182.09 66.0271 207.463 78.1412 208.506C90.2553 209.549 146.695 212.928 158.958 209.454C171.221 205.98 192.761 176.253 192.761 176.253L126.252 191.239L70.1915 189.999L44.4059 182.09Z"
        fill="#1D2744"
      />
      <Path
        d="M105.694 210.422C105.694 210.422 87.0321 218.304 75.866 218.054C72.5751 217.979 70.09 217.471 68.2482 216.692C67.8148 216.51 67.4288 216.32 67.07 216.11C66.4131 215.751 65.8577 215.325 65.3973 214.878C65.1467 214.634 64.9165 214.39 64.7201 214.126C64.2867 213.557 63.9821 212.961 63.7654 212.359C63.6706 212.074 63.5961 211.79 63.5419 211.512C63.4065 210.815 63.3862 210.124 63.4472 209.474C63.4743 209.257 63.508 209.041 63.5419 208.831C63.5622 208.743 63.5758 208.662 63.6029 208.58C64.5915 205.066 72.0266 204.01 76.6786 202.75C81.4389 201.457 90.3839 195.728 90.3839 195.728L105.694 210.422Z"
        fill="#A2CAC2"
      />
      <Path
        d="M69.0946 208.208C67.2257 208.831 65.3432 209.251 63.4404 209.481C63.4675 209.264 63.5013 209.047 63.5352 208.838C63.5555 208.75 63.5691 208.668 63.5962 208.587C65.3838 208.357 67.158 207.951 68.905 207.375C69.4332 207.192 69.6228 208.039 69.0946 208.208Z"
        fill="#2D6A62"
      />
      <Path
        d="M68.8847 211.174C67.1986 211.722 65.4922 212.129 63.7654 212.372C63.6706 212.088 63.5961 211.803 63.5419 211.526C65.2822 211.296 66.9954 210.889 68.6883 210.327C69.2165 210.158 69.3993 210.998 68.8847 211.174Z"
        fill="#2D6A62"
      />
      <Path
        d="M68.8847 213.97C67.7335 214.35 66.5688 214.661 65.3973 214.884C65.1468 214.641 64.9165 214.397 64.7202 214.133C66.0541 213.903 67.3746 213.564 68.6883 213.124C69.2165 212.955 69.3993 213.794 68.8847 213.97Z"
        fill="#2D6A62"
      />
      <Path
        d="M69.4669 216.32C69.0607 216.455 68.6477 216.577 68.2482 216.699C67.8148 216.516 67.4289 216.327 67.07 216.117C67.8081 215.948 68.5528 215.731 69.2841 215.487C69.8055 215.311 69.9951 216.144 69.4669 216.32Z"
        fill="#2D6A62"
      />
      <Path
        d="M172.399 188.659C161.538 193.033 160.17 200.387 152.133 202.757C144.095 205.127 137.303 205.174 131.073 210.944C127.83 213.957 123.395 216.618 118.756 218.223C118.174 218.433 117.578 218.616 116.982 218.792C113.271 219.848 109.513 220.193 106.188 219.462C103.162 218.798 99.9318 215.623 97.2029 211.58C97.0404 211.336 96.8845 211.099 96.722 210.849C92.4763 204.253 89.612 195.701 90.9731 191.835C91.0476 191.625 102.342 182.829 116.413 172.38C116.664 172.197 116.901 172.021 117.152 171.839C132.035 160.821 149.824 148.125 160.746 141.807C179.631 130.892 199.363 136.525 201.889 154.646C204.395 172.786 183.268 184.284 172.399 188.659Z"
        fill="#2B59E3"
      />
      <Path
        d="M118.749 218.223C118.167 218.433 117.571 218.616 116.975 218.792C114.246 218.182 111.639 217.024 109.249 215.521C106.872 214.024 104.875 212.04 102.038 211.512C100.426 211.214 98.801 211.302 97.2029 211.58C97.0404 211.336 96.8845 211.099 96.722 210.849C100.189 210.273 103.561 210.469 106.73 212.718C110.481 215.365 114.355 217.593 118.749 218.223Z"
        fill="#1D2744"
      />
      <Path
        d="M142.023 193.839L142.016 193.961C142.009 194.191 141.82 194.374 141.583 194.374C141.583 194.374 141.576 194.374 141.562 194.374C141.332 194.367 141.143 194.164 141.149 193.927L141.156 193.805C141.217 192.315 141.305 190.27 140.594 189.045C140.086 188.164 138.908 187.392 137.953 186.769L137.053 186.167C131.107 182.165 121.431 175.685 116.407 172.38C116.657 172.197 116.894 172.021 117.145 171.839C122.25 175.197 131.69 181.522 137.533 185.456L138.427 186.052C139.45 186.722 140.736 187.569 141.339 188.618C142.179 190.047 142.091 192.248 142.023 193.839Z"
        fill="#1D2744"
      />
      <Path
        d="M172.481 212.894C172.42 213.185 172.338 213.456 172.237 213.72C172.007 214.329 171.668 214.871 171.242 215.345C171.011 215.602 170.754 215.832 170.483 216.049C169.779 216.611 168.939 217.051 168.025 217.349C167.565 217.519 167.077 217.647 166.583 217.735C165.188 217.999 163.685 218.033 162.276 217.803C157.455 217.045 154.699 216.009 150.162 214.262C146.871 212.995 143.607 211.539 140.249 210.449C136.768 209.318 123.659 205.939 123.659 205.939L128.006 188.029C128.006 188.029 138.982 192.309 140.933 193.162C142.89 194.015 149.201 193.629 152.884 195.105C157.076 196.784 157.475 198.376 161.057 200.78C165.75 203.922 171.106 206.914 171.641 208.039C171.953 208.682 172.169 209.291 172.325 209.867C172.413 210.178 172.481 210.483 172.515 210.788C172.637 211.56 172.609 212.257 172.481 212.894Z"
        fill="#A2CAC2"
      />
      <Path
        d="M144.142 195.112C142.151 194.726 140.154 194.34 138.163 193.954C137.621 193.846 137.445 194.692 137.987 194.8L143.966 195.958C144.515 196.06 144.691 195.213 144.142 195.112Z"
        fill="#2D6A62"
      />
      <Path
        d="M172.515 210.795C170.801 210.422 169.129 209.853 167.558 208.973C167.077 208.702 167.456 207.93 167.937 208.194C169.352 208.98 170.822 209.508 172.325 209.874C172.413 210.192 172.481 210.497 172.515 210.795Z"
        fill="#2D6A62"
      />
      <Path
        d="M172.481 212.894C172.42 213.185 172.338 213.456 172.237 213.72C170.591 213.347 168.987 212.785 167.463 211.939C166.982 211.668 167.362 210.903 167.849 211.174C169.325 212 170.889 212.535 172.481 212.894Z"
        fill="#2D6A62"
      />
      <Path
        d="M171.235 215.345C171.004 215.602 170.747 215.832 170.476 216.049C169.034 215.677 167.639 215.155 166.298 214.41C165.818 214.14 166.197 213.374 166.678 213.645C168.133 214.458 169.67 214.993 171.235 215.345Z"
        fill="#2D6A62"
      />
      <Path
        d="M168.025 217.356C167.565 217.525 167.077 217.654 166.583 217.742C165.973 217.498 165.377 217.207 164.795 216.889C164.314 216.618 164.694 215.853 165.174 216.124C166.095 216.638 167.05 217.038 168.025 217.356Z"
        fill="#2D6A62"
      />
      <Path
        d="M140.54 188.875C140.032 187.765 137.384 186.526 136.436 185.855C132.969 183.391 129.469 180.98 125.941 178.61C116.041 171.96 105.931 165.555 95.4557 159.84C82.9895 153.048 65.2213 143.487 50.6221 146.534C40.5936 148.633 35.7317 153.163 35.6707 162.954C35.5556 181.237 58.7749 194.381 68.9049 197.049C79.0282 199.716 80.8295 209.826 88.2171 210.943C95.6047 212.061 101.888 208.039 108.071 212.792C114.253 217.546 123.401 221.419 130.308 218.46C137.202 215.501 143.296 194.895 140.54 188.875Z"
        fill="#2B59E3"
      />
      <Path
        d="M101.239 95.9579C101.198 96.2558 101.164 96.5605 101.123 96.872C99.7624 96.6079 98.4013 96.3235 97.0538 96.012C94.9614 95.5313 93.7562 94.6578 94.0677 92.3555C94.5755 88.5432 96.1263 84.7918 98.2051 81.4128C98.564 81.4061 98.916 81.4061 99.2545 81.4128C97.5684 84.1146 96.2752 87.0399 95.4423 90.1412C94.9615 91.9086 94.3928 94.4004 96.6883 95.0573C97.9545 95.4161 99.3155 95.5854 100.616 95.8428L101.239 95.9579Z"
        fill="#C7D2FE"
      />
      <Path
        d="M145.49 96.012C143.817 96.398 142.145 96.7366 140.452 97.0548C140.445 96.7434 140.425 96.4319 140.418 96.1339L141.935 95.8428C143.228 95.5854 144.589 95.4229 145.862 95.0573C148.158 94.4004 147.582 91.9086 147.108 90.1412C146.241 86.9451 144.894 83.9386 143.133 81.1623C143.458 81.142 143.797 81.1217 144.149 81.0946C146.329 84.548 147.961 88.4213 148.49 92.3555C148.794 94.6578 147.582 95.5313 145.49 96.012Z"
        fill="#C7D2FE"
      />
    </Svg>
  );
};
