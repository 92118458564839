import { ScrollView } from 'native-base';
// @ts-expect-error - This error is a lie, it just isn't included in the typescript definitions.
import { listenToKeyboardEvents } from 'react-native-keyboard-aware-scroll-view';

export const LayoutScrollView: LayoutScrollViewType = listenToKeyboardEvents({})(ScrollView);

export type LayoutScrollViewType = typeof ScrollView & {
  scrollToPosition: (x: number, y: number, animated?: boolean) => void;
  scrollToEnd: (animated?: boolean) => void;
  scrollForExtraHeightOnAndroid: (extraHeight: number) => void;
};
