import { map, values } from 'lodash';
import { CoachingAssessments, useSubmitCoachingAssessmentV2Mutation } from '../../../graphQL';
import { useCoachingAssessmentContext } from '../contexts/coachingAssessmentContext';

type UseSaveCoachingAssessmentReturn = [
  saveAnswers: () => Promise<void>,
  info: {
    isSaving: boolean;
  },
];

export const useSaveCoachingAssessmentLazy = ({
  assessmentType,
  onCompleted,
  onError,
}: {
  assessmentType: CoachingAssessments;
  onCompleted?: () => void;
  onError?: () => void;
}): UseSaveCoachingAssessmentReturn => {
  const { answersByAssessment } = useCoachingAssessmentContext();

  const [submitAssessment, { loading }] = useSubmitCoachingAssessmentV2Mutation({
    onCompleted,
    onError,
  });

  const saveAnswers = async (): Promise<void> => {
    const submissions = map(answersByAssessment, (answers, questionnaireKey) => {
      return {
        questionnaireKey,
        answers: values(answers),
      };
    });

    void submitAssessment({
      variables: {
        assessmentType,
        submissions,
      },
    });
  };

  return [saveAnswers, { isSaving: loading }];
};
