import Svg, { Path } from 'react-native-svg';

export const ImageCutRibbon = (): JSX.Element => {
  return (
    <Svg
      title="Scissors cutting a ribbon"
      width="138"
      height="114"
      viewBox="0 0 138 114"
      aria-hidden={true}
    >
      <Path
        d="M103.051 30.2465L113.431 12.3561C113.431 12.3561 105.834 11.3808 95.6406 13.7091C84.8308 16.1809 80.0211 17.6917 74.762 13.5513C69.7802 9.62616 59.0947 -0.88243 44.0872 0.0594238C43.2457 0.112015 42.9446 1.20207 43.6378 1.68973C46.9176 3.99416 52.6356 8.70822 49.6618 11.7967C47.3335 14.2159 42.366 16.8168 38.546 18.6001C37.6616 19.0112 37.9771 20.3356 38.9524 20.3117C43.0736 20.2017 48.7152 20.6368 52.9273 23.1946C60.9354 28.0569 65.0566 32.5032 74.1214 33.0578C83.2865 33.6171 86.3416 30.8872 93.1927 30.3326C100.053 29.7732 103.051 30.2465 103.051 30.2465Z"
        fill="#F67E6A"
      />
      <Path
        d="M77.7358 27.3062C79.4283 24.1078 81.1925 20.9523 83.0332 17.8351C83.3439 17.3044 82.5216 16.8215 82.2061 17.3522C80.3702 20.4694 78.6059 23.6249 76.9087 26.8234C76.6218 27.3684 77.4442 27.8513 77.7358 27.3062Z"
        fill="#35405F"
      />
      <Path
        d="M72.754 29.5102C73.6385 27.6696 74.5612 25.848 75.57 24.0695C75.876 23.534 75.0489 23.0511 74.7429 23.5866C73.7341 25.3651 72.8114 27.1867 71.9269 29.0274C71.664 29.582 72.4863 30.0648 72.754 29.5102Z"
        fill="#35405F"
      />
      <Path
        d="M0.422427 65.7454L17.9017 63.8856C17.9017 63.8856 30.012 43.007 46.1621 42.3711C62.3123 41.7353 59.3385 52.9945 59.3385 52.9945L50.3646 65.9892C50.3646 65.9892 74.1022 67.8442 78.6728 71.8794C83.2435 75.9145 78.3525 85.5052 78.3525 85.5052C78.3525 85.5052 83.2721 90.9507 82.0291 96.774C79.8633 106.9 71.7786 104.213 71.7786 104.213C71.7786 104.213 72.9357 112.599 63.2589 113.952C57.3257 114.779 33.832 104.634 33.832 104.634L6.8481 107.608C6.8481 107.603 -1.54256 88.9044 0.422427 65.7454Z"
        fill="#A2CAC2"
      />
      <Path
        d="M51.4021 110.491C47.9024 108.808 43.6808 106.403 42.519 102.387C42.2656 101.517 42.1796 100.594 42.323 99.6951C42.9302 95.8656 46.3151 95.2727 49.5614 95.5404C50.1734 95.593 50.1734 94.6368 49.5614 94.5842C46.5064 94.3309 43.5134 94.5651 41.9979 97.6106C41.3859 98.8393 41.1851 100.221 41.3716 101.574C42.0361 106.412 46.9366 109.401 50.9192 111.318C51.469 111.585 51.9567 110.758 51.4021 110.491Z"
        fill="#35405F"
      />
      <Path
        d="M46.6976 91.8304C45.1008 90.0949 44.7231 87.475 45.8084 85.3618C46.9701 83.1003 49.2459 82.2206 51.6603 82.1824C52.277 82.1728 52.277 81.2166 51.6603 81.2262C48.6865 81.2692 45.904 82.5696 44.7278 85.4382C43.743 87.8287 44.288 90.6161 46.0235 92.5045C46.4394 92.9587 47.1136 92.2846 46.6976 91.8304Z"
        fill="#35405F"
      />
      <Path
        d="M45.9661 92.4424C49.461 96.0807 54.2754 98.4425 58.8317 100.455C62.8095 102.21 67.0168 103.945 71.3149 104.72C71.9173 104.83 72.1755 103.907 71.5683 103.797C67.3562 103.037 63.2063 101.349 59.3098 99.6282C54.8348 97.6488 50.0682 95.3397 46.6354 91.7683C46.2147 91.3236 45.5406 91.9977 45.9661 92.4424Z"
        fill="#35405F"
      />
      <Path
        d="M51.6602 82.1728C56.3073 82.0007 61.0788 83.7219 65.4677 85.0557C66.0558 85.2374 66.3091 84.3147 65.7211 84.133C61.2413 82.7704 56.403 81.0397 51.6602 81.2118C51.0483 81.2358 51.0435 82.192 51.6602 82.1728Z"
        fill="#35405F"
      />
      <Path
        d="M74.0018 89.8941C80.1831 89.8941 85.1941 84.8832 85.1941 78.7018C85.1941 72.5205 80.1831 67.5095 74.0018 67.5095C67.8204 67.5095 62.8094 72.5205 62.8094 78.7018C62.8094 84.8832 67.8204 89.8941 74.0018 89.8941Z"
        fill="#35405F"
      />
      <Path
        d="M54.1415 63.2306C60.3228 63.2306 65.3338 58.2196 65.3338 52.0383C65.3338 45.857 60.3228 40.846 54.1415 40.846C47.9602 40.846 42.9492 45.857 42.9492 52.0383C42.9492 58.2196 47.9602 63.2306 54.1415 63.2306Z"
        fill="#35405F"
      />
      <Path
        d="M75.3644 69.0538L113.431 3.7312C113.431 3.7312 97.4286 6.99661 88.6555 26.6512C79.8872 46.3011 70.9515 68.9247 70.9515 68.9247L75.3644 69.0538Z"
        fill="#35405F"
      />
      <Path
        d="M63.9761 48.1896L137.876 29.1134C137.876 29.1134 130.384 43.6285 109.094 46.7314C87.7997 49.8342 64.6502 50.9625 64.6502 50.9625L63.9761 48.1896Z"
        fill="#35405F"
      />
      <Path
        d="M86.6379 45.7704C86.6379 46.9417 85.6865 47.8932 84.5151 47.8932C83.3438 47.8932 82.3923 46.9417 82.3923 45.7704C82.3923 44.5991 83.3438 43.6476 84.5151 43.6476C85.6865 43.6476 86.6379 44.5991 86.6379 45.7704Z"
        fill="#FAEAD3"
      />
      <Path
        d="M69.4647 73.5957C66.5387 75.7615 61.7386 81.1067 62.2549 85.5625C62.8908 91.0893 68.542 91.3714 71.0902 90.2814C72.5341 89.6598 78.8067 85.9402 80.743 82.3258C82.5215 79.003 80.8243 75.0348 79.0314 73.5623C77.0043 71.8889 72.6201 71.2626 69.4647 73.5957Z"
        fill="#A2CAC2"
      />
      <Path
        d="M49.681 58.1771C49.681 58.1771 60.7298 65.076 65.9793 61.1508C71.2289 57.2209 68.6901 52.7459 66.7969 50.6088C65.7546 49.4326 58.3394 44.2548 54.2421 44.2501C50.9193 44.2501 48.8921 46.478 48.0172 48.3761C46.4777 51.7227 46.7502 55.5762 49.681 58.1771Z"
        fill="#A2CAC2"
      />
      <Path
        d="M31.7618 92.2703C42.5047 88.1156 50.0634 77.8747 50.8427 66.3764C50.8857 65.7645 49.9295 65.7645 49.8865 66.3764C49.1406 77.4157 41.8305 87.3554 31.5084 91.3475C30.9395 91.5675 31.1833 92.4902 31.7618 92.2703Z"
        fill="#35405F"
      />
      <Path
        d="M31.7186 99.6856C31.843 101.297 32.1968 102.841 32.8039 104.337C33.0334 104.902 33.9562 104.653 33.7267 104.084C33.153 102.669 32.7944 101.211 32.6748 99.6856C32.6318 99.0784 31.6708 99.0688 31.7186 99.6856Z"
        fill="#35405F"
      />
      <Path
        d="M62.2692 80.6716C60.9162 83.5354 60.7202 87.102 62.9433 89.6264C65.0613 92.0312 68.4988 92.2464 71.2001 90.7977C71.7404 90.5061 71.2622 89.679 70.7172 89.9706C68.4128 91.2089 65.4056 91.0511 63.6175 88.9523C61.7338 86.7339 61.9154 83.6454 63.0915 81.1545C63.3545 80.5999 62.5321 80.1122 62.2692 80.6716Z"
        fill="#35405F"
      />
      <Path
        d="M62.2454 85.553C64.1817 83.3776 66.859 81.2931 69.7802 80.7194C72.6727 80.1504 74.7811 82.2971 73.8392 85.2087C73.2512 87.0207 71.8982 88.6845 70.6169 90.0471C70.1961 90.4965 70.8702 91.1706 71.2909 90.7212C73.4854 88.3881 77.5971 82.5314 73.2607 80.117C69.3594 77.9464 63.9761 82.1728 61.5664 84.8788C61.1601 85.333 61.8342 86.0119 62.2454 85.553Z"
        fill="#35405F"
      />
      <Path
        d="M44.6609 88.3164C48.6243 89.0287 55.3751 91.73 52.8746 96.86C52.6069 97.4098 53.4293 97.8975 53.7018 97.3429C56.5226 91.5531 49.6379 88.2399 44.9143 87.3937C44.3167 87.2885 44.0585 88.2112 44.6609 88.3164Z"
        fill="#35405F"
      />
      <Path
        d="M42.3326 102.951C45.7749 103.573 51.8371 105.456 51.1009 109.993C51.0053 110.596 51.9232 110.854 52.0236 110.247C52.8651 105.059 46.6689 102.765 42.586 102.028C41.9836 101.918 41.7254 102.841 42.3326 102.951Z"
        fill="#35405F"
      />
      <Path
        d="M112.245 24.9492L132.751 18.7865C132.751 18.7865 134.228 25.1548 134.237 32.2785C134.252 45.7704 132.005 50.2358 125.603 60.8831C119.555 70.9423 123.887 82.4023 128.376 90.4009C129.036 91.5722 127.635 92.8201 126.55 92.0264C121.52 88.3403 114.32 83.4876 111.289 83.4876C108.224 83.4876 107.33 87.7044 107.091 90.8503C107 92.0169 105.523 92.4519 104.834 91.5101C102.492 88.3164 99.1451 82.6892 99.2837 76.7942C99.4941 67.763 105.748 62.0975 109.482 55.9683C113.431 49.49 115.171 39.7368 112.245 24.9492Z"
        fill="#F67E6A"
      />
      <Path
        d="M112.288 62.8672C115.271 62.8768 118.25 62.8911 121.233 62.9007C121.85 62.9055 121.85 61.9493 121.233 61.9445C118.25 61.9349 115.271 61.9206 112.288 61.911C111.671 61.911 111.671 62.8672 112.288 62.8672Z"
        fill="#35405F"
      />
      <Path
        d="M106.876 65.8171C109.003 65.7836 111.131 65.7693 113.258 65.6975C113.87 65.6784 113.875 64.7222 113.258 64.7413C111.131 64.8131 109.003 64.8274 106.876 64.8609C106.264 64.8704 106.259 65.8266 106.876 65.8171Z"
        fill="#35405F"
      />
    </Svg>
  );
};
