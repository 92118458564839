import { createIcon, Path } from './index';

export const IconChevronLeft = createIcon({
  title: 'A left chevron',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M15 18L9 12L15 6"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
