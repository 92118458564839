import { createIcon, Path } from './index';

export const IconSend = createIcon({
  title: 'Send.',
  viewBox: '0 0 24 24',
  path: [
    <Path d="M22 2L11 13" fill="none" stroke="currentColor" />,
    <Path
      d="M22 2L15 22L11 13L2 9L22 2Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
