import { Avatar } from 'native-base';
import React from 'react';
import { Button, Heading, Layout, Text } from '../../../components/core';
import { IconArrowLeft } from '../../../components/icons/IconArrowLeft';
import { IconUser } from '../../../components/icons/IconUser';
import { useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';

type ChatHeaderProps = {
  coachName: string;
  coachAvatarUrl: string;
};

export const ChatHeader = ({ coachName, coachAvatarUrl }: ChatHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  const onBackPress = (): void => {
    // -1 is equivalent to hitting the browser back button
    navigate(-1);
  };

  return (
    <Layout.HStack {...styles.header}>
      <Button.tertiaryMedium
        accessibilityLabel="Back"
        testID="pressable-chat-header-back"
        onPress={onBackPress}
      >
        <IconArrowLeft size={5} accessibilityHidden />
      </Button.tertiaryMedium>

      <Layout.HStack {...styles.coachInfo} space={2}>
        {coachAvatarUrl ? (
          <Avatar source={{ uri: coachAvatarUrl }} />
        ) : (
          <Layout.View {...styles.iconUserWrapper}>
            <IconUser {...styles.iconUser} size={10} />
          </Layout.View>
        )}

        <Layout.VStack space={1} flex={1}>
          <Heading.h3>{coachName}</Heading.h3>

          <Text.para>Your Coach</Text.para>
        </Layout.VStack>
      </Layout.HStack>
    </Layout.HStack>
  );
};

const styles = getStylesheet({
  header: {
    backgroundColor: 'secondary.50',
    paddingY: 3,
    shadow: 'small',
  },

  coachInfo: {
    alignItems: 'center',
    flex: 1,
  },

  iconUser: {
    color: 'secondary.300',
  },

  iconUserWrapper: {
    backgroundColor: 'secondary.100',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'secondary.300',
    overflow: 'hidden',
  },
});
