import { StatusFeedback } from '../../../components/core';
import { IconHelpCircle } from '../../../components/icons/IconHelpCircle';
import type { JSX } from 'react';

export const PatientPortalReauthInfo = (): JSX.Element => {
  return (
    <StatusFeedback.info Icon={IconHelpCircle} testID="patient-portal-reauth-info">
      Please re-authenticate to continue to the Mantra Patient Portal.
    </StatusFeedback.info>
  );
};
