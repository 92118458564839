export const progressStyles = {
  Progress: {
    variants: {
      skill: () => progressVariantSkill,
    },
  },
};

export const progressVariantSkill = {
  backgroundColor: 'secondary.alpha.20:alpha.20',

  _filledTrack: {
    backgroundColor: 'secondary.900',
  },

  _dark: {
    backgroundColor: 'secondary.alpha.20:alpha.20',

    _filledTrack: {
      backgroundColor: `secondary.400`,
    },
  },
};
