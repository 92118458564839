import * as Linking from 'expo-linking';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-native';
import { setRedirectUrl } from '../../utils/authenticationStore';

export const useURLChange = (): {
  handleURLChange: (event: { url: string }, setUrl: boolean) => void;
} => {
  const navigate = useNavigate();
  const handleURLChange = (event: { url: string }, setUrl: boolean): void => {
    if (isEmpty(event.url)) {
      return;
    }

    const parsedRoute = Linking.parse(event.url);

    if (parsedRoute.path == null) {
      return;
    }

    let parsedURL = `/${parsedRoute.path}`;
    if (parsedRoute.hostname != null) {
      parsedURL = `/${parsedRoute.hostname}` + parsedURL;
    }

    if (setUrl) {
      void setRedirectUrl(parsedURL);
    }

    navigate(parsedURL);
  };

  return { handleURLChange };
};
