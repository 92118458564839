import { defaultTo } from 'lodash';
import { Divider } from 'native-base';
import { Heading, Layout, Text } from '../../../../components/core';
import { ModuleDocument } from '../../../module/ModuleTypes';
import { TopicModuleBox } from '../../../view/components/slice/ViewModules/TopicModuleBox';
import { View } from '../../../view/components/View';
import { SkillBox } from '../../SkillBox';
import { SkillDocument, SkillRatingValue } from '../../SkillTypes';

type UpNextProps = {
  isIntro?: boolean;
  isPathway?: boolean;
  isVisible?: boolean;
  module: ModuleDocument;
  skillRating?: SkillRatingValue;
  upNextSkill: SkillDocument;
  upNextSkillIsCompleted: boolean;
};

export const UpNext = ({
  isIntro = false,
  isPathway = false,
  isVisible = true,
  module,
  skillRating = 0,
  upNextSkill,
  upNextSkillIsCompleted,
}: UpNextProps): JSX.Element => {
  const type = defaultTo(module.data.type, 'topic');

  const isCollection = isIntro || type === 'collection';

  if (isIntro && skillRating === 1) {
    return (
      <Layout.VStack space={8}>
        <Layout.VStack space={4}>
          <Heading.h2 center>Keep the vibes going.</Heading.h2>

          <Text.para center>
            If you liked that skill, check out the rest of our Mindfulness guides.
          </Text.para>
        </Layout.VStack>

        <TopicModuleBox module={module} showContinue />

        <Divider backgroundColor="secondary.alpha.10:alpha.10" marginTop={8} />

        <Heading.h4 center>{isCollection ? 'Collections' : 'Topics'} you might like</Heading.h4>

        <View isCollection={isCollection} omitModuleId={module.id} hideHeader />
      </Layout.VStack>
    );
  }

  if (isIntro && skillRating === -1) {
    return (
      <Layout.VStack space={8}>
        <Heading.h2 center>Try something different.</Heading.h2>

        <View isCollection={isCollection} omitModuleId={module.id} hideHeader />
      </Layout.VStack>
    );
  }

  return (
    <Layout.VStack space={8}>
      <Heading.h2 center>Up Next</Heading.h2>

      <SkillBox
        horizontal
        isDisabled={!isVisible}
        navToPathwaySkill={isPathway}
        moduleId={module.id}
        skill={upNextSkill}
        skillIsCompleted={upNextSkillIsCompleted}
      />

      <Divider backgroundColor="secondary.alpha.10:alpha.10" />

      <Heading.h5 center>
        {isIntro
          ? 'Collections you might like'
          : `Other ${isCollection ? 'collections' : 'topics'} you might like`}
      </Heading.h5>

      {isVisible && <View isCollection={isCollection} omitModuleId={module.id} hideHeader />}
    </Layout.VStack>
  );
};
