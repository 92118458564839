import { ComponentProps, ReactNode } from 'react';
import { getStylesheet } from '../../styles';
import { Layout, Text } from '../core';

type LayoutViewProps = ComponentProps<typeof Layout.View>;

type FormErrorSubmitProps = {
  children: ReactNode;
} & LayoutViewProps;

export const FormErrorSubmit = ({ children, ...props }: FormErrorSubmitProps): JSX.Element => {
  return (
    <Layout.View {...styles.topView} {...props}>
      <Text.para flex={1}>{children}</Text.para>
    </Layout.View>
  );
};

const styles = getStylesheet({
  topView: {
    backgroundColor: 'danger.alpha.20:alpha.20',
    borderColor: 'danger.alpha.40:alpha.40',
    borderRadius: 4,
    borderWidth: 1,
    padding: 4,
  },
});
