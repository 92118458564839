import React from 'react';
import { Button, Heading, Layout, Text } from '../../components/core';
import { IconChevronRight } from '../../components/icons/IconChevronRight';
import { SkillWrapper } from '../../content/skill/components/SkillWrapper';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { ImageCutRibbon } from './images/ImageCutRibbon';

export const TourFinish = ({ isVisible }: { isVisible: boolean }): JSX.Element => {
  const navigate = useNavigate();

  const assessmentPress = (): void => {
    navigate(getRoute('findCareStartCare', {}));
  };

  const homepagePress = (): void => {
    navigate(getRoute('home', {}));
  };

  return (
    <SkillWrapper isVisible={isVisible} hideContinueButton>
      <Layout.VStack {...styles.topStack} space={12}>
        <ImageCutRibbon />

        <Layout.VStack space={4}>
          <Heading.h2 center>Get started with Mantra Health</Heading.h2>

          <Text.para center>
            Complete the Care Assessment, a brief questionnaire that will recommend the right level
            of care to suit your needs. It should take less than 5 minutes to complete.
          </Text.para>
        </Layout.VStack>

        <Layout.VStack space={4} width="100%">
          <Button.skillLarge
            isDisabled={!isVisible}
            isLink
            onPress={assessmentPress}
            testID="button-tour-finish-go-to-assessment"
          >
            Take the Assessment
          </Button.skillLarge>

          <Button.secondaryLarge
            isDisabled={!isVisible}
            isLink
            onPress={homepagePress}
            rightIcon={<IconChevronRight />}
            testID="button-tour-finish-go-to-homepage"
          >
            Go to Dashboard
          </Button.secondaryLarge>
        </Layout.VStack>
      </Layout.VStack>
    </SkillWrapper>
  );
};

const styles = getStylesheet({
  topStack: {
    alignItems: 'center',
    paddingX: 6,
  },
});
