import { JSX } from 'react';
import { Path, Svg } from '../icons';

type ImageMantraHealthProps = { accessibilityLabel?: string; height?: number; reversed?: boolean };

const calculateWidthFromHeight = (height = 14): number => {
  const ratio = 113 / 14; // Defined by the view box dimensions.

  return Math.round(ratio * height);
};

export const ImageMantraHealth = ({ reversed, ...props }: ImageMantraHealthProps): JSX.Element => {
  const fillColor = reversed === true ? 'white' : '#1D2744';

  return (
    <Svg
      color={fillColor}
      aria-label="Mantra Health"
      role="img"
      viewBox="0 0 113 14"
      width={calculateWidthFromHeight(props.height)}
      {...props}
    >
      <Path
        clipRule="evenodd"
        d="M11.7871 13.6425V13.5715C12.769 13.1603 13.0441 12.8018 13.0441 11.5117C13.0441 11.1715 13.0282 10.742 12.9929 10.238L12.5628 3.1325L8.2595 13.6425H7.37802L3.38853 3.04334H3.33739L2.8967 10.0451C2.86316 10.5273 2.84556 10.9386 2.84556 11.297C2.84556 12.6399 3.13819 13.1948 4.18716 13.5715V13.6425H0.428589V13.5715C1.70145 13.1421 1.80545 12.3342 1.97819 10.0451L2.36779 4.54814C2.40305 4.04593 2.43653 3.61651 2.43653 3.22347C2.43653 2.20269 2.16156 1.80783 1.18133 1.36021V1.28925H4.98927L8.56979 10.7966L12.4482 1.28925H15.974V1.36021C14.9903 1.78963 14.7082 2.21906 14.7082 3.29444C14.7082 3.65836 14.7253 4.07019 14.7594 4.52995L15.1225 10.0615C15.2988 12.609 15.3623 13.0711 16.584 13.5897V13.6425H11.7871Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M22.1302 9.07711C21.9602 9.04151 21.7871 9.02386 21.6137 9.02431C20.5294 9.02431 19.3589 9.66848 19.3589 11.1187C19.3589 12.174 20.1681 12.729 21.0283 12.729C21.414 12.7211 21.7921 12.6168 22.1302 12.4252V9.07711ZM25.7442 13.1603C25.4004 13.375 24.2122 13.8044 23.507 13.8044C22.8353 13.8044 22.2324 13.4277 22.1478 12.6399C21.596 13.1421 20.6317 13.8572 19.514 13.8572C18.257 13.8572 17.2769 12.9983 17.2769 11.6919C17.2769 10.2762 18.6184 8.89877 22.1302 8.54031V7.44854C22.1302 5.99288 21.3897 4.92294 20.064 4.92294C19.5369 4.93557 19.0243 5.10351 18.5867 5.407C19.172 5.53254 19.6198 5.94374 19.6198 6.51694C19.6225 6.65728 19.5975 6.79677 19.5464 6.92688C19.4953 7.057 19.4192 7.17506 19.3226 7.27386C19.226 7.37271 19.111 7.45031 18.9845 7.50191C18.858 7.55351 18.7227 7.57808 18.5867 7.57408C18.311 7.5742 18.0461 7.46317 17.8486 7.26471C17.651 7.0662 17.5363 6.79597 17.5289 6.51146C17.5289 5.33054 19.4682 4.29156 21.247 4.29156C23.0257 4.29156 24.191 5.20134 24.191 7.1374V11.388C24.191 12.8437 24.8098 12.9983 25.7389 13.0875L25.7442 13.1603Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M31.4965 13.6425V13.5715C32.4588 13.2076 32.6674 12.6399 32.6674 11.206V7.30483C32.6674 6.10568 31.9445 5.48523 31.0137 5.48523C30.3502 5.51454 29.7022 5.70134 29.12 6.03111V11.2079C29.12 12.6399 29.328 13.2094 30.3085 13.5733V13.6443H25.8852V13.5715C26.8674 13.2076 27.0731 12.6399 27.0731 11.206V7.60323C27.0731 6.02746 26.8971 5.63443 25.6965 5.1686V5.09763C26.7982 5.02489 28.1754 4.61363 29.0177 4.29156H29.0868V5.78363C29.7405 5.15586 31.0137 4.31522 32.168 4.31522C33.492 4.31522 34.7314 5.10311 34.7314 7.40854V11.2079C34.7314 12.6399 34.9377 13.2094 35.9022 13.5733V13.6443L31.4965 13.6425Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M42.3422 12.3542C41.9628 12.8546 41.0514 13.7898 39.8634 13.7898C38.5571 13.7898 37.5577 13.0748 37.5577 11.0604V5.44332H36.3022V5.35235L37.0588 4.92292C38.1782 4.27881 38.8302 3.2944 39.5371 2.29181H39.6234V4.50989H41.896V5.47612H39.6234V10.9567C39.6234 11.9593 40.1525 12.6053 41.0514 12.6053C41.4811 12.6092 41.904 12.4915 42.2731 12.265L42.3422 12.3542Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M46.6451 5.55071C46.6428 5.38786 46.672 5.22614 46.7302 5.07483C46.7885 4.92357 46.8754 4.78568 46.9857 4.66916C47.096 4.55265 47.2274 4.45979 47.3725 4.39592C47.5182 4.33206 47.6737 4.29844 47.832 4.29702C48.1497 4.30323 48.4525 4.43817 48.6748 4.67271C48.8971 4.90729 49.0211 5.2226 49.02 5.55071C49.0217 5.71266 48.992 5.87334 48.9331 6.0234C48.8742 6.17346 48.7868 6.30997 48.676 6.42488C48.5657 6.53986 48.4342 6.63094 48.2891 6.693C48.144 6.75506 47.9885 6.78671 47.832 6.7862C47.6742 6.7882 47.5182 6.75751 47.3731 6.69603C47.2274 6.63449 47.0954 6.5434 46.9845 6.42808C46.8742 6.31283 46.7868 6.17574 46.7285 6.025C46.6702 5.87431 46.6422 5.713 46.6451 5.55071ZM42.668 13.6425V13.5715C43.6325 13.2076 43.8388 12.6399 43.8388 11.206V7.60323C43.8388 6.02746 43.6622 5.63443 42.4617 5.1686V5.09763C43.5811 5.02489 44.9914 4.61363 45.836 4.29156H45.912V11.206C45.912 12.638 46.1182 13.193 47.2885 13.5715V13.6425H42.668Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M54.1308 9.07709C53.9605 9.0416 53.7874 9.02395 53.6142 9.02429C52.5297 9.02429 51.3594 9.66846 51.3594 11.1186C51.3594 12.174 52.1685 12.729 53.0285 12.729C53.4142 12.721 53.7925 12.6167 54.1308 12.4251V9.07709ZM57.7445 13.1602C57.4011 13.375 56.2125 13.8044 55.5074 13.8044C54.836 13.8044 54.2331 13.4277 54.1485 12.6398C53.5965 13.1421 52.632 13.8571 51.5142 13.8571C50.2577 13.8571 49.2771 12.9983 49.2771 11.6918C49.2771 10.2762 50.6188 8.89875 54.1308 8.54029V7.44852C54.1308 5.99286 53.3902 4.92292 52.0645 4.92292C51.5365 4.9352 51.0234 5.10315 50.5857 5.40697C51.1708 5.53252 51.6165 5.94372 51.6165 6.51692C51.6194 6.6572 51.5948 6.79657 51.544 6.92669C51.4931 7.0568 51.4171 7.17492 51.3205 7.27377C51.2245 7.37263 51.1091 7.45023 50.9828 7.50189C50.8565 7.55349 50.7211 7.57806 50.5857 7.57406C50.3108 7.57423 50.0462 7.46395 49.8491 7.26663C49.6514 7.06932 49.5365 6.8004 49.5274 6.51692C49.5274 5.336 51.4668 4.297 53.2457 4.297C55.0245 4.297 56.1897 5.2068 56.1897 7.14286V11.3879C56.1897 12.8437 56.8085 12.9983 57.7377 13.0874L57.7445 13.1602Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M69.7663 13.6425V13.5715C70.9349 13.1948 71.1766 12.6235 71.1766 11.1896V7.68147H65.3589V11.1896C65.3589 12.6235 65.6006 13.1912 66.7532 13.5715V13.6425H61.7623V13.5715C62.9315 13.1948 63.1726 12.6235 63.1726 11.1896V3.72933C63.1726 2.3155 62.9315 1.74233 61.7623 1.36385V1.28925H66.7532V1.36021C65.6006 1.73687 65.3589 2.31004 65.3589 3.72569V6.94639H71.1766V3.72386C71.1766 2.31004 70.9349 1.73687 69.7663 1.35839V1.28925H74.7572V1.36021C73.604 1.73687 73.3623 2.31004 73.3623 3.72569V11.186C73.3623 12.6199 73.604 13.1876 74.7572 13.5679V13.6388L69.7663 13.6425Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M80.7366 8.00349C80.7366 5.85457 80.2537 4.78103 79.136 4.78103C78.0674 4.78103 77.3291 6.15846 77.2251 8.23823L80.7366 8.00349ZM82.7571 11.5845C82.3268 12.3359 81.1051 13.8571 79.1943 13.8571C76.8017 13.8571 75.0634 12.0375 75.0634 9.29177C75.0634 6.33857 77.0771 4.297 79.2806 4.297C81.1737 4.297 82.6303 5.2632 82.6303 8.03989V8.62943H77.2251C77.2251 11.1769 78.6354 12.4251 80.2217 12.4251C81.1068 12.4124 81.9617 12.0918 82.648 11.5153L82.7571 11.5845Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M88.6189 9.07711C88.448 9.04151 88.2743 9.02386 88.1006 9.02431C87.016 9.02431 85.8474 9.66848 85.8474 11.1187C85.8474 12.174 86.6549 12.729 87.5171 12.729C87.9023 12.7208 88.2806 12.6166 88.6189 12.4252V9.07711ZM92.2326 13.1603C91.88 13.375 90.7006 13.8044 89.9937 13.8044C89.324 13.8044 88.7211 13.4277 88.6349 12.6399C88.0846 13.1421 87.12 13.8572 86.0029 13.8572C84.7457 13.8572 83.7634 12.9983 83.7634 11.6919C83.7634 10.2762 85.1069 8.89877 88.6189 8.54031V7.44854C88.6189 5.99288 87.8783 4.92294 86.5526 4.92294C86.0246 4.93528 85.5109 5.10323 85.072 5.407C85.6571 5.53254 86.1046 5.94374 86.1046 6.51694C86.108 6.65734 86.0829 6.79694 86.032 6.92717C85.9811 7.0574 85.9051 7.17557 85.808 7.27448C85.7114 7.37334 85.5966 7.45094 85.4697 7.50243C85.3434 7.55397 85.208 7.57831 85.072 7.57408C84.7977 7.57186 84.5349 7.4598 84.3394 7.26157C84.1434 7.06328 84.0303 6.79437 84.0229 6.51146C84.0229 5.33054 85.9623 4.29156 87.7389 4.29156C89.516 4.29156 90.6829 5.20134 90.6829 7.1374V11.388C90.6829 12.8437 91.3034 12.9983 92.2326 13.0875V13.1603Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M92.2766 13.6425V13.5715C93.2589 13.2076 93.4651 12.6399 93.4651 11.206V3.45455C93.4651 1.87877 93.2886 1.48575 92.088 1.01993V0.948963C93.2554 0.828083 94.404 0.557295 95.5063 0.142883H95.5754V11.2079C95.5754 12.6399 95.7817 13.2094 96.744 13.5733V13.6443L92.2766 13.6425Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M103.049 12.3542C102.678 12.8546 101.758 13.7898 100.563 13.7898C99.2617 13.7898 98.2709 13.0711 98.2709 11.0604V5.44332H97.0137V5.35235L97.772 4.92292C98.8897 4.27881 99.5349 3.2944 100.24 2.29181H100.326V4.50989H102.599V5.47612H100.328V10.9567C100.328 11.9593 100.857 12.6053 101.758 12.6053C102.187 12.6092 102.61 12.4915 102.979 12.265L103.049 12.3542Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M108.577 13.6425V13.5715C109.557 13.2076 109.763 12.6399 109.763 11.206V7.30483C109.763 6.10568 109.093 5.48523 108.177 5.48523C107.509 5.52134 106.858 5.70763 106.267 6.03111V11.2079C106.267 12.6399 106.474 13.2094 107.454 13.5733V13.6443H102.983V13.5715C103.947 13.2076 104.154 12.6399 104.154 11.206V3.45455C104.154 1.87877 103.977 1.48575 102.777 1.01993V0.948963C103.948 0.833278 105.1 0.562352 106.204 0.142883H106.274V5.74723C106.858 5.22865 108.133 4.29156 109.252 4.29156C110.645 4.29156 111.815 5.1322 111.815 7.40671V11.2079C111.815 12.6399 112.022 13.2094 113.003 13.5733V13.6443L108.577 13.6425Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  );
};
