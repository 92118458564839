import { IPressableProps, Pressable as BasePressable } from 'native-base';
import { forwardRef } from 'react';
import { getLinkRoleProps } from '../../utils/accessibility';

export type PressableProps = IPressableProps & {
  isLink?: boolean;
  testID: string;
};

export const Pressable = forwardRef(
  ({ children, isLink = false, ...props }: PressableProps, ref): JSX.Element => {
    const linkProps = getLinkRoleProps(isLink || props.role === 'link');

    return (
      <BasePressable role="button" {...linkProps} {...props} ref={ref}>
        {children}
      </BasePressable>
    );
  },
);
