import { useHubUserOnboardMutation } from '../../../graphQL';
import { errorLink } from '../../../plugins/apolloSetup';

export enum OnboardErrorType {
  UnknownError = 'unknown-error',
}

type OnboardError = {
  type: OnboardErrorType;
  message: string;
};

type OnboardUserResponse = [
  (preferredName: string, birthDate: string) => Promise<void>,
  { loading: boolean },
];

type OnboardUserArgs = {
  onCompleted: () => void;
  onConsentBlocked: () => void;
  onError?: (error: OnboardError) => void;
};

export function useOnboardUser({
  onCompleted,
  onConsentBlocked,
  onError,
}: OnboardUserArgs): OnboardUserResponse {
  const [onboardHubUser, { loading }] = useHubUserOnboardMutation({
    onCompleted: ({ hubUserOnboard }) => {
      const responseType = hubUserOnboard?.__typename;

      if (responseType === 'HubUserOnboardError') {
        const { errorCode } = hubUserOnboard;

        if (errorCode === 'AgeBlocked') {
          errorLink.onBlockedError('ageTooYoung');
          return;
        } else if (errorCode === 'AgeConsentRequired' || errorCode === 'AgeConsentIncomplete') {
          errorLink.onBlockedError('ageConsentRequired');
          onConsentBlocked();
          return;
        }

        onError?.({
          type: OnboardErrorType.UnknownError,
          message: 'An unknown error has occurred.',
        });
        return;
      }

      if (responseType === 'HubUserOnboardSuccess') {
        onCompleted?.();
      } else {
        onError?.({
          type: OnboardErrorType.UnknownError,
          message: 'An unknown error has occurred.',
        });
      }
    },
    onError: () => {
      onError?.({
        type: OnboardErrorType.UnknownError,
        message: 'An unknown error has occurred.',
      });
    },
  });

  const onboardUser = async (name: string, birthDate: string): Promise<void> => {
    const birthDateRegexp = /^\d{2}\/\d{2}\/[1-2]\d{3}$/;
    if (!birthDateRegexp.test(birthDate)) {
      // eslint-disable-next-line no-console
      console.error('Invalid user birthdate format - must match MM/DD/YYYY');

      onError?.({
        type: OnboardErrorType.UnknownError,
        message: 'An unknown error has occurred.',
      });
      return;
    }

    await onboardHubUser({
      variables: {
        birthDate,
        name,
      },
    });
  };

  return [onboardUser, { loading }];
}
