import {
  Consumer,
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { Platform, ScrollView as RNScrollView } from 'react-native';
import { Layout } from '../components/core';

type ScrollView = typeof Layout.ScrollView;

export type ScrollContextType = {
  scrollRef: MutableRefObject<ScrollView | undefined>;
  scrollTo: (options: {
    xCoordinate?: number | undefined;
    yCoordinate?: number | undefined;
    animated?: boolean | undefined;
  }) => void;
  scrollToEnd: RNScrollView['scrollToEnd'];
  scrollToTop: RNScrollView['scrollToEnd'];
};

type ScrollFactoryReturnType = {
  ScrollConsumer: Consumer<ScrollContextType>;
  ScrollProvider: ({ children }: { children: ReactNode }) => JSX.Element;
  useScrollContext: () => ScrollContextType;
};

export const scrollContextFactory = (): ScrollFactoryReturnType => {
  const ScrollContext = createContext<ScrollContextType>({
    scrollRef: { current: undefined },
    scrollTo: () => undefined,
    scrollToEnd: () => undefined,
    scrollToTop: () => undefined,
  });

  const ScrollConsumer = ScrollContext.Consumer;

  const ScrollProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const scrollRef = useRef<ScrollView | undefined>();

    const scrollTo = useCallback(
      (options: { xCoordinate?: number; yCoordinate?: number; animated?: boolean }) => {
        if (Platform.OS === 'web') {
          window.scrollTo({ top: options.yCoordinate, left: options.xCoordinate });
        }

        scrollRef.current?.scrollToPosition(
          options.xCoordinate ?? 0,
          options.yCoordinate ?? 0,
          options.animated,
        );
      },
      [scrollRef],
    );

    const scrollToTop = useCallback(
      (options: { animated?: boolean }) => {
        if (Platform.OS === 'web') {
          window.scrollTo({ top: 0 });
        }

        scrollRef.current?.scrollToPosition(0, 0, options?.animated);
      },
      [scrollRef],
    );

    const scrollToEnd = useCallback(
      (options: { animated?: boolean }) => {
        if (Platform.OS === 'web') {
          window.scrollTo({ top: document.body.scrollHeight });
        }

        scrollRef.current?.scrollToEnd(options?.animated);
      },
      [scrollRef],
    );

    const providerValue: ScrollContextType = {
      scrollRef,
      scrollTo,
      scrollToEnd,
      scrollToTop,
    };

    return <ScrollContext.Provider value={providerValue}>{children}</ScrollContext.Provider>;
  };

  const useScrollContext = (): ScrollContextType => {
    return useContext(ScrollContext);
  };

  return {
    ScrollConsumer,
    ScrollProvider,
    useScrollContext,
  };
};

export const { ScrollConsumer, ScrollProvider, useScrollContext } = scrollContextFactory();
