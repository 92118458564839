import { defaultTo } from 'lodash';
import { PageContent, PageError, PageFooter, PageLoading } from '../../components/page';
import { Module } from '../../content/module/components/Module';
import { useModuleById } from '../../content/module/hooks/useModuleById';
import { RouteParams, useParams } from '../../routes';

type ModulePageParams = RouteParams<'module'>;

export const ModulePage = (): JSX.Element => {
  const params = useParams<ModulePageParams>();
  const moduleId = defaultTo(params.moduleId, '');

  const [
    { module, sectionsById, skillsById, completedSkillIds, upNextSkill, moduleIsComplete },
    { loaded, error, refetch },
  ] = useModuleById({ moduleId });

  if (error) {
    return <PageError onRefreshPress={refetch} />;
  }
  if (!loaded) {
    return <PageLoading pageName="modules" />;
  }
  if (!module) {
    return <PageError onRefreshPress={refetch} />;
  }

  return (
    <PageContent pageSize="medium">
      <Module
        module={module}
        sectionsById={sectionsById}
        skillsById={skillsById}
        completedSkillIds={completedSkillIds}
        upNextSkill={upNextSkill}
        moduleIsComplete={moduleIsComplete}
      />

      <PageFooter />
    </PageContent>
  );
};
