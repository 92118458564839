import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../../routes';
import { useFindCareContext } from '../context/findCareContext';

type FindCareRedirectOptions = {
  isStart?: boolean;
};

export const useFindCareRedirect = (options?: FindCareRedirectOptions): void => {
  const { isStart = false } = options ?? {};

  const navigate = useNavigate();
  const { hasStarted, recommendationIsActive } = useFindCareContext();

  useEffect(() => {
    // If they have a recommendation, redirect them to their recommendation.
    if (recommendationIsActive) {
      navigate(getRoute('findCareRecommendation', {}));
    }
  }, [recommendationIsActive]);

  useEffect(() => {
    // If the find care assessment has not been started, then redirect to the start page.
    // This is to prevent incomplete assessments from being submitted.
    if (!recommendationIsActive && !hasStarted && !isStart) {
      navigate(getRoute('findCareStartCare', {}));
    }
  }, [recommendationIsActive, hasStarted, isStart]);
};
