import { defaultTo } from 'lodash';
import { useContrastText } from 'native-base';
import React from 'react';
import { Heading, Layout } from '../../../components/core';
import { IconCheckCircle2 } from '../../../components/icons/IconCheckCircle2';
import { PageBubbleHeader, PageTopBar } from '../../../components/page';
import { getRoute, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { getColorFromShade } from '../../../utils/color';
import { SkillBox } from '../../skill/SkillBox';
import { SkillDocument } from '../../skill/SkillTypes';
import { ModuleDocument } from '../ModuleTypes';

type ModuleHeaderProps = {
  module: ModuleDocument;
  moduleIsComplete: boolean;
  upNextSkill?: SkillDocument;
};

export const ModuleHeader = ({
  module,
  moduleIsComplete,
  upNextSkill,
}: ModuleHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  const data = module.data;
  const color = defaultTo(data?.color, '');
  const colorShade = defaultTo(data?.color_shade, '');
  const imageUrl = defaultTo(data.image?.url, '');
  const title = defaultTo(data.title, '');
  const description = defaultTo(data.description, '');

  const onBackPressed = (): void => {
    navigate(getRoute('selfCareHome', {}));
  };

  const backgroundColor = getColorFromShade(color, colorShade, 'secondary.900');

  // Determines the text color based on the background color.
  const textColor = useContrastText(backgroundColor);

  return (
    <Layout.VStack space={4} testID="module-header">
      <PageTopBar
        alignSelf="flex-start"
        backAccessibilityLabel="Back to Self Care"
        backTitle="Back"
        onBackPress={onBackPressed}
      >
        {moduleIsComplete && (
          <Layout.HStack {...styles.completedMessage} space={2}>
            <IconCheckCircle2 color="green.500" size={5} />

            <Heading.h6>Completed</Heading.h6>
          </Layout.HStack>
        )}
      </PageTopBar>

      <PageBubbleHeader
        backgroundColor={backgroundColor}
        image={{ alt: data.image.alt ?? title, url: imageUrl }}
        textColor={textColor}
        title={title}
        undertext={description}
      />

      <Heading.h3 {...styles.visuallyHidden} center level={2}>
        Up Next
      </Heading.h3>

      {upNextSkill && (
        <SkillBox horizontal moduleId={module.id} skill={upNextSkill} contextText="Up Next" />
      )}
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  completedMessage: {
    alignItems: 'center',
    padding: 4,
  },

  visuallyHidden: {
    opacity: 0,
    height: 0,
  },
});
