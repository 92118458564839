import { useFonts } from 'expo-font';

export function useSetupFonts(): { areFontsLoaded: boolean } {
  const [areFontsLoaded] = useFonts({
    'DMSans-Bold': require('../../assets/fonts/DMSans/DMSans-Bold.ttf'),
    'DMSans-BoldItalic': require('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf'),
    'DMSans-Italic': require('../../assets/fonts/DMSans/DMSans-Italic.ttf'),
    'DMSans-Medium': require('../../assets/fonts/DMSans/DMSans-Medium.ttf'),
    'DMSans-MediumItalic': require('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf'),
    'DMSans-Regular': require('../../assets/fonts/DMSans/DMSans-Regular.ttf'),
    'DMSerifText-Italic': require('../../assets/fonts/DMSerifText/DMSerifText-Italic.ttf'),
    'DMSerifText-Regular': require('../../assets/fonts/DMSerifText/DMSerifText-Regular.ttf'),
  });

  return {
    areFontsLoaded,
  };
}
