import { useSkillContext } from '../../../contexts';
import { ModuleDocument } from '../../module/ModuleTypes';
import { SkillDocument } from '../SkillTypes';
import { SkillWrapper } from './SkillWrapper';
import { EndOfModule } from './upNext/EndOfModule';
import { ModuleComplete } from './upNext/ModuleComplete';
import { UpNext } from './upNext/UpNext';

type SkillUpNextProps = {
  isIntro: boolean;
  isPathway: boolean;
  isVisible: boolean;
  module: ModuleDocument;
  moduleIsComplete: boolean;
  upNextSkill?: SkillDocument;
  upNextSkillIsCompleted?: boolean;
};

export const SkillUpNext = ({
  isIntro,
  isPathway,
  isVisible,
  module,
  moduleIsComplete,
  upNextSkill,
  upNextSkillIsCompleted,
}: SkillUpNextProps): JSX.Element => {
  const { skillRating } = useSkillContext();

  return (
    <SkillWrapper isVisible={isVisible} hideContinueButton>
      {moduleIsComplete && <ModuleComplete isVisible={isVisible} module={module} />}

      {!moduleIsComplete && upNextSkill && (
        <UpNext
          isIntro={isIntro}
          isPathway={isPathway}
          isVisible={isVisible}
          skillRating={skillRating}
          module={module}
          upNextSkill={upNextSkill}
          upNextSkillIsCompleted={upNextSkillIsCompleted ?? false}
        />
      )}

      {!moduleIsComplete && !upNextSkill && <EndOfModule isVisible={isVisible} module={module} />}
    </SkillWrapper>
  );
};
