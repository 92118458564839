import * as Sentry from '@sentry/react-native';

import { config } from '../config';

if (config.sentry.dsn) {
  /**
   * For local dev debugging purposes, add:
   *   debug: true,
   */
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    release: config.sentry.release,
  });
}
