import { defaultTo } from 'lodash';
import { Layout, Text } from '../../components/core';
import { PageContent, PageError, PageFooter, PageHeader, PageLoading } from '../../components/page';
import { useIsOnDemandServiceAvailableQuery, useOrganizationOnDemandQuery } from '../../graphQL';
import { getRoute } from '../../routes';
import { getStylesheet } from '../../styles';
import { Offerings } from './components/Offerings';
import { OtherContactMethods } from './components/OtherContactMethods';
import { VideoContact } from './components/VideoContact';

export function OnDemandLandingPage(): JSX.Element {
  const { data: onDemandData, loading, error, refetch } = useOrganizationOnDemandQuery();
  const onDemandEnabled = defaultTo(onDemandData?.organizationOnDemand?.onDemandEnabled, false);
  const { data: onDemandServiceAvailability, error: onDemandServiceAvailabilityError } =
    useIsOnDemandServiceAvailableQuery();

  const isDuringServiceHours = onDemandServiceAvailability?.isOnDemandServiceAvailable ?? false;

  if (onDemandServiceAvailabilityError) {
    return <PageError goBackTo="onDemandHome" />;
  }

  if (loading) {
    return <PageLoading pageName="on demand landing page" />;
  }

  if (!onDemandEnabled) {
    return <PageError goBackTo={getRoute('home', {})} />;
  }

  if (error) {
    return <PageError showContact onRefreshPress={refetch} />;
  }

  const onDemandCrisisContact = onDemandData?.organizationOnDemand?.onDemandData;

  return (
    <PageContent>
      <Layout.VStack space={4}>
        <PageHeader title="On-Demand Emotional Support" />
        <Offerings />
        <Layout.Shift
          {...styles.shiftContainer}
          reverseMobile={!isDuringServiceHours}
          reverseNonMobile={!isDuringServiceHours}
        >
          <VideoContact
            crisisPhoneNumber={onDemandCrisisContact?.crisisCallPhoneNumber ?? ''}
            isDuringServiceHours={isDuringServiceHours}
          />
          {onDemandCrisisContact && <OtherContactMethods onDemandData={onDemandCrisisContact} />}
        </Layout.Shift>
        <Text.paraSmall marginTop={8} center>
          * Video call support is available 7 days a week from noon - 12am ET, excluding major
          holidays.
        </Text.paraSmall>
        <PageFooter />
      </Layout.VStack>
    </PageContent>
  );
}

const styles = getStylesheet({
  shiftContainer: {
    gap: 3,
  },
});
