import { useEffect } from 'react';
import { useParams } from '../../../routes';
import { SlideContextType } from '../slideContextFactory';

/**
 * Makes sure the slide set in the route params is valid.
 * It can not be less than 1 or greater than the slideCount.
 */
export const useSlideParamCheck = <Slide,>(context: SlideContextType<Slide>): void => {
  const { slide: rawSlide } = useParams<{ slide: string }>();

  const { slides, slideCount, slideNumber, triggerFirstSlide, triggerLastSlide } = context;

  useEffect(() => {
    // If the slide set in the URL wasn't a valid number, then redirect again to the first slide.
    if (slideNumber !== Number(rawSlide)) {
      triggerFirstSlide();
      return;
    }

    // If the slide set in the URL is larger than the slideCount, then redirect to the last slide.
    if (slideCount !== undefined && slideCount > 0 && slideNumber > 1 && slideNumber > slideCount) {
      triggerLastSlide();
    }
  }, [slideNumber, slides]);
};
