export const themeFonts = {
  DMSans: {
    100: {
      normal: 'DMSans-Regular',
      italic: 'DMSans-Italic',
    },
    200: {
      normal: 'DMSans-Regular',
      italic: 'DMSans-Italic',
    },
    300: {
      normal: 'DMSans-Regular',
      italic: 'DMSans-Italic',
    },
    400: {
      normal: 'DMSans-Regular',
      italic: 'DMSans-Italic',
    },
    500: {
      normal: 'DMSans-Medium',
      italic: 'DMSans-MediumItalic',
    },
    600: {
      normal: 'DMSans-Medium',
      italic: 'DMSans-MediumItalic',
    },
    700: {
      normal: 'DMSans-Bold',
      italic: 'DMSans-BoldItalic',
    },
    800: {
      normal: 'DMSans-Bold',
      italic: 'DMSans-BoldItalic',
    },
    900: {
      normal: 'DMSans-Bold',
      italic: 'DMSans-BoldItalic',
    },
  },
  DMSerif: {
    100: {
      normal: 'DMSerifText-Regular',
      italic: 'DMSerifText-Italic',
    },
    200: {
      normal: 'DMSerifText-Regular',
      italic: 'DMSerifText-Italic',
    },
    300: {
      normal: 'DMSerifText-Regular',
      italic: 'DMSerifText-Italic',
    },
    400: {
      normal: 'DMSerifText-Regular',
      italic: 'DMSerifText-Italic',
    },
    500: {
      normal: 'DMSerifText-Regular',
      italic: 'DMSerifText-Italic',
    },
    600: {
      normal: 'DMSerifText-Regular',
      italic: 'DMSerifText-Italic',
    },
    700: {
      normal: 'DMSerifText-Regular',
      italic: 'DMSerifText-Italic',
    },
    800: {
      normal: 'DMSerifText-Regular',
      italic: 'DMSerifText-Italic',
    },
    900: {
      normal: 'DMSerifText-Regular',
      italic: 'DMSerifText-Italic',
    },
  },
};
