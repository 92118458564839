import { SliceZone } from '@prismicio/react';
import { defaultTo } from 'lodash';
import { Heading, Layout } from '../../../components/core';
import { RichText } from '../../../components/prismic';
import { getStylesheet } from '../../../styles';
import { SkillDocument } from '../../skill/SkillTypes';
import { SectionDocument } from '../SectionTypes';
import { components as sliceComponents } from './slice';

type SectionContext = {
  moduleId: string;
  skillsById: Record<string, SkillDocument>;
  completedSkillIds: string[];
};

type SectionProps = {
  context: SectionContext;
  section: SectionDocument;
};

export const Section = ({ context, section }: SectionProps): JSX.Element => {
  const data = section.data;
  const title = defaultTo(data.title, '');
  const description = defaultTo(data.description, []);

  return (
    <Layout.VStack {...styles.stack} space={4} testID="section">
      {title && (
        <Heading.h3 center level={2}>
          {title}
        </Heading.h3>
      )}

      {description.length > 0 && (
        <RichText styles={{ text: { textAlign: 'center' } }} field={description} />
      )}

      <SliceZone context={context} slices={data.slices} components={sliceComponents} />
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  stack: {
    alignItems: 'stretch',
    width: '100%',
  },
});
