import React from 'react';
import { Heading, Layout } from '../../components/core';
import { PageContent, PageError, PageFooter, PageLoading } from '../../components/page';
import { useIsOnDemandServiceAvailableQuery, useOnDemandUserQuery } from '../../graphQL';
import { getRoute, useNavigate } from '../../routes';
import { OnDemandPageWrapper } from './components/OnDemandPageWrapper';
import { EncounterForm } from './forms/EncounterForm';
import { isDuringHoliday } from './utils/availability';

export const onDemandPreEncounterPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { loading: checkingAvailability } = useIsOnDemandServiceAvailableQuery({
    onCompleted(data) {
      const isDuringServiceHours = data.isOnDemandServiceAvailable ?? false;

      if (!isDuringServiceHours || isDuringHoliday()) {
        navigate(getRoute('onDemandHome', {}), { replace: true });
      }
    },
    onError() {
      navigate(getRoute('onDemandHome', {}), { replace: true });
    },
  });

  const { data: patientData, loading, error, refetch } = useOnDemandUserQuery();

  if (loading || checkingAvailability) {
    return <PageLoading pageName="on demand pre encounter page" />;
  }

  if (error) {
    return <PageError showContact onRefreshPress={refetch} />;
  }

  const currentPhone = patientData?.onboardedHubUser.phone ?? '';

  return (
    <OnDemandPageWrapper testID="on-demand-pre-encounter-page">
      <PageContent>
        <Layout.VStack space={7}>
          <Heading.h2>Before we connect, please answer a few short questions</Heading.h2>

          <EncounterForm currentPhone={currentPhone} />
        </Layout.VStack>
        <PageFooter />
      </PageContent>
    </OnDemandPageWrapper>
  );
};
