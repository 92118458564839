import { useMemo } from 'react';

type PasswordRules = {
  confirmPasswordRules: { validate: (validateValue: string | undefined) => string | boolean };
  passwordRules: {
    minLength: { message: string; value: number };
    validate: (validateValue: string | undefined) => string | boolean;
  };
};

export const usePasswordRules = (passwordValue: string): PasswordRules => {
  const confirmPasswordRules = useMemo(
    () => ({
      validate: (validateValue: string | undefined): string | boolean => {
        if (passwordValue !== validateValue) {
          return 'Passwords do not match.';
        }
        return true;
      },
    }),
    [passwordValue],
  );

  const passwordRules = {
    minLength: {
      value: 8,
      message: 'Invalid password. Password must be at least 8 characters long.',
    },
    validate: (validateValue: string | undefined): string | boolean => {
      if (validateValue === undefined) {
        // isRequired handles the undefined case.
        return true;
      }
      if (!validateValue.match(/[A-Z]/)) {
        return 'Invalid password. Password must include at least one uppercase letter.';
      }
      if (!validateValue.match(/[a-z]/)) {
        return 'Invalid password. Password must include at least one lowercase letter.';
      }
      if (!validateValue.match(/[0-9]/)) {
        return 'Invalid password. Password must include at least one number.';
      }
      if (!validateValue.match(/[^a-zA-Z0-9]/)) {
        return 'Invalid password. Password must include at least one special character.';
      }
      return true;
    },
  };

  return {
    confirmPasswordRules,
    passwordRules,
  };
};
