import { FormControl, IIconButtonProps } from 'native-base';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Button, Layout } from '../core';
import { FormScrollingProps } from './hooks/useFormScrolling';

type FormLikeProps<TFieldValues extends FieldValues> = IIconButtonProps &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> & {
    button: Button.ButtonComponent;
    buttonSelected: Button.ButtonComponent;
    contextLabel: string;
    preventUnrate?: boolean;
    iconUp: JSX.Element;
    iconDown: JSX.Element;
    label?: string;
    space?: number;
  };

export function FormLike<TFieldValues extends FieldValues>({
  button,
  buttonSelected,
  contextLabel,
  control,
  iconUp,
  iconDown,
  name,
  onLayout,
  label = '',
  space = 4,
  preventUnrate = false,
  ...iconButtonProps
}: FormLikeProps<TFieldValues>): JSX.Element {
  const upRating = 1;
  const downRating = -1;

  return (
    <FormControl onLayout={onLayout}>
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          const onPress = (rating: number) => () => {
            if (preventUnrate) {
              onChange(rating);
              return;
            }

            onChange(value === rating ? 0 : rating);
          };

          const DownButtonComponent = value !== downRating ? button : buttonSelected;
          const UpButtonComponent = value !== upRating ? button : buttonSelected;

          return (
            <Layout.HStack space={space}>
              <DownButtonComponent
                accessibilityLabel={`Rate ${contextLabel} with thumbs down.`}
                leftIcon={iconDown}
                rounded="3xl"
                {...iconButtonProps}
                testID="button-form-like-down"
                onPress={onPress(downRating)}
              />
              <UpButtonComponent
                accessibilityLabel={`Rate ${contextLabel} with thumbs up.`}
                leftIcon={iconUp}
                rounded="3xl"
                {...iconButtonProps}
                testID="button-form-like-up"
                onPress={onPress(upRating)}
              />
            </Layout.HStack>
          );
        }}
      />
    </FormControl>
  );
}
