import { has } from 'lodash';
import { darkText } from '../styles/colors';

const colorShades = {
  light: '100',
  medium: '300',
  dark: '600',
} as const;

type ShadeNames = keyof typeof colorShades;

export const getColorFromShade = (color: string, colorShade: string, fallback: string): string => {
  if (!color || !colorShade || !has(colorShades, colorShade)) {
    return fallback ?? '';
  }

  // The typing is safe here because of the includes check above.
  const shadeValue = colorShades[colorShade as ShadeNames];

  return `${color}.${shadeValue}`;
};

export const hexToRgba = (hex: string, opacity: number): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result?.[1] !== undefined && result?.[2] !== undefined && result?.[3] !== undefined
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}, ${opacity})`
    : darkText;
};
