import { Divider } from 'native-base';
import React from 'react';
import { Layout } from '../../components/core';
import {
  PageContent,
  PageFooter,
  PageHeader,
  PageLoading,
  PageTopBar,
} from '../../components/page';
import { useHubOrganizationResourcesQuery } from '../../graphQL';
import { useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { CampusResource } from '../campusResources/components/CampusResource';
import { AdditionalResources } from './components/AdditionalResources';

export const CrisisResourcesPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { data: hubUserOrgResourcesData, loading } = useHubOrganizationResourcesQuery();

  if (loading) {
    return <PageLoading pageName="resources" />;
  }

  const resources = hubUserOrgResourcesData?.hubOrganizationResources ?? [];
  const crisisResources = resources.filter(resource => resource.showAsCrisisResource);
  const orgHasResources = crisisResources.length > 0;

  const onBackPress = (): void => {
    navigate(-1);
  };

  return (
    <PageContent testID="crisis-resources-page">
      <PageTopBar backAccessibilityLabel="Back" backTitle="Back" onBackPress={onBackPress} />

      <PageHeader
        title="Get Help Now"
        undertext="If you or anyone you know are in a crisis or may be in danger, the following resources can
          provide you with immediate help."
      />

      <Layout.VStack
        {...styles.resourcesStack}
        divider={<Divider backgroundColor="secondary.alpha.10:alpha.10" />}
        space={10}
      >
        {orgHasResources && (
          <Layout.VStack>
            {crisisResources.map(resource => (
              <CampusResource key={resource.name} resource={resource} />
            ))}
          </Layout.VStack>
        )}

        <AdditionalResources orgHasResources={orgHasResources} />
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  resourcesStack: {
    paddingTop: 10,
  },
});
