export const modalStyles = {
  ModalContent: {
    baseStyle: {
      backgroundColor: 'secondary.100',
    },
  },
  ModalBody: {
    baseStyle: {
      backgroundColor: 'secondary.100',
    },
  },
};
