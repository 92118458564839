import { ReactNode } from 'react';
import { SimpleHeader } from './SimpleHeader';
import { SimpleLayout } from './SimpleLayout';

type SimpleLayoutProps = {
  children: ReactNode;
};

export const SimpleHeaderLayout = ({ children }: SimpleLayoutProps): JSX.Element => {
  return (
    <SimpleLayout>
      <SimpleHeader>{children}</SimpleHeader>
    </SimpleLayout>
  );
};
