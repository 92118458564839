import { isEmpty } from 'lodash';
import { Divider } from 'native-base';
import React from 'react';
import { Button, Heading, Layout, Text } from '../../components/core';
import { IconChevronRight } from '../../components/icons/IconChevronRight';
import {
  PageContent,
  PageError,
  PageFooter,
  PageHeader,
  PageLoading,
  PageTopBar,
} from '../../components/page';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { usePathway } from './hooks/usePathway';
import { SkillPathwayStep } from './SkillPathwayStep';

export const SkillPathwayPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [pathwaySteps, { loading, error }] = usePathway();

  if (loading) {
    return <PageLoading pageName="pathway" />;
  }
  if (error) {
    return <PageError />;
  }

  const onBackPressed = (): void => {
    navigate(getRoute('selfCareHome', {}));
  };

  const onResetPressed = (): void => {
    navigate(
      getRoute('selfCareQuiz', {
        slide: '1',
      }),
    );
  };

  return (
    <PageContent testID="skill-pathway-page">
      <PageTopBar
        backAccessibilityLabel="Back to Self Care"
        backTitle="Back"
        onBackPress={onBackPressed}
        testID="skill-pathway-top-bar"
      />

      <Layout.VStack space={8}>
        <PageHeader
          title="Your Pathway"
          undertext="Based on your responses, we think the following skills can help you reach your goals."
          testID="skill-pathway-header"
        />

        {!isEmpty(pathwaySteps) && (
          <Layout.VStack
            {...styles.topWrapper}
            space={6}
            divider={<Divider backgroundColor="secondary.alpha.10:alpha.10" />}
          >
            {pathwaySteps.map((step, stepIndex) => (
              <SkillPathwayStep
                key={`${step.moduleId}-${step.sectionId}`}
                {...step}
                isFirstStep={stepIndex === 0}
                isLastStep={stepIndex === pathwaySteps.length - 1}
              />
            ))}
          </Layout.VStack>
        )}

        {isEmpty(pathwaySteps) && (
          <Layout.VStack
            aria-labelledby="skill-pathway-empty-reset"
            alignItems="center"
            role="group"
            space={2}
          >
            <Heading.h4 level={2} id="skill-pathway-empty-reset">
              We didn't find any results for you, try resetting your goals.
            </Heading.h4>

            <Button.primaryLarge
              isLink
              onPress={onResetPressed}
              testID="button-pathway-empty-reset-goals"
              alignSelf="center"
            >
              Reset Goals
            </Button.primaryLarge>
          </Layout.VStack>
        )}

        <Layout.HStack {...styles.resetWrapper} aria-labelledby="skill-pathway-reset" role="group">
          <Text.para {...styles.textWrapper} id="skill-pathway-reset">
            Not happy with your recommendations?
          </Text.para>

          <Button.tertiarySmall
            isLink
            rightIcon={<IconChevronRight aria-hidden size={4} />}
            onPress={onResetPressed}
            testID="button-pathway-reset-goals"
          >
            Reset Goals
          </Button.tertiarySmall>
        </Layout.HStack>
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  topWrapper: {
    alignItems: 'center',
  },

  textWrapper: {
    maxWidth: '80%',
  },

  resetWrapper: {
    alignItems: 'center',
    backgroundColor: 'secondary.200',
    borderRadius: '4px',
    justifyContent: 'space-between',
    padding: 4,
  },

  resetGoalsAction: {
    alignItems: 'center',
  },
});
