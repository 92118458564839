import { StatusFeedback } from '../../../components/core';
import { IconHelpCircle } from '../../../components/icons/IconHelpCircle';
import type { JSX } from 'react';

export const SignupReauthenticate = (): JSX.Element => {
  return (
    <StatusFeedback.info Icon={IconHelpCircle} testID="signup-reauthenticate">
      Password set, please log in to continue.
    </StatusFeedback.info>
  );
};
