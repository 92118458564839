import { usePrismicClient } from '@prismicio/react';
import { useEffect, useState } from 'react';
import { ViewDocument } from '../ViewTypes';

type ResultsByTag = Record<string, ViewDocument[]>;

type AggregateByTagsReturn = [
  ResultsByTag,
  {
    loading: boolean;
    error?: Error;
  },
];

export const usePrismicAggregateByTags = (tags: string[]): AggregateByTagsReturn => {
  const client = usePrismicClient();

  const [documentsByTag, setDocumentsByTag] = useState<ResultsByTag>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<Error>();

  useEffect((): void => {
    setIsLoading(true);

    const prismicPromises = tags.map(async tag => {
      return client.getByTag(tag).then(result => [tag, result] as const);
    });

    Promise.all(prismicPromises)
      .then(results => {
        const accumulator: ResultsByTag = {};
        const resultsByTag: ResultsByTag = results.reduce((acc, [tag, result]) => {
          if (result == null) {
            return acc;
          }

          return {
            ...acc,
            [tag]: result.results as unknown as ViewDocument[],
          };
        }, accumulator);

        setDocumentsByTag(() => resultsByTag);
        setIsLoading(false);
      })
      .catch(prismicError => {
        setLoadError(() => prismicError);
        setIsLoading(false);
      });
  }, [tags]);

  return [documentsByTag, { loading: isLoading, error: loadError }];
};
