import { useResetBadgeCountMutation } from '../../../graphQL';

export enum BadgeCountCounterNameEnum {
  UnreadMessages = 'unreadMessages',
  UnviewedAssignedSkills = 'unviewedAssignedSkills',
}

type UseBadgeCountReturn = [() => Promise<void>];

export const useBadgeCountReset = (counterName: BadgeCountCounterNameEnum): UseBadgeCountReturn => {
  const [resetBadgeCount] = useResetBadgeCountMutation({
    variables: {
      counterName,
    },
  });

  const badgeCountReset = async (): Promise<void> => {
    void resetBadgeCount();
  };

  return [badgeCountReset];
};
