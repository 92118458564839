import { useEffect } from 'react';
import { useOnboardedUserLazyQuery } from '../../../graphQL';

export const NewUserRedirect = (): null => {
  const [fetchOnboardedUser] = useOnboardedUserLazyQuery();

  const check = (): void => {
    // If the user is not onboarded, this will throw an onboarding error. Then the apollo
    // error handler will redirect the user to the onboarding page.
    void fetchOnboardedUser();
  };

  useEffect(() => {
    void check();
  }, []);

  return null;
};
