// Code generated by Slice Machine. DO NOT EDIT.

import CompletionSlide from './CompletionSlide';
import InfoSlide from './InfoSlide';
import RatingSlide from './RatingSlide';
import ReflectionSlide from './ReflectionSlide';
import VideoSlide from './VideoSlide';

export { CompletionSlide, InfoSlide, RatingSlide, ReflectionSlide, VideoSlide };

export const components = {
  completion_slide: CompletionSlide,
  info_slide: InfoSlide,
  rating_slide: RatingSlide,
  reflection_slide: ReflectionSlide,
  video_slide: VideoSlide,
};
