import React, { useEffect } from 'react';
import { Heading, Layout } from '../../components/core';
import { useLogout } from '../../components/layout/app/hooks/useLogout';
import { PageContent, PageFooter } from '../../components/page';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';

export const LogoutPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [logout] = useLogout({
    onCompleted: async () => {
      navigate(getRoute('login', {}));
    },
  });

  useEffect(() => {
    void logout();
  }, []);

  return (
    <PageContent {...styles.mainStack}>
      <Layout.VStack space={3}>
        <Heading.h3 center>Logging you out...</Heading.h3>

        <PageFooter />
      </Layout.VStack>
    </PageContent>
  );
};

const styles = getStylesheet({
  mainStack: {
    paddingTop: 6,
  },
});
