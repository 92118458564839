import { SliceZone } from '@prismicio/react';
import { Layout } from '../../../components/core';
import { SectionDocument } from '../../section/SectionTypes';
import { SkillDocument } from '../../skill/SkillTypes';
import { ModuleDocument } from '../ModuleTypes';
import { ModuleHeader } from './ModuleHeader';
import { components as sliceComponents } from './slice';

type ModuleProps = {
  module: ModuleDocument;
  moduleIsComplete: boolean;
  sectionsById: Record<string, SectionDocument>;
  skillsById: Record<string, SkillDocument>;
  completedSkillIds: string[];
  upNextSkill: SkillDocument | undefined;
};

export const Module = ({
  module,
  moduleIsComplete,
  sectionsById,
  skillsById,
  completedSkillIds,
  upNextSkill,
}: ModuleProps): JSX.Element => {
  const data = module.data;

  const sliceContext = {
    moduleId: module.id,
    sectionsById,
    skillsById,
    completedSkillIds,
  };

  return (
    <Layout.View testID="module">
      <ModuleHeader module={module} upNextSkill={upNextSkill} moduleIsComplete={moduleIsComplete} />

      <SliceZone slices={data.slices} components={sliceComponents} context={sliceContext} />
    </Layout.View>
  );
};
