import { extendTheme } from 'native-base';
import { Platform } from 'react-native';
import * as themeColors from './colors';
import { badgeStyles } from './components/badge';
import { buttonStyles } from './components/button';
import { formStyles } from './components/form';
import { headerStyles } from './components/header';
import { iconStyles } from './components/icon';
import { linkStyles } from './components/link';
import { modalStyles } from './components/modal';
import { progressStyles } from './components/progress';
import { textStyles } from './components/text';
import { themeFonts } from './fonts';

/**
 * The native base custom theme.
 * @see {https://docs.nativebase.io/customizing-theme}
 */
export const theme = extendTheme({
  // Set the app breakpoints.
  breakpoints: {
    base: 0,
    sm: 550,
    md: 900,
    lg: 1000,
    xl: 1280,
  },

  // Set all aspects to use the new font.
  fonts: {
    body: 'DMSans',
    heading: 'DMSans',
    mono: 'DMSans',
    serif: 'DMSerif',
    sans: 'DMSans',
  },

  /**
   * Bolding text in different browsers is inconsistent.
   * Chrome sets bold weight with "bold" which does not pass a weight of 700.
   * Firefox uses "bolder" which renders 700 weight as 900 as a relative weight.
   * Setting this to "bold" will limit the weight to 700 as per our designs.
   * https://css-tricks.com/firefoxs-bolder-default-is-a-problem-for-variable-fonts/#aa-where-is-this-difference-coming-from
   * "bold" doesn't work correctly on mobile which relies on specific font weights.
   * */
  fontWeights: {
    bold: Platform.OS === 'web' ? 'bold' : 700,
  },

  colors: themeColors,
  fontConfig: themeFonts,

  components: {
    ...badgeStyles,
    ...buttonStyles,
    ...formStyles,
    ...headerStyles,
    ...iconStyles,
    ...linkStyles,
    ...modalStyles,
    ...progressStyles,
    ...textStyles,
  },

  shadows: {
    small: {
      shadowColor: themeColors.primary.alpha['5'],
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.05,
      shadowRadius: 4,
      elevation: 10,
    },
    medium: {
      shadowColor: themeColors.primary.alpha['5'],
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.05,
      shadowRadius: 10,
      elevation: 10,
    },
    large: {
      shadowColor: themeColors.primary.alpha['5'],
      shadowOffset: {
        width: 0,
        height: 10,
      },
      shadowOpacity: 0.05,
      shadowRadius: 20,
      elevation: 10,
    },
  },
});

type CustomThemeType = typeof theme;

declare module 'native-base' {
  // Make sure this stays an interface so it extends native-base.
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ICustomTheme extends CustomThemeType {}
}
