import { createIcon, Path } from './index';

export const IconChevronsUp = createIcon({
  title: 'Two up chevrons',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M17 11L12 6L7 11"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M17 18L12 13L7 18"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
