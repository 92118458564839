import { Platform } from 'react-native';
import { useDisableDeviceMutation } from '../../../../graphQL';
import {
  getDeviceTokenRegistered,
  removeDeviceTokenRegistered,
} from '../../../../utils/authenticationStore';

export const useDisableDevice = (): { asyncDisableDevice: () => Promise<void> } => {
  const [disableDevice] = useDisableDeviceMutation();

  const asyncDisableDevice = async (): Promise<void> => {
    const firebaseToken = await getDeviceTokenRegistered();

    if (Platform.OS === 'web' || !firebaseToken) {
      return;
    }

    const deviceDisabled = await disableDevice({
      variables: { firebaseToken },
    });
    if (deviceDisabled.data?.disableDevice === true) {
      await removeDeviceTokenRegistered();
    }
  };

  return { asyncDisableDevice };
};
