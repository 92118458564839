import { JSX } from 'react';
import { Link, StatusFeedback } from '../../../components/core';
import { IconAlert } from '../../../components/icons/IconAlert';

export const SignupRateLimitError = (): JSX.Element => {
  return (
    <StatusFeedback.danger Icon={IconAlert} testID="signup-rate-limit-error">
      Unable to create an account at this time. Please try again in 15 minutes or reach out to us at{' '}
      <Link.paraSmall bold to="mailto:hi@mantrahealth.com">
        hi@mantrahealth.com
      </Link.paraSmall>
    </StatusFeedback.danger>
  );
};
